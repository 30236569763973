import { TimelineSegmentEvent } from '@/utils/timelineTypes';
import { TruncatingText } from '@instech/components';
import { FC } from 'react';
import styled from 'styled-components';

const Subtitle = styled.div`
  font-size: 12px;
`;

interface Props {
  event: TimelineSegmentEvent;
}
/**
 * Subtitle string shown below the main name for an Event on the Vessel Movement timeline.
 *
 * If the event is an occurrence, it will try to display the event's name. If not, it will
 * try to display a comment instead. If neither exists, it nothing will show.
 */
export const EventSubtitle: FC<Props> = ({ event }) => {
  const subtitleText = (event.eventType === 'Occurrence' && event.name) || event.comment;

  if (!subtitleText) {
    return null;
  }

  return (
    <Subtitle>
      <TruncatingText text={subtitleText} width="100%" />
    </Subtitle>
  );
};
