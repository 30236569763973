import { roles } from '@/components/AppRouting/roles';
import { CheckboxControlled } from '@/components/shared/Form/FormFields';
import { ApplicationRole } from '@/types';
import {
  BoxButton, ButtonGroup, Divider
} from '@instech/components';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  >:last-child {
    margin-left: auto;
  }
`;

interface Props {
  role: ApplicationRole | null | undefined;
  close: () => void;
  filterRequestedFiles: boolean;
  setFilterRequestedFiles: (value: boolean) => void;
  hasFiles: boolean;
}
export const AvailableInformationModalFooter = ({ role, filterRequestedFiles, setFilterRequestedFiles, close, hasFiles }: Props) => (
  <>
    <Divider padding="0px" />
    <Container>
      {role?.name === roles.claimHandler && hasFiles && (
      <CheckboxControlled
            id="filter-only-requested-files-input"
            label="Show only requested files"
            checked={filterRequestedFiles}
            onChange={setFilterRequestedFiles}
          />
      )}
      <ButtonGroup alignRight>
        <BoxButton onClick={close}>Done</BoxButton>
      </ButtonGroup>
    </Container>
  </>
);
