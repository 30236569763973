import { Page } from '@/components/layout/Page';
import { StopCircle } from '@instech/icons';
import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const DownForMaintenance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MessageBox = styled.div`
  margin-top: 40px;
  font-weight: 700;
  font-size: 60px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
`;

export const DownForMaintenancePage = () => (
  <Page noScroll={false} hideUserInfo showHelpAndNotifications={false}>
    <Layout>
      <DownForMaintenance>
        <StopCircle />
        <MessageBox>Sorry, this site is down for maintenance</MessageBox>
      </DownForMaintenance>
    </Layout>
  </Page>
);
