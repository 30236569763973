import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useFormikContext } from 'formik';
import { ReadOnlyField } from '@/components/shared/ReadOnlyField';
import { newEntryModel } from '@/components/tools/VesselParticulars/information/VesselInformation';
import { RemoveButton } from '@/components/shared/LinkButton/LinkButtons';
import { validationSchema } from '@/components/tools/VesselParticulars/information/core';
import { Pane } from '@instech/components';
import {
  FieldPadding, Information, StyledInputForm
} from './Components';

interface CompanyParticularAdd {
  title: string;
  value: string;
  includeInReport: boolean;
  editable: boolean;
}

interface CompanyParticularEntry {
  id: string;
  title: string;
  description: string;
  source: {
    name: string;
    date: string;
  };
}

const Field = styled.div`
  width: 100%;
  padding-right: 10px;
  min-width: 0px;
`;

const fields = [
  { name: 'title', label: 'Title', placeholder: 'Add title' },
  { name: 'value', label: 'Description', placeholder: 'Add description' }
];

export const CompanyParticularsFields = () => {
  const { values, setFieldValue } = useFormikContext<{ companyParticulars: CompanyParticularEntry[] }>();

  if (!values.companyParticulars) return null;

  const addParticular = (model: CompanyParticularAdd) => {
    const newEntry: CompanyParticularEntry = {
      id: uuidv4(),
      description: model.value,
      title: model.title,
      source: {
        date: new Date().toDateString(),
        name: 'Claims handler'
      }
    };
    const particulars = [...values.companyParticulars, newEntry];
    void setFieldValue('companyParticulars', particulars);
  };

  const deleteParticular = (id: string) => {
    const particulars = values.companyParticulars.filter(item => item.id !== id);
    void setFieldValue('companyParticulars', particulars);
  };

  return (
    <Pane title="Company Particulars" padding="0px" margin="16px 0px 0px">
      <FieldPadding>
        {values.companyParticulars.map(field => (
          <Information key={field.id}>
            <Field>
              <ReadOnlyField key={field.id} title={field.title} value={field.description} />
            </Field>
            <RemoveButton onClick={() => deleteParticular(field.id)} />
          </Information>
        ))}
      </FieldPadding>
      <StyledInputForm
        formId="companyParticulars"
        model={newEntryModel}
        fields={fields}
        onSubmit={model => addParticular(model as CompanyParticularAdd)}
        closeForm={() => { }}
        validationSchema={validationSchema}
        cancelIsReset
      />
    </Pane>
  );
};
