import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavEditIcon } from '@/components/shared/LinkButton/LinkButtons';
import { formatNumber } from '@/utils/number';
import { TruncatingText } from '@instech/components';

const ContentWrapper = styled.div`
  background-color: ${props => props.theme.background.primary};
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextContent = styled.div`
  padding-left: 20px;
  font-size: 24px;
  color: ${props => props.theme.blackPearl};
  font-weight: bold;
  padding-top: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
`;

const StatusBox = styled.div`
  font-size: 13px;
  font-weight: bold;
  background-color: ${props => props.theme.status[props.color]};
  color: ${props => props.theme.white};
  text-align: center;
  padding: 4px 10px;
`;

const HeaderContent = styled.div`
  color: ${props => props.theme.marineBlue};
  font-size: 13px;
  font-weight: bold;
  padding-left: 20px;
  padding-top: 10px;
`;

const ContentContainer = styled.div`
  // This is needed to make the text truncation work in the children
  overflow: hidden;
`;

const statusTypes = {
  'Final': {
    text: 'FINAL',
    color: 'green'
  },
  'Quoted / Verified': {
    text: 'QUOTED / VERIFIED',
    color: 'blue'
  },
  'Estimate': {
    text: 'ESTIMATE',
    color: 'red'
  }
};

const ContentSeparator = styled(ContentWrapper)`
  margin-bottom: 15px;
  :last-child {
    margin-bottom: 0;
  }
`;

const Currency = styled.span`
  padding-right: 1em;
`;

export const ExpenseCard = ({ status, currency, sum, title, to }) => {
  const statusType = status && statusTypes[status];

  return (
    <ContentSeparator>
      <ContentContainer>
        <HeaderContent><TruncatingText text={title} /></HeaderContent>
        <TextContent>
          <Currency>{currency}</Currency>
          {formatNumber(sum)}
        </TextContent>
      </ContentContainer>
      <div>
        {statusType && <StatusBox color={statusType.color}>{statusType.text}</StatusBox>}
        {to && <ButtonWrapper><NavEditIcon to={to} /></ButtonWrapper>}
      </div>
    </ContentSeparator>
  );
};
ExpenseCard.propTypes = {
  title: PropTypes.string.isRequired,
  currency: PropTypes.string,
  sum: PropTypes.number,
  status: PropTypes.string,
  to: PropTypes.string
};

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 3px;
`;

const CardSeparator = styled.div`
  margin-bottom: 15px;
  :last-child {
    margin-bottom: 0;
  }
`;

const TimeSummaryContent = styled(ContentWrapper)`
  display: grid;
  grid-template-columns: minmax(120px, 0.5fr) 1fr 128px;
  align-items: start;
`;

export const TimeSummaryCard = ({ title, status, contents, to }) => {
  const statusType = status && statusTypes[status];
  return (
    <CardSeparator>
      <Title><TruncatingText text={title} /></Title>
      <TimeSummaryContent>
        {contents.map(({ header, content }) => (
          <div key={header + content}>
            <HeaderContent>{header}</HeaderContent>
            <TextContent>{content}</TextContent>
          </div>
        ))}
        <div>
          {statusType && <StatusBox color={statusType.color}>{statusType.text}</StatusBox>}
          {to && <ButtonWrapper><NavEditIcon to={to} /></ButtonWrapper>}
        </div>
      </TimeSummaryContent>
    </CardSeparator>
  );
};
TimeSummaryCard.propTypes = {
  title: PropTypes.string.isRequired,
  contents: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string,
    content: PropTypes.string
  })),
  status: PropTypes.string,
  to: PropTypes.string
};
