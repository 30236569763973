import { ReactNode, FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-bottom: 16px;
  padding-top: 16px;
`;

export const InformationHeader = ({ children }: { children: ReactNode }) => (
  <Wrapper>
    {children}
  </Wrapper>
);

export const CommentArea = styled.div`
  white-space: pre-wrap;
`;

const AttendanceArea = styled.div`
  padding-bottom: 16px;
`;

type AttendanceOption =
'none'
| 'attendance'
| 'remote'
| 'withoutAttendance'
| 'attendanceAndRemote'
| 'remoteAndWithoutAttendance'
| 'attendanceAndWithoutAttendance'
| 'all';

const getAttendanceOption = (attendance?: boolean, remote?: boolean, withoutAttendance?: boolean): AttendanceOption => {
  if (!attendance && !remote && !withoutAttendance) return 'none';
  if (attendance && !remote && !withoutAttendance) return 'attendance';
  if (!attendance && remote && !withoutAttendance) return 'remote';
  if (!attendance && !remote && withoutAttendance) return 'withoutAttendance';
  if (attendance && remote && !withoutAttendance) return 'attendanceAndRemote';
  if (!attendance && remote && withoutAttendance) return 'remoteAndWithoutAttendance';
  if (attendance && !remote && withoutAttendance) return 'attendanceAndWithoutAttendance';

  return 'all';
};

const getAttendanceText = (attendance?: boolean, remote?: boolean, withoutAttendance?: boolean) => {
  const attendanceOption = getAttendanceOption(attendance, remote, withoutAttendance);

  switch (attendanceOption) {
    case 'none': return null;
    case 'attendance': return 'The survey was performed with attendance.';
    case 'remote': return 'The survey was performed by Remote Survey.';
    case 'withoutAttendance': return 'The survey was performed without attendance.';
    case 'attendanceAndRemote': return 'The survey was performed with attendance and by Remote Survey.';
    case 'remoteAndWithoutAttendance': return 'The survey was performed by Remote Survey and without attendance.';
    case 'attendanceAndWithoutAttendance': return 'The survey was performed with and without attendance.';
    case 'all': return 'The survey was performed with attendance, by Remote Survey and without attendance.';
    default: return null;
  }
};

interface AttendanceProps {
  attendance?: boolean;
  remote?: boolean;
  withoutAttendance?: boolean;
}
export const IntroductionAttendanceText: FC<AttendanceProps> = ({ attendance, remote, withoutAttendance }) => {
  if (!attendance && !remote && !withoutAttendance) return null;
  return <AttendanceArea>{getAttendanceText(attendance, remote, withoutAttendance)}</AttendanceArea>;
};
