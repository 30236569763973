import { ChapterReference } from '@/types';
import styled from 'styled-components';
import { Widget as ChapterWidget } from '@/utils/manifestTypes';
import { PreviewReportPane } from '@/components/shared/Pane';
import { getUrlFriendlyString } from '@/utils/text';
import { Widget } from './Widget';

const ChapterContainer = styled.div`
  &:not(:empty) {
    padding: 0 30px 30px;
  }
  page-break-inside: avoid;
`;

interface ChapterProps extends ChapterReference {
  widgets: ChapterWidget[];
}

const getShowPlaceholder = (length: number, title: string) => {
  const emptyWidgets = length === 0;
  const skipWidget = (title === 'Damage Repairs') || (title === 'Repair Time');
  return emptyWidgets && !skipWidget;
};

export const Chapter = ({ caseId, chapterId, title, widgets }: ChapterProps) => {
  const showPlaceholderPane = getShowPlaceholder(widgets.length, title);

  return (
    <ChapterContainer id={getUrlFriendlyString(title)}>
      {showPlaceholderPane && <PreviewReportPane title={title} caseId={caseId} chapterId={chapterId} />}
      {widgets.map((each, ix) => (
        <Widget
          key={each.widgetId || ix}
          {...each}
          caseId={caseId}
          chapterId={chapterId}
          title={title}
          widgetTitle={each.title}
          editable
        />
      ))}
    </ChapterContainer>
  );
};
