import styled from 'styled-components';
import { Update as UpdatesPageWidget } from 'insify-remote-component-loader';
import { useHistory } from 'react-router';
import { Page } from '@/components/layout/Page';
import { useAccount } from '@/components/authentication/AuthenticationProvider';

const ContentArea = styled.div`
  margin: 0 auto;
`;

const portal = {
  name: 'survey',
  displayName: 'Survey'
};

export const UpdatesPage = () => {
  const { user } = useAccount();
  const history = useHistory();

  return (
    <Page noScroll>
      <ContentArea>
        <UpdatesPageWidget account={user} history={history} portal={portal} />
      </ContentArea>
    </Page>
  );
};
