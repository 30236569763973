import {
  Case, Guid, UserInfo
} from '@/types';

export interface AccessFormValues {
  users: UserInfo[];
  primaryId?: Guid;
}

export type AccessDropdownOption = {
  selectedLabel: string;
  label: string;
  value: string;
};

export type SingleUserOption = {
  byName: AccessDropdownOption,
  byCompany: AccessDropdownOption
}

export type UserOptions = {
  byName: AccessDropdownOption[],
  byCompany: AccessDropdownOption[]
}

const sortStrings = (a: string, b: string) => (
  (a < b && -1) || (a > b && 1) || 0
);

const sortUsersByName = (users: UserInfo[]) => (
  users.sort((a, b) => sortStrings(a.displayName, b.displayName))
);

const sortUsersByCompany = (users: UserInfo[]) => (
  users.sort((a, b) => ((a.company === b.company)
    ? sortStrings(a.displayName, b.displayName)
    : sortStrings(a.company, b.company)
  ))
);

/**
 * Takes a list of users and returns { byName[], byCompany[] },
 * both of which are arrays of values to be used by an AccessDropdown.
 */
export const usersToOptions = (users: UserInfo[]): UserOptions => {
  const byName = sortUsersByName(users).map(user => ({
    selectedLabel: user.displayName,
    label: `${user.displayName}, ${user.company}`,
    value: user.id
  }));
  const byCompany = sortUsersByCompany(users).map(user => ({
    selectedLabel: user.company,
    label: `${user.company}, ${user.displayName}`,
    value: user.id
  }));
  return { byName, byCompany };
};

/**
 * Takes a single user and returns a single, flat set of { byName, byCompany }
 * values used by the AccessDropdown.
 */
export const singleUserToOption = (user: UserInfo): SingleUserOption => {
  const { byName, byCompany } = usersToOptions([user]);
  return ({
    byName: byName[0],
    byCompany: byCompany[0]
  });
};

export const createInitialValues = (report?: Case, surveyors?: UserInfo[]): AccessFormValues => {
  const selectedUsers = surveyors?.filter(surveyor => report?.users?.surveyors.includes(surveyor.id)) || [];
  return ({
    users: selectedUsers,
    primaryId: report?.users?.primarySurveyorId || ''
  });
};
