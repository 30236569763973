import { FunctionComponent } from 'react';
import {
  ButtonGroup, BoxButton, Divider
} from '@instech/components';
import { useModalContext } from './ModalContext';

export const ModalContentArea: FunctionComponent = props => {
  const { close } = useModalContext();
  return (
    <>
      {props.children}
      <Divider padding="0px" />
      <ButtonGroup alignRight>
        <BoxButton onClick={close}>Done</BoxButton>
      </ButtonGroup>
    </>
  );
};
