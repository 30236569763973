import useSWR from 'swr';

type ValidResourceKey =
  'introductionChapterText'
  | 'surveyLocationChapterText'
  | 'attendingRepresentativesText'
  | 'summaryOfTimeChapterText'
  | 'occurrenceChapterText'
  | 'simultaneousRepairsText'
  | 'lossOfHireTimeForWorkNotConcerningAverage';

export type StaticResourceDict = Record<ValidResourceKey, string>;

export const useStaticResources = () => useSWR<StaticResourceDict>('staticresources');

export const useStaticResource = (key: ValidResourceKey) => {
  const swr = useSWR<StaticResourceDict>('staticresources');

  return {
    error: swr.error,
    isValidating: swr.isValidating,
    data: swr.data ? swr.data[key] : undefined
  };
};
