import { ClaimOccurrenceOverview } from '@/types';
import { getFriendlyDate } from '@instech/components';
import { useField } from 'formik';
import styled from 'styled-components';
import { ClaimCheckbox } from './ClaimCheckbox';
import { ErrorLine, InstructionText } from './FieldComponents';

/* Couldn't find any easy way to properly handle scrolling just within
   the list of this table grid, without a regrettable conttent shit,
   so this is set up with a workaround that scrolls the entire grid
   element -- but stickies the header row and a bar at the bottom
   so that it looks like it is only scrolling the contents.
   Not sure if there's much to do about the position of the scrollbar
   in this context though.
*/

const ScrollWrapper = styled.div`
  overflow-y: auto;
  max-height: 320px;
  margin-top: 8px;
`;

const ListHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 70px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${props => props.theme.border.gray};
  background: ${props => props.theme.white};
  /* Make sure this component stays at the scroll top */
  position: sticky;
  z-index: 100;
  top: 0;
  & > div {
    padding: 8px 8px 0px;
    color: ${props => props.theme.marineBlue};
    font-size: 14px;
  }
`;

const ClaimsList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 70px;
  padding: 8px 0px;
`;

const Row = styled.div<{ disabled?: boolean }>`
  display: contents;
  & > div {
    opacity: ${props => props.disabled && '0.4'};
  }
`;

const RowCell = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  color: ${props => props.theme.marineBlue};
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

interface ClaimRowProps {
  claim: ClaimOccurrenceOverview;
}
const ClaimRow = ({ claim }: ClaimRowProps) => (
  <Row key={claim.id}>
    <RowCell>{claim.claimReference}</RowCell>
    <RowCell>{getFriendlyDate(claim.dateOfLoss)}</RowCell>
    <RowCell>{claim.interest}</RowCell>
    <Center>
      <ClaimCheckbox value={claim.id} />
    </Center>
  </Row>
);

interface InterestModalTableProps {
  claims: ClaimOccurrenceOverview[] | undefined;
  label: string;
}
const ClaimTableModal = ({ claims = [], label }: InterestModalTableProps) => {
  const [, { touched, error }] = useField<string[]>('selectedClaims');

  return (
    <>
      <InstructionText>{label}</InstructionText>
      <ScrollWrapper>
        <ListHeader>
          <div>Claim Number</div>
          <div>Date of Loss</div>
          <div>Interest</div>
          <Center>Select</Center>
        </ListHeader>
        <ClaimsList>
          {claims && claims.map(claim => (
            <ClaimRow key={claim.id} claim={claim} />
          ))}
        </ClaimsList>
      </ScrollWrapper>
      {touched && error && (<ErrorLine>{error}</ErrorLine>)}
    </>
  );
};

export default ClaimTableModal;
