import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { getFriendlyDate } from '@/utils/date';

const Source = styled.div`
  display: flex;
  color: ${props => props.theme.softBlue};
  width: 100%;
  justify-content: flex-end;
  font-size: 12px;
  padding-top: 5px;
`;

const Element = styled.div`
  padding: 0px 5px;
`;

interface SourceDetailsProps {
  name?: string;
  date?: string;
  included?: boolean;
}
export const SourceDetails: FunctionComponent<SourceDetailsProps> = ({ name, date, included }) => (
  <Source>
    {name && <Element>{name}</Element>}
    {date && <Element>{getFriendlyDate(date)}</Element>}
    {included && <Element>Included</Element>}
  </Source>
);
