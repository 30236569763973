import React, { createContext, useContext } from 'react';
import { PageLoader } from '@instech/components';
import { useUsersClaims } from '@/services/management/userManagementService';

export interface User {
  name?: string;
  email?: string;
}

interface ContextValue {
  isAuthenticated: boolean;
  user: User | null;
  logout: () => void;
}

const AuthenticationContext = createContext<ContextValue | undefined>(undefined);

export const AuthenticationProvider = ({ children }: { children?: React.ReactNode }) => {
  const { data, loading } = useUsersClaims();

  const value = React.useMemo(() => {
    if (!data) return {} as ContextValue;
    const userInfo: User = {
      name: data.claims?.find(i => i.type === 'name')?.value,
      email: data.claims?.find(i => i.type === 'email')?.value,
    };
    const logout = () => {
      const logoutUrl = data.claims?.find(i => i.type === 'bff:logout_url')?.value;
      if (logoutUrl) window.location.href = logoutUrl;
    };
    const login = () => {
      window.location.href = '/bff/login';
    };
    return { ...data, user: userInfo, isAuthenticated: true, logout, login } as ContextValue;
  }, [data]);

  if (data === null || loading) return <PageLoader />;
  return (
    <AuthenticationContext.Provider
      value={value}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAccount = () => useContext<ContextValue>(AuthenticationContext as React.Context<ContextValue>);
