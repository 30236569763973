import { Star } from '@instech/icons';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TableCell } from '../core/Components';

const IconButton = styled(TableCell)<{ starred?: boolean; }>`
  display: inline-block;
  padding: 8px;
  margin: -8px;
  cursor: pointer;
  & svg * {
    fill: ${props => props.starred ? 'currentColor' : 'transparent'};
    transition: fill 0.1s ease-in;
  }
  &:hover {
    color: ${props => props.theme.marineBlue75};
  }
`;

interface Props {
  starred?: boolean;
  onClick: () => any;
}
export const StarButton: FunctionComponent<Props> = ({ starred, onClick }) => (
  <TableCell center>
    <IconButton onClick={onClick} starred={starred}>
      <Star />
    </IconButton>
  </TableCell>
);
