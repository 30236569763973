import { FunctionComponent } from 'react';
import { useComments } from '@/components/pages/edit/widgets/Comments/commentsService';
import { Pane, PreviewReportPane } from '@/components/shared/Pane';
import { Comments } from './core/Comments';

interface ViewProps {
  widgetTitle: string;
  data?: {
    comment: string;
    includeInReport: boolean;
  }
}

interface PreviewProps {
  widgetTitle: string;
  caseId: string;
  chapterId: string;
  widgetId: string;
}

// TODO: Should these not render at all if empty?

export const CommentsView: FunctionComponent<ViewProps> = ({ widgetTitle, data }) => (
  <Pane title={widgetTitle || ''}>
    {data?.comment && <Comments comment={data.comment} />}
  </Pane>
);

export const CommentsPreview: FunctionComponent<PreviewProps> = ({ widgetTitle, caseId, chapterId, widgetId }) => {
  const { data } = useComments({ caseId, chapterId, widgetId, swrOptions: { suspense: true } });
  return (
    <PreviewReportPane title={widgetTitle || ''} caseId={caseId} chapterId={chapterId}>
      {data.includeInReport && <Comments comment={data.comment} />}
    </PreviewReportPane>
  );
};
