import styled from 'styled-components';
import { TimelineSegmentOccurrence } from '@/utils/timelineTypes';
import { concatString, isNullishOrWhitespace } from '@/utils/text';
import {
  Divider, ButtonGroup, BoxButton,
  TruncatingText
} from '@instech/components';
import { EventTimeView } from './core/EventTimeView';

const Section = styled.div`
  padding: 20px 12px;
`;

const Heading = styled.h3`
  color: ${props => props.theme.marineBlue};
  font-size: 14px;
  font-weight: bold;
  line-height: 15px;
  margin-top: 0px;
  margin-bottom: 12px;
`;

const Text = styled.p`
  color: ${props => props.theme.marineBlue};
  font-size: 16px;
  margin: 0px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface FooterProps {
  onEdit: () => any;
  onClose: () => any;
}
const FooterButtons = ({ onEdit, onClose }: FooterProps) => (
  <>
    <Divider />
    <Buttons>
      <ButtonGroup>
        <BoxButton inverted onClick={onEdit}>Edit time</BoxButton>
        <BoxButton onClick={onClose}>Done</BoxButton>
      </ButtonGroup>
    </Buttons>
  </>
);

interface ViewOccurrenceProps {
  event: TimelineSegmentOccurrence;
  onEdit: () => void;
  onClose: () => void;
  canEdit: boolean;
  isReport: boolean;
}
export const ViewOccurrence = ({ event, onEdit, onClose, canEdit, isReport }: ViewOccurrenceProps) => {
  const { name, dateOfLoss, timeOfLoss, timezoneId, location, charter, interests } = event.occurrenceDetails;
  const referenceText = concatString(interests.flatMap(x => x.claimReference) ?? []);
  return (
    <div>
      <Section>
        <Heading>Occurrence</Heading>
        <Text><TruncatingText text={name} /></Text>
      </Section>
      <Section>
        <Heading>Date of Loss</Heading>
        <EventTimeView
          RenderComponent={Text}
          date={dateOfLoss}
          timeOfDay={timeOfLoss}
          timezoneId={timezoneId ?? 'UTC'}
        />
      </Section>
      <Section>
        <Heading>Location</Heading>
        <Text><TruncatingText text={location} /></Text>
      </Section>
      <Section>
        <Heading>Charter</Heading>
        <Text>{charter !== '' ? charter : <em>Charter not specified</em>}</Text>
      </Section>
      <Section>
        <Heading>Status</Heading>
        <Text>{event.timeStatus}</Text>
      </Section>
      <Section>
        <Heading>Claim Reference</Heading>
        <Text>{isNullishOrWhitespace(referenceText) ? '-' : <TruncatingText text={referenceText ?? ''} />}</Text>
      </Section>
      {canEdit && (
        <FooterButtons onEdit={onEdit} onClose={onClose} />
      )}
    </div>
  );
};
