import styled from 'styled-components';

export const notStarted = 'Not started';
export const draft = 'Draft';
export const submitted = 'Public';

const GreyDot = styled.div`
  height: 10px;
  width: 10px;
  background-color: ${props => props.theme.lightGray};
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
`;

const YellowDot = styled(GreyDot)`
  background-color: ${props => props.theme.yellow};
`;

const GreenDot = styled(GreyDot)`
  background-color: ${props => props.theme.lightGreen};
`;

interface StatusDotProps {
  status: string;
}
export const StatusDot = ({ status }: StatusDotProps) => {
  if (status === draft) return <YellowDot />;
  if (status === submitted) return <GreenDot />;
  return <GreyDot />;
};
