import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'draft-js';
import styled from 'styled-components';
import { ImagesView } from './ImagesView';
import { getEditorState } from '../RichTextForm/utility';
import { customBlockStyleFn } from '../core/customStyles';
import { imageStatePropType } from '../../../../prop-types/custom-prop-types';

const TextView = styled.div`
  font-size: 16px;
`;

const RichTextView = ({ data }) => {
  const editorState = useMemo(() => getEditorState(data.text), [data.text]);
  return (
    <>
      <TextView>
        <Editor
          editorState={editorState}
          blockStyleFn={customBlockStyleFn}
          readOnly
        />
      </TextView>
      { data?.images && <ImagesView images={data.images} />}
    </>
  );
};

RichTextView.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string,
    images: imageStatePropType
  })
};

export { RichTextView };
