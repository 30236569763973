import { useEffect, useState } from 'react';
import { ExpenseFormType } from '@/types';
import styled from 'styled-components';
import { useDebounce } from '@/hooks/useDebounce';
import { calculateExpensesCostSummary, mapToExpenses } from './ExpenseListUtils';

const Indent = styled.div`
  display: flex;
  background: ${props => props.theme.background.primary};
  padding: 16px 20px;
  margin-bottom: 20px;
`;

const ItemWrapper = styled.div`
  width: 100%;
`;

const Title = styled.span`
  display: block;
  color: ${props => props.theme.marineBlue};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
`;

interface TextProps {
  isValid: boolean;
}
const Text = styled.span<TextProps>`
  display: block;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  color: ${props => props.isValid ? 'currentColor' : props.theme.red};
`;

interface SummaryItemProps {
  title: string;
  text: string;
  isValid: boolean;
}

const SummaryItem = ({ title, text, isValid }: SummaryItemProps) => (
  <ItemWrapper>
    <Title>{title}</Title>
    <Text isValid={isValid}>{text}</Text>
  </ItemWrapper>
);

const invalidNumberText = 'Some entries are not counted as they do not have a valid number';

interface ExpenseSummaryProps {
  expenses?: ExpenseFormType[],
  currency: string
}
export const ExpenseSummary = ({ expenses = [], currency }: ExpenseSummaryProps) => {
  const delayedExpenses = useDebounce(expenses, 300);
  const [costSummary, setCostSummary] = useState(calculateExpensesCostSummary(mapToExpenses(expenses)));
  useEffect(() => {
    const updatedSummary = calculateExpensesCostSummary(mapToExpenses(delayedExpenses));
    setCostSummary(updatedSummary);
  }, [delayedExpenses]);

  return (
    <Indent title={costSummary.isValid ? undefined : invalidNumberText}>
      <SummaryItem title={`SUM - ${currency}`} text={costSummary.totalCost} isValid={costSummary.isValid} />
      <SummaryItem title="Estimate" text={`${costSummary.estimate}%`} isValid={costSummary.isValid} />
      <SummaryItem title="Quoted / Verified" text={`${costSummary.quoted}%`} isValid={costSummary.isValid} />
      <SummaryItem title="Final" text={`${costSummary.final}%`} isValid={costSummary.isValid} />
    </Indent>
  );
};
