import { FunctionComponent } from 'react';
import { SummaryOfTimeGroup, SummaryOfTime } from '@/types';
import { ColumnWidgetWrapper } from '@/components/pages/edit/widgets/ColumnWidgetWrapper';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { SummaryTime } from '@instech/icons';
import { useSummaryOfTime } from '@/services/summaryoftimeService';
import { getOccurrencePrefixTitle } from '@/utils/text';
import {
  getTotalDays, getDurationDateRange, getStatus
} from './utility';
import { TimeSummaryCard } from '../../components/CostAndTimeSummary/ContentPane';
import { HeaderPane } from '../../components/CostAndTimeSummary/HeaderPane';
import { EditPane } from '../../core/EditPane';

const SummaryCard: FunctionComponent<SummaryOfTime> = props => {
  const caseId = useCaseIdFromUrl();
  return (
    <TimeSummaryCard key={props.id}
      title={getOccurrencePrefixTitle(props.title, props.isOccurrence)}
      contents={[
        { header: 'Time for repairs', content: `${getTotalDays(props.dryDock, props.afloat)} days` },
        { header: 'Date for repairs', content: getDurationDateRange(props.duration) }
      ]}
      status={getStatus(props)}
      to={`/case/${caseId}/edit/chapter/${props.chapterId}`}
    />
  );
};

const SummaryGroup = ({ title, timeSummaries }: SummaryOfTimeGroup) => (
  <EditPane title={title}>
    {timeSummaries.map(each => <SummaryCard key={each.id} {...each} />)}
  </EditPane>
);

interface TimeSummaryProps {
  title: string;
}
export const TimeSummary: FunctionComponent<TimeSummaryProps> = ({ title }) => {
  const { data } = useSummaryOfTime({ suspense: true }) as { data: SummaryOfTimeGroup[] };

  return (
    <ColumnWidgetWrapper>
      <HeaderPane text={title} icon={<SummaryTime />} />
      {data.map(item => <SummaryGroup key={item.chapterId} {...item} />)}
    </ColumnWidgetWrapper>
  );
};
