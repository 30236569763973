import { useRepairDescription } from '@/services/repairDescriptionServices';
import { useOccurrence } from '@/services/occurrenceServices';
import { Detail, DetailRow } from '@/components/pages/edit/components/OccurrenceDetails';
import { getFriendlyDate } from '@/utils/date';
import { concatString, getOccurrencePrefixTitle } from '@/utils/text';
import { ItemDescriptionTable } from '../../components/ItemDescriptionTable';
import { FullwidthWidgetWrapper } from '../FullwidthWidgetWrapper';
import { EditPane } from '../../core/EditPane';

interface Props {
  widgetId: string;
  title: string;
}
const RepairDescription = ({ title, widgetId }: Props) => {
  const [item, update] = useRepairDescription(widgetId);
  const { data: occurrence } = useOccurrence(item?.occurrenceId, { suspense: true });
  const occurrenceTitle = getOccurrencePrefixTitle(title, true);

  const submitRepair = async (newRepair: any) => {
    const newItem = { ...item, repairs: newRepair };
    await update(newItem);
  };

  const deleteRepair = async (itemArrayIndex: number) => {
    const newItem = { ...item, repairs: item?.repairs?.filter((_e, index) => index !== itemArrayIndex) ?? [] };
    await update(newItem);
  };

  return (
    <FullwidthWidgetWrapper>
      <EditPane title={occurrenceTitle}>
        <DetailRow>
          <Detail title="Date of loss" text={getFriendlyDate(occurrence?.dateOfLoss)} />
          <Detail title="Claim reference" text={concatString(occurrence?.interests.map(x => x.claimReference))} />
          <Detail title="Location" text={occurrence?.location} />
        </DetailRow>
        <ItemDescriptionTable
          formId={`widget:${widgetId}-damage`}
          data={item?.repairs || []}
          addLabel="Repair"
          submit={submitRepair}
          onDelete={deleteRepair}
        />
      </EditPane>
    </FullwidthWidgetWrapper>
  );
};

export { RepairDescription };
