import {
  Duration, Guid, LabelValuePair, SurveyLocation
} from '@/types';
import { getLabelValuePairs } from '@/components/shared/Form/fields/Dropdown/OccurrenceSelect';
import deepEqual from 'deep-equal';

export interface LocationForm {
  id: string;
  location: string;
  duration: Duration;
  occurrences: LabelValuePair[];
  report?: boolean;
}

export interface LocationTableForm {
  locations: LocationForm[];
}

export const locationModel = (id: Guid): LocationForm => ({
  id,
  location: '',
  duration: { startDate: null, endDate: null },
  occurrences: [],
  report: false
});

// Create initial state of existing entries or one new empty entry
export const setInitialState = (locations: SurveyLocation[], initialElementId: Guid) => {
  if (!locations.length) return [locationModel(initialElementId)];
  return locations.map(location => ({ ...location, occurrences: getLabelValuePairs(location.occurrences) }));
};

export const deepEqualsDefaultModel = (location: SurveyLocation) => {
  const { id, ...defaultModel } = locationModel('');
  const { id: _id2, ...locationWithoutId } = location;
  return deepEqual(defaultModel, locationWithoutId);
};
