import { FullwidthWidgetWrapper } from '@/components/pages/edit/widgets/FullwidthWidgetWrapper';
import { FormIdProvider } from '@/components/shared/Form/core/useFieldId';
import { SaveButton } from '@/components/shared/FormButton/FormButton';
import { Pane } from '@/components/shared/Pane';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useStatusTypes } from '@/services/configurationServices';
import { useOccurrencesForCase } from '@/services/occurrenceServices';
import {
  Occurrence, TimeDetailsForm, claimInterests
} from '@/types';
import { getNumberOfDays } from '@/utils/date';
import { getOccurrencePrefixTitle } from '@/utils/text';
import {
  dateRangeOptionalStart,
  daysRequired,
  dropdownOptionRequired,
  stringMaxLength, stringRequiredMaxLength
} from '@/utils/validationSchemas';
import {
  ButtonGroup, Divider, FormikWithPrompt as Formik,
  Loader
} from '@instech/components';
import {
  Form,
  FormikErrors,
  FormikHelpers
} from 'formik';
import { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { EditPane } from '../../core/EditPane';
import { DurationFields } from './DurationFields';
import { SimultaneousRepairs } from './SimultaneousRepairs';
import { TableFields } from './TableFields';
import { mapToTimeDetails, mapToTimeDetailsForm } from './timeDetailUtils';
import { updateTimeDetailsAsync, useTimeDetails } from './timeDetailsService';

const durationSchema = dateRangeOptionalStart();

const daysSchema = Yup.object().shape({
  dryDock: daysRequired(),
  afloat: daysRequired()
});

const commentSchema = (includeFieldName: string) =>
  stringMaxLength(500)
    .when(includeFieldName, {
      is: true,
      then: stringRequiredMaxLength(500, 'Please add a comment')
    });

const simultaneousRepairsSchema = () =>
  Yup.object().shape({
    id: Yup.string(),
    days: daysRequired(),
    status: dropdownOptionRequired(),
    comment: commentSchema('includeCommentInReport'),
    includeCommentInReport: Yup.boolean()
  }).nullable();

/**
 * In order for TimeStatus to be able to validate properly, it needs to generate
 * the list of TimeStatus options as provided by the backend. See same setup
 * as shown in ExpenseList.jsx
 */
const createTimeDetailsSchema = () =>
  Yup.object().shape({
    duration: durationSchema,
    actualDays: daysSchema,

    durationComment: commentSchema('includeDurationComment'),
    includeDurationComment: Yup.boolean(),
    durationTimeStatus: dropdownOptionRequired(),

    separateDays: daysSchema,

    separateDaysDryDockComment: commentSchema('includeSeparateDaysDryDockComment'),
    includeSeparateDaysDryDockComment: Yup.boolean(),
    separateDaysDryDockTimeStatus: dropdownOptionRequired(),

    separateDaysAfloatComment: commentSchema('includeSeparateDaysAfloatComment'),
    includeSeparateDaysAfloatComment: Yup.boolean(),
    separateDaysAfloatTimeStatus: dropdownOptionRequired(),
    simultaneousRepairs: simultaneousRepairsSchema()
  });

function createInitialState(): TimeDetailsForm {
  const duration = { startDate: null, endDate: null };
  return {
    id: '',
    chapterId: '',
    title: '',
    duration,
    durationComment: '',
    includeDurationComment: false,
    durationTimeStatus: {
      label: 'Estimate',
      value: 'Estimate',
    },
    separateDays: {
      dryDock: 0,
      afloat: 0
    },
    separateDaysDryDockComment: '',
    includeSeparateDaysDryDockComment: false,
    separateDaysDryDockTimeStatus: {
      label: 'Estimate',
      value: 'Estimate',
    },
    separateDaysAfloatComment: '',
    includeSeparateDaysAfloatComment: false,
    separateDaysAfloatTimeStatus: {
      label: 'Estimate',
      value: 'Estimate',
    },
    actualDays: {
      dryDock: 0,
      afloat: 0
    },
    simultaneousRepairs: null
  };
}

function getTotalDays(dryDock: number, afloat: number) {
  const days = dryDock + afloat;
  if (days < 0) return 0;
  return days;
}

function validateTotalDaysFields(
  values: TimeDetailsForm,
  setErrors: (errors: FormikErrors<TimeDetailsForm>) => void
): boolean {
  if (!values.duration?.endDate) return true; // The total duration has no value, so cannot validate the rest at this point
  if (!values.duration?.startDate) return true;

  const totalDays = values.duration ? getNumberOfDays(values.duration.startDate, values.duration.endDate) : null;
  const totalSeparateDays = getTotalDays(values.separateDays.dryDock, values.separateDays.afloat);
  const totalActualDays = getTotalDays(values.actualDays.dryDock, values.actualDays.afloat);

  const errors = {} as any;
  const actualDaysOk = totalDays && totalActualDays <= totalDays;
  if (!actualDaysOk) {
    const message = 'Total actual days exceed total duration';
    errors.actualDays = {
      afloat: message,
      dryDock: message
    };
  }

  const separateDaysOk = totalDays && totalSeparateDays <= totalActualDays;
  if (!separateDaysOk) {
    const message = 'Total separate days exceed total actual days';
    errors.separateDays = {
      afloat: message,
      dryDock: message
    };
  }
  if (separateDaysOk && actualDaysOk) {
    return true;
  }
  setErrors(errors);
  return false;
}

const validateSimultaneousRepairsDaysField = (values: TimeDetailsForm, setErrors: (errors: FormikErrors<TimeDetailsForm>) => void) => {
  if (!values.simultaneousRepairs?.days) {
    return true;
  }
  const { days } = values.simultaneousRepairs;

  const totalSeparateDays = getTotalDays(values.separateDays.dryDock, values.separateDays.afloat);

  const daysOk = days && days <= totalSeparateDays;

  if (daysOk) return true;

  const errors = {
    simultaneousRepairs: {
      days: 'Number of days cannot exceed the total days of "if carried out separately"'
    }
  } as unknown as FormikErrors<TimeDetailsForm>;

  setErrors(errors);
  return false;
};

const validateDays = (values: TimeDetailsForm, setErrors: (errors: FormikErrors<TimeDetailsForm>) => void) => {
  const isValidTotalDays = validateTotalDaysFields(values, setErrors);
  const isValidSimultaneousRepairsDays = validateSimultaneousRepairsDaysField(values, setErrors);

  return isValidTotalDays && isValidSimultaneousRepairsDays;
};

const getShowSimultaneousRepairs = (initialData: TimeDetailsForm, occurrences: Occurrence[]) => {
  // Only the Work not concerning average chapter has the simultaneousRepairs property set.
  const isWorkNotConcerningAverage = initialData.simultaneousRepairs != null;
  // The SimultaneousRepairs form should only be displayed in LOH cases.
  const interests = occurrences.flatMap(x => x.interests.flatMap(i => i.claimInterest));
  const isLossOfHireCase = interests.some(o => o.includes(claimInterests.lossOfHire));

  return isWorkNotConcerningAverage && isLossOfHireCase;
};

interface Props {
  title: string;
  widgetId: string;
}
export const TimeDetails: FunctionComponent<Props> = ({ title, widgetId }) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const caseId = useCaseIdFromUrl();
  const { chapterId } = useParams<{ chapterId: string }>();
  const { data, mutate } = useTimeDetails(caseId, chapterId, widgetId, { suspense: true });
  const { data: occurrences } = useOccurrencesForCase(caseId);
  const { data: statusTypes } = useStatusTypes();
  const initialData = data === undefined ? createInitialState() : mapToTimeDetailsForm(data);
  const ownersWork = !data?.occurrenceId;

  const computedTitle = getOccurrencePrefixTitle(title, data?.occurrenceId);

  const onSubmitHandler = async (values: TimeDetailsForm, helpers: FormikHelpers<TimeDetailsForm>) => {
    const isValid = validateDays(values, helpers.setErrors);
    if (!isValid) return;

    const payload = mapToTimeDetails(values);

    try {
      await mutate(payload, false);
      const newValues = await updateTimeDetailsAsync({ caseId, chapterId, widgetId, timeDetails: payload });
      await mutate(newValues, false);
      // after successful post, reset form
      helpers.resetForm({ values });
    } catch (err) {
      setError(err as string);
    }
  };
  if (error) {
    throw new Error(error);
  }

  // Stall loading form until status types and occurrences are present
  if (!statusTypes || !occurrences) return <Loader />;

  // Only 'Work not Concerning Average' has a report preview component at this time.
  // Do not show link to the report preview for other widgets.
  const isWorkNotConcerningAverage = initialData.simultaneousRepairs != null;
  const PaneComponent = isWorkNotConcerningAverage ? EditPane : Pane;

  const showSimultaneousRepairs = getShowSimultaneousRepairs(initialData, occurrences);

  const formModelSchema = createTimeDetailsSchema();

  return (
    <FullwidthWidgetWrapper>
      <PaneComponent title={computedTitle} margin="0px 0px 30px">
        <Formik
          formId={widgetId}
          initialValues={initialData}
          validationSchema={formModelSchema}
          onSubmit={onSubmitHandler}
        >
          {({ dirty, handleSubmit, values, isSubmitting }) => {
            const totalSeparateDays = getTotalDays(values.separateDays.dryDock, values.separateDays.afloat);
            const totalActualDays = getTotalDays(values.actualDays.dryDock, values.actualDays.afloat);
            return (
              <Form>
                <FormIdProvider formId={widgetId}>
                  <DurationFields totalActualDays={totalActualDays} ownersWork={ownersWork} />
                  <TableFields totalSeparateDays={totalSeparateDays} />
                  {showSimultaneousRepairs && <SimultaneousRepairs />}
                  <Divider />
                  <ButtonGroup alignRight>
                    <SaveButton
                      isSubmitting={isSubmitting}
                      disabled={!dirty}
                      onClick={handleSubmit}
                    />
                  </ButtonGroup>
                </FormIdProvider>
              </Form>
            );
          }}
        </Formik>
      </PaneComponent>
    </FullwidthWidgetWrapper>
  );
};
