import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Pane, PreviewReportPane } from '@/components/shared/Pane';
import { useSurveyLocations } from '@/services/locationServices';
import { Loader } from '@instech/components';
import { SurveyLocation } from '@/types';
import { useStaticResource } from '@/services/staticResourcesServices';
import { LocationTable } from './Components';

const InfoText = styled.div`
  margin-bottom: 20px;
`;
interface PreviewProps {
  title: string;
  caseId: string;
  chapterId: string;
}

export const SurveyLocationsReportPreview: FunctionComponent<PreviewProps> = ({ title, caseId, chapterId }) => {
  const { data: locations } = useSurveyLocations();
  const { data: infoText } = useStaticResource('surveyLocationChapterText');

  if (!locations || !infoText) return <Loader />;

  const reportLocations = locations.filter(location => location.report);

  return (
    <PreviewReportPane title={title} caseId={caseId} chapterId={chapterId}>
      <InfoText>
        {infoText}
      </InfoText>
      <LocationTable locations={reportLocations} />
    </PreviewReportPane>
  );
};

interface ViewProps {
  title: string;
  data: {
    items: SurveyLocation[];
    infoText?: string;
  };
}

export const SurveyLocationsReportView: FunctionComponent<ViewProps> = ({ title, data }) => {
  // Fallback text for backward compatibility
  const { data: fallbackText } = useStaticResource('surveyLocationChapterText');

  return (
    <Pane title={title}>
      <InfoText>
        {data.infoText || fallbackText}
      </InfoText>
      <LocationTable locations={data.items} />
    </Pane>
  );
};
