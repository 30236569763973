import { useState } from 'react';
import styled from 'styled-components';
import { Change } from '@instech/icons';
import { Pane } from '@/components/shared/Pane';
import { useImageList } from '@/services/imageServices';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { Loader, IconButton } from '@instech/components';
import { hiddenOnPrint } from '@/utils/styles';
import { ImagePreview } from './core/ImagePreview';
import { UploadForm } from './core/UploadForm';

interface WrapperProps {
  readonly readonly: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  padding: ${props => props.readonly ? '0 30px 30px' : '0 0 30px 0'};
  margin-top: 16px;
`;

const PaneTitle = styled.span`
  ${hiddenOnPrint};
`;

const StyledPane = styled(Pane)<{hideOnPrint?: boolean}>`
  ${props => props.hideOnPrint && hiddenOnPrint};
`;

interface FrontpageImageUploaderProps {
  readonly?: boolean;
}
export const FrontpageImageUploader = ({ readonly = false }: FrontpageImageUploaderProps) => {
  const [showUploadForm, setShowUploadForm] = useState(false);
  const caseId = useCaseIdFromUrl();
  const { images } = useImageList(caseId, ['Frontpage']);

  if (!images) {
    return <Loader />;
  }

  const hasUploadImage = images.length !== 0;

  const newImageButton = <IconButton icon={<Change />} onClick={() => setShowUploadForm(true)} />;

  const currentImageId = hasUploadImage ? images[images.length - 1] : undefined;

  const uploadFormIsVisible = (showUploadForm || !hasUploadImage) && !readonly;

  return (
    <Wrapper readonly={readonly}>
      <StyledPane
        title={<PaneTitle>Frontpage image</PaneTitle>}
        button={(!hasUploadImage || showUploadForm || readonly) ? undefined : newImageButton}
        hideOnPrint={uploadFormIsVisible}
      >
        {(uploadFormIsVisible) ?
          (
            <UploadForm
              caseId={caseId}
              currentImageId={currentImageId}
              handleClose={() => setShowUploadForm(false)}
            />
          )
          : (
            <ImagePreview
              imageId={currentImageId ?? ''}
              readonly={readonly}
              onErrorDismiss={() => setShowUploadForm(false)} />
          )}
      </StyledPane>
    </Wrapper>
  );
};
