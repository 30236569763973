import { getFriendlyDate } from '@/utils/date';
import { SurveyLocation } from '@/types';

export const createTitle = (name: string, startDate: string | null, endDate: string | null) => {
  const endDateString = (endDate && endDate !== startDate) ? ` - ${getFriendlyDate(endDate)}` : '';

  return (
    `${name} (${getFriendlyDate(startDate)}${endDateString})`
  );
};

export const extractTitles = ({ duration, location, occurrences }: SurveyLocation) => {
  const title = createTitle(location, duration.startDate, duration.endDate);
  const subTitle = `Affecting occurrence${(occurrences.length > 1 ? 's' : '')}: ${occurrences.map(x => x.name).join(' | ')}`;

  return {
    title,
    subTitle
  };
};
