import {
  Duration, SummaryOfTime, TimeStatus
} from '@/types';
import moment from 'moment';
import { getNumberOfDays } from '@/utils/date';

const formatDays = (days: number | null) => {
  const dayText = days === 1 ? 'day' : 'days';
  return days ? `${days} ${dayText}` : '-';
};

const getNotConcerningAvg = (summary: SummaryOfTime[]) => {
  const summaryItem = summary.find(item => item.title === 'Time for work not concerning average');
  const duration = summaryItem?.duration;
  const days = getNumberOfDays(duration?.startDate, duration?.endDate);
  return formatDays(days);
};

// Compare two dates and return the latest date
const selectLastDate = (date1: string | null, date2: string | null) => {
  if (!date2) {
    return date1;
  }
  if (!date1) {
    return null;
  }
  const isAfter = moment.utc(date1).isSameOrAfter(moment.utc(date2));
  return isAfter ? date1 : date2;
};

// Compare two dates and return the earliest date
const selectFirstDate = (date1: string | null, date2: string | null) => {
  if (!date2) {
    return date1;
  }
  if (!date1) {
    return null;
  }
  const isBefore = moment.utc(date1).isSameOrBefore(moment.utc(date2));
  return isBefore ? date1 : date2;
};

const isInvalidSummaryItems = (summary: SummaryOfTime[]) => {
  const isValidItem = (item: SummaryOfTime) => {
    if (item.duration.startDate === null) return false;
    if (item.duration.endDate === null) return false;
    return true;
  };
  const validItems = summary.filter(isValidItem);

  return validItems.length !== summary.length;
};

// Used to check if all durations in the summary is overlapping
// Return true if all durations overlap and return false if there is a gap between any of the durations.
const isOverlappingSummaryItems = (summary: SummaryOfTime[]) => {
  const sortByStartDate = (a: Duration, b: Duration) => {
    if (moment.utc(a.startDate).isSame(moment.utc(b.startDate))) {
      return 0;
    }
    return moment.utc(a.startDate).isAfter(moment.utc(b.startDate)) ? 1 : -1;
  };

  // Sort all the intervals by accending start date
  const intervals = summary
    .map(item => item.duration)
    .sort(sortByStartDate);

  // eslint-disable-next-line no-plusplus
  for (let index = 1; index < intervals.length; index++) {
    const current = intervals[index];
    const previous = intervals[index - 1];

    // If there is a gap between the current and the previous interval, return false.
    // While isAfter is the quickest way to do this, it runs into issues with days that are consecutive
    // but non-overlapping (e.g. endDate 13.05 and startDate 14.05). We want to cover those dates, so
    // let's cheese it by pretending the endDate is one day later (causing an overlap).
    const endDatePlusOne = moment.utc(previous.endDate).add(1, 'days');
    if (moment.utc(current.startDate).isAfter(endDatePlusOne)) {
      return false;
    }
  }
  return true;
};

const getRepairTime = (summary: SummaryOfTime[]) => {
  const summaryItems = summary.filter(item => item.isOccurrence);

  // Base case: 1 duration
  if (summaryItems.length === 1) {
    const { duration } = summaryItems[0];
    const days = getNumberOfDays(duration.startDate, duration.endDate);
    return formatDays(days);
  }

  // If any of the durations are missing start date or end date
  if (isInvalidSummaryItems(summaryItems)) {
    return formatDays(null);
  }

  // If all durations in the summary is overlapping
  if (isOverlappingSummaryItems(summaryItems)) {
    const minStartDate = summaryItems
      .reduce<string | null>((accumulator, item) => selectFirstDate(item.duration.startDate, accumulator), null);
    const maxEndDate = summaryItems
      .reduce<string | null>((accumulator, item) => selectLastDate(item.duration.endDate, accumulator), null);

    const days = getNumberOfDays(minStartDate, maxEndDate);
    return formatDays(days);
  }

  // If there are any gaps between any of the durations in the summary
  return 'Multiple';
};

// Return the 'lowest' status in any of the summary items, considering all status fields in each item
const getStatus = (summary: SummaryOfTime[]) => {
  const allStatuses = [] as TimeStatus[];

  const summaryItems = summary.filter(item => item.isOccurrence);
  summaryItems.forEach(item => allStatuses.push(item.durationStatus, item.dryDockStatus, item.afloatStatus));

  const set = new Set<TimeStatus>(allStatuses);
  if (set.has('Estimate')) return 'Estimate';
  if (set.has('Quoted / Verified')) return 'Quoted / Verified';
  return 'Final';
};

export const getTimeEstimateValues = (summary: SummaryOfTime[]) => ({
  notConcerningAvg: getNotConcerningAvg(summary),
  repairTime: getRepairTime(summary),
  status: getStatus(summary)
});
