import { Guid, UserInfo } from '@/types';
import useSWR, { mutate } from 'swr';
import { AxiosError } from 'axios';
import client from './client';

const baseUrl = (caseId: string) => `/cases/${caseId}/surveyors`;

type AllowedSurveyors = {
  surveyors: UserInfo[];
}

export const useAllowedSurveyors = (caseId: string) => {
  const url = `${baseUrl(caseId)}/allowed`;
  const { data, error } = useSWR<AllowedSurveyors>(url, {
    suspense: true
  });

  if (error) {
    throw error;
  }

  return data!.surveyors;
};

export type AssignedSurveyorsInCase = {
  surveyors: UserInfo[]
  primarySurveyorId: Guid;
};

export const useAssignedSurveyors = (caseId: string) => {
  const url = `${baseUrl(caseId)}/assigned`;
  const { data, error } = useSWR<AssignedSurveyorsInCase>(url, {
    suspense: true
  });

  if (error) {
    throw error;
  }

  const surveyorsInCase = data!;
  const primarySurveyor = surveyorsInCase
    .surveyors
    .find(surveyor => surveyor.id === surveyorsInCase.primarySurveyorId)
    ?? surveyorsInCase.surveyors[0];
  const surveyorsWithoutPrimary = surveyorsInCase
    .surveyors
    .filter(surveyor => surveyor.id !== surveyorsInCase.primarySurveyorId);

  const surveyorsWithPrimaryFirst: AssignedSurveyorsInCase = {
    primarySurveyorId: surveyorsInCase.primarySurveyorId,
    surveyors: [primarySurveyor, ...surveyorsWithoutPrimary]
  };

  return surveyorsWithPrimaryFirst;
};

export type UpdateAssignedSurveyors = {
  primarySurveyorId: Guid;
  surveyors: Guid[];
}

export const reassignSurveyors = async (caseId: string, caseReassignments: UpdateAssignedSurveyors) => {
  const url = `${baseUrl(caseId)}/assigned`;
  let response;
  try {
    response = await client.put(url, caseReassignments);
  } catch (error) {
    return (error as AxiosError).response!;
  }
  await mutate(url);
  return response;
};
