import { roles } from '@/components/AppRouting/roles';
import { useCaseStatus } from '@/services/caseStatusServices';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import { ImagesReadonlyView } from './ImagesReadonlyView';
import { ImagesEditView } from './ImagesEditView';

const ImagesRoleResolver = () => {
  const role = useCurrentRole();
  const { data: status } = useCaseStatus(true);

  if (!status?.isClosed && role?.name === roles.surveyor) {
    return <ImagesEditView />;
  }

  return <ImagesReadonlyView />;
};

export const ImagesTool = () => <ImagesRoleResolver />;

export const openImagesTool = {
  component: ImagesTool,
  options: {
    title: 'Image library',
  },
};
