import { ChapterReference } from '@/types';
import { Widget as ChapterWidget } from '@/utils/archivedReportTypes';
import styled from 'styled-components';
import { getUrlFriendlyString } from '@/utils/text';
import { Widget } from './Widget';

const ChapterContainer = styled.div`
  &:not(:empty) {
    padding: 0 30px 30px;
  }
  page-break-inside: avoid;
`;

interface ChapterProps extends ChapterReference {
  widgets: ChapterWidget[]
}
export const Chapter = ({ caseId, chapterId, title, widgets }: ChapterProps) => (
  <ChapterContainer id={getUrlFriendlyString(title)}>
    {
      widgets.map((each, ix) => (
        <Widget
          key={each.widgetId || ix}
          {...each}
          caseId={caseId}
          chapterId={chapterId}
          title={title}
          widgetTitle={each.title}
        />
      ))
    }
  </ChapterContainer>
);
