import { useState } from 'react';
import { OnboardingPageLayout } from '@/components/layout/OnboardingPageLayout/OnboardingPageLayout';
import { OnboardingText } from '@/components/layout/OnboardingPageLayout/Text';
import { OnboardingTitle } from '@/components/layout/OnboardingPageLayout/Title';
import styled from 'styled-components';
import { SaveButton } from '@/components/shared/FormButton/FormButton';
import { acceptTermsOfUseCurrentUserAsync, mutateCurrentUserInfoAsync } from '@/services/userInfoService';
import { useModalContext } from '@/components/modal/ModalContext';
import { showTermsOfUseModal } from '../profile/widgets/TermsOfUse/TermsOfUse';

const termsOfUseIntroductionText = 'Before starting to use the portal, please read and agree to the terms of use.';

const TextLink = styled.div`
  font-size: 16px;
  display: block;
  color: ${props => props.theme.deepAzureBlue};
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 30px;
  line-height: 28px;
`;

const acceptButtonText = 'I ACCEPT THE TERMS OF USE';

const AcceptButton = ({ submitting, onClick }: { submitting: boolean, onClick: () => void }) => (
  <SaveButton
    label={acceptButtonText}
    title={acceptButtonText}
    isSubmitting={submitting}
    onClick={onClick}
    padding="15px 40px"
    noIcon
    fontSize="14px"
  />
);

export const AcceptTermsPage = () => {
  const [submitting, setSubmitting] = useState(false);
  const { open } = useModalContext();

  const handleAccept = async () => {
    const result = await acceptTermsOfUseCurrentUserAsync();
    if (result.status === 200) {
      setSubmitting(false);
      void mutateCurrentUserInfoAsync(result.data, false);
    }
  };

  return (
    <OnboardingPageLayout>
      <OnboardingTitle>Terms of Use</OnboardingTitle>
      <OnboardingText>{termsOfUseIntroductionText}</OnboardingText>

      <TextLink onClick={() => open(showTermsOfUseModal)}>Link to Terms of Use</TextLink>

      <AcceptButton submitting={submitting} onClick={handleAccept} />
    </OnboardingPageLayout>
  );
};
