import { FC } from 'react';
import { Form, Formik } from 'formik';
import { UserPicker } from '@instech/components';
import { useModalContext } from '@/components/modal/ModalContext';
import { ModalFormButtons } from '@/components/shared/FormButton';
import { useAllowedUserInfoList } from '@/services/userInfoService';
import { AreaLoader } from '@/components/shared/Loader';
import { UserInfo } from '@/types';
import { selectClaimsHandlerValidationSchema } from './validationSchema';

type OnSelectCallback = (users: UserInfo) => void;

interface Props {
  claimId: string;
  role: string;
  selectedUser: UserInfo;
  onSelect: OnSelectCallback;
}
const SelectClaimsHandlerModal: FC<Props> = ({ claimId, role, selectedUser, onSelect }) => {
  const users = useAllowedUserInfoList(role, claimId);
  const { close } = useModalContext();

  const onCompletion = (user: UserInfo) => {
    onSelect(user);
    close();
  };

  if (!users) {
    return <AreaLoader />;
  }

  return (
    <div data-testid="users-input-modal">
      <Formik
        initialValues={{ claimsHandler: { ...selectedUser, fullName: selectedUser.displayName } }}
        validationSchema={selectClaimsHandlerValidationSchema}
        onSubmit={vals => onCompletion(vals.claimsHandler)}>
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UserPicker name="claimsHandler" options={users} />
            <ModalFormButtons
              onSave={handleSubmit}
              onCancel={close}
              isPending={isSubmitting}
              isDisabled={!dirty || isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const showSelectClaimsHandlerModal = (
  onSelect: OnSelectCallback,
  claimId: string,
  role: string,
  selectedUser?: UserInfo
) => ({
  component: SelectClaimsHandlerModal,
  options: {
    title: 'Select Primary Claims Handler',
    padding: '20px',
    footer: true
  },
  args: {
    onSelect,
    selectedUser,
    claimId,
    role
  }
});
