import styled from 'styled-components';
import { Load } from '@instech/icons';

export const DropdownOuter = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid;
  border-color: ${props => props.theme.border.gray};
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
`;

const DropdownInner = styled.div`
  display: flex;
  position: relative;
  height: 38px;
  align-items: center;
  &::after {
    content: '';
    position: absolute;
    height: 0.813em;
    width: 0.813em;
    top: 0.625em;
    border-style: solid;
    border-color: ${props => props.theme.marineBlue};
    border-width: 0.1em 0.1em 0 0;
    box-sizing: border-box;
    transform: rotate(135deg);
    right: 15px;
    pointer-events: none;
  }

  > svg {
    display: block;
    padding-left: 16px;
    max-height: 14px;
    max-width: 70px;
  }
`;

const DropdownLoader = () => (
  <DropdownOuter>
    <DropdownInner>
      <Load />
    </DropdownInner>
  </DropdownOuter>
);

export default DropdownLoader;
