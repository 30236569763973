import {
  SummaryOfTime, Duration, TimeStatus
} from '@/types';
import { getFriendlyDate } from '@/utils/date';

const getDateRange = (startDate?: string | null, endDate?: string | null) => {
  const startDateString = startDate ? getFriendlyDate(startDate) : '';
  const endDateString = (endDate && startDate !== endDate) ? getFriendlyDate(endDate) : '';

  if (startDateString && endDateString) {
    return `${startDateString} - ${endDateString}`.trim();
  }
  return `${startDateString}${endDateString}`.trim();
};

export const getDurationDateRange = ({ startDate, endDate }: Duration) =>
  getDateRange(startDate, endDate);

export const getTotalDays = (dryDock: number, afloat: number) => {
  const days = dryDock + afloat;
  if (days < 0) return 0;
  return days;
};

export const getStatus = ({ durationStatus, dryDockStatus, afloatStatus }: SummaryOfTime): TimeStatus => {
  const set = new Set<TimeStatus>([durationStatus, dryDockStatus, afloatStatus]);
  if (set.has('Estimate')) return 'Estimate';
  if (set.has('Quoted / Verified')) return 'Quoted / Verified';
  return 'Final';
};
