/* eslint-disable no-console */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { Trash } from '@instech/icons';
import { TextField } from '@instech/components';
import {
  TableRow, ExpandedDetailRow, TableCell, CellButton, ArrowButton
} from '@/components/shared/Table';
import { RichText } from '@/components/shared/Form/RichTextForm/RichText';
import { ChevronToggle } from '@/components/shared/Chevron';
import { openConfirmDeleteDialog } from '@/components/shared/ConfirmDeleteDialog';
import { useModalContext } from '@/components/modal/ModalContext';

export const ItemDescription = ({ form, arrayId, length, number, setFieldValue, onDelete, onMoveDown, onMoveUp, disableDelete }) => {
  const [open, setOpen] = useState(false);
  const { open: openModal } = useModalContext();
  const textFieldName = `${arrayId}.details.text`;
  const imagesFieldName = `${arrayId}.details.images`;

  const even = number % 2 === 0;
  const first = number === 0;
  const last = number + 1 === length;
  const disabled = length === 1;

  const arrowUpHandler = evt => {
    evt.stopPropagation();
    onMoveUp();
  };

  const arrowDownHandler = evt => {
    evt.stopPropagation();
    onMoveDown();
  };

  return (
    <>
      <TableRow even={even}>
        <TableCell center ticker>
          <ArrowButton
            first={first}
            last={last}
            disabled={disabled}
            onClickUp={arrowUpHandler}
            onClickDown={arrowDownHandler}
          />
        </TableCell>
        <TableCell showError>
          <TextField
            name={`${arrayId}.description`}
            aria-labelledby="damageheader-description"
            placeholder="Title"
            noLabel
          />
        </TableCell>
        <TableCell button>
          <CellButton
            type="button"
            onClick={() => setOpen(!open)}
            icon={<ChevronToggle big thin open={open} />}
          />
        </TableCell>
        <TableCell button right>
          <CellButton
            type="button"
            lineLeft
            smallIcon
            icon={<Trash />}
            onClick={!disableDelete
              ? () => openConfirmDeleteDialog({
                name: undefined,
                callback: onDelete,
                open: openModal
              })
              : undefined}
            disabled={disableDelete}
          />
        </TableCell>
      </TableRow>
      <ExpandedDetailRow isOpen={open}>
        <TableCell span={4}>
          <RichText
            onRichTextChange={value => setFieldValue(textFieldName, value)}
            richText={getIn(form.values, textFieldName)}
            onImageChange={value => setFieldValue(imagesFieldName, value)}
            imageState={getIn(form.values, imagesFieldName)}
            useImages
          />
        </TableCell>
      </ExpandedDetailRow>
    </>
  );
};
// TODO: Define Damage & repair prop type?
ItemDescription.propTypes = {
  form: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  arrayId: PropTypes.string,
  number: PropTypes.number,
  length: PropTypes.number,
  setFieldValue: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveDown: PropTypes.func,
  onMoveUp: PropTypes.func,
  disableDelete: PropTypes.bool
};
