import { Introduction } from '@/types';
import useSWR, { mutate } from 'swr';
import client from '@/services/client';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useCase } from '@/services/dashboardServices';
import { useStaticResource } from '@/services/staticResourcesServices';

const introductionUrl = (caseId: string) => `case/${caseId}/introduction`;

export const useIntroduction = (caseId: string) => {
  const swrRoute = introductionUrl(caseId);

  const { data, error } = useSWR<Introduction>(swrRoute);

  if (error) {
    throw error;
  }

  return data;
};

export const updateIntroductionAsync = async (caseId: string, introduction: Introduction): Promise<Introduction> => {
  const url = introductionUrl(caseId);
  const result = await client.put<Introduction>(url, introduction);
  await mutate(url, result.data);
  return result.data;
};

export const useIntroductionInfoText = () => {
  const caseId = useCaseIdFromUrl();
  const { data: report, error: reportError } = useCase(caseId, true);
  const { data: infoTemplate, error: resourceError } = useStaticResource('introductionChapterText');

  if (!report || !infoTemplate) {
    return { data: undefined, isValidating: true };
  }
  if (reportError || resourceError) {
    return { error: reportError || resourceError, isValidating: false };
  }

  const interestText = '[interest coming here]';
  // vesselName was recently added to CaseMetadata so this is to ensure backwards compatability
  const vesselName = report.metadata.vesselName ?? 'above-mentioned vessel';

  const infoWithData = infoTemplate
    .replace('{0}', interestText)
    .replace('{1}', vesselName);

  return {
    data: infoWithData,
    isValidating: false
  };
};
