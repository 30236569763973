import {
  FunctionComponent, useEffect, useState
} from 'react';
import styled from 'styled-components';
import {
  Loader, IconButton, Divider
} from '@instech/components';
import { InfoCircle } from '@instech/icons';
import { ChevronToggle } from '@/components/shared/Chevron';
import { RichTextView } from '@/components/shared/Form/RichTextView';
import { useInstructions } from '@/services/instructionsServices';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
`;

const InformationText = styled.div<{open: boolean}>`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.white};
  padding: 12px 24px 12px 12px;
  ${props => !props.open && 'box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06)'};;
  justify-content: space-between;
  flex-grow: 1;
`;

const LeftSection = styled.div`
  display: flex;
  height: 100%;
  align-items: center;

  & svg {
    flex-shrink: 0;
  }
`;

const ExpandedContent = styled.div`
  padding: 0 24px 12px 12px;
  background: ${props => props.theme.white};
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
  grid-row-start: 2;
`;

const Content = styled.div`
  margin: 3px 0px 5px 10px;
  font-size: 16px;
  font-weight: bold;
`;

interface Props {
  fallbackDescription: string;
  instructionReference: string | null;
  actionButton: JSX.Element | null;
}
export const DetailedInformationBox: FunctionComponent<Props> = ({ actionButton, instructionReference, fallbackDescription }) => {
  const [open, setOpen] = useState(false);
  const instructions = useInstructions(instructionReference);

  const instructionsLoading = instructionReference && !instructions;

  useEffect(() => {
    setOpen(false); // Reset 'open' when instructionReference change
  }, [instructionReference]);

  if (instructionsLoading) {
    return <Loader />;
  }

  const text = instructions?.description || fallbackDescription;
  const details = instructions?.details;

  return (
    <Container>
      <InformationText open={open}>
        <LeftSection>
          <InfoCircle />
          <Content>{text}</Content>
        </LeftSection>
        {details && (
        <IconButton
          icon={<ChevronToggle big thin open={open} />}
          onClick={() => setOpen(!open)}
          width="40px"
          height="32px"
        />
        )}
      </InformationText>
      {actionButton}
      { details && open && (
      <ExpandedContent>
        <Divider padding="0 5px 12px 0" />
        <RichTextView data={{ text: details }} />
      </ExpandedContent>
      ) }
    </Container>
  );
};
