import styled from 'styled-components';
import { Guid } from '@/types';
import { useCaseStatus } from '@/services/caseStatusServices';
import { useLightboxContext } from '@instech/components';
import { ImageCard } from '@/components/shared/Image/ImageCard';

const ImagesArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 16px;
`;

interface ImagesViewProps {
  images: Guid[];
}
export const ImagesView = ({ images }: ImagesViewProps) => {
  const { data: caseStatus } = useCaseStatus(false);
  const { open: openInLightbox } = useLightboxContext();

  if (!images || images.length <= 0) {
    return null;
  }
  return (
    <ImagesArea>
      {images.map(imageId => (
        <ImageCard
          key={imageId}
          id={imageId}
          hidePiiImages={caseStatus?.isClosed}
          openInLightbox={image => openInLightbox(image)}
        />
      ))}
    </ImagesArea>
  );
};
