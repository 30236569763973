import PropTypes from 'prop-types';
import { EditableTableRow } from '@/components/shared/Table';
import { ContactRowEdit } from './ContactRowEdit';
import { ContactRowView } from './ContactRowView';

export const ContactRow = ({ data, even, remove, caseId, isEditMode, toggleEditMode }) => (
  <EditableTableRow even={even} isEditMode={isEditMode}>
    {isEditMode ? (
      <ContactRowEdit
        data={data}
        toggleEditMode={toggleEditMode}
        caseId={caseId}
      />
    ) : (
      <ContactRowView
        contact={data}
        toggleEditMode={toggleEditMode}
        remove={remove}
      />
    )}
  </EditableTableRow>
);

ContactRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    function: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
  even: PropTypes.bool,
  remove: PropTypes.func,
  caseId: PropTypes.string,
  isEditMode: PropTypes.bool,
  toggleEditMode: PropTypes.func
};
