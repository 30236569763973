import {
  FunctionComponent, useEffect, useState
} from 'react';
import { Guid } from '@/types';
import { useModalContext } from '@/components/modal/ModalContext';
import { downloadReportPdf } from '@/services/fileServices';
import { Loader } from '@instech/components';
import { PdfNotReadyModal } from './PdfNotReadyModal';
import { PdfDoesNotExistModal } from './PdfDoesNotExistModal';

const invokeDownload = (url: string, name: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  if (link.parentNode) link.parentNode.removeChild(link);
};

interface FileDownloadProps {
  caseId: Guid;
  archivedId: Guid;
  callbackAsync?: () => void;
}

const FileDownloadModal: FunctionComponent<FileDownloadProps> = ({ caseId, archivedId, callbackAsync }) => {
  const { close } = useModalContext();
  const [error, setError] = useState<string|undefined>(undefined);

  // Is the Effect strictly necessary? It does prevent unintentional
  // re-fetches on component refresh, by only fetching upon mount
  useEffect(() => {
    /* Flag with loader while waiting for a response */
    const handleDownloadWithPending = async () => {
      let result;
      try {
        result = await downloadReportPdf(caseId, archivedId);
      } catch (err) {
        setError(err as string);
        return;
      }

      if (result?.status === 200) {
        if (callbackAsync) {
          await callbackAsync();
        }
        const { url, id } = result.data;
        invokeDownload(url, id);
        close();
      } else if (result?.status === 204) {
        // Media API will return 204 (No content) if the file does not exist in the storage account
        setError('File does not exist');
      } else {
        setError('Download of report failed');
      }
    };
    void handleDownloadWithPending();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error === 'File does not exist') {
    return <PdfDoesNotExistModal />;
  }

  if (error) {
    throw error;
  }

  return (
    <div>
      <Loader />
    </div>
  );
};

export const openDownloadPdf = (caseId: Guid, archivedId: Guid, pdfGenerated: boolean, callbackAsync?: () => void) => ({
  component: pdfGenerated ? FileDownloadModal : PdfNotReadyModal,
  options: {
    size: 'small'
  },
  args: {
    caseId,
    archivedId,
    callbackAsync
  }
});
