import { FunctionComponent, useState } from 'react';
import styled, { css } from 'styled-components';
import { PaneTitle, TabbedPaneContent } from './core/Components';
import { ToolPaneTab } from './types';

const Container = styled.div`
  display: grid;
  grid-template-rows: 41px auto;
  height: 100%;
`;

const TabContainer = styled.div<{ tabCount: number }>`
  display: flex;
  & > button {
    width: ${props => `calc(100% / ${props.tabCount ?? 1})`};
  }
`;

const HeaderButton = styled.button<{ $active: boolean }>`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  height: 41px;
  padding-left: 0px 12px;
  border: none;
  ${props => !props.$active && css`
    background: ${props.theme.white};
    border-bottom: 1px solid ${props.theme.marineBlue60};
    padding-bottom: 1px;
    cursor: pointer;
  `};
  ${props => props.$active && css`
    background: ${props.theme.lightBlue};
    border-bottom: 2px solid ${props.theme.marineBlue};
  `}
`;

interface ToolPaneProps {
  tabs: [ToolPaneTab, ...ToolPaneTab[]];
}
/**
 * Renders a ToolPane component with togglable tabs.
 */
export const TabbedToolPane: FunctionComponent<ToolPaneProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].key);

  return (
    <Container>
      <TabContainer tabCount={tabs.length}>
        {tabs.map(tab => (
          <HeaderButton key={tab.key} onClick={() => setActiveTab(tab.key)} $active={tab.key === activeTab}>
            <PaneTitle>{tab.title}</PaneTitle>
          </HeaderButton>
        ))}
      </TabContainer>
      {tabs.map(tab => (
        <TabbedPaneContent tab={tab} isActive={tab.key === activeTab} key={tab.key} />
      ))}
    </Container>
  );
};
