/* eslint-disable react/jsx-props-no-spreading */
import { Pane } from '@/components/shared/Pane';
import { useCase } from '@/services/dashboardServices';
import { PageLoader, WarningNotification } from '@instech/components';
import { useAccount } from '@/components/authentication/AuthenticationProvider';
import { useNewestReport } from '@/services/versionsService';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { getSurveyorPageTitle } from '@/components/shared/Text';
import styled from 'styled-components';
import { VersionTable } from './reportVersions/VersionTable';
import { CaseInformationContainer } from './caseInformation/CaseInformationContainer';
import { DraftNavigationContainer } from './core/DraftNavigationContainer';
import { DashboardBasePage } from './core/DashboardBasePage';
import { ToolsAndFeedback } from './core/ToolsAndFeedback';

const WarningContentArea = styled.div`
  margin-top: 32px;
`;

export const SurveyorDashboardPage = () => {
  const caseId = useCaseIdFromUrl();
  const { data: reportCase } = useCase(caseId);
  const { data: report } = useNewestReport(caseId);
  const { user: { name } } = useAccount();
  const infoText = `Welcome, ${name}`;

  if (!reportCase) {
    return <PageLoader />;
  }

  if (reportCase.userCannotAccessBehindWall) {
    return (
      <DashboardBasePage
        behindClaimWall={reportCase.userCannotAccessBehindWall}
        vesselName={reportCase.name}>
        <WarningContentArea>
          <WarningNotification
            headingText="Claim Behind Chinese Wall"
            descriptionText={`You cannot access this case as it is behind a Chinese Wall.
              ${reportCase.metadata.claimReference &&
              ` Claim reference: ${reportCase.metadata.claimReference}`
              }`}
            size="large"
          />
        </WarningContentArea>
      </DashboardBasePage>
    );
  }

  const title = getSurveyorPageTitle(reportCase.name, reportCase.finalized);

  return (
    <DashboardBasePage name={title}>
      <Pane title={infoText}>
        <ToolsAndFeedback readonly={reportCase.finalized} />
        <DraftNavigationContainer />
        <CaseInformationContainer vesselData={reportCase.metadata} />
        {report && <VersionTable {...report} />}
      </Pane>
    </DashboardBasePage>
  );
};
