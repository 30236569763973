/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */

// TODO: TypeScript this file

// Take Array, add or remove value depending on whether it is
// already in the array or not. Then return new array.
export function addOrRemove(array, value) {
  const newArray = array;
  // Brute force way of making this be OK with comparing objects, covers the
  // use case that was needed when writing this but can be made a deepEqual
  const index = array.findIndex(item => JSON.stringify(item) === JSON.stringify(value));
  if (index === -1) newArray.push(value);
  else newArray.splice(index, 1);
  return newArray;
}

// This function is a no-param-reassign, but is it a problem?
// Brought over from WAR for use with table filtering functions
export function pick(obj, values) {
  return values.reduce((a, b) => {
    if (Object.keys(obj).includes(b)) {
      a[b] = obj[b];
    }
    return a;
  }, {});
}

export function unique(arr, keyProps) {
  const keyValueArray = arr.map(entry => {
    const key = keyProps.map(k => entry[k]).join('|');
    return [key, entry];
  });
  const map = new Map(keyValueArray);
  return Array.from(map.values());
}

export function groupBy(array, groupFunction) {
  const groups = {};
  array.forEach(o => {
    const group = JSON.stringify(groupFunction(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(group => groups[group]);
}

export const isOnlyOption = (array, value) => (array?.length === 1 && array[0] === value);

export const cmp = (a, b) => {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
};

export const transpose = obj => {
  const newObj = [];
  for (const [key, value] of Object.entries(obj)) {
    for (const [k, v] of Object.entries(value)) {
      newObj[k] = newObj[k] || [];
      newObj[k][key] = v;
    }
  }
  return newObj;
};
