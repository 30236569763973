import { Option, TimelineEntryGroup } from '@/types';
import useSWR from 'swr';

const fiveMinutesInMs = 1000 * 60 * 5;

export const useStatusTypes = () =>
  useSWR<Option[]>('configuration/statusTypes', {
    dedupingInterval: fiveMinutesInMs,
  });

export const useTimelineOptions = ({ swrOptions }: { swrOptions?: any }) =>
  useSWR<TimelineEntryGroup[]>('configuration/timeline', {
    dedupingInterval: fiveMinutesInMs,
    ...swrOptions
  });
