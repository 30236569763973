import styled from 'styled-components';
import { useModalContext } from '@/components/modal/ModalContext';
import { TableCell, TableRow } from '@/components/shared/Table';
import { openDownloadPdf } from '@/components/shared/FileDownload/FileDownloadModal';
import {
  MoveLayerDown, FileParagraph, Calendar, WarningFilled
} from '@instech/icons';
import { Link } from 'react-router-dom';
import { reportViewRoute } from '@/utils/routeTemplates';
import { getFriendlyDate, friendlyDateDotted } from '@/utils/date';
import { showPdfFallbackVersionModal } from '@/components/modal/PdfGeneratedWithFallbackParametersModal/PdfGeneratedWithFallbackParametersModal';

const MultiCell = styled(TableCell)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const MultiCellButton = styled(TableCell)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

const CellText = styled.div`
  padding: 5px;
`;

const StyledLinkbutton = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

type ViewButtonProps = {
  to: string;
  onClick: () => void;
}
const ViewButton = ({ to, onClick }: ViewButtonProps) => (
  <StyledLinkbutton to={to} onClick={onClick}>
    <FileParagraph />
    <CellText>View Report</CellText>
  </StyledLinkbutton>
);

type DownloadButtonProps = {
  to: string;
  onDownloadPdf: () => void;
}
const DownloadButton = ({ to, onDownloadPdf }: DownloadButtonProps) => (
  <StyledLinkbutton to={to} onClick={onDownloadPdf}>
    <MoveLayerDown />
    <CellText>Download</CellText>
  </StyledLinkbutton>
);

const YellowWarning = styled(WarningFilled)`
  color: ${props => props.theme.yellow};
`;

const WarningCell = styled(MultiCell)`
  gap: 8px;
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
`;

type ReportVersionProps = {
  index: number;
  caseId: string;
  archivedId: string;
  versionId: number;
  lastModified: string;
  interest: string;
  onReportView: () => void;
  pdfGenerated: boolean;
  pdfGeneratedWithFallbackParameters: boolean;
};
export const ReportVersion = ({
  index,
  caseId,
  archivedId,
  versionId,
  lastModified,
  interest,
  onReportView,
  pdfGenerated,
  pdfGeneratedWithFallbackParameters
}: ReportVersionProps) => {
  const { open } = useModalContext();
  const reportRoute = reportViewRoute(caseId, archivedId);
  const even = index % 2 === 0;

  const handleClick = pdfGeneratedWithFallbackParameters ? () => open(showPdfFallbackVersionModal) : undefined;
  return (
    <TableRow even={even}>
      <WarningCell onClick={handleClick}>
        {pdfGeneratedWithFallbackParameters && (
          <YellowWarning />
        )}
        {versionId}
      </WarningCell>
      <MultiCell>
        <Calendar />
        <CellText>{getFriendlyDate(lastModified, `${friendlyDateDotted} - HH:mm`)}</CellText>
      </MultiCell>
      <TableCell>{interest}</TableCell>
      <MultiCellButton>
        <DownloadButton
          to={reportRoute}
          onDownloadPdf={() => open(openDownloadPdf(caseId, archivedId, pdfGenerated))}
        />
      </MultiCellButton>
      <TableCell>
        <ViewButton to={reportRoute} onClick={onReportView} />
      </TableCell>
    </TableRow>
  );
};
