import styled from 'styled-components';
import { UserSingle } from '@instech/icons';
import { BrokerContact } from '@/types';
import { Form } from 'formik';
import * as Yup from 'yup';
import {
  FormField, Loader, TextField, FormikWithPrompt as Formik
} from '@instech/components';
import { NewBoxButton } from '@/components/shared/BoxButton/NewBoxButton';
import {
  BrokerUpdate, updateBrokerAsync, useBrokerInCase
} from '@/services/brokerServices';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useCaseStatus } from '@/services/caseStatusServices';
import { DashboardPane } from './DashboardPane';

const validationSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string(),
  phone: Yup.string()
});

const FormArea = styled(Form)`
  display: grid;
  grid-template-columns: auto 150px;
  grid-template-rows: auto auto;
`;

const FieldArea = styled.div`
  grid-column: 1;
  grid-row: 1;
`;

const ButtonArea = styled.div`
  margin: 5px;
  grid-column: 2;
  grid-row: 2;
  justify-self: end;
`;

interface BrokerFormProps {
  broker: BrokerContact;
  onSubmit: (model: BrokerContact) => void // Promise<boolean>
  isClosed: boolean;
}
const BrokerForm = ({ broker, onSubmit, isClosed }:BrokerFormProps) => (
  <Formik
      initialValues={broker}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
  >
    {({ handleSubmit, dirty, isSubmitting }) => (
      <FormArea>
        <FieldArea>
          <FormField>
            <TextField name="name" placeholder="Name" label="Contact name" readOnly={isClosed} />
          </FormField>
          <FormField>
            <TextField name="email" placeholder="Email" label="Email" readOnly={isClosed} />
          </FormField>
          <FormField>
            <TextField name="phone" placeholder="Number" label="Phone number" readOnly={isClosed} />
          </FormField>
        </FieldArea>
        <ButtonArea>
          <NewBoxButton
            onClick={handleSubmit}
            disabled={!dirty || isClosed || isSubmitting}
            height="40px"
            width="100px"
            lowercase
            padding="8px 8px"
          >
            Save
          </NewBoxButton>
        </ButtonArea>
      </FormArea>
    )}
  </Formik>
);

const updateBroker = async (caseId: string, model: BrokerContact) => {
  const newValues: BrokerUpdate = {
    name: model.name,
    email: model.email,
    phone: model.phone,
  };
  await updateBrokerAsync(caseId, newValues);
};

export const BrokerPane = () => {
  const { data: caseStatus } = useCaseStatus();
  const { data: broker } = useBrokerInCase();
  const caseId = useCaseIdFromUrl();

  // If the backend does not find a broker - like on old cases - it returns a null object (204),
  // but swr interprets this as an empty string
  if (broker === '') {
    return (
      <DashboardPane title="Broker" icon={<UserSingle />} padding="24px">
        <div>No broker data available</div>
      </DashboardPane>
    );
  }

  if (!broker || !caseStatus) return <Loader />;

  return (
    <DashboardPane title="Broker" icon={<UserSingle />} padding="24px">
      <h3>{broker.companyName}</h3>
      <BrokerForm broker={broker} isClosed={caseStatus.isClosed} onSubmit={model => updateBroker(caseId, model)} />
    </DashboardPane>
  );
};
