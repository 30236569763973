import { Suspense } from 'react';
import styled from 'styled-components';
import {
  Edit,
  Download,
  Send
} from '@instech/icons';
import { hiddenOnPrint } from '@/utils/styles';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useReportIdFromUrl } from '@/hooks/useReportIdFromUrl';
import { useVersionedReport } from '@/services/versionsService';
import { useModalContext, useTypedModal } from '@/components/modal/ModalContext';
import { openDownloadPdf } from '@/components/shared/FileDownload/FileDownloadModal';
import { Loader } from '@instech/components';
import { ActionButton } from '@/components/shared/ActionButton';
import { useCaseStatus } from '@/services/caseStatusServices';
import { PublishVersionModal } from './PublishReport/PublishVersionModal';

const ButtonList = styled.div`
  position: sticky;
  top: 50px;
  display: block;
  padding-top: 25px;
  ${hiddenOnPrint}
  @media (max-width: ${props => props.theme.breakpoints.tabletMax}) {
    top: 100px;
  }
`;

const Button = styled(ActionButton)`
  width: 180px;
  height: 50px;
  margin: 5px 0px;
  padding: 10px;
`;

export const publishTitle = 'Publish report to claims handler';
export const draftTitle = 'Submit draft to claims handler';
export const previewTitle = 'Download report preview - PDF';

export const ActionSideBarPreview = () => {
  const { data: caseStatus } = useCaseStatus();

  const { open: openPublish } = useTypedModal({
    component: PublishVersionModal,
    options: { title: publishTitle },
    args: { versionStatus: 'Public' }
  });

  const { open: openDraft } = useTypedModal({
    component: PublishVersionModal,
    options: { title: draftTitle },
    args: { versionStatus: 'Draft' }
  });

  const { open: openPreview } = useTypedModal({
    component: PublishVersionModal,
    options: { title: previewTitle },
    args: { versionStatus: 'Preview' }
  });

  if (caseStatus && caseStatus.isClosed) {
    return null;
  }

  return (
    <Suspense fallback={<Loader />}>
      <ButtonList>
        <Button boldFont={false} startIcon={<Send />} onClick={() => openPublish()}>
          {publishTitle}
        </Button>
        <Button inverted boldFont={false} startIcon={<Edit />} onClick={() => openDraft()}>
          {draftTitle}
        </Button>
        <Button inverted boldFont={false} startIcon={<Download />} onClick={() => openPreview()}>
          {previewTitle}
        </Button>
      </ButtonList>
    </Suspense>
  );
};

// Calls the download service to retrieve the file and download it
export const ActionSideBarView = () => {
  const { open } = useModalContext();
  const caseId = useCaseIdFromUrl();
  const archivedId = useReportIdFromUrl();

  const { data } = useVersionedReport(caseId, archivedId);

  if (!data) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <ButtonList>
        <Button
          inverted
          boldFont={false}
          startIcon={<Download />}
          onClick={() => open(openDownloadPdf(caseId, archivedId, data.pdfGenerated))}
        >
          Download
        </Button>
      </ButtonList>
    </Suspense>
  );
};
