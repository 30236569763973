import { FC, useMemo } from 'react';
import { Guid, UserInfo } from '@/types';
import { UserPickerMultiPrimary } from '@instech/components';
import { useModalContext } from '@/components/modal/ModalContext';
import { useAllowedUserInfoList } from '@/services/userInfoService';
import { ModalFormButtons } from '@/components/shared/FormButton';
import { Formik, Form } from 'formik';
import { AreaLoader } from '@/components/shared/Loader';
import { selectUsersValidationSchema } from './validationSchema';

export type SelectUsersReturn = {
  users: UserInfo[];
  primary: Guid;
}

type SelectUsersFormData = {
  users: (UserInfo | '')[];
  primary: Guid;
}

type OnSelectCallback = (values: SelectUsersReturn) => void;

const createInitialValues = (users: UserInfo[], primary?: string): SelectUsersFormData => ({
  users: users.length ? users : [''],
  primary: primary ?? ''
});

interface Props {
  claimId: string;
  role: string;
  onSelect: OnSelectCallback;
  selectedUsers: UserInfo[];
  selectedPrimary: string;
}
const SelectUsersModal: FC<Props> = ({ claimId, role, onSelect, selectedUsers, selectedPrimary }) => {
  const userOptions = useAllowedUserInfoList(role, claimId);
  const { close } = useModalContext();
  const onCompletion = (values: SelectUsersFormData) => {
    onSelect({
      users: values.users.filter((user): user is UserInfo => user !== ''),
      primary: values.primary
    });
    close();
  };

  const selectedUserOptions = useMemo(() => selectedUsers.map(user => ({
    ...user,
    fullName: user.displayName,
  })), [selectedUsers]);

  if (!userOptions) {
    return <AreaLoader />;
  }

  return (
    <div data-testid="users-input-modal">
      <Formik
        initialValues={createInitialValues(selectedUserOptions, selectedPrimary)}
        validationSchema={selectUsersValidationSchema}
        onSubmit={onCompletion}>
        {({ handleSubmit, isSubmitting, submitCount, dirty }) => (
          <Form>
            <UserPickerMultiPrimary
              name="users"
              namePrimary="primary"
              options={userOptions}
              // suppress errors until user has submitted at least once
              noErrorColors={submitCount <= 0}
              noErrors={submitCount <= 0}
            />
            <ModalFormButtons
              onSave={handleSubmit}
              onCancel={close}
              isPending={isSubmitting}
              isDisabled={!dirty || isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const showSelectUsersModal = (
  claimId: string,
  role: string,
  onSelect: OnSelectCallback,
  selectedUsers: UserInfo[],
  selectedPrimary: string = '',
) => ({
  component: SelectUsersModal,
  options: {
    title: 'Select Users',
    padding: '20px',
    footer: true
  },
  args: {
    onSelect,
    selectedUsers,
    selectedPrimary,
    claimId,
    role
  }
});
