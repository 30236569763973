import { FC } from 'react';
import styled from 'styled-components';

const Heading = styled.h3`
  color: ${props => props.theme.darkGray};
  font-size: 16px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 12px;
`;

const Text = styled.p`
  max-width: 810px;
  margin: 0px;
`;

interface Props {
  className?: string;
}
export const FilesHiddenGdprMessage: FC<Props> = ({ className }) => (
  <div className={className}>
    <Heading>Files containing Personal Identifiable Information are hidden</Heading>
    <Text>
      This case is closed. Documents and pictures marked as containing personal data are now hidden for all involved parties.
      This data will be permanently deleted 10 years after the case is closed.
    </Text>
  </div>
);
