import { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { FullwidthWidgetWrapper } from '@/components/pages/edit/widgets/FullwidthWidgetWrapper';
import { Checkbox, NumberField } from '@/components/shared/Form/FormFields';
import {
  Divider, ButtonGroup, FormField, FormRow, TextArea, FormikWithPrompt as Formik
} from '@instech/components';
import { SaveButton } from '@/components/shared/FormButton/FormButton';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { TimeSaved } from '@/types';
import { daysRequired, optionalComment } from '@/utils/validationSchemas';
import { CommentIncludeInReportBehavior } from '@/components/shared/CommentIncludeInReportBehavior/CommentIncludeInReportBehavior';
import { useTimeSavedDetails, updateTimeSavedDetailsAsync } from './timeSavedDetailsService';
import { EditPane } from '../../core/EditPane';

const formModelSchema = Yup.object().shape({
  days: daysRequired(),
  comment: optionalComment('includeInReport'),
  includeInReport: Yup.boolean()
});

interface TimeSavedDetailsProps {
  title: string;
  widgetId: string;
}

export const TimeSavedDetails: FunctionComponent<TimeSavedDetailsProps> = ({ title, widgetId }) => {
  const caseId = useCaseIdFromUrl();
  const { chapterId } = useParams<{ chapterId: string }>();
  const { data, mutate } = useTimeSavedDetails({ caseId, chapterId, widgetId, swrOptions: { suspense: true } });

  const onSubmitHandler = async (values: TimeSaved, helpers: FormikHelpers<TimeSaved>) => {
    try {
      const newValues = await updateTimeSavedDetailsAsync({ caseId, chapterId, widgetId, timeSavedDetails: values });
      void mutate(newValues);
      helpers.resetForm({ values: newValues });
    } catch {
      // eslint-disable-next-line no-console
      console.error('Could not submit data.', values);
    }
  };

  return (
    <FullwidthWidgetWrapper>
      <EditPane title={title}>
        <Formik
          formId={widgetId}
          initialValues={data}
          validationSchema={formModelSchema}
          onSubmit={onSubmitHandler}
        >
          {({ dirty, handleSubmit }) => (
            <Form>
              <FormRow gutter="40px">
                <FormField width="90px">
                  <NumberField
                    name="days"
                    label="Days"
                    placeholder="0"
                    min={0}
                  />
                </FormField>
                <FormField>
                  <CommentIncludeInReportBehavior commentFieldName="comment" includeInReportFieldName="includeInReport">
                    <TextArea
                    name="comment"
                    label="Comment - Optional"
                    placeholder="Comment"
                    maxLength={500}
                  />
                    <Checkbox
                    name="includeInReport"
                    label="Include in report?"
                    noPadding
                  />
                  </CommentIncludeInReportBehavior>
                </FormField>
              </FormRow>
              <Divider />
              <ButtonGroup alignRight>
                <SaveButton
                  disabled={!dirty}
                  onClick={handleSubmit}
                />
              </ButtonGroup>
            </Form>
          )}
        </Formik>
      </EditPane>
    </FullwidthWidgetWrapper>
  );
};
TimeSavedDetails.propTypes = {
  title: PropTypes.string.isRequired,
  widgetId: PropTypes.string.isRequired
};
