import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { ErrorBoundary, AppInsightsProvider } from '@instech/components';
import { unregister } from './registerServiceWorker';
import { ErrorPage } from './components/pages/error/ErrorPage';
import { theme } from './utils/theme';
import { getJsonAsync } from './services/client';
import 'normalize.css';
import './fonts.scss';
import './custom.css';
import { WebsiteDownProvider } from './providers/websiteDownProvider';
import { App } from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const swrConfig = {
  revalidateOnFocus: false,
  errorRetryCount: 3,
  fetcher: (...args) => getJsonAsync(...args)
};

const appInsightsApplicationName = 'Insify.Survey.Web';

createRoot(rootElement).render(
  <AppInsightsProvider application={appInsightsApplicationName}>
    <ErrorBoundary>
      <WebsiteDownProvider>
        <SWRConfig value={swrConfig}>
          <BrowserRouter basename={baseUrl}>
            <ThemeProvider theme={theme}>
              <ErrorBoundary component={ErrorPage}>
                <App />
              </ErrorBoundary>
            </ThemeProvider>
          </BrowserRouter>
        </SWRConfig>
      </WebsiteDownProvider>
    </ErrorBoundary>
  </AppInsightsProvider>
);

unregister();
