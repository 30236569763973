import { Table, TableHeader } from '@/components/shared/Table';
import { ReportVersion } from '@/utils/archivedReportTypes';
import { TableEntry } from './TableEntry';

const HeaderRow = () => (
  <>
    <TableHeader>Report type</TableHeader>
    <TableHeader>Version</TableHeader>
    <TableHeader>Status</TableHeader>
    <TableHeader>Last modified</TableHeader>
    <TableHeader />
    <TableHeader />
  </>
);

export const VersionTable = (props: ReportVersion) => (
  <Table layout="auto auto auto auto 180px 180px">
    <HeaderRow />
    <TableEntry {...props} />
  </Table>
);
