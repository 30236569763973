import useSWR from 'swr';
import { useState } from 'react';
import { CaseNotifications, Guid } from '@/types';
import client from './client';

type NotificationType = 'NewReport' | 'FeedbackFromSurveyor';

export interface Notification {
  name: string;
  type: NotificationType;
}
export interface CaseNotificationsList {
  caseId: Guid;
  notifications: Notification[];
}
export const mapToStatusList = (status: CaseNotifications) => {
  const notifications: Notification[] = [];
  if (status.hasArchiveVersionNotification) {
    notifications.push({
      name: 'New report',
      type: 'NewReport'
    });
  }
  if (status.hasImmediateFeedbackNotification) {
    notifications.push({
      name: 'Feedback from Surveyor',
      type: 'FeedbackFromSurveyor'
    });
  }
  return ({
    caseId: status.caseId,
    notifications
  });
};

const caseNotificationsUrl = (caseId?: string) => (
  caseId ? `/casenotifications/cases/${caseId}` : 'casenotifications'
);

const dismissImmediateFeedbackAsync = (caseId: string) => {
  const url = `${caseNotificationsUrl(caseId)}/immediate-feedback`;
  return client.put(url);
};

const dismissArchiveVersionAsync = (caseId: string) => {
  const url = `${caseNotificationsUrl(caseId)}/archive-version`;
  return client.put(url);
};

const oneMinuteInMs = 60000;

export const useCaseNotifications = (caseId: string) => {
  const [error, setError] = useState('');

  if (error) {
    throw new Error(error);
  }

  const { data, mutate } = useSWR<CaseNotifications>(caseNotificationsUrl(caseId), { refreshInterval: oneMinuteInMs });

  const dismissImmediateFeedback = async () => {
    try {
      await dismissImmediateFeedbackAsync(caseId);
      void mutate();
    } catch (errorMessage) {
      setError(errorMessage as string);
    }
  };

  const dismissArchiveVersion = async () => {
    try {
      await dismissArchiveVersionAsync(caseId);
      void mutate();
    } catch (errorMessage) {
      setError(errorMessage as string);
    }
  };

  return { data, dismissImmediateFeedback, dismissArchiveVersion };
};
