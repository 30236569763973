import {
  FunctionComponent,
  createContext,
  useContext,
  useMemo
} from 'react';
import { ModalContextData, ModalType } from './types';

export const ModalContext = createContext<ModalContextData>(null as unknown as ModalContextData);

export const ModalProvider: FunctionComponent<ModalContextData> = ({ children, open, close }) => {
  const value = useMemo(() => ({
    open, close
  }), [open, close]);

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);

export function useTypedModal<T>(modalObject: ModalType<T>) {
  const { open, close } = useModalContext();
  const payload = modalObject;
  return {
    open: (
      context: Partial<T> = {},
      onCancel: () => void = () => null
    ) => open({
      ...payload,
      args: { ...payload.args, ...context },
      onCancel
    }),
    close
  };
}
