import styled from 'styled-components';
import * as Yup from 'yup';
import { Guid } from '@/types';
import {
  TextArea, TextField, FormikWithPrompt as Formik
} from '@instech/components';
import { Form } from 'formik';
import { ImagePreview, updateImageMetadataAsync } from '@/services/imageServices';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import { PiiFields } from '@/components/shared/Form/fields/PiiFields';
import { boolToYesNo } from '@/utils/yesNoType';
import { yesNoOption } from '@/utils/validationSchemas';
import { trimString } from '@/utils/text';
import { EditedImageMeta } from './EditedImageMeta';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .max(60, 'Cannot be longer than 60 characters')
    .required('Required'),
  description: Yup.string()
    .max(256, 'Cannot be longer than 256 characters'),
  pii: yesNoOption().required('Required'),
  piiIncludesEu: yesNoOption().when('pii', {
    is: 'Yes',
    then: yesNoOption().required('Required')
  })
});

const FieldArea = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const initializeValues = (image: ImagePreview) => {
  const { personallyIdentifiableInformation, ...restImage } = image;
  return ({
    ...restImage,
    pii: personallyIdentifiableInformation ? 'Yes' : 'No',
    piiIncludesEu: personallyIdentifiableInformation ? boolToYesNo(personallyIdentifiableInformation?.includesEu) : undefined
  });
};

interface EditImageFormProps {
  image: ImagePreview;
  onSave: (result: ImagePreview) => void;
  onClose: () => void;
  onDelete?: (imageId: Guid) => void;
}
export const EditImageForm = ({ image, onSave, onClose, onDelete }: EditImageFormProps) => (
  <>
    <EditedImageMeta image={image} onDelete={onDelete} />
    <Formik
      initialValues={initializeValues(image)}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async values => {
        const pii = values.pii === 'Yes'
          ? { includesEu: values.piiIncludesEu === 'Yes' }
          : undefined;
        const submitValues = {
          ...values,
          title: trimString(values.title),
          personallyIdentifiableInformation: pii,
        };
        const { caseId, id: imgId } = image;
        const result = await updateImageMetadataAsync(caseId, imgId, submitValues);
        if (result) {
          onSave(result);
        }
      }}
    >
      {({ dirty, isSubmitting, handleSubmit }) => (
        <Form>
          <FieldArea>
            <TextField
              name="title"
              label="Image title"
              placeholder="Enter a title"
              useSpellcheck={false}
            />
            <TextArea
              name="description"
              label="Description"
              placeholder="Enter a description"
              maxLength={256}
            />
          </FieldArea>
          <PiiFields piiLabel="This image contains Personal Identifiable Information" />
          <SaveCloseButtons
            closeLabel="Cancel"
            onSave={handleSubmit}
            onClose={onClose}
            isSubmitting={isSubmitting}
            disableSave={!dirty}
            alignRight
            reverse
          />
        </Form>
      )}
    </Formik>
  </>
);
