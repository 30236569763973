import { dropdownOptionRequired } from '@/utils/validationSchemas';
import { LabelValuePair } from '@instech/components';
import moment from 'moment';
import * as yup from 'yup';

const occurrenceSchema = yup.object({
  id: yup.string().default('').required(),
  name: yup.string().default('').required('Required').max(110, 'Cannot be longer than 110 characters'),
  charter: yup.string().default(''),
  location: yup.string().default('').required('Required').max(60, 'Cannot be longer than 60 characters'),
  claimEventName: yup.string().nullable().default(''),
  timeOfLoss: yup.string().nullable(),
  timezoneId: yup.string().nullable(),
  fleetName: yup.string().nullable(),
  className: yup.string().nullable(),
  conditions: yup.string().nullable().default(''),
  dateOfLoss: yup.string()
    .nullable(true)
    .required('You must pick a date')
    .test('Is valid  ISO date', 'Invalid date', value => moment.utc(value, moment.ISO_8601, true).isValid())
    .test('min date', 'The date cannot be before the year 2000', value => moment.utc(value).isAfter('2000-01-01')),
  interests: yup.array(yup.object({
    interest: yup.string().nullable().default(''),
    ourShare: yup.number().nullable().default(null),
    interestGroup: yup.string().nullable(),
    claimInterest: yup.string().required().default(''),
    claimLeaderName: yup.string().nullable(),
    activeClaimWall: yup.bool().default(false),
    claimEventName: yup.string().nullable(),
    claimId: yup.string().nullable().default(''),
    deductible: yup.string().nullable().default(''),
    claimReference: yup.string().default(''),
  }).required()).default([]),
  status: dropdownOptionRequired()
}).required();

export const occurrenceFormSchema = yup.object({
  occurrences: yup.array(occurrenceSchema).required().default([])
});

export interface InterestSchema {
  interest: string | null;
  claimId: string | null;
  deductible: string | null;
  claimReference: string | null;
  ourShare?: number | null;
  interestGroup?: string | null;
  claimInterest?: string | null;
  claimLeaderName?: string | null;
  activeClaimWall?: boolean | null;
  claimEventName?: string | null;
}

export interface OccurrenceSchema {
  id: string;
  name: string;
  charter: string;
  location: string;
  conditions?: string | null;
  dateOfLoss?: string | null;
  interests: InterestSchema[];
  status: LabelValuePair;
  claimEventName?: string | null;
  timeOfLoss?: string | null;
  timezoneId?: string | null;
  fleetName?: string | null;
  className?: string | null;
}

export interface OccurrenceFormSchema {
  occurrences: OccurrenceSchema[];
}
