import styled from 'styled-components';
import { TableCell } from './TableCell';

interface TableRowProps {
  even: boolean;
  isOpen?: boolean;
}
export const TableRow = styled.span<TableRowProps>`
  display: contents;
  > span {
    background-color: ${props => props.even ? props.theme.white : props.theme.flatWhite};
  }
`;

interface EditableTableRowProps {
  isEditMode: boolean;
  even: boolean;
}
export const EditableTableRow = styled.span<EditableTableRowProps>`
  display: contents;
  ${TableCell} {
    ${props => props.isEditMode ? `
      background-color: ${props.theme.whiteBlue};
      border-top: 1px solid ${props.theme.border.gray};
      border-bottom: 1px solid ${props.theme.border.gray};
    ` : `
      background-color: ${props.even ? props.theme.white : props.theme.flatWhite};
    `}
  }
`;

export const ExpandedDetailRow = styled(TableRow)`
  display: ${props => props.isOpen ? 'contents' : 'none'};
  & ${TableCell} {
    justify-content: flex-start;
    background: ${props => props.theme.whiteBlue};    
  }
`;
