import useSWR, { mutate as globalMutate } from 'swr';
import { postFormAsync } from '@/services/client';
import { Guid, SignatureUserInfo } from '@/types';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { Crop } from './imageServices';

const signatureUserRoute = '/signatures/user';
const signatureRoute = (caseId: Guid, signatureId: Guid, crop: Crop = 'original') => {
  const queryParam = crop !== 'original' ? `?crop=${crop}` : '';
  return `/case/${caseId}/signatures/${signatureId}${queryParam}`;
};
const apiRoute = (caseId: Guid) => `case/${caseId}/signatures`;

interface ContentLinkModel {
  id: Guid;
  caseId: Guid;
  url: string;
  archivedVersionId?: Guid;
  userInfo: SignatureUserInfo
}
export interface SignatureResponse {
  signature: null | ContentLinkModel;
}

export const useUserSignature = () => {
  const caseId = useCaseIdFromUrl();
  const swr = useSWR<SignatureResponse>(signatureUserRoute);

  const signatureId = swr.data?.signature?.id;
  if (caseId && signatureId) {
    const route = signatureRoute(caseId, signatureId);
    void globalMutate(route, swr.data, false);
  }

  return swr;
};

export const useSignature = (caseId: Guid, signatureId?: Guid, crop: Crop = 'original') => {
  const route = signatureId ? signatureRoute(caseId, signatureId, crop) : null;
  const swr = useSWR<SignatureResponse>(route);

  if (!signatureId) {
    return { data: undefined };
  }

  return swr;
};

interface SignatureForm {
  image: File;
}

export async function uploadSignatureAsync(caseId: Guid, form: SignatureForm): Promise<Guid> {
  const signaturesUrl = apiRoute(caseId);

  const formData = new FormData();
  formData.append('image', form.image);

  const result: SignatureResponse = await postFormAsync(signaturesUrl, formData);
  void globalMutate(signatureRoute(caseId, result?.signature?.id as Guid), result, false);
  void globalMutate(signatureUserRoute, result, false);

  return result?.signature?.id ?? '';
}
