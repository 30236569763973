import { useModalContext } from '@/components/modal/ModalContext';
import { Guid, UserInfo } from '@/types';
import { Pen } from '@instech/icons';
import { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';
import { showChangePrimaryClaimsHandlerModal } from './ChangePrimaryClaimsHandlerModal';

const StyledPen = styled(Pen)`
  min-width: 12px;
  height: 16px;
  width: 16px;
  margin-left: 12px;
  color: ${props => props.disabled ? props.theme.lightGray : props.theme.blue};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;
const Wrapper = styled.div`
  // Prevent VesselInfo from collapsing into this component
  margin-left: 32px;
`;

const Heading = styled.div`
  color: ${props => props.theme.blue};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
`;

const PrimaryClaimsHandler = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.blue};
`;

interface Props {
  userInfo?: UserInfo;
  claimId?: Guid | null;
}
export const CurrentClaimsHandler: FunctionComponent<Props> = ({ userInfo, claimId }) => {
  const { open, close } = useModalContext();

  const openModal = useCallback(
    () => open(showChangePrimaryClaimsHandlerModal({ userInfo, close, claimId })),
    [userInfo, close, open, claimId]);

  return (
    <Wrapper>
      <Heading>Claims Handler</Heading>
      <PrimaryClaimsHandler onClick={openModal}>
        {userInfo?.displayName}
        <StyledPen />
      </PrimaryClaimsHandler>
    </Wrapper>
  );
};
