import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ContextMenu } from '@/components/shared/ContextMenu';
import { Edit, Trash } from '@instech/icons';
import { VesselInformation } from '@/services/vesselInformationServices';
import { SourceDetails } from '../../../shared/SourceDetails';
import { ReadOnlyField } from '../../../shared/ReadOnlyField';

interface EditableProps {
  editable: boolean;
}

const Container = styled.div<EditableProps>`
  display: flex;
  margin-top: 12px;
  margin-right: -8px;
`;

const Field = styled.div<EditableProps>`
  width: 100%;
  min-width: 0px;
`;

interface InformationProps extends VesselInformation {
  onDelete: (id: string) => void;
  onToggleEdit: (id: string) => void;
  readonly?: boolean;
  isEditMode: boolean;
}

export const InformationRowView: FunctionComponent<InformationProps> = ({
  id,
  title,
  value,
  includeInReport,
  source,
  editable,
  onDelete,
  onToggleEdit,
  readonly
}) => {
  const userEditable = !readonly && editable;

  return (
    <Container editable={!readonly}>
      <Field editable={userEditable}>
        <ReadOnlyField title={title} value={value} />
        <SourceDetails {...source} included={includeInReport} />
      </Field>
      {!readonly && (
        <ContextMenu
          id={id}
          buttonColor="marineBlue"
          options={[
            {
              icon: <Edit />,
              label: 'Edit',
              onClick: () => onToggleEdit(id)
            },
            {
              icon: <Trash />,
              label: 'Delete',
              onClick: userEditable ? () => onDelete(id) : null
            },
          ]} />
      )}
    </Container>
  );
};
