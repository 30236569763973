import { useTimelineContext } from '@/components/pages/edit/core/TimelineContext';
import {
  Checkbox,
  OccurrenceSelect,
  StatusTypeRadio, TimezonePicker
} from '@/components/shared/Form/FormFields';
import { useDebounce } from '@/hooks/useDebounce';
import { useTimelineOptions } from '@/services/configurationServices';
import { TimelineEntryGroup, TimelineItem } from '@/types';
import { constructDateTime } from '@/utils/date';
import {
  DatePicker,
  DropdownCreatable,
  FormField, FormRow,
  FormikWithPrompt as Formik,
  TextField, TimePicker
} from '@instech/components';
import { Form, FormikProps } from 'formik';
import { FunctionComponent, useRef } from 'react';
import { CommentIncludeInReportBehavior } from '@/components/shared/CommentIncludeInReportBehavior/CommentIncludeInReportBehavior';
import { getOptions } from '../../../Form/fields/Dropdown/OccurrenceSelect';
import { getDropdownOptions, getFormFieldSchema } from '../../utils/timelineOptionsUtils';
import { EventFormTypeSelection } from '../core/EventFormTypeSelection';
import { EventFormModel } from '../core/types';
import {
  FormButtons,
  MainSection
} from './Components';
import {
  addEventModel,
  clearInvalidFields,
  eventModel,
  eventSchema,
  getFieldStates,
} from '../core/eventFormUtils';
import { useResetTouched } from '../core/useResetTouch';

interface EventFormBodyProps extends FormikProps<EventFormModel> {
  setAddNew: (newValue: boolean) => void;
  handleClose: () => void;
  locations: any;
}

// Return True if given Fields are untouched, False if they are changed
const checkDisableSave = (initial: EventFormModel, values: EventFormModel) => {
  const fieldsToCompare = ['location', 'eventDate', 'timeStatus', 'comment', 'includeInReport'] as const;
  let disabled = true;
  fieldsToCompare.forEach(field => {
    if (initial[field] !== values[field]) disabled = false;
  });
  return disabled;
};

const EventFormBody: FunctionComponent<EventFormBodyProps> = ({
  initialValues,
  values,
  handleSubmit,
  setAddNew,
  handleClose,
  locations,
  setTouched,
  isSubmitting
}) => {
  const { data: timelineOptions } = useTimelineOptions({ swrOptions: { suspense: true } }) as { data: TimelineEntryGroup[] };

  const dropdownOptions = getDropdownOptions(timelineOptions);
  const formFieldSchema = getFormFieldSchema(timelineOptions);

  /* Get and set up conditionals. These have to be sent to Formik to get
  them to Yup. Param-reassign is bad, but doing it like this prevents a re-render loop */
  const fieldSchema = getFieldStates(values, formFieldSchema);
  // eslint-disable-next-line no-param-reassign
  values.fieldSchema = fieldSchema;

  // Set the entire form to untouched so that form validation errors don't appear when
  // the user changes any of the options in the dependency array.
  const resetValueCheck = values.movementType || values.eventType || values.typeOfActivity?.value;
  useResetTouched(eventModel, resetValueCheck, setTouched);

  const dateTime = constructDateTime(values.eventDate, values.eventTime);
  // Prevents the list of timezones being refreshed too often
  const debouncedDateTime = useDebounce<string | undefined>(dateTime, 500);

  const customActivity = values.typeOfActivity?.value === 'Custom';
  const showMainForm = (values.typeOfActivity && fieldSchema);

  return (
    <Form>
      <EventFormTypeSelection
        dropdownOptions={dropdownOptions}
      />
      {showMainForm && (
        <MainSection>
          {customActivity && (
            <FormRow gutter="20px" marginBottom="10px">
              <FormField span={6}>
                <TextField
                  name="customActivityName"
                  label="Activity name"
                  placeholder="Activity name"
                />
              </FormField>
            </FormRow>
          )}
          <FormRow gutter="20px" marginBottom="10px">
            <FormField span={6}>
              <DropdownCreatable
                name="location"
                label="Location"
                placeholder="Select location"
                options={locations}
              />
            </FormField>
          </FormRow>
          <FormRow gutter="20px" marginBottom="10px">
            <FormField span={6}>
              <DatePicker
                name="eventDate"
                label="Date"
              />
            </FormField>
          </FormRow>
          <FormRow gutter="20px" marginBottom="10px">
            <FormField span={2}>
              <TimePicker
                name="eventTime"
                label="Time (if significant)"
              />
            </FormField>
            <FormField span={4}>
              <TimezonePicker
                name="timezoneId"
                label="Time zone"
                dateTime={debouncedDateTime}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField span={6}>
              <StatusTypeRadio
                name="timeStatus"
                label="Time status"
                customId="inline"
              />
            </FormField>
          </FormRow>
            {fieldSchema.selectOccurrences && (
            <FormRow marginBottom="10px">
              <FormField span={6}>
                <OccurrenceSelect
                  label="Occurrences"
                  name="occurrences"
                />
              </FormField>
            </FormRow>
            )}
          <FormRow marginBottom="10px">
            <FormField span={12}>
              <TextField
              name="comment"
              placeholder="Optional ..."
              label="Comment"
            />
            </FormField>
          </FormRow>
          <FormRow marginBottom="20px">
            <FormField>
              <Checkbox
                name="includeInReport"
                rightLabel="Include event in report?"
                noErrors
              />
            </FormField>
          </FormRow>
        </MainSection>
      )}
      <FormButtons
      saveLabel="Submit"
        addLabel="Submit &amp; new"
        closeLabel="Cancel"
        onSave={() => {
          setAddNew(false); handleSubmit();
        }}
        onClose={handleClose}
        onAdd={() => {
          setAddNew(true); handleSubmit();
        }}
        disableSave={checkDisableSave(initialValues, values)}
        addTitle="Save and add new event using same date and location"
        isSubmitting={isSubmitting}
      />
    </Form>
  );
};

interface NewEventFormProps {
  handleClose: () => void;
  onSubmit: (clearedValues: any, addNew: any) => Promise<boolean>;
  addingNew: boolean;
}
export const NewEventForm: FunctionComponent<NewEventFormProps> = ({ handleClose, onSubmit, addingNew = false }) => {
  const { locations } = useTimelineContext();
  const addNew = useRef(addingNew); // Using useState will not work in this case.
  const setAddNew = (val: boolean) => {
    addNew.current = val;
  };
  return (
    <Formik
      formId="new-event-form"
      initialValues={addNew.current ? addEventModel : eventModel}
      validationSchema={eventSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        // Imperatively remove data from fields that aren't included and submit
        const clearedValues = clearInvalidFields(values, values.fieldSchema);
        const payload: TimelineItem = {
          ...clearedValues,
          location: values.location!.value,
          typeOfActivity: values.typeOfActivity!.value,
          occurrences: getOptions(values.occurrences)
        };
        const success = await onSubmit(payload, addNew.current);
        if (success && !addNew.current) handleClose();

        // Reset form and add some values from current form
        // addEventModel is mutated on submit if addNew is true
        if (addNew.current) resetForm({ values: addEventModel } as any);
        setSubmitting(false);
      }}>
      {formikProps => (
        <EventFormBody
          {...formikProps}
          locations={locations}
          setAddNew={setAddNew}
          handleClose={handleClose}
        />
      )}
    </Formik>
  );
};
