import { getJsonAsync } from '@/services/client';
import React, {
  createContext, FunctionComponent, useContext, useEffect, useState
} from 'react';

const websiteDownDataRoute = '/featureflags/websitedown';

type WebsiteDownStatus = boolean | undefined
const WebsiteDownContext = createContext<WebsiteDownStatus>(undefined);

export const WebsiteDownProvider: FunctionComponent = ({ children }) => {
  const [downForMaintenance, setDownForMaintenance] = useState<WebsiteDownStatus>(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getJsonAsync<boolean>(websiteDownDataRoute);
      setDownForMaintenance(!!response);
    };

    void fetchData();
  }, []);

  return (
    <WebsiteDownContext.Provider value={downForMaintenance}>
      {children}
    </WebsiteDownContext.Provider>
  );
};

export const useWebsiteDown = () => useContext<WebsiteDownStatus>(WebsiteDownContext as React.Context<WebsiteDownStatus>);
