import { useModalContext } from '@/components/modal/ModalContext';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useClaimsOccurrenceOverview } from '@/services/claimsOccurrenceServices';
import { updateOccurrencesWithInterestsAsync } from '@/services/occurrenceServices';
import {
  ClaimInterest,
  ClaimOccurrenceOverview,
  Occurrence
} from '@/types';
import { toLabelValuePair } from '@/utils/form';
import { Loader } from '@instech/components';
import { Formik } from 'formik';
import { useState } from 'react';
import { OccurrenceSchema, OccurrenceValidator } from '../OccurrenceValidator';
import {
  ClaimTableModal,
  ErrorLine,
  NoClaimsMessage,
  OccurrenceCharterDropdown,
  OccurrenceNameInput
} from '../core';
import { ModalActionButtonGroup, ModalFooter } from '../core/ModalFooterComponents';

interface ModalProps {
  occurrence: {
    occurrenceName: string;
    charter: string | null;
    claimId: string | null;
    id: string;
    interests: {
      claimId: string | null;
      claimReference: string;
      interest: string;
      claimInterest: ClaimInterest;
    }[];
    dateOfLoss: string;
  },
  onOccurrencesUpdate: (update: (occurrences: Occurrence[]) => Occurrence[]) => Promise<void>;
}
export const EditOccurrence = ({ occurrence, onOccurrencesUpdate }: ModalProps) => {
  const caseId = useCaseIdFromUrl();
  const { data: claimsData, isValidating } = useClaimsOccurrenceOverview(occurrence.claimId || '');
  const { close } = useModalContext();
  const [hasError, setError] = useState(false);
  const selectedClaims = occurrence.interests.map(interest => interest.claimId).filter(id => id !== null) as string[] ?? [];

  if (isValidating) {
    return <Loader />;
  }

  const initialValues: OccurrenceSchema = {
    occurrenceName: occurrence.occurrenceName,
    charter: occurrence.charter ? toLabelValuePair([occurrence.charter])[0] : null,
    selectedClaims,
    caseId
  };

  const handleSubmit = async (formData: OccurrenceSchema) => {
    setError(false);
    const { occurrenceName: name, selectedClaims: newSelectedClaims, charter } = formData;
    const selectedClaimsInFetchedOrder = claimsData?.map(claim => claim.id).filter(id => newSelectedClaims.includes(id)) ?? newSelectedClaims;
    const request = { name, charter: charter?.value ?? '', claimId: selectedClaimsInFetchedOrder };

    try {
      const updatedOccurrence = await updateOccurrencesWithInterestsAsync(caseId, occurrence.id, request);

      await onOccurrencesUpdate(occurrences => {
        const updatedOccurrences = [...occurrences];
        const occurrenceIndex = occurrences.findIndex(o => o.id === updatedOccurrence.id);
        updatedOccurrences[occurrenceIndex] = updatedOccurrence;
        return updatedOccurrences;
      });

      close();
    } catch (e) {
      setError(true);
    }
  };

  const availableClaims: ClaimOccurrenceOverview[] = claimsData?.map(claim => ({ ...claim, disabled: false })) ?? [];
  const missingSelectedClaimIds = selectedClaims.filter(selectedId => availableClaims.every(available => available.id !== selectedId));
  const missingSelectedClaims: ClaimOccurrenceOverview[] = occurrence.interests
    .filter(interest => missingSelectedClaimIds.includes(interest.claimId ?? ''))
    .map(interest => ({
      id: interest.claimId ?? interest.claimReference,
      claimReference: interest.claimReference,
      dateOfLoss: occurrence.dateOfLoss,
      interest: interest.interest,
      claimInterest: interest.claimInterest
    }));
  const claims = [...availableClaims, ...missingSelectedClaims];
  claims.sort((a, b) => (a.claimInterest.localeCompare(b.claimInterest)) || a.claimReference.localeCompare(b.claimReference));

  if (claims.length === 0) {
    return <NoClaimsMessage closeModal={close} />;
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={OccurrenceValidator}>
      {() => (
        <>
          <OccurrenceNameInput />
          <OccurrenceCharterDropdown />
          <ClaimTableModal claims={claims} label="Select at least one (or more) interest type(s) for this occurrence." />
          <ModalFooter>
            {hasError && <ErrorLine>Could not update the occurrence. Please try again later.</ErrorLine>}
            <ModalActionButtonGroup submitButtonText="UPDATE" />
          </ModalFooter>
        </>
      )}
    </Formik>
  );
};

export const showEditOccurrenceModal = (props: ModalProps) => ({
  component: EditOccurrence,
  options: {
    title: 'Update Occurrence and Interest(s)',
    size: 'medium',
    footer: false,
  },
  args: props
});
