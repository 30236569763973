import { useState } from 'react';
import styled from 'styled-components';
import { Info } from '@instech/icons';
import { TimelineView } from '@/components/shared/TimelineView';
import { extractLocations } from '@/components/shared/TimelineView/utils/timelineUtils';
import { useLatestArchivedReportTimeline } from '@/services/archivedReportsSummaryServices';
import { TimelineProvider } from '@/components/pages/edit/core/TimelineContext';
import { TimelineHeader } from '@/components/shared/TimelineView/components/TimelineHeader';
import { DashboardPane } from '../core/DashboardPane';

const NoEntryText = styled.div`
  font-style: italic;
`;

interface Props {
  caseId: string;
}

export const ClaimsHandlerVesselMovements = ({ caseId }: Props) => {
  const [displayInUtcTime, setDisplayInUtcTime] = useState(false);

  const { data: timelineEvents } = useLatestArchivedReportTimeline(caseId);
  const locations = extractLocations(timelineEvents);
  const noEnties = !timelineEvents && timelineEvents !== undefined;

  return (
    <DashboardPane
      title="Vessel Movement & Events"
      icon={<Info />}
      padding="20px 20px 40px"
      chapterLink="vessel-movement--events"
    >
      {noEnties ? <NoEntryText>No added information</NoEntryText> : (
        <TimelineProvider value={{ locations }}>
          <TimelineHeader displayInUtcTime={displayInUtcTime} setDisplayInUtcTime={setDisplayInUtcTime} />
          <TimelineView data={timelineEvents} displayInUtcTime={displayInUtcTime} />
        </TimelineProvider>
      )}
    </DashboardPane>
  );
};
