import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BoxButton } from '@instech/components';
import { useAccount } from '@/components/authentication/AuthenticationProvider';
import { ContentArea } from '../core/Components';
import { PageTitle } from '../core/PageTitle';

const Text = styled.p`
  font-size: 16px;
  line-height: 28px;
`;

export const Logout: FunctionComponent = () => {
  const { logout } = useAccount();
  return (
    <ContentArea>
      <PageTitle title="Log out" />
      <Text>Do you want to log out of the system?</Text>
      <BoxButton onClick={logout} title="Log out">Yes</BoxButton>
    </ContentArea>
  );
};
