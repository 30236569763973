import { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import * as Yup from 'yup';
import {
  Dropzone, FormField, FormRow, TextArea, TextField, FormikWithPrompt as Formik
} from '@instech/components';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import { InformationBox, InformationRow } from '@/components/shared/InformationBox';
import { uploadImageAsync } from '@/services/imageServices';
import { yesNoToBool } from '@/utils/yesNoType';
import { YesOrNo } from '@/types';
import {
  imageRequired, stringRequiredMaxLength, yesNoOption
} from '@/utils/validationSchemas';
import { PiiFields } from '@/components/shared/Form/fields/PiiFields';

interface FormModel {
  imagesToolImage: any;
  title: string;
  description: string;
  pii?: YesOrNo;
  piiIncludesEu?: YesOrNo;
}
const imageSchema: FormModel = {
  imagesToolImage: null,
  title: '',
  description: '',
  pii: undefined,
  piiIncludesEu: undefined
};

const validationSchema = Yup.object().shape({
  imagesToolImage: imageRequired(),
  title: stringRequiredMaxLength(),
  description: Yup.string()
    .max(256, 'Cannot be longer than 256 characters'),
  pii: yesNoOption().required('Required'),
  piiIncludesEu: yesNoOption().when(
    'pii', {
      is: 'Yes',
      then: yesNoOption().required('Required')
    }
  )
});

interface Props {
  caseId: string;
}
export const UploadImageForm: FunctionComponent<Props> = ({ caseId }) => (
  <Formik
    initialValues={{ ...imageSchema }}
    validationSchema={validationSchema}
    onSubmit={async (values, { resetForm }) => {
      const submitValues = {
        ...values,
        image: values.imagesToolImage,
        pii: values.pii === 'Yes',
        piiIncludesEu: yesNoToBool(values.piiIncludesEu)
      };
      const result = await uploadImageAsync(caseId, submitValues);
      if (result) {
        resetForm();
      }
    }}
  >
    {({ values, dirty, isSubmitting, handleSubmit, resetForm }) => (
      <Form>
        <InformationRow>
          <InformationBox text="All images will be shared with the Claims Handler" />
        </InformationRow>
        <FormRow marginBottom={values.imagesToolImage && '30px'}>
          <Dropzone name="imagesToolImage" />
        </FormRow>
        {values.imagesToolImage && (
          <>
            <FormRow gutter="40px">
              <FormField>
                <TextField name="title" label="Image title" placeholder="Enter a title" useSpellcheck={false} />
              </FormField>
            </FormRow>
            <FormRow marginBottom="10px">
              <FormField>
                <TextArea name="description" label="Description" placeholder="Enter a description" maxLength={256} />
              </FormField>
            </FormRow>
            <PiiFields piiLabel="This image contains Personal Identifiable Information" />
            <SaveCloseButtons
              closeLabel="Cancel"
              onSave={handleSubmit}
              onClose={resetForm}
              isSubmitting={isSubmitting}
              disableSave={!dirty}
              alignRight
              reverse
            />
          </>
        )}
      </Form>
    )}
  </Formik>
);
UploadImageForm.propTypes = {
  caseId: PropTypes.string.isRequired
};
