import styled from 'styled-components';
import {
  TimelineVoyageShort, TimelineVoyageLong, TimelineVoyageVert
} from '@instech/icons';
import { TimelineAnchor } from './TimelineAnchor';

interface PathProps {
  isEnd?: boolean;
  isRight?: boolean;
}

interface VerticalPathProps {
  isRight?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  elsewhere?: boolean;
  locationBefore?: boolean;
}

const PathContainer = styled.div<PathProps>`
  position: relative;
  display: flex;
  justify-content: center;
  grid-area: center;
  & svg {
    position: absolute;
    width: 100%;
    transform: ${props =>
    (props.isRight && props.isEnd && 'scaleX(-1) scaleY(-1)') ||
    (props.isRight && !props.isEnd && 'scaleX(-1)') ||
    (!props.isRight && props.isEnd && 'scaleY(-1)') ||
    ''};
  }
`;
const PathContainerShort = styled(PathContainer)`
  & svg {
    width: 112px;
    left: ${props => props.isRight && 'calc(50% - 1px)'};
    right: ${props => !props.isRight && 'calc(50% - 1px)'};
  }
`;
const PathContainerLong = styled(PathContainer)`
  & svg {
    width: 219px;
  }
`;

export const TimelinePath = ({ isEnd, isRight }: PathProps) => (
  <TimelineAnchor height="34px" padding="4px">
    <PathContainerShort isEnd={isEnd} isRight={isRight}>
      <TimelineVoyageShort />
    </PathContainerShort>
  </TimelineAnchor>
);

export const TimelinePathLong = ({ isEnd, isRight }: PathProps) => (
  <TimelineAnchor height="34px" padding="4px">
    <PathContainerLong isEnd={isEnd} isRight={isRight}>
      <TimelineVoyageLong />
    </PathContainerLong>
  </TimelineAnchor>
);

const VerticalWrapper = styled.div<VerticalPathProps>`
  position: relative;
  display: flex;
  align-items: center;
  grid-area: ${props => props.isRight ? 'right' : 'left'};
`;

const VerticalContainer = styled.div<VerticalPathProps>`
  position: absolute;
  width: 2px;
  bottom: -2px;
  top: ${props => props.locationBefore ? '-20px' : '-2px'};
  right: ${props => !props.isRight && '61px'};
  left: ${props => props.isRight && '59px'};
  & svg {
    position: absolute;
    top: 0;
    bottom: 0;
  }
`;

export const VerticalPath = ({ isRight, locationBefore, elsewhere }: VerticalPathProps) => (
  <VerticalWrapper isRight={isRight}>
    <VerticalContainer isRight={isRight} locationBefore={locationBefore}>
      <TimelineVoyageVert height="100%" />
    </VerticalContainer>
  </VerticalWrapper>
);
