import styled from 'styled-components';
import {
  useCaseStatus, useCaseStatusChangeLog, useTotalClaimHandlingDays
} from '@/services/caseStatusServices';
import { Loader } from '@instech/components';
import { useCurrentRoleContext } from '@/components/authentication/CurrentRoleContext';
import { roles } from '@/components/AppRouting/roles';
import { SurveyorFrame } from './SurveyorFrame';
import { ClaimsHandlerFrame } from './ClaimsHandlerFrame';

const Content = styled.div`
  color: ${props => props.theme.marineBlue};
`;

interface ModalProps {
  close: () => void;
}
const CaseStatusModal = ({ close }: ModalProps) => {
  const { data: changelog } = useCaseStatusChangeLog();
  const { data: caseStatus } = useCaseStatus();
  const { data: handlingDays } = useTotalClaimHandlingDays();
  const { currentRole } = useCurrentRoleContext();

  if (!changelog || !handlingDays || !currentRole || !caseStatus) return <Loader />;

  const daysText = `Total handling days: ${handlingDays.totalClaimHandlingDays}`;
  const closed = caseStatus.isClosed;
  const isClaimsHandler = currentRole.name === roles.claimHandler;

  return (
    <Content>
      {isClaimsHandler
        ? (
          <ClaimsHandlerFrame
            daysText={daysText}
            close={close}
            closed={closed}
            changelog={changelog}
          />
        )
        : (
          <SurveyorFrame
            daysText={daysText}
            close={close}
            closed={closed}
            changelog={changelog}
          />
        )}
    </Content>
  );
};

export const showCaseStatusModal = ({ close }: ModalProps) => ({
  component: CaseStatusModal,
  options: {
    title: 'Case status',
    size: 'small',
    footer: false,
    hideTitle: true
  },
  args: {
    close
  }
});
