import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { InfoCircle } from '@instech/icons';

const Container = styled.div`
  display: flex;
  max-width: 400px;
  background-color: #EBF1F5;
  padding: 32px 28px;
  color: ${props => props.theme.blue};
  & svg g { /* Thicken SVG stroke to appear bold */
    stroke-width: 2;
  }
`;

const Message = styled.div`
  padding-left: 20px;
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
`;

interface Props {
  message: string;
}
export const InfoMessage: FunctionComponent<Props> = ({ message }) => (
  <Container>
    <InfoCircle />
    <Message>{message}</Message>
  </Container>
);
