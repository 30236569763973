import styled from 'styled-components';
import { TruncatingTextTableCell, TableRow } from '@/components/shared/Table';

export const Content = styled.div`
  display: grid;
  background-color: #F5F5F5;
  grid-template-columns: 400px auto;
`;

type ParticularProps = {
  title: string;
  description: string;
  index: number;
};

export const ToolItem = ({ title, description, index }: ParticularProps) => (
  <TableRow even={index % 2 === 0}>
    <TruncatingTextTableCell text={title} />
    <TruncatingTextTableCell text={description} />
  </TableRow>
);
