import {
  createContext, useContext, FunctionComponent, useEffect, useState, useMemo
} from 'react';
import { useLocation } from 'react-router-dom';

interface ContextData {
  scrollToContent: () => any;
  shouldScroll: number;
}
const scrollUpdateFuncs: ContextData = {
  scrollToContent: () => { },
  shouldScroll: 0
};

const ScrollUpdateContext = createContext<ContextData>(scrollUpdateFuncs);

export const ScrollUpdateProvider: FunctionComponent = ({ children }) => {
  const [shouldScroll, setShouldScroll] = useState(0);

  const updateFuncs = useMemo(() => ({
    scrollToContent: () => setShouldScroll(Math.random()),
    shouldScroll
  }), [shouldScroll]);

  return (
    <ScrollUpdateContext.Provider value={updateFuncs}>
      {children}
    </ScrollUpdateContext.Provider>
  );
};

export const useScrollUpdateContext = () => useContext(ScrollUpdateContext);

export const ScrollUpdater: FunctionComponent<{ children: any }> = ({ children }) => {
  const { hash } = useLocation();
  const { shouldScroll } = useScrollUpdateContext();

  useEffect(() => {
    if (hash.includes('#')) {
      const id = hash.substring(1, hash.length);
      const element = document.getElementById(id);
      if (element) {
        // Find an offset and topPos based on whether this is a desktop
        // or mobile user. scrollIntoView() doesn't support the same
        // kind of offset, putting the scroll underneath the header.
        const scrollOffset = window.innerWidth < 1100 ? 120 : 70;
        const topPos = element.getBoundingClientRect().top + window.pageYOffset - scrollOffset;
        window.scrollTo({
          top: topPos,
          behavior: 'smooth'
        });
      }
    }
  }, [hash, shouldScroll]);

  return children;
};
