import styled from 'styled-components';
import { RichTextView } from '@/components/shared/Form/RichTextView';
import { TruncatingText } from '@instech/components';

interface ItemProps {
  description: string;
  details: { text?: string; images?: string[]; }
}

const SectionWrapper = styled.div`
  & + & {
    margin-top: 40px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2em;
  margin: 0px;
`;

const DescriptionTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
  color: ${props => props.theme.marineBlue};
`;

const ContentArea = styled.div`
  padding-left: 16px;
`;

interface SectionProps {
  title?: string;
  items: ItemProps[];
}
export const Section = ({ title, items }: SectionProps) => {
  if (!(items && items.length)) {
    return null;
  }
  return (
    <SectionWrapper>
      { title && <SectionTitle>{title}</SectionTitle> }
      {items.map(item => (
        <div key={item.description}>
          <DescriptionTitle><TruncatingText text={item.description} /></DescriptionTitle>
          <ContentArea>
            <RichTextView data={item.details} />
          </ContentArea>
        </div>
      ))}
    </SectionWrapper>
  );
};
