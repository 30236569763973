import { useModalContext } from '@/components/modal/ModalContext';
import {
  BoxButton, ButtonGroup, Divider, LinkButton
} from '@instech/components';
import { PasswordProtectedPdfHandlerContent } from './PasswordProtectedPdfHandlerContent';

interface Props {
  error: Error;
  clearError: () => void;
}
export const AvailableInformationModalErrorHandler = ({ error, clearError }: Props) => {
  const { close: closeModal } = useModalContext();

  if (error.message.toLowerCase().includes('pdf cannot have passwords')) {
    return (
      <>
        <PasswordProtectedPdfHandlerContent />
        <Divider padding="0px" />
        <ButtonGroup alignRight>
          <LinkButton onClick={closeModal}>Dismiss</LinkButton>
          <BoxButton onClick={clearError}>Upload a new file</BoxButton>
        </ButtonGroup>
      </>
    );
  }

  throw error;
};
