import { FunctionComponent } from 'react';
import { ContentArea } from '../../core/Components';
import { PageTitle } from '../../core/PageTitle';
import { TermsPane } from './TermsPane';

interface Props {
  preTitle?: string;
}
export const TermsOfUse: FunctionComponent<Props> = ({ preTitle }) => (
  <ContentArea>
    <PageTitle title="Terms of Use" />
    <TermsPane />
  </ContentArea>
);

export const showTermsOfUseModal = () => ({
  component: TermsOfUse,
  options: {
    title: 'Terms of Use',
    size: 'large',
    footer: false,
    hideTitle: true
  },
  args: {}
});
