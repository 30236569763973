import { useAccount } from '@/components/authentication/AuthenticationProvider';
import {
  createContext, FunctionComponent, useContext, useMemo
} from 'react';
import useSWR from 'swr';

interface FeatureFlags {
  claimsDashboard: boolean;
  remoteUserMenu: boolean;
  filterClaimsHandlers: boolean;
  portalSelector: boolean;
  vSightSession: boolean;
}

export type FeatureNames = keyof FeatureFlags;

const featureFlagRoute = '/featureFlags';

const FeatureFlagContext = createContext({} as FeatureFlags);

export const FeatureFlagProvider: FunctionComponent = ({ children }) => {
  const { isAuthenticated, } = useAccount();
  const { data } = useSWR<FeatureFlags>(isAuthenticated ? featureFlagRoute : null);

  const value = useMemo(() => data || {} as FeatureFlags, [data]);
  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlagsContext = () => useContext<FeatureFlags>(FeatureFlagContext);

export const useFeatureFlag = (key: FeatureNames) => {
  const ctx = useFeatureFlagsContext();
  return !!ctx[key];
};
