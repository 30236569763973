import client, { postJsonAsync } from '@/services/client';
import {
  TimelineItem, Guid, Occurrence
} from '@/types';
import useSWR, { mutate as mutateGlobal } from 'swr';
import { mutateOccurrence, occurrenceApiUrl } from './occurrenceServices';

const getTimelineRoute = (caseId: Guid) => `/case/${caseId}/timelineitems`;
const getArchivedTimelineRoute = (caseId: Guid, versionId: Guid) => `/case/${caseId}/timelineitems/versions/${versionId}`;
const getEventsRoute = (caseId: Guid) => `/case/${caseId}/vesselevents`;
const getEventItemRoute = (caseId: Guid, eventId: Guid) => `/case/${caseId}/vesselevents/${eventId}`;
const getArchivedEventItemRoute = (caseId: Guid, eventId: Guid, versionId: Guid) => `/case/${caseId}/vesselevents/${eventId}/version/${versionId}`;

const mutateTimeline = (caseId: Guid) => mutateGlobal(getTimelineRoute(caseId));

type TimelineEventResult = { status: 'notfound' | 'success', event?: TimelineItem };
/**
 * Returns a specific timeline item based on the eventId and caseId
 *
 * The we reason why we don't use`/case/${caseId}/vesselevents/${eventId}` directly
 * is because the list of timeline events is a combination of both VesselEvents and
 * Occurrences.
 */
const getTimelineEvent = async (caseId: Guid, eventId: Guid, versionId?: '' | string | null): Promise<TimelineEventResult> => {
  const timelineRoute = versionId ? getArchivedTimelineRoute(caseId, versionId): getTimelineRoute(caseId);
  const result = await client.get<TimelineItem[]>(timelineRoute);
  const timelineItems = result.data;
  const findEvent = timelineItems.find(x => x.id === eventId);

  if (!findEvent) {
    return { status: 'notfound', event: undefined };
  }

  return { status: 'success', event: findEvent };
};

export const useTimelineEventService = (caseId: Guid, eventId: Guid, versionId?: '' | string | null) => {
  const endpointRoute = versionId ? getArchivedEventItemRoute(caseId, eventId, versionId): getEventItemRoute(caseId, eventId);
  const { data, error, mutate } = useSWR<TimelineEventResult>(endpointRoute, () => getTimelineEvent(caseId, eventId, versionId));
  if (error) {
    throw error;
  }

  const removeEvent = async () => {
    await client.delete(endpointRoute);
    await mutateTimeline(caseId);
  };

  const updateEvent = async (event: TimelineItem) => {
    if (event.id !== eventId) {
      throw new Error(`Mismatch between update ID ${event.id} - ${eventId}`);
    }
    const updatedEvent = await client.put<TimelineItem>(endpointRoute, event);
    if (updatedEvent) {
      void mutate({ status: 'success', event });
      void mutateTimeline(caseId);
      return true;
    }
    return false;
  };

  const updateOccurrenceTime = async ({ timeOfLoss, timezoneId }: { timeOfLoss?: string | null, timezoneId?: string | null }) => {
    const updateOccurrenceTimeRoute = `${occurrenceApiUrl(caseId)}/${eventId}/time`;
    const response = await client.put<Occurrence>(updateOccurrenceTimeRoute, { timeOfLoss, timezoneId });

    if (response.status === 200) {
      const promise1 = mutateOccurrence(caseId, eventId, response.data);
      const promise2 = mutate();
      const promise3 = mutateTimeline(caseId);

      // Wait for revalidation of data so the 'isSubmitting' won't be fulfilled until
      // all data is updated from the backend
      await Promise.all([promise1, promise2, promise3]);
      return true;
    }
    return false;
  };

  return {
    data,
    removeEvent,
    updateEvent,
    updateOccurrenceTime
  };
};

export const useReadonlyTimeline = (caseId: string) => {
  const swrRoute = `/case/${caseId}/timelineitems?includedOnly=true`;
  const { data, error } = useSWR(swrRoute);
  if (error) {
    throw error;
  }
  return data;
};

export const useTimelineService = (caseId: string) => {
  const endpointRoute = getTimelineRoute(caseId);
  const { data, error, mutate } = useSWR<TimelineItem[]>(endpointRoute);
  if (error) {
    throw error;
  }

  const addItem = async (event: TimelineItem) => {
    const eventsRoute = getEventsRoute(caseId);
    const newEvent = await postJsonAsync<TimelineItem>(eventsRoute, event);
    void mutate();
    return newEvent;
  };

  const removeItem = async (eventId: Guid) => {
    const itemRoute = getEventItemRoute(caseId, eventId);
    await client.delete(itemRoute);
    void mutate();
  };

  return {
    items: data,
    addItem,
    removeItem
  };
};
