import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { StatusDot } from '../../../reportVersions/StatusDot';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  color: ${props => props.theme.marineBlue};
`;

interface Props {
  status: string;
}
export const StatusInfo: FunctionComponent<Props> = ({ status }) => (
  <Container>
    <StatusDot status={status} />
    <Text>{status}</Text>
  </Container>
);
