import { useImagePreviewById } from '@/services/imageServices';
import { Guid } from '@/types';
import { ImageCard as InsifyImageCard } from '@instech/components';
import { ComponentProps, FunctionComponent } from 'react';

interface Props extends Omit<ComponentProps<typeof InsifyImageCard>, 'image'> {
  id: Guid;
}

export const ImageCard: FunctionComponent<Props> = ({ id, ...rest }) => {
  const { data: image } = useImagePreviewById(id, 'medium');

  return <InsifyImageCard image={image} {...rest} />;
};
