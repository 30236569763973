import { matchPath, useLocation } from 'react-router-dom';

function getCaseIdFromPathname(pathname: string) {
  const match = matchPath<{id: string}>(pathname, { path: '/case/:id' });

  if (match) {
    return match.params.id;
  }
  return null;
}

function useCaseIdFromUrl() {
  /// <summary>
  /// Hook that wraps 'useLocation' from 'react-router-dom' and returns the caseId URL param.
  /// Useful when the URL param isn't available from useParams directly, such as in a modal.
  /// </summary>

  const location = useLocation();
  const result = location && location.pathname && getCaseIdFromPathname(location.pathname);
  if (!result) {
    throw new Error('caseId cannot be null');
  }
  return result;
}

export { useCaseIdFromUrl, getCaseIdFromPathname };
