import { getJsonAsync } from '@/services/client';
import { Loader } from '@instech/components';
import { Suspense } from 'react';
import { SWRConfig } from 'swr';

interface Props {
  children: any;
}

const swrExtendedConfig = {
  revalidateOnFocus: false,
  errorRetryCount: 3,
  fetcher: (...args: any) => getJsonAsync(args[0]),
  suspense: true
};

export const ReportLoader = ({ children }: Props) => (
  <SWRConfig value={swrExtendedConfig}>
    <Suspense fallback={<Loader />}>
      {children}
    </Suspense>
  </SWRConfig>
);
