import styled from 'styled-components';
import { Lighthouse } from '@instech/icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px;
  & svg {
    height: 85px;
    width: 81px;
  };
`;

const Text = styled.span`
  font-size: 16px;
  color: ${props => props.theme.marineBlue};
  padding-top: 27px;
`;

export const MissingEstimate = () => (
  <Wrapper>
    <Lighthouse />
    <Text>The Surveyors haven&apos;t published any cost or time estimates yet</Text>
  </Wrapper>
);
