import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TruncatingText } from '@instech/components';

const Heading = styled.div`
  font-weight: bold;
  margin-bottom: 3px;
`;

interface CommentsProps {
  comment: string;
}
export const Comments: FunctionComponent<CommentsProps> = ({ comment }) => (
  <>
    <Heading>Comment</Heading>
    <TruncatingText text={comment} wrap />
  </>
);
