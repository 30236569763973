import styled from 'styled-components';
import { Add, Trash } from '@instech/icons';
import {
  Divider, ButtonGroup, BoxButton,
  TruncatingText
} from '@instech/components';
import { TimelineSegmentEvent } from '@/utils/timelineTypes';
import { EventTimeView } from './core/EventTimeView';

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Section = styled.div`
  padding: 20px 12px;
`;

const Heading = styled.h3`
  color: ${props => props.theme.marineBlue};
  font-size: 14px;
  font-weight: bold;
  line-height: 15px;
  margin-top: 0px;
  margin-bottom: 12px;
`;

const Text = styled.p`
  color: ${props => props.theme.marineBlue};
  font-size: 16px;
  margin: 0px;
`;

interface FooterProps {
  eventType: string;
  canEdit: boolean;
  addNewLabel: string;
  onEdit: () => any;
  onClose: () => any;
  onRemove: () => any;
  onAddNew: () => any;
}
const FooterButtons = ({ eventType, canEdit, addNewLabel, onAddNew, onEdit, onRemove, onClose }: FooterProps) => {
  /// If this is an Occurrence, or it is flagged as uneditable, do not edit
  const noEdit = (eventType !== 'Occurrence' && !canEdit) || (eventType === 'Occurrence');

  return (
    <>
      <Divider />
      <Buttons>
        <ButtonGroup>
          {!noEdit && <BoxButton startIcon={<Trash />} inverted padding="10px" onClick={onRemove}>Delete</BoxButton>}
          <BoxButton startIcon={<Add />} inverted padding="10px" title={addNewLabel} onClick={onAddNew}>New event</BoxButton>
        </ButtonGroup>
        <ButtonGroup>
          {!noEdit && <BoxButton inverted onClick={onEdit}>Edit</BoxButton>}
          <BoxButton onClick={onClose}>Done</BoxButton>
        </ButtonGroup>
      </Buttons>
    </>
  );
};

interface ViewEventProps {
  event: TimelineSegmentEvent;
  canEdit: boolean;
  onEdit: () => any;
  onClose: () => any;
  onRemove: () => any;
  onAddNew: (event: TimelineSegmentEvent) => any;
  isReport: boolean;
}
export const ViewEvent = ({ event, onEdit, onClose, onRemove, canEdit, onAddNew, isReport }: ViewEventProps) => (
  <div>
    <Section>
      <Heading>Activity</Heading>
      <Text>{event.typeOfActivity}</Text>
    </Section>
    {event.customActivityName && (
      <Section>
        <Heading>Activity name</Heading>
        <Text>{event.customActivityName}</Text>
      </Section>
    )}
    <Section>
      <Heading>Location</Heading>
      <Text><TruncatingText text={event.location} /></Text>
    </Section>
    <Section>
      <Heading>Date and time</Heading>
      <EventTimeView
        RenderComponent={Text}
        date={event.eventDate}
        timeOfDay={event.eventTime}
        timezoneId={event.timezoneId}
      />
    </Section>
    <Section>
      <Heading>Time status</Heading>
      <Text>{event.timeStatus}</Text>
    </Section>
    <Section>
      <Heading>Comment</Heading>
      <Text>{event.comment || <i>No comment.</i>}</Text>
    </Section>
    {!isReport && (
      <Section>
        <Heading>Visibility</Heading>
        <Text>{event.includeInReport ? 'Included in report' : 'Not included in report'}</Text>
      </Section>
    )}
    {canEdit && (
      <FooterButtons
        eventType={event.eventType}
        canEdit={canEdit}
        addNewLabel="Add new event with same location and date"
        onAddNew={() => {
          onAddNew(event);
          onClose();
        }}
        onEdit={onEdit}
        onRemove={onRemove}
        onClose={onClose}
      />
    )}
  </div>
);
