import {
  createContext, useContext, ReactNode
} from 'react';

const ReportContext = createContext({ toc: [] as any });

interface ReportProviderProps<T> {
  value: { toc: T };
  children: ReactNode;
}

export const ReportProvider = <T, >({ value, children }: ReportProviderProps<T>) => (
  <ReportContext.Provider value={value}>
    {children}
  </ReportContext.Provider>
);

export const useReportContext = () => useContext(ReportContext);
