import { ExpenseFormType } from '@/types';
import deepEqual from 'deep-equal';

export const defaultExpenseItemModel = {
  id: '',
  name: '',
  cost: '',
  status: {
    label: 'Estimate',
    value: 'Estimate',
  },
  includeInReport: false,
  comment: '',
  includeCommentInReport: false
};

export const deepEqualsDefaultModel = (model: ExpenseFormType) => {
  const modelWithDefaultId = { ...model, id: '' };

  return deepEqual(modelWithDefaultId, defaultExpenseItemModel);
};
