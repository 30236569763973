import styled from 'styled-components';
import { TimelineVoyageVert } from '@instech/icons';

interface Props {
  isRight?: boolean;
  elsewhere?: boolean;
  locationBefore?: boolean;
}

const Wrapper = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  grid-area: ${props => props.isRight ? 'left' : 'right'};
`;

const Container = styled.div<Props>`
  position: absolute;
  width: 2px;
  bottom: -2px;
  top: ${props => props.locationBefore ? '-20px' : '-2px'};
  right: ${props => props.isRight && '61px'};
  left: ${props => !props.isRight && '59px'};
`;

export const PathElsewhere = ({ isRight, elsewhere, locationBefore }: Props) => {
  const renderElsewhere = elsewhere ? isRight : !isRight;
  return (
    <Wrapper isRight={renderElsewhere}>
      <Container isRight={renderElsewhere} locationBefore={locationBefore}>
        <TimelineVoyageVert height="100%" />
      </Container>
    </Wrapper>
  );
};
