import { useModalContext } from '@/components/modal/ModalContext';
import { openConfirmDeleteDialog } from '@/components/shared/ConfirmDeleteDialog';
import { getOptions } from '@/components/shared/Form/fields/Dropdown/OccurrenceSelect';
import { CellButton } from '@instech/components';
import { Trash } from '@instech/icons';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  LocationForm, LocationTableForm, deepEqualsDefaultModel, locationModel
} from './utils';

const ConfirmDeleteContent = ({ locationName }: { locationName: string }) => (
  <div>
    <p style={{ fontWeight: 'bold', marginTop: 0 }}>
      Are you sure you want to delete the location &quot;
      {locationName}
      &quot;?
    </p>
    <p style={{ marginBottom: '2.2rem' }}>This will also delete any attending representatives linked to this location</p>
  </div>
);

interface Props {
  row: LocationForm;
  locationIds: string[];
  removeFromArray: () => void;
  onDeleteOnServer: (locationId: string) => Promise<void>;
  onUpdateInitialId: (id: string) => void;
}
export const LocationDeleteButton: FC<Props> = ({
  row,
  locationIds,
  removeFromArray, // Unsure if FieldArray can be accessed from context?
  onDeleteOnServer,
  onUpdateInitialId
}) => {
  const { open } = useModalContext();
  const { values, dirty, resetForm } = useFormikContext<LocationTableForm>();

  const isLast = values.locations.length === 1;
  const deepEqualsDefault = deepEqualsDefaultModel({
    ...values.locations[0],
    occurrences: getOptions(values.locations[0].occurrences)
  });

  const confirmDeleteCallback = async () => {
    // Create a copy of  occurrences without the item about to be deleted
    const copyLocations = [...values.locations].filter(item => item.id !== row.id);

    // If the occurrence exists on the backend, send call to delete
    if (locationIds.includes(row.id)) {
      await onDeleteOnServer(row.id);
    }

    // Also send call for Formik to safely remove from FieldArray
    removeFromArray();

    if (!dirty) {
      // If deleting was all the user did, reset form to disable Save button
      resetForm({ values: { locations: copyLocations } });
    }
    if (isLast) {
      // If deleted occurrence was the last one, reset form to an empty state
      const newInitialItemId = uuidv4();
      onUpdateInitialId(newInitialItemId);
      const newState = [locationModel(newInitialItemId)];
      resetForm({ values: { locations: newState } });
    }
  };

  return (
    <CellButton
      lineLeft
      smallIcon
      icon={<Trash />}
      disabled={isLast && deepEqualsDefault}
      onClick={() => openConfirmDeleteDialog({
        content: <ConfirmDeleteContent locationName={row.location} />,
        callback: confirmDeleteCallback,
        open
      })}
    />
  );
};
