import { FunctionComponent } from 'react';
import { FieldArray } from 'formik';
import { CertificateListItem } from '@/services/certificatesServices';
import { CertificateList } from './CertificateList';
import { AddMoreButton } from '../../../shared/Buttons';

interface Props {
  fieldName: string;
  entries: CertificateListItem[];
  nameLabel: string;
  dateLabel: string;
  placeholder: string;
}
export const CertificateEntryList: FunctionComponent<Props> = ({ fieldName, entries, nameLabel, dateLabel, placeholder }) => (
  <FieldArray name={fieldName}
    render={arrayHelpers => (
      <div>
        <CertificateList
          arrayName={fieldName}
          entries={entries}
          nameLabel={nameLabel}
          dateLabel={dateLabel}
          placeholder={placeholder}
          arrayHelpers={arrayHelpers}
        />
        <AddMoreButton onClick={() => arrayHelpers.push({ name: '', date: null })} />
      </div>
    )} />
);
