import { LinkButton } from '@instech/components';
import { ChevronDown, WarningFilled } from '@instech/icons';
import { useState } from 'react';
import styled from 'styled-components';

const Warning = styled(WarningFilled)`
  color: ${props => props.theme.marineBlue};
  height: 32px;
  width: 32px;
`;
const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Header = styled.h1`
  color: ${props => props.theme.marineBlue};
  font-weight: bold;
  font-size: 24px;
`;

const Description = styled.p`
  color: ${props => props.theme.marineBlue};
`;

const SpoilerButton = styled(LinkButton)`
  text-decoration: underline;
  span {
    font-weight: normal;
  }
`;

const SpoilerIcon = styled(ChevronDown)<{ open: boolean }>`
  rotate: ${props => props.open ? '180deg' : '0deg'};
  transition: rotate 100ms ease-out;
`;

const SpoilerContainer = styled.div<{ open: boolean }>`
  max-height: ${props => props.open ? '30vh' : '0px'};
  transition: max-height 100ms ease-out;
  overflow: hidden;
`;

const ListContainer = styled.ol``;

const ListItem = styled.li`
  color: ${props => props.theme.marineBlue};
  line-height: 21px;
`;

export const PasswordProtectedPdfHandlerContent = () => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <CenteredContainer>
      <Warning />
      <Header>This PDF is password protected and cannot be uploaded</Header>
      <Description>Please provide an unlocked file</Description>
      <SpoilerButton onClick={toggleOpen} endIcon={<SpoilerIcon open={open} />}>Instructions to unlock PDF</SpoilerButton>
      <SpoilerContainer open={open}>
        <ListContainer>
          <ListItem>Open the PDF in Acrobat Pro on Windows or Mac.</ListItem>
          <ListItem>Choose Tools &gt; Encrypt &gt; Remove Security.</ListItem>
          <ListItem>If the document has a Document Open password, click OK to remove it.</ListItem>
          <ListItem>If the document has a permissions password, type the correct password in the Enter Password box and then click OK.</ListItem>
        </ListContainer>
      </SpoilerContainer>
    </CenteredContainer>
  );
};
