/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Table, TableHeader } from '@/components/shared/Table';

import { ExpenseTableItem } from './ExpenseTableItem';
import { deepEqualsDefaultModel } from '../core/common';

export const ExpenseTable = ({
  expenses,
  arrayHelpers,
  setFieldValue,
  currency,
}) => {
  const tableLayout = '50px 1.5fr 1fr 1fr 1fr 120px 60px';

  const { values } = useFormikContext();

  const isExactlyOneItemInList = values.expenses.length === 1;

  return (
    <Table layout={tableLayout}>
      <TableHeader button />
      <TableHeader>Expense</TableHeader>
      <TableHeader>{`Cost - ${currency}`}</TableHeader>
      <TableHeader>Status</TableHeader>
      <TableHeader button>Visualise in report</TableHeader>
      <TableHeader>Comment</TableHeader>
      <TableHeader button />
      {expenses.map((expense, i) => (
        <ExpenseTableItem
            key={expense.id}
            expenses={expenses}
            form={arrayHelpers.form}
            arrayId={`expenses.${i}`}
            number={i}
            length={expenses.length}
            setFieldValue={setFieldValue}
            onMoveDown={() => arrayHelpers.swap(i, i + 1)}
            onMoveUp={() => arrayHelpers.swap(i, i - 1)}
            disableDelete={isExactlyOneItemInList && deepEqualsDefaultModel(expense)}
            onRemove={() => arrayHelpers.remove(i)}
          />
      ))}
    </Table>
  );
};

ExpenseTable.propTypes = {
  expenses: PropTypes.arrayOf(PropTypes.object),
  arrayHelpers: PropTypes.object,
  setFieldValue: PropTypes.func,
  currency: PropTypes.string,
};
