import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TruncatingText } from '@instech/components';

const InfoRow = styled.div`
  display: flex; 
`;

const Container = styled.div`
  display: flex;
  max-width: 100px;
  :not(:last-of-type) {
    margin-right: 32px;
  }
  & svg { /* Styling for the icon */
    height: 16px;
    min-width: 16px;
    margin-right: 4px;
    color: ${props => props.theme.marineBlue};
  }
`;

const TextContainer = styled.div`
  overflow: hidden;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.blue};
  margin-bottom: 4px;
`;

const Value = styled(TruncatingText)`
  color: ${props => props.theme.blue};
`;

export interface Field {
  id: string;
  title: string;
  value: string;
  icon: FunctionComponent;
}

interface Props {
  items: Field[]
}

export const VesselInfo: FunctionComponent<Props> = ({ items }) => (
  <InfoRow>
    {items.map(({ id, title, value, icon: Icon }) => (
      <Container key={id}>
        <Icon />
        <TextContainer>
          <Label>{title}</Label>
          <Value text={value} wrap />
        </TextContainer>
      </Container>
    ))}
  </InfoRow>
);
