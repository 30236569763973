import { SurveyLocation } from '@/types';
import { ArchivedReport } from '@/utils/archivedReportTypes';

export const getSurveyLocations = (archiveReport: ArchivedReport): SurveyLocation[] => {
  const locations = archiveReport?.parts
    .flatMap(p => p.chapters)
    .flatMap(c => c.widgets)
    .filter(w => w.widgetType === 'SurveyLocations')
    .filter(w => w.data?.items?.length) // Widget must have more than 0 items
    .flatMap(w => w.data?.items as SurveyLocation[]) ?? [];

  return locations;
};
