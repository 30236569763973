import { TextField } from '@instech/components';
import { Checkbox } from '../Form/FormFields';

interface ArrayTextFieldProps {
  name: string;
  placeholder: string;
  readOnly?: boolean;
}
export const ArrayTextField = ({ name, placeholder, readOnly }: ArrayTextFieldProps) => (
  <TextField
    name={name}
    placeholder={placeholder}
    readOnly={readOnly}
    noLabel
  />
);

interface ArrayCheckboxProps {
  name: string;
  disabled?: boolean;
  title?: string;
}
export const ArrayCheckbox = ({ name, disabled, title }: ArrayCheckboxProps) => (
  <Checkbox name={name} disabled={disabled} title={title} />
);
