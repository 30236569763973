import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

function usePrevious<T>(value:T) {
  const [[current, previous], setValues] = useState<[T, T | undefined]>([value, undefined]);

  useEffect(() => {
    setValues([value, current]);
  }, [current, value]);

  return previous;
}

type Props<T> = {
  children: React.ReactNode;
  commentFieldName: keyof T & string;
  includeInReportFieldName: keyof T & string;
}

export function CommentIncludeInReportBehavior<T>({ children, commentFieldName, includeInReportFieldName }: Props<T>) {
  const { setFieldValue } = useFormikContext<T>();

  const [commentField] = useField<string>(commentFieldName);

  const comment = commentField.value;
  const previousComment = usePrevious(comment);

  useEffect(() => {
    if (previousComment === undefined) {
      return;
    }

    if (comment && !previousComment) {
      void setFieldValue(includeInReportFieldName, true);
    }

    if (!comment && previousComment) {
      void setFieldValue(includeInReportFieldName, false);
    }
  }, [previousComment, setFieldValue, comment, includeInReportFieldName]);

  return <>{children}</>;
}
