import { Page } from '@/components/layout/Page';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ContentArea = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  padding: 16px;
`;

export const AdminLandingPage = () => (
  <Page title="Administrate">
    <ContentArea>
      <Link to="/create">Create Case</Link>
      <div>____</div>
      <Link to="/companies">Manage companies</Link>
    </ContentArea>
  </Page>
);
