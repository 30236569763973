import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Close, Menu as MenuIcon } from '@instech/icons';
import { Chevron } from '@/components/shared/Chevron';

const Wrapper = styled.div`
  display: flex;
  height: 50px;
  justify-content: space-between;
`;

const Title = styled.div`
  padding: 4px 0px;
`;

const Return = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 0px 16px;
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    width: auto;
  }

  & ${Title} {
    margin-left: 4px;
  }
`;

const TabletMenu = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0px 16px;
  cursor: pointer;
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    display: none;
  }

  & ${Title} {
    margin-left: 10px;
  }
`;

const Menu = ({ menuOpen, onClick }) => (
  <TabletMenu data-testid="navigation-menu-button" onClick={() => onClick(value => !value)}>
    {menuOpen ? <Close /> : <MenuIcon />}
    <Title>Menu</Title>
  </TabletMenu>
);
Menu.propTypes = {
  menuOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export const NavigationHeader = ({ title, to, menuOpen, onClick }) => (
  <Wrapper>
    <Return to={to}>
      <Chevron left big thin />
      <Title>{title}</Title>
    </Return>
    <Menu menuOpen={menuOpen} onClick={onClick} />
  </Wrapper>
);
NavigationHeader.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  menuOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};
