import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import {
  addEquipmentsParticularsAsync,
  deleteEquipmentsParticularsAsync,
  NewEquipmentParticular,
  useEquipmentsParticulars,
  restoreDeletedEquipmentsParticularsAsync
} from '@/services/equipmentsParticularsServices';
import { Loader } from '@instech/components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useRestoreData, RestoreDeletedItemPopup } from '@/components/shared/RestoreDeletedItem';
import { VesselInformationForm } from '../shared/VesselInformationForm';
import { EquipmentList } from './EquipmentList';
import { fields, validationSchema } from './core';

const newEntryModel = {
  equipment: '',
  make: '',
  type: '',
  includeInReport: true
};

const Wrapper = styled.div`
  position: relative;
`;

const EditEquipmentsParticulars = () => {
  const caseId = useCaseIdFromUrl();
  const { state: restoreState, showRestoreDialog, hideRestoreDialog } = useRestoreData();
  const { data, error, mutate } = useEquipmentsParticulars();
  const [showNewItemForm, setShowNewItemForm] = useState(false);

  if (error) throw new Error(error);
  if (!data) return <Loader />;

  const onRestoreDeleted = (id: string) => async () => {
    const result = await restoreDeletedEquipmentsParticularsAsync(caseId, id);
    void mutate(result);
  };

  const onDelete = async (id: string) => {
    void mutate(existingItems => existingItems?.filter(x => x.id !== id), false);
    showRestoreDialog(onRestoreDeleted(id));
    await deleteEquipmentsParticularsAsync(caseId, id);
  };

  const submitNewEquipmentAsync = async (model: NewEquipmentParticular) => {
    const result = await addEquipmentsParticularsAsync(caseId, model);
    const newList = [...data, result];
    void mutate(newList);
  };

  return (
    <Wrapper data-testid="equipment-list">
      <EquipmentList entries={data} onDelete={onDelete} />
      <VesselInformationForm
        model={newEntryModel}
        fields={fields}
        onSubmit={submitNewEquipmentAsync}
        validationSchema={validationSchema}
        hideRestoreDialog={hideRestoreDialog}
        showNewItemForm={data.length === 0 || showNewItemForm}
        cancelIsReset={data.length === 0}
        setShowNewItemForm={setShowNewItemForm}
      />
      <RestoreDeletedItemPopup
        {...restoreState}
      />
    </Wrapper>
  );
};

const ReadOnlyEquipmentsParticulars = () => {
  const { data, error } = useEquipmentsParticulars();

  if (error) throw new Error(error);
  if (!data) return <Loader />;

  return (
    <div data-testid="equipment-list">
      <EquipmentList
        entries={data}
        onDelete={() => null}
        readonly
      />
    </div>
  );
};

interface EquipmentsParticularsProps {
  readonly?: boolean;
}
export const EquipmentsParticulars: FunctionComponent<EquipmentsParticularsProps> = ({ readonly = true }) => (
  readonly
    ? <ReadOnlyEquipmentsParticulars />
    : <EditEquipmentsParticulars />
);
