import { UserPicker } from '@instech/components';
import { updatePrimaryClaimsHandler, useAllowedUserInfoList } from '@/services/userInfoService';
import { Guid, UserInfo } from '@/types';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { ModalType } from '@/components/modal/types';
import { Form, Formik } from 'formik';
import { AreaLoader } from '@/components/shared/Loader';
import { ModalFormButtons } from '@/components/shared/FormButton';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { userPickerRequired } from '@/utils/validationSchemas';
import { roles } from '@/components/AppRouting/roles';

const changeClaimsHandlerValidation = Yup.object({
  claimsHandler: userPickerRequired('A Claims Handler must be selected')
});

type ClaimsHandlerFormValues = {
  claimsHandler: UserInfo | '';
};

interface ModalProps {
  userInfo?: UserInfo;
  close: () => void;
  claimId?: Guid | null;
}
const ChangePrimaryClaimsHandlerModal = ({ userInfo, close, claimId }: ModalProps) => {
  const caseId = useCaseIdFromUrl();
  const claimHandlers = useAllowedUserInfoList(roles.claimHandler, claimId, caseId);

  const initialValues: ClaimsHandlerFormValues = useMemo(() =>
    ({ claimsHandler: userInfo ? { ...userInfo, fullName: userInfo.displayName } : '' }), [userInfo]
  );

  if (!claimHandlers) {
    return <AreaLoader />;
  }

  const handleUpdate = async (values: ClaimsHandlerFormValues) => {
    if (values.claimsHandler) {
      const newClaimsHandler = values.claimsHandler as UserInfo;
      await updatePrimaryClaimsHandler(caseId, newClaimsHandler.id);
      close();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={changeClaimsHandlerValidation}
      onSubmit={handleUpdate}>
      {({ handleSubmit, isSubmitting, dirty }) => (
        <Form>
          <UserPicker name="claimsHandler" options={claimHandlers} />
          <ModalFormButtons
            onSave={handleSubmit}
            onCancel={close}
            isPending={isSubmitting}
            isDisabled={!dirty || isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export const showChangePrimaryClaimsHandlerModal = ({
  userInfo,
  close,
  claimId
}: ModalProps): ModalType<ModalProps> => ({
  component: ChangePrimaryClaimsHandlerModal,
  options: {
    title: 'Primary Claims Handler',
    size: 'medium',
    padding: '0px 20px 20px',
    useNewHeader: true
  },
  args: {
    userInfo,
    close,
    claimId
  }
});
