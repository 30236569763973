import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';

interface ModalObject {
  component: React.ComponentType;
  options: {
    [key: string]: any;
  },
  args: {
    callback?: () => (unknown | Promise<unknown>);
    [key: string]: any;
  }
}

interface OpenConfirmDeleteDialogProps {
  /** Name of the item to delete */
  name?: string;

  /** The JSX to render inside the confirm dialog box. Not compatible with the 'name' prop. */
  content?: JSX.Element;

  /** Function to trigger after dialog has been confirmed
   * If the callback returns a promise the modal will show a <Loader /> on the
   * confirm button until the promise has been fulfilled. If the promise rejects, the
   * modal will display a generic error message.
  */
  callback: () => (unknown | Promise<unknown>);

  /** The open function for the specific modal context to open.
   *
   * Retrieve it as a hook with `const { open } = useModalContext();` */
  open: (modalObj: ModalObject) => void;
}
export const openConfirmDeleteDialog = (
  { name, callback, open, content }: OpenConfirmDeleteDialogProps
) => {
  const modalObj: ModalObject = {
    component: ConfirmDeleteDialog as React.ComponentType,
    options: {
      size: 'small'
    },
    args: {
      name,
      callback,
      content
    }
  };
  open(modalObj);
};
