import PropTypes from 'prop-types';
import { FullwidthWidgetWrapper } from '@/components/pages/edit/widgets/FullwidthWidgetWrapper';
import { useExpensesService } from '@/services/expensesServices';
import { ExpenseList } from '../../components/ExpenseList';

export const GeneralExpenses = ({ widgetId, title }) => {
  const [data, update] = useExpensesService('GeneralExpenses', widgetId, { suspense: true });
  return (
    <FullwidthWidgetWrapper>
      <ExpenseList data={data} title={title} onSubmit={update} isStatic formId={widgetId} />
    </FullwidthWidgetWrapper>
  );
};
GeneralExpenses.propTypes = {
  widgetId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
