import useSWR from 'swr';

interface AllowedFileExtensionsResponse {
  image: string[];
  document: string[];
  other: string[];
}

export const getAllowedFileExtensionsUrl = () => '/allowedfileextensions';

const flattenDictionary = (data: AllowedFileExtensionsResponse) => ([
  ...data.document,
  ...data.image,
  ...data.other,
]);

const tenMinutesInMs = 1000 * 60 * 10;
export const useAllowedFileExtensions = () => {
  const swr = useSWR(getAllowedFileExtensionsUrl(), { suspense: true, dedupingInterval: tenMinutesInMs });
  return flattenDictionary(swr.data);
};
