import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Guid } from '@/types';
import { TimeMachine } from '@instech/icons';
import { useModalContext } from '@/components/modal/ModalContext';
import { showVersionsModal } from '../../../reportVersions/VersionsModal';

const Container = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: ${props => props.theme.marineBlue};
  
  & svg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-top: 2px;
  }
`;

const Text = styled.div`
  text-decoration: underline;
  margin-left: 8px;
`;

interface Props {
  id: Guid;
}
export const SeeAllVersions: FunctionComponent<Props> = ({ id }) => {
  const { open } = useModalContext();
  const showVersions = () => open(showVersionsModal(id));

  return (
    <Container>
      <TimeMachine />
      <Text onClick={showVersions}>See all versions</Text>
    </Container>
  );
};
