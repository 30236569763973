import { Pane, ReportPane } from '@/components/shared/Pane';
import { Table, TableHeader } from '@/components/shared/Table';
import { openCompanyParticularsTool } from '@/components/tools/CompanyParticulars';
import { useCompanyParticulars } from '@/services/companyParticularsServices';
import { getToolWidget, useManifest } from '@/services/manifestServices';
import { CompanyParticular } from '@/types';
import { Manifest } from '@/utils/manifestTypes';
import { NoAddedInformation } from '../VesselInformation/Components';
import { ToolItem } from './ToolItem';

interface ViewProps {
  data: {items?: CompanyParticular[];}
  title: string;
}

interface TableProps {
  particulars?: CompanyParticular[];
}

const CompanyParticularsTable = ({ particulars }: TableProps) => {
  if (!particulars || particulars.length === 0) return <NoAddedInformation />;
  return (
    <Table layout="auto auto">
      <TableHeader>Function</TableHeader>
      <TableHeader>Company</TableHeader>
      {particulars.map((particular, i) => (
        <ToolItem
            key={particular.id}
            title={particular.title}
            description={particular.description}
            index={i}
          />
      ))}
    </Table>
  );
};

export const CompanyParticularsView = ({ data, title }: ViewProps) => (
  <Pane title={title}>
    <CompanyParticularsTable particulars={data.items} />
  </Pane>
);

export const CompanyParticularsPreview = () => {
  const { data: particulars } = useCompanyParticulars();
  const { data: manifest } = useManifest() as { data: Manifest };

  const widget = getToolWidget(manifest, 'CompanyParticulars');

  const title = widget?.title ?? 'Company Particulars';

  return (
    <ReportPane title={title} open={openCompanyParticularsTool} editable>
      <CompanyParticularsTable particulars={particulars} />
    </ReportPane>
  );
};
