import styled from 'styled-components';
import { InfoCircle } from '@instech/icons';
import { FC } from 'react';

const Container = styled.div`
  display: flex;
  background-color: #fff;
  padding: 12px 24px 12px 12px;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
  flex-grow: 1;

  & svg {
    flex-shrink: 0;
  }
`;

const Content = styled.div`
  margin: 3px 0px 5px 10px;
  font-size: 16px;
  font-weight: bold;
`;

interface InfoBoxProps {
  text: string;
  className?: string;
}
export const InformationBox: FC<InfoBoxProps> = ({ text, className }) => (
  <Container className={className}>
    <InfoCircle />
    <Content>{text}</Content>
  </Container>
);

export const InformationRow = styled.div`
  display: flex;
  margin-bottom: 30px;

  & > *:not(:first-child) {
    margin-left: 20px;
  };
`;
