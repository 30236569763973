import {
  createContext, FunctionComponent, useContext
} from 'react';
import { Manifest, TransformedChapter } from '@/utils/manifestTypes';

export interface EditContextState {
  toc: Manifest | null;
  chapters: TransformedChapter[]
}
const EditContext = createContext<EditContextState>({ toc: null, chapters: [] });

export const EditProvider: FunctionComponent<{ value: EditContextState}> = ({ value, children }) => (
  <EditContext.Provider value={value}>
    {children}
  </EditContext.Provider>
);

export const useEditContext = () => useContext(EditContext);
