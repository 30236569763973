import { Dictionary } from '@/types';
import { OccurrenceReportView } from '@/components/pages/report/shared/components/OccurrenceReportView';
import { AttendingRepresentativesReportView } from '@/components/pages/report/shared/components/AttendingRepresentativesReportView';
import { SurveyLocationsReportView } from '@/components/pages/report/shared/components/SurveyLocationsReportView';
import { VesselMovementsReportView } from '@/components/pages/report/shared/components/VesselMovementsReportView';
import { DescriptionReportView } from '@/components/pages/report/shared/components/Description/DescriptionReportView';
import { DamageDescriptionReportView } from '@/components/pages/report/shared/components/Description/DamageDescriptionReportView';
import { RepairDescriptionReportView } from '@/components/pages/report/shared/components/Description/RepairDescriptionReportView';
import { ExpensesSummaryReportView } from '@/components/pages/report/shared/components/ExpensesSummaryReportView';
import { TimeSummaryReportView } from '@/components/pages/report/shared/components/TimeSummaryReportView/TimeSummaryReportView';
import { CompanyParticularsView } from '@/components/pages/report/shared/components/CompanyParticulars/CompanyParticularsView';
import { AvailableInformationReportView } from '@/components/pages/report/shared/components/AvailableInformation/AvailableInformationReportView';
import { VesselParticularsView } from '@/components/pages/report/shared/components/VesselInformation/VesselParticularsView';
import { CertificatesView } from '@/components/pages/report/shared/components/VesselInformation/CertificatesView';
import { EquipmentsParticularsView } from '@/components/pages/report/shared/components/VesselInformation/EquipmentsView';
import { IntroductionReportView } from '@/components/pages/edit/widgets/Introduction/IntroductionReportView';
import { TimeSavedDetailsReportView } from '../../shared/components/TimeSavedDetails';
import { CommentsView } from '../../shared/components/Comments/CommentsView';
import { ExpensesReportView } from '../../shared/components/Expenses';
import { TimeDetailsReportView } from '../../shared/components/TimeDetails';

export const widgetTypeToComponentMap: Dictionary<any> = {
  Introduction: IntroductionReportView,
  Occurrences: OccurrenceReportView,
  SurveyLocations: SurveyLocationsReportView,
  AttendingRepresentatives: AttendingRepresentativesReportView,
  VesselEvents: VesselMovementsReportView,
  Description: DescriptionReportView,
  DamageDescription: DamageDescriptionReportView,
  RepairDescription: RepairDescriptionReportView,
  ExpensesSummary: ExpensesSummaryReportView,
  TimeSummary: TimeSummaryReportView,
  CompanyParticulars: CompanyParticularsView,
  VesselInformation: VesselParticularsView,
  AvailableInformation: AvailableInformationReportView,
  Certificates: CertificatesView,
  EquipmentParticulars: EquipmentsParticularsView,
  TimeSavedDetails: TimeSavedDetailsReportView,
  Comments: CommentsView,
  TimeDetails: TimeDetailsReportView,
  Expenses: ExpensesReportView
};
