import {
  FunctionComponent, useState, useRef, useEffect
} from 'react';
import styled from 'styled-components';
import { useOutsideAlerter } from '@/utils/mouseEventHelpers';
import { RadioButtonControlled, RadioButtonLabel } from '@/components/shared/Form/fields/RadioButtons/RadioButtonControlled';
import { TruncatingText } from '@instech/components';
import { DropdownField } from '../FilterDropdown/DropdownField';
import { DropdownButtons } from '../core/DropdownButtons';
import {
  DropdownContainer, DropdownElement, ListContainer
} from '../core/Components';

interface ContainerProps {
  width?: string;
}
const Wrapper = styled.div<ContainerProps>`
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: ${props => props.width && props.width};
`;

interface Props {
  blocked: boolean;
  parameterName: string;
  selected: string;
  entries: string[];
  onChange: (value: string) => void;
}
export const FilterSelectDropdown: FunctionComponent<Props> = (
  { blocked, parameterName, selected, entries, onChange }
) => {
  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState<string>(selected);

  const apply = () => {
    onChange(currentValue);
    setOpen(false);
  };

  const cancel = () => {
    setCurrentValue(selected);
    setOpen(false);
  };

  // If dropdown closes or is blocked, trigger Cancel
  useEffect(() => {
    if (blocked || !open) cancel();
  }, [blocked, open]); // eslint-disable-line react-hooks/exhaustive-deps

  const clickRef = useRef(null);
  const onOutsideClick = () => setOpen(false);
  useOutsideAlerter(clickRef, open, onOutsideClick);

  const onToggleOpen = () => !blocked && setOpen(!open);

  return (
    <Wrapper ref={clickRef} width="200px">
      <DropdownField onClick={onToggleOpen} isOpen={open}>
        <TruncatingText text={selected} />
      </DropdownField>
      <DropdownContainer isOpen={open}>
        <DropdownElement first>
          <ListContainer>
            {entries.map(value => (
              <RadioButtonControlled
                key={`${parameterName}-radio-${value}`}
                id={`${parameterName}-radio-${value}`}
                name={`${parameterName}-radio`}
                label={value}
                checked={currentValue === value}
                onChange={() => setCurrentValue(value)}
              >
                <RadioButtonLabel>{value}</RadioButtonLabel>
              </RadioButtonControlled>
            ))}
          </ListContainer>
        </DropdownElement>
        <DropdownButtons
          onFilter={apply}
          onCancel={cancel}
        />
      </DropdownContainer>
    </Wrapper>
  );
};
