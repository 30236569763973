import { useModalContext } from '@/components/modal/ModalContext';
import { useTimelineContext } from '@/components/pages/edit/core/TimelineContext';
import { ContextMenu } from '@/components/shared/ContextMenu';
import { TimelineSegmentEvent } from '@/utils/timelineTypes';
import {
  Add, Edit, Trash
} from '@instech/icons';
import styled, { css } from 'styled-components';
import { openEventModal } from '../../modal/eventModal';
import { TimelineAnchor, VerticalPath } from '../core';
import { EventDate } from '../Event';
import { EventDetails } from './core/EventDetails';
import { StyledContainerProps, StyledEventProps } from './types';

const Container = styled.div<StyledContainerProps>`
  display: flex;
  ${props => !props.isRight && css`
    grid-area: left;
    justify-content: flex-end;
    padding-right: 14px;
  `};
  ${props => props.isRight && css`
    grid-area: right;
    justify-content: flex-start;
    padding-left: 14px;
  `};
`;

const Event = styled.div<StyledEventProps>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 400px;
  min-height: 64px;
  background-color: ${props => props.includeInReport ? props.theme.white : props.theme.lightGray};
  ${props => !props.includeInReport && css`
    color: #333;
  `};
  border: 1px solid ${props => props.theme.timeline[props.eventType].main};
  border-radius: 6px;
  transition: box-shadow 0.2s ease;
  box-sizing: border-box;
  ${props => !props.isRight && css`
    padding-right: 8px;
  `};
  ${props => props.isRight && css`
    flex-direction: row-reverse;
    padding-left: 8px;
  `};
  &::before {
    content: '';
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    background-color: ${props => props.includeInReport ? props.theme.white : props.theme.lightGray};
    border: 1px solid;
    border-color: 
      ${props => props.theme.timeline[props.eventType].main} 
      ${props => props.theme.timeline[props.eventType].main} 
      transparent transparent;
    transition: box-shadow 0.2s ease;
    ${props => !props.isRight && css`
      right: -0.70rem;
      transform: rotate(45deg);
    `};
    ${props => props.isRight && css`
      left: -0.70rem;
      transform: rotate(225deg);
    `};
  }
  &::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: ${props => props.theme.border.gray};
    ${props => !props.isRight && css`
      right: -27px;
    `};
    ${props => props.isRight && css`
      left: -27px;
    `};
  }
  @media (hover: hover) { 
    &:hover {
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.18);
      &::before {
        box-shadow: ${props => `${!props.isRight ? '4px ' : '2px'} -2px 2px 0 rgba(0,0,0,0.06)`};
      }
    }
  }
`;

interface Props {
  event: TimelineSegmentEvent;
  prevItem: any;
  isRight?: boolean;
  elsewhere?: boolean;
  canEdit: boolean;
  onRemove: {
    func: (value: string) => any;
    value: string;
  };
  onAddNew: (value: TimelineSegmentEvent) => any;
  displayInUtcTime: boolean;
}
/**
 * Renders an event card on the timeline, complete with the datetime at which it occurred.
 * Makes use of some data from the previous event before it in the timeline, to figure out
 * some of the metadata around it.
 *
 * Some of that metadata could potentially be moved out and handled parent to this component,
 * though it is located inside of here mostly to save some code complexity in the timeline
 * segment mapper. They are things that need to be mapped out anyway, it just looks a little
 * messy with all of it in one place here.
 */
export const TimelineEvent = ({
  event,
  prevItem,
  isRight = false,
  elsewhere = false,
  canEdit,
  onRemove,
  onAddNew,
  displayInUtcTime
}: Props) => {
  const { open } = useModalContext();
  const { locations } = useTimelineContext();
  const viewEventModal = openEventModal({ id: event.id, mode: 'view', canEdit, locations, onAddNew });
  const editEventModal = openEventModal({ id: event.id, mode: 'edit', canEdit, locations, onAddNew });

  // Work through all the variables here, so that the components know what to do.
  // It needs to work through ordinary vessel activities; activities with custom names, and
  // Occurrences with their name written in sub-text all in the same place here.
  const eventType = event.eventType || 'None';
  const locationBefore = prevItem && prevItem.type === 'location';
  const testid = !isRight ? 'timeline-event-left' : 'timeline-event-right';
  const isIncludedInReport = event.includeInReport === undefined || event.includeInReport;

  // If the event is Elsewhere, render opposite of the voyage path
  const isOtherSide = elsewhere ? !isRight : isRight;

  return (
    <TimelineAnchor padding="6px" id={event.id}>
      <EventDate
        eventType={eventType}
        utcDate={event.utcEventDate || ''}
        hasTime={(event.hasTime || event.eventTime) as boolean}
        localTimeOfDay={event.eventTime}
        localDate={event.eventDate || ''}
        displayInUtcTime={displayInUtcTime}
      />
      <Container isRight={isOtherSide}>
        <Event eventType={eventType} isRight={isOtherSide} data-testid={testid} includeInReport={isIncludedInReport}>
          <EventDetails
            event={event}
            eventType={eventType}
            isOtherSide={isOtherSide}
            isIncludedInReport={isIncludedInReport}
            onClick={() => open(viewEventModal)}
          />
          {eventType !== 'Occurrence' && canEdit && (
            <ContextMenu
              id={event.id}
              options={[
                {
                  icon: <Add />,
                  label: 'New event at this date',
                  onClick: () => onAddNew(event)
                },
                {
                  icon: <Edit />,
                  label: 'Edit event',
                  onClick: () => open(editEventModal)
                },
                {
                  icon: <Trash />,
                  label: 'Delete',
                  onClick: () => onRemove.func(onRemove.value)
                }
              ]}
            />
          )}
        </Event>
      </Container>
      {elsewhere && <VerticalPath isRight={isRight} elsewhere={elsewhere} locationBefore={locationBefore} />}
    </TimelineAnchor>
  );
};
