import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field, useField } from 'formik';
import {
  FieldLayout, ErrorMessage, InputField
} from '../../../../../shared/Form/core/Components';

/* In order to style the arrow on the dropdown, we need to wrap the
Select in a new element, add the arrow to the wrapper, and
then give the Select extra padding to make space. */
const DropdownField = styled(InputField)`
  position: relative;
  & select {
    padding-right: 40px;
    color: ${props => props.selected === '' && props.theme.placeholderGray};
  }
  &::after {
    content: '';
    position: absolute;
    height: 0.813em;
    width: 0.813em;
    margin-top: 0.625em;
    border-style: solid;
    border-color: ${props => props.theme.marineBlue};
    border-width: 0.1em 0.1em 0 0;
    box-sizing: border-box;
    transform: rotate(135deg);
    right: 15px;
    pointer-events: none;
  }
`;

const Option = styled.option`
  color: initial; /* Keep colour graying in parent from inheriting */
  &:first-child {
    color: ${props => props.theme.placeholderGray};
  }
  &:disabled {
    font-weight: bold;
    color: ${props => props.theme.black};
    padding-left: 0px;
  }
`;

export const CharterSelect = ({ name, options }) => {
  const [, meta] = useField(name);

  return (
    <FieldLayout error={meta.touched && meta.error}>
      <DropdownField selected={meta.value || ''}>
        <Field id={name} name={name} as="select">
          <Option value="" defaultValue>Select Charter</Option>
          {options.map(({ value, isHeader }, i) => (
            <Option key={value} disabled={isHeader} defaultValue={i === 0} value={value}>{value}</Option>
          ))}
        </Field>
      </DropdownField>
      <ErrorMessage>
        {meta.error && meta.touched ? meta.error : ''}
      </ErrorMessage>
    </FieldLayout>
  );
};

CharterSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    isHeader: PropTypes.bool
  })).isRequired,
};
