import styled from 'styled-components';
import { useModalContext } from '@/components/modal/ModalContext';
import { TableCell, TableRow } from '@/components/shared/Table';
import { openDownloadPdf } from '@/components/shared/FileDownload/FileDownloadModal';
import {
  MoveLayerDown, FileParagraph, Calendar
} from '@instech/icons';
import { Link } from 'react-router-dom';
import { reportViewRoute } from '@/utils/routeTemplates';
import { getFriendlyDate } from '@/utils/date';
import { showPdfFallbackVersionModal } from '@/components/modal/PdfGeneratedWithFallbackParametersModal';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import { roles } from '@/components/AppRouting/roles';
import { YellowWarning } from './YellowWarning';
import { StatusDot } from './StatusDot';

const MultiCell = styled(TableCell)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const MultiCellButton = styled(TableCell)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

const CellText = styled.div`
  padding: 5px;
`;

const StyledLinkButton = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

type ViewButtonProps = {
  to: string;
  onClick: () => void;
}
const ViewButton = ({ to, onClick }: ViewButtonProps) => (
  <StyledLinkButton to={to} onClick={onClick}>
    <FileParagraph />
    <CellText>View Report</CellText>
  </StyledLinkButton>
);

// Triggers a PDF download
type DownloadButtonProps = {
  onDownloadPdf: () => void;
}
const DownloadButton = ({ onDownloadPdf }: DownloadButtonProps) => (
  <StyledLinkButton to={null!} onClick={onDownloadPdf}>
    <MoveLayerDown />
    <CellText>Download</CellText>
  </StyledLinkButton>
);

const WarningCell = styled(MultiCell)`
  gap: 8px;
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
`;

type ReportVersionProps = {
  index: number;
  caseId: string;
  archivedId: string;
  versionId: number;
  status: string;
  lastModified: string;
  onReportView: () => void;
  pdfGenerated: boolean;
  interest: string;
  pdfGeneratedWithFallbackParameters: boolean;
}
export const ReportVersion = ({
  index,
  caseId,
  archivedId,
  versionId,
  status,
  lastModified,
  onReportView,
  pdfGenerated,
  interest,
  pdfGeneratedWithFallbackParameters
}: ReportVersionProps) => {
  const { open } = useModalContext();
  const currentRole = useCurrentRole();
  const isSurveyor = currentRole?.name === roles.surveyor;
  const reportRoute = reportViewRoute(caseId, archivedId);
  const even = index % 2 === 0;
  const showPdfWarning = pdfGeneratedWithFallbackParameters && isSurveyor;
  const onModalOpen = pdfGeneratedWithFallbackParameters ? () => open(showPdfFallbackVersionModal) : undefined;
  return (
    <TableRow even={even}>
      <WarningCell onClick={onModalOpen}>
        {showPdfWarning && (
          <YellowWarning />
        )}
        {versionId}
      </WarningCell>
      <MultiCell>
        <StatusDot status={status} />
        <CellText>{status}</CellText>
      </MultiCell>
      <TableCell>
        <CellText>{interest}</CellText>
      </TableCell>
      <MultiCell>
        <Calendar />
        <CellText>{getFriendlyDate(lastModified)}</CellText>
      </MultiCell>
      <MultiCellButton>
        <DownloadButton
          onDownloadPdf={() => open(openDownloadPdf(caseId, archivedId, pdfGenerated))}
        />
      </MultiCellButton>
      <TableCell>
        <ViewButton to={reportRoute} onClick={onReportView} />
      </TableCell>
    </TableRow>
  );
};
