import * as Yup from 'yup';
import { stringRequiredMaxLength } from '@/utils/validationSchemas';

export const validationSchema = Yup.object().shape({
  equipment: stringRequiredMaxLength(),
  make: stringRequiredMaxLength(),
  type: stringRequiredMaxLength(),
  includeInReport: Yup.boolean()
});

export const fields = [
  { name: 'equipment', label: 'Equipment', placeholder: 'Add equipment' },
  { name: 'make', label: 'Make / Manufacturer', placeholder: 'Add make / manufacturer' },
  { name: 'type', label: 'Type / Model', placeholder: 'Add type / model' }
];
