import dayjs from 'dayjs';
import { SurveyLocation } from '@/types';

const sortByDurationDesc = (locations: SurveyLocation[]) => locations.sort((a, b) => {
  const aDate = (a.duration.endDate || a.duration.startDate);
  const bDate = (b.duration.endDate || b.duration.startDate);
  if (!aDate) {
    return 1;
  }
  if (!bDate) {
    return -1;
  }
  if (dayjs(aDate).isSame(bDate)) return 0;

  return dayjs(aDate).isBefore(bDate) ? 1 : -1;
});

export default sortByDurationDesc;
