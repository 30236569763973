import { FunctionComponent } from 'react';
import styled from 'styled-components';

interface NoContentProps {
  text: string;
}

const Message = styled.div`
  display: flex;
  justify-content: center;
  font-style: italic;
`;

export const NoContent: FunctionComponent<NoContentProps> =
  ({ text }) => <Message>{text}</Message>;
