import { FunctionComponent } from 'react';
import styled from 'styled-components';

const Title = styled.div`
  font-weight: bold;
`;

const Summary = styled.div`
  display: block;
`;

const SummaryDetail = styled.div`
  padding: 5px;
`;

const Container = styled.div`
  margin: 15px 0px;
`;

interface SubmittedReportProps {
  versionId: number;
  status: string;
  reportType: string;
}

export const ArchivedSummary: FunctionComponent<SubmittedReportProps> = ({ versionId, status, reportType }) => {
  const versionText = `Version: ${versionId}`;
  const statusText = `Status: ${status}`;
  const typeText = `Type: ${reportType}`;
  const isDraft = status === 'Draft';
  const isPreview = status === 'Preview';

  return (
    <Container>
      <Title>{isDraft ? 'Archived draft' : 'Archived report'}</Title>
      <Summary>
        {!isPreview && <SummaryDetail>{versionText}</SummaryDetail>}
        <SummaryDetail>{typeText}</SummaryDetail>
        <SummaryDetail>{statusText}</SummaryDetail>
      </Summary>
    </Container>
  );
};
