import { CompanyParticular } from '@/types';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Form } from 'formik';
import { stringRequired, stringRequiredMaxLength } from '@/utils/validationSchemas';
import {
  FormField, FormRow, TextField, FormikWithPrompt as Formik
} from '@instech/components';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { companyParticularsBaseUrl, updateCompanyParticularAsync } from '@/services/companyParticularsServices';
import { mutate } from 'swr';

const particularSchema = Yup.object().shape({
  title: stringRequiredMaxLength(),
  description: stringRequired()
});

const Container = styled.div`
  background-color: ${props => props.theme.background.primary};
  padding: 15px;
  margin-bottom: 20px;
`;

interface Props {
  toggleEditMode: () => void;
  initialData: CompanyParticular;
}
export const CompanyParticularRowEdit = ({ initialData, toggleEditMode }: Props) => {
  const caseId = useCaseIdFromUrl();

  const submit = async ({ title, description }: { title: string, description: string }) => {
    const submitData = { ...initialData, title, description };
    await updateCompanyParticularAsync(caseId, submitData);
    await mutate(companyParticularsBaseUrl(caseId));
  };

  return (
    <Container>
      <Formik
        initialValues={{ title: initialData.title, description: initialData.description }}
        validationSchema={particularSchema}
        onSubmit={async values => {
          await submit(values);
          toggleEditMode();
        }}
      >
        {({ dirty, isSubmitting, handleSubmit }) => (
          <Form>
            <FormRow gutter="10px">
              <FormField span={6}>
                <TextField name="title" label="Function" placeholder='"Ship Owner"' />
              </FormField>
              <FormField span={6}>
                <TextField name="description" label="Company" placeholder='"High Sea Shipping LTD"' />
              </FormField>
            </FormRow>
            <SaveCloseButtons
              saveLabel="Save"
              closeLabel="Cancel"
              onSave={handleSubmit}
              disableSave={!dirty}
              isSubmitting={isSubmitting}
              onClose={toggleEditMode}
              alignRight
              reverse />
          </Form>
        )}
      </Formik>
    </Container>
  );
};
