import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { refreshOccurrencesAsync } from '@/services/occurrenceServices';
import { Interest } from '@/types';
import styled from 'styled-components';
import { Field } from '../Components';
import { RefreshClaimButton } from './RefreshClaimButton';
import { useRefreshState } from './useRefreshState';

const ClaimFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.warning};
  margin: 0;
`;

interface ClaimFieldProps {
  claimReference: string;
  occurrenceClaimId: string | null;
  occurrenceId: string;
  updateInterest: (occurrenceId: string, interest: Interest) => Promise<void>;
  userCanEdit: boolean;
}
const ClaimField = ({ claimReference, occurrenceClaimId, occurrenceId, updateInterest, userCanEdit }: ClaimFieldProps) => {
  const caseId = useCaseIdFromUrl();
  const { doneRefreshing, refreshState: { isRefreshing, hasRefreshed, hasRefreshError }, startRefresh, errorRefreshing } = useRefreshState();
  const refreshClaimIsDisabled = occurrenceClaimId === null;

  const refreshClaim = async () => {
    if (refreshClaimIsDisabled) return;
    startRefresh();
    const { data: interest, isError } = await refreshOccurrencesAsync(caseId, occurrenceId, occurrenceClaimId);

    if (isError) {
      errorRefreshing();
    } else {
      doneRefreshing();
      await updateInterest(occurrenceId, interest);
    }
  };

  return (
    <>
      <Field label="Claim Number" value={claimReference} />
      {userCanEdit && (
        <ClaimFieldContainer>
          <RefreshClaimButton onClick={refreshClaim} disabled={refreshClaimIsDisabled} isLoading={isRefreshing} hasRefreshed={hasRefreshed} />
          {hasRefreshError && <ErrorText>Could not refresh claim</ErrorText>}
        </ClaimFieldContainer>
      )}
    </>
  );
};

export default ClaimField;
