import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Case } from '@/types';

const LinkEntry = styled.div`
  border: 2px solid lightgray;
  margin: 8px 0;
  padding: 8px 16px;
  width: 100%;
  display: flex;
  background: #fefefe;
  box-sizing: border-box;
  > a {
    font-size: 16px;
    font-weight: bold;
    width: 100%;
  };
`;

const CaseLink: FunctionComponent<Case> = ({ id, name }) => (
  <LinkEntry key={id}>
    <Link to={`/case/${id}/dashboard`}>{name}</Link>
  </LinkEntry>
);

interface CreatedCasesListProps {
  cases: Case[];
}
export const CreatedCasesList = ({ cases }: CreatedCasesListProps) => (
  <div>
    {cases.map(CaseLink)}
  </div>
);
