import styled from 'styled-components';

const PageLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  padding: 20px;
  & > div {
    max-width: 600px;
  }
`;

const Wrapper = styled.div<{ marginTop?: string; }>`
  margin-top: ${props => props.marginTop};
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: unset;
  color: ${props => props.theme.marineBlue};
  margin: 0px 0px 12px 0px;
`;

const Text = styled.p`
  font-size: 16px;
  margin: 0px;
`;

interface NoAccessProps {
  marginTop?: string;
}
export const NoAccess = ({ marginTop }: NoAccessProps) => (
  <Wrapper marginTop={marginTop}>
    <Title>You have no access.</Title>
    <Text>If you need access, please reach out to your Norwegian Hull Club contact.</Text>
  </Wrapper>
);

export const NoAccessPage = () => (
  <PageLayout>
    <NoAccess />
  </PageLayout>
);
