import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { AssignedUsersPane } from '../SurveyorDashboard/AssignedUsers/AssignedUsersPane';
import { NavButtons } from './NavButtons';

const ContentArea = styled.div`
  display: grid;
  grid-template-columns: auto 400px;
  gap: 20px;
  background: ${props => props.theme.white};
  width: 100%;
  box-sizing: border-box; 
`;

const ToolArea = styled.div`
  color: ${props => props.theme.deepAzureBlue};
  background: ${props => props.theme.lightBlue};
`;

const FeedbackArea = styled.div`
  box-sizing: border-box;
  background: ${props => props.theme.lightBlue};
`;

interface ToolsAndFeedbackProps {
  readonly?: boolean;
}
export const ToolsAndFeedback: FunctionComponent<ToolsAndFeedbackProps> = ({ readonly = false }) => (
  <ContentArea>
    <ToolArea>
      <NavButtons />
    </ToolArea>
    <FeedbackArea>
      <AssignedUsersPane readonly={readonly} />
    </FeedbackArea>
  </ContentArea>
);
