/* eslint-disable react/jsx-props-no-spreading */
import { childrenPropType } from '@/prop-types/custom-prop-types';
import { Route } from 'react-router-dom';
import { RequireAuth } from './RequireAuth';

export const ProtectedRoute = ({ children, ...rest }) => (
  <Route {...rest}>
    <RequireAuth>
      {children}
    </RequireAuth>
  </Route>
);

ProtectedRoute.propTypes = {
  children: childrenPropType
};

export default ProtectedRoute;
