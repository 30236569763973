/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { childrenPropType } from '@/prop-types/custom-prop-types';
import { BoxButton } from '@instech/components';

const StyledButton = styled(BoxButton)`
  box-sizing: border-box;
  min-height: ${props => !props.height && '40px'};
  border: 1px solid ${props => props.inverted ? props.theme.lightGray : props.theme.marineBlue};
  border-radius: 2px;
  cursor: pointer;
  color: ${props => props.inverted ? props.theme.marineBlue : props.theme.white};
  background-color: ${props => props.inverted ? props.theme.lightGray : props.theme.marineBlue};
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
  transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, width 0.3s ease;
  padding: 0px 8px;
  
  // Apply some style changes to the text
  > div > span {
    font-size: ${props => props.fontSize ? props.fontSize : '14px'};
    text-transform: ${props => !props.lowercase && 'uppercase'}; // Uppercase is the default, lowercase is the option
    margin-top: -1px;
  }

  & svg {
    max-height: 16px;
    max-width: 16px;
  }

  :active&:not(:disabled){
    padding-left: 9px;
    padding-right: 7px;
  }
  :disabled {
    color: ${props => props.inverted ? props.theme.black : props.theme.darkGray};
    background-color: ${props => props.inverted ? props.theme.ultraLightGray : props.theme.mediumGray};
    border-color: transparent;
    cursor: default;
  }

  @media (hover: hover) { 
    &:hover&:not(:disabled) {
      background-color: ${props => props.inverted ? props.theme.mediumGray : props.theme.marineBlue75};
      border-color: ${props => props.inverted ? props.theme.mediumGray : props.theme.marineBlue75};
    }; 
  }
`;

/**
 * This is a new implementation of the BoxButton, currently for use in the new ClaimsHandlerDashboard.
 * It still uses the original BoxButton for its baseline, though it is considerably different visually,
 * and will probably at some point be merged with the original?
 */
export const NewBoxButton = props => (
  <StyledButton padding="4px 8px" height="48px" {...props}>{props.children}</StyledButton>
);

NewBoxButton.propTypes = {
  children: childrenPropType,
  inverted: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  lowercase: PropTypes.bool,
  boldFont: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  className: PropTypes.string,
  title: PropTypes.string,
  padding: PropTypes.string
};
