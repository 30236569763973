import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Page } from '@/components/layout/Page';
import { ErrorBoundary } from '@instech/components';
import { DashboardHeader } from './DashboardHeader';
import { ErrorPage } from '../../error/ErrorPage';

const ContentArea = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 50px 0px;
`;

interface DashboardBasePageProps {
  name: string;
}

export const DashboardBasePage: FunctionComponent<DashboardBasePageProps> = ({ name, children }) => (
  <ErrorBoundary component={ErrorPage}>
    <Page title={name}>
      <DashboardHeader
        section="My Assignments"
        title={name}
        to="/"
      />
      <ContentArea>
        {children}
      </ContentArea>
    </Page>
  </ErrorBoundary>
);
