import * as Yup from 'yup';
import { stringRequiredMaxLength } from '@/utils/validationSchemas';

export const fields = [
  { name: 'title', label: 'Title', placeholder: 'Add title' },
  { name: 'value', label: 'Description', placeholder: 'Add description' }
];

export const validationSchema = Yup.object().shape({
  title: stringRequiredMaxLength(),
  value: stringRequiredMaxLength(120),
  includeInReport: Yup.boolean(),
  editable: Yup.boolean()
});
