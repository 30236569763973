import {
  ButtonGroup, SlimButton, WarningNotification
} from '@instech/components';
import { FC } from 'react';

interface NoClaimsMessageProps {
  closeModal: () => void;
}
const NoClaimsMessage: FC<NoClaimsMessageProps> = ({ closeModal }) => (
  <>
    <WarningNotification size="large" headingText="No Claims" descriptionText="There are no available claims for this occurrence." />
    <ButtonGroup alignRight>
      <SlimButton variant="secondary" onClick={closeModal}>CANCEL</SlimButton>
    </ButtonGroup>
  </>
);

export default NoClaimsMessage;
