import styled from 'styled-components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { UploadImageForm } from './UploadImageForm';

// The default margin for the space above this would be 32px, but
// the error message field for the image form takes up 20px of that.
// So this would make the spacing above the box 32px in total.
const GreyBox = styled.div`
  height: 50px;
  background-color: ${props => props.theme.flatWhite};
  margin-top: 12px; 
`;

export const UploadImageContainer = () => {
  const caseId = useCaseIdFromUrl();
  return (
    <div>
      <UploadImageForm caseId={caseId} />
      <GreyBox />
    </div>
  );
};
