import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTermsOfuse } from '@/services/termsOfUseServices';
import { Loader, Pane } from '@instech/components';
import { RichTextView } from '@/components/shared/Form/RichTextView';
import { DownloadButton } from './DownloadButton';

/* Float download button to top-right of page content */
const AlignButton = styled.div`  
  float: right;
  margin-left: 16px;
  margin-bottom: 16px;
  /* Ensure button is over the text it's floating around */
  position: relative;
  z-index: 10;
`;

export const TermsPane: FunctionComponent = () => {
  const { data: termsText } = useTermsOfuse();

  return (
    <Pane margin="32px 0px 0px">
      {!termsText ? (
        <Loader />
      ) : (
        <>
          <AlignButton>
            <DownloadButton />
          </AlignButton>
          <RichTextView data={{ text: termsText }} />
        </>
      )}
    </Pane>
  );
};
