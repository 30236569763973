import { NewBoxButton } from '@/components/shared/BoxButton/NewBoxButton';
import { ErrorMessage } from '@/components/shared/Form/core/Components';
import { UserInfoTable } from '@/components/shared/UserInfoTable';
import { Guid, UserInfo } from '@/types';
import { FC, useMemo } from 'react';
import styled from 'styled-components';

const SelectArea = styled.div`
  padding: 20px;
  background: ${props => props.theme.white};
  & ~ & { // avoid 40px gap between two successive SelectAreas
    padding-top: 0px;
  }
`;

const Title = styled.h4`
    margin: 0;
    margin-bottom: 4px;
    color: ${props => props.theme.marineBlue};
`;

const FooterLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  // Override alignment without changing underlying component
  & ${ErrorMessage} {
    text-align: left;
  }
`;

// Normalize user list, which can be an array or a single user, to an array
const normalizeUserList = (users?: UserInfo | UserInfo[]): UserInfo[] => {
  if (!users) return [];
  if (!Array.isArray(users)) return [users];
  return users;
};

interface Props {
  label: string;
  buttonText?: string;
  users?: UserInfo | UserInfo[];
  primaryValue?: Guid;
  userError?: string | false;
  primaryError?: string | false;
  openModal: () => void;
}
export const UserSelectArea: FC<Props> = ({
  label,
  buttonText = 'Select Users',
  users,
  primaryValue,
  userError,
  primaryError,
  openModal
}) => {
  const userList = useMemo(() => normalizeUserList(users), [users]);
  return (
    <SelectArea>
      <Title>{label}</Title>
      <UserInfoTable
        users={userList}
        primaryId={primaryValue}
      />
      <FooterLayout>
        <div>
          {userError && <ErrorMessage>{userError}</ErrorMessage>}
          {primaryError && <ErrorMessage>{primaryError}</ErrorMessage>}
        </div>
        <NewBoxButton
          type="button"
          width="108px"
          lowercase
          onClick={openModal}>
          {buttonText}
        </NewBoxButton>
      </FooterLayout>
    </SelectArea>
  );
};
