import styled from 'styled-components';
import { Page } from '@/components/layout/Page';

const ContentArea = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
`;

const ComponentWrapper = styled.div``;

export const SandboxPage = () => (
  <Page>
    <ContentArea>
      <h1>Sandbox page</h1>
      <p>Use this page to test your components in isolation when developing</p>

      <ComponentWrapper>
        <code>Your component here!</code>

        {/* <ModalLayout closeModal={() => {}}>
          <ConfirmDeleteDialog callback={() => {}} />
        </ModalLayout> */}

      </ComponentWrapper>
    </ContentArea>
  </Page>
);
