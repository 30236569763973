import {
  FaListUl, FaListOl, FaBold, FaItalic, FaQuoteRight
} from 'react-icons/fa';

export const headers = [
  { label: 'Paragraph', value: 'unstyled' },
  { label: 'Header 1', value: 'header-one' },
  { label: 'Header 2', value: 'header-two' },
  { label: 'Header 3', value: 'header-three' },
];
export const inline = [
  { label: 'Bold', style: 'BOLD', icon: FaBold },
  { label: 'Italic', style: 'ITALIC', icon: FaItalic }
];

export const inlineItalic = [
  { label: 'Italic', style: 'ITALIC', icon: FaItalic }
];

export const blocks = [
  { label: 'List', style: 'ordered-list-item', icon: FaListOl },
  { label: 'Bullets', style: 'unordered-list-item', icon: FaListUl },
  { label: 'Blockquote', style: 'blockquote', icon: FaQuoteRight },
];
