import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { MoreVert } from '@instech/icons';
import { ScreenReaderOnly } from '@/components/shared/ScreenReaderOnly';
import { LinkButton, useCloseOnClickOutside } from '@instech/components';
import { childrenPropType } from '../../../prop-types/custom-prop-types';
import { Menu } from './core/Menu';

const Anchor = styled.div`
  position: relative;
  height: ${props => props.size};
`;

/**
 * Renders a clickable Context Menu button, which will open to show the
 * provided Options when clicked.
 * TODO: Should be deprecated and replaced with the new ContextMenu from @instech/components
 */
export const ContextMenu = ({ id, size = '42px', options, buttonColor = 'marineBlue50', screenReaderTitle = 'More options' }) => {
  const [showing, setShowing] = useState(false);
  const anchorRef = useRef(null);
  const theme = useTheme();

  useCloseOnClickOutside(showing, setShowing, anchorRef);

  return (
    <Anchor ref={anchorRef} size={size} data-testid="context-menu">
      <LinkButton
        startIcon={<MoreVert />}
        color={theme[buttonColor]}
        height={size}
        onClick={() => setShowing(!showing)}
        srTextOnly
      >
        <ScreenReaderOnly>{screenReaderTitle}</ScreenReaderOnly>
      </LinkButton>
      {showing && (
        <Menu id={id} setShowing={setShowing} options={options} />
      )}
    </Anchor>
  );
};
ContextMenu.propTypes = {
  id: PropTypes.string,
  size: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    icon: childrenPropType,
    label: PropTypes.string,
    onClick: PropTypes.func
  })),
  buttonColor: PropTypes.string,
  screenReaderTitle: PropTypes.string
};
