import { FunctionComponent, ComponentProps } from 'react';
import { useStatusTypes } from '@/services/configurationServices';
import { Dropdown } from '@instech/components';
import { DropdownLoader } from './core';

export const StatusTypeDropdownField: FunctionComponent<ComponentProps<typeof Dropdown>> = props => {
  const { data } = useStatusTypes();

  if (!data) {
    return <DropdownLoader />;
  }

  const transformed = data.map(item => ({ value: item.id, label: item.name }));

  return (
    <Dropdown
      placeholder="Select status"
      options={transformed}
      {...props}
    />
  );
};
