/* eslint-disable no-param-reassign */
import { TimelineEntryOption, TimelineEntryGroup } from '@/types';

const getOptions = (options: TimelineEntryOption[]) => (
  options.reduce((obj: any, item) => {
    const { name, selectOccurrences, useCustomName } = item;
    obj[name] = { selectOccurrences, useCustomName };
    return obj;
  }, {})
);

const getOptionsName = (options: TimelineEntryOption[]) => (
  options.map(({ name }) => ({ value: name, label: name }))
);

export const getFormFieldSchema = (response: TimelineEntryGroup[] = []) => (
  response.reduce((obj: any, item) => {
    obj[item.name] = getOptions(item.options);
    return obj;
  }, {})
);

export const getDropdownOptions = (response: TimelineEntryGroup[] = []) => (
  response.reduce((obj: any, item) => {
    obj[item.name] = getOptionsName(item.options);
    return obj;
  }, {})
);
