import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import {
  CaseMetadata, Guid, LabelValuePair, Occurrence, UserInfo
} from '@/types';
import {
  dropdownOptionRequired, datePickerRequired,
  stringMaxLength, stringRequired, stringRequiredMaxLength, userPickerMultiRequired, userPickerRequired
} from '@/utils/validationSchemas';
import { CreateCaseModel } from '../admin/adminServices';

interface CaseMetadataForm extends Omit<CaseMetadata, 'conditions'> {
  conditions: LabelValuePair | null;
}

/**
 * Variant interface of CreateCaseModel, but with a few added properties
 * and using UserInfo for selected users instead of ID strings.
 */
export interface CreateCaseFormModel extends Omit<CreateCaseModel, 'users' | 'metadata' | 'templateId'> {
  template: string;
  claimNumber: string;
  year: string;
  activeClaimWall: boolean;
  metadata: CaseMetadataForm;
  users: {
    surveyors: UserInfo[],
    claimsHandler: UserInfo | '',
    primaryClaimsHandlerId?: Guid,
    primarySurveyorId?: Guid
  }
}

export const getOccurrenceValues = (occurrence?: Occurrence): Occurrence => ({
  id: occurrence?.id || uuidv4(),
  name: occurrence?.name || '',
  dateOfLoss: occurrence?.dateOfLoss || '',
  location: occurrence?.location || '',
  charter: occurrence?.charter || '',
  timeOfLoss: occurrence?.timeOfLoss || null,
  timezoneId: occurrence?.timezoneId || null,
  conditions: occurrence?.conditions || null,
  claimEventName: occurrence?.claimEventName || null,
  fleetName: occurrence?.fleetName || null,
  className: occurrence?.className || null,

  interests: [
    {
      claimId: occurrence?.interests[0].claimId || null,
      claimInterest: occurrence?.interests[0].claimInterest || 'HullAndMachinery',
      claimReference: occurrence?.interests[0].claimReference || '',
      interest: occurrence?.interests[0].interest || '',
      interestGroup: occurrence?.interests[0].interestGroup || null,
      claimLeaderName: occurrence?.interests[0].claimLeaderName || null,
      ourShare: occurrence?.interests[0].ourShare || null,
      activeClaimWall: occurrence?.interests[0].activeClaimWall || false,
      deductible: occurrence?.interests[0].deductible || null,
    }
  ]
});

export const createCaseInitialValues = (templateId: Guid, currentUser: UserInfo): CreateCaseFormModel => ({
  claimNumber: '',
  year: '',
  name: '',
  template: templateId,
  claimId: '',
  metadata: {
    claimId: '',
    interest: '',
    conditions: null,
    policyCurrency: '',
    expenseCurrency: '',
    deductible: '',
    claimReference: '',
    vesselName: ''
  },
  instructions: '',
  companyParticulars: undefined,
  vesselInformation: undefined,
  occurrence: getOccurrenceValues(),
  users: {
    surveyors: [],
    claimsHandler: currentUser,
    primaryClaimsHandlerId: currentUser.id,
    primarySurveyorId: undefined
  },
  activeClaimWall: false,
  broker: {
    id: '',
    caseId: '',
    companyName: '',
    externalId: '',
    name: '',
    email: '',
    phone: ''
  }
});

export const createCaseSchema = (templates: { id: string, name: string }[]) => (
  Yup.object().shape({
    claimNumber: Yup.string(),
    year: Yup.string(),
    name: stringRequiredMaxLength(),
    template: Yup.string().required('Required'),
    claimId: Yup.string(),
    metadata: Yup.object().shape({
      interest: Yup.string(),
      conditions: dropdownOptionRequired(),
      policyCurrency: Yup.string(),
      expenseCurrency: Yup.string(),
      deductible: Yup.string(),
      claimReference: Yup.string(),
      vesselName: stringMaxLength()
    }),
    instructions: Yup.string(),
    companyParticulars: Yup.array().of(Yup.object().shape({
      id: Yup.string(),
      title: stringMaxLength(),
      description: Yup.string(),
      source: Yup.object().shape({
        name: stringMaxLength(),
        date: Yup.string()
      }).nullable()
    })).nullable(),
    vesselInformation: Yup.array().of(Yup.object().shape({
      id: Yup.string(),
      title: stringMaxLength(),
      value: stringMaxLength(),
      includeInReport: Yup.boolean(),
      source: Yup.object().shape({
        name: stringMaxLength(),
        date: Yup.string()
      }),
      editable: Yup.boolean()
    })).nullable(),
    occurrence: Yup.object().shape({
      id: stringRequired(),
      name: stringRequiredMaxLength(120),
      dateOfLoss: datePickerRequired(),
      claimReference: Yup.string(),
      location: stringRequiredMaxLength(),
      charter: Yup.string(),
      timeOfLoss: Yup.string().nullable(),
      timezoneId: Yup.string().nullable()
    }),
    users: Yup.object().shape({
      claimsHandler: userPickerRequired('Must select a primary Claims Handler'),
      surveyors: userPickerMultiRequired('Must select at least one Surveyor'),
      primaryClaimsHandlerId: Yup.string().required('Must select a primary Claims Handler'),
      primarySurveyorId: Yup.string().required('Must select a primary Surveyor'),
    }),
    activeClaimWall: Yup.boolean()
  })
);
