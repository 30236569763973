import { Field } from '@/components/shared/Field';
import styled from 'styled-components';
import { toReadableDate } from './utils';
import { MapLocationToOccurrencesReturn } from './utils/mapLocationToOccurrences';

const OccurrenceContainer = styled.div`
  background-color: ${({ theme }) => theme.marineBlue10};
  color: #003C71;

  &:last-of-type {
    padding-bottom: 0px;
  }
`;

const OccurrenceRow = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(6, 1fr);
  background-color: ${({ theme }) => theme.white};
  padding: 12px 32px;
  box-shadow: 0px 2px 6px 0 rgba(0, 60, 113, 0.25);
`;

const InterestContainer = styled.div`
  padding: 0 16px;
`;

const InterestRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 12px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.marineBlue50};

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
  :last-child {
    border: 0;
  }
`;

interface OccurrenceWithMultipleInterestsRowProps extends MapLocationToOccurrencesReturn {
  policyCurrency: string | undefined;
}
export const OccurrenceWithMultipleInterestsRow = ({
  name,
  dateOfLoss,
  location,
  dateOfSurvey,
  surveyLocation,
  policyCurrency,
  conditions,
  interests
}: OccurrenceWithMultipleInterestsRowProps) => {
  const hasInterestConnectedToIns = interests.length >= 1 && interests[0].claimReference !== '';
  return (
    <OccurrenceContainer>
      <OccurrenceRow>
        <Field label="Occurrence" value={name} />
        <Field label="Date of loss" value={toReadableDate(dateOfLoss)} />
        <Field label="Loss location" value={location} />
        <Field label="Date of survey" value={dateOfSurvey} />
        <Field label="Survey location" value={surveyLocation} />
        <Field label="Policy Currency" value={policyCurrency} />
        <Field label="Conditions" value={conditions} />
      </OccurrenceRow>
      <InterestContainer>
        {!hasInterestConnectedToIns && (
          <InterestRow>
            <Field label="Interest" value="TBA by Claims Handler" />
            <Field label="Deductible" value="TBA by Claims Handler" />
            <Field label="Claim Number" value="TBA by Claims Handler" />
          </InterestRow>
        )}
        {hasInterestConnectedToIns && interests.map(interest => (
          <InterestRow key={interest.claimReference}>
            <Field label="Interest" value={interest.interest} />
            <Field label="Deductible" value={interest.deductible} />
            <Field label="Claim Number" value={interest.claimReference} />
          </InterestRow>
        ))}
      </InterestContainer>
    </OccurrenceContainer>
  );
};
