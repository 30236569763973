import { FunctionComponent } from 'react';
import { Pane, PreviewReportPane } from '@/components/shared/Pane';
import { useSummaryOfCost } from '@/services/summaryofcostService';
import { ExpenseSummaryGroup, SummaryOfCost } from '@/types';
import { calculateSummary, mapComments } from './utils';
import { Costs } from './Components';
import { useMetadataContext } from '../../core/MetadataContext';

interface ExpensesSummaryProps {
  data: SummaryOfCost[];
}
const ExpensesSummary: FunctionComponent<ExpensesSummaryProps> = ({ data }) => {
  const { metadata: { expenseCurrency } } = useMetadataContext();
  const { markedData, comments } = mapComments(data);
  const summary = calculateSummary(markedData);
  return (
    <Costs summary={summary} comments={comments} currency={expenseCurrency} />
  );
};

interface PreviewProps {
  caseId: string;
  chapterId: string;
}
export const ExpensesSummaryReportPreview: FunctionComponent<PreviewProps> = ({ caseId, chapterId }) => {
  const { data } = useSummaryOfCost({ suspense: true }) as { data: ExpenseSummaryGroup[] };
  const expenses = data.flatMap(d => d.expenses);
  return (
    <PreviewReportPane title="Summary of cost" caseId={caseId} chapterId={chapterId}>
      <ExpensesSummary data={expenses} />
    </PreviewReportPane>
  );
};

interface ViewProps {
  data: {
    items: SummaryOfCost[];
  }
}
export const ExpensesSummaryReportView: FunctionComponent<ViewProps> = ({ data }) => (
  <Pane title="Summary of cost">
    <ExpensesSummary data={data.items} />
  </Pane>
);
