import { FunctionComponent } from 'react';
import styled from 'styled-components';

export const Text = styled.div`
  padding-bottom: 20px;
`;

export const Header: FunctionComponent = ({ children }) => (
  <Text>
    <b>
      {children}
    </b>
  </Text>
);
