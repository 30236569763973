import { useReducer } from 'react';

interface RefreshState {
  isRefreshing: boolean;
  hasRefreshed: boolean;
  hasRefreshError: boolean;
}
const initialState: RefreshState = {
  isRefreshing: false,
  hasRefreshed: false,
  hasRefreshError: false
};

const actions = {
  start: 'start-refreshing',
  done: 'done-refreshing',
  error: 'error-refreshing'
};

const reducer = (state: RefreshState, action: string): RefreshState => {
  switch (action) {
    case actions.start:
      return { isRefreshing: true, hasRefreshed: false, hasRefreshError: false };
    case actions.done:
      return { isRefreshing: false, hasRefreshed: true, hasRefreshError: false };
    case actions.error:
      return { isRefreshing: false, hasRefreshed: false, hasRefreshError: true };
    default:
      throw new Error();
  }
};

export const useRefreshState = () => {
  const [refreshState, dispatch] = useReducer(reducer, initialState);

  const startRefresh = () => dispatch(actions.start);
  const doneRefreshing = () => dispatch(actions.done);
  const errorRefreshing = () => dispatch(actions.error);

  return { refreshState, startRefresh, doneRefreshing, errorRefreshing };
};
