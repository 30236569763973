import { Guid, TimelineItem } from '@/types';
import useSWR from 'swr';

export const useLatestArchivedReportSummary = (caseId?: Guid) => {
  const swr = useSWR(caseId ? `case/${caseId}/archivedreportdata/summary` : null);

  return swr;
};

export const useLatestArchivedReportTimeline = (caseId?: Guid) => {
  const swr = useSWR<TimelineItem[] | null>(caseId ? `case/${caseId}/archivedreportdata/timeline` : null);

  return swr;
};
