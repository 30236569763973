import { CaseInstruction } from '@/types';
import useSWR from 'swr';

const instructionUrl = (caseId: string) => `case/${caseId}/caseinstruction`;

export const useCaseInstruction = (caseId: string) => {
  const swrRoute = instructionUrl(caseId);

  const { data, error } = useSWR<CaseInstruction>(swrRoute);

  if (error) {
    throw error;
  }

  return data;
};
