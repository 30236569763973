import {
  Switch, Route, Redirect
} from 'react-router';
import { AdminLandingPage } from '../pages/admin/AdminLandingPage';
import { CompaniesPage } from '../pages/admin/CompaniesPage';
import { CompanyEditPage } from '../pages/admin/CompanyEditPage';
import { CreateCasePage } from '../pages/CreateCasePage';

export const AdminRoutes = () => (
  <Switch>
    <Route exact path="/create">
      <CreateCasePage />
    </Route>
    <Route exact path="/companies">
      <CompaniesPage />
    </Route>
    <Route exact path="/companies/:companyId">
      <CompanyEditPage />
    </Route>
    <Route exact path="/">
      <AdminLandingPage />
    </Route>
    <Route path="*" render={() => <Redirect to="/" />} />
  </Switch>
);
