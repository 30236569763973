import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Form } from 'formik';
import * as Yup from 'yup';
import {
  Divider, FormField, FormRow, TimePicker, FormikWithPrompt as Formik
} from '@instech/components';
import { TimezonePicker } from '@/components/shared/Form/FormFields';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import { Guid, TimelineItem } from '@/types';
import { constructDateTime, getCurrentTimezoneOrDefault } from '@/utils/date';

const validationSchema = () => Yup.object().shape({
  eventTime: Yup.string().nullable(),
  timezoneId: Yup.string().nullable().required('Required'),
});

const Indent = styled.div`
  padding: 10px 30px 0px;
`;

interface EditOccurrenceFormValues {
  occurrenceId: Guid;
  timeOfLoss?: string | null;
  timezoneId?: string | null;
}

interface Props {
  onClose: () => void;
  onSubmit: (values: EditOccurrenceFormValues) => Promise<boolean>;
  occurrenceId: string;
  occurrenceEvent: TimelineItem;
}
export const EditOccurrenceForm: FunctionComponent<Props> = ({ onSubmit, onClose, occurrenceId, occurrenceEvent }) => {
  const [error, setError] = useState<string|undefined>(undefined);

  if (error) {
    throw new Error(error);
  }
  const initialValues = {
    timeOfLoss: occurrenceEvent.eventTime,
    timezoneId: occurrenceEvent.timezoneId ?? getCurrentTimezoneOrDefault()
  };

  return (
    <Formik
      formId="edit-event-form"
      initialValues={initialValues}
      validationSchema={validationSchema()}
      onSubmit={async (values, { resetForm }) => {
        const formValues = { ...values, occurrenceId };
        try {
          const success = await onSubmit(formValues);
          if (success) {
            resetForm({ values: { ...values } });
            onClose();
          }
        } catch (err) {
          setError(err as string);
        }
      }}
    >
      {({ values, dirty, isSubmitting, handleSubmit }) => {
        const dateTime = constructDateTime(occurrenceEvent.eventDate, values.timeOfLoss);
        return (
          <Form>
            <Indent>
              <FormRow gutter="20px" marginBottom="10px">
                <FormField span={4}>
                  <TimePicker
                    name="timeOfLoss"
                    label="Time (if significant)"
                  />
                </FormField>
                <FormField span={8}>
                  <TimezonePicker
                    name="timezoneId"
                    label="Time zone"
                    dateTime={dateTime}
                  />
                </FormField>
              </FormRow>
            </Indent>
            <Divider />
            <SaveCloseButtons
              disableSave={!dirty}
              isSubmitting={isSubmitting}
              onSave={handleSubmit}
              onClose={onClose}
              closeLabel="Cancel"
              alignRight
              reverse
            />
          </Form>
        );
      }}
    </Formik>
  );
};
