import { AddButton } from '@/components/shared/FormButton';
import {
  ButtonGroup, Pane, TextField
} from '@instech/components';
import { FC } from 'react';

interface Props {
  onClaimSearch: () => void;
}
export const ClaimSearch: FC<Props> = ({ onClaimSearch }) => (
  <Pane padding="20px">
    <TextField
      name="claimNumber"
      placeholder="Claim number"
      label="INS+ Claim number"
    />
    <TextField
      name="year"
      placeholder="Insurance year"
      label="INS+ Insurance year"
    />
    <ButtonGroup alignRight>
      <AddButton label="Search / Get Claim data" onClick={onClaimSearch} />
    </ButtonGroup>
  </Pane>
);
