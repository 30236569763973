import styled from 'styled-components';
import { useModalContext } from '@/components/modal/ModalContext';
import { openImmediateFeedbackTool } from '@/components/tools/ImmediateFeedback/ImmediateFeedbackTool';
import { InfoCircle } from '@instech/icons';
import { BoxButton } from '@instech/components';

const Container = styled.div`
  margin: 4px;
`;

export const FeedbackButton = () => {
  const { open } = useModalContext();
  const showFeedback = openImmediateFeedbackTool({ readonly: true });

  return (
    <Container>
      <BoxButton
        startIcon={<InfoCircle />}
        width="100%"
        onClick={() => open(showFeedback)}>
        Show Immediate Feedback
      </BoxButton>
    </Container>
  );
};
