import moment from 'moment';
import {
  useState, useEffect, useRef
} from 'react';

export const DefaultIdleWindowDuration = moment.duration(30, 'minutes').asMilliseconds();

export const useIdle = (delayMs: number = DefaultIdleWindowDuration): any => {
  const [isIdle, setIsIdle] = useState(false);
  const isIdleRef = useRef(isIdle);
  isIdleRef.current = isIdle;

  const timeoutId = useRef<any>();

  const goInactive = () => {
    setIsIdle(true);
  };

  const startTimer = () => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(goInactive, delayMs);
  };

  const goActive = () => {
    setIsIdle(false);
    startTimer();
  };

  const resetTimer = () => {
    clearTimeout(timeoutId.current);
    goActive();
  };

  const setup = () => {
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('mousedown', resetTimer);
    document.addEventListener('keypress', resetTimer);
    document.addEventListener('DOMMouseScroll', resetTimer);
    document.addEventListener('mousewheel', resetTimer);
    document.addEventListener('touchmove', resetTimer);

    // if tab is changed or is out of focus
    window.addEventListener('blur', startTimer);
    window.addEventListener('focus', resetTimer);
  };

  const cleanUp = () => {
    document.removeEventListener('mousemove', resetTimer);
    document.removeEventListener('mousedown', resetTimer);
    document.removeEventListener('keypress', resetTimer);
    document.removeEventListener('DOMMouseScroll', resetTimer);
    document.removeEventListener('mousewheel', resetTimer);
    document.removeEventListener('touchmove', resetTimer);

    // if tab is changed or is out of focus
    window.removeEventListener('blur', startTimer);
    window.removeEventListener('focus', resetTimer);

    clearTimeout(timeoutId.current);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setup();

    return () => {
      cleanUp();
    };
  }, []);

  return [isIdle, isIdleRef];
};
