import { FunctionComponent } from 'react';
import {
  Duration, SurveyLocation, Option
} from '@/types';
import {
  Table, TableHeader, TableRow, TruncatingTableCell, TruncatingTextTableCell
} from '@/components/shared/Table';
import { getFriendlyDate } from '@/utils/date';
import { TruncatingText } from '@instech/components';
import {
  formatDuration, getEndString, getMidString, getStartString, joinOccurrences
} from './surveyLocationsUtils';

const getDurationText = (duration: Duration) => {
  if (duration.endDate && duration.endDate !== duration.startDate) {
    return `${getFriendlyDate(duration.startDate)} - ${getFriendlyDate(duration.endDate)}`;
  }
  return getFriendlyDate(duration.startDate);
};

interface OccurrenceCellProps {
  occurrences: Option[];
}

const OccurrenceCell = ({ occurrences }: OccurrenceCellProps) => (
  <TruncatingTableCell>
    {occurrences.map(occurrence => <TruncatingText key={occurrence.id} text={occurrence.name} wrap />)}
  </TruncatingTableCell>
);

interface LocationItemProps {
  location: SurveyLocation;
  index: number;
}

const LocationItem: FunctionComponent<LocationItemProps> = ({ location, index }) => {
  const durationText = getDurationText(location.duration);

  return (
    <TableRow even={index % 2 === 0}>
      <TruncatingTextTableCell text={location.location} wrap />
      <TruncatingTextTableCell text={durationText} />
      <OccurrenceCell occurrences={location.occurrences} />
    </TableRow>
  );
};

interface LocationTableProps {
  locations: SurveyLocation[];
}
export const LocationTable: FunctionComponent<LocationTableProps> = ({ locations }) => (
  <Table layout="auto auto auto">
    <TableHeader>Survey location</TableHeader>
    <TableHeader>Date of survey</TableHeader>
    <TableHeader>Occurrence(s)</TableHeader>
    {locations.map((location, i) => <LocationItem key={location.id} location={location} index={i} />)}
  </Table>
);

// @Cleanup-1522 - remove P2
interface LocationProps {
  location: SurveyLocation;
  index: number
  lastIndex: number
}
export const Location: FunctionComponent<LocationProps> = ({ location, index, lastIndex }) => {
  const occurrences = joinOccurrences(location.occurrences);
  const duration = formatDuration(location.duration);
  switch (index) {
    case 0:
      return <span>{getStartString(occurrences, duration, location.location, lastIndex !== 0)}</span>;
    case lastIndex:
      return <span>{getEndString(occurrences, duration, location.location)}</span>;
    default:
      return <span>{getMidString(occurrences, duration, location.location)}</span>;
  }
};
