import styled from 'styled-components';
import { Pane } from '@/components/shared/Pane';
import { useArchivedImageList } from '@/services/imageServices';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { Loader } from '@instech/components';
import { hiddenOnPrint } from '@/utils/styles';
import { useReportIdFromUrl } from '@/hooks/useReportIdFromUrl';
import { ImagePreview } from './core/ImagePreview';

const Wrapper = styled.div`
  padding: 0 30px 30px;
  margin-top: 16px;
`;

const PaneTitle = styled.span`
  ${hiddenOnPrint};
`;

export const FrontpageImageReportView = () => {
  const caseId = useCaseIdFromUrl();
  const reportId = useReportIdFromUrl();
  const { images } = useArchivedImageList(caseId, reportId as string, ['Frontpage']);

  if (!images) {
    return <Loader />;
  }

  const hasUploadImage = images.length !== 0;
  const currentImageId = hasUploadImage ? images[images.length - 1] : undefined;

  if (!currentImageId) {
    return null;
  }
  return (
    <Wrapper>
      <Pane title={<PaneTitle>Frontpage image</PaneTitle>}>
        <ImagePreview
          readonly
          imageId={currentImageId ?? ''}
        />
      </Pane>
    </Wrapper>
  );
};
