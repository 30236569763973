import styled from 'styled-components';
import { Guid } from '@/types';
import { NotifyTooltip } from '@/components/shared/Tooltip';
import { Task } from '@/services/caseTasksService';

const Badge = styled.div`
  display: inline-block;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.orange};
  background-color: ${props => props.theme.orange10};
  padding: 3px 8px;
  font-weight: bold;
  font-size: 0.8em;
  color: ${props => props.theme.orange};
`;

const getTooltipText = (tasks: Task[]) => tasks.map(t => t.name).join(', ');

interface Props {
  tasks: Task[];
  caseId: Guid;
}

export const TaskBadge = ({ tasks, caseId }: Props) => {
  const tooltipId = `task-${caseId}`;
  const tooltipMessage = getTooltipText(tasks);
  const badgeText = `${tasks.length} task${tasks.length > 1 ? 's' : ''}`;
  return (
    <div>
      <NotifyTooltip id={tooltipId} />
      <Badge data-tip={tooltipMessage} data-for={tooltipId}>{badgeText}</Badge>
    </div>
  );
};
