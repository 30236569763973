import {
  useReducer, useEffect, useRef
} from 'react';

export interface RestoreState {
  showRestoreDialog: boolean;
  onRestore?: () => void;
  error?: string;
  pending: boolean;
  success: boolean;
}

const initialState: RestoreState = {
  showRestoreDialog: false,
  onRestore: () => {},
  error: undefined,
  pending: false,
  success: false
};

type RestoreAction =
  { type: 'SHOW_RESTORE_DIALOG', onRestore: () => Promise<void> } |
  { type: 'HIDE_DIALOG' } |
  { type: 'RESTORE_START' } |
  { type: 'RESTORE_ERROR', error: string } |
  { type: 'RESTORE_SUCCESS' };

export function reducer(state: RestoreState, action: RestoreAction): RestoreState {
  switch (action.type) {
    case 'SHOW_RESTORE_DIALOG':
      return {
        ...state,
        error: undefined,
        pending: false,
        success: false,
        showRestoreDialog: true,
        onRestore: action.onRestore
      };
    case 'HIDE_DIALOG':
      return {
        ...state,
        showRestoreDialog: false,
      };
    case 'RESTORE_START':
      return {
        ...state,
        error: undefined,
        pending: true,
        success: false
      };
    case 'RESTORE_ERROR':
      return {
        ...state,
        error: action.error,
        pending: false,
        success: false
      };
    case 'RESTORE_SUCCESS':
      return {
        ...state,
        error: undefined,
        pending: false,
        success: true
      };
    default:
      throw new Error();
  }
}

export interface UseRestoreData {
  state: RestoreState;
  showRestoreDialog: (onRestore: () => Promise<void>) => void;
  hideRestoreDialog: () => void;
}
export function useRestoreData(): UseRestoreData {
  const [state, dispatch] = useReducer(reducer, initialState);
  const timer = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      dispatch({ type: 'HIDE_DIALOG' });
    }, 10_000);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [state]);

  const restoreAction = (onRestore: () => Promise<void>) => async () => {
    try {
      dispatch({ type: 'RESTORE_START' });
      await onRestore();
      dispatch({ type: 'RESTORE_SUCCESS' });
    } catch (err) {
      const error = err as string;
      dispatch({ type: 'RESTORE_ERROR', error });
    }
  };

  const showRestoreDialog = (onRestore: () => Promise<void>) => {
    dispatch({ type: 'SHOW_RESTORE_DIALOG', onRestore: restoreAction(onRestore) });
  };

  const hideRestoreDialog = () => dispatch({ type: 'HIDE_DIALOG' });

  return {
    state,
    showRestoreDialog,
    hideRestoreDialog
  };
}
