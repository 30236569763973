import useSWR from 'swr';
import client from '@/services/client';
import { TimeDetails } from '@/types';
import { useStaticResource } from '@/services/staticResourcesServices';

interface BaseOptions {
  caseId: string;
  chapterId: string;
  widgetId: string;
  occurrenceId?: string;
}

interface UpdateTimeDetailsInput extends BaseOptions {
  timeDetails: TimeDetails;
}

const timeDetailsUrl = ({ caseId, chapterId, widgetId }: BaseOptions) => (
  `/timedetails/case/${caseId}/chapters/${chapterId}/widget/${widgetId}`
);

export const useTimeDetails = (caseId: string, chapterId: string, widgetId: string, swrOptions: any) => {
  const requestUrl = `/timedetails/case/${caseId}/chapters/${chapterId}/widget/${widgetId}`;
  return useSWR<TimeDetails>(requestUrl, swrOptions);
};

export const updateTimeDetailsAsync = async ({ caseId, chapterId, widgetId, timeDetails } : UpdateTimeDetailsInput) => {
  const url = timeDetailsUrl({ caseId, chapterId, widgetId });
  const { data } = await client.put<TimeDetails>(url, timeDetails);
  return data;
};

export const useSimultaneousRepairsInfoText = (days: number) => {
  const { data: infoTemplate, error } = useStaticResource('simultaneousRepairsText');

  if (!infoTemplate) {
    return { data: undefined, isValidating: true };
  }
  if (error) {
    throw new Error(error);
  }

  const infoWithData = infoTemplate
    .replace('{0}', days.toString().length === 0 ? '0' : days.toString());

  return {
    data: infoWithData,
    isValidating: false
  };
};
