import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useImageList } from '@/services/imageServices';
import { ModalHandler } from '@/components/modal/Modal';
import { Loader } from '@instech/components';
import { ImageLibraryEdit } from './views/ImageLibraryEdit';
import { UploadImageContainer } from './core/UploadImageContainer';

export const ImagesEditView = () => {
  const caseId = useCaseIdFromUrl();
  const { images, deleteImage } = useImageList(caseId);

  if (!images) return <Loader />;

  return (
    <ModalHandler>
      <UploadImageContainer />
      <ImageLibraryEdit imageIds={images} deleteImage={deleteImage} />
    </ModalHandler>
  );
};
