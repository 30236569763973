import { roles } from '@/components/AppRouting/roles';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import { ModalContentArea } from '@/components/modal/ModalContentArea';
import { useCaseStatus } from '@/services/caseStatusServices';
import { useCurrentUserInfo } from '@/services/userInfoService';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useCase } from '@/services/dashboardServices';
import { ContactsReadonlyView } from './ContactsReadonlyView';
import { ContactsEditView } from './ContactsEditView';
import { ContactsHiddenMessage } from './ContactsHiddenMessage';

export const ContactsRoleResolver = () => {
  const role = useCurrentRole();
  const caseId = useCaseIdFromUrl();

  const { data: status } = useCaseStatus(true);
  const { data: currentUser } = useCurrentUserInfo();
  const { data: report } = useCase(caseId);

  if (status.isClosed) {
    return <ContactsHiddenMessage />;
  }

  const canEditContacts = role.name === roles.claimHandler &&
    currentUser?.id === report?.users?.primaryClaimsHandlerId;

  if (canEditContacts) {
    return <ContactsEditView />;
  }

  return <ContactsReadonlyView />;
};

export const ContactsTool = () => (
  <ModalContentArea>
    <ContactsRoleResolver />
  </ModalContentArea>
);

export const openContactsTool = {
  component: ContactsTool,
  options: {
    title: 'Contacts',
    footer: true
  },
  args: {}
};
