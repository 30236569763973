import { useState } from 'react';
import { Redirect } from 'react-router';
import { PageLoader } from '@instech/components';
import { useManifest, transformTOC } from '@/services/manifestServices';
import { useUserEditAccess } from '@/services/userInfoService';
import { NoAccessPage } from '@/components/AppRouting/NoAccess';
import { useCaseStatus } from '@/services/caseStatusServices';
import { dashboardRoute } from '@/utils/routeTemplates';
import { EditProvider } from './core/EditContext';
import { EditRouter } from './routing/EditRouter';
import { EditPage as EditLayout } from '../../layout/Page';
import { Sidebar, Content } from '../../layout/EditGrid';
import { EditNav } from '../../layout/navigation/EditNav';

export const EditPage = () => {
  const { data, error } = useManifest();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { data: userHasEditAccess } = useUserEditAccess();
  const { data: caseStatus } = useCaseStatus();

  if (error) {
    throw new Error(error);
  }

  if (!data || !caseStatus) return <PageLoader />;

  if (userHasEditAccess === false) {
    return (
      <NoAccessPage />
    );
  }

  if (caseStatus.isClosed) {
    return <Redirect to={dashboardRoute(data.caseId)} />;
  }

  const chapters = transformTOC(data);

  return (
    <EditProvider value={{ toc: data, chapters }}>
      <EditLayout title={data.title}>
        <Sidebar>
          <EditNav onMobileOpen={setMobileMenuOpen} />
        </Sidebar>
        <Content visible={!mobileMenuOpen}>
          <EditRouter />
        </Content>
      </EditLayout>
    </EditProvider>
  );
};
