/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import {
  deleteVesselInformationAsync,
  addVesselInformationAsync,
  VesselInformationAdd,
  restoreDeletedVesselInformationAsync
} from '@/services/vesselInformationServices';
import { Loader } from '@instech/components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useRestoreData, RestoreDeletedItemPopup } from '@/components/shared/RestoreDeletedItem';
import { InfoCircle } from '@instech/icons';
import { InformationList } from './InformationList';
import { VesselInformationForm } from '../shared/VesselInformationForm';
import { useVesselInformation } from './useVesselInformation';
import { fields, validationSchema } from './core';

export const newEntryModel = {
  title: '',
  value: '',
  includeInReport: true,
  editable: true,
};

const Wrapper = styled.div`
  position: relative;
`;

const InfoArea = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background-color:rgba(0,60,113,0.1);
  > span {
    margin-left: 13px;
  }
  > svg {
    color: ${props => props.theme.marineBlue};
  }
  margin-bottom: 20px;
  margin-top: -12px;
`;

const InfoBox = () => (
  <InfoArea>
    <InfoCircle />
    <span>If changes made to IMO, flag and class - please also notify the respective Claims Handler directly on email</span>
  </InfoArea>
);

const EditVesselInformation = () => {
  const caseId = useCaseIdFromUrl();
  const { state: restoreState, showRestoreDialog, hideRestoreDialog } = useRestoreData();
  const { data, error, mutate } = useVesselInformation();
  const [showNewItemForm, setShowNewItemForm] = useState(false);

  if (error) throw new Error(error);
  if (!data) return <Loader />;

  const onRestoreDeleted = (id: string) => async () => {
    const result = await restoreDeletedVesselInformationAsync(caseId, id);
    void mutate(result);
  };

  const onDelete = async (id: string) => {
    void mutate(existingItems => existingItems?.filter(x => x.id !== id), false);
    showRestoreDialog(onRestoreDeleted(id));
    await deleteVesselInformationAsync(caseId, id);
  };

  const onSubmit = async (model: VesselInformationAdd) => {
    const result = await addVesselInformationAsync(caseId, model);
    const newList = [...data, result];
    void mutate(newList);
  };

  return (
    <Wrapper>
      <InfoBox />
      <InformationList
        entries={data}
        onDelete={onDelete}
      />
      <VesselInformationForm
        model={newEntryModel}
        fields={fields}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        hideRestoreDialog={hideRestoreDialog}
        showNewItemForm={data.length === 0 || showNewItemForm}
        cancelIsReset={data.length === 0}
        setShowNewItemForm={setShowNewItemForm}
      />
      <RestoreDeletedItemPopup
        {...restoreState}
      />
    </Wrapper>
  );
};

const ReadOnlyVesselInformation = () => {
  const { data, error } = useVesselInformation();

  if (error) throw new Error(error);
  if (!data) return <Loader />;

  return (
    <Wrapper>
      <InfoBox />
      <InformationList
        entries={data}
        onDelete={() => null}
        readonly
      />
    </Wrapper>
  );
};

interface VesselInformationProps {
  readonly?: boolean;
}
export const VesselInformation: FunctionComponent<VesselInformationProps> = ({ readonly = false }) => (
  readonly
    ? <ReadOnlyVesselInformation />
    : <EditVesselInformation />
);
