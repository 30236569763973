import styled from 'styled-components';
import { RichUtils } from 'draft-js';
import PropTypes from 'prop-types';
import { editorStatePropType } from '../../../../../prop-types/custom-prop-types';
import {
  blocks, inline, inlineItalic
} from './controlOptions';
import { Dropdown } from './Dropdown';

const TextStyleWrapper = styled.div`
  margin: 0px 8px;
  cursor: pointer;
  border-bottom: 2px solid ${props => props.active ? props.theme.marineBlue : 'transparent'};
  color: ${props => props.active ? props.theme.marineBlue : props.theme.black};
  &:hover {
    color: ${props => props.theme.marineBlue};
  }
`;

const TextStyle = ({ label, style, icon: Icon, onToggle, active }) => {
  const callback = evt => {
    // Prevent losing editor focus when clicking the button
    evt.preventDefault();
    onToggle(style);
  };
  return <TextStyleWrapper title={label} onMouseDown={callback} active={active}><Icon /></TextStyleWrapper>;
};

TextStyle.propTypes = {
  label: PropTypes.string,
  style: PropTypes.string,
  icon: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  active: PropTypes.bool
};

export const DropdownWrapper = ({ options, onToggle, editorState }) => {
  const blockType = RichUtils.getCurrentBlockType(editorState);

  return <Dropdown options={options} onChange={onToggle} value={blockType} />;
};

DropdownWrapper.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  onToggle: PropTypes.func,
  editorState: editorStatePropType
};

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const BlockStyleControls = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <Row>
      {blocks.map(item => (
        <TextStyle
          key={item.label}
          label={item.label}
          style={item.style}
          icon={item.icon}
          onToggle={onToggle}
          active={item.style === blockType}
        />
      ))}
    </Row>
  );
};

BlockStyleControls.propTypes = {
  editorState: editorStatePropType,
  onToggle: PropTypes.func
};

const Middlerow = styled.div`
  padding: 0 25px;
  display: flex;
`;

export const InlineStyleControls = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const isHeader = blockType.toLowerCase() === 'header-one' || blockType.toLowerCase() === 'header-two' || blockType.toLowerCase() === 'header-three';
  const inlineStyles = isHeader ? inlineItalic : inline;
  return (
    <Middlerow>
      {inlineStyles.map(item => (
        <TextStyle
          key={item.label}
          label={item.label}
          style={item.style}
          icon={item.icon}
          onToggle={onToggle}
          active={currentStyle.has(item.style)}
        />
      ))}
    </Middlerow>
  );
};

InlineStyleControls.propTypes = {
  editorState: editorStatePropType,
  onToggle: PropTypes.func
};
