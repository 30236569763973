import { formatNumber } from '@/utils/number';
import { SummaryOfCost } from '@/types';

const formatSummarySet = (data: SummaryOfCost) => {
  if (!data) return undefined; // Throw blank if data does not exist
  return { lines: data.lines, sum: formatNumber(data.sum) };
};

export const calculateSummary = (data: SummaryOfCost[]) => {
  const expenses = data.reduce((obj: any, item) => {
    // eslint-disable-next-line no-param-reassign
    obj[item.title.toLowerCase()] = item;
    return obj;
  }, {});

  const occurrences = data.filter(item => item.isOccurrence);
  const occurrenceSum = occurrences.reduce((sum: number, occurrence) => sum + occurrence.sum, 0);
  // Format occurrence data here, so it does not have to be formatted later
  const formattedOccurrences = occurrences.map(occurrence => {
    const summary = formatSummarySet(occurrence);
    return { title: occurrence.title, lines: summary?.lines, sum: summary?.sum || '' };
  });

  const generalExpenses = expenses['cost for general expenses'];
  const temporaryExpenses = expenses['cost for temporary expenses'];
  const extraExpenses = expenses['cost for extra expenses'];
  const adjustersConsideration = expenses["cost for adjuster's consideration"];
  const notConcerningAverage = expenses['cost for work not concerning average'];

  const sum = occurrenceSum + generalExpenses.sum + adjustersConsideration.sum + extraExpenses.sum + temporaryExpenses.sum;
  const total = sum + notConcerningAverage.sum;

  const result = {
    occurrences: formattedOccurrences,
    occurrenceSum: formatNumber(occurrenceSum),
    generalExpenses: formatSummarySet(generalExpenses),
    temporaryExpenses: formatSummarySet(temporaryExpenses),
    extraExpenses: formatSummarySet(extraExpenses),
    adjustersConsideration: formatSummarySet(adjustersConsideration),
    notConcerningAverage: formatSummarySet(notConcerningAverage),
    sum: formatNumber(sum),
    total: formatNumber(total)
  };
  return result;
};

/*
  Map out comments by looping the data. Each line with a comment has that comment copied to
  a new Comment array ("(i) comment"), while the name of the line is appended with the
  same number ("cost name (i)"). The count is defined out of loop, so that the numbers are
  in order regardless of how many non-commented fields are between them.
*/
export const mapComments = (data: SummaryOfCost[]) => {
  let i = 0;
  const comments: string[] = [];
  const markedData = data.map(item => {
    if (!item.lines) return item;
    const newItem = {
      ...item,
      lines: item.lines.map(line => {
        if (!line.comment) return line;
        i += 1;
        comments.push(`[${i}]: ${line.comment}`);
        return { ...line, name: `${line.name} [${i}]` };
      })
    };
    return newItem;
  });
  return { markedData, comments };
};
