import styled from 'styled-components';

/// https://www.sarasoueidan.com/blog/accessible-icon-buttons/
export const ScreenReaderOnly = styled.span`
  clip: rect(0 0 0 0); 
  clip-path: inset(100%); 
  height: 1px; 
  overflow: hidden; 
  position: absolute; 
  white-space: nowrap; 
  width: 1px; 
`;
