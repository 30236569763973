import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Field, useField } from 'formik';
import {
  FieldLayout, FieldLabel, ErrorMessage
} from '../../core/Components';
import { RadioField } from './core/RadioField';

const OptionsGroup = styled.div<{ horizontal?: boolean}>`
  ${props => props.horizontal && css`
    display: flex;
  `}
`;

interface RadioButtonsProps {
  name: string;
  label?: string;
  noErrors?: boolean;
  options: string[];
  customId?: string;
  horizontal?: boolean;
}

export const RadioButtons: FunctionComponent<RadioButtonsProps> = ({ name, label, noErrors, options = [], customId, horizontal }) => {
  const [, meta] = useField(name);
  return (
    <FieldLayout>
      <FieldLabel>{label}</FieldLabel>
      <OptionsGroup horizontal={horizontal}>
        {options.map(option => {
          const fieldName = `${name}-${option}${(customId && `-${customId}`) || ''}`;
          return (
            <RadioField key={fieldName} as="label" htmlFor={fieldName} error={meta.touched && meta.error}>
              <Field id={fieldName} name={name} value={option} type="radio" />
              {option}
            </RadioField>
          );
        })}
      </OptionsGroup>
      {noErrors ? '' : (
        <ErrorMessage>
          {meta.error && meta.touched ? meta.error : ''}
        </ErrorMessage>
      )}
    </FieldLayout>
  );
};
