import { Field } from '@/components/shared/Field';
import { FC } from 'react';
import styled from 'styled-components';
import { InterestSchema } from './occurrenceFormSchema';

const InterestRowsContainer = styled.div`
  padding: 0 16px;
  box-shadow: 0px 2px 6px 0px ${({ theme }) => theme.marineBlue50} inset;
  background-color: ${({ theme }) => theme.marineBlue10};
  color: ${({ theme }) => theme.marineBlue};
`;

const InterestRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 12px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.marineBlue60};
  :last-child {
    border-bottom: 0;
  }
`;

interface InterestRowsProps {
  interests: InterestSchema[];
  occurrenceId: string;
}
export const InterestRows: FC<InterestRowsProps> = ({ occurrenceId, interests }) => {
  const interestsConnectedToIns = interests.filter(interest => interest.claimId !== null);

  return (
    <InterestRowsContainer>
      {interestsConnectedToIns.map(interest => (
        <InterestRow key={`${occurrenceId}${interest.claimReference}`}>
          <Field label="Interest" value={interest.interest} />
          <Field label="Deductible" value={interest.deductible} />
          <Field label="Claim Number" value={interest.claimReference} />
        </InterestRow>
      ))}
      {interestsConnectedToIns.length === 0 && (
        <InterestRow>
          <Field label="Interest" value="TBA by Claims Handler" />
          <Field label="Deductible" value="TBA by Claims Handler" />
          <Field label="Claim Number" value="TBA by Claims Handler" />
        </InterestRow>
      )}
    </InterestRowsContainer>
  );
};
