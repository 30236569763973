import { Table, TableHeader } from '@/components/shared/Table';
import { Loader } from '@instech/components';
import { useModalContext } from '@/components/modal/ModalContext';
import { usePreviewVersionedReports } from '@/services/versionsService';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { ReportVersion } from './ReportVersion';

const HeaderRow = () => (
  <>
    <TableHeader>Version</TableHeader>
    <TableHeader>Created</TableHeader>
    <TableHeader>Interest</TableHeader>
    <TableHeader>PDF Preview</TableHeader>
    <TableHeader />
  </>
);

export const PreviewVersions = () => {
  const caseId = useCaseIdFromUrl();
  const { data: reports } = usePreviewVersionedReports(caseId);
  const { close } = useModalContext();
  if (!reports) return <Loader />;
  return (
    <Table layout="0.5fr 1fr 1fr 1fr 1fr">
      <HeaderRow />
      {reports.map(({ versionId, archivedId, lastModified, interest, pdfGenerated, pdfGeneratedWithFallbackParameters }, i) => (
        <ReportVersion
          key={versionId}
          caseId={caseId}
          index={i}
          archivedId={archivedId}
          versionId={versionId}
          lastModified={lastModified}
          onReportView={close}
          pdfGenerated={pdfGenerated}
          interest={interest}
          pdfGeneratedWithFallbackParameters={pdfGeneratedWithFallbackParameters}
        />
      ))}
    </Table>
  );
};
