import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IconButton, TruncatingText } from '@instech/components';
import { Trash } from '@instech/icons';

const StyledField = styled.fieldset`
  position: relative;
  padding: 10px 12px;
  border: 1px solid ${props => props.theme.border.blueGray};
  margin: 0px;
  min-width: 0px;
`;

type Variant = 'default' | 'dark' | 'table' | 'lightBlue';
const StyledTitle = styled.legend<{variant?: Variant}>`
  display: block;
  position: absolute;
  top: -13px;
  left: 8px;
  padding: 4px;
  background-color: ${props => {
    if (props.variant === 'dark') return props.theme.background.primary;
    if (props.variant === 'table') return props.theme.flatWhite;
    if (props.variant === 'lightBlue') return props.theme.lightBlue;
    return props.theme.white;
  }};
  color: ${props => props.theme.marineBlue};
  font-size: 13px;
  font-weight: bold;
`;

const StyledValue = styled.div`
  width: 100%;
`;

interface ReadOnlyFieldProps {
  title: string;
  value?: string;
}

interface ReadOnlyFieldPropsWithVariant extends ReadOnlyFieldProps {
  variant?: Variant;
}

export const ReadOnlyField: FunctionComponent<ReadOnlyFieldPropsWithVariant> = ({ title, value, children, variant = 'default' }) => (
  <StyledField>
    <StyledTitle variant={variant}><TruncatingText text={title} /></StyledTitle>
    <StyledValue>{children || (value && <TruncatingText text={value} />)}</StyledValue>
  </StyledField>
);

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

interface ReadOnlyFieldWithDeleteProps extends ReadOnlyFieldProps {
  onDelete: (...args: any) => any;
}
export const ReadOnlyFieldWithDelete: FunctionComponent<ReadOnlyFieldWithDeleteProps> = ({ title, value, onDelete }) => (
  <StyledField>
    <StyledTitle>{title}</StyledTitle>
    <Flex>
      <StyledValue>{value}</StyledValue>
      <IconButton smallIcon icon={<Trash />} onClick={onDelete} />
    </Flex>
  </StyledField>
);
