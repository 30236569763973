import {
  useEffect, useRef, useState
} from 'react';
import { useTypedModal } from '@/components/modal/ModalContext';
import moment from 'moment';
import { useUsersClaims } from '@/services/management/userManagementService';
import { defaultUserSessionExpireModalProps, userSessionExpiredModal } from './userSessionExpireModal';
import { DefaultIdleWindowDuration, useIdle } from './useIdle';

export interface Claim {
  type: string;
  value: string;
}

export const getExpiresIn = (data?: Claim[]) => {
  const seconds = data?.find(x => x.type === 'bff:session_expires_in')?.value;

  if (seconds) {
    return parseInt(seconds);
  }

  return 0;
};

const useStateRefs = (initial: any) => {
  const [value, setValue] = useState(initial);
  const valueRef = useRef(value);
  valueRef.current = value;
  return [value, setValue, valueRef];
};

export const useUserSessionSlide = (
  actBeforeSessionEnds?: () => any,
  idleWindowDuration: number = DefaultIdleWindowDuration,
) => {
  const { open: openUserSessionModal } = useTypedModal(userSessionExpiredModal(defaultUserSessionExpireModalProps));
  const [, setSessionIsAlive, sessionIsAliveRef] = useStateRefs(true);
  const [sessionClaims, setSessionClaims, sessionClaimsRef] = useStateRefs(null);
  const [, isUserIdleRef] = useIdle(idleWindowDuration);

  const { data, slideUserSession } = useUsersClaims();

  const slidingInterval = useRef<any>();
  const sessionAliveCheckTimeout = useRef<any>();

  const purgeMemory = () => {
    clearInterval(slidingInterval.current!);
    clearTimeout(sessionAliveCheckTimeout.current!);
  };

  useEffect(() => () => {
    purgeMemory();
  }, []);

  const performSessionEndedActions = () => {
    if (sessionClaimsRef.current || (data?.claims)) {
      actBeforeSessionEnds?.();
      setSessionIsAlive(false);
      purgeMemory();

      const logoutUrl = (sessionClaimsRef.current ?? data?.claims)
        ?.find((x: { type: string; }) => x.type === 'bff:logout_url')?.value ?? '/';
      openUserSessionModal({ logoutUrl });
    }
  };

  const startSessionTerminateActionsTimeout = (sessionExpiresInMs: number) => {
    sessionAliveCheckTimeout.current = setTimeout(performSessionEndedActions, sessionExpiresInMs);
  };

  const startSlidingInterval = (slideAfterMs: number) => {
    /* eslint-disable no-use-before-define */
    slidingInterval.current = setInterval(slideSession, slideAfterMs);
  };

  const startSlidingSessionWindowWithTerminationCheck = (expiresInMs: number) => {
    if (expiresInMs && expiresInMs > 0) {
      setSessionIsAlive(true);

      const slideSessionAfterFinal = (expiresInMs / 5);
      startSlidingInterval(slideSessionAfterFinal);
      startSessionTerminateActionsTimeout(expiresInMs - 6000);
    } else {
      performSessionEndedActions();
    }
  };

  const slideSession = () => {
    if (!isUserIdleRef.current && sessionIsAliveRef.current) {
      void slideUserSession().then(claims => {
        setSessionClaims(claims);
        const expiresIn = moment.duration(getExpiresIn(claims!), 'seconds').asMilliseconds();
        purgeMemory();
        startSlidingSessionWindowWithTerminationCheck(expiresIn);
      });
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (data && !sessionClaims) {
      const expiresIn = moment.duration(getExpiresIn(data!.claims), 'seconds').asMilliseconds();
      setSessionClaims(data!.claims);
      startSlidingSessionWindowWithTerminationCheck(expiresIn);
    }
  }, [data, sessionClaims, setSessionClaims]);

  return [sessionIsAliveRef.current];
};
