import { Notification } from '@/services/caseNotificationsServices';
import styled from 'styled-components';
import { Guid } from '@/types';
import { NotifyTooltip } from '@/components/shared/Tooltip';

const Badge = styled.div`
  display: inline-block;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.green};
  background-color: ${props => props.theme.lightGreen25};
  padding: 3px 8px;
  font-weight: bold;
  font-size: 0.8em;
  color: ${props => props.theme.marineGreen};
`;

const getTooltipText = (notifications: Notification[]) => notifications.map(n => n.name).join(', ');

interface Props {
  notifications: Notification[];
  caseId: Guid;
}

export const NotificationBadge = ({ notifications, caseId }: Props) => {
  const hasNotifications = notifications.length > 0;
  if (!hasNotifications) return null;

  const tooltipId = `notification-${caseId}`;
  const tooltipMessage = getTooltipText(notifications);
  return (
    <>
      <NotifyTooltip id={tooltipId} />
      <Badge data-tip={tooltipMessage} data-for={tooltipId}>New</Badge>
    </>
  );
};
