import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useReportIdFromUrl } from '@/hooks/useReportIdFromUrl';
import client, {
  postFormAsync, putFormAsync, putJsonAsync
} from '@/services/client';
import { Guid, Option } from '@/types';
import useSWR, { mutate as globalMutate } from 'swr';

export type FileStatusOptionType = 'Available' | 'AvailableOnRequest' | 'Enclosed' | 'Requested';
export type FileStatusOptionName = 'Available' | 'Available on request' | 'Enclosed' | 'Requested';

export type FileStatusOption = {
  status: FileStatusOptionType;
  name: FileStatusOptionName;
  requireFile: boolean;
}

export const fileStatusOptions: Option[] = [
  {
    id: 'Available',
    name: 'Available'
  },
  {
    id: 'AvailableOnRequest',
    name: 'Available on request'
  },
  {
    id: 'Enclosed',
    name: 'Enclosed'
  },
  {
    id: 'Requested',
    name: 'Requested'
  }
];

export type FileCategory = 'General information' | 'Log books' | 'Reports' | 'Other';

export interface FileForm {
  file?: File;
  title: string;
  status: FileStatusOptionType;
  category: FileCategory;
  pii?: boolean;
  piiIncludesEu?: boolean;
}
export interface Pii {
  includesEu: boolean;
}
export interface FileModel {
  id: Guid;
  caseId: Guid;
  title: string;
  description: string;
  uploadedAt: string;
  uploadedBy: string;
  fileName: string;
  fileSizeInBytes?: number;
  category: FileCategory;
  status: string;
  isPlaceholderForRequestedFile: boolean;
  personallyIdentifiableInformation: Pii | null;
}

export interface UpdateFileRequest {
  status: FileStatusOptionType;
}

export const filesApiRoute = (caseId: Guid) => `case/${caseId}/files`;
const fileRoute = (caseId: Guid, fileId: Guid) => `case/${caseId}/files/${fileId}`;
export const archivedFilesApiRoute = (caseId: Guid, reportId: Guid) => `case/${caseId}/files/archived/${reportId}`;

export const deleteFilesAsync = async (caseId: Guid, fileId: Guid) => {
  const route = fileRoute(caseId, fileId);
  await client.delete(route);
  void globalMutate(filesApiRoute(caseId), (prev: FileModel[]) => prev.filter(file => file.id !== fileId));
};

export const uploadFileAsync = async (caseId: Guid, form: FileForm) => {
  const fileListUrl = filesApiRoute(caseId);

  const formData = new FormData();
  formData.append('title', form.title);
  formData.set('status', form.status);
  formData.set('category', form.category);
  if (form.file) {
    formData.set('file', form.file);
  }
  if (form.pii) {
    formData.set('personallyIdentifiableInformation.includesEu', form.piiIncludesEu ? 'true' : 'false');
  }

  try {
    const result: FileModel = await postFormAsync(fileListUrl, formData);
    await globalMutate(fileListUrl, async (files: FileModel[]) => [...files, result]);
  } catch (e: any) {
    const msg = e.response?.data?.Message;
    if (msg === 'Pdf cannot have passwords') {
      throw new Error(msg);
    }
    throw e;
  }
};

export const useFileList = () => {
  const caseId = useCaseIdFromUrl();
  const reportId = useReportIdFromUrl();
  const swrFileListUrl = reportId ? archivedFilesApiRoute(caseId, reportId) : filesApiRoute(caseId);
  const { data, mutate } = useSWR<FileModel[]>(swrFileListUrl, { suspense: true });

  const deleteFile = async (fileId: Guid) => {
    const route = fileRoute(caseId, fileId);
    await client.delete(route);
    void mutate(prev => prev?.filter(file => file.id !== fileId));
  };
  return {
    files: data as FileModel[],
    deleteFile
  };
};

const updateFileCache = async (caseId: Guid, fileId: Guid, newModel: FileModel, shouldRevalidate = true) => {
  const fileTask = globalMutate(fileRoute(caseId, fileId), newModel, shouldRevalidate);

  const updateFileListCache = (prevFiles: FileModel[]) => prevFiles.map(file => file.id === fileId ? newModel : file);

  const fileListTask = globalMutate(filesApiRoute(caseId), updateFileListCache, shouldRevalidate);

  await Promise.all([fileTask, fileListTask]);
};

export const updateFileStatusAsync = async (caseId: Guid, existingItem: FileModel, newStatus: FileStatusOptionType) => {
  const fileId = existingItem.id;
  const pii = existingItem.personallyIdentifiableInformation;
  const swrKey = fileRoute(caseId, fileId);
  void updateFileCache(caseId, fileId, { ...existingItem, status: newStatus }, false); // Optimistically update cache to instantly update the UI

  const result = await putJsonAsync<FileModel>(swrKey, { status: newStatus, personallyIdentifiableInformation: pii });

  void updateFileCache(caseId, fileId, result); // Update the cache again to refresh it with real data

  return result;
};

const swrConfiguration = {
  dedupingInterval: 10 * 60 * 1000, // 10 minutes
  errorRetryInterval: 10 * 1000, // 10 seconds
  errorRetryCount: 7
};

interface ContentLinkModel {
  id: string;
  caseId: string;
  url: string;
}
// For fetching without using SWR in the file
export const useFileLink = (id: string, isPlaceholder: boolean) => {
  const caseId = useCaseIdFromUrl();
  const reportId = useReportIdFromUrl();
  const swrFileMetadataUrl = reportId ? `case/${caseId}/files/${id}/archived/${reportId}/link` : `case/${caseId}/files/${id}/link`;
  return useSWR<ContentLinkModel>(isPlaceholder ? null : swrFileMetadataUrl, swrConfiguration);
};

export const useCategories = () => {
  const url = 'fileOptions/categories';
  return useSWR<FileCategory[]>(url, { suspense: true });
};

export const useArchivedCategories = () => {
  const caseId = useCaseIdFromUrl();
  const versionId = useReportIdFromUrl();

  const url = `fileOptions/categories/archived/${caseId}/${versionId}`;
  return useSWR<{ categories: FileCategory[] }>(url, { suspense: true });
};

export const useFileOptions = () => {
  const url = 'fileOptions';
  return useSWR<FileStatusOption[]>(url, { suspense: true });
};

interface UploadPlaceholderFileRequest {
  file: File;
  status: FileStatusOptionType;
  pii: boolean;
  piiIncludesEu?: boolean;
}
export const uploadFileForPlaceholderAsync = async (caseId: Guid, fileId: Guid, data: UploadPlaceholderFileRequest) => {
  const uploadUrl = `${fileRoute(caseId, fileId)}/placeholder`;

  const formData = new FormData();
  formData.append('file', data.file);
  formData.set('status', data.status);
  if (data.pii) {
    formData.set('personallyIdentifiableInformation.includesEu', data.piiIncludesEu ? 'true' : 'false');
  }

  const result = await putFormAsync<FileModel>(uploadUrl, formData);

  void updateFileCache(caseId, fileId, result);

  return result;
};
