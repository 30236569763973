import { ModalType } from '@/components/modal/types';

const PdfGeneratedWithFallbackParametersModal = () => (
  <p>
    This PDF was generated using fallback parameters due to an issue
    encountered during the standard generation process. This may have slightly
    affected the layout of the document. Please review the PDF to ensure
    everything appears as expected.
  </p>
);

export const showPdfFallbackVersionModal: ModalType = {
  component: PdfGeneratedWithFallbackParametersModal,
  options: {
    title: 'This PDF was generated with fallback parameters',
    size: 'medium'
  }
};
