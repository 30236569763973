import {
  FunctionComponent, useState, useRef, useEffect, useCallback
} from 'react';
import styled from 'styled-components';
import { useOutsideAlerter } from '@/utils/mouseEventHelpers';
import { addOrRemove } from '@/utils/arrayHelpers';
import { DropdownContainer, DropdownElement } from '../core/Components';
import { DropdownButtons } from '../core/DropdownButtons';
import { DropdownField } from './DropdownField';
import { FilterList } from '../core/FilterList';
import { Filters } from '../types';

interface ContainerProps {
  width?: string;
}
const Wrapper = styled.div<ContainerProps>`
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: ${props => props.width && props.width};
`;

interface Props {
  facets: string[];
  parameterName: string;
  filters?: Filters;
  changeFilter: (parameterName: string, selectedFacets: string[]) => any;
}
export const FilterDropdown: FunctionComponent<Props> = ({ facets, parameterName, filters, changeFilter }) => {
  const [open, setOpen] = useState(false);
  const [selectedFacets, setSelectedFacets] = useState<any>([]);

  const checkCheckboxesForCurrentFilters = useCallback(() => {
    if (filters) setSelectedFacets(filters[parameterName] || []);
  }, [filters, parameterName]);

  const toggleFacet = (facet: string) => {
    const newSelectedFacets = addOrRemove([...selectedFacets], facet);
    setSelectedFacets(newSelectedFacets);
  };

  const apply = () => {
    changeFilter(parameterName, selectedFacets);
    setOpen(false);
  };

  const cancel = () => {
    checkCheckboxesForCurrentFilters();
    setOpen(false);
  };

  useEffect(() => {
    checkCheckboxesForCurrentFilters();
  }, [filters, checkCheckboxesForCurrentFilters]);

  // If dropdown closes, trigger Cancel
  useEffect(() => {
    if (!open) cancel();
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const clickRef = useRef(null);
  const onOutsideClick = () => setOpen(false);
  useOutsideAlerter(clickRef, open, onOutsideClick);

  const onToggleOpen = () => setOpen(!open);

  return (
    <Wrapper ref={clickRef} width="200px">
      <DropdownField onClick={onToggleOpen} isOpen={open}>Open / Close</DropdownField>
      <DropdownContainer isOpen={open}>
        <DropdownElement first>
          <FilterList
            id={parameterName}
            isLoading={false} // TODO: What to make of this prop?
            facets={facets}
            selectedFacets={selectedFacets}
            toggleFacet={toggleFacet}
          />
        </DropdownElement>
        <DropdownButtons
          onFilter={apply}
          onCancel={cancel}
        />
      </DropdownContainer>
    </Wrapper>
  );
};
