import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { NewBoxButton } from '@/components/shared/BoxButton/NewBoxButton';
import { DropdownElement } from './Components';

const Buttons = styled(DropdownElement)`
  display: flex;
  width: 100%;
  & button {
    width: 100%;
  }
  & button + button {
    margin-left: 8px;
  }
`;

interface Props {
  onCancel: () => any;
  onFilter: () => any;
  disableFilter?: boolean;
}
export const DropdownButtons: FunctionComponent<Props> = ({ onCancel, onFilter, disableFilter }) => (
  <Buttons>
    <NewBoxButton inverted onClick={onCancel}>
      Cancel
    </NewBoxButton>
    <NewBoxButton
      onClick={!disableFilter ? () => onFilter() : null}
      disabled={disableFilter}>
      Filter
    </NewBoxButton>
  </Buttons>
);
