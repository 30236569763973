import { TableCell } from '@instech/components';
import { Approved } from '@instech/icons';
import { FC } from 'react';
import styled from 'styled-components';

const StyledTableCell = styled(TableCell)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: ${({ theme }) => theme.marineBlue};
  padding: 0px 8px;
  & > svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

interface Props {
  isPrimary: boolean;
}
export const PrimaryUserCell: FC<Props> = ({ isPrimary }) => (
  <StyledTableCell>
    {isPrimary && <Approved data-testid="primary-user-check" />}
  </StyledTableCell>
);
