import { stringRequiredMaxLength, dateRangeRequiredStart } from '@instech/components';
import * as Yup from 'yup';
import { dropdownOptionRequired } from '@/utils/validationSchemas';

const locationModelSchema = Yup.object({
  location: stringRequiredMaxLength(60, 'Location required'),
  duration: dateRangeRequiredStart(),
  occurrences: Yup.array().of(dropdownOptionRequired()).required('Occurrence required').min(1, 'Occurrence required'),
  report: Yup.array().nullable()
});

export const locationSchema = Yup.object({
  locations: Yup.array(locationModelSchema).required()
});
