import { RadioField } from '@/components/shared/Form/fields/RadioButtons/';
import { ScreenReaderOnly } from '@/components/shared/ScreenReaderOnly';
import { ChangeEventHandler, FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

interface RadioFieldProps {
  locked?: boolean;
  disabled?: boolean;
  isError?: boolean;
}
/* Need to make some small style changes to the radio field */
const StyledRadioField = styled(RadioField) <RadioFieldProps>`
  display: flex;
  vertical-align: center;
  color: ${props => (props.disabled && props.theme.mediumGray) || (props.isError && props.theme.red)};
  cursor: ${props => (!props.locked && !props.disabled) && 'pointer'};
  & input[type="radio"] {
    margin-right: 0px;
    // Parent element cursor doesn't affect input cursor in this scenario
    cursor: ${props => (!props.locked && !props.disabled) && 'pointer'};
  }
  ${props => props.disabled && css`
    & input[type="radio"] {
      background: ${props.theme.ultraLightGray};
      border-color: ${props.theme.mediumGray};
    }
    &:checked,
    &:checked::after {
      background: ${props.theme.mediumGray};
    }
  `}
  ${props => props.isError && css`
    & input[type="radio"],
    &:checked {
      border-color: ${props.theme.red};
    }
    &:checked::after {
      background: ${props.theme.red};
    }
  `};
`;

interface Props {
  name: string;
  id: string;
  label: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  isError?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}
const SelectableRadioButton: FunctionComponent<Props> = ({
  name,
  id,
  label,
  value,
  checked,
  disabled,
  isError,
  onChange,
  children
}) => (
  <StyledRadioField as="label" htmlFor={id} disabled={disabled} isError={isError}>
    <ScreenReaderOnly>{label}</ScreenReaderOnly>
    <input type="radio" id={id} name={name} value={value} checked={checked} disabled={disabled} onChange={onChange} />
    {children}
  </StyledRadioField>
);

const StaticSelectedRadioButton: FunctionComponent = () => (
  <StyledRadioField as="label" locked>
    <input type="radio" checked disabled />
  </StyledRadioField>
);

export const RadioButtonLabel = styled.div`
  margin-left: 20px;
`;

// This component is a radio buttons that can be used outside of a Form context where necessary,
// such as in the modal for picking a Claim Number for a case.
//
// If the component is flagged 'locked', it returns a static, faded button to show the user's
// existing selection. Otherwise it returns the actual clickable radio button.
interface ComponentProps extends Props {
  locked?: boolean;
}
export const RadioButtonControlled: FunctionComponent<ComponentProps> = ({
  name,
  id,
  label,
  value,
  checked,
  disabled,
  isError,
  onChange,
  locked,
  children
}) => {
  if (locked) {
    return <StaticSelectedRadioButton>{children}</StaticSelectedRadioButton>;
  }
  return (
    <SelectableRadioButton
      name={name}
      id={id}
      label={label}
      value={value}
      checked={checked}
      disabled={disabled}
      isError={isError}
      onChange={onChange}>
      {children}
    </SelectableRadioButton>
  );
};
