import { ModalType } from '@/components/modal/types';
import { UserInfo } from '@/types';
import { UserPickerMultiPrimary } from '@instech/components';
import {
  FormikHelpers, Formik, useFormikContext, Form
} from 'formik';
import {
  FC, useEffect, useMemo, useState
} from 'react';
import { useModalContext } from '@/components/modal/ModalContext';
import { ErrorMessage } from '@/components/shared/Form/core/Components';
import { ModalFormButtons } from '@/components/shared/FormButton/FormButton';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import {
  AssignedSurveyorsInCase, UpdateAssignedSurveyors, reassignSurveyors, useAllowedSurveyors
} from '@/services/CaseAssignmentsService';
import { AccessFormValues } from '../../SurveyorsModalCommon/utils';
import { PrimaryErrorDisplay } from '../../SurveyorsModalCommon/core/PrimaryErrorDisplay';
import { AccessNotification } from '../../SurveyorsModalCommon/core/AccessNotification';
import { accessFormValidationSchema } from '../../SurveyorsModalCommon/validationSchema';

interface InnerFormProps {
  allowedSurveyors: UserInfo[];
  hasSaveError: boolean;
  assignedSurveyors: AssignedSurveyorsInCase;
}
const SurveyorsModalInnerForm: FC<InnerFormProps> = ({
  allowedSurveyors, hasSaveError,
  assignedSurveyors
}) => {
  const { initialValues, setFieldValue, values, dirty, errors, handleSubmit, isSubmitting } = useFormikContext<AccessFormValues>();
  const { close } = useModalContext();
  const editableUsers = useMemo(() =>
    values.users.filter(user => allowedSurveyors.find(s => s.id === user.id)), [allowedSurveyors, values]);

  // If the UserPicker only has one editable row left in it and no primary surveyor is selected,
  // try to ensure the selection in that row is also the Primary
  useEffect(() => {
    if (!values.primaryId && editableUsers.length === 1) {
      void setFieldValue('primaryId', editableUsers[0].id);
    }
  }, [assignedSurveyors.surveyors, editableUsers, setFieldValue, values]);

  const showPrimaryError = !!(errors.primaryId);

  const deleteIsDisabled = (user: UserInfo): boolean => {
    const isLastEditableUser = editableUsers.length === 1;
    const isPrimaryUser = user.id === values.primaryId;
    return isLastEditableUser && isPrimaryUser;
  };

  const isDisabled = (user: UserInfo): boolean => !allowedSurveyors.some(surveyor => surveyor.id === user.id);

  const primarySelectionIsDisabled = ((): boolean => {
    if (!values.primaryId) {
      return false;
    }

    return !allowedSurveyors.some(surveyor => surveyor.id === values.primaryId);
  })();

  return (
    <Form>
      <PrimaryErrorDisplay showError={showPrimaryError} />
      <UserPickerMultiPrimary
        name="users"
        namePrimary="primaryId"
        options={allowedSurveyors}
        noErrorColors={!showPrimaryError}
        noErrors
        isDisabled={isDisabled}
        primarySelectionIsDisabled={primarySelectionIsDisabled}
        deleteIsDisabled={deleteIsDisabled}
      />
      <AccessNotification initialUsers={initialValues.users} selectedUsers={values.users} />
      <ModalFormButtons
        onSave={handleSubmit}
        onCancel={close}
        isPending={isSubmitting}
        isDisabled={!dirty || isSubmitting}
      />
      {hasSaveError && (
        <ErrorMessage>Error when saving surveyors.</ErrorMessage>
      )}
    </Form>
  );
};

const createInitialValues = (assignedSurveyorsInCase: AssignedSurveyorsInCase): AccessFormValues => ({
  users: assignedSurveyorsInCase.surveyors,
  primaryId: assignedSurveyorsInCase.primarySurveyorId
});

export type SurveyorModalProps = {
  assignedSurveyorsInCase: AssignedSurveyorsInCase;
}
const SurveyorsModal = ({ assignedSurveyorsInCase }: SurveyorModalProps) => {
  const caseId = useCaseIdFromUrl();
  const [hasSaveError, setHasSaveError] = useState(false);
  const allowedSurveyors = useAllowedSurveyors(caseId);
  const initialValues = useMemo(() => createInitialValues(assignedSurveyorsInCase), [assignedSurveyorsInCase]);

  const handleSubmit = async (values: AccessFormValues, helpers: FormikHelpers<AccessFormValues>) => {
    setHasSaveError(false);
    const { users, primaryId } = values;

    const nonEmptyUsers = users.filter(item => !!item);
    if (!primaryId || nonEmptyUsers.length === 0) {
      return;
    }

    const validUserIds = nonEmptyUsers.map(user => user.id);
    const request: UpdateAssignedSurveyors = {
      primarySurveyorId: primaryId,
      surveyors: validUserIds
    };

    const response = await reassignSurveyors(caseId, request);
    setHasSaveError(response.status < 200 || response.status >= 300);

    helpers.resetForm({ values: { users: nonEmptyUsers, primaryId } });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={accessFormValidationSchema}
      enableReinitialize
      onSubmit={handleSubmit}>
      <SurveyorsModalInnerForm
        allowedSurveyors={allowedSurveyors}
        hasSaveError={hasSaveError}
        assignedSurveyors={assignedSurveyorsInCase}
      />
    </Formik>
  );
};

export const openSurveyorEditSurveyorsModal = (props: SurveyorModalProps): ModalType<SurveyorModalProps> => ({
  component: SurveyorsModal,
  options: {
    title: 'Surveyors with access to edit report',
    padding: '0px 20px 20px',
    useNewHeader: true
  },
  args: props
});
