import { useState } from 'react';
import { Case } from '@/types';
import { Page } from '@/components/layout/Page';
import styled from 'styled-components';
import { createCaseAsync, CreateCaseModel } from '../admin/adminServices';
import { CreateCaseForm } from './CreateCaseForm';
import { CreatedCasesList } from './CreatedCasesList';

const ContentArea = styled.div`
  max-width: 740px;
  margin: 0 auto;
`;

export const CreateCasePage = () => {
  const [list, setList] = useState<Case[]>([]);

  const createCase = async (model: CreateCaseModel) => {
    const result = await createCaseAsync(model);
    setList(prev => [...prev, result]);
    return true;
  };

  return (
    <Page>
      <ContentArea>
        <CreateCaseForm onCreate={createCase} />
        <CreatedCasesList cases={list} />
      </ContentArea>
    </Page>
  );
};
