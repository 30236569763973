import { charterOptionsByGroups } from '@/components/pages/edit/widgets/Occurrences/core/options';
import { Dropdown, TextField } from '@instech/components';
import { WarningFilled } from '@instech/icons';
import { useField } from 'formik';
import {
  ComponentProps, FC, PropsWithChildren
} from 'react';
import styled from 'styled-components';

const StyledInstructionText = styled.label<{ hasError: boolean }>`
  font-weight: bold;
  color: ${({ theme, hasError }) => hasError ? theme.warning : theme.marineBlue};

  ::after {
    content: '*';
    color: ${({ theme }) => theme.warning};
  }
`;

export const InstructionText: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [, { touched, error }] = useField<string[]>('selectedClaims');
  return (
    <StyledInstructionText htmlFor="selectedClaims" hasError={touched && error !== undefined}>{children}</StyledInstructionText>
  );
};

const StyledOccurrenceNameInput = styled(TextField)`
  margin-bottom: 24px;
`;
export const OccurrenceNameInput: FC<ComponentProps<typeof StyledOccurrenceNameInput>> = props => (
  <StyledOccurrenceNameInput required label="Occurrence Name" placeholder="" name="occurrenceName" {...props} />
);

// Styles wouldn't apply directly to the dropdown.
const DropdownContainer = styled.div`
  .occurrence-modal-prefix__menu {
    z-index: 200;
  }
  > div {
    margin: 0 0 24px 0;
  }
`;
export const OccurrenceCharterDropdown = () => (
  <DropdownContainer>
    <Dropdown
      placeholder="Select Charter"
      label="Charter"
      name="charter"
      classNamePrefix="occurrence-modal-prefix"
      clearable
      options={charterOptionsByGroups}
    />
  </DropdownContainer>
);

const ErrorText = styled.p`
  color: ${({ theme }) => theme.warning};
  display: flex;
  gap: 4px;
  margin: 0;
`;
export const ErrorLine: FC<PropsWithChildren<{}>> = ({ children }) => (
  <ErrorText>
    <WarningFilled />
    {children}
  </ErrorText>
);
