/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading,no-nested-ternary */
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Field, useField } from 'formik';
import { TruncatingText } from '@instech/components';
import {
  FieldLayout, FieldLabel, EmptyLabel, ErrorMessage
} from '../../core/Components';
import { useFieldId } from '../../core/useFieldId';

interface PaddingProps {
  noPadding?: boolean;
}

export const Padding = styled.span<PaddingProps>`
  padding: ${props => props.noPadding ? '0px' : '10px'};
`;

interface CheckboxFieldProps {
  error?: boolean | string;
  disabled?: boolean;
}

export const CheckboxField = styled.div<CheckboxFieldProps>`
  display: flex;
  flex-direction: row;

  & label {
    display: flex;
    align-items: center;
    line-height: 21px;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    // This is needed to make the text truncation work in the children
    overflow: hidden;
  }
  & input[type="checkbox"]:disabled {
    cursor: default;
  }
  & input[type="checkbox"] {
    position: relative;
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    line-height: 21px;
    box-sizing: content-box;
    appearance: none;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};

    &::before {
      content: '';
      display: inline-block;
      left: 0px;
      width: 20px;
      height: 20px;
      background: ${props => props.disabled ? props.theme.lightGray : props.theme.white};
      border: 1px solid ${props => props.error ? props.theme.red : (props.disabled ? props.theme.mediumGray : props.theme.border.gray)};
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
      box-sizing: border-box;
    }
    &:checked::before {
        border-color: ${props => props.theme.marineBlue};
        background: ${props => props.theme.marineBlue};
      }
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 0px;
      height: 0px;
      bottom: 5px;
      left: 7px;
      border: 1px solid transparent;
      border-bottom-color: ${props => props.disabled ? props.theme.lightGray : props.theme.white};
      border-right-color: ${props => props.disabled ? props.theme.lightGray : props.theme.white};
      transform: rotate(45deg);
    }
    &:checked::after {
      width: 5px;
      height: 13px;
    }
  }
`;

interface Props {
  name: string;
  title?: string;
  value?: string;
  label?: string;
  emptyLabel?: boolean;
  rightLabel?: string;
  noErrors?: boolean | string;
  className?: string;
  disabled?: boolean;
  /** Unique identifier to couple label and input field.
   * Will be generated based on name and formId in context if not specified. */
  fieldId?: string;
  noPadding?: boolean
}
export const Checkbox: FunctionComponent<Props> = (
  { fieldId, name, title, value, label, emptyLabel, rightLabel, noErrors, className, disabled, noPadding, ...fieldProps }
) => {
  const [field, meta] = useField(name);
  const { fieldId: generatedFieldId } = useFieldId(name);
  const id = fieldId || generatedFieldId;
  return (
    <FieldLayout className={className}>
      {label && !emptyLabel && <FieldLabel as="label" htmlFor={id}>{label}</FieldLabel>}
      {!label && emptyLabel && <EmptyLabel />}
      <CheckboxField error={meta.touched && meta.error} disabled={disabled} {...fieldProps}>
        <label htmlFor={id}>
          <Padding noPadding={noPadding}>
            <Field id={id} title={title} name={name} type="checkbox" value={value || (disabled ? false : field.checked)} disabled={disabled} {...fieldProps} />
          </Padding>
          {rightLabel && <TruncatingText text={rightLabel} />}
        </label>
      </CheckboxField>
      {!noErrors && (
        <ErrorMessage>
          {meta.error && meta.touched ? meta.error : ''}
        </ErrorMessage>
      )}
    </FieldLayout>
  );
};
