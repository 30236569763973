import { useSurveyLocations } from '@/services/locationServices';
import { useOccurrences } from '@/services/occurrenceServices';
import { CaseMetadata } from '@/types';
import { Loader } from '@instech/components';
import styled from 'styled-components';
import { OccurrenceWithMultipleInterestsRow } from './OccurrenceWithMultipleInterestsRow';
import { mapLocationToOccurrences } from './utils/mapLocationToOccurrences';

const ContentArea = styled.div`
  margin: 30px 0px;
`;

interface Props {
  vesselData: CaseMetadata;
}

export const CaseInformationContainer = ({ vesselData }: Props) => {
  const { data: occurrenceData } = useOccurrences();
  const { data: locationsData } = useSurveyLocations();

  if (!occurrenceData || !locationsData) {
    return <Loader />;
  }

  const mappedOccurrences = mapLocationToOccurrences(occurrenceData, locationsData);

  return (
    <ContentArea>
      {mappedOccurrences.map(occurrence => (
        <OccurrenceWithMultipleInterestsRow key={occurrence.id} {...occurrence} policyCurrency={vesselData.policyCurrency} />
      ))}
    </ContentArea>
  );
};
