import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MoveLayerDown } from '@instech/icons';
import { fileStatusOptions, useFileLink } from '@/services/fileServices';
import { availableInfoPropType } from '@/prop-types/custom-prop-types';
import {
  Table, TableHeader, TableRow, TableCell
} from '@/components/shared/Table';
import { ShortenedLabel, TruncatingText } from '@instech/components';

const TableWrapper = styled.div`
  & + & {
    margin-top: 10px
  }
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 8px;
  color: ${props => props.theme.marineBlue};
  &:hover:active {
    padding: 0px 7px 0px 9px;
  }
`;

const statusValueToLabel = status => {
  const foundStatus = fileStatusOptions.find(option => option.id === status);
  if (!foundStatus?.name) return <i>Unknown status</i>;
  return foundStatus.name;
};

const DownloadButton = ({ id }) => {
  const { data } = useFileLink(id, false);
  return (
    <StyledLink href={data?.url ?? ''}>
      <MoveLayerDown width={24} height={24} />
    </StyledLink>
  );
};
DownloadButton.propTypes = {
  id: PropTypes.string,
};

export const InfoTable = ({ info, category }) => (
  <TableWrapper>
    <Table layout="1fr 150px 180px 120px">
      <TableHeader>{category}</TableHeader>
      <TableHeader>
        <ShortenedLabel label="PII" tooltip="Personal Identifiable Information" />
      </TableHeader>
      <TableHeader>Status</TableHeader>
      <TableHeader textAlignCenter>Download</TableHeader>
      {info.map(({ id, title, status, personallyIdentifiableInformation: pii, isPlaceholderForRequestedFile }, i) => (
        <TableRow key={id} even={i % 2 === 0}>
          <TableCell><TruncatingText text={title} width="280px" /></TableCell>
          <TableCell>{pii ? `Yes ${pii.includesEu ? ' - EU citizens' : ''}` : 'No'}</TableCell>
          <TableCell>{statusValueToLabel(status)}</TableCell>
          <TableCell>
            { !isPlaceholderForRequestedFile && <DownloadButton id={id} /> }
          </TableCell>
        </TableRow>
      ))}
    </Table>
  </TableWrapper>
);
InfoTable.propTypes = {
  info: PropTypes.arrayOf(availableInfoPropType),
  category: PropTypes.string
};
