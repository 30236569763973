import { ReactNode } from 'react';
import styled from 'styled-components';
import ReactTooltip, { TooltipProps } from 'react-tooltip';
import { theme } from '@/utils/theme';
import { TouchArea, IconWrapper } from './Components';

const Exclaim = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: -2px;
`;

// Styling a React Tooltip needs a defined class to reliably
// add a style, so let's do this as a workaround
const StyledReactTooltip = styled(ReactTooltip)`
  &.marineBlueTooltip {
    padding: 8px 12px;
    &.show {
      opacity: 1; 
    }
  }
  &.whiteToolTip {
    padding-right: 0px;
    color: ${props => props.theme.marineBlue};
    box-shadow: 0 4px 12px 0 rgba(0,62,103,0.33);
    &.show {
      opacity: 1; 
    }
    &.show::after {
      left: 85%;
    }
  }
`;

export const HeaderWrapper = styled.h2`
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: bold;
`;

export const TextWrapper = styled.p`
  padding-right: 10%;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  margin-top: 5px;
`;

interface IconPositon {
  top?: string;
  right?: string;
}

interface IconSize {
  width?: string;
  height?: string;
}

interface IconProps {
  id: string;
  message: string;
  symbol?: string;
  color?: string;
  backgroundColor?: string;
  borderWidth?: string;
  dataEvent?: string;
  position?: IconPositon;
  iconSize?: IconSize;
}

export const NotifyIcon = (props: IconProps) => (
  <IconWrapper
    data-tip={props.message}
    data-for={props.id}
    tabIndex={0}
    data-testid="notify-tooltip-ring"
    backgroundColor={props.backgroundColor}
    color={props.color}
    borderWidth={props.borderWidth}
    data-event={props.dataEvent}
    position={props.position}
    iconSize={props.iconSize}>
    <Exclaim>{props.symbol ?? '!'}</Exclaim>
    <TouchArea />
  </IconWrapper>
);

interface NotifyTooltipProps extends TooltipProps {
  id: string;
}
export const NotifyTooltip = ({ id, ...props }: NotifyTooltipProps) => (
  <StyledReactTooltip
    id={id}
    effect="solid"
    backgroundColor={theme.marineBlue}
    className="marineBlueTooltip"
    {...props}
  />
);

interface AverageValuesTooltipProps extends TooltipProps {
  children: ReactNode;
  refCallBack(element: any): void;
}

export const AverageValuesTooltip = ({ id, afterShow, afterHide, refCallBack, children }: AverageValuesTooltipProps) => (
  <StyledReactTooltip
    id={id}
    clickable
    offset={{ left: 115 }}
    afterShow={afterShow}
    afterHide={afterHide}
    ref={el => refCallBack(el)}
    backgroundColor={theme.white}
    className="whiteToolTip">
    {children}
  </StyledReactTooltip>
);
