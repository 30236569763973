import styled from 'styled-components';
import { Star } from '@instech/icons';
import { BoxButton, Divider } from '@instech/components';

const Container = styled.div`
  color: ${props => props.theme.marineBlue};  
  width: 100%;
`;

const Text = styled.div`
  max-width: 320px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & svg {
    width: 50px;
    height: 50px;
    margin: 20px;
  }
`;

const Header = styled.h2`
  margin-bottom: 10px;
`;

interface ModalProps {
  close: () => void;
}

const MaximumFavouritesModal = ({ close }: ModalProps) => (
  <Container>
    <Content>
      <Star />
      <Header>Maximum 10 starred cases</Header>
      <Text>You have reached the limit of 10 starred cases.</Text>
      <Text>Please remove one of your starred cases to add a new one.</Text>
    </Content>
    <Divider />
    <ButtonWrapper>
      <BoxButton onClick={close}>
        Done
      </BoxButton>
    </ButtonWrapper>
  </Container>
);

interface Props {
  close: () => void;
}
export const showMaximumFavouritesModal = ({ close }: Props) => ({
  component: MaximumFavouritesModal,
  options: {
    title: '',
    size: 'small',
    footer: false,
  },
  args: {
    close
  }
});
