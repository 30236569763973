import useSWR from 'swr';

export interface Instruction {
  reference: string;
  description: string;
  details: string;
}

const tenMinutesInMs = 1000 * 60 * 10;

export const useInstructions = (instructionsKey: string | null) => {
  const { data, error } = useSWR<Instruction>(instructionsKey ? `instructions/${instructionsKey}` : null, { dedupingInterval: tenMinutesInMs });

  if (error) {
    throw new Error(error);
  }
  if (!instructionsKey) {
    return undefined;
  }

  return data;
};
