import { useModalContext } from '@/components/modal/ModalContext';
import { SlimButton } from '@instech/components';
import { ComponentProps, FC } from 'react';
import styled from 'styled-components';
import { RowBackground } from '../../Components';
import { AddNewOccurrence, showAddNewOccurrenceModal } from './AddNewOccurrenceModal';

const ButtonRow = styled.div`
  padding: 32px;
  margin: 0 5px;
`;

const AddNewOccurrenceButton: FC<ComponentProps<typeof AddNewOccurrence>> = props => {
  const modal = showAddNewOccurrenceModal(props);
  const { open } = useModalContext();
  const openModal = () => open(modal);

  return (
    <RowBackground>
      <ButtonRow>
        <SlimButton onClick={openModal} disabled={!props.userCanEdit}>ADD NEW OCCURRENCE</SlimButton>
      </ButtonRow>
    </RowBackground>
  );
};

export default AddNewOccurrenceButton;
