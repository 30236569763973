import { TimelineEventType, TimelineSegmentEvent } from '@/utils/timelineTypes';
import { showBackgroundOnPrint } from '@instech/components';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import {
  StyledEventDetailsProps, StyledEventProps, StyledEventTextProps
} from '../types';
import { EventSubtitle } from './EventSubtitle';

const DetailsContainer = styled.div<StyledEventDetailsProps>`
  display: grid;
  column-gap: 16px;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 10;
  ${props => !props.isRight && css`
    grid-template-areas: "type text status"; 
    grid-template-columns: 32px 1fr auto;
    padding-right: 8px;
  `}
  ${props => props.isRight && css`
    grid-template-areas: "text status type"; 
    grid-template-columns: 1fr auto 32px;
    padding-left: 8px;
  `};
`;

const EventTypeMarker = styled.div<StyledEventProps>`
  grid-area: type;
  width: 32px;
  height: 100%;
  background-color: ${props => props.theme.timeline[props.eventType].main};  
  border-radius: ${props => !props.isRight ? '3px 0 0 3px' : '0 3px 3px 0'};
  ${showBackgroundOnPrint}
`;

const EventText = styled.span<StyledEventTextProps>`
  grid-area: text;
  margin-right: auto;
  font-size: 18px;
  line-height: 18px;
  padding: 12px 0px;
  overflow-wrap: break-word;
  width: 100%;
  overflow: hidden;
  ${props => props.isBlank && css`
    color: grey;
    font-style: italic;
  `};
  ${props => props.isBold && css`
    font-weight: bold;
  `}
`;

interface EventStatusProps {
  includeInReport: boolean;
}
const EventStatus = styled.span<EventStatusProps>`
  grid-area: status;
  background-color: ${props => props.theme.timeline.Occurrence.main};
  padding: 4px 8px;
  border-radius: 8px;
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  text-transform: uppercase;
  ${props => !props.includeInReport && css`
    opacity: 80%;
  `};
`;

interface Props {
  event: TimelineSegmentEvent;
  eventType: TimelineEventType;
  isOtherSide: boolean;
  isIncludedInReport: boolean;
  onClick: () => void;
}
export const EventDetails: FC<Props> = ({ event, eventType, isOtherSide, isIncludedInReport, onClick }) => {
  const activityType = event.typeOfActivity === 'Custom' ? event.customActivityName : event.typeOfActivity;
  const showStatus = event.timeStatus === 'Estimate';
  return (
    <DetailsContainer isRight={isOtherSide} onClick={onClick}>
      <EventTypeMarker eventType={eventType} isRight={isOtherSide} includeInReport={isIncludedInReport} />
      <EventText isBlank={!event.typeOfActivity}>
        {activityType || 'No activity given'}
        <EventSubtitle event={event} />
      </EventText>
      {showStatus && (
        <EventStatus includeInReport={isIncludedInReport}>{event.timeStatus}</EventStatus>
      )}
    </DetailsContainer>
  );
};
