import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Load } from '@instech/icons';
import { Loader } from '@instech/components';

const Wrapper = styled.div<{ color?: string; }>`
  display: flex;
  justify-content: center;
  color: ${props => props.color || props.theme.marineBlue};
`;

const AreaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.marineBlue};
  width: 100%;
  height: 120px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;  
  width: 70px;
  height: 48px;
  padding: 10px 20px;
  box-sizing: border-box;
  align-items: center;
  color: ${props => props.theme.marineBlue};
`;

export const SmallLoader: FunctionComponent = () => (
  <Wrapper color="inherit">
    <Load width="24px" height="16px" />
  </Wrapper>
);

export const ButtonLoader: FunctionComponent = () => (
  <ButtonWrapper>
    <Load width="80px" />
  </ButtonWrapper>
);

export const AreaLoader: FunctionComponent = () => (
  <AreaWrapper>
    <Loader size="medium" />
  </AreaWrapper>
);
