import { roles } from '@/components/AppRouting/roles';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import { ModalHandler } from '@/components/modal/Modal';
import { useModalContext } from '@/components/modal/ModalContext';
import { useCaseStatus } from '@/services/caseStatusServices';
import {
  useCategories,
  useFileList,
  useFileOptions
} from '@/services/fileServices';
import { Loader } from '@instech/components';
import { useState } from 'react';
import { AvailableInformationModalFooter } from './core/AvailableInformationModalFooter';
import { AvailableInfoEditPage } from './pages/AvailableInfoEditPage/AvailableInfoEditPage';
import { AvailableInfoReadOnlyPage } from './pages/AvailableInfoReadOnlyPage/AvailableInfoReadOnlyPage';

export const AvailableInformationTool = () => {
  const { files } = useFileList();
  const modal = useModalContext();
  const role = useCurrentRole();
  const { data: fileOptions } = useFileOptions();
  const { data: categories } = useCategories();
  const { data: status } = useCaseStatus(true);
  const [filterRequestedFiles, setFilterRequestedFiles] = useState<boolean>(false);

  if (!files || !fileOptions || !categories) {
    return <Loader />;
  }

  const isReadOnly = role?.name === roles.claimHandler || status?.isClosed;
  const filteredItems = files.filter(file => !filterRequestedFiles || file.status === 'Requested');

  return (
    <ModalHandler>
      {isReadOnly ? (
        <AvailableInfoReadOnlyPage
          items={filteredItems}
          fileStatusList={fileOptions}
          categories={categories}
          showGdprMessage={status?.isClosed}
        />
      ) : (
        <AvailableInfoEditPage items={files} fileStatusList={fileOptions} categories={categories} />
      )}
      <AvailableInformationModalFooter
        role={role}
        close={modal.close}
        filterRequestedFiles={filterRequestedFiles}
        setFilterRequestedFiles={setFilterRequestedFiles}
        hasFiles={files.length > 0}
      />
    </ModalHandler>
  );
};

export const openAvailableInformationTool = {
  component: AvailableInformationTool,
  options: {
    title: 'Available Information',
    footer: true // Deprecated
  },
  args: {}
};
