import { ClipboardEvent, useState } from 'react';
import styled from 'styled-components';
import { useField, useFormikContext } from 'formik';
import {
  formatNumber, parseFormattedNumber, sanitizeFormattedNumber
} from '@/utils/number';
import {
  FieldLayout, FieldLabel, ErrorMessage, InputField
} from '../core/Components';
import { useFieldId } from '../core/useFieldId';

interface NumberInputProps {
  error?: string | boolean;
}
const NumberInput = styled(InputField) <NumberInputProps>`
  & input[type=number] {
    -moz-appearance: textfield;
  }
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

interface FieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  noErrors?: boolean;
  min?: number;
  step?: number;
  className?: string;
}
export const MoneyField = ({ name, label, noErrors, placeholder, min, step = 0.01, className }: FieldProps) => {
  const [, meta] = useField(name);
  const { handleBlur, setFieldValue } = useFormikContext();
  const [isEditing, setIsEditing] = useState(false);
  const { fieldId } = useFieldId(name);

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  // on key down, catch comma key and replace with dot
  const onKeyDownHandler = (e: any) => {
    if (e.key === ',') {
      e.preventDefault();
      // snapshot current value
      const selStart = e.target.selectionStart;
      const selEnd = e.target.selectionEnd;
      const oldStr = e.target.value;
      // slice around added comma, insert dot, and place caret after the dot
      const newValue = `${oldStr.slice(0, selStart)}.${oldStr.slice(selEnd)}`;
      e.target.value = newValue;
      e.target.selectionStart = selStart + 1;
      e.target.selectionEnd = selStart + 1;
    }
  };

  // On blur, sanitize field by clearing spaces before effects follow
  const onBlurHandler = (e: any) => {
    const sanitizeVal = sanitizeFormattedNumber(e.target.value);
    setFieldValue(name, sanitizeVal);
    handleBlur(e);
    toggleEditing();
  };

  const onPasteHandler = (e: ClipboardEvent) => {
    // Stop data actually being pasted into div
    e.stopPropagation();
    e.preventDefault();

    // Get pasted data via clipboard API
    const clipboardData = e.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('text');

    const parsedNumber = parseFormattedNumber(pastedData);

    setFieldValue(name, parsedNumber);
  };

  // Format non-focused display with thousand-separators
  const formatValue = (val: string) => {
    const numVal = Number(val);
    if (Number.isNaN(numVal)) return val;
    return formatNumber(numVal);
  };

  const handleAutoSelect = (input: any) => {
    if (!input) return;
    // needs to be checked as a string, otherwise it will always be false
    if (input.value.toString() === '0') {
      input.select();
    }
  };

  return (
    <FieldLayout className={className}>
      {label && <FieldLabel as="label" htmlFor={fieldId}>{label}</FieldLabel>}
      <NumberInput error={meta.touched && meta.error}>
        {isEditing ? (
          <input
            id={label && fieldId}
            placeholder={placeholder}
            name={name}
            type="text"
            inputMode="decimal"
            onKeyDown={onKeyDownHandler}
            onChange={e => setFieldValue(name, e.target.value)}
            onBlur={onBlurHandler}
            onPaste={onPasteHandler}
            value={meta.value}
            min={min}
            step={step}
            ref={input => handleAutoSelect(input)}
          />
        ) : (
          <input
            id={label && fieldId}
            type="text"
            name={name}
            placeholder={placeholder}
            value={formatValue(meta.value)}
            onFocus={toggleEditing}
          />
        )}
      </NumberInput>
      {!noErrors && (
        <ErrorMessage>
          {meta.error && meta.touched ? meta.error : ''}
        </ErrorMessage>
      )}
    </FieldLayout>
  );
};
