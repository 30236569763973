import { childrenPropType } from '@/prop-types/custom-prop-types';
import { Route, Switch } from 'react-router';
import { ProtectedRoute } from '@/components/authentication/ProtectedRoute';
import { UnauthorizedPage } from '@/components/pages/error/UnauthorizedPage';
import { CallbackPage } from '@/components/pages/authentication/CallbackPage';
import { LoginPage } from '@/components/pages/authentication/LoginPage';
import { SandboxPage } from '../pages/sandbox/SandboxPage';

const isDevelopment = () => process.env.NODE_ENV === 'development';

export const RootRouting = ({ children }) => (
  <Switch>
    { isDevelopment && (
    <Route exact path="/sandbox">
      <SandboxPage />
    </Route>
    )}
    <Route path="/login">
      <LoginPage />
    </Route>
    <Route path="/callback">
      <CallbackPage />
    </Route>
    <Route path="/unauthorized">
      <UnauthorizedPage />
    </Route>
    <Route path="/signedout">
      <LoginPage />
    </Route>
    <ProtectedRoute path="/">
      {children}
    </ProtectedRoute>
  </Switch>
);

RootRouting.propTypes = {
  children: childrenPropType
};
