import { Guid } from '@/types';
import { useLightboxContext } from '@instech/components';
import { useCaseStatus } from '@/services/caseStatusServices';
import styled from 'styled-components';
import { ImageGrid } from '../core/ImageGrid';
import { NoImagesUploaded } from '../core/NoImagesUploaded';
import { FilesHiddenGdprMessage } from '../../shared/FilesHiddenGdprMessage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

interface ImageLibraryProps {
  imageIds: Guid[];
}
export const ImageLibraryReadOnly = ({ imageIds }: ImageLibraryProps) => {
  const { open: openInLightbox } = useLightboxContext();
  const { data: status } = useCaseStatus(true);
  const showGdprMessage = imageIds.length > 0 && status?.isClosed;

  if (imageIds.length < 1) {
    return <NoImagesUploaded />;
  }

  return (
    <Container>
      {showGdprMessage && <FilesHiddenGdprMessage />}
      <ImageGrid
        imageIds={imageIds}
        onSelectImage={image => openInLightbox(image)}
        disableSelect={false}
        noSidebar /* Quick workaround to make this a bit wider without a sidebar */
        showPlaceHolderForPiiImages={status?.isClosed}
      />
    </Container>
  );
};
