import { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router';
import { Guid, ReportTemplate } from '@/types';
import { Loader } from '@instech/components';
import {
  useTemplate, getTemplateWidgets, getTemplateChapters, filterOutHiddenChaptersAndWidgets
} from '@/services/templateService';
import { useVersionedReport } from '@/services/versionsService';
import { Part as PartType, SigneeUserInfo } from '@/utils/archivedReportTypes';
import { Tool, ViewChapter } from '@/utils/manifestTypes';
import { ReportLoader } from '@/components/shared/ReportLoader';
import { EditPage } from '@/components/layout/Page';
import { ReportProvider } from '../shared/core/ReportContext';
import { ActionSideBarView } from '../shared/components/ActionSideBar';
import { ScrollUpdater } from '../shared/core/ScrollUpdater';
import { FrontpageImageReportView } from '../shared/components/FrontpageImage';
import { SignatureReportView } from '../shared/components/Signature';
import { Content } from '../../../layout/EditGrid';
import { ReportViewNav } from '../../../layout/navigation/ReportNav';
import {
  ContentGrid, ReportSidebar, ReportArea
} from '../core/Components';
import { MetadataProvider } from '../shared/core/MetadataContext';
import { getTitleChapterDictionary } from '../core/utils';
import { Chapter } from './core/Chapter';

interface ReportContentProps {
  caseId: Guid;
  parts: PartType[];
  tools: Tool[];
  signatureId?: Guid;
  signerCompany?: string;
  signeeUserInfo?: SigneeUserInfo;
  viewChapters: ViewChapter[];
  companyName?: string;
  reportTemplate: ReportTemplate;
}
const ReportContent: FunctionComponent<ReportContentProps> = (
  { caseId, parts, tools, signatureId, signerCompany, signeeUserInfo, viewChapters, companyName, reportTemplate }
) => {
  const chaptersByTitle = getTitleChapterDictionary(parts, tools);
  const reportTemplateChapters = getTemplateChapters(reportTemplate);
  const reportTemplateWidgets = getTemplateWidgets(reportTemplate);

  return (
    <ReportArea>
      <FrontpageImageReportView />
      {viewChapters.map(({ title }) => {
        const chapterData = chaptersByTitle[title];
        const widgets = filterOutHiddenChaptersAndWidgets(chapterData, true, reportTemplateChapters, reportTemplateWidgets);
        if (widgets.length === 0) return null;
        return (
          <Chapter
            key={chapterData.id}
            caseId={caseId}
            title={title}
            widgets={widgets}
            chapterId={chapterData.id} />
        );
      })}
      <SignatureReportView signatureId={signatureId} companyName={companyName ?? signerCompany} signeeUserInfo={signeeUserInfo} />
    </ReportArea>
  );
};

const getTocFallbackStructure = (parts: PartType[], tools: Tool[]) => {
  const partChapters = parts.flatMap(p => p.chapters.map(c => ({ title: c.title, showHeading: true })));
  const toolChapters = tools.flatMap(t => t.widgets).map(tw => ({ title: tw.title, showHeading: true }));
  return [
    ...toolChapters,
    ...partChapters
  ];
};

export const ReportViewPage = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { id: caseId, version } = useParams() as { id: string; version: string; };
  const { data } = useVersionedReport(caseId, version);
  const { data: reportTemplate } = useTemplate(caseId);

  if (!data || !reportTemplate) return <Loader />;

  const fallbackStructure = getTocFallbackStructure(data.parts, data.tools);
  const viewChapters = data.viewStructure?.flatMap(p => p.viewChapters);

  const viewChaptersOrFallback = viewChapters ?? fallbackStructure;

  const { metadata } = data;
  return (
    <ReportLoader>
      <MetadataProvider value={{ metadata }}>
        <ReportProvider<ViewChapter[]> value={{ toc: viewChaptersOrFallback }}>
          <EditPage title={data.title}>
            <ReportSidebar>
              <ReportViewNav onMobileOpen={setMobileMenuOpen} reportTemplate={reportTemplate} />
            </ReportSidebar>
            <Content visible={!mobileMenuOpen}>
              <ScrollUpdater>
                <ContentGrid>
                  <ReportContent
                    {...data}
                    viewChapters={viewChaptersOrFallback}
                    caseId={caseId}
                    reportTemplate={reportTemplate!}
                  />
                  <ActionSideBarView />
                </ContentGrid>
              </ScrollUpdater>
            </Content>
          </EditPage>
        </ReportProvider>
      </MetadataProvider>
    </ReportLoader>
  );
};
