import { useParams } from 'react-router';
import { FullwidthWidgetWrapper } from '@/components/pages/edit/widgets/FullwidthWidgetWrapper';
import {
  updateLocationsAsync, deleteLocationAsync, useSurveyLocations
} from '@/services/locationServices';
import { SurveyLocation } from '@/types';
import { Loader } from '@instech/components';
import { useOccurrences } from '@/services/occurrenceServices';
import { MissingChapter } from '@/components/pages/edit/components/MissingChapter';
import { getOptions } from '@/components/shared/Form/fields/Dropdown/OccurrenceSelect';
import { LocationTable } from './LocationTable';
import { ReportPreview } from './core/ReportPreview';
import { EditPane } from '../../core/EditPane';
import { LocationTableForm } from './core/utils';

export const SurveyLocations = () => {
  const { id: caseId } = useParams<{ id: string }>();
  const { data: locations, mutate } = useSurveyLocations();
  const { data: occurrences } = useOccurrences({ suspense: true });
  const missingDependencies = occurrences?.length === 0 && locations?.length === 0;

  const onSaveHandler = async (locationForm: LocationTableForm) => {
    const payload: SurveyLocation[] = locationForm.locations.map(location => ({
      ...location, occurrences: getOptions(location.occurrences)
    }));
    /**
     * Some weird Formik bug doesn't update initialValues on all updates to the server
     * data. Therefore, we call mutate twice in the update and delete callbacks to ensure
     * that initialValues was updated.
     *
     * If we do not update twice, the dirty state of the form will not be correct, and the
     * 'Save' button will be active when it shouldn't
     */
    void mutate(payload, false);
    const result = await updateLocationsAsync(caseId, payload);
    void mutate(result, false);
    return result;
  };

  const onDelete = async (surveyLocationId: string) => {
    const newLocations = locations?.filter(x => x.id !== surveyLocationId);
    void mutate(newLocations, false);
    await deleteLocationAsync(caseId, surveyLocationId);
    void mutate(newLocations, false);
  };

  // TODO: Proper handling of empty data
  if (!locations) {
    return <Loader />;
  }

  if (missingDependencies) {
    return (
      <MissingChapter
        chapterName="Occurrences"
        text="Please add an occurrence before editing this chapter."
      />
    );
  }

  return (
    <FullwidthWidgetWrapper>
      <EditPane title="Survey location">
        <ReportPreview />
        <LocationTable
          locations={locations}
          onSubmit={onSaveHandler}
          onDeleteOnServer={onDelete}
        />
      </EditPane>
    </FullwidthWidgetWrapper>
  );
};
