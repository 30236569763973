import { FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  Checkbox, NumberField as Number, CheckboxField as CheckboxClassName,
} from '@/components/shared/Form/FormFields';
import { TableHeader as HeaderRow } from '@/components/shared/Table';
import { ErrorMessage } from '@/components/shared/Form/core/Components';

const StyledNumberWrapper = styled.div`
  ${ErrorMessage} {
    height: auto;
  }
`;

interface NumberWrapperProps {
  name: string;
  label: string;
  placeholder?: string;
}
export const NumberField: FunctionComponent<NumberWrapperProps> = ({ name, label, placeholder = '' }) => (
  <StyledNumberWrapper>
    <Number
      name={name}
      label={label}
      placeholder={placeholder}
      min={0}
    />
  </StyledNumberWrapper>
);

export const CheckboxField = styled(Checkbox)`
  > ${CheckboxClassName} {
    justify-content: center;
  }
`;

export const TableHeader = styled(HeaderRow)`
  grid-column: 1 / span 4;
  font-size: 13px;
  color: ${props => props.theme.darkGray};
`;
