import { Loader } from '@instech/components';
import { Pane, ReportPane } from '@/components/shared/Pane';
import { openVesselInformationTool } from '@/components/tools/VesselParticulars';
import { Certificates as CertificatesType, useCertificates } from '@/services/certificatesServices';
import { getFriendlyDate } from '@/utils/date';
import { VesselInformationTab } from '@/components/tools/VesselParticulars/VesselInformationTool';
import {
  Wrapper, InfoBox, ContentBox, Particular, HeaderText, NotSpecified, ViewProps, NoAddedInformation
} from './Components';

type DateTextProp = string | undefined;

interface ValidCertificateProps {
  startDate?: DateTextProp;
  endDate?: DateTextProp;
}

const CertificateText = ({ startDate, endDate }: ValidCertificateProps) => {
  if (!startDate && !endDate) return <NotSpecified />;
  if (startDate && !endDate) return <div>{`Issued ${getFriendlyDate(startDate)}`}</div>;
  if (!startDate && endDate) return <div>{`Valid till ${getFriendlyDate(endDate)}`}</div>;
  return <div>{`Issued ${getFriendlyDate(startDate)}, valid till ${getFriendlyDate(endDate)}`}</div>;
};

interface IsmTextProps {
  value: boolean | undefined;
}

const ISMText = ({ value }: IsmTextProps) => {
  if (value) return <div>Yes</div>;
  if (value === false) return <div>No</div>;
  return <NotSpecified />;
};

interface ClassRecommendationsProps {
  hasClassRecommendations: boolean;
  classRecommendations: any[];
}

const ClassRecommendations = ({ hasClassRecommendations, classRecommendations }: ClassRecommendationsProps) => {
  if (hasClassRecommendations === false) return <NoAddedInformation />;
  if (hasClassRecommendations) {
    return (
      <>
        {classRecommendations.map(entry => (<div key={entry.id}>{`${entry.name}, due ${getFriendlyDate(entry.date)}`}</div>))}
      </>
    );
  }
  return <NotSpecified />;
};

interface InvalidCertificatesProps {
  certificatesValid: boolean;
  invalidCertificates: any[];
}

const InvalidCertificates = ({ certificatesValid, invalidCertificates }: InvalidCertificatesProps) => {
  if (certificatesValid) return <div>All certificates valid at time of occurrence</div>;
  if (certificatesValid === false) {
    return (
      <>
        {invalidCertificates.map(entry => (
          <div key={entry.id}>{`${entry.name}, invalidated ${getFriendlyDate(entry.date)}`}</div>
        ))}
      </>
    );
  }
  return <NotSpecified />;
};

export const Certificates = ({ data }: { data: CertificatesType }) => (
  <Wrapper>
    <InfoBox>
      <HeaderText large>Certificates</HeaderText>
    </InfoBox>
    <ContentBox>
      <Particular>
        <HeaderText>{data.certificatesValid ? 'Certificates' : 'Invalid Certificates'}</HeaderText>
        <InvalidCertificates certificatesValid={data.certificatesValid} invalidCertificates={data.invalidCertificates} />
      </Particular>
      <Particular>
        <HeaderText>Class recommendations</HeaderText>
        <ClassRecommendations hasClassRecommendations={data.hasClassRecommendations} classRecommendations={data.classRecommendations} />
      </Particular>
      <Particular>
        <HeaderText>DOC Details</HeaderText>
        <CertificateText {...data.ismCertificates.doc} />
      </Particular>
      <Particular>
        <HeaderText>ISM SMC Details</HeaderText>
        <CertificateText {...data.ismCertificates.smc} />
      </Particular>
      <Particular>
        <HeaderText>Casualty ISM reported?</HeaderText>
        <ISMText value={data.casualtyIsmReported} />
      </Particular>
      <Particular>
        <HeaderText>Prior related ISM reports?</HeaderText>
        <ISMText value={data.priorRelatedIsmReports} />
      </Particular>
    </ContentBox>
  </Wrapper>
);

export const CertificatesView = ({ data, title }: ViewProps) => (
  <Pane title={title}>
    <Certificates data={data} />
  </Pane>
);

export const CertificatesPreview = () => {
  const { data } = useCertificates();
  if (!data) return <Loader />;
  const tab = VesselInformationTab.CertificatesTab;

  return (
    <ReportPane title="Certificates" open={openVesselInformationTool({ currentTab: tab })} editable>
      <Certificates data={data} />
    </ReportPane>
  );
};
