import { FullwidthWidgetWrapper } from '@/components/pages/edit/widgets/FullwidthWidgetWrapper';
import { Loader, TruncatingText } from '@instech/components';
import { Introduction, WidgetReference } from '@/types';
import { PreviewReportPane } from '@/components/shared/Pane';
import { useCase } from '@/services/dashboardServices';
import {
  IntroductionAttendanceText, CommentArea, InformationHeader
} from './utility';
import { useIntroduction, useIntroductionInfoText } from './introductionService';

interface IntroductionContentProps {
  introduction: Introduction;
  infoText: string;
}
const IntroductionContent = ({ introduction, infoText }: IntroductionContentProps) => (
  <FullwidthWidgetWrapper>
    <InformationHeader>
      {infoText}
    </InformationHeader>
    <IntroductionAttendanceText
      attendance={introduction.attendance}
      remote={introduction.remote}
      withoutAttendance={introduction.withoutAttendance} />
    <CommentArea>
      <TruncatingText text={introduction.comment} wrap />
    </CommentArea>
  </FullwidthWidgetWrapper>
);

export const IntroductionReportPreview = ({ title, caseId, chapterId }: WidgetReference) => {
  const introduction = useIntroduction(caseId);
  const { data: report } = useCase(caseId, true);

  const { data: infoText } = useIntroductionInfoText();

  if (!introduction || !report || !infoText) return <Loader />;

  return (
    <PreviewReportPane title={title} caseId={caseId} chapterId={chapterId}>
      <IntroductionContent introduction={introduction} infoText={infoText} />
    </PreviewReportPane>
  );
};
