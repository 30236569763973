import { v4 as uuidv4 } from 'uuid';
import {
  EditorState, convertToRaw, convertFromRaw, CompositeDecorator, ContentBlock, RawDraftContentState
} from 'draft-js';
import styled from 'styled-components';
import linkify from 'linkify-it';
import tlds from 'tlds';
import { draftToMarkdown, markdownToDraft } from '@instech/components';

const urlMatcher = linkify()
  .tlds(tlds);

export interface RichTextState {
  id?: string;
  text: string;
  images: string[];
}
const initialState = () => ({
  id: uuidv4(),
  text: '',
  images: []
} as RichTextState);

export const initializeRichText = (data: RichTextState): RichTextState => {
  if (data === undefined) return initialState();

  const { text, images } = data;

  return {
    text: text ?? '',
    images
  };
};

const StyledLink = styled.a`
  color: ${props => props.theme.marineBlue};
  text-decoration: underline;
`;

const markdownOptions = {
  preserveNewlines: true,
  remarkableOptions: {
    disable: {
      // Disabling this makes the parser treat '    ' before a line as a paragraph instead
      // of a 'code' block, which the oarser doesn't currently have a setup to parse.
      block: ['code']
    }
  }
};

const findURLs = (contentBlock: ContentBlock, callback: (startIndex: number, endIndex: number) => void) => {
  const text = contentBlock.getText();

  const matches = urlMatcher.match(text);

  if (matches?.length) {
    matches.forEach(match => callback(match.index, match.lastIndex));
  }
};

const findLinkEntities = (contentBlock: any, callback: any) => {
  findURLs(contentBlock, callback);
};

const Link = (props: any) => (
  <StyledLink
    href={props.decoratedText}
    target="_blank"
    rel="noopener noreferrer">
    {props.children}
  </StyledLink>
);

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

export const getEditorState = (text: string) => {
  const draftState = convertFromRaw(markdownToDraft(text, markdownOptions) as RawDraftContentState);

  return new (EditorState.createWithContent as any)(draftState, decorator) as EditorState;
};

export const editorStateToText = (editorState: EditorState) => {
  const draftContent = convertToRaw(editorState.getCurrentContent());
  const markDown = draftToMarkdown(draftContent, markdownOptions);
  return markDown;
};
