import { FunctionComponent } from 'react';
import { Pane, PreviewReportPane } from '@/components/shared/Pane';
import {
  ArchivedExpense, ArchivedExpenseGroup, ExpenseGroup, Guid
} from '@/types';
import { useExpenses } from '@/services/expensesServices';
import { ExpensesView } from './ExpensesView';

const mapToArchived = (expenseGroup: ExpenseGroup, chapterId: string) => ({
  ...expenseGroup,
  chapterId,
  items: expenseGroup.items.map(expense => ({
    ...expense,
    comment: expense.includeCommentInReport ? expense.comment : null
  } as ArchivedExpense))
});

interface PreviewProps {
  caseId: Guid;
  chapterId: Guid;
  widgetId: Guid;
  widgetTitle: string;
}
export const ExpensesReportPreview: FunctionComponent<PreviewProps> = ({ caseId, chapterId, widgetId, widgetTitle }) => {
  const { data: expenses } = useExpenses('Expenses', caseId, chapterId, widgetId, { suspense: true });

  if (!expenses) return null;

  const archivedExpenses = mapToArchived(expenses, chapterId);

  return (
    <PreviewReportPane
      title={widgetTitle}
      caseId={caseId}
      chapterId={chapterId}
    >
      <ExpensesView expenses={archivedExpenses} />
    </PreviewReportPane>
  );
};

interface ViewProps {
  data: ArchivedExpenseGroup
}

export const ExpensesReportView: FunctionComponent<ViewProps> = ({ data }) => (
  <Pane title={data.name}>
    <ExpensesView expenses={data} />
  </Pane>
);
