import { useCaseInstruction } from '@/services/caseInstructionService';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useModalContext } from '@/components/modal/ModalContext';
import { RichTextView } from '@/components/shared/Form/RichTextView';
import {
  Loader, BoxButton, ButtonGroup,
  Divider
} from '@instech/components';
import { ComingSoon } from '../ComingSoon';

export const InstructionTool = () => {
  const caseId = useCaseIdFromUrl();
  const modal = useModalContext();
  const caseInstruction = useCaseInstruction(caseId);
  if (caseInstruction === undefined) return <Loader />;
  if (!caseInstruction || !caseInstruction.instructions) return <ComingSoon />;

  return (
    <div>
      <RichTextView data={{ text: caseInstruction.instructions }} />
      <Divider />
      <ButtonGroup alignRight>
        <BoxButton onClick={modal.close}>Done</BoxButton>
      </ButtonGroup>
    </div>
  );
};

export const openInstructionTool = {
  component: InstructionTool,
  options: {
    title: 'Instructions from NHC',
    size: 'small'
  },
  args: {}
};
