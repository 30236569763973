import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TableCell } from '../core/Components';
import { Row } from './Row';

type RowSize = 'medium' | 'small' | 'auto';
function getRowSize(size?: RowSize) {
  switch (size) {
    case 'small':
      return '100px';
    case 'medium':
      return '400px';
    case 'auto':
    default:
      return 'auto';
  }
}

interface NoDataCellProps {
  size?: RowSize;
}
const NoDataCell = styled(TableCell) <NoDataCellProps>`
  display: flex;
  height: ${props => getRowSize(props.size)};
  align-items: center;
  justify-content: center;
  grid-column: 1/-1;
  color: ${props => props.theme.marineBlue50};
`;

interface NoDataRowProps {
  even: boolean;
  size?: RowSize;
}
export const NoDataRow: FunctionComponent<NoDataRowProps> = ({ children, even, size = 'auto' }) => (
  <Row even={even}>
    <NoDataCell size={size}>
      {children}
    </NoDataCell>
  </Row>
);
