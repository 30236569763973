import { useEffect, useState } from 'react';

export const useHasScrolled = (scrolledHeight: number) => {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrolledHeight && !hasScrolled) {
        setHasScrolled(true);
      } else if (window.scrollY <= scrolledHeight && hasScrolled) {
        setHasScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return hasScrolled;
};
