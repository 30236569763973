import { NotifyIcon } from '@/components/shared/Tooltip';
import { CaseMetadata, Occurrence } from '@/types';
import { getFriendlyDate } from '@/utils/date';
import { isNullishOrWhitespace } from '@/utils/text';
import { WarningFilled } from '@instech/icons';
import { ComponentProps, FunctionComponent } from 'react';
import {
  Field,
  FieldIcon,
  Row,
  RowBackground,
  Rows
} from './Components';
import InterestRowsList from './InterestRowsList';
import { EditOccurrenceButton } from './UpdateOccurrenceModals';

const toReadableDate = (date?: string) => {
  if (!date) return '-';
  return getFriendlyDate(date);
};

interface Props {
  occurrence: Occurrence;
  caseMetadata: CaseMetadata;
  index: number;
  onOccurrencesUpdate: (update: (occurrences: Occurrence[]) => Occurrence[]) => Promise<void>;
  updateInterest: ComponentProps<typeof InterestRowsList>['updateInterest'];
  userCanEdit: boolean;
}
export const OccurrenceRow: FunctionComponent<Props> = ({ occurrence, caseMetadata, index, onOccurrencesUpdate, updateInterest, userCanEdit }) => {
  const alert = occurrence.interests.some(x => isNullishOrWhitespace(x.claimReference));

  const occurrenceModel: ComponentProps<typeof EditOccurrenceButton>['occurrence'] = {
    ...occurrence,
    occurrenceName: occurrence.name,
    id: occurrence.id ?? '',
    claimId: occurrence.interests[0].claimId ?? caseMetadata.claimId,
    charter: occurrence.charter
  };
  return (
    <>
      <RowBackground alert={alert}>
        {alert && <NotifyIcon id="occurrence-list-notify" message="Missing Claim Number" />}
        <Rows>
          <Row>
            <FieldIcon><WarningFilled /></FieldIcon>
            <Field label={`Occurrence ${index + 1}`} value={occurrence.name ?? ''} />
            <Field label="Date of Loss" value={toReadableDate(occurrence.dateOfLoss) ?? ''} />
            <Field label="Location" value={occurrence.location ?? ''} />
            <Field label="Charter" value={occurrence.charter ?? ''} />
            <Field label="Conditions" value={occurrence.conditions ?? ''} />
            <Field label="Claim event" value={occurrence.claimEventName ? occurrence.claimEventName : ''} />
            {userCanEdit && (
              <EditOccurrenceButton onOccurrencesUpdate={onOccurrencesUpdate} occurrence={occurrenceModel} />
            )}
          </Row>
        </Rows>
      </RowBackground>
      <InterestRowsList updateInterest={updateInterest} interests={occurrence.interests ?? []} occurrenceId={occurrence.id} userCanEdit={userCanEdit} />
    </>
  );
};
