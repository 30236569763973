import styled from 'styled-components';
import {
  Table, TableRow, TableCell
} from '@/components/shared/Table';
import { useField } from 'formik';
import { Loader, TextArea } from '@instech/components';
import { StatusTypeDropdownField } from '@/components/shared/Form/FormFields';
import {
  NumberField, CheckboxField, TableHeader
} from './Components';
import { useSimultaneousRepairsInfoText } from './timeDetailsService';

const DescriptionRow = styled.div`
  grid-column: 1 / span 4;
  background-color: ${props => props.theme.white};
`;

const Description = styled.div`
  width: 90%;
  padding: 20px;
  font-size: 13px;
  line-height: 21px;
  color: ${props => props.theme.black};
`;

const Wrapper = styled.div`
  margin-top: 20px;
`;

export const SimultaneousRepairs = () => {
  const [, { value: days }] = useField('simultaneousRepairs.days');
  const { data: infotext, isValidating } = useSimultaneousRepairsInfoText(days);

  if (isValidating) return <Loader />;
  return (
    <Wrapper>
      <Table layout="minmax(min-content, 200px) 220px minmax(280px, 1fr) auto">
        <TableHeader>Loss of Hire only - Simultaneous repairs</TableHeader>
        <DescriptionRow>
          <Description>
            {infotext}
          </Description>
        </DescriptionRow>
        <TableRow even>
          <TableCell>
            <NumberField
              name="simultaneousRepairs.days"
              label="Days"
            />
          </TableCell>
          <TableCell>
            <StatusTypeDropdownField
              name="simultaneousRepairs.status"
              label="Status"
            />
          </TableCell>
          <TableCell>
            <TextArea
              name="simultaneousRepairs.comment"
              placeholder="..."
              maxLength={500}
              label="Comment"
            />
          </TableCell>
          <TableCell>
            <CheckboxField
              name="simultaneousRepairs.includeCommentInReport"
              label="Include comment in report?"
            />
          </TableCell>
        </TableRow>
      </Table>
    </Wrapper>
  );
};
