import React, { FunctionComponent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAccount } from './AuthenticationProvider';

export const RequireAuth: FunctionComponent = ({ children }) => {
  const { isAuthenticated } = useAccount();
  const location = useLocation();
  const navigate = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      // Do not redirect if already in login flow
      if (location.pathname.startsWith('/management/')) return;
      // Login and make sure we get back to the current page
      // Use window, instead of navigate, to have the it hit the backend
      // instead of the SPA route
      window.location.href = `/management/login?returnUrl=${location.pathname}`;
    }
  }, [isAuthenticated, location, navigate]);

  return (children as React.ReactElement);
};
