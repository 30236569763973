import { FunctionComponent } from 'react';
import { BoxButton } from '@instech/components';
import { MoveLayerDown } from '@instech/icons';
import { Guid } from '@/types';
import { useModalContext } from '@/components/modal/ModalContext';
import { openDownloadPdf } from './FileDownloadModal';

interface Props {
  caseId: Guid;
  archivedId: Guid;
  title?: string;
  height?: string;
  width?: string;
  className?: string;
  pdfGenerated: boolean;
}

interface BoxButtonProps extends Props {
  inverted?: boolean;
  padding?: string;
}
export const DownloadReportButton: FunctionComponent<BoxButtonProps> = ({
  caseId,
  archivedId,
  title = 'Download report',
  inverted,
  height = '40px',
  width = '100%',
  padding = '5px 10px',
  children,
  className,
  pdfGenerated
}) => {
  const { open } = useModalContext();

  return (
    <BoxButton
      title={title}
      onClick={() => open(openDownloadPdf(caseId, archivedId, pdfGenerated))}
      startIcon={<MoveLayerDown />}
      inverted={inverted}
      height={height}
      width={width}
      padding={padding}
      className={className}
    >
      {children}
    </BoxButton>
  );
};
