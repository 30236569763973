import { FunctionComponent, useState } from 'react';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useUserSignature } from '@/services/signatureServices';
import { Loader } from '@instech/components';
import {
  ErrorMessage, FieldLabel, FieldLayout
} from '@/components/shared/Form/core/Components';
import { useField } from 'formik';
import { useCurrentUserInfo } from '@/services/userInfoService';
import { useAccount } from '@/components/authentication/AuthenticationProvider';
import { UploadForm } from './core/UploadForm';
import { SignaturePreview } from './core/SignaturePreview';

interface Props {
  name: string;
  companyName?: string;
}
export const SignatureUploader: FunctionComponent<Props> = ({ name = 'signatureId', companyName }) => {
  const [, meta, helpers] = useField(name);
  const caseId = useCaseIdFromUrl();
  const { data: userSignature } = useUserSignature();
  const { user } = useAccount();
  const { data: userInfo } = useCurrentUserInfo();

  const [signatureError, setSignatureError] = useState(false);
  const signatureId = meta.value;

  const isFetching = signatureId && !userSignature;

  if (isFetching || !user) {
    return <Loader />;
  }
  const userSignatureId = userSignature?.signature?.id;

  const signUrl = userSignature?.signature?.url;
  return (
    <FieldLayout>
      <p>Subject to the rights of the Underwriters according to the relevant insurance conditions and policy.</p>
      <FieldLabel>Signature</FieldLabel>
      <div>
        {(!signatureId || !signUrl) ?
          (
            <UploadForm
              caseId={caseId}
              onSubmitted={newSignatureId => helpers.setValue(newSignatureId)}
              onCancel={userSignatureId ? () => helpers.setValue(userSignatureId, true) : undefined}
              onError={setSignatureError}
            />
          ) : (
            <SignaturePreview
              url={signUrl}
              text="Signature"
              onReplaceSignature={() => helpers.setValue(undefined, false)}
            />
          )}
        <ErrorMessage>{!signatureError && meta.error}</ErrorMessage>
        <p>Yours faithfully,</p>
        <p>
          {userInfo?.displayName ?? user.name}
          <br />
          {userInfo?.title?.trim() ? userInfo.title : 'Surveyor'}
          {companyName && <div>{companyName}</div>}
        </p>
      </div>
    </FieldLayout>
  );
};
