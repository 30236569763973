import { useState } from 'react';
import { Guid, ReportTemplate } from '@/types';
import {
  Part as PartType, Tool, ViewChapter
} from '@/utils/manifestTypes';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { Loader } from '@instech/components';
import {
  useTemplate, getTemplateWidgets, getTemplateChapters, filterOutHiddenChaptersAndWidgets
} from '@/services/templateService';
import { useManifest } from '@/services/manifestServices';
import { ReportLoader } from '@/components/shared/ReportLoader';
import { useCase } from '@/services/dashboardServices';
import { useUserEditAccess } from '@/services/userInfoService';
import { NoAccessPage } from '@/components/AppRouting/NoAccess';
import { getSurveyorPageTitle } from '@/components/shared/Text';
import { EditPage } from '@/components/layout/Page';
import { ReportProvider } from '../shared/core/ReportContext';
import { ActionSideBarPreview } from '../shared/components/ActionSideBar';
import { ScrollUpdateProvider, ScrollUpdater } from '../shared/core/ScrollUpdater';
import { Content } from '../../../layout/EditGrid';
import { ReportPreviewNav } from '../../../layout/navigation/ReportNav';
import {
  ContentGrid, ReportSidebar, ReportArea
} from '../core/Components';
import { FrontpageImageUploader } from '../shared/components/FrontpageImage';
import { MetadataProvider } from '../shared/core/MetadataContext';
import { Chapter } from './core/Chapter';
import { getTitleChapterDictionary } from '../core/utils';

interface FrontpageImageWrapperProps {
  parts: PartType[];
}
const FrontpageImageWrapper = ({ parts }: FrontpageImageWrapperProps) => {
  const titleChapter = parts
    .flatMap(p => p.chapters)
    .find(c => c.title === 'Introduction');
    // @Cleanup-1522 - remove P2

  const readonly = titleChapter != null;
  return <FrontpageImageUploader readonly={readonly} />;
};

interface ReportContentProps {
  caseId: Guid;
  parts: PartType[];
  tools: Tool[];
  viewChapters: ViewChapter[];
  reportTemplate: ReportTemplate;
}
const ReportContent = ({ caseId, parts, tools, viewChapters, reportTemplate }: ReportContentProps) => {
  const chaptersByTitle = getTitleChapterDictionary(parts, tools);
  const reportTemplateChapters = getTemplateChapters(reportTemplate);
  const reportTemplateWidgets = getTemplateWidgets(reportTemplate);

  return (
    <ReportArea>
      <FrontpageImageWrapper parts={parts} />
      {viewChapters.map(({ title }) => {
        const chapterData = chaptersByTitle[title];
        const widgets = filterOutHiddenChaptersAndWidgets(chapterData, false, reportTemplateChapters, reportTemplateWidgets);
        if (widgets.length === 0) return null;
        return (
          <Chapter
            key={chapterData.id}
            caseId={caseId}
            title={title}
            widgets={widgets}
            chapterId={chapterData.id} />
        );
      })}
    </ReportArea>
  );
};

export const ReportPreviewPage = () => {
  const caseId = useCaseIdFromUrl();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { data } = useManifest();
  const { data: report } = useCase(caseId);
  const { data: userHasEditAccess } = useUserEditAccess();
  const { data: reportTemplate } = useTemplate(caseId);

  if (!data || !report || !reportTemplate) return <Loader />;

  if (userHasEditAccess === false) {
    return (
      <NoAccessPage />
    );
  }

  const viewChapters = data.viewStructure.flatMap(p => p.viewChapters);

  const { metadata } = report;
  const title = getSurveyorPageTitle(report.name, report.finalized);
  return (
    <ReportLoader>
      <MetadataProvider value={{ metadata }}>
        <ReportProvider<ViewChapter[]> value={{ toc: viewChapters }}>
          <ScrollUpdateProvider>
            <EditPage title={title}>
              <ReportSidebar>
                <ReportPreviewNav onMobileOpen={setMobileMenuOpen} reportTemplate={reportTemplate} />
              </ReportSidebar>
              <Content visible={!mobileMenuOpen}>
                <ScrollUpdater>
                  <ContentGrid>
                    <ReportContent caseId={caseId} parts={data.parts} tools={data.reportTools} viewChapters={viewChapters} reportTemplate={reportTemplate!} />
                    <ActionSideBarPreview />
                  </ContentGrid>
                </ScrollUpdater>
              </Content>
            </EditPage>
          </ScrollUpdateProvider>
        </ReportProvider>
      </MetadataProvider>
    </ReportLoader>
  );
};
