import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import {
  Interest, Occurrence, Option
} from '@/types';
import { useParams } from 'react-router';
import useSWR, { ConfigInterface, mutate as mutateGlobal } from 'swr';
import client, { postJsonAsync, putJsonAsync } from './client';

export const occurrenceApiUrl = (caseId: string) => `/cases/${caseId}/occurrences`;

export const getOccurrencesAsync = async (caseId: string) => {
  const occurrencesRoute = occurrenceApiUrl(caseId);
  const { data } = await client.get<Occurrence[]>(occurrencesRoute);
  return data;
};
export const getOccurrenceAsync = async (itemId: string, caseId: string) => {
  const { data } = await client.get<Occurrence>(`${occurrenceApiUrl(caseId)}/${itemId}`);
  return data;
};

interface OccurrenceRequest {
  name: string;
  charter: string | undefined;
  claimId: string[];
}
export const createOccurrenceAsync = (caseId: string, request: OccurrenceRequest) => postJsonAsync<Occurrence>(occurrenceApiUrl(caseId), request);

export const updateSurveyOccurrencesAsync = async (caseId: string, occurrences: Occurrence[]) => {
  const { data } = await client.put<Occurrence[]>(occurrenceApiUrl(caseId), occurrences);
  return data;
};

export const updateOccurrencesWithClaimIdAsync = async (caseId: string, occurrenceId: string, claimId: string) => {
  const { data } = await client.put<any>(`${occurrenceApiUrl(caseId)}/${occurrenceId}/claims/${claimId}`, {});
  return data;
};

export const updateOccurrencesWithInterestsAsync = async (caseId: string, occurrenceId: string, request: OccurrenceRequest) =>
  putJsonAsync<Occurrence>(`${occurrenceApiUrl(caseId)}/${occurrenceId}`, request);

export const refreshOccurrencesAsync = async (caseId: string, occurrenceId: string, claimId: string) => {
  const result = await client.put<any, Interest>(`${occurrenceApiUrl(caseId)}/${occurrenceId}/interest/${claimId}`)
    .catch(error => error.response);

  return { data: result.data as Interest, isError: result.status > 399 };
};

export const deleteSurveyOccurrenceAsync = async (caseId: string, occurrenceId: string) => {
  await client.delete<void>(`${occurrenceApiUrl(caseId)}/${occurrenceId}`);
};

export const useOccurrences = (config?: ConfigInterface<any, Error>) => {
  const { id: caseId } = useParams<{ id: string }>();
  const occurrencesRoute = occurrenceApiUrl(caseId);
  const swr = useSWR<Occurrence[]>(occurrencesRoute, config);
  swr.data?.forEach(occurrence =>
    occurrence.interests.sort(
      (a, b) =>
        a.claimInterest.localeCompare(b.claimInterest) ||
        a.claimReference.localeCompare(b.claimReference)
    )
  );
  return swr;
};

export const useOccurrencesForCase = (caseId: string, config?: ConfigInterface<any, Error>) => {
  const occurrencesRoute = occurrenceApiUrl(caseId);
  return useSWR<Occurrence[]>(occurrencesRoute, config);
};

export const useOccurrence = (id?: string, config?: ConfigInterface<any, Error>) => {
  const caseId = useCaseIdFromUrl();
  return useSWR<Occurrence>(() => `${occurrenceApiUrl(caseId)}/${id}`, config);
};

export const useOccurrenceOptions = () => {
  const caseId = useCaseIdFromUrl();
  const occurrenceOptionsRoute = `${occurrenceApiUrl(caseId)}/options`;
  return useSWR<Option[]>(occurrenceOptionsRoute);
};

export const useOccurrenceOptionsService = () => {
  const caseId = useCaseIdFromUrl();
  const occurrenceOptionsRoute = `${occurrenceApiUrl(caseId)}/options`;
  const { data, error } = useSWR<Option[]>(occurrenceOptionsRoute);
  if (error) {
    throw error;
  }
  return data;
};

export const mutateOccurrence = (caseId: string, itemId: string, occurrence?: Occurrence) => {
  // Update '/cases/${caseId}/occurrences/${itemId}' cache
  const promise1 = mutateGlobal(`${occurrenceApiUrl(caseId)}/${itemId}`, occurrence);

  // Update '/cases/${caseId}/occurrences' cache
  const promise2 = mutateGlobal(
    occurrenceApiUrl(caseId),
    (prev: Occurrence[]) => prev?.map(prevOccurrence => (prevOccurrence.id === itemId) ? occurrence : prevOccurrence)
  );

  return Promise.all([promise1, promise2]);
};
