/* eslint-disable react/forbid-prop-types */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableHeader } from '@/components/shared/Table';
import { ContactRow } from './ContactRow';

export const ContactsTable = ({ data, remove, caseId }) => {
  // TODO: Proper handling of empty data
  const [editItem, setEditItem] = useState(null);
  if (!data) return null;

  return (
    <Table layout="1fr 1fr 1fr 1fr 60px">
      <TableHeader>Name</TableHeader>
      <TableHeader>Function</TableHeader>
      <TableHeader>Phone</TableHeader>
      <TableHeader>Email</TableHeader>
      <TableHeader button />
      {data.map((row, i) => (
        <ContactRow
          key={row.id}
          data={row}
          even={i % 2 === 0}
          remove={remove}
          caseId={caseId}
          isEditMode={editItem === row.id}
          toggleEditMode={() => setEditItem(prev => prev === row.id ? null : row.id)}
        />
      ))}
    </Table>
  );
};

ContactsTable.propTypes = {
  data: PropTypes.array,
  remove: PropTypes.func,
  caseId: PropTypes.string
};
