import styled from 'styled-components';
import { AccountCircle } from '@instech/icons';
import { hiddenOnPrint } from '@/utils/styles';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import { Link } from 'react-router-dom';
import { useUserInfo } from '@/services/userInfoService';

const LinkArea = styled(Link)`
  display: flex;
  text-decoration: none;
  margin-bottom: 5px;
`;

const UserDetails = styled.div`
  text-align: right;
`;

const Name = styled.div`
  font-size: 14px;
  line-height: 14px;
`;

const Role = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;

const IconWrapper = styled.div`
  ${hiddenOnPrint}
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    display: block;
    height: 24px;
    width: 24px;
  }
`;

export const UserInfo = () => {
  const { user } = useUserInfo();
  const role = useCurrentRole();
  return (
    <LinkArea to="/profile">
      <UserDetails>
        <Name>{user?.fullName || 'Unknown name'}</Name>
        <Role>{role?.displayName || 'Unknown role'}</Role>
      </UserDetails>
      <IconWrapper>
        <AccountCircle />
      </IconWrapper>
    </LinkArea>
  );
};
