import { FC } from 'react';
import styled from 'styled-components';

const Header = styled.p`
  margin-bottom: 1.6rem;
  font-size: 16px;
  font-weight: 600;
`;

interface DeleteOccurrenceModalContentProps {
  name: string;
}
export const DeleteOccurrenceModalContent: FC<DeleteOccurrenceModalContentProps> = ({ name }) => (
  <div>
    <Header>{`Are you sure you want to delete "${name}"?`}</Header>
    <p>This will delete ALL INFORMATION in other chapters linked to this occurrence.</p>
  </div>
);
