import { Guid } from '@/types';
import styled from 'styled-components';
import { Clock } from '@instech/icons';
import { TableRow, TableCell } from '@/components/shared/Table';
import { NavBox } from '@/components/shared/BoxButton/BoxButtons';
import { useModalContext } from '@/components/modal/ModalContext';
import { reportViewRoute } from '@/utils/routeTemplates';
import { ReportVersion } from '@/utils/archivedReportTypes';
import { getFriendlyDate } from '@/utils/date';
import { DownloadReportButton } from '@/components/shared/FileDownload/DownloadReport';
import { showPdfFallbackVersionModal } from '@/components/modal/PdfGeneratedWithFallbackParametersModal';
import { showVersionsModal } from './VersionsModal';
import { StatusDot } from './StatusDot';
import { YellowWarning } from './YellowWarning';

const HorizontalCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ReportTypeText = styled.div`
  font-weight: bold;
`;

const VersionText = styled.div`
  padding-left: 10px;
  text-decoration: underline;
  cursor: pointer;
`;

interface ViewReportButtonProps {
  caseId: Guid;
  archivedId: Guid;
}
const ViewReportButton = ({ caseId, archivedId }: ViewReportButtonProps) => {
  const reportRoute = reportViewRoute(caseId, archivedId);
  return (
    <NavBox to={reportRoute} height="40px" width="100%" padding="10px">View Report</NavBox>
  );
};

interface ViewVersionsActionProps {
  id: Guid;
}
const ViewVersionsAction = ({ id }: ViewVersionsActionProps) => {
  const { open } = useModalContext();
  const showVersions = () => open(showVersionsModal(id));

  return (
    <HorizontalCell>
      <Clock />
      <VersionText onClick={showVersions}>See all versions</VersionText>
    </HorizontalCell>
  );
};

interface StatusInfoEntryProps {
  status: string;
}
const StatusInfoEntry = ({ status }: StatusInfoEntryProps) => (
  <div>
    <StatusDot status={status} />
    {status}
  </div>
);

const ReportInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: ${props => props.onClick ? 'pointer' : 'default'}
`;

interface ReportInfoEntryProps {
  type: string;
  version: number;
  pdfGeneratedWithFallbackParameters: boolean;
}
const ReportInfoEntry = ({ type, version, pdfGeneratedWithFallbackParameters }: ReportInfoEntryProps) => {
  const reportTypeText = ` Version ${version}`;

  const { open } = useModalContext();
  const handleClick = pdfGeneratedWithFallbackParameters ? () => open(showPdfFallbackVersionModal) : undefined;

  return (
    <ReportInfoWrapper onClick={handleClick}>
      {pdfGeneratedWithFallbackParameters && <YellowWarning />}
      <div>
        <ReportTypeText>{type}</ReportTypeText>
        <div>{reportTypeText}</div>
      </div>
    </ReportInfoWrapper>
  );
};

const AlignedCell = styled(TableCell)`
  justify-content: center;
`;

export const TableEntry = ({
  caseId,
  reportType,
  versionId,
  archivedId,
  status,
  lastModified,
  pdfGenerated,
  pdfGeneratedWithFallbackParameters
}: ReportVersion) => (
  <TableRow even>
    <AlignedCell>
      <ReportInfoEntry type={reportType} version={versionId} pdfGeneratedWithFallbackParameters={pdfGeneratedWithFallbackParameters} />
    </AlignedCell>
    <AlignedCell>
      <ViewVersionsAction id={caseId} />
    </AlignedCell>
    <AlignedCell>
      <StatusInfoEntry status={status} />
    </AlignedCell>
    <AlignedCell>
      {getFriendlyDate(lastModified) || '-'}
    </AlignedCell>
    <AlignedCell>
      <DownloadReportButton caseId={caseId} archivedId={archivedId} pdfGenerated={pdfGenerated} inverted>Download</DownloadReportButton>
    </AlignedCell>
    <AlignedCell>
      <ViewReportButton caseId={caseId} archivedId={archivedId} />
    </AlignedCell>
  </TableRow>
);
