import { CaseMetadata } from '@/types';
import {
  createContext, useContext, ReactNode
} from 'react';

const MetadataContext = createContext({ metadata: {} as CaseMetadata });

  interface MetadataProviderProps {
    value: { metadata: CaseMetadata };
    children: ReactNode;
  }

export const MetadataProvider = ({ value, children }: MetadataProviderProps) => (
  <MetadataContext.Provider value={value}>
    {children}
  </MetadataContext.Provider>
);

export const useMetadataContext = () => useContext(MetadataContext);
