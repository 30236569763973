import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FileParagraph } from '@instech/icons';

const Button = styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    @media (min-width: ${props => props.theme.breakpoints.tablet}) {
      justify-content: space-between;
      border-bottom: 1px solid ${props => props.theme.border.white};
    }
    @media (max-width: ${props => props.theme.breakpoints.tabletMax}) {
      justify-content: flex-start;
    }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

export const ReportLink = ({ to }) => (
  <Button data-testid="chapter-expander-Button" to={to}>
    <span>View Report</span>
    <IconWrapper>
      <FileParagraph width="24px" height="24px" />
    </IconWrapper>
  </Button>
);
ReportLink.propTypes = {
  to: PropTypes.string.isRequired
};
