import { useRef, FunctionComponent } from 'react';
import styled from 'styled-components';
import { ModalHandler } from '@/components/modal/Modal';
import { ImmediateFeedback, DescriptionBase } from '@/types';
import { Form } from 'formik';
import {
  BoxButton, Divider, FormikWithPrompt as Formik
} from '@instech/components';
import { RichText } from './RichText';
import { initializeRichText } from './utility';
import { Pane } from '../../Pane';

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  padding: 0px 20px 20px;

`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  & > button + button {
    margin-left: 16px;
  }
`;

interface Props {
  data: ImmediateFeedback;
  onSave: (data: ImmediateFeedback) => void;
  onPublish: () => void;
  headerText: string;
  margin?: string;
}

export const RichTextFormSaveDraftAndPublish: FunctionComponent<Props> = ({ data, onSave, onPublish, headerText, margin }) => {
  const published = useRef(data.isPublished); // Using useState or setFieldValue will not work in this case.
  const setPublished = (val: boolean) => {
    published.current = val;
  };
  const initialValues = initializeRichText(data);

  const submit = (newData: DescriptionBase) => {
    const isPublished = published.current;
    if (isPublished) {
      onPublish();
    } else {
      onSave({ isPublished, ...newData });
    }
  };

  return (
    <Pane title={headerText} padding="0px" margin={margin}>
      <ModalHandler>
        <Formik initialValues={initialValues} onSubmit={values => submit(values)}>
          {({ values, setFieldValue, handleSubmit, dirty, resetForm }) => (
            <Form>
              <RichText
                useImages
                onRichTextChange={value => setFieldValue('text', value)}
                richText={values.text}
                onImageChange={value => setFieldValue('images', value)}
                imageState={values.images}
                padding="20px"
                spaceBetween="20px"
              />
              <Footer>
                <Divider />
                <ButtonWrapper>
                  <BoxButton
                    onClick={e => {
                      setPublished(false);
                      handleSubmit(e);
                      resetForm({ values });
                    }}
                    inverted
                    disabled={!dirty}
                  >
                    Save draft
                  </BoxButton>
                  <BoxButton
                    onClick={e => {
                      setPublished(true);
                      handleSubmit(e);
                    }}
                    disabled={dirty || !values.text}
                  >
                    Submit to Claims Handler
                  </BoxButton>
                </ButtonWrapper>
              </Footer>
            </Form>
          )}
        </Formik>
      </ModalHandler>
    </Pane>
  );
};
