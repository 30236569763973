import { FunctionComponent } from 'react';
import styled from 'styled-components';

const PreTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  color: ${props => props.theme.marineBlue};
  opacity: 0.6;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  letter-spacing: 0;
  color: ${props => props.theme.marineBlue};
  text-transform: uppercase;
`;

interface Props {
  title: string;
  preTitle?: string;
}
export const PageTitle: FunctionComponent<Props> = ({ title, preTitle }) => (
  <div>
    {preTitle && <PreTitle>{preTitle}</PreTitle>}
    <Title>{title}</Title>
  </div>
);
