import styled from 'styled-components';
import { WidgetReference } from '@/types';
import { widgetTypeToComponentMap } from './widgetMap';

const WidgetWrapper = styled.div`
  margin-top: 16px;
`;

const MissingWidget = ({ widgetType, title, widgetId, chapterId }: WidgetReference) => (
  // eslint-disable-next-line react/jsx-one-expression-per-line
  <div>No Type Found == {widgetType} | {title} | wid: {widgetId} | cid: {chapterId}</div>
);

export const Widget = (props: WidgetReference) => {
  const Component = widgetTypeToComponentMap[props.widgetType];
  if (Component == null) {
    return (
      <WidgetWrapper>
        <MissingWidget {...props} />
      </WidgetWrapper>
    );
  }
  return (
    <WidgetWrapper>
      <Component {...props} />
    </WidgetWrapper>
  );
};
