import {
  useState, FunctionComponent, useEffect
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { filterOutHiddenChapters } from '@/services/templateService';
import { ReportTemplate } from '@/types';
import { editRoute, dashboardRoute } from '@/utils/routeTemplates';
import { ViewChapter } from '@/utils/manifestTypes';
import { useReportContext } from '@/components/pages/report/shared/core/ReportContext';
import { useScrollUpdateContext } from '@/components/pages/report/shared/core/ScrollUpdater';
import { NavigationHeader } from './core/NavigationHeader';
import { ChapterEntriesReportPreview, ChapterEntriesReportView } from './core/Chapter/ChapterEntries';
import { NavWrapperInner, NavSections } from './core/Components';
import { ReportNavContainer, ReportNavWrapperOuter } from './core/ReportComponents';

interface ReportNavProps {
  viewMode?: boolean;
  onMobileOpen: (newState: boolean) => void;
  reportTemplate: ReportTemplate;
}
const ReportNav: FunctionComponent<ReportNavProps> = ({ viewMode, onMobileOpen, reportTemplate }) => {
  const { toc } = useReportContext() as { toc: ViewChapter[] };
  const [showContents, setShowContents] = useState(true);
  const { scrollToContent } = useScrollUpdateContext();
  const { id: caseId, version } = useParams<any>();
  const { hash } = useLocation();

  // To avoid conflict with ScrollUpdater, don't scroll if there is a hash in the url
  const shouldScroll = !hash;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (shouldScroll) {
      const id = setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }, 600);
      return () => {
        clearTimeout(id);
      };
    }
  }, [shouldScroll]);

  const onOpenMenu = () => {
    setShowContents(!showContents);
    onMobileOpen(showContents);
  };

  const onClickMenuItem = () => {
    setShowContents(true);
    onMobileOpen(false);
    scrollToContent();
  };

  // Decide what view to use for the nav entries
  const EntryComponent = viewMode ? ChapterEntriesReportView : ChapterEntriesReportPreview;

  return (
    <ReportNavContainer>
      <NavigationHeader
        title={viewMode ? 'Back to vessel dashboard' : 'Back'}
        to={viewMode ? dashboardRoute(caseId) : editRoute(caseId)}
        menuOpen={!showContents}
        onClick={onOpenMenu}
      />
      <ReportNavWrapperOuter data-testid="navigation-section-wrapper" visible={!showContents}>
        <NavWrapperInner>
          <NavSections>
            <EntryComponent entries={filterOutHiddenChapters(toc, viewMode || true, reportTemplate)} onClick={onClickMenuItem} version={version} />
          </NavSections>
        </NavWrapperInner>
      </ReportNavWrapperOuter>
    </ReportNavContainer>
  );
};

interface ViewProps {
  onMobileOpen: (newState: boolean) => void;
  reportTemplate: ReportTemplate;
}
export const ReportPreviewNav: FunctionComponent<ViewProps> = ({ onMobileOpen, reportTemplate }) => (
  <ReportNav onMobileOpen={onMobileOpen} reportTemplate={reportTemplate} />
);
export const ReportViewNav: FunctionComponent<ViewProps> = ({ onMobileOpen, reportTemplate }) => (
  <ReportNav viewMode onMobileOpen={onMobileOpen} reportTemplate={reportTemplate} />
);
