import {
  FunctionComponent, useEffect, useState
} from 'react';
import * as Yup from 'yup';
import { Guid } from '@/types';
import { uploadSignatureAsync } from '@/services/signatureServices';
import {
  Loader, FormRow, Dropzone
} from '@instech/components';
import { CloseButton } from '@/components/shared/FormButton/FormButton';
import {
  Formik, FormikProps, useField
} from 'formik';
import { imageRequired } from '@/utils/validationSchemas';

interface FormProps {
  signature: null | File;
}
interface FormFieldProps extends FormikProps<FormProps> {
  onCancel?: () => void;
  onError?: (hasError: boolean) => void;
}
const FormFields: FunctionComponent<FormFieldProps> = ({ isSubmitting, onCancel, onError, validateForm, submitForm }) => {
  const [, meta] = useField('signature');

  const handleSave = async () => {
    await validateForm();
    void submitForm();
  };

  useEffect(() => {
    if (meta.value && !isSubmitting) {
      void handleSave();
    }
    // Eslint wants handleSave, but that would cause a loop?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, meta.value, submitForm]);

  useEffect(() => {
    if (onError && meta.error) onError(!!meta.error);
  }, [meta.error, onError]);

  return (
    <div>
      <FormRow>
        {!isSubmitting && (
          <Dropzone name="signature" />
        )}
        {isSubmitting && <Loader size="medium" />}
      </FormRow>
      {onCancel && <CloseButton label="Cancel" onClick={onCancel} disabled={isSubmitting} />}
    </div>
  );
};

const validationSchema = Yup.object().shape({
  signature: imageRequired(),
});
interface Props {
  caseId: Guid;
  onSubmitted: (newSignatureId: Guid) => void;
  onCancel?: () => void;
  onError?: (hasError: boolean) => void;
}
export const UploadForm: FunctionComponent<Props> = ({ caseId, onSubmitted, onCancel, onError }) => {
  const [error, setError] = useState(undefined);

  const uploadSignature = async (file: File) => {
    try {
      const imageId = await uploadSignatureAsync(caseId, { image: file });
      return imageId;
    } catch (err: any) {
      setError(err);
    }
    return undefined;
  };
  if (error) {
    throw new Error(error);
  }

  return (
    <Formik
      initialValues={{ signature: null } as FormProps}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async values => {
        const result = await uploadSignature(values.signature as File);
        if (result) {
          onSubmitted(result);
        }
      }}
    >
      {formikProps => <FormFields {...formikProps} onCancel={onCancel} onError={onError} />}
    </Formik>
  );
};
