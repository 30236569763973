import { Case, UserInfo } from '@/types';
import { FC, useMemo } from 'react';
import { ButtonGroup } from '@instech/components';
import { NewBoxButton } from '@/components/shared/BoxButton/NewBoxButton';
import { useModalContext } from '@/components/modal/ModalContext';
import { UserInfoTable } from '@/components/shared/UserInfoTable';

interface Props {
  report: Case;
  allowedSurveyors: UserInfo[];
}
export const ReadonlySurveyorsModal: FC<Props> = ({ report, allowedSurveyors }) => {
  const { close } = useModalContext();

  const selectedSurveyors = useMemo(() => (
    allowedSurveyors?.filter(surveyor => report?.users?.surveyors.includes(surveyor.id))
  ), [report?.users, allowedSurveyors]);

  return (
    <div>
      <UserInfoTable
        users={selectedSurveyors}
        primaryId={report.users?.primarySurveyorId}
      />
      <ButtonGroup alignRight marginTop="24px">
        <NewBoxButton lowercase width="150px" onClick={close}>Close</NewBoxButton>
      </ButtonGroup>
    </div>
  );
};
