import { ModalFunction } from '@/components/modal/types';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useCaseStatus } from '@/services/caseStatusServices';
import { useImmediateFeedbackService } from '@/services/immediateFeedbackServices';
import { ImmediateFeedback } from '@/types';
import { Loader } from '@instech/components';
import { FC } from 'react';
import { RichTextFormSaveDraftAndPublish } from '../../shared/Form/RichTextForm/RichTextFormSaveDraftAndPublish';
import { ImmediateFeedbackView } from './ImmediateFeedbackView';

interface Props {
  readonly?: boolean;
}
export const ImmediateFeedbackTool: FC<Props> = ({ readonly }) => {
  const caseId = useCaseIdFromUrl();
  const { data: status } = useCaseStatus(true);
  const [immediateFeedback, updateImmediateFeedback, publishImmediateFeedback] = useImmediateFeedbackService(caseId);

  if (!immediateFeedback) {
    return <Loader />;
  }

  if (immediateFeedback.isPublished || readonly) {
    return (
      <ImmediateFeedbackView data={immediateFeedback} showGdprMessage={status?.isClosed} />
    );
  }

  const onSave = async (updatedFeedback: ImmediateFeedback) => {
    await updateImmediateFeedback(updatedFeedback);
  };

  const onPublish = async () => {
    await publishImmediateFeedback();
  };

  return (
    <RichTextFormSaveDraftAndPublish
      data={immediateFeedback}
      headerText="Add your inital information about the damage"
      onSave={onSave}
      onPublish={onPublish}
    />
  );
};

export const openImmediateFeedbackTool: ModalFunction<Props> = ({ readonly }) => ({
  component: ImmediateFeedbackTool,
  options: {
    title: 'Immediate Feedback',
    footer: true
  },
  args: { readonly }
});
