import { ReadOnlyField } from '@/components/shared/ReadOnlyField';
import {
  Table, TableCell,
  TableRow,
  TruncatingTableCell
} from '@/components/shared/Table';
import {
  FileCategory,
  FileModel,
  FileStatusOption,
  FileStatusOptionName,
  useFileLink
} from '@/services/fileServices';
import { Dictionary } from '@/types';
import { getFriendlyDate } from '@/utils/date';
import { TruncatingText } from '@instech/components';
import { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import { FilesHiddenGdprMessage } from '../../../shared/FilesHiddenGdprMessage';
import { NoContent } from '../../../shared/NoContent';
import {
  FileMetaInformation, FileMetaInformationRow,
  InformationHeader
} from '../../core/Components';
import { FileDownload } from '../../core/FileDownloadOrUpload';
import { getPiiText, transformFiles } from '../../utils/availableInfoUtils';

const FieldContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
`;

interface AvailableInfoRowProps {
  item: FileModel;
  i: number;
  fileStatusDict: Dictionary<FileStatusOptionName>;
}
const AvailableInfoRow: FunctionComponent<AvailableInfoRowProps> = ({ item, i, fileStatusDict }) => {
  const { data } = useFileLink(item.id, item.isPlaceholderForRequestedFile);
  const even = i % 2 === 0;
  const readonlyVariant = even ? 'default' : 'table';
  return (
    <TableRow key={item.id} even={even}>
      <TruncatingTableCell>
        <ReadOnlyField title="Document name" variant={readonlyVariant}>
          <FieldContent>
            <TruncatingText text={item.title} padding="0px 10px 0px 0px" />
            {!item.isPlaceholderForRequestedFile && <FileDownload fileName={item.fileName} url={data?.url ?? ''} />}
          </FieldContent>
        </ReadOnlyField>
        <FileMetaInformationRow>
          <FileMetaInformation>{getPiiText(item.personallyIdentifiableInformation)}</FileMetaInformation>
          <FileMetaInformation>{`${item.uploadedBy} - ${getFriendlyDate(item.uploadedAt)}`}</FileMetaInformation>
        </FileMetaInformationRow>
      </TruncatingTableCell>
      <TableCell>
        <ReadOnlyField title="Status" variant={readonlyVariant}>
          <FieldContent>
            {fileStatusDict[item.status]}
          </FieldContent>
        </ReadOnlyField>
      </TableCell>
    </TableRow>
  );
};

interface Props {
  items: FileModel[];
  fileStatusList: FileStatusOption[];
  categories: FileCategory[];
  showGdprMessage?: boolean;
}
export const AvailableInfoReadOnlyPage: FunctionComponent<Props> = ({ items = [], categories, fileStatusList, showGdprMessage }) => {
  const categoryData = transformFiles(items, categories);

  const fileStatusDict = useMemo(
    () =>
      fileStatusList.reduce((a, x) => ({ ...a, [x.status]: x.name }), {} as Dictionary<FileStatusOptionName>),
    [fileStatusList]
  );

  return (
    <>
      {items.length === 0 && <NoContent text="No documents uploaded" />}
      {items.length > 0 && showGdprMessage && <FilesHiddenGdprMessage />}
      {categoryData.sortedCategories.map(category => (
        <div key={category}>
          <InformationHeader>{category}</InformationHeader>
          <Table layout="1fr 200px">
            {categoryData.data[category].map((item, i) => (
              <AvailableInfoRow
                key={item.id}
                item={item}
                i={i}
                fileStatusDict={fileStatusDict}
              />
            ))}
          </Table>
        </div>
      ))}
    </>
  );
};
