import { widgetComponentPropType } from '@/prop-types/custom-prop-types';
import { RichTextForm } from '@/components/shared/Form/RichTextForm';
import { FullwidthWidgetWrapper } from '@/components/pages/edit/widgets/FullwidthWidgetWrapper';
import { useDescription, updateDescriptionAsync } from '@/services/descriptionService';
import { useParams } from 'react-router';
import { getOccurrencePrefixTitle } from '@/utils/text';

export const Description = ({ widgetId, title }) => {
  const { id: caseId, chapterId } = useParams();
  const { data, mutate } = useDescription(caseId, chapterId, widgetId, { suspense: true });
  const newTitle = getOccurrencePrefixTitle(title, data.occurrenceId);

  return (
    <FullwidthWidgetWrapper>
      <RichTextForm
        withReportLink
        formId={widgetId}
        data={data}
        headerText={newTitle}
        onSubmit={async widget => {
          const result = await updateDescriptionAsync(caseId, chapterId, widgetId, widget);
          await mutate(result);
        }} />
    </FullwidthWidgetWrapper>
  );
};
Description.propTypes = widgetComponentPropType;
