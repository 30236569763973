import { Guid } from '@/types';
import deepEqual from 'deep-equal';
import { OccurrenceSchema } from './occurrenceFormSchema';

export const defaultOccurrence = (id: Guid): OccurrenceSchema => ({
  id,
  name: '',
  dateOfLoss: '',
  location: '',
  charter: '',
  timeOfLoss: null,
  timezoneId: null,
  claimEventName: '',
  fleetName: '',
  className: '',
  conditions: '',
  status: {
    label: 'Estimate',
    value: 'Estimate'
  },
  interests: [
    {
      claimId: null,
      claimReference: '',
      interest: '',
      interestGroup: null,
      claimLeaderName: '',
      claimEventName: '',
      ourShare: null,
      deductible: '',
      claimInterest: 'HullAndMachinery',
      activeClaimWall: false,
    }
  ]
});

export const deepEqualsDefaultModel = (occurrence: OccurrenceSchema) => {
  const { id, ...defaultModel } = defaultOccurrence('');
  const { id: _id2, ...occurrenceWithoutId } = occurrence;
  return deepEqual(defaultModel, occurrenceWithoutId);
};
