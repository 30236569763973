import moment from 'moment';
import { Duration } from '@/types';

// Date format used for input formatting
export const friendlyDateFormat = 'D MMM YYYY';

// Date format and function used to format readonly text, such as
// in the report. Should not be used to format inside input fields.
export const friendlyDateDotted = 'D MMM. YYYY';
const friendlyMonthDotted = 'D MMM.';
export const getFriendlyDate = (date?: string | null, format = friendlyDateDotted) => {
  if (!date) {
    return '-';
  }
  const momentDate = moment.utc(date, moment.ISO_8601);
  // May should not have a dot after - and moment counts months from 0
  if (momentDate.month() === 4) {
    if (format === friendlyDateDotted) return momentDate.format('D MMM YYYY');
    if (format === friendlyMonthDotted) return momentDate.format('D MMM');
  }
  return momentDate.format(format);
};

// Date output for the Vessel Movement timeline
export const getTimelineDate = (date: string) => getFriendlyDate(date, friendlyMonthDotted);

// Convert moment to string: "YYYY-MM-DDTHH:mm:ss.offset"
export const getDateISOString = (date: moment.Moment) => date === null ? null : date.utc().startOf('date').toISOString();

export const parseISODateString = (dateString: string) => moment.utc(dateString, moment.ISO_8601);

export const normalizeDateString = (date: string) => moment.utc(date).toISOString();

export const normalizeDuration = (duration: Duration) => ({
  startDate: duration.startDate ? normalizeDateString(duration.startDate) : duration.startDate,
  endDate: duration.endDate ? normalizeDateString(duration.endDate) : duration.endDate
});

export const getNumberOfDays = (dateFrom?: string | null, dateTo?: string | null) => {
  if (dateTo && dateFrom) {
    return moment.utc(dateTo).diff(moment.utc(dateFrom), 'days') + 1;
  }
  return null;
};

export const getCurrentTimezoneOrDefault = () => Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'UTC';

export function constructDateTime(date?: string | null, timeOfDay?: string | null) {
  if (!date) return undefined;
  // Force Moment to expect ISO-8601 to keep it from trying browser Date(), as Chromium v8
  // will otherwise treat certain unsupported date formats as though they are valid.
  const datePart = moment.utc(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
  let suffix = '';
  if (timeOfDay) {
    const containsSeconds = timeOfDay.split(':').length > 2;
    suffix = containsSeconds ? '.00000' : ':00.00000';
  }
  const timePart = timeOfDay ? `${timeOfDay}${suffix}` : '00:00:00.00000';
  return `${datePart}T${timePart}`;
}

/**
 * Formats a timeOfDay string to the format 'hh:mm'
 */
export function formatTimeOfDay(timeOfDay: string) {
  const timeComponents = timeOfDay.split(':');

  if (timeComponents.length < 2) {
    throw new Error('Invalid input. Must be of format hh:mm:ss');
  }
  const hours = timeComponents[0];
  const minutes = timeComponents[1];

  return `${hours}:${minutes}`;
}
