import { useState } from 'react';
import { TimelineView } from '@/components/shared/TimelineView';
import { extractLocations } from '@/components/shared/TimelineView/utils/timelineUtils';
import { useReadonlyTimeline } from '@/services/timelineServices';
import { TimelineProvider } from '@/components/pages/edit/core/TimelineContext';
import { Pane, PreviewReportPane } from '@/components/shared/Pane';
import { TimelineItem } from '@/types';
import { TimelineHeader } from '@/components/shared/TimelineView/components/TimelineHeader';

interface PreviewProps {
  title: string;
  caseId: string;
  chapterId: string;
}

export const VesselMovementsReportPreview = ({ title, caseId, chapterId }: PreviewProps) => {
  const [displayInUtcTime, setDisplayInUtcTime] = useState(false);

  const timelineEvents = useReadonlyTimeline(caseId);
  const locations = extractLocations(timelineEvents);

  return (
    <PreviewReportPane title={title} caseId={caseId} chapterId={chapterId}>
      <TimelineProvider value={{ locations }}>
        <TimelineHeader displayInUtcTime={displayInUtcTime} setDisplayInUtcTime={setDisplayInUtcTime} />
        <TimelineView data={timelineEvents} displayInUtcTime={displayInUtcTime} />
      </TimelineProvider>
    </PreviewReportPane>
  );
};

interface ViewProps {
  data: {
    items: TimelineItem[];
  };
}

export const VesselMovementsReportView = ({ data }: ViewProps) => {
  const [displayInUtcTime, setDisplayInUtcTime] = useState(false);

  const timelineEvents = data.items;
  const locations = extractLocations(timelineEvents);

  return (
    <Pane title="Vessel Movement & Events">
      <TimelineProvider value={{ locations }}>
        <TimelineHeader displayInUtcTime={displayInUtcTime} setDisplayInUtcTime={setDisplayInUtcTime} />
        <TimelineView data={timelineEvents} displayInUtcTime={displayInUtcTime} />
      </TimelineProvider>
    </Pane>
  );
};
