import { Pane } from '@/components/shared/Pane';
import { useVersionedReport } from '@/services/versionsService';
import { useParams } from 'react-router';
import { Loader, TruncatingText } from '@instech/components';
import { getDefaultText } from '@/utils/surveyLocation';
import {
  CommentArea, InformationHeader, IntroductionAttendanceText
} from './utility';

interface ViewProps {
  title: string;
  data: {
    comment: string;
    infoText: string;
    attendance?: boolean;
    remote?: boolean;
    withoutAttendance?: boolean;
  };
}
export const IntroductionReportView = ({ data, title }: ViewProps) => {
  const { id: caseId, version } = useParams() as { id: string; version: string; };

  const { data: versionData } = useVersionedReport(caseId, version);

  if (!versionData) return <Loader />;

  // vesselName was recently added to CaseMetadata so this is to ensure backwards compatability
  const vesselName = versionData.metadata.vesselName ?? 'above-mentioned vessel';

  const text = data.infoText
    || getDefaultText(versionData.metadata.interest, vesselName); // Fallback to default for older cases

  return (
    <Pane title={title || 'missing'}>
      <InformationHeader>
        {text}
      </InformationHeader>
      <IntroductionAttendanceText
        attendance={data.attendance}
        remote={data.remote}
        withoutAttendance={data.withoutAttendance} />
      <CommentArea><TruncatingText text={data.comment} wrap /></CommentArea>
    </Pane>
  );
};
