import styled, { css } from 'styled-components';
import { getFriendlyDate } from '@/utils/date';
import PropTypes from 'prop-types';
import { TruncatingText } from '@instech/components';

const OccurrenceRow = styled.div`
  display: flex;
  padding-bottom: 20px;
  :not(:first-child){
    border-top: 1px solid ${props => props.theme.background.primary};
    padding-top: 20px;
  }
`;

const HeaderBox = styled.div`
  display: block;
`;

const ContentBox = styled.div`
  display: block;
  margin-left: 60px;
  // This is needed to make the text truncation work in the children
  overflow: hidden;
`;

const HeaderText = styled.div`
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 3px;
  display: block;
  ${props => props.large && css`
    font-size: 16px;
  `}
  ${props => props.upper && css`
    text-transform: uppercase;
  `}
`;

const ContentText = styled.div`
  display: block;
  font-size: 16px;
  padding-bottom: 10px;
  ${props => props.small && css`
    font-size: 13px;
  `}
`;

const DataField = ({ name, value }) => (
  <>
    <HeaderText upper>{name}</HeaderText>
    <ContentText><TruncatingText text={value} /></ContentText>
  </>
);
DataField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const CharterField = ({ charter = '' }) => {
  if (charter === '') return null;
  return (
    <DataField name="Charter" value={charter} />
  );
};

CharterField.propTypes = {
  charter: PropTypes.string
};

export const Occurrence = ({ date = '-', name, location, charter = '' }) => (
  <OccurrenceRow>
    <HeaderBox>
      <HeaderText large>Occurrence</HeaderText>
      <ContentText small>{getFriendlyDate(date)}</ContentText>
    </HeaderBox>
    <ContentBox>
      <DataField name="Name" value={name} />
      <DataField name="Location" value={location} />
      <CharterField charter={charter} />
    </ContentBox>
  </OccurrenceRow>
);

Occurrence.propTypes = {
  date: PropTypes.string,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  charter: PropTypes.string
};

export const Occurrences = ({ data }) => (
  <>
    {data.map(({ id, name, dateOfLoss, location, charter }) => (
      <Occurrence
        key={id}
        date={dateOfLoss}
        name={name}
        location={location}
        charter={charter}
      />
    ))}
  </>
);

Occurrences.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    dateOfLoss: PropTypes.string,
    location: PropTypes.string.isRequired,
    charter: PropTypes.string
  }))
};
