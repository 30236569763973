import { useModalContext } from '@/components/modal/ModalContext';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useClaimsOccurrenceOverview } from '@/services/claimsOccurrenceServices';
import { createOccurrenceAsync } from '@/services/occurrenceServices';
import {
  ClaimOccurrenceOverview, Guid, Occurrence
} from '@/types';
import { Loader } from '@instech/components';
import { Formik } from 'formik';
import { useState } from 'react';
import { OccurrenceSchema, OccurrenceValidator } from '../OccurrenceValidator';
import {
  NoClaimsMessage, ClaimTableModal, ErrorLine, OccurrenceCharterDropdown, OccurrenceNameInput
} from '../core';
import { ModalActionButtonGroup, ModalFooter } from '../core/ModalFooterComponents';

interface ModalProps {
  caseMetaClaimId?: Guid | null;
  occurrenceClaimId: string | null;
  onOccurrencesUpdate: (update: (occurrences: Occurrence[]) => Occurrence[]) => Promise<void>;
  userCanEdit: boolean;
}
export const AddNewOccurrence = ({ caseMetaClaimId, occurrenceClaimId, onOccurrencesUpdate, userCanEdit }: ModalProps) => {
  const caseId = useCaseIdFromUrl();
  const claimIdToFetch = occurrenceClaimId || caseMetaClaimId;
  const { close } = useModalContext();
  const { data: claimsData, isValidating } = useClaimsOccurrenceOverview(claimIdToFetch);
  const claims: ClaimOccurrenceOverview[] = claimsData?.map(claim => ({ ...claim, disabled: false })) ?? [];
  const [hasError, setError] = useState(false);

  if (isValidating) {
    return <Loader />;
  }

  const handleSubmit = async (formData: OccurrenceSchema) => {
    const { occurrenceName: name, selectedClaims, charter } = formData;
    setError(false);
    try {
      const selectedClaimsInFetchedOrder = claimsData?.map(claim => claim.id).filter(id => selectedClaims.includes(id)) ?? selectedClaims;
      const occurrence = await createOccurrenceAsync(caseId, { name, charter: charter?.value ?? '', claimId: selectedClaimsInFetchedOrder });
      await onOccurrencesUpdate(occurrences => [...occurrences, occurrence]);
      close();
    } catch (e) {
      setError(true);
    }
  };

  if (claims.length === 0) {
    return <NoClaimsMessage closeModal={close} />;
  }

  const initialValues: OccurrenceSchema = { occurrenceName: '', charter: null, selectedClaims: [], caseId };
  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={OccurrenceValidator}>
      {() => (
        <div>
          <OccurrenceNameInput />
          <OccurrenceCharterDropdown />
          <ClaimTableModal claims={claims} label="Select interest type(s) to the occurrence." />
          <ModalFooter>
            {hasError && <ErrorLine>Could not add an occurrence. Please try again later.</ErrorLine>}
            <ModalActionButtonGroup submitButtonText="SAVE" />
          </ModalFooter>
        </div>
      )}
    </Formik>
  );
};

export const showAddNewOccurrenceModal = ({ caseMetaClaimId, occurrenceClaimId, onOccurrencesUpdate, userCanEdit }: ModalProps) => ({
  component: AddNewOccurrence,
  options: {
    title: 'Add Occurrence and Interest(s)',
    size: 'medium',
    footer: false,
  },
  args: {
    caseMetaClaimId,
    occurrenceClaimId,
    onOccurrencesUpdate,
    userCanEdit
  }
});
