import { FunctionComponent } from 'react';
import { ContentArea } from '../../core/Components';
import { PageTitle } from '../../core/PageTitle';
import { PersonalInformationForm } from './PersonalInformationForm';

export const ProfileSettings: FunctionComponent = () => (
  <ContentArea>
    <PageTitle title="Profile Settings" />
    <PersonalInformationForm />
  </ContentArea>
);
