import { getLabelValuePairs } from '@/components/shared/Form/fields/Dropdown/OccurrenceSelect';
import { useOccurrenceOptionsService } from '@/services/occurrenceServices';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { locationModel } from './utils';

type FormType = { locations: ReturnType<typeof locationModel>[] };
type Location = FormType['locations'][number];

const useFilterExistingOccurrenceOptions = () => {
  const occurrenceOptions = useOccurrenceOptionsService();

  const { values, resetForm } = useFormikContext<FormType>();

  useEffect(() => {
    if (!occurrenceOptions) return;

    const optionValues = getLabelValuePairs(occurrenceOptions).map(option => option.value);
    const filteredLocations = values.locations.map<Location>(location => ({
      ...location,
      occurrences: location.occurrences.filter(occurrence => optionValues.includes(occurrence.value))
    }));

    if (filteredLocations.some((location, index) => location.occurrences.length !== values.locations[index].occurrences.length)) {
      resetForm({ values: { locations: filteredLocations } });
    }
  }, [values, resetForm, occurrenceOptions]);
};

export default useFilterExistingOccurrenceOptions;
