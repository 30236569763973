import { UserInfo } from '@/types';
import { TableCell, TableRow } from '@instech/components';
import { FC } from 'react';
import styled from 'styled-components';
import { PrimaryUserCell } from './PrimaryUserCell';

const StyledRow = styled(TableRow)`
  & > ${TableCell} {
    border-bottom: 1px solid ${({ theme }) => theme.lightGray};
    background-color: ${({ theme, even }) => even ? theme.marineBlue05 : theme.white};
  }
`;

const StyledCell = styled(TableCell)`
  color: ${({ theme }) => theme.marineBlue};
  font-size: 14px;
  padding: 12px 8px;
`;

const NoUsersCell = styled(StyledCell)`
  font-style: italic;
  text-align: center;
`;

interface Props {
  users: UserInfo[];
  primaryId?: string;
}
export const UserTableRows: FC<Props> = ({ users, primaryId }) => (
  <>
    {users.map((user, index) => (
      <StyledRow key={user.id} even={index % 2 === 0}>
        <StyledCell>{user.displayName}</StyledCell>
        <StyledCell>{user.company}</StyledCell>
        <PrimaryUserCell isPrimary={user.id === primaryId} />
      </StyledRow>
    ))}
  </>
);

export const NoUsersRow: FC = () => (
  <StyledRow>
    <NoUsersCell span="3">
      No users have been selected
    </NoUsersCell>
  </StyledRow>
);
