import { FunctionComponent } from 'react';
import { SummaryOfCost } from '@/types';
import { ExpenseCard } from '../../components/CostAndTimeSummary/ContentPane';
import { EditPane } from '../../core/EditPane';

interface TotalAmountProps {
  data: SummaryOfCost[]
}
const getCurrencySums = (data: SummaryOfCost[]) => {
  const currencies = [...new Set(data.map(item => item.currency))];

  return currencies.map(currency => {
    const currentData = data.filter(item => item.currency === currency);
    const sum = currentData.reduce((accumulator, item) => {
      if (item.sum) {
        return accumulator + item.sum;
      }
      return accumulator;
    }, 0);

    return { currency, sum };
  });
};

export const TotalAmount: FunctionComponent<TotalAmountProps> = ({ data }) => {
  const currencySums = getCurrencySums(data);

  return (
    <EditPane title="Total Amount of Owners’ General Account">
      {currencySums.map(each => (
        <ExpenseCard
          key={each.currency}
          title="Grand total"
          sum={each.sum}
          currency={each.currency}
          status={null}
        />
      ))}
    </EditPane>
  );
};
