import {
  Table, TableCell, TableHeader, TableRow
} from '@/components/shared/Table';
import { TruncatingText } from '@instech/components';
import { ArchivedExpenseGroup, ArchivedExpense } from '@/types';
import { formatNumber } from '@/utils/number';
import styled from 'styled-components';

const Comment = styled.div`
  margin-top: 20px;
`;

const ExpenceItem = ({ currency, expense }: { currency: string; expense: ArchivedExpense }) => {
  if (!expense.includeInReport) return null;
  return (
    <div>
      <Table layout="2fr 1fr 1fr">
        <TableHeader>Expense</TableHeader>
        <TableHeader>{`Cost - ${currency}`}</TableHeader>
        <TableHeader>Status</TableHeader>
        <TableRow even>
          <TableCell>{expense.name}</TableCell>
          <TableCell>{formatNumber(expense.cost)}</TableCell>
          <TableCell>{expense.status}</TableCell>
        </TableRow>
      </Table>
      {expense.comment && (
        <Comment><TruncatingText text={expense.comment} wrap /></Comment>
      )}
    </div>
  );
};

const Wrapper = styled.div`
  & div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ExpensesView = ({ expenses }: { expenses: ArchivedExpenseGroup; }) => (
  <Wrapper>
    {expenses.items.map(expense => <ExpenceItem key={expense.id} currency={expenses.currency} expense={expense} />)}
  </Wrapper>
);
