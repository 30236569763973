import useSWR from 'swr';
import { Case } from '@/types';

export const useCase = (caseId: string, suspense: boolean = false) => {
  const swrRoute = `/case/${caseId}`;
  return useSWR<Case>(swrRoute, { suspense });
};

// For now, this just exports a shortlist of mock claims for testing
// while the actual API endpoint is being set up.
// TODO: Export actual data here.
export const useClaims = (caseId: string, suspense: boolean = false) => ({
  data: [
    {
      id: 'mock-claimsid-1',
      dateOfLoss: '2021-04-14',
      interestName: 'LOH 14/90/90',
      claimReference: '170154/2020'
    },
    {
      id: 'mock-claimsid-2',
      dateOfLoss: '2021-04-14',
      interestName: 'LOH 14/90/90',
      claimReference: '169154/2020'
    },
    {
      id: 'mock-claimsid-3',
      dateOfLoss: '2021-04-14',
      interestName: 'H&M',
      claimReference: '156154/2020'
    },
    {
      id: 'mock-claimsid-4',
      dateOfLoss: '2021-04-14',
      interestName: 'LOH 14/90/90',
      claimReference: '140154/2020'
    },
    {
      id: 'mock-claimsid-5',
      dateOfLoss: '2021-04-14',
      interestName: 'LOH 14/90/90',
      claimReference: '163672/2020'
    },
    {
      id: 'mock-claimsid-6',
      dateOfLoss: '2021-04-14',
      interestName: 'H&M',
      claimReference: '151144/2020'
    },
    {
      id: 'mock-claimsid-7',
      dateOfLoss: '2021-04-14',
      interestName: 'H&M',
      claimReference: '186154/2020'
    },
    {
      id: 'mock-claimsid-8',
      dateOfLoss: '2021-04-14',
      interestName: 'LOH 14/90/90',
      claimReference: '172524/2020'
    },
    {
      id: 'mock-claimsid-9',
      dateOfLoss: '2021-04-14',
      interestName: 'LOH 14/90/90',
      claimReference: '134625/2020'
    },
    {
      id: 'mock-claimsid-10',
      dateOfLoss: '2021-04-14',
      interestName: 'H&M',
      claimReference: '116154/2020'
    }
  ]
});
