import { useAccount } from '@/components/authentication/AuthenticationProvider';
import { PortalSelector } from 'insify-remote-component-loader';

export const PortalSelectorLoader = () => {
  const { user } = useAccount();

  return (
    <PortalSelector
      account={user}
      portal={{ name: 'Insify Survey', displayName: 'Survey' }}
    />
  );
};
