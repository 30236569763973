import useSWR from 'swr';
import { SurveyLocation } from '@/types';
import client from './client';

export interface AttendingRepresentative {
  id: string;
  name: string;
  function: string;
  company: string;
  representing?: string;
  includeInReport: boolean;
  surveyLocationId: string;
}

export interface AttendingRepresentativeGroup {
  surveyLocationId: string;
  surveyLocation: SurveyLocation;
  items: AttendingRepresentative[];
}

const surveyLocationRepresentativesUrl = (caseId: string, surveyLocationId: string) =>
  `/case/${caseId}/attendingrepresentatives/surveylocation/${surveyLocationId}`;

const attendingRepresentativesReadonlyUrl = (caseId: string) =>
  `/case/${caseId}/attendingrepresentatives?includedOnly=true`;

export const updateRepresentativesAsync = async (
  data: AttendingRepresentative[],
  caseId: string,
  surveyLocationId: string
) => {
  const url = surveyLocationRepresentativesUrl(caseId, surveyLocationId);

  const result = await client.put<AttendingRepresentativeGroup>(url, data);

  return result.data;
};

export function useAttendingRepresentativeGroups(caseId: string, swrOptions?: any) {
  const requestUrl = attendingRepresentativesReadonlyUrl(caseId);
  return useSWR<AttendingRepresentativeGroup[]>(requestUrl, swrOptions);
}

export function useAttendingRepresentatives(caseId: string, surveyLocationId: string) {
  const requestUrl = surveyLocationRepresentativesUrl(caseId, surveyLocationId);
  return useSWR<AttendingRepresentative[]>(requestUrl);
}
