import { Guid } from '@/types';
import { getWithRetry } from '@/services/client';
import { AxiosResponse } from 'axios';

interface ArchivedPdf {
  id: Guid;
  archivedVersionId: Guid;
  caseId: Guid;
  url: string;
}

const fetchConfig = {
  retryCount: 10,
  errorRetryInterval: 800,
  retryCondition: (response?: AxiosResponse<ArchivedPdf>) =>
    response?.status === 404 ||
    response?.status === 204 // TODO: Endpoint returns 204 (No Content) sometimes. Not sure why...
};

export const downloadReportPdf = async (caseId: Guid, archivedId: Guid) => {
  const apiRoute = `case/${caseId}/reportpdf/${archivedId}`;
  return getWithRetry<ArchivedPdf>(apiRoute, fetchConfig);
};
