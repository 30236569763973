import { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { FullwidthWidgetWrapper } from '@/components/pages/edit/widgets/FullwidthWidgetWrapper';
import { useExpensesService } from '@/services/expensesServices';
import { getOccurrencePrefixTitle } from '@/utils/text';
import { ExpenseList } from '../../components/ExpenseList';

interface OccurrenceExpensesProps {
  widgetId: string;
  title: string;
}

export const OccurrenceExpenses: FunctionComponent<OccurrenceExpensesProps> = ({ widgetId, title }) => {
  const [data, update] = useExpensesService('OccurrenceExpenses', widgetId, { suspense: true });
  const occurrenceTitle = getOccurrencePrefixTitle(title, true);

  return (
    <FullwidthWidgetWrapper>
      <ExpenseList formId={widgetId} data={data} title={occurrenceTitle} onSubmit={update} />
    </FullwidthWidgetWrapper>
  );
};

OccurrenceExpenses.propTypes = {
  widgetId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
