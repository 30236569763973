import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Guid } from '@/types';
import { useImageList } from '@/services/imageServices';
import { ImageView } from '@/components/shared/Image';

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 207px;
  height: 207px;
  border-radius: 50%;
  background-color: ${props => props.theme.flatWhite};
  overflow: hidden;
  flex-shrink: 0;
`;

interface Props {
  caseId: Guid;
}
export const FrontImage: FunctionComponent<Props> = ({ caseId }) => {
  const { images } = useImageList(caseId, ['Frontpage']);

  return (
    <Circle>
      {images && images[0] && (
        <ImageView imageId={images[0]} crop="medium" noBorder />
      )}
    </Circle>
  );
};
