import { FunctionComponent } from 'react';
import {
  Loader, Pane, FormField, TextField, FormikWithPrompt as Formik
} from '@instech/components';
import { UserSingle } from '@instech/icons';
import { useProfileService } from '@/services/profileService';
import { Form } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { stringMaxLength, stringRequiredMaxLength } from '@/utils/validationSchemas';
import { SaveButton } from '@/components/shared/FormButton/FormButton';
import { trimString } from '@/utils/text';

const StyledForm = styled(Form)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const FormFields = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const personalInformationSchema = Yup.object().shape({
  displayName: stringRequiredMaxLength(),
  title: stringMaxLength().nullable(),
  phoneNumber: stringMaxLength().nullable()
});

export const PersonalInformationForm: FunctionComponent = () => {
  const { data: userInfo, updateProfile } = useProfileService();
  if (!userInfo) return <Loader />;

  const paneTitle = `Personal information - ${userInfo.email}`;

  return (
    <Pane title={paneTitle} icon={<UserSingle />}>
      <Formik
        initialValues={userInfo}
        validationSchema={personalInformationSchema}
        onSubmit={async values => {
          const { displayName, title, phoneNumber } = values;
          const titleValue = title ? trimString(title) : null;
          const phoneNumberValue = phoneNumber ? trimString(phoneNumber) : null;

          await updateProfile({
            ...values,
            displayName: trimString(displayName),
            title: titleValue,
            phoneNumber: phoneNumberValue
          });
        }}
        enableReinitialize
      >
        {({ dirty, handleSubmit, isSubmitting }) => (
          <>
            <StyledForm>
              <FormFields>
                <FormField>
                  <TextField
                    name="displayName"
                    placeholder="Full name"
                    label="Full name"
                  />
                </FormField>
                <FormField>
                  <TextField
                    name="title"
                    placeholder="Title"
                    label="Title"
                  />
                </FormField>
                <FormField>
                  <TextField
                    name="phoneNumber"
                    placeholder="Phone number"
                    label="Phone number"
                  />
                </FormField>
              </FormFields>
            </StyledForm>
            <ButtonWrapper>
              <SaveButton
                label="UPDATE"
                title="Update"
                noIcon
                onClick={handleSubmit}
                disabled={!dirty}
                isSubmitting={isSubmitting}
              />
            </ButtonWrapper>
          </>
        )}
      </Formik>
    </Pane>
  );
};
