import { FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  AddWhite, Trash, FloppyDisk
} from '@instech/icons';
import { BoxButton, IconButton } from '@instech/components';

interface ButtonClickProps {
  onClick: (newValue: any) => void;
}

const Button = styled.div`
  padding: 6px;
`;

export const AddMoreButton: FunctionComponent<ButtonClickProps> = ({ onClick }) => (
  <BoxButton height="50px" startIcon={<AddWhite />} onClick={onClick}>Add more</BoxButton>
);

export const RemoveButton: FunctionComponent<ButtonClickProps> = ({ onClick }) => (
  <IconButton smallIcon icon={<Trash />} onClick={onClick} />
);

export const SaveButton: FunctionComponent<ButtonClickProps> = ({ onClick }) => (
  <Button>
    <BoxButton height="50px" width="120px" startIcon={<FloppyDisk />} onClick={onClick}>
      Save
    </BoxButton>
  </Button>
);
