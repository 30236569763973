import { ApplicationRole, RoleApiRole } from '@/types';
import {
  createContext,
  useContext,
  FunctionComponent,
  useState,
  useMemo,
  PropsWithChildren,
  useEffect
} from 'react';
import { getJsonAsync } from '@/services/client';
import { useAccount } from './AuthenticationProvider';

interface RoleContextData
{
  currentRole: ApplicationRole | null;
  setCurrentRole: (props: ApplicationRole) => void;
}
const CurrentRoleContext = createContext<RoleContextData| null>(null);

const fetchRole = async (setCurrentRole: (arg: ApplicationRole) => void) => {
  const roleUrl = '../core/roles/user/Insify%20Survey';
  const result = await getJsonAsync<RoleApiRole>(roleUrl);

  if (result.role) {
    const roleApiRole = result as RoleApiRole;
    setCurrentRole({ name: roleApiRole.role, displayName: roleApiRole.displayName ?? roleApiRole.role } as ApplicationRole);
  }

  return result;
};

interface CurrentRoleProviderProps {
  applicationRole?: ApplicationRole | null;
}
export const CurrentRoleProvider: FunctionComponent<PropsWithChildren<CurrentRoleProviderProps>> = ({ children, applicationRole = null }) => {
  const [currentRole, setCurrentRole] = useState<ApplicationRole | null>(applicationRole);
  const { isAuthenticated } = useAccount();

  const value = useMemo(() => ({
    currentRole, setCurrentRole
  }), [currentRole]);

  const setCurrentRoleValues = (role: ApplicationRole) => {
    setCurrentRole(role);
  };

  useEffect(() => {
    if (!currentRole && isAuthenticated) {
      void fetchRole(setCurrentRoleValues)
        // eslint-disable-next-line no-console -- Intentional console error catch
        .catch(console.error);
    }
  }, [currentRole, isAuthenticated]);

  return (
    <CurrentRoleContext.Provider value={value}>
      {children}
    </CurrentRoleContext.Provider>
  );
};

export const useCurrentRole = () => useContext(CurrentRoleContext)?.currentRole;
export const useCurrentRoleContext = () => useContext(CurrentRoleContext) as RoleContextData;
