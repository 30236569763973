export const formatNumber = (value: number) => value.toLocaleString('en');

export const sanitizeFormattedNumber = (value: string) => value.replace(/\s/g, '');

export const parseFormattedNumber = (value: string) => {
  const sanitizeVal = sanitizeFormattedNumber(value);

  if (sanitizeVal.includes(',') && sanitizeVal.includes('.')) { // format: 3,000,000.00
    return sanitizeVal.replace(/,/g, '');
  }
  const hasCommaAsThousandSeparator = /,[0-9][0-9][0-9]($|[^0-9])/.test(sanitizeVal); // Has substring with exactly three numbers preceded by comma, e.g. ',000'

  return hasCommaAsThousandSeparator
    ? sanitizeVal.replace(/,/g, '')
    : sanitizeVal.replace(/,/g, '.');
};

export const clampNumber = (value: number, min = 0, max = 9999999999) =>
  Math.min(Math.max(min, value), max);
