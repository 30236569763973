import { FunctionComponent } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  color: ${props => props.theme.marineBlue};
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
`;

interface ReportInfoEntryProps {
  type: string;
  version: number;
}
export const ReportInfoEntry: FunctionComponent<ReportInfoEntryProps> = ({ type, version }) => {
  const reportTypeText = ` Version ${version}`;
  return (
    <Container>
      <Title>{` ${type} Report`}</Title>
      {reportTypeText}
    </Container>
  );
};
