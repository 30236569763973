import { stringRequired, userPickerMultiRequired } from '@/utils/validationSchemas';
import * as Yup from 'yup';

export const selectUsersValidationSchema = Yup.object({
  users: userPickerMultiRequired('At least one user must be selected'),
  primary: stringRequired('Primary user must be selected')
});

export const selectClaimsHandlerValidationSchema = Yup.object({
  // Field value will either be Object or ''
  claimsHandler: Yup.mixed().test(
    'has-value',
    'A user must be selected',
    value => (value !== '' && typeof value === 'object')
  )
});
