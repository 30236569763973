import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Enlarge } from '@instech/icons';

interface TableHeaderProps {
  textAlignCenter?: boolean;
}
export const TableHeader = styled.span<TableHeaderProps>`
  padding: 14px 20px 16px;
  background-color: ${props => props.theme.flatWhite};
  color: ${props => props.theme.blue};
  font-size: 16px;
  font-weight: bold;
  ${props => props.textAlignCenter && 'text-align: center;'}
`;

const TableHeaderWithIcon = styled(TableHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${props => props.onClick && 'pointer'};
  & svg {
    margin-top: 2px;
    width: 12px;
    height: 12px;
    opacity: 0.4;
    & path {
      stroke: ${props => props.theme.marineBlue};
      stroke-width: 3px;
    }
  }
`;

// If this component has an onClick, it will render with a sorting icon.
// If it does not, it will render as though it is an ordinary header.
// TODO: State handling to highlight when a given header is actively used to sort.
interface SortableHeaderProps {
  onClick?: () => any;
}
export const SortableTableHeader: FunctionComponent<SortableHeaderProps> = ({ children, onClick }) => (
  <TableHeaderWithIcon onClick={onClick}>
    {children}
    {onClick && <Enlarge />}
  </TableHeaderWithIcon>
);
