import { useState } from 'react';
import { Guid } from '@/types';
import { DeleteImageStatus, ImagePreview } from '@/services/imageServices';
import { useModalContext } from '@/components/modal/ModalContext';
import { ContentGrid, Sidebar } from '../core/ContentGrid';
import { ImageGrid } from '../core/ImageGrid';
import { EditImageForm } from '../core/ImageForm/EditImageForm';
import { NoImagesUploaded } from '../core/NoImagesUploaded';
import { openInUseDeleteDialog } from '../core/InUseDeleteDialog/openInUseDeleteDialog';

interface ImageLibraryProps {
  imageIds: Guid[];
  deleteImage: (id: Guid) => Promise<DeleteImageStatus>;
}
export const ImageLibraryEdit = ({ imageIds, deleteImage }: ImageLibraryProps) => {
  const [editingImage, setEditingImage] = useState<ImagePreview | null>(null);
  const { open } = useModalContext();

  const onSelectImage = (image: ImagePreview) => {
    const selected = editingImage?.id !== image.id ? image : null;
    setEditingImage(selected);
  };

  const onDelete = async (imageId: Guid) => {
    const result = await deleteImage(imageId);
    if (result === 'Inuse') {
      openInUseDeleteDialog({ open });
    }
    if (result === 'Success') {
      setEditingImage(null);
    }
  };

  if (imageIds.length < 1) {
    return <NoImagesUploaded />;
  }

  return (
    <ContentGrid>
      <ImageGrid
        imageIds={imageIds}
        selectedIds={editingImage ? [editingImage.id] : []}
        onSelectImage={onSelectImage}
        disableSelect={false}
      />
      <Sidebar title={editingImage ? 'Editing image' : 'Select image to edit'}>
        {editingImage && (
          <EditImageForm
            image={editingImage}
            onSave={() => setEditingImage(null)}
            onClose={() => setEditingImage(null)}
            onDelete={onDelete}
          />
        )}
      </Sidebar>
    </ContentGrid>
  );
};
