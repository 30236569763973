import { useTimezone } from '@/services/timezoneServices';
import { formatTimeOfDay, getFriendlyDate } from '@/utils/date';
import { ReactNode, FunctionComponent } from 'react';

interface EventTimeViewProps {
  RenderComponent: (children : {children: ReactNode}) => JSX.Element;
  date?: string;
  timeOfDay?: string | null;
  timezoneId?: string;
}
export const EventTimeView: FunctionComponent<EventTimeViewProps> = ({ RenderComponent, date, timeOfDay, timezoneId = 'UTC' }) => {
  const timezone = useTimezone(timezoneId, date, timeOfDay);
  const timePart = timeOfDay ? `, ${formatTimeOfDay(timeOfDay)}` : '';
  const timezonePart = timezoneId ? ` (UTC ${timezone?.offset})` : '';

  const fullDateString = `${getFriendlyDate(date)}${timePart}${timezonePart}`;

  return <RenderComponent>{fullDateString}</RenderComponent>;
};
