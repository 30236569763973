import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useCompanyLogo } from '@/services/companiesService';
import { PdfLogoAlignment } from '@/services/companyPdfDesignServices';

interface ImagePreviewProps {
  imageUrl: string;
}
const StyledImage = styled.img`
  width: 32px;
  height: 24px;
  object-fit: scale-down;
`;
const ImagePreview: FunctionComponent<ImagePreviewProps> = ({ imageUrl }) => (
  <StyledImage src={imageUrl} alt="Company logo" />
);

const PlaceholderLogo = styled.div`
  padding: 6px;
  background-color: ${props => props.theme.lightBlue};
  color: ${props => props.theme.marineBlue50};
`;

const LogoWrapper = styled.div<{logoAlignment: PdfLogoAlignment}>`
  padding-right: ${props => props.logoAlignment === 'Right' ? '16px' : '48px'};
  padding-left: ${props => props.logoAlignment === 'Right' ? '48px' : '16px'};
`;

interface Props {
  companyId: string;
  logoAlignment: PdfLogoAlignment;
}

export const SmallLogo: FunctionComponent<Props> = ({ companyId, logoAlignment }) => {
  const { data } = useCompanyLogo(companyId);

  const hasExistingLogo = Boolean(data);

  if (!hasExistingLogo) {
    return (
      <LogoWrapper logoAlignment={logoAlignment}>
        <PlaceholderLogo>Logo</PlaceholderLogo>
      </LogoWrapper>
    );
  }

  return (
    <LogoWrapper logoAlignment={logoAlignment}>
      <ImagePreview imageUrl={data?.url ?? ''} />
    </LogoWrapper>
  );
};
