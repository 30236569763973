import PropTypes from 'prop-types';
import styled from 'styled-components';

const TabItem = styled.div`
  padding: 6px;

  color: ${props => props.active ? props.theme.marineBlue : props.theme.black};
  background-color: ${props => props.active ? props.theme.whiteBlue : props.theme.flatWhite};
  border-bottom: ${props => props.active ? '4px solid' : 'none'};
  border-color: ${props => props.theme.marineBlue};    
  width: 100%;
  cursor: pointer;
`;

const StyledTitle = styled.div`
  margin-top: 10px;
  font-weight: bold;
`;

export const Tab = ({ title, onClick, active }) => (
  <TabItem active={active} onClick={onClick}><StyledTitle>{title}</StyledTitle></TabItem>
);

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool
};
