import { FunctionComponent } from 'react';
import * as Yup from 'yup';
import { Form, } from 'formik';
import { uploadLogoImageAsync } from '@/services/companiesService';
import { Guid } from '@/types';
import { imageRequired } from '@/utils/validationSchemas';
import { SaveButton, CloseButton } from '@/components/shared/FormButton/FormButton';
import {
  ButtonGroup, Dropzone, FormRow, FormikWithPrompt as Formik
} from '@instech/components';

interface FormModel {
  companyLogo: any;
}
const imageSchema: FormModel = {
  companyLogo: null,
};

const validationSchema = Yup.object().shape({
  companyLogo: imageRequired()
});
interface Props {
  companyId: Guid;
  handleClose: () => void;
  hasExistingLogo: boolean;
}
export const UploadForm: FunctionComponent<Props> = ({ companyId, hasExistingLogo, handleClose }) => (
  <Formik
    initialValues={{ ...imageSchema }}
    validationSchema={validationSchema}
    onSubmit={async values => {
      const submitValues = {
        image: values.companyLogo
      };
      const upload = await uploadLogoImageAsync(companyId, submitValues);
      if (upload) {
        handleClose();
      }
    }}
  >
    {({ values, isSubmitting, handleSubmit, dirty, resetForm }) => (
      <Form>
        <FormRow marginBottom={values.companyLogo && '30px'}>
          <Dropzone name="companyLogo" />
        </FormRow>
        <ButtonGroup alignRight reverse>
          <SaveButton disabled={!values.companyLogo} isSubmitting={isSubmitting} onClick={handleSubmit} />
          { hasExistingLogo && <CloseButton label="Cancel" onClick={handleClose} /> }
          {(!hasExistingLogo && dirty) && <CloseButton label="Reset" onClick={resetForm} />}
        </ButtonGroup>
      </Form>
    ) }
  </Formik>
);
