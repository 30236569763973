import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ErrorBoundary } from '@instech/components';
import { Page } from '@/components/layout/Page';
import { ErrorPage } from '../../../error/ErrorPage';
import { DashboardHeader } from './DashboardHeader';

const ContentArea = styled.div`
  padding: 30px;
`;

interface DashboardBasePageProps {
  vesselName: string;
  behindClaimWall?: boolean;
}
export const DashboardBasePage: FunctionComponent<DashboardBasePageProps> = ({ vesselName, behindClaimWall, children }) => (
  <ErrorBoundary component={ErrorPage}>
    <Page>
      <ContentArea>
        <DashboardHeader behindClaimWall={behindClaimWall} vesselName={vesselName} />
        {children}
      </ContentArea>
    </Page>
  </ErrorBoundary>
);
