import { RestoreDeletedItemPopup, useRestoreData } from '@/components/shared/RestoreDeletedItem';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import {
  addCompanyParticularAsync, deleteCompanyParticularsAsync, restoreDeletedCompanyParticularAsync, useCompanyParticulars
} from '@/services/companyParticularsServices';
import { CompanyParticular, Guid } from '@/types';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { NewParticularForm } from '../../core/NewParticularForm';
import { CompanyParticularRowEdit } from './CompanyParticularRowEdit';
import { CompanyParticularRowView } from './CompanyParticularRowView';

const Wrapper = styled.div`
  position: relative;
`;

const RestorePopup = styled(RestoreDeletedItemPopup)`
  right: 0;
`;

interface RowProps {
  item: CompanyParticular;
  isEditMode: boolean;
  onDelete: () => void;
  toggleEdit: () => void;
}
const ParticularRow: FC<RowProps> = ({ item, isEditMode, onDelete, toggleEdit }) => {
  if (isEditMode) {
    return <CompanyParticularRowEdit initialData={item} toggleEditMode={toggleEdit} />;
  }
  return <CompanyParticularRowView item={item} onDelete={onDelete} toggleEdit={toggleEdit} />;
};

interface Props {
  particulars: CompanyParticular[];
}
export const CompanyParticularsEditableView: FC<Props> = ({ particulars }) => {
  const caseId = useCaseIdFromUrl();
  const [editItem, setEditItem] = useState<Guid | undefined>(undefined);

  const { state: restoreState, showRestoreDialog, hideRestoreDialog } = useRestoreData();
  const { mutate } = useCompanyParticulars();

  const submitNewParticularAsync = async (particular: CompanyParticular) => {
    const result = await addCompanyParticularAsync(caseId, particular);
    if (result) {
      void mutate(existingItems => [...(existingItems ?? []), result], false);
    }
  };

  const onRestoreDeleted = (id: string) => async () => {
    const result = await restoreDeletedCompanyParticularAsync(caseId, id);
    void mutate(result);
  };

  const deleteParticular = async (itemId: Guid) => {
    void mutate(existingItems => existingItems?.filter(x => x.id !== itemId), false);
    await deleteCompanyParticularsAsync(caseId, itemId);
    showRestoreDialog(onRestoreDeleted(itemId));
  };

  const toggleEdit = (id: Guid) => {
    setEditItem(id !== editItem ? id : undefined);
  };

  return (
    <Wrapper>
      {particulars.map(item => (
        <ParticularRow
          key={item.id}
          item={item}
          isEditMode={editItem === item.id}
          onDelete={() => deleteParticular(item.id)}
          toggleEdit={() => toggleEdit(item.id)}
        />

      ))}
      <NewParticularForm
        submit={submitNewParticularAsync}
        showWhenEmpty={particulars.length === 0}
        hideRestoreDialog={hideRestoreDialog}
      />
      <RestorePopup
        {...restoreState}
      />
    </Wrapper>
  );
};
