import styled from 'styled-components';

interface TimezoneLabelProps {
  value: string;
  label: string;
  offset: string;
}

const Offset = styled.span`
  margin-right: 5px;
  opacity: 0.5;
`;

export const TimezoneLabel = ({ value, label, offset }: TimezoneLabelProps) => (
  <div>
    <Offset>{`(${offset})`}</Offset>
    <span>{label}</span>
  </div>
);
