import styled from 'styled-components';
import { NavContainer, NavWrapperOuter } from './Components';

/* These changes are only for the Report Nav for now,
   so we'll contain them here */
export const ReportNavContainer = styled(NavContainer)`
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    position: sticky;
    height: calc(100vh - 50px);
    top: 50px;
  }
`;

export const ReportNavWrapperOuter = styled(NavWrapperOuter)`
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    min-height: initial;
    height: calc(100vh - 100px);
    overflow-y: auto;
  }
`;
