import { useLocation } from 'react-router';
import { useAccount } from '@/components/authentication/AuthenticationProvider';

const getRedirectRoute = location => {
  if (location.state && location.state.from) return location.state.from.pathname;
  return '/';
};

export const LoginPage = () => {
  const { login } = useAccount();
  const location = useLocation();
  const route = getRedirectRoute(location);
  const directExternalLogin = location.search.includes('directExternalLogin=true');
  const options = {
    state: { route },
    extraQueryParams: { directExternalLogin }
  };
  login(options);

  return <div>You will now be logged in!</div>;
};
