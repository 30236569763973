/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { Table, TableHeader } from '@/components/shared/Table';
import { ExpenseTableStaticItem } from './ExpenseTableStaticItem';

export const ExpenseTableStatic = ({ expenses, arrayHelpers, setFieldValue, currency }) => {
  const tableLayout = '1.5fr 1fr 1fr 1fr 120px';

  return (
    <Table layout={tableLayout}>
      <TableHeader>Expense</TableHeader>
      <TableHeader>{`Cost - ${currency}`}</TableHeader>
      <TableHeader>Status</TableHeader>
      <TableHeader button>Visualise in report</TableHeader>
      <TableHeader>Comment</TableHeader>
      {expenses.map((expense, i) => (
        <ExpenseTableStaticItem
            key={expense.id}
            expense={expense}
            form={arrayHelpers.form}
            arrayId={`expenses.${i}`}
            number={i}
            setFieldValue={setFieldValue}
            isStatic
          />
      ))}
    </Table>
  );
};
ExpenseTableStatic.propTypes = {
  expenses: PropTypes.arrayOf(PropTypes.object),
  arrayHelpers: PropTypes.object,
  setFieldValue: PropTypes.func,
  currency: PropTypes.string
};
