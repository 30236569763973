import { UserInfo } from '@/types';
import { InformationNotification } from '@instech/components';
import { FunctionComponent as FC, useMemo } from 'react';
import styled from 'styled-components';

const headingText = 'Automatic email and portal notifications';

const bodyText = 'Users you give access to for the first time will automatically receive notifications.';

const Container = styled.div`
  margin-top: 24px;
`;

const getActualIds = (array: (UserInfo | undefined)[]) => (
  array.map(user => user?.id).filter(id => !!id)
);

interface Props {
  initialUsers: UserInfo[];
  selectedUsers: UserInfo[];
}
export const AccessNotification: FC<Props> = ({ initialUsers, selectedUsers }) => {
  const initialUserIds = useMemo(() => getActualIds(initialUsers), [initialUsers]);

  // Check if selectedIds contains any values not in initialIds
  const hasNewIds = useMemo(() => {
    const selectedUserIds = getActualIds(selectedUsers);
    return !selectedUserIds.every(user => initialUserIds.indexOf(user) !== -1);
  }, [initialUserIds, selectedUsers]);

  if (!hasNewIds) {
    return null;
  }

  return (
    <Container>
      <InformationNotification
        size="medium"
        headingText={headingText}
        descriptionText={bodyText}
      />
    </Container>
  );
};
