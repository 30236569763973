import {
  ArchivedTimeDetails, TimeDetails, claimInterests
} from '@/types';
import { useOccurrencesForCase } from '@/services/occurrenceServices';
import {
  Table, TableCell, TableHeader, TableRow
} from '@/components/shared/Table';
import { numberToDays } from '@/utils/text';
import { TruncatingText } from '@instech/components';
import { Header, Text } from './Components';
import { SimultaneousRepairs } from './SimultaneousRepairs';

const TimeForWorkNotConcerningAverage = ({ timeDetails }: { timeDetails: ArchivedTimeDetails }) => {
  const { dryDock, afloat } = timeDetails.separateDays;
  const totalDays = dryDock + afloat;

  return (
    <>
      <Header>{timeDetails.title}</Header>
      {timeDetails.durationComment && (
        <Text>{timeDetails.durationComment}</Text>
      )}
      <Table layout="2fr 1fr 2fr">
        <TableHeader>{`If carried out separately; ${numberToDays(totalDays)}`}</TableHeader>
        <TableHeader>Status</TableHeader>
        <TableHeader>Comment</TableHeader>
        <TableRow even>
          <TableCell>{`Dry Dock ${numberToDays(dryDock)}`}</TableCell>
          <TableCell>{timeDetails.separateDaysDryDockTimeStatus}</TableCell>
          <TableCell>
            {timeDetails.separateDaysDryDockComment && (
              <TruncatingText text={timeDetails.separateDaysDryDockComment} wrap />
            )}
          </TableCell>
        </TableRow>
        <TableRow even={false}>
          <TableCell>{`Afloat ${numberToDays(afloat)}`}</TableCell>
          <TableCell>{timeDetails.separateDaysAfloatTimeStatus}</TableCell>
          <TableCell>
            {timeDetails.separateDaysAfloatComment && (
              <TruncatingText text={timeDetails.separateDaysAfloatComment} wrap />
            )}
          </TableCell>
        </TableRow>
      </Table>
    </>
  );
};

const mapToArchived = (timeDetails: TimeDetails) => ({
  ...timeDetails,
  durationComment: timeDetails.includeDurationComment ? timeDetails.durationComment : null,
  separateDaysDryDockComment: timeDetails.includeSeparateDaysDryDockComment ? timeDetails.separateDaysDryDockComment : null,
  separateDaysAfloatComment: timeDetails.includeSeparateDaysAfloatComment ? timeDetails.separateDaysAfloatComment : null,
  widgetText: null
} as ArchivedTimeDetails);

interface PreviewProps {
  timeDetails: TimeDetails;
  caseId: string;
}
export const TimeForWorkNotConcerningAveragePreview = ({ timeDetails, caseId }: PreviewProps) => {
  const { data: occurrences } = useOccurrencesForCase(caseId);

  if (!timeDetails || !occurrences) return null;

  const { simultaneousRepairs } = timeDetails;
  const interests = occurrences?.flatMap(x => x.interests.flatMap(i => i.claimInterest)) ?? [];
  const someOccurrencesLOH = interests.includes(claimInterests.lossOfHire);
  const allOccurrencesLOH = interests.every(o => o === claimInterests.lossOfHire);

  const archivedTimeDetails = mapToArchived(timeDetails);

  if (simultaneousRepairs && someOccurrencesLOH) {
    return (
      <>
        <TimeForWorkNotConcerningAverage timeDetails={archivedTimeDetails} />
        <SimultaneousRepairs {...simultaneousRepairs} showWarning={!allOccurrencesLOH} />
      </>
    );
  }

  return (
    <TimeForWorkNotConcerningAverage timeDetails={archivedTimeDetails} />
  );
};

interface ViewProps {
  timeDetails: ArchivedTimeDetails;
}
export const TimeForWorkNotConcerningAverageView = ({ timeDetails }: ViewProps) => {
  const { simultaneousRepairs } = timeDetails;

  if (simultaneousRepairs) {
    return (
      <>
        <TimeForWorkNotConcerningAverage timeDetails={timeDetails} />
        <SimultaneousRepairs {...simultaneousRepairs} />
      </>
    );
  }

  return (
    <TimeForWorkNotConcerningAverage timeDetails={timeDetails} />
  );
};
