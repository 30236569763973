/* eslint-disable react/forbid-prop-types */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { TextArea } from '@instech/components';
import {
  TableRow, ExpandedDetailRow, TableCell, CellButton
} from '@/components/shared/Table';
import {
  Checkbox, StatusTypeDropdownField, MoneyField
} from '@/components/shared/Form/FormFields';
import { ChevronToggle } from '@/components/shared/Chevron';
import { CommentIncludeInReportBehavior } from '@/components/shared/CommentIncludeInReportBehavior/CommentIncludeInReportBehavior';

export const ExpenseTableStaticItem = ({ expense, form, arrayId, number, setFieldValue }) => {
  const [open, setOpen] = useState(false);
  const even = number % 2 === 0;

  const commentFieldName = `${arrayId}.comment`;
  const [, { error, touched }] = useField(commentFieldName);

  /**
   * Opens the comment field whenever the comment field has a validation error. Also
   * prevents the user from closing the comment field when there is a validation error.
   * */
  useEffect(() => {
    if (error && touched) {
      setOpen(true);
    }
  }, [error, touched]);
  const collapseCommentIsDisabled = !!(touched && error);

  return (
    <CommentIncludeInReportBehavior
      commentFieldName={`${arrayId}.comment`}
      includeInReportFieldName={`${arrayId}.includeCommentInReport`}
    >
      <TableRow even={even}>
        <TableCell>
          {expense.name}
        </TableCell>
        <TableCell>
          <MoneyField
            name={`${arrayId}.cost`}
            placeholder="0"
            step={0.01}
            min={0}
          />
        </TableCell>
        <TableCell>
          <StatusTypeDropdownField name={`${arrayId}.status`} noErrors noLabel />
        </TableCell>
        <TableCell checkbox>
          <Checkbox
            name={`${arrayId}.includeInReport`}
            noErrors
          />
        </TableCell>
        <TableCell button>
          <CellButton
            onClick={() => setOpen(!open)}
            icon={<ChevronToggle big thin open={open} />}
            disabled={collapseCommentIsDisabled}
          />
        </TableCell>
      </TableRow>
      <ExpandedDetailRow isOpen={open}>
        <TableCell span={5}>
          <TextArea
            name={`${arrayId}.comment`}
            placeholder="Comment"
            maxLength={500}
            noLabel
          />
          <Checkbox
            name={`${arrayId}.includeCommentInReport`}
            rightLabel="Include comment in report?"
            noErrors
          />
        </TableCell>
      </ExpandedDetailRow>
    </CommentIncludeInReportBehavior>
  );
};
// TODO: Define Damage & repair prop type?
ExpenseTableStaticItem.propTypes = {
  expense: PropTypes.object,
  form: PropTypes.object,
  arrayId: PropTypes.string,
  number: PropTypes.number,
  setFieldValue: PropTypes.func
};
