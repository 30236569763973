import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 27px 16px;
  & >:first-child {
    padding-bottom: 48px;
  }
`;

interface RowProps {
  gridTemplateColumns?: string;
}

export const Row = styled.div<RowProps>`
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns ?? '1fr 2fr 2fr'};
  column-gap: 20px;
`;

interface FieldProps {
  column?: number;
  position?: string;
}
export const Field = styled.div<FieldProps>`
  ${props => props.position && css`
    position: ${props.position};
  `}
  ${props => props.column && css`
    grid-column: ${props.column};`}
`;

const LargeFieldWrapper = styled(Field)`
  flex-direction: column;
  display: flex;
  align-items: center;
`;

export const LargeField: FunctionComponent = ({ children }) => (
  <LargeFieldWrapper>
    <div>{children}</div>
  </LargeFieldWrapper>
);

export const FieldHeader = styled.div`
  color: ${props => props.theme.blue};
  font-size: 14px;
  font-weight: bold;
`;

export const FieldContent = styled.div`
  color: ${props => props.theme.marineBlue};
  font-size: 24px;
  font-weight: bold;
  padding-top: 10px;
`;
