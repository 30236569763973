import { useEffect, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';

const config = {
  headers: {
    'X-CSRF': '1'
  }
};

interface Claim {
  type: string;
  value: string;
}
export interface UserClaims {
  isAuthenticated: boolean;
  claims?: Claim[]
}

export const useUsersClaims = () => {
  const [data, setData] = useState<UserClaims>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState(true);

  const fetchClaims = async () => {
    try {
      const result: AxiosResponse<Claim[]> = await axios.get('/management/user', config);
      if (result.status === 200) {
        setData({ isAuthenticated: true, claims: result.data });
      } else {
        setData({ isAuthenticated: false });
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const slideUserSession = async () : Promise<Claim[]> => {
    try {
      const result: AxiosResponse<Claim[]> = await axios.get('/management/user?slide=true', config);
      if (result.status === 200) {
        setData({ isAuthenticated: true, claims: result.data });
        return result?.data ?? [];
      }
      setData({ isAuthenticated: false });
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setLoading(false);
    }

    return [];
  };

  useEffect(() => {
    void fetchClaims();
  }, []);

  return { data, error, loading, slideUserSession };
};
