import {
  Guid, CaseMetadata, Case, CompanyParticular, Occurrence, BrokerContact
} from '@/types';
import client, { postJsonAsync } from '@/services/client';
import useSWR from 'swr';
import { VesselInformation } from '@/services/vesselInformationServices';

export interface UsersInCase {
  surveyors: Guid[],
  primaryClaimsHandlerId?: Guid,
  primarySurveyorId?: Guid
}

export interface CreateCaseModel {
  templateId: Guid;
  claimId: Guid;
  name: String;
  metadata: CaseMetadata;
  instructions?: String;
  companyParticulars?: CompanyParticular[];
  vesselInformation?: VesselInformation[];
  occurrence?: Occurrence,
  users: UsersInCase,
  broker: BrokerContact
}

export interface ClaimsData {
  claimId: Guid;
  name: string;
  metadata: CaseMetadata;
  companyParticulars?: CompanyParticular[];
  vesselInformation?: VesselInformation[];
  occurrence?: Occurrence;
  activeClaimWall: boolean;
  broker: BrokerContact;
}

export interface ReportTemplateDefinition {
  id: Guid;
  name: string;
  defaultInstructions?: string;
}

export const createCaseAsync = async (model: CreateCaseModel) => (
  postJsonAsync<Case>('case', model)
);

export const useTemplateDefinitions = () => {
  const { data } = useSWR<ReportTemplateDefinition[]>('template');
  return data;
};

export const getClaimsDataAsync = async (claimId: Guid) => {
  const result = await client.get<ClaimsData>(`claims/${claimId}`);
  if (result.status === 404) {
    return { status: 'InvalidClaimId', data: undefined };
  }
  return { status: 'Success', data: result.data };
};

export const getClaimsDataByClaimNumberAsync = async (claimNumber: string, year: string) => {
  // need to allow 404 instead of throwing an exception
  const result = await client.get<ClaimsData>(`claims/${claimNumber}/${year}`, { validateStatus: status => (status === 200 || status === 404) });

  if (result.status === 404) {
    return { status: 'InvalidClaimId', data: undefined };
  }
  return { status: 'Success', data: result.data };
};

export const useConditions = () => {
  const { data } = useSWR<string[]>('conditions');
  return data;
};
