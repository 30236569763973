import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { PaneContent, PaneTitle } from './core/Components';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  padding-left: 20px;
  padding-right: 12px; /* Close button has its own padding */
  border-bottom: 1px solid ${props => props.theme.marineBlue};
`;

interface ToolPaneProps {
  title: string;
}
export const ToolPane: FunctionComponent<ToolPaneProps> = ({ title, children }) => (
  <>
    <Header>
      <PaneTitle>{title}</PaneTitle>
    </Header>
    <PaneContent>
      {children}
    </PaneContent>
  </>
);
