import { FunctionComponent, useMemo } from 'react';
import {
  Table, TableHeader, TableRow, TruncatingTextTableCell
} from '@/components/shared/Table';
import styled from 'styled-components';
import { Pane, PreviewReportPane } from '@/components/shared/Pane';
import { AttendingRepresentative, useAttendingRepresentativeGroups } from '@/services/representativesServices';
import { Loader } from '@instech/components';
import { SurveyLocation } from '@/types';
import { setStringOrDash } from '@/utils/text';
import { getSurveyLocations } from '@/components/pages/report/shared/components/locationUtils';
import { useStaticResource } from '@/services/staticResourcesServices';
import { useParams } from 'react-router';
import { useVersionedReport } from '@/services/versionsService';
import { ArchivedReport } from '@/utils/archivedReportTypes';
import { extractTitles } from '../../../edit/widgets/AttendingRepresentatives/utility/helpers';

const fallbackInfoText = 'The following persons were present / involved:';
interface RowProps {
  representative: AttendingRepresentative;
  isEven: boolean;
}

const RepresentativeRow: FunctionComponent<RowProps> = ({ representative: { name, function: role, company, representing }, isEven }) => (
  <TableRow even={isEven}>
    <TruncatingTextTableCell text={name} wrap />
    <TruncatingTextTableCell text={role} wrap />
    <TruncatingTextTableCell text={company} wrap />
    <TruncatingTextTableCell text={setStringOrDash(representing)} wrap />
  </TableRow>
);

interface TableProps {
  representatives: AttendingRepresentative[];
}

export const AttendingRepresentativesTable: FunctionComponent<TableProps> = ({ representatives }) => (
  <Table columns={4}>
    <TableHeader>Name</TableHeader>
    <TableHeader>Function</TableHeader>
    <TableHeader>Company</TableHeader>
    <TableHeader>Representing</TableHeader>
    {representatives.map((row, i) => (
      <RepresentativeRow
        key={row.id}
        representative={row}
        isEven={i % 2 === 0}
      />
    ))}
  </Table>
);

interface ContainerProps {
  location: SurveyLocation;
  representatives: AttendingRepresentative[];
}

const IntroText = styled.div`
  padding-bottom: 20px;
`;

const SurveyLocationContainer: FunctionComponent<ContainerProps> = ({ location, representatives }) => {
  const { title, subTitle } = extractTitles(location);
  if (!representatives || representatives.length === 0) return null;
  return (
    <Pane title={title} subTitle={subTitle}>
      <AttendingRepresentativesTable representatives={representatives} />
    </Pane>
  );
};

interface PreviewProps {
  caseId: string;
  chapterId: string;
}

export const AttendingRepresentativesReportPreview: FunctionComponent<PreviewProps> = ({ caseId, chapterId }) => {
  const { data } = useAttendingRepresentativeGroups(caseId);
  const { data: infoText } = useStaticResource('attendingRepresentativesText');

  if (!data || !infoText) {
    return <Loader />;
  }

  return (
    <PreviewReportPane title="Attending Representatives" caseId={caseId} chapterId={chapterId}>
      {data.length > 0 && <IntroText>{infoText}</IntroText>}
      {
        data.map(({ surveyLocationId, surveyLocation, items }) => (
          <SurveyLocationContainer key={surveyLocationId} location={surveyLocation} representatives={items} />
        ))
      }
    </PreviewReportPane>
  );
};

interface ViewProps {
  data: {
    items: AttendingRepresentative[];
    infoText?: string;
  };
}

export const AttendingRepresentativesReportView: FunctionComponent<ViewProps> = ({ data }) => {
  const { id: caseId, version } = useParams() as { id: string; version: string; };
  const { data: report } = useVersionedReport(caseId, version, true) as { data: ArchivedReport };

  // getSurveyLocations is expensive to compute, therefore we wrap it in a
  // useMemo so it only recomputes when 'report' changes.
  const locations = useMemo(() => getSurveyLocations(report), [report]);

  return (
    <Pane title="Attending Representatives">
      {locations.length > 0 && <IntroText>{data.infoText || fallbackInfoText}</IntroText>}
      {
        locations.map(location => {
          const representatives = data.items.filter(({ surveyLocationId }) => surveyLocationId === location.id);
          return (
            <SurveyLocationContainer key={location.id} location={location} representatives={representatives} />
          );
        })
      }
    </Pane>
  );
};
