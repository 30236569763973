import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SortableCase, Dictionary } from '@/types';
import { CaseNotificationsList, mapToStatusList } from '@/services/caseNotificationsServices';
import { ReportVersion } from '@/utils/archivedReportTypes';
import {
  GridTable, FilterableTable, NoDataRow
} from '@/components/shared/FilterableTable';
import { Filters } from '@/components/shared/FilterableTable/types';
import { Loader, SortedTableHeader } from '@instech/components';
import { TableRow } from './TableRow';
import { sortableHeaders } from './sortableHeaders';

const TableRowLoader = styled(Loader)`
  width: 100%;
  padding: 24px 0px;
  background-color: white;
  grid-column: 1 / -1;
  box-sizing: border-box;
`;

interface RowProps {
  cases?: SortableCase[];
  favourites?: string[];
  reportVersions?: Dictionary<ReportVersion>;
}
// Row extracted out to own component so that pending data can be dealt with
// down here, causing data refresh to only affect the table inner
const AssignmentsTableRow: FunctionComponent<RowProps> = (
  { cases, favourites, reportVersions }
) => {
  if (!cases || !favourites || !reportVersions) {
    return <TableRowLoader />;
  }

  const rowData = cases.map(item => ({
    id: item.caseId,
    vessel: item.name,
    isClosed: item.finalized,
    dateRegistered: item.createdAt,
    broker: item.broker,
    surveyCompanyName: item.surveyCompanyName,
    occurrences: item.occurrences ?? [],
    favorite: favourites.includes(item.caseId),
    notifications: mapToStatusList(item.notifications)?.notifications ?? [],
    tasks: item.caseTasks,
    reportVersion: reportVersions[item.caseId]
  }));

  if (!rowData.length) {
    return (
      <NoDataRow even size="small">
        <i>Found no data matching currently set filters.</i>
      </NoDataRow>
    );
  }

  return (
    <>
      {rowData.map((item, index) => <TableRow key={item.id} item={item} index={index} />)}
    </>
  );
};

interface Props {
  cases?: SortableCase[];
  favourites?: string[];
  notifications?: Dictionary<CaseNotificationsList>;
  reportVersions?: Dictionary<ReportVersion>;
  filters?: Filters;
  allowFilters?: boolean;
  sortedHeader?: SortedTableHeader;
  changeFilter: (parameterName: any, selectedFacets: any[]) => any;
  setSortedHeader: (header: SortedTableHeader) => void;
}
export const AssignmentsTable: FunctionComponent<Props> = ({
  cases, favourites, notifications, reportVersions, filters, allowFilters,
  sortedHeader, setSortedHeader, changeFilter
}) => (
  <GridTable columns="repeat(9, auto) 100px">
    <FilterableTable
      endpoint="placeholder" // TODO: Needs a real endpoint
      headers={sortableHeaders}
      filters={filters}
      columnMetadata={[]} // TODO: Needs actual metadata
      sortedHeader={sortedHeader}
      setSortedHeader={setSortedHeader}
      changeFilter={changeFilter}
      allowFilters={allowFilters}
      wide
    />
    <AssignmentsTableRow
      cases={cases}
      favourites={favourites}
      reportVersions={reportVersions}
    />
  </GridTable>
);
