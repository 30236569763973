import styled, { css } from 'styled-components';
import { TruncatingText } from '@instech/components';
import { VerticalPath } from './TimelinePath';
import { TimelineAnchor } from './TimelineAnchor';

interface LocationTextProps {
  isRight?: boolean;
}
const LocationText = styled.span<LocationTextProps>`
  display: flex;
  white-space: nowrap;
  color: ${props => props.theme.marineBlue};
  font-weight: bold;
  line-height: 20px;
  margin-top: -2px;
  ${props => !props.isRight && css`
    grid-area: left;
    text-align: right;
    justify-content: flex-end;
    margin-right: 2px;
    padding-right: 14px;
  `};
  ${props => props.isRight && css`
    grid-area: right;
    text-align: left;
    justify-content: flex-start;
    margin-left: 2px;  
    padding-left: 14px;
  `};
`;

const YearText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: center;
  width: 96px;
  color: ${props => props.theme.marineBlue};
  font-size: 21px;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
`;

interface LocationProps {
  name: string;
  isRight?: boolean;
  elsewhere?: boolean;
}
export const TimelineLocation = ({ name, isRight, elsewhere }: LocationProps) => (
  <TimelineAnchor height="18px">
    <LocationText isRight={elsewhere ? !isRight : isRight}>
      <TruncatingText text={name} width="300px" />
    </LocationText>
  </TimelineAnchor>
);

interface YearProps {
  date: Date;
  hasPath: boolean;
  isRight: boolean;
}
export const TimelineYear = ({ date, hasPath, isRight }: YearProps) => (
  <TimelineAnchor height={hasPath ? '64px' : 'auto'} padding={hasPath ? '6px' : '4px'}>
    <YearText>
      <span>{new Date(date).getFullYear()}</span>
    </YearText>
    {hasPath && <VerticalPath isRight={isRight} />}
  </TimelineAnchor>
);
