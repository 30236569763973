import {
  Dictionary, Occurrence, SortableCaseSurveyor, SurveyorDashboardAssignment
} from '@/types';
import { ReportVersion } from '@/utils/archivedReportTypes';
import { isNullishOrWhitespace } from '@/utils/text';

export const sortableHeaders = [
  {
    title: 'Report Status',
    propertyName: 'sortableReportStatus',
    parameterName: 'SelectedReportStatuses'
  },
  {
    title: 'Claim Number',
    propertyName: 'sortableClaimNumber',
    parameterName: 'SelectedClaimNumbers'
  },
  {
    title: 'Vessel',
    propertyName: 'name',
    parameterName: 'SelectedVessels'
  },
  {
    title: 'Date of Loss',
    propertyName: 'sortableDateOfLoss',
    parameterName: 'SelectedLossDates',
    noDropdown: true
  },
  {
    title: 'Occurrence Name',
    propertyName: 'sortableOccurrenceName',
    parameterName: 'SelectedOccurrenceNames'
  },
  {
    title: 'Fleet',
    propertyName: 'sortableFleet',
    parameterName: 'SelectedFleets'
  },
  {
    title: 'Interest',
    propertyName: 'sortableInterest',
    parameterName: 'SelectedInterestTypes'
  },
  {
    title: 'Deductible',
    propertyName: 'sortableDeductible',
    parameterName: 'SelectedDeductibles'
  },
  {
    title: 'Claims Handler',
    propertyName: 'claimsHandler',
    parameterName: 'SelectedClaimsHandlers'
  },
  {
    title: 'Starred',
    propertyName: 'sortableFavourite',
    parameterName: 'SelectedStarredRows',
    center: true
  }
];

const sortableDateOfLoss = (occurrences: Occurrence[] | null): string | null => {
  const occurrence = occurrences?.find(item => item.dateOfLoss !== null);
  if (!occurrence) return null;
  return occurrence.dateOfLoss;
};

const sortableOccurrenceName = (occurrences: Occurrence[] | null): string | null | undefined => {
  const occurrence = occurrences?.find(item => item.name !== null);
  if (!occurrence) return null;
  return occurrence.name;
};

const sortableFleet = (occurrences: Occurrence[] | null): string | null | undefined => {
  const occurrence = occurrences?.find(item => item.fleetName !== null);
  if (!occurrence) return null;
  return occurrence.fleetName;
};

const sortableInterest = (occurrences: Occurrence[] | null): string | null | undefined => {
  const interests = occurrences?.flatMap(x => x.interests?.flatMap(i => i.interest)) ?? [];
  const interest = interests.find(item => !isNullishOrWhitespace(item));
  if (!interest) return null;
  return interest;
};

const sortableClaimEvent = (occurrences: Occurrence[] | null): string | null | undefined => {
  const claimEventNames = occurrences?.flatMap(x => x.interests?.flatMap(i => i.claimEventName)) ?? [];
  const claimEventName = claimEventNames.find(item => !isNullishOrWhitespace(item));
  if (!claimEventName) return null;
  return claimEventName;
};

const sortableClaimNumber = (occurrences: Occurrence[] | null): string | null => {
  const claimReferences = occurrences?.flatMap(x => x.interests?.flatMap(i => i.claimReference)) ?? [];
  const claimReference = claimReferences.find(item => !isNullishOrWhitespace(item));
  if (!claimReference) return null;
  return claimReference;
};

const sortableDeductible = (occurrences: Occurrence[] | null): string | null => {
  const deductibles = occurrences?.flatMap(x => x.interests?.flatMap(i => i.deductible)) ?? [];
  const deductible = deductibles.find(item => !isNullishOrWhitespace(item));
  if (!deductible) return null;
  return deductible;
};

export const convertToSortableCases = (
  cases: SurveyorDashboardAssignment[],
  versions: Dictionary<ReportVersion>,
  favourites?: string[]
): SortableCaseSurveyor[] =>
  cases.map(item => (
    {
      ...item,
      sortableReportStatus: versions[item.caseId]?.reportType,
      sortableClaimNumber: sortableClaimNumber(item.occurrences),
      sortableDateOfLoss: sortableDateOfLoss(item.occurrences),
      sortableOccurrenceName: sortableOccurrenceName(item.occurrences),
      sortableFleet: sortableFleet(item.occurrences),
      sortableInterest: sortableInterest(item.occurrences),
      sortableClaimEvent: sortableClaimEvent(item.occurrences),
      sortableFavourite: favourites?.includes(item.caseId) ?? false,
      sortableDeductible: sortableDeductible(item.occurrences)
    }
  ));
