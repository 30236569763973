import styled from 'styled-components';

interface Position {
  top?: string;
  right?: string;
}

interface IconSize {
  width?: string;
  height?: string;
}
interface Props {
  color?: string;
  backgroundColor?: string;
  borderWidth?: string;
  position?: Position;
  iconSize?: IconSize;
}

export const IconWrapper = styled.div<Props>`
  position: absolute;
  top: ${props => props.position?.top ?? '-10px'};
  right: ${props => props.position?.right ?? '-10px'};
  z-index: 10;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.iconSize?.width ?? '20px'};
  height: ${props => props.iconSize?.height ?? '20px'};
  background-color: ${props => props.backgroundColor ?? props.theme.orange};
  border: ${props => props.borderWidth ?? '1px'} solid ${props => props.color ?? props.theme.white};
  border-radius: 50%;
  color: ${props => props.color ?? props.theme.white};
  cursor: pointer;
  // Avoid having a wider touch area causing a select-glow
  // spreading far outside the icon circle on tap
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

// Doing some media-hover trickery here to expand the
// touch area for this on mobile.
export const TouchArea = styled.div`
  @media (hover: none) {
    position: absolute;
    top: -10px;
    right: -10px;
    left: -10px;
    bottom: -10px;
  }
`;
