import { FC } from 'react';
import styled from 'styled-components';
import { DangerNotification } from '@instech/components';

const Container = styled.div`
  width: 100%;
  padding-bottom: 32px;
`;

interface Props {
  showError: boolean;
}
export const PrimaryErrorDisplay: FC<Props> = ({ showError }) => {
  if (!showError) return null;

  return (
    <Container>
      <DangerNotification
        headingText="Primary Surveyor must be selected."
        descriptionText="Please select a primary surveyor."
        size="medium"
      />
    </Container>
  );
};
