import useSWR, { mutate } from 'swr';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import {
  Manifest, Part, TransformedChapter
} from '@/utils/manifestTypes';

export const getManifestUrl = (caseId: string) => `/case/${caseId}/manifest`;

export const useManifest = (suspense: boolean = false) => {
  const caseId = useCaseIdFromUrl();
  return useSWR<Manifest>(getManifestUrl(caseId), { suspense });
};

export const getToolWidget = (manifest: Manifest, widgetType: string) => manifest.reportTools
  .map(x => x.widgets)
  .flat()
  .find(x => x.widgetType === widgetType);

export function transformTOC({ parts }: { parts: Part[] }): TransformedChapter[] {
  return parts.flatMap((part: any) =>
    part.chapters.map((chapter: any) => ({
      id: chapter.id,
      part: part.title,
      title: chapter.title,
      description: chapter.description,
      instructionReference: chapter.instructionReference,
      canAddWidget: chapter.canAddWidget,
      occurrenceWidget: chapter.occurrenceWidget,
      widgets: chapter.widgets || null
    })));
}

export const refreshCachedManifest = async (caseId: string) => {
  const url = getManifestUrl(caseId);
  await mutate(url);
};
