/* eslint-disable react/forbid-prop-types */
import { PropsWithChildren } from 'react';
import * as Yup from 'yup';
import { ToggleInlineButton } from '@/components/shared/FormButton/FormButton';
import { Field } from '@/types';
import { AnyObject } from 'yup/lib/types';
import { InputForm } from './InputForm';

type Props<TModel> = {
  model: TModel;
  fields: Field[];
  onSubmit: (model: TModel) => Promise<void>;
  validationSchema: Yup.ObjectSchema<AnyObject>;
  hideRestoreDialog?: () => void;
  showNewItemForm: boolean;
  setShowNewItemForm: (value: boolean) => void;
  cancelIsReset?: boolean;
}

function VesselInformationForm<TModel>({
  model,
  fields,
  onSubmit,
  validationSchema,
  hideRestoreDialog,
  showNewItemForm,
  setShowNewItemForm,
  cancelIsReset
}: PropsWithChildren<Props<TModel>>) {
  const submit = async (newModel: TModel) => {
    await onSubmit(newModel);
    if (hideRestoreDialog) {
      hideRestoreDialog();
    }
  };

  if (!showNewItemForm) {
    return (
      <ToggleInlineButton
        label="Add more"
        onClick={() => {
          setShowNewItemForm(true);
          if (hideRestoreDialog) {
            hideRestoreDialog();
          }
        }}
        marginTop="16px"
      />
    );
  }

  return (
    <InputForm
      model={model}
      fields={fields}
      onSubmit={values => submit(values)}
      closeForm={() => setShowNewItemForm(false)}
      validationSchema={validationSchema}
      cancelIsReset={cancelIsReset}
    />
  );
}

export { VesselInformationForm };
