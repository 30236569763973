import { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Add } from '@instech/icons';
import { LabelValuePair, UserInfo } from '@/types';
import { useFeatureFlag } from '@/providers/featureFlagProvider';
import { useCaseFavourites } from '@/services/caseFavoritesServices';
import { useAllClaimsHandlers, useCurrentUserInfo } from '@/services/userInfoService';
import { useDashboardAssignments } from '@/services/dashboardAssignmentsServices';
import { useUserNewestReports } from '@/services/versionsService';
import {
  PageLoader, SortedTableHeader, sortTableByParameter
} from '@instech/components';
import { NavBox } from '@/components/shared/BoxButton/BoxButtons';
import { FilterSelectDropdown, FilterSelectDropdownMulti } from '@/components/shared/FilterableTable/FilterSelect';
import { FilterRow } from '@/components/shared/FilterableTable';
import { AssignmentsTable } from './core/AssignmentsTable';
import { convertToSortableCases } from './core/sortableHeaders';

const myClaimsOption = (currentUser: UserInfo) => ({ label: 'My Claims', value: currentUser.id });

const claimHandlersToOptions = (claimHandlers: UserInfo[], currentUser: UserInfo): LabelValuePair[] => {
  const options = claimHandlers
    .map(user => ({
      label: user.displayName,
      value: user.id
    }))
    .filter(user => user.value !== currentUser.id) // Exclude current user
    .sort((userA, userB) => (
      userA.label.localeCompare(userB.label)
    ));
  return [myClaimsOption(currentUser), ...options];
};

const Wrapper = styled.div`
  padding: 60px 40px 40px;
`;

const Container = styled.div`
  background-color: ${props => props.theme.white};
  border-top: 6px solid ${props => props.theme.maritime2};
  border-radius: 2px 2px 0px 0px;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
`;

interface InnerProps {
  currentUser: UserInfo;
  claimHandlers: UserInfo[];
}
const ClaimsHandlerAssignmentsInner = ({ currentUser, claimHandlers }: InnerProps) => {
  const [showOpen, setShowOpen] = useState(true);
  const [sortedHeader, setSortedHeader] = useState<SortedTableHeader>();
  // Initializing this value with just the current user as selected claims handler
  const [selectedClaimsHandlers, setSelectedClaimsHandlers] = useState<LabelValuePair[]>([myClaimsOption(currentUser)]);

  const cachedHandlers = useMemo(() => selectedClaimsHandlers.map(item => item.value), [selectedClaimsHandlers]);
  const handlerOptions = useMemo(() => claimHandlersToOptions(claimHandlers, currentUser), [claimHandlers, currentUser]);

  const { data: cases, error } = useDashboardAssignments(showOpen, cachedHandlers);
  const { data: reportVersions } = useUserNewestReports();
  const { data: favourites } = useCaseFavourites();

  if (error) throw new Error(error);

  const sortableCases = useMemo(() => {
    if (!cases || !reportVersions || !favourites) return cases;
    return convertToSortableCases(cases, reportVersions, favourites.caseId);
  }, [cases, reportVersions, favourites]);

  // If true, can access Claims Handlers dropdown
  const claimsHandlersFlag = useFeatureFlag('filterClaimsHandlers');
  // True if anything is still loading, to block certain elements
  const pending = !cases || !favourites || !reportVersions || !sortableCases;

  const sortedCases = useMemo(() => {
    if (!sortableCases) return sortableCases;
    return sortTableByParameter(sortableCases, sortedHeader?.propertyName, sortedHeader?.sortOrder);
  }, [sortableCases, sortedHeader]);

  const handleSortedHeader = (header: SortedTableHeader) => {
    setSortedHeader(header);
  };

  // This function is currently not used, as it hasn't been implemented yet
  // from how this worked in WAR. Should be followed up on some time?
  const changeFilter = (parameterName: any, selectedFacets: any[]) => {
    // Change filter handler
  };

  const changeOpenStatus = (value: string) => {
    const valToBool = value === 'Open';
    setShowOpen(valToBool);
  };

  const changeClaimsHandlers = (value: LabelValuePair[]) => {
    setSelectedClaimsHandlers(value);
  };

  return (
    <Wrapper>
      <Heading>
        <Title>Claims dashboard</Title>
        <NavBox to="/create" height="40px" padding="6px 20px" startIcon={Add}>
          Create new case
        </NavBox>
      </Heading>
      <Container>
        <FilterRow>
          <FilterSelectDropdown
            blocked={pending}
            parameterName="CaseStatus"
            entries={['Open', 'Closed']}
            selected={showOpen ? 'Open' : 'Closed'}
            onChange={changeOpenStatus}
          />
          {claimsHandlersFlag && (
            <FilterSelectDropdownMulti
              blocked={pending}
              parameterName="ClaimHandlers"
              entries={handlerOptions}
              selected={selectedClaimsHandlers}
              onChange={changeClaimsHandlers}
            />
          )}
        </FilterRow>
        <AssignmentsTable
          cases={sortedCases}
          favourites={favourites?.caseId}
          reportVersions={reportVersions}
          allowFilters
          changeFilter={changeFilter}
          sortedHeader={sortedHeader}
          setSortedHeader={handleSortedHeader}
        />
      </Container>
    </Wrapper>
  );
};

// Exported this out in a component above, so that the rest can load
// with the current user and claim handlers already loaded
export const ClaimsHandlerAssignments = () => {
  const { data: currentUser } = useCurrentUserInfo();
  const claimHandlers = useAllClaimsHandlers();

  if (!currentUser || !claimHandlers) {
    return <PageLoader />;
  }

  return (
    <ClaimsHandlerAssignmentsInner currentUser={currentUser} claimHandlers={claimHandlers} />
  );
};
