import styled from 'styled-components';
import { Certificates as CertificatesType } from '@/services/certificatesServices';
import {
  FormatClassNotation, FormatCertificates, FormatDetails, FormatTriState
} from './Components';

const Columns = styled.div`
  display: flex;
  & > div {
    width: 50%;
    padding: 0px 12px;
    overflow: hidden;
  }
`;

const Entry = styled.div`
  display: block;
  margin-bottom: 10px;
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
`;

const EntryTitle = styled.span`
  display: block;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 4px;
  color: ${props => props.theme.marineBlue};
`;

export const CertificateList = ({ values }: { values: CertificatesType }) => (
  <Columns>
    <div>
      <Entry>
        <EntryTitle>ISM SMC Details</EntryTitle>
        <FormatDetails dates={values.ismCertificates.smc} />
      </Entry>
      <Entry>
        <EntryTitle>{values.certificatesValid ? 'Certificates' : 'Invalid Certificates'}</EntryTitle>
        <FormatCertificates valid={values.certificatesValid} invalid={values.invalidCertificates} />
      </Entry>
    </div>
    <div>
      <Entry>
        <EntryTitle>DOC Details</EntryTitle>
        <FormatDetails dates={values.ismCertificates.doc} />
      </Entry>
      <Entry>
        <EntryTitle>Class recommendations</EntryTitle>
        <FormatClassNotation recommendations={values.classRecommendations} />
      </Entry>
    </div>
    <div>
      <Entry>
        <EntryTitle>Casualty ISM reported?</EntryTitle>
        <FormatTriState state={values.casualtyIsmReported} />
      </Entry>
      <Entry>
        <EntryTitle>Prior related ISM reports?</EntryTitle>
        <FormatTriState state={values.priorRelatedIsmReports} />
      </Entry>
    </div>
  </Columns>
);
