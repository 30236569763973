import useSWR from 'swr';
import { CaseTasks, Guid } from '@/types';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';

type TaskType = 'AvailableInformation' | 'ClaimsNumber';

export interface Task {
  name: string;
  type: TaskType;
}
export interface CaseTasksList {
  caseId: Guid;
  tasks: Task[];
}

export const occurrenceTask: Task = {
  name: 'Add Claim Number',
  type: 'ClaimsNumber'
};

export const availableInformationTask: Task = {
  name: 'Available information requested',
  type: 'AvailableInformation'
};

export const getTasksList = (caseTasks: CaseTasks) => {
  const tasks = [];
  if (caseTasks.hasAvailableInformationTask) {
    tasks.push(availableInformationTask);
  }
  if (caseTasks.hasOccurrenceTask) {
    tasks.push(occurrenceTask);
  }
  return tasks;
};

const caseTasksUrl = (caseId: string) => `/casetasks/cases/${caseId}`;

export const useCaseTasks = () => {
  const caseId = useCaseIdFromUrl();
  const swr = useSWR<CaseTasks>(caseTasksUrl(caseId), { suspense: true });

  return swr;
};
