import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { InformationBox, InformationRow } from '@/components/shared/InformationBox';
import { TransformedChapter, Widget } from '@/utils/manifestTypes';
import { DetailedInformationBox } from '@/components/shared/DetailedInformationBox';
import { useEditContext } from './core/EditContext';
import { SubcategoriesButton } from './core/SubcategoriesButton';
import { ChapterHeader } from './core/ChapterHeader';
import { WidgetsContainer } from './core/ChapterWidgets';

const ignoredInstructionReferences = [
  'VesselEventsChapterReference'
];

const ContentArea = styled.div`
  padding: 30px;
`;

function hasOccurrenceDependentWidgetButNoOccurrence(widgets: Widget[], canAddWidget: boolean, occurrenceWidget?: string) {
  if (widgets.length > 0) return false;
  return !canAddWidget && !!occurrenceWidget;
}

const ChapterPageWrapper: FunctionComponent<{part: string, title: string}> = ({ part, title, children }) => (
  <div>
    <ChapterHeader part={part} title={title} />
    <ContentArea>
      {children}
    </ContentArea>
  </div>
);

export const ChapterPage = () => {
  const { id: caseId, chapterId } = useParams<{id: string, chapterId: string}>();
  const { chapters } = useEditContext();

  const currentChapter = chapters.find(f => f.id === chapterId) as TransformedChapter;
  const { part, title, widgets, description, canAddWidget, occurrenceWidget, instructionReference } = currentChapter;

  const showFillOccurrenceMessage = hasOccurrenceDependentWidgetButNoOccurrence(widgets ?? [], canAddWidget, occurrenceWidget);

  if (showFillOccurrenceMessage) {
    return (
      <ChapterPageWrapper part={part} title={title}>
        <InformationRow>
          <InformationBox text="Please add an Occurrence before editing this chapter" />
        </InformationRow>
      </ChapterPageWrapper>
    );
  }

  const isIgnoredInstruction = instructionReference && ignoredInstructionReferences.includes(instructionReference);
  const showInformationRow = !!(description || instructionReference) && !isIgnoredInstruction;
  return (
    <ChapterPageWrapper part={part} title={title}>
      { showInformationRow && (
        <InformationRow>
          <DetailedInformationBox
            instructionReference={instructionReference}
            fallbackDescription={description}
            actionButton={canAddWidget ? <SubcategoriesButton caseId={caseId} chapterId={chapterId} /> : null}
          />
        </InformationRow>
      )}
      <WidgetsContainer widgets={widgets.filter(x => !x.notInUse)} />
    </ChapterPageWrapper>
  );
};
