import styled, { css } from 'styled-components';
import { MoveUp, MoveDown } from '@instech/icons';

const Wrapper = styled.div<{ singleArrow?: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: center;
  ${props => !props.singleArrow && css`
    height: 44px;
    margin: -2px 0px;
  `}
  ${props => props.singleArrow && css`
    height: 40px;
    justify-content: center;
  `}
`;

interface StyledArrowProps {
  disabled?: boolean;
  solo?: boolean;
  top?: boolean;
  bottom?: boolean;
}
const StyledArrow = styled.button<StyledArrowProps>`
  height: 22px;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: ${props => props.theme.marineBlue};
  cursor: pointer;
  ${props => props.disabled && css`
    cursor: initial;
    color: ${props.theme.marineBlue50};
  `}
  ${props => !props.solo && css`
    display: flex;
    ${props.top && css`
      align-items: flex-end;
      padding-bottom: 2px;
    `}
    ${props.bottom && css`
      align-items: flex-start;
      padding-top: 2px;
    `}
  `}
`;

interface ArrowProps {
  show: boolean;
  solo?: boolean;
  top?: boolean;
  bottom?: boolean;
  disabled?: boolean;
  onClick?: () => any;
}
const Arrow = ({ show, solo, top, bottom, disabled, onClick }: ArrowProps) => {
  if (!show) return null;
  return (
    <StyledArrow type="button" disabled={disabled} solo={solo} top={top} bottom={bottom} onClick={!disabled ? onClick : undefined}>
      {top && <MoveUp />}
      {bottom && <MoveDown />}
    </StyledArrow>
  );
};

interface Props {
  first: boolean;
  last: boolean;
  disabled?: boolean;
  onClickUp: () => any;
  onClickDown: () => any;
}
export const ArrowButton = ({ first, last, disabled = false, onClickUp, onClickDown }: Props) => (
  <Wrapper singleArrow={first || last}>
    <Arrow show={!first} solo={first || last} top onClick={onClickUp} />
    <Arrow show={disabled || !last} solo={first || last} bottom disabled={disabled} onClick={onClickDown} />
  </Wrapper>
);
