import { FunctionComponent } from 'react';
import { useTimeDetails } from '@/components/pages/edit/widgets/TimeDetails/timeDetailsService';
import { Pane, PreviewReportPane } from '@/components/shared/Pane';
import { ArchivedTimeDetails, Guid } from '@/types';
import { TimeForWorkNotConcerningAveragePreview, TimeForWorkNotConcerningAverageView } from './TimeForWorkNotConcerningAverage';

/**
 * TimeForWorkNotConcerningAverage is the only TimeDetails widget supported at the moment.
 * We may want to implement a widet map here in the future if we should include additional TimeDetails widgets.
 */

interface PreviewProps {
  caseId: Guid;
  chapterId: Guid;
  widgetId: Guid;
}
export const TimeDetailsReportPreview: FunctionComponent<PreviewProps> = ({ caseId, chapterId, widgetId }) => {
  const { data: timeDetails } = useTimeDetails(caseId, chapterId, widgetId, { suspense: false });

  if (!timeDetails) return null;

  return (
    <PreviewReportPane
      title={timeDetails.title}
      caseId={caseId}
      chapterId={chapterId}
    >
      <TimeForWorkNotConcerningAveragePreview timeDetails={timeDetails} caseId={caseId} />
    </PreviewReportPane>
  );
};

interface ViewProps {
  data: ArchivedTimeDetails;
}
export const TimeDetailsReportView: FunctionComponent<ViewProps> = ({ data }) => (
  <Pane title={data.title}>
    <TimeForWorkNotConcerningAverageView timeDetails={data} />
  </Pane>
);
