export const charterOptions = [
  {
    value: 'Employed',
    isHeader: true
  },
  { value: 'Bareboat Charter' },
  { value: 'Time Charter' },
  { value: 'Voyage Charter' },
  {
    value: 'Not Employed',
    isHeader: true
  },
  { value: 'Awaiting assignment' },
  { value: 'Laid up' },
  { value: 'Detained' }
];

/**
 * The new dropdown component supports options by groups in a different data structure.
 */
export const charterOptionsByGroups = [
  {
    label: 'Employed',
    options: [
      { value: 'Bareboat Charter', label: 'Bareboat Charter' },
      { value: 'Time Charter', label: 'Time Charter' },
      { value: 'Voyage Charter', label: 'Voyage Charter' },
    ],
  },
  {
    label: 'Not Employed',
    options: [
      { value: 'Awaiting assignment', label: 'Awaiting assignment' },
      { value: 'Laid up', label: 'Laid up' },
      { value: 'Detained', label: 'Detained' },
    ],
  },
];
