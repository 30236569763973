import useSWR from 'swr';
import moment from 'moment';
import client from '@/services/client';
import { constructDateTime } from '@/utils/date';

export interface TimezoneOptionFromService {
  ianaId: string;
  label: string;
  offsetValue: string;
  offset: string;
}

export interface TimezoneOption {
  value: string;
  label: string;
  offset: string;
}

export const mapTimezones = (timezones: TimezoneOptionFromService[]) => {
  const optionMap = timezones
    .map((zone: TimezoneOptionFromService) => ({
      value: zone.ianaId,
      label: zone.label,
      offset: zone.offset,
    }));
  return optionMap;
};

export const timezoneUrl = (dateTime?: string) => {
  const url = 'timezones';
  const isValidDate = moment(dateTime).isValid();
  if (dateTime && isValidDate) return `${url}?localDateTime=${encodeURI(dateTime)}`;
  return url;
};
const getTimezoneOptions = async (dateTime?: string) => {
  const result = await client.get<TimezoneOptionFromService[]>(timezoneUrl(dateTime));

  const mappedResult = mapTimezones(result.data);
  return mappedResult;
};

const tenMinutesInMilliseconds = 10 * 60 * 1000;

export const useTimezoneOptions = (dateTime?: string, suspense?: boolean) => {
  const swr = useSWR<TimezoneOption[]>(
    timezoneUrl(dateTime),
    () => getTimezoneOptions(dateTime),
    { dedupingInterval: tenMinutesInMilliseconds, suspense }
  );

  if (swr.error) {
    throw new Error(swr.error);
  }
  return swr;
};

const defaultTimezone = 'UTC';
export const useTimezone = (timezoneId?: string | null, eventDate?: string, eventTime?: string | null) => {
  const timezoneIdOrDefault = timezoneId || defaultTimezone;
  const dateTime = constructDateTime(eventDate, eventTime);

  const { data } = useTimezoneOptions(dateTime, true);

  return data?.find(x => x.value === timezoneIdOrDefault);
};
