import { SummaryOfCost, ExpenseSummaryGroup } from '@/types';
import { ColumnWidgetWrapper } from '@/components/pages/edit/widgets/ColumnWidgetWrapper';
import { SummaryCost } from '@instech/icons';
import { useSummaryOfCost } from '@/services/summaryofcostService';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { getOccurrencePrefixTitle } from '@/utils/text';
import { ExpenseCard } from '../../components/CostAndTimeSummary/ContentPane';
import { HeaderPane } from '../../components/CostAndTimeSummary/HeaderPane';
import { TotalAmount } from './TotalAmount';
import { EditPane } from '../../core/EditPane';

const SummaryItem = ({ isOccurrence, title, status, currency, sum, chapterId }: SummaryOfCost) => {
  const caseId = useCaseIdFromUrl();
  const newTitle = getOccurrencePrefixTitle(title, isOccurrence);

  return (
    <ExpenseCard
      title={newTitle}
      status={status}
      currency={currency}
      sum={sum}
      to={`/case/${caseId}/edit/chapter/${chapterId}`}
    />
  );
};

const SummaryGroup = ({ title, expenses }: ExpenseSummaryGroup) => (
  <EditPane title={title}>
    {expenses.map(expense => <SummaryItem key={expense.id} {...expense} />)}
  </EditPane>
);

interface Props {
  title: string;
}
export const ExpensesSummary = ({ title }: Props) => {
  const { data } = useSummaryOfCost({ suspense: true }) as { data: ExpenseSummaryGroup[] };

  const allExpenses = data.flatMap(d => d.expenses);
  return (
    <ColumnWidgetWrapper>
      <HeaderPane text={title} icon={<SummaryCost />} />
      <TotalAmount data={allExpenses} />
      {data.map(each => <SummaryGroup key={each.chapterId} {...each} />)}
    </ColumnWidgetWrapper>
  );
};
