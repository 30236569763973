import { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { getWidgetEditComponent } from '@/services/editServices';
import { WidgetType } from '@/types';

const widgetShape = {
  title: PropTypes.string,
  widgetType: PropTypes.string.isRequired,
  widgetId: PropTypes.string
};
export const widgetPropType = PropTypes.shape(widgetShape);

interface WidgetProps {
  title: string;
  widgetId: string;
  widgetType: WidgetType;
}
export const Widget: FunctionComponent<WidgetProps> = ({ title, widgetId, widgetType }) => {
  const Component = getWidgetEditComponent(widgetType);
  return <Component title={title} widgetId={widgetId} />;
};
