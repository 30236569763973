import styled, { css } from 'styled-components';

interface DropdownProps {
  isOpen: boolean;
}
export const DropdownField = styled.div<DropdownProps>`
  position: relative; 
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 4px 16px;
  padding-right: 44px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid;
  background-color: ${props => props.theme.white};
  border-color: ${props => props.theme.border.gray};
  /* box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05); */
  appearance: none;
  cursor: pointer;
  z-index: 35;

  ${props => props.isOpen && css`
    border-color: ${props.theme.strongBlue};
  `};

  &::after {
    content: '';
    position: absolute;
    height: 0.813em;
    width: 0.813em;
    top: 7px;
    right: 18px;
    border-style: solid;
    border-color: ${props => props.theme.marineBlue};
    border-width: 0.1em 0.1em 0 0;
    box-sizing: border-box;
    transform: rotate(135deg);
    pointer-events: none;
    opacity: 0.8;
    ${props => props.isOpen && css`
      opacity: 1;
    `};
  }
`;
