import styled from 'styled-components';
import PropTypes from 'prop-types';
import { hiddenOnPrint } from '@/utils/styles';
import { useCaseStatus } from '@/services/caseStatusServices';
import { useModalContext } from '../../modal/ModalContext';
import { EditIconButton, NavEditIcon } from '../LinkButton/LinkButtons';
import { Pane } from './Pane';
import { childrenPropType, modalPropType } from '../../../prop-types/custom-prop-types';

const EditIcon = styled(NavEditIcon)`
  ${hiddenOnPrint}
`;

const EditButton = ({ caseId, chapterId }) => {
  const { data } = useCaseStatus();
  if (!data || data.isClosed) return null;
  return <EditIcon to={`/case/${caseId}/edit/chapter/${chapterId}`} />;
};

EditButton.propTypes = {
  caseId: PropTypes.string.isRequired,
  chapterId: PropTypes.string.isRequired,
};

const IconButton = ({ open, to, openModal }) => {
  if (to) {
    return <NavEditIcon to={to} color="marineBlue" />;
  }
  return <EditIconButton onClick={() => openModal ? open(openModal) : null} color="marineBlue" />;
};

IconButton.propTypes = {
  openModal: modalPropType,
  to: PropTypes.string,
  open: PropTypes.func.isRequired,
};

export const PreviewReportPane = ({ title, subTitle, caseId, chapterId, children }) => {
  const button = <EditButton caseId={caseId} chapterId={chapterId} />;
  return (
    <Pane title={title} subTitle={subTitle} button={button} color="marineBlue" margin="20px 0">
      {children}
    </Pane>
  );
};

PreviewReportPane.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  caseId: PropTypes.string.isRequired,
  chapterId: PropTypes.string.isRequired,
  children: childrenPropType,
};

export const ReportPane = ({ title, to, open: openModal, children, editable }) => {
  const { data } = useCaseStatus();
  const { open } = useModalContext();

  const showIconButton = editable && data && data.isClosed === false;

  return (
    <Pane title={title} button={showIconButton && <IconButton open={open} to={to} openModal={openModal} />}>
      {children}
    </Pane>
  );
};

ReportPane.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  open: modalPropType,
  children: childrenPropType,
  editable: PropTypes.bool
};
