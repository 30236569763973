import useSWR from 'swr';
import {
  ReportVersion, ArchivedReport, PublishReportOptions
} from '@/utils/archivedReportTypes';
import { Dictionary, Guid } from '@/types';
import client from './client';

// Enabled refresh interval to poll the 'pdfGenerated' value
const refreshInterval = 5000;

const withPdfGeneratedWithFallbackParameters = (version: ReportVersion) => ({
  ...version,
  pdfGeneratedWithFallbackParameters: version.attemptsMadeToGeneratePdf > 1
});

export const useNewestReport = (caseId: string) => {
  const swrRoute = `/case/${caseId}/versions/latest`;
  const swr = useSWR<ReportVersion>(swrRoute, { refreshInterval });

  if (swr.error) {
    throw new Error(swr.error);
  }

  const data = swr.data ? withPdfGeneratedWithFallbackParameters(swr.data) : swr.data;
  return { ...swr, data };
};

export const useNewestPreviewReport = (caseId: string) => {
  const swrRoute = `/case/${caseId}/versions/latest/preview`;

  const swr = useSWR<ReportVersion>(swrRoute, { refreshInterval });

  if (swr.error) {
    throw new Error(swr.error);
  }

  const data = swr.data ? withPdfGeneratedWithFallbackParameters(swr.data) : undefined;
  return { ...swr, data };
};

export const useVersionedReports = (caseId: string) => {
  const swrRoute = `/case/${caseId}/versions`;
  const swr = useSWR<ReportVersion[]>(swrRoute, { refreshInterval });

  if (swr.error) {
    throw new Error(swr.error);
  }

  return { ...swr, data: swr.data?.map(withPdfGeneratedWithFallbackParameters) };
};

export const usePreviewVersionedReports = (caseId: string) => {
  const swrRoute = `/case/${caseId}/versions/preview`;
  const swr = useSWR<ReportVersion[]>(swrRoute, { refreshInterval });

  if (swr.error) {
    throw new Error(swr.error);
  }

  return { ...swr, data: swr.data?.map(withPdfGeneratedWithFallbackParameters) };
};

export const useVersionedReport = (caseId: string, versionId: string, suspense: boolean = false) => {
  const swrRoute = `/case/${caseId}/versions/${versionId}`;
  const swr = useSWR<ArchivedReport>(swrRoute, { refreshInterval, suspense });

  if (swr.error) {
    throw new Error(swr.error);
  }

  return swr;
};

export const publishCurrentReport = async (caseId: string, options: PublishReportOptions, signatureId?: Guid) => {
  const url = `/case/${caseId}/versions/publish`;
  const result = await client.post<ArchivedReport>(url, { ...options, signatureId });
  return result.data;
};

const userNewestReportsUrl = '/versions';

const getUserNewestReportsAsync = async () => {
  const response = await client.get<ReportVersion[]>(userNewestReportsUrl);

  const dict: Dictionary<ReportVersion> = Object
    .fromEntries(response.data.map(version => [version.caseId, version]));

  return dict;
};

export const useUserNewestReports = () => {
  const swr = useSWR(userNewestReportsUrl, getUserNewestReportsAsync);
  return swr;
};
