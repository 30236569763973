import { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { FormikProps } from 'formik';
import {
  dropdownOptionRequired,
  fileRequired, stringRequiredMaxLength, yesNoOption
} from '@/utils/validationSchemas';
import {
  CloseButton,
  SaveButton
} from '@/components/shared/FormButton/FormButton';
import {
  FileStatusOptionType, FileCategory, uploadFileAsync, FileStatusOption
} from '@/services/fileServices';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useAllowedFileExtensions } from '@/services/allowedFileExtensionsServices';
import { YesOrNo } from '@/types';
import { yesNoToBool } from '@/utils/yesNoType';
import { PiiFields } from '@/components/shared/Form/fields/PiiFields';
import { usePrevious } from '@/hooks/usePrevious';
import {
  ButtonGroup, Dropdown, Dropzone, FormField, FormRow, LabelValuePair, TextField, FormikWithPrompt as Formik
} from '@instech/components';
import { isValidFileExtension } from '@/utils/file';

const Container = styled.div`
  background-color: ${props => props.theme.background.primary};
  padding: 15px;
`;

interface FormModel {
  fileName: string;
  fileStatus: LabelValuePair;
  category: LabelValuePair;
  file?: File;
  pii?: YesOrNo;
  piiIncludesEu?: YesOrNo;
}
const initialFormValues: FormModel = {
  fileName: '',
  fileStatus: {
    label: 'Available',
    value: 'Available'
  },
  category: {
    label: 'General information',
    value: 'General information',
  },
  file: undefined,
  pii: undefined,
  piiIncludesEu: undefined
};

export const fileRequiredStatuses = ['Available', 'Enclosed'];
const validEnclosedFileExtensions = ['.pdf'];

const isFileRequired = ({ then, otherwise }: { then: any, otherwise?: any }) =>
  Yup.mixed()
    .when('fileStatus', {
      is: (fileStatus: LabelValuePair) => fileRequiredStatuses.includes(fileStatus.value),
      then,
      otherwise
    });

export const availableInformationModelSchema = (validFileExtensions: string[]) => Yup.object().shape({
  file: isFileRequired({ then: fileRequired(validFileExtensions, false) })
    .when('fileStatus', {
      is: (fileStatus: LabelValuePair) => fileStatus.value === 'Enclosed',
      then: Yup.mixed().test('file', 'Can only enclose .pdf files', value => isValidFileExtension(value?.name, validEnclosedFileExtensions))
    }),
  fileName: stringRequiredMaxLength(),
  fileStatus: dropdownOptionRequired(),
  category: dropdownOptionRequired(),
  pii: isFileRequired({ then: yesNoOption().required('Required') }),
  piiIncludesEu: yesNoOption().when('pii', {
    is: 'Yes',
    then: yesNoOption().required('Required')
  })
});

const FieldsWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  > *:not(:last-child) {
    padding-right: 16px;
  }
`;

const FilenameField = styled.div`
  min-width: 200px;
  max-width: 551px; // CategoryField + StatusField + gutter => 220px + 315px + 16px = 551px
  flex-grow: 1;
`;
interface NewAvailableFormFieldsProps extends FormikProps<FormModel> {
  handleClose?: () => void;
  canClose?: boolean;
  categories?: FileCategory[];
  fileStatusList: FileStatusOption[];
  allowedFileExtensions: string[];
}
const NewAvailableFormFields: FunctionComponent<NewAvailableFormFieldsProps> = ({
  dirty,
  handleSubmit,
  isSubmitting,
  handleClose,
  values,
  categories,
  setValues,
  setTouched,
  canClose,
  resetForm,
  fileStatusList,
  allowedFileExtensions
}) => {
  const showFileUpload = fileRequiredStatuses.includes(values.fileStatus.value);
  const categoryOptions = categories ? categories.map(category => ({ value: category, label: category })) : [];
  const prevFileStatus = usePrevious(values.fileStatus);

  const dropdownOptions = fileStatusList.map(x => ({ value: x.status, label: x.name }));
  const fileTypes = allowedFileExtensions.map(e => e.replace('.', ''));

  // The 'file' and 'pii' fields be hidden on certain file statuses. This resets their values every time
  // the fileStatus field is set to a value that hides those fields.
  useEffect(() => {
    if (prevFileStatus !== values.fileStatus && !fileRequiredStatuses.includes(values.fileStatus.value)) {
      const newValues = {
        ...values,
        file: initialFormValues.file,
        pii: initialFormValues.pii,
        piiIncludesEu: initialFormValues.piiIncludesEu,
      };
      setValues(newValues);
      setTouched({
        file: false,
        pii: false,
        piiIncludesEu: false,
        fileName: false
      });
    }
  }, [prevFileStatus, setTouched, setValues, values, values.fileStatus]);
  return (
    <>
      <FieldsWrapper>
        <FormField width="315px">
          <Dropdown
            name="category"
            label="Category"
            options={categoryOptions}
          />
        </FormField>
        <FormField width="220px">
          <Dropdown
            name="fileStatus"
            label="Status"
            options={dropdownOptions}
          />
        </FormField>
        <FilenameField>
          <TextField
            placeholder="Filename"
            name="fileName"
            label="Document name"
            />
        </FilenameField>
      </FieldsWrapper>

      {showFileUpload && (
        <>
          <FormRow marginBottom="20px">
            <FormField>
              <Dropzone name="file" filetypes={fileTypes} label="Click or drop file to upload" />
            </FormField>
          </FormRow>
          <PiiFields />
        </>
      )}

      <ButtonGroup alignRight>
        { canClose && <CloseButton onClick={handleClose} label="Cancel" /> }
        <CloseButton label="Reset" onClick={resetForm} disabled={!dirty} />
        <SaveButton disabled={!dirty} isSubmitting={isSubmitting} onClick={handleSubmit} />
      </ButtonGroup>
    </>
  );
};

interface Props {
  categories: FileCategory[];
  fileStatusList: FileStatusOption[];
  handleClose?: () => void;
  canClose?: boolean;
  setError: (error: Error) => void;
}
export const NewAvailableForm: FunctionComponent<Props> = ({ categories, fileStatusList, handleClose, canClose, setError }) => {
  const caseId = useCaseIdFromUrl();
  const allowedFileExtensions = useAllowedFileExtensions();

  return (
    <Container>
      <Formik
        initialValues={{ ...initialFormValues }}
        validationSchema={availableInformationModelSchema(allowedFileExtensions)}
        onSubmit={async (values, { setSubmitting }) => {
          const newItem = {
            status: values.fileStatus.value as FileStatusOptionType,
            title: values.fileName,
            file: values.file ? values.file as File : undefined,
            category: values.category.value as FileCategory,
            pii: values.pii === 'Yes',
            piiIncludesEu: yesNoToBool(values.piiIncludesEu)
          };
          try {
            await uploadFileAsync(caseId, newItem);
            setSubmitting(false);
            if (handleClose) {
              handleClose();
            }
          } catch (err: any) {
            setSubmitting(false);
            setError(err);
          }
        }}
      >
        {props => (
          <NewAvailableFormFields
            {...props}
            handleClose={handleClose}
            categories={categories}
            canClose={canClose}
            fileStatusList={fileStatusList}
            allowedFileExtensions={allowedFileExtensions}
          />
        )}
      </Formik>
    </Container>
  );
};
