import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Form } from 'formik';
import { useModalContext } from '@/components/modal/ModalContext';
import {
  Loader, BoxButton, ButtonGroup,
  Divider,
  FormField,
  FormikWithPrompt as Formik
} from '@instech/components';
import { useSubcategoriesService } from '@/services/descriptionService';
import { getOccurrencePrefixTitle } from '@/utils/text';
import { ErrorMessage } from '../Form/core/Components';
import { Checkbox } from '../Form/FormFields';

const formSchema = Yup.object().shape({
  options: Yup.array().min(1, 'At least one category must be checked.')
});

const Indent = styled.div`
  background-color: ${props => props.theme.flatWhite};
  padding: 12px 16px;
  margin: 10px 0px;
  font-weight: bold;
`;

const CheckboxField = ({ widgetId, title, occurrenceId }) => {
  const label = getOccurrencePrefixTitle(title, occurrenceId);
  return (
    <FormField key={widgetId}>
      <Checkbox
        fieldId={`options.${widgetId}`}
        name="options"
        value={widgetId}
        rightLabel={label}
        noErrors
      />
    </FormField>
  );
};
CheckboxField.propTypes = {
  widgetId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  occurrenceId: PropTypes.string
};

const isWorkNotConcerningAvgCategory = choice => choice.showTitle;

export const SubcategoriesForm = ({ choices, selected, onSubmit }) => (
  <Formik
    initialValues={{ options: selected }}
    validationSchema={formSchema}
    onSubmit={values => {
      onSubmit(values.options);
    }}
  >
    {({ touched, errors, handleSubmit }) => (
      <Form>
        {choices.filter(x => x.occurrenceId == null && !isWorkNotConcerningAvgCategory(x)).map(CheckboxField)}
        <Indent>Show unique sections for:</Indent>
        {choices.filter(x => x.occurrenceId != null).map(CheckboxField)}
        {choices.filter(x => isWorkNotConcerningAvgCategory(x)).map(CheckboxField)}
        <ErrorMessage>
          {touched.options && errors.options}
        </ErrorMessage>
        <Divider />
        <ButtonGroup alignRight>
          <BoxButton onClick={handleSubmit}>Done</BoxButton>
        </ButtonGroup>
      </Form>
    )}
  </Formik>
);
SubcategoriesForm.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.any),
  selected: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func.isRequired
};

export const SubcategoriesDialog = ({ caseId, chapterId }) => {
  const { close } = useModalContext();
  const { subcategories, update } = useSubcategoriesService(caseId, chapterId);
  if (!subcategories) {
    return <Loader />;
  }

  const submitHandler = options => {
    const selectedChoices = subcategories.map(({ widgetId, selected, ...rest }) => ({
      widgetId,
      selected: options.includes(widgetId),
      ...rest
    }));
    void update(selectedChoices);
    close();
  };

  const selected = subcategories.filter(c => c.selected).map(c => c.widgetId);

  return (
    <SubcategoriesForm choices={subcategories} selected={selected} onSubmit={submitHandler} />
  );
};
SubcategoriesDialog.propTypes = {
  caseId: PropTypes.string.isRequired,
  chapterId: PropTypes.string.isRequired
};
