import styled from 'styled-components';
import PropTypes from 'prop-types';

const Pane = styled.div`
  display: flex;
  background-color: ${props => props.theme.white};
  padding: 12px 24px 12px 12px;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
`;

const Content = styled.div`
  margin: 3px 0px 5px 10px;
  font-size: 16px;
  font-weight: bold;
`;

export const HeaderPane = ({ text, icon: Icon }) => (
  <Pane>
    {Icon}
    <Content>{text}</Content>
  </Pane>
);

HeaderPane.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired
};
