import { FunctionComponent } from 'react';
import { useStatusTypes } from '@/services/configurationServices';
import { ButtonLoader } from '@/components/shared/Loader';
import { RadioButtons } from './RadioButtons';

interface Props {
  name: string;
  label?: string;
  customId?: string;
}
export const StatusTypeRadio: FunctionComponent<Props> = props => {
  const { data } = useStatusTypes();

  if (!data) {
    return <ButtonLoader />;
  }

  const options = data.map(({ name }) => name);

  return (
    <RadioButtons
      name={props.name}
      label={props.label}
      customId={props.customId}
      options={options}
    />
  );
};
