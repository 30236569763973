import { matchPath, useLocation } from 'react-router-dom';

function getReportIdFromPathname(pathname: string) {
  const match = matchPath<{id: string, reportId: string}>(pathname, { path: '/case/:id/report/:reportId' });

  if (match) {
    return match.params.reportId;
  }
  return null;
}

function useReportIdFromUrl() {
  const location = useLocation();
  return location && location.pathname && getReportIdFromPathname(location.pathname);
}

export { useReportIdFromUrl, getReportIdFromPathname };
