import { FunctionComponent } from 'react';
import { useCompanies } from '@/services/companiesService';
import { Guid } from '@/types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Loader } from '@instech/components';
import { Page } from '@/components/layout/Page';

const ContentArea = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 16px;
`;

const LinkEntry = styled(Link)`
  border: 2px solid lightgray;
  margin: 8px 0;
  padding: 8px 16px;
  width: 100%;
  display: flex;
  background: #fefefe;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
`;
interface Company {
  id: Guid;
  name: string;
}

const CompanyLink: FunctionComponent<Company> = ({ id, name }) =>
  (
    <LinkEntry key={id} to={`/companies/${id}`}>
      {name}
    </LinkEntry>
  );
export const CompaniesPage: FunctionComponent = () => {
  const { data, error } = useCompanies();
  if (error) {
    throw new Error(error);
  }
  if (!data) return <Loader />;

  return (
    <Page title="Companies">
      <ContentArea>
        {data.map(company => <CompanyLink key={company.id} id={company.id} name={company.name} />)}
      </ContentArea>
    </Page>
  );
};
