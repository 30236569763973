import { FullwidthWidgetWrapper } from '@/components/pages/edit/widgets/FullwidthWidgetWrapper';
import { refreshCachedManifest } from '@/services/manifestServices';
import {
  deleteSurveyOccurrenceAsync,
  updateSurveyOccurrencesAsync,
  useOccurrences
} from '@/services/occurrenceServices';
import { Guid, Occurrence } from '@/types';
import { useParams } from 'react-router-dom';
import { NotifyTooltip } from '@/components/shared/Tooltip';
import { EditPane } from '../../core/EditPane';
import { OccurrenceTable } from './OccurrenceTable';

export const Occurrences = ({ title }: { title: string }) => {
  const { id: caseId } = useParams<{ id: Guid }>();

  const { data, mutate } = useOccurrences({ suspense: true });
  const occurrenceList = data as Occurrence[];

  const onSaveHandler = async (newOccurrences: Occurrence[]) => {
    const result = await updateSurveyOccurrencesAsync(caseId, newOccurrences);
    await mutate(result, false);
    await refreshCachedManifest(caseId);
    return result;
  };

  const onDelete = async (occurrenceId: string) => {
    await deleteSurveyOccurrenceAsync(caseId, occurrenceId);
    await refreshCachedManifest(caseId);
    await mutate(existingData => {
      const newData = existingData?.filter(x => x.id !== occurrenceId);
      return newData;
    }, false);
  };

  return (
    <FullwidthWidgetWrapper>
      <NotifyTooltip
        id="edit-occurrences-tooltip"
        place="left"
        offset={{ left: -8 }}
        bodyMode
      />
      <EditPane title={title}>
        <OccurrenceTable
          occurrences={occurrenceList}
          onSave={onSaveHandler}
          onDeleteOnServer={onDelete}
        />
      </EditPane>
    </FullwidthWidgetWrapper>
  );
};
