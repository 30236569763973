import { useState } from 'react';
import { Form } from 'formik';
import styled from 'styled-components';
import { SaveButton } from '@/components/shared/FormButton/FormButton';
import {
  FormikWithPrompt as Formik,
  ButtonGroup,
  PageLoader
} from '@instech/components';
import { AreaLoader } from '@/components/shared/Loader';
import { useCurrentUserInfo } from '@/services/userInfoService';
import {
  ClaimsData,
  CreateCaseModel,
  getClaimsDataByClaimNumberAsync,
  useTemplateDefinitions
} from '../admin/adminServices';
import { Title } from './core/Components';
import { VesselInfoFields } from './core/VesselInfoFields';
import { CompanyParticularsFields } from './core/CompanyParticularsFields';
import { OccurrenceFields } from './core/OccurrenceFields';
import {
  getOccurrenceValues, createCaseSchema, createCaseInitialValues, CreateCaseFormModel
} from './utils';
import { BrokerFields } from './core/BrokerFields';
import { UsersFields } from './core/UsersFields';
import { ClaimSearch } from './core/ClaimSearch';
import { CoreFields } from './core/CoreFields';
import { InstructionsField } from './core/InstructionsField';
import { AutoEmailNotification } from './core/AutoEmailNotification';

const FormArea = styled.div`
  padding-top: 60px;
  padding-bottom: 40px;
`;

// Null values will be caught by the validation scheme
const mapToPayload = ({ metadata, template, users, ...rest }: CreateCaseFormModel): CreateCaseModel => ({
  ...rest,
  templateId: template,
  metadata: {
    ...metadata,
    conditions: metadata.conditions!.value,
  },
  users: {
    surveyors: users.surveyors.map(user => user.id),
    primarySurveyorId: users.primarySurveyorId,
    primaryClaimsHandlerId: users.primaryClaimsHandlerId
  }
});

interface CreateCaseFormProps {
  onCreate: (model: CreateCaseModel) => Promise<boolean>
}

export const CreateCaseForm = ({ onCreate }: CreateCaseFormProps) => {
  const [dataImported, setDataImported] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { data: currentUser } = useCurrentUserInfo();
  const templates = useTemplateDefinitions();

  const getClaimsData = async (data: CreateCaseFormModel, resetForm: any, setFieldError: any) => {
    const { claimNumber, year } = data;
    if (!claimNumber || !year) return;

    // before fetching, set component to fetching state
    setIsFetching(true);

    const result = await getClaimsDataByClaimNumberAsync(claimNumber, year);

    if (result.status === 'Success') {
      const claimsData = result.data as ClaimsData;
      const newData: CreateCaseFormModel = {
        template: data.template,
        claimId: claimsData.claimId,
        name: claimsData.name,
        metadata: {
          ...claimsData.metadata,
          conditions: {
            label: claimsData.metadata.conditions,
            value: claimsData.metadata.conditions,
          }
        },
        instructions: data.instructions || '',
        companyParticulars: claimsData.companyParticulars,
        vesselInformation: claimsData.vesselInformation,
        occurrence: getOccurrenceValues(claimsData.occurrence),
        claimNumber: data.claimNumber,
        year: data.year,
        users: data.users,
        activeClaimWall: claimsData.activeClaimWall,
        broker: claimsData.broker
      };
      setDataImported(true);
      resetForm({ values: newData });
    } else {
      setFieldError('claimNumber', 'Invalid claim number / year');
      setDataImported(false);
    }

    // after data has been fetched and imported, exit fetching state
    setIsFetching(false);
  };

  if (!templates || !currentUser) {
    return <PageLoader />;
  }

  const defaultTemplate = templates[0];
  const initialValuesWithDefaultTemplate = createCaseInitialValues(defaultTemplate.id, currentUser);

  return (
    <FormArea>
      <Title>Create New Case</Title>
      <Formik
        initialValues={initialValuesWithDefaultTemplate}
        validationSchema={createCaseSchema(templates)}
        onSubmit={async (newValues, { resetForm }) => {
          try {
            const payload = mapToPayload(newValues);
            await onCreate(payload);
            resetForm({ values: initialValuesWithDefaultTemplate });
            setDataImported(false);
          } catch {
            // eslint-disable-next-line no-console
            console.error('Could not submit data.', newValues);
          }
        }}
      >
        {({ values, handleSubmit, isSubmitting, dirty, resetForm, setFieldError }) => (
          <Form>
            <ClaimSearch onClaimSearch={() => getClaimsData(values, resetForm, setFieldError)} />
            {isFetching && (
              <AreaLoader />
            )}
            {(!isFetching && dataImported) && (
              <>
                <CoreFields />
                <VesselInfoFields />
                <CompanyParticularsFields />
                <BrokerFields />
                <InstructionsField currentTemplateId={defaultTemplate.id} />
                <OccurrenceFields />
                <UsersFields
                  claimId={values.claimId}
                  activeClaimWall={values.activeClaimWall}
                />
                <AutoEmailNotification />
                <ButtonGroup marginTop="32px">
                  <SaveButton
                    label="Submit"
                    disabled={!dirty}
                    isSubmitting={isSubmitting}
                    onClick={handleSubmit}
                  />
                </ButtonGroup>
              </>
            )}
          </Form>
        )}
      </Formik>
    </FormArea>
  );
};
