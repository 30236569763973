import * as Yup from 'yup';

export const OccurrenceValidator = Yup.object({
  occurrenceName: Yup.string().required('Please enter an occurrence name.').max(110, 'Cannot be longer than 110 characters'),
  caseId: Yup.string().required(),
  charter: Yup.object({ label: Yup.string(), value: Yup.string() }).nullable().optional(),
  selectedClaims: Yup.array(Yup.string().required()).min(1, 'Please select at least one claim.').required(),
});

export type OccurrenceSchema = Yup.InferType<typeof OccurrenceValidator>;
