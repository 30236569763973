import {
  Dropdown, FormField, FormRow
} from '@instech/components';
import { FC } from 'react';
import { useFormikContext } from 'formik';
import { TypeSection } from '../../NewEventForm/Components';
import { ToggleButtons } from './ToggleButtons';
import { EventFormModel, eventTypeValues } from '../types';

interface Props {
  dropdownOptions: any; // TODO: Properly type this value
}
export const EventFormTypeSelection: FC<Props> = ({ dropdownOptions }) => {
  const { values, setFieldValue } = useFormikContext<EventFormModel>();

  const toggleEventType = (eventType: string) => {
    if (eventType === values.eventType) return;
    void setFieldValue('movementType', '');
    void setFieldValue('typeOfActivity', '');
  };

  const toggleSubType = (eventType: string) => {
    const checkType = eventType !== 'Other' ? eventType : '';
    void setFieldValue('movementType', eventType);
    void setFieldValue('typeOfActivity', { label: checkType, value: checkType });
  };

  const typeMovement = values.eventType === 'Vessel Movement';
  const typeEvent = values.eventType === 'Event';
  const typeStatus = values.eventType === 'Vessel Status';
  const subTypeOther = values.movementType === 'Other';

  return (
    <TypeSection>
      <FormRow marginBottom="10px">
        <FormField>
          <ToggleButtons
            name="eventType"
            label="Type of activity"
            options={eventTypeValues}
            onClickCallback={toggleEventType} />
        </FormField>
      </FormRow>
      {typeMovement && (
        <FormRow marginBottom="10px">
          <FormField span={12}>
            <ToggleButtons
              name="movementType"
              label="Type of vessel movement"
              options={['Departure', 'Arrival', 'Other']}
              onClickCallback={toggleSubType} />
          </FormField>
        </FormRow>
      )}
      {typeMovement && subTypeOther && (
        <FormRow marginBottom="10px">
          <FormField span={12}>
            <Dropdown
              name="typeOfActivity"
              options={dropdownOptions.VesselMovement}
              placeholder="Select an option" />
          </FormField>
        </FormRow>
      )}
      {typeEvent && (
        <FormRow marginBottom="10px">
          <FormField span={12}>
            <Dropdown
              name="typeOfActivity"
              options={dropdownOptions.Event}
              placeholder="Select an option" />
          </FormField>
        </FormRow>
      )}
      {typeStatus && (
        <FormRow marginBottom="10px">
          <FormField span={12}>
            <Dropdown
              name="typeOfActivity"
              options={dropdownOptions.Status}
              placeholder="Select an option" />
          </FormField>
        </FormRow>
      )}
    </TypeSection>
  );
};
