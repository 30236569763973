import { useOccurrenceOptionsService } from '@/services/occurrenceServices';
import { LabelValuePair, Option } from '@/types';
import { Dropdown } from '@instech/components';
import { useField } from 'formik';
import { DropdownLoader } from './core';

const mapOptionToLabelValue = (option: Option) => ({
  value: option.id,
  label: option.name
});

export const getLabelValuePairs = (options: Option[] | undefined) =>
  options?.map(mapOptionToLabelValue) || [];

const mapLabelValueToOption = (labelValue: LabelValuePair) => ({
  id: labelValue.value,
  name: labelValue.label
});

export const getOptions = (pairs: LabelValuePair[] | undefined | null) =>
  pairs?.map(mapLabelValueToOption) || [];

interface OccurrenceSelectProps {
  label?: string;
  name: string;
}
export const OccurrenceSelect = ({ label, name }: OccurrenceSelectProps) => {
  const [field] = useField<LabelValuePair[]>(name);

  const occurrenceOptions = useOccurrenceOptionsService();

  if (!occurrenceOptions) {
    return <DropdownLoader />;
  }

  const options = getLabelValuePairs(occurrenceOptions);

  return (
    <Dropdown
      label={label}
      noLabel={!label}
      name={field.name}
      options={options}
      multiDropdown
    />
  );
};
