import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { IconButton, TruncatingText } from '@instech/components';

const ButtonWrapper = styled.div<{ lineLeft?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 10px;

  ${props => props.lineLeft && css`
    border-left: 1px solid;
    border-left-color: ${props.theme.lightGray};
  `};
`;

interface CellButtonProps {
  lineLeft?: boolean;
  smallIcon?: boolean;
  icon: any;
  onClick?: () => any;
  disabled?: boolean;
}
export const CellButton: FunctionComponent<CellButtonProps> = ({ lineLeft, smallIcon, icon, onClick, disabled }) => (
  <ButtonWrapper lineLeft={lineLeft}>
    <IconButton
      icon={icon}
      smallIcon={smallIcon}
      onClick={onClick}
      width="40px"
      height="40px"
      disabled={disabled}
    />
  </ButtonWrapper>
);

interface TableCellProps {
  span?: string;
  disabled?: boolean;
  right?: boolean;
  center?: boolean;
  button?: boolean;
  checkbox?: boolean;
  ticker?: boolean;
  showError?: boolean;
}
export const TableCell = styled.span<TableCellProps>`
  display: flex;
  flex-direction: column;
  grid-column: ${props => props.span ? `span ${props.span}` : ''};
  box-sizing: border-box;
  padding: ${props => (
    ((props.checkbox || props.ticker) && '16px 10px') ||
    (props.button && '16px 0px') ||
    '16px 20px'
  )};
  ${props => props.showError && css`
    padding-bottom: 5px;
  `};

  ${props => props.disabled && css`
    justify-content: flex-start;
    ${props.right && css`
      align-items: flex-end;
    `};
    ${props.center && css`
      padding: 0px 12px 0px 12px; 
      align-items: center;
    `};
  `};
`;

// This component supports wrapping a TruncatingText component.
export const TruncatingTableCell = styled(TableCell)`
  overflow: hidden;
`;

interface TruncatingTextTableCellProps {
  text: string;
  width?: string;
  padding?: string;
  wrap?: boolean;
}
export const TruncatingTextTableCell: FunctionComponent<TruncatingTextTableCellProps> = ({ text, width, padding, wrap }) => (
  <TruncatingTableCell>
    <TruncatingText text={text} padding={padding} wrap={wrap} width={width} />
  </TruncatingTableCell>
);
