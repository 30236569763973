import { Contact } from '@/types';
import useSWR, { mutate } from 'swr';
import client from './client';

const contactsUrl = (caseId: string) => `case/${caseId}/contacts`;

export const useContacts = (caseId: string) => {
  const swrRoute = contactsUrl(caseId);

  const { data, error } = useSWR<Contact[]>(swrRoute);

  if (error) {
    throw error;
  }

  return data;
};

export const mutateContactsAsync = async (caseId: string, contacts: Contact[]) =>
  mutate(contactsUrl(caseId), contacts);

export const addContactAsync = async (caseId: string, contact: Contact) => {
  const url = contactsUrl(caseId);
  const result = await client.post<Contact>(url, contact);
  return result.data;
};

export const removeContactAsync = async (caseId: string, contactId: string) => {
  const url = `${contactsUrl(caseId)}/${contactId}`;
  await client.delete(url);
};

export const updateContactAsync = async (caseId: string, contact: Contact) => {
  const url = `${contactsUrl(caseId)}/${contact.id}`;
  await client.put(url, contact);
};
