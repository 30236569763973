import styled from 'styled-components';
import { addHeaderToTopOnPrint } from '@/utils/styles';

export const Grid = styled.div`
  position: relative;
  display: grid;
  min-height: 100vh;
  margin: 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.tabletMax}) {
    grid-template-columns: minmax(0,1fr);
    grid-template-rows: 50px 50px 1fr;
    grid-template-areas:
      "header"
      "sidebar"
      "content";
  }
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-rows: 50px auto;
    grid-template-columns: 256px minmax(0,1fr);
    grid-template-areas:
      "header header"
      "sidebar content";
  } 
  @media print {
    display: block;
  }
`;

export const Header = styled.div`
  grid-area: header;
  position: sticky;
  top: 0;
  background: ${props => props.theme.marineBlue};
  color: ${props => props.theme.white};
  z-index: ${props => props.theme.zIndex.header};
  ${addHeaderToTopOnPrint}
`;

export const Sidebar = styled.div` 
  grid-area: sidebar;
  border-right: 1px solid ${props => props.theme.border.white};
  background-color: ${props => props.theme.white};
  z-index: ${props => props.theme.zIndex.sidebar};
  @media (max-width: ${props => props.theme.breakpoints.tabletMax}) {
    position: sticky; 
    top: 50px;
  }
`;

interface ContentProps {
  readonly visible: boolean;
}
export const Content = styled.div<ContentProps>`
  grid-area: content;
  background-color: ${props => props.theme.background.primary};
  @media (max-width: ${props => props.theme.breakpoints.tabletMax}) {
    display: ${props => props.visible ? 'initial' : 'none'};
  }
`;
