import styled from 'styled-components';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import {
  FormField, TextField, FormikWithPrompt as Formik
} from '@instech/components';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import { contactSchema } from './contactSchema';

const StyledForm = styled(Form)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @media screen and (max-width: 899px) {
    flex-wrap: wrap;
  }
`;

const FormFields = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  width: 100%;

  @media screen and (max-width: 899px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px 40px;
  }

  @media screen and (max-width: 599px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const NewContactForm = ({ submit, onClose }) => (
  <Formik
    initialValues={{
      name: '',
      function: '',
      phone: '',
      email: '',
    }}
    validationSchema={contactSchema}
    onSubmit={async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      await submit(values);
      resetForm();
      setSubmitting(false);
    }}>
    {({ handleSubmit, isSubmitting, dirty }) => (
      <>
        <StyledForm>
          <FormFields>
            <FormField>
              <TextField
                name="name"
                label="Name"
                placeholder="Name"
              />
            </FormField>
            <FormField>
              <TextField
                name="function"
                label="Function"
                placeholder="Function"
              />
            </FormField>
            <FormField>
              <TextField
                name="phone"
                label="Phone"
                placeholder="Phone"
              />
            </FormField>
            <FormField>
              <TextField
                name="email"
                label="Email"
                placeholder="Email"
              />
            </FormField>
          </FormFields>
        </StyledForm>
        <SaveCloseButtons
          saveLabel="Save"
          closeLabel="Cancel"
          onSave={handleSubmit}
          disableSave={!dirty}
          isSubmitting={isSubmitting}
          onClose={onClose}
          alignRight
          reverse
        />
      </>
    )}
  </Formik>
);

NewContactForm.propTypes = {
  submit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
