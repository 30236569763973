/* eslint-disable react/no-array-index-key */
import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { CertificateListItem } from '@/services/certificatesServices';
import {
  FormField, FormRow, TextField, DatePicker
} from '@instech/components';
import { RemoveButton } from '../../../shared/Buttons';

interface ButtonWrapperProps {
  labelSpace?: boolean;
}
const ButtonWrapper = styled.div<ButtonWrapperProps>`
  /* When neighbouring Field has label, add space */ 
  ${props => props.labelSpace && css`
    margin-top: 27px;
  `}
`;

interface Props {
  arrayName: string;
  entries: CertificateListItem[];
  nameLabel: string;
  dateLabel: string;
  placeholder: string;
  arrayHelpers: any;
}
export const CertificateList: FunctionComponent<Props> = ({ arrayName, entries, nameLabel, dateLabel, placeholder, arrayHelpers }) => (
  <div>
    {entries.map((item, index) => (
      <FormRow key={index} gutter="10px" marginBottom="6px">
        <FormField>
          <TextField
            name={`${arrayName}.${index}.name`}
            placeholder={placeholder}
            label={index === 0 ? nameLabel : undefined}
            noLabel={index !== 0}
          />
        </FormField>
        <FormField width="200px">
          <DatePicker
            name={`${arrayName}.${index}.date`}
            label={index === 0 ? dateLabel : undefined}
          />
        </FormField>
        <ButtonWrapper labelSpace={index === 0}>
          <RemoveButton onClick={() => arrayHelpers.remove(index)} />
        </ButtonWrapper>
      </FormRow>
    ))}
  </div>
);
