import { FunctionComponent, useState } from 'react';
import { useModalContext } from '@/components/modal/ModalContext';
import { BoxButton } from '@instech/components';
import styled from 'styled-components';
import { SmallLoader } from '../Loader';

const Content = styled.div`
  margin-bottom: 1.6rem;
  font-size: 16px;
  font-weight: bold;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  & > :first-child {
    margin-right: 16px;
  }
`;
interface Props {
  name?: string;
  content?: JSX.Element;
  callback: () => void | Promise<void>;
}
export const ConfirmDeleteDialog: FunctionComponent<Props> = ({
  name,
  callback,
  content,
}) => {
  const { close } = useModalContext();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  if (error) {
    throw new Error(error);
  }

  const itemName = name ? `"${name}"` : 'this item';

  const handleDelete = async () => {
    const result = callback() as any;
    if (result instanceof Promise) {
      try {
        setSubmitting(true);
        await result;
        setSubmitting(false);
        close();
      } catch (err) {
        setError((err ?? 'Something went wrong') as string);
        setSubmitting(false);
      }
    } else {
      close();
    }
  };

  return (
    <>
      {!content && (
        <Content>{`Are you sure you want to delete ${itemName}?`}</Content>
      )}
      {content || null}
      <ButtonGroup>
        <BoxButton inverted onClick={close}>
          Cancel
        </BoxButton>
        <BoxButton onClick={handleDelete} disabled={submitting} width="88px">
          {submitting ? <SmallLoader /> : 'Delete'}
        </BoxButton>
      </ButtonGroup>
    </>
  );
};
