import styled from 'styled-components';
import { BoatFront } from '@instech/icons';

const ShipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 40px;
  padding-top: 8px;
  & svg {
    transform: scale(1.6665);
  }
`;

export const TimelineShip = () => (
  <ShipContainer>
    <BoatFront />
  </ShipContainer>
);
