import { LabelValuePair } from '@instech/components';

export interface EventFormModel {
  fieldSchema: any;
  eventType: string;
  movementType: string | null;
  typeOfActivity: LabelValuePair | null;
  location: LabelValuePair | null;
  eventDate: string | null;
  eventTime: string;
  timezoneId: string;
  timeStatus: string;
  vesselStatus: string;
  customActivityName: string;
  occurrences: LabelValuePair[];
  comment: string;
  includeInReport: boolean;
}

export const eventTypes = {
  vesselMovement: 'Vessel Movement',
  event: 'Event',
  vesselStatus: 'Vessel Status'
};

export const eventTypeValues = Object.values(eventTypes);
