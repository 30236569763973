import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { reportPreviewRoute, dashboardRoute } from '@/utils/routeTemplates';
import { useEditContext } from '../../pages/edit/core/EditContext';
import { ChapterExpander } from './core/Chapter/ChapterExpander';
import { EditTools } from './core/EditTools';
import { FeedbackButton } from './core/FeedbackButton';
import { NavigationHeader } from './core/NavigationHeader';
import { ReportLink } from './core/ReportLink';
import {
  NavContainer, NavWrapperOuter, NavWrapperInner, ToolsContainer, NavSections
} from './core/Components';

export const EditNav = ({ onMobileOpen }) => {
  const { toc: { parts } } = useEditContext();
  const [showContents, setShowContents] = useState(true);
  const location = useLocation();
  const { hash } = useLocation();
  const { id: caseId } = useParams();

  useEffect(() => {
    setShowContents(true);
    onMobileOpen(false);
    let id;
    if (!hash) {
      id = setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }, 600);
    }
    return () => {
      if (id) clearTimeout(id);
    };
  }, [onMobileOpen, location, hash]);

  const onOpenMenu = () => {
    setShowContents(!showContents);
    onMobileOpen(showContents);
  };

  return (
    <NavContainer>
      <NavigationHeader title="Back to vessel dashboard" to={dashboardRoute(caseId)} menuOpen={!showContents} onClick={onOpenMenu} />
      <NavWrapperOuter data-testid="navigation-section-wrapper" visible={!showContents}>
        <NavWrapperInner>
          <ToolsContainer>
            <EditTools />
            <FeedbackButton />
          </ToolsContainer>
          <NavSections>
            {parts.map(part => <ChapterExpander key={part.title} title={part.title} entries={part.chapters} />)}
            <ReportLink to={reportPreviewRoute(caseId)} />
          </NavSections>
        </NavWrapperInner>
      </NavWrapperOuter>
    </NavContainer>
  );
};
EditNav.propTypes = {
  onMobileOpen: PropTypes.func.isRequired
};
