import { FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  BoxButton, ButtonGroup, Divider
} from '@instech/components';
import { ComingSoon as RowingIcon } from '@instech/icons';
import { useModalContext } from '@/components/modal/ModalContext';

const Title = styled.h2`
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 29px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
`;

const Text = styled.p`
  margin: 0px;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
  padding-bottom: 1.5rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
`;

interface Props {
  title: string;
  textMessage: string;
}
export const ErrorModal: FunctionComponent<Props> = ({ title, textMessage }) => {
  const { close } = useModalContext();
  return (
    <div>
      <Content>
        <RowingIcon />
        <Title>{title}</Title>
        <Text>{textMessage}</Text>
      </Content>
      <Divider />
      <ButtonGroup alignRight>
        <BoxButton onClick={close}>Done</BoxButton>
      </ButtonGroup>
    </div>
  );
};
