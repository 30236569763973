import styled from 'styled-components';
import { useTimeDetails } from '@/components/pages/edit/widgets/TimeDetails/timeDetailsService';
import { useOccurrencesForCase } from '@/services/occurrenceServices';
import { Manifest } from '@/utils/manifestTypes';
import { numberToDays } from '@/utils/text';
import {
  Table, TableHeader, TableRow, TableCell
} from '@/components/shared/Table';
import { ArchivedReport } from '@/utils/archivedReportTypes';
import { TimeDetails, claimInterests } from '@/types';

const chapterName = 'Work not Concerning Average';
const widgetType = 'TimeDetails';

const Wrapper = styled.div`
  margin-top: 20px;
`;

export const getSimultaneousRepairsMetadata = (manifest: Manifest) => {
  const chapter = manifest.parts
    .flatMap(x => x.chapters)
    .find(c => c.title === chapterName);

  const chapterId = chapter?.id;

  const widgetId = chapter?.widgets
    .find(x => x.widgetType === widgetType)?.widgetId;

  if (!chapterId || !widgetId) return null;

  return { chapterId, widgetId };
};

export const getSimultaneousRepairsData = (report: ArchivedReport) => {
  const timeDetails = report.parts
    .flatMap(x => x.chapters)
    .find(c => c.title === chapterName)
    ?.widgets.find(x => x.widgetType === widgetType)?.data as unknown as TimeDetails;

  return timeDetails.simultaneousRepairs;
};

const Warning = styled.div`
  margin-bottom: 20px;
`;

export const SimultaneousRepairsView = ({ days, showWarning }: { days?: number; showWarning?: boolean; }) => (
  <Wrapper>
    {showWarning && <Warning>This part will only be shown in reports published as LOH:</Warning>}
    <Table layout="auto 12%">
      <TableHeader />
      <TableHeader>Total</TableHeader>
      <TableRow even>
        <TableCell>Time for Simultaneous Repairs</TableCell>
        <TableCell>{numberToDays(days)}</TableCell>
      </TableRow>
    </Table>
  </Wrapper>
);

interface Props {
  caseId: string;
  chapterId: string;
  widgetId: string;
}
export const SimultaneousRepairsContainer = ({ caseId, chapterId, widgetId }: Props) => {
  const { data: timeDetails } = useTimeDetails(caseId, chapterId, widgetId, { suspense: false });
  const { data: occurrences } = useOccurrencesForCase(caseId);

  if (!occurrences || !timeDetails) return null;
  const interests = occurrences.flatMap(x => x.interests.flatMap(i => i.claimInterest));
  const someOccurrencesLOH = interests.includes(claimInterests.lossOfHire);
  const allOccurrencesLOH = interests.every(o => o === claimInterests.lossOfHire);
  if (timeDetails.simultaneousRepairs && someOccurrencesLOH) {
    return (
      <SimultaneousRepairsView
        days={timeDetails.simultaneousRepairs.days}
        showWarning={!allOccurrencesLOH}
      />
    );
  }

  return null;
};
