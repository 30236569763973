/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent } from 'react';
import styled, { useTheme } from 'styled-components';
import { Check, Warning } from '@instech/icons';
import { LinkButton } from '@instech/components';
import { RestoreState } from './useRestoreData';

const Wrapper = styled.div`
  position: absolute;
  right: 36px;
  bottom: 0;
`;

const LinkBox = styled.div`
  padding: 15px 30px;
  border-radius: 5px;
  background: ${props => props.theme.blue};
  color: white;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
`;

const Text = styled.span`
  color: ${props => props.theme.lightGray};
`;

const PendingContent = () => <Text>Restoring item...</Text>;

const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.lightGray};
`;
const SuccessIcon = styled(Check)`
  margin-right: 6px;
`;
const SuccessContent = () => (
  <IconTextWrapper>
    <SuccessIcon />
    Item is restored
  </IconTextWrapper>
);

const ErrorIcon = styled(Warning)`
  margin-right: 6px;
`;
const ErrorContent = () => (
  <IconTextWrapper>
    <ErrorIcon />
    Could not restore item
  </IconTextWrapper>
);

const InitialContent: FunctionComponent<{onRestore?: () => void}> = ({ onRestore }) => {
  const theme = useTheme();
  return (
    <>
      <Text>The item has been deleted.</Text>
      <LinkButton onClick={onRestore} color={theme.mediumBlue}>
        Undo?
      </LinkButton>
    </>
  );
};

const Content: FunctionComponent<RestoreState> = ({
  success,
  error,
  pending,
  onRestore
}) => {
  if (success) {
    return <SuccessContent />;
  }
  if (error) {
    return <ErrorContent />;
  }
  if (pending) {
    return <PendingContent />;
  }
  return <InitialContent onRestore={onRestore} />;
};

/**
 * Popup to restore an element after it has been deleted.
 *
 * The component has no positioning styling. If you want positioning,
 * use 'RestoreDeletedItemPopup' instead.
 */
export const RestoreDeletedItemLink: FunctionComponent<RestoreState> = props => {
  if (!props.showRestoreDialog) {
    return null;
  }
  return (
    <LinkBox>
      <Content {...props} />
    </LinkBox>
  );
};

interface PopupProps extends RestoreState { className?: string }

/**
 * Popup to restore an element after it has been deleted.
 *
 * Will position at the bottom right of the closest relative positioned parent.
 */
export const RestoreDeletedItemPopup: FunctionComponent<PopupProps> = props => (
  <Wrapper className={props.className}>
    <RestoreDeletedItemLink {...props} />
  </Wrapper>
);
