import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Page } from '../Page';

const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: calc(100vh - ${props => props.theme.header.height});
  max-width: 3000px;
`;

const Background = styled.div`
  background-image: url('/water_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 70%;
  flex: 1;
  max-width: 870px;
`;

const Content = styled.div`
  background: ${props => props.theme.whiteBlue};
  flex: 1;
`;

const ContentArea = styled.div`
  max-width: 516px;
  margin: 40px;
  margin-top: 145px;
  @media (min-width: ${props => props.theme.breakpoints.tabletMax}) {
    margin: 145px;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const OnboardingPageLayout: FunctionComponent = ({ children }) => (
  <Page hideUserInfo>
    <PageContent>
      <Background />
      <Content>
        <ContentArea>
          {children}
        </ContentArea>
      </Content>
    </PageContent>
  </Page>
);
