import { FunctionComponent } from 'react';
import { Pane } from '@/components/shared/Pane';
import { NavPreviewIcon } from '@/components/shared/LinkButton/LinkButtons';
import { reportPreviewRoute } from '@/utils/routeTemplates';
import { useParams } from 'react-router';
import { Guid } from '@/types';
import { getUrlFriendlyString } from '@/utils/text';
import { useManifest } from '@/services/manifestServices';
import { Chapter, Manifest } from '@/utils/manifestTypes';

const findChapter = (chapterId: Guid, manifest: Manifest): Chapter | undefined => {
  const chapters = manifest.parts.flatMap(p => p.chapters);

  const chapter = chapters.find(c => c.id === chapterId);

  return chapter;
};

interface Props {
  title?: string | JSX.Element;
  subTitle?: string;
  padding?: string;
  margin?: string;
}

export const EditPane: FunctionComponent<Props> = ({ children, title, subTitle, padding, margin }) => {
  const { id: caseId, chapterId } = useParams<{id: Guid, chapterId: Guid}>();
  const { data } = useManifest(true) as { data: Manifest };

  const chapterTitle = findChapter(chapterId, data)?.title;

  const chapterUrlFriendlyString = chapterTitle ? getUrlFriendlyString(chapterTitle) : '';

  return (
    <Pane
      title={title}
      subTitle={subTitle}
      button={<NavPreviewIcon to={`${reportPreviewRoute(caseId)}#${chapterUrlFriendlyString}`} title="Preview chapter" />}
      padding={padding}
      margin={margin}
    >
      {children}
    </Pane>
  );
};
