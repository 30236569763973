import { WidgetReference, RepairDescription } from '@/types';
import { Pane, PreviewReportPane } from '@/components/shared/Pane';
import { Loader } from '@instech/components';
import { useRepairDescriptionViewService } from '@/services/repairDescriptionServices';
import { getOccurrencePrefixTitle } from '@/utils/text';
import { Section } from './Components';

export const RepairDescriptionReportPreview = ({ title, caseId, chapterId, widgetId, widgetTitle }: WidgetReference) => {
  const [data] = useRepairDescriptionViewService(caseId, chapterId, widgetId as string);

  if (!data) return <Loader />;

  return (
    <PreviewReportPane title={title} subTitle={getOccurrencePrefixTitle(widgetTitle, true)} caseId={caseId} chapterId={chapterId}>
      <Section items={data.repairs} />
    </PreviewReportPane>
  );
};

interface ViewProps {
  title: string;
  widgetTitle: string;
  data: RepairDescription;
}
export const RepairDescriptionReportView = ({ title, widgetTitle, data }: ViewProps) => (
  <Pane title={title} subTitle={getOccurrencePrefixTitle(widgetTitle, true)}>
    <Section items={data.repairs} />
  </Pane>
);
