import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { SummaryLine } from '@/types';
import { getOccurrencePrefixTitle } from '@/utils/text';
import { formatNumber } from '@/utils/number';
import { TruncatingText } from '@instech/components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  & + & {
    margin-top: 30px;
  }
  & >:not(:first-child) {
    margin-top: 15px;
  }
`;

interface RowProps {
  bold?: boolean;
  indent?: boolean;
}
const Row = styled.div<RowProps>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  ${props => props.bold && css`font-weight: bold;`}
  ${props => props.indent && css`padding-left: 15px;`}
`;

interface LineProps {
  double?: boolean;
}
const Line = styled.div<LineProps>`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.border.darkGray};
  ${props => props.double && css`
    margin-top: 2px;
  `}
`;

const DoubleLine = () => (
  <div>
    <Line />
    <Line double />
  </div>
);

interface CostRowItem { lines?: SummaryLine[], sum: string }
interface OccurrenceRowItem { title?: string, lines?: SummaryLine[], sum: string }

interface CostRowProps {
  data?: CostRowItem;
  label: string;
}
const CostRow: FunctionComponent<CostRowProps> = ({ data, label }) => {
  if (!data) {
    return null;
  }
  return (
    <div>
      <Row bold>
        <TruncatingText text={label} padding="0px 10px 0px 0px" />
        <div>{data?.sum}</div>
      </Row>
      {data?.lines?.map(line => (
        <Row indent key={line.name + line.cost}>
          <TruncatingText text={line.name} padding="0px 10px 0px 0px" />
          <div>{formatNumber(line.cost)}</div>
        </Row>
      ))}
    </div>
  );
};

interface SumProps {
  sum: string;
  label: string;
}
const SumRow: FunctionComponent<SumProps> = ({ sum, label }) => {
  if (!sum) {
    return null;
  }
  return (
    <div>
      <Row bold>
        <div>{label}</div>
        <div>{sum}</div>
      </Row>
    </div>
  );
};

// There's a little extra spacing inserted below this component, that doesn't need to be a
// Styled Component right at his moment.
const OccurrenceCosts: FunctionComponent<{ data: any[] }> = ({ data }) => (
  <div style={{ marginBottom: '15px' }}>
    <div><b>Repair Cost</b></div>
    {data.map(occurrence => <CostRow key={occurrence.title} data={occurrence} label={getOccurrencePrefixTitle(occurrence.title, true)} />)}
  </div>
);

interface CostsProps {
  summary: {
    occurrences: OccurrenceRowItem[];
    generalExpenses?: CostRowItem;
    adjustersConsideration?: CostRowItem;
    temporaryExpenses?: CostRowItem;
    extraExpenses?: CostRowItem;
    notConcerningAverage?: CostRowItem;
    sum: string;
    total: string;
  };
  comments: string[];
  currency: string | null;
}
export const Costs: FunctionComponent<CostsProps> = ({ summary, comments, currency }) => (
  <Wrapper>
    {summary.occurrences.length >= 1 && <OccurrenceCosts data={summary.occurrences} />}
    <CostRow data={summary.temporaryExpenses} label="Cost for Temporary Expenses" />
    <CostRow data={summary.extraExpenses} label="Cost for Extra Expenses" />
    <CostRow data={summary.generalExpenses} label="General Expenses" />
    <CostRow data={summary.adjustersConsideration} label="Cost for Adjuster’s Consideration" />
    <SumRow sum={summary.sum} label="Sum" />
    <Line />
    <CostRow data={summary.notConcerningAverage} label="Work not Concerning Average" />
    <Line />
    <SumRow sum={currency ? `${currency} ${summary.total}` : summary.total} label="Total Amount of Owners’ General Account" />
    <DoubleLine />
    {comments.length >= 1 && (
      <div>
        {comments.map(comment => <TruncatingText key={comment} text={comment} />)}
      </div>
    )}
  </Wrapper>
);
