import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import useSWR from 'swr';
import client from './client';

export interface CertificateListItem {
  id: string;
  name: string;
  date: string;
}
export interface CertificateDates {
  startDate?: string;
  endDate?: string;
}
export interface IsmCertificates {
  issuedBy: string;
  doc: CertificateDates;
  smc: CertificateDates;
}

export interface Certificates {
  id: string;
  certificatesValid: boolean;
  invalidCertificates: CertificateListItem[];
  hasClassRecommendations: boolean;
  classRecommendations: CertificateListItem[];
  ismCertificates: IsmCertificates;
  casualtyIsmReported?: boolean;
  priorRelatedIsmReports?: boolean;
}

export const certificatesApiUrl = (caseId: string) => `/case/${caseId}/certificates`;
export const getCertificates = async (caseId: string) => {
  const result = await client.get<Certificates>(certificatesApiUrl(caseId));
  return result.data;
};

export const useCertificates = () => {
  const caseId = useCaseIdFromUrl();
  const swr = useSWR<Certificates>(certificatesApiUrl(caseId));
  return swr;
};

export const updateCertificates = async (caseId: string, values: Certificates) => {
  const result = await client.put<Certificates>(certificatesApiUrl(caseId), values);
  return result.data;
};
