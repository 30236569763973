import { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { TableCell } from '../core/Components';
import { TableSection } from '../types';

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
export const Subtitle = styled.div`
    font-weight: 400;
`;

interface TableHeaderProps {
  span?: string | number;
}
export const TableHeader = styled.span<TableHeaderProps>`
  padding: 8px;
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.marineBlue};
  font-size: 14px;
  font-weight: bold;
  box-sizing: border-box;
  ${props => props.span && css`
    grid-column: span ${props.span};
  `}
  h2 { // Not sure what WAR used this for?
    margin: 0;
  }
`;

interface TableHeaderCellSpanProps {
  span: number | string;
}
export const TableHeaderCellSpan = styled(TableCell) <TableHeaderCellSpanProps>`
  font-size: 18px;
  font-weight: bold;
  justify-content: flex-start;
`;

export const renderTableHeaders = (headers: Array<string>, delimiters: number[] = []) =>
  headers.map((header, i) => (
    <TableHeader key={header}>{header}</TableHeader>
  ));

const SubHeader = styled.div`
  font-weight: normal;
  font-size: 16px;
`;
export const renderTableSectionHeaders = (tableSections: TableSection[]) => {
  const sections = tableSections.map(el => {
    let element;
    if (!el.name) {
      element = <TableHeaderCellSpan span={el.columns.length} />;
    } else {
      element = (
        <TableHeaderCellSpan span={el.columns.length}>
          {el.name}
          <SubHeader>{el.subheader}</SubHeader>
        </TableHeaderCellSpan>
      );
    }
    return (
      <Fragment key={el.name || 'header'}>
        {element}
      </Fragment>
    );
  });

  return (
    <>
      { sections}
    </>
  );
};
