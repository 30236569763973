import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { ToolButton } from '@/components/shared/ToolButton';
import { useManifest } from '@/services/manifestServices';
import { getReportTools } from '@/services/toolsServices';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import { useModalContext } from '../../../modal/ModalContext';

const FlexWrappedRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${props => props.theme.flatWhite};
  justify-content: space-around;
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    justify-content: none;
  };
`;

export const EditTools = () => {
  const { id: caseId } = useParams();
  const { open } = useModalContext();
  const role = useCurrentRole();
  const { data: manifest } = useManifest();
  const tools = getReportTools(manifest?.reportTools, role.name);

  return (
    <FlexWrappedRow>
      {tools.map(each => (
        <ToolButton
          key={each.label}
          onClick={each.onclick ? () => open({
            ...each.onclick,
            args: {
              ...each.onclick.args,
              caseId
            }
          }) : null}
          icon={<each.icon />}
          editTools
        >
          {each.label}
        </ToolButton>
      ))}
    </FlexWrappedRow>
  );
};
