import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useCompanyPdfDesign } from '@/services/companyPdfDesignServices';
import { Loader, Pane } from '@instech/components';
import { PdfDesignPreview } from '@/components/shared/PdfDesign/PdfDesignPreview';
import { useCurrentUserInfo } from '@/services/userInfoService';
import { InfoMessage } from '../../core/InfoMessage';

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

/* Helper component to vert-horiz center contents */
const AlignCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PDFDesign: FunctionComponent = () => {
  const { data: userInfo } = useCurrentUserInfo();

  const { data } = useCompanyPdfDesign(userInfo?.companyId);

  if (!data) {
    return <Loader />;
  }

  return (
    <Pane title="PDF design" margin="32px 0px 0px">
      <Layout>
        <AlignCenter>
          <InfoMessage message="Changes to the PDF design can only be done by an administrator." />
        </AlignCenter>
        <PdfDesignPreview
          colorProfile={data.colorProfile}
          logoAlignment={data.logoAlignment}
          footerText={data.footerText}
          companyId={userInfo?.companyId ?? ''}
        />
      </Layout>
    </Pane>
  );
};
