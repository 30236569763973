import styled, { css } from 'styled-components';

interface FieldProps {
  padding: string;
  error?: boolean;
  disabled?: boolean;
}
export const Field = styled.div<FieldProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: ${props => props.padding};
  background: ${props => props.theme.white};
  border-radius: 3px;
  border: 1px solid;
  border-color: ${props => props.theme.border.gray};
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
  box-sizing: border-box;

  ${props => props.error && css`
    border-color: #AD1F23;
    color: #AD1F23;
  `};

  ${props => props.disabled && css`
    background-color: ${props.theme.background.primary};
  `}
`;

export const FieldAction = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  color: ${props => props.theme.marineBlue};
  cursor: pointer;

  & svg {
    height: 24px;
    width: 24px;
  }
`;

export const FieldActionText = styled.span`
  display: flex;
  margin-bottom: 1px;
  margin-right: 4px;
`;

export const FieldActionFileText = styled.span`
  display: flex;
  margin-bottom: 1px;
  margin-right: 4px;
  text-decoration: underline;
`;

export const FieldActionLinkText = styled.span`
  margin-bottom: 1px;
  margin-right: 4px;
  font-weight: bold;
  font-size: 14px;
`;

export const FileMetaInformation = styled.div`
  font-size: 12px;
  color: ${props => props.theme.softBlue};
`;

export const FileMetaInformationRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;

export const InformationHeader = styled.div`
  padding-bottom: 12px;
  color: ${props => props.theme.darkGray};
  font-size: 16px;
  font-weight: bold;
`;
