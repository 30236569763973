import { FunctionComponent } from 'react';
import { useCase } from '@/services/dashboardServices';
import { Loader } from '@instech/components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useUserInfo } from '@/services/userInfoService';
import { FeedbackPane } from './FeedbackPane';

// This only exports the readonly container, as it is a feature-flagged mirror of the
// original dashboard. Some temporary duplication going on here.
export const FeedbackContainer: FunctionComponent = () => {
  const caseId = useCaseIdFromUrl();
  const { data: caseData, error: caseError } = useCase(caseId);
  const surveyorId = caseData?.users?.primarySurveyorId;
  const { data: userInfo, error: userInfoError } = useUserInfo(surveyorId);

  if (caseError || userInfoError) {
    throw new Error(caseError || userInfoError);
  }

  if (!userInfo && surveyorId) return <Loader />;

  return (
    <FeedbackPane
      contactInfo={userInfo}
      titleText="Surveyor"
      unassignedText="No surveyors currently assigned"
      buttonText="Feedback from Surveyor"
      readonlyFeedback
    />
  );
};
