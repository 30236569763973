import { timelineEventPropType } from '@/prop-types/custom-prop-types';
import { Loader } from '@instech/components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TimelineEvent } from './components/TimelineEvent';
import {
  TimelineLocation, TimelinePath, TimelinePathLong, TimelineShip, TimelineYear
} from './components/core';
import * as timelineUtils from './utils/timelineUtils';
import { segmentTypes } from './utils/types';

const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TimelineSegment = ({ item, prevItem, onRemove, canEdit = false, onAddNew, displayInUtcTime }) => {
  switch (item.type) {
    case segmentTypes.pathAfter:
      return <TimelinePath isRight={item.right} isEnd />;
    case segmentTypes.pathBefore:
      return <TimelinePath isRight={item.right} />;
    case segmentTypes.pathLong:
      return <TimelinePathLong isRight={item.right} />;
    case segmentTypes.yearStart:
      return <TimelineYear date={item.date} />;
    case segmentTypes.year:
      return <TimelineYear date={item.date} hasPath isRight={prevItem?.right} />;
    case segmentTypes.location:
      return <TimelineLocation name={item.location} isRight={item.right} elsewhere={item.elsewhere} />;
    case segmentTypes.vessel:
      return (
        <TimelineEvent
          event={item.event}
          prevItem={prevItem}
          isRight={item.right}
          elsewhere={item.elsewhere}
          onRemove={{ func: onRemove, value: item.event.id }}
          canEdit={canEdit}
          onAddNew={onAddNew}
          displayInUtcTime={displayInUtcTime}
        />
      );
    case segmentTypes.tail:
      return <TimelineShip />;
    default:
      return null; // TODO: Create actual <TimelineUknown /> to debug missing segments
  }
};
TimelineSegment.propTypes = {
  item: timelineEventPropType, // TODO: This is not the right type
  prevItem: timelineEventPropType, // TODO: This is not the right type
  onRemove: PropTypes.func,
  canEdit: PropTypes.bool,
  onAddNew: PropTypes.func,
  displayInUtcTime: PropTypes.bool
};

const TimelineView = ({ data, onRemove, canEdit, onAddNew, displayInUtcTime }) => {
  if (!data) {
    return <Loader />;
  }
  const timelineStructure = timelineUtils.getStructure(data);
  return (
    <Timeline id="timeline">
      {timelineStructure.map((item, i) => (
        <TimelineSegment
          key={item.event ? item.event.id : `tlsegment-${i}`}
          item={item}
          prevItem={timelineStructure[i - 1] || null}
          onRemove={onRemove}
          canEdit={canEdit}
          onAddNew={onAddNew}
          displayInUtcTime={displayInUtcTime}
        />
      ))}
    </Timeline>
  );
};
TimelineView.propTypes = {
  data: PropTypes.arrayOf(timelineEventPropType),
  onRemove: PropTypes.func,
  canEdit: PropTypes.bool,
  onAddNew: PropTypes.func,
  displayInUtcTime: PropTypes.bool
};

export { TimelineView };
