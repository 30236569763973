/* eslint-disable jsx-a11y/label-has-associated-control */
import 'styled-components/macro';
import { useField, useFormikContext } from 'formik';
import { UserInfo } from '@/types';
import { useModalContext } from '@/components/modal/ModalContext';
import { roles } from '@/components/AppRouting/roles';
import { Pane } from '@instech/components';
import { useCallback } from 'react';
import { SelectUsersReturn, showSelectUsersModal } from './SelectUsersModal';
import { ClaimWallWarning } from '../Components';
import { showSelectClaimsHandlerModal } from './SelectClaimsHandlerModal';
import { UserSelectArea } from './UserSelectArea';

interface SelectUserListProps {
  name: string;
  label: string;
  primaryName: string;
  claimId: string;
  role: string;
}
const SelectedUserList = ({ name, label, primaryName, claimId, role }: SelectUserListProps) => {
  const [field, meta, helpers] = useField<UserInfo[]>(`users.${name}`);
  const [primaryField, primaryMeta, primaryHelpers] = useField(`users.${primaryName}`);
  const { submitCount } = useFormikContext();
  const { open } = useModalContext();

  const onSelect = (values: SelectUsersReturn) => {
    void helpers.setValue(values.users);
    void primaryHelpers.setValue(values.primary);
    // This is a workaround solution to error messages not updating right. Would be
    // nice to be able to avoid but right now it works reliably enough.
    setTimeout(() => helpers.setTouched(true), 100);
  };

  const openSelectUsers = () => open(showSelectUsersModal(
    claimId, role, onSelect, field.value, primaryField.value
  ));

  return (
    <UserSelectArea
      label={label}
      users={field.value}
      primaryValue={primaryField.value}
      userError={meta.touched && meta.error}
      primaryError={submitCount > 0 && primaryMeta.error}
      openModal={openSelectUsers}
    />
  );
};

interface SelectedClaimsHandlerProps {
  name: string;
  label: string;
  primaryName: string;
  claimId: string;
  role: string;
}
const SelectedClaimsHandler = ({ name, label, primaryName, claimId, role }: SelectedClaimsHandlerProps) => {
  const [field, meta, helpers] = useField<UserInfo>(`users.${name}`);
  const [primaryField, primaryMeta, primaryHelpers] = useField(`users.${primaryName}`);
  const { submitCount } = useFormikContext();
  const { open } = useModalContext();

  const onSelect = useCallback((selectedUser: UserInfo) => {
    void helpers.setValue(selectedUser);
    void primaryHelpers.setValue(selectedUser.id);
  }, [helpers, primaryHelpers]);

  const openSelectUsers = useCallback(() => (
    open(showSelectClaimsHandlerModal(onSelect, claimId, role, field.value))
  ), [claimId, field.value, onSelect, open, role]);

  return (
    <UserSelectArea
      label={label}
      buttonText="Select User"
      users={field.value}
      primaryValue={primaryField.value}
      userError={meta.touched && meta.error}
      primaryError={submitCount > 0 && primaryMeta.error}
      openModal={openSelectUsers}
    />
  );
};

interface UsersInputProps {
  claimId: string;
  activeClaimWall: boolean;
}
export const UsersFields = ({ claimId, activeClaimWall }: UsersInputProps) => (
  <Pane title="Users" padding="0px" margin="16px 0px 0px">
    {activeClaimWall && (
      <ClaimWallWarning />
    )}
    <SelectedClaimsHandler
      label="Primary Claims Handler"
      name="claimsHandler"
      primaryName="primaryClaimsHandlerId"
      claimId={claimId}
      role={roles.claimHandler}
    />
    <SelectedUserList
      label="Surveyors"
      name="surveyors"
      primaryName="primarySurveyorId"
      claimId={claimId}
      role={roles.surveyor}
    />
  </Pane>
);
