import { roles } from '@/components/AppRouting/roles';
import { openAvailableInformationTool } from '@/components/tools/AvailableInformation';
import { openCompanyParticularsTool } from '@/components/tools/CompanyParticulars';
import { openContactsTool } from '@/components/tools/Contacts';
import { openImagesTool } from '@/components/tools/Images';
import { openInstructionTool } from '@/components/tools/Instruction';
import { openVesselInformationTool } from '@/components/tools/VesselParticulars';
import { VesselInformationTab } from '@/components/tools/VesselParticulars/VesselInformationTool';
import { Dictionary } from '@/types';
import { Tool } from '@/utils/manifestTypes';
import {
  FolderInfo, Briefcase, BoatFront, Books, Image, Contacts
} from '@instech/icons';

const ignoreToolsMap: Dictionary<boolean> = {
  ImmediateFeedback: true,
  EquipmentParticulars: true,
  Certificates: true
};

interface ToolData {
  label: string;
  icon: (...args: any) => JSX.Element;
  onclick: {
    component: (...args: any) => JSX.Element;
    options: any;
    args?: any;
  }
}
const toolTypeToComponentMap: Dictionary<ToolData> = {
  SurveyorInstructions: {
    label: 'Instructions from NHC',
    icon: FolderInfo,
    onclick: openInstructionTool
  },
  CompanyParticulars: {
    label: 'Company particulars',
    icon: Briefcase,
    onclick: openCompanyParticularsTool
  },
  VesselInformation: {
    label: 'Vessel information',
    icon: BoatFront,
    onclick: openVesselInformationTool({ currentTab: VesselInformationTab.VesselsParticularsTab })
  },
  AvailableInformation: {
    label: 'Available information',
    icon: Books,
    onclick: openAvailableInformationTool
  },
  ImageLibrary: {
    label: 'Image library',
    icon: Image,
    onclick: openImagesTool
  },
  Contacts: {
    label: 'Contacts',
    icon: Contacts,
    onclick: openContactsTool
  }
};

export function getReportTools(reportTools: Tool[], role?: string) {
  const tools: any[] = [];

  if (!reportTools) return tools;

  reportTools.forEach(tool => {
    const type = tool.widgets[0].widgetType;
    if (!ignoreToolsMap[type]) {
      const component = toolTypeToComponentMap[type];

      if (!component) {
        throw new Error(`Could not map a tool for type ${type}`);
      }
      tools.push(component);
    }
  });

  // Image library is not a part of the tools in the manifest, so adding this manually
  if (role === roles.surveyor || role === roles.claimHandler) {
    tools.push(toolTypeToComponentMap.ImageLibrary);
  }
  return tools;
}
