import { FieldLayout } from '@/components/shared/Form/core/Components';
import { ReadOnlyField } from '@/components/shared/ReadOnlyField';
import { SourceDetails } from '@/components/shared/SourceDetails';
import styled from 'styled-components';

const Layout = styled(FieldLayout)`
  min-width: 0px;
`;

interface Props {
  title: string;
  description: string;
  source?: {
    date?: string;
    name?: string;
  }
}
export const ParticularField = ({ title, description, source }: Props) => (
  <Layout>
    <ReadOnlyField title={title} value={description} />
    <SourceDetails name={source?.name} date={source?.date} />
  </Layout>
);
