import { FunctionComponent, useState } from 'react';
import { VesselInformation } from '@/services/vesselInformationServices';
import { Guid } from '@/types';
import { Loader } from '@instech/components';
import { InformationRowEdit } from './InformationRowEdit';
import { InformationRowView } from './InformationRowView';

interface InformationProps extends VesselInformation {
  onDelete: (id: string) => void;
  onToggleEdit: (id: string) => void;
  readonly?: boolean;
  isEditMode: boolean;
}

const InformationRow: FunctionComponent<InformationProps> = props => {
  if (props.isEditMode) {
    return <InformationRowEdit {...props} />;
  }
  return <InformationRowView {...props} />;
};

interface InformationListProps {
  entries: VesselInformation[];
  onDelete: (id: string) => void;
  readonly?: boolean;
  editItem?: Guid;
  setEditItem?: (value?: Guid) => void;
}
export const InformationList: FunctionComponent<InformationListProps> = ({
  entries,
  onDelete,
  readonly,
}) => {
  const [editItem, setEditItem] = useState<Guid | undefined>(undefined);

  const handleToggleEdit = (id: string) => {
    if (id === editItem) {
      setEditItem(undefined);
    } else {
      setEditItem(id);
    }
  };

  if (!entries) return <Loader />;

  return (
    <>
      {entries.map(entry => (
        <InformationRow
          key={entry.id}
          onDelete={onDelete}
          readonly={readonly}
          onToggleEdit={handleToggleEdit}
          isEditMode={editItem === entry.id}
          {...entry}
        />
      ))}
    </>
  );
};
