import { FunctionComponent } from 'react';
import { ComingSoon as RowingIcon } from '@instech/icons';
import { NavBox } from '@/components/shared/BoxButton/BoxButtons';
import styled from 'styled-components';
import { FullwidthWidgetWrapper } from '../../widgets/FullwidthWidgetWrapper';
import { useEditContext } from '../../core/EditContext';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.white};
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
  padding: 32px;
`;

const Title = styled.h2`
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 29px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
`;

const Text = styled.p`
  max-width: 500px;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 18px;
`;

interface Props {
  chapterName: string;
  text: string;
}
export const MissingChapter: FunctionComponent<Props> = ({ chapterName, text }) => {
  const { chapters } = useEditContext();
  const chapterId = chapters.find(chapter => chapter.title === chapterName)?.id || '';
  return (
    <FullwidthWidgetWrapper>
      <Content>
        <RowingIcon />
        <Title>{`Missing ${chapterName}`}</Title>
        <Text>{text}</Text>
        <ButtonWrapper>
          <NavBox to={chapterId}>{`Back to ${chapterName}`}</NavBox>
        </ButtonWrapper>
      </Content>
    </FullwidthWidgetWrapper>
  );
};
