import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Guid } from '@/types';
import { PdfColorProfile, PdfLogoAlignment } from '@/services/companyPdfDesignServices';
import { Content } from './core/Content';
import { Header } from './core/Header';

interface Props {
  colorProfile: PdfColorProfile;
  logoAlignment: PdfLogoAlignment;
  footerText?: string;
  companyId: Guid;
}

const PaperPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.15);
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const Inset = styled.div`
  display: flex;
  background-color: ${props => props.theme.ghost};
  padding: 32px;
  width: 310px;
  height: 415px;
`;

const FooterText = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  font-size: 7px;
  height: 1.2rem;
`;

const FooterWrapper = styled.div`
  width: calc(100% - 32px);
  margin: 0 auto;
`;

const DividerBar = styled.div`
  width: 100%;
  border-top: 2px solid ${props => props.theme.background.primary};
`;

const Footer: FunctionComponent<{text?: string}> = ({ text }) => (
  <FooterWrapper>
    <DividerBar />
    <FooterText>{text}</FooterText>
  </FooterWrapper>
);

export const PdfDesignPreview: FunctionComponent<Props> = ({ colorProfile, logoAlignment, footerText, companyId }) => (
  <Inset>
    <PaperPage>
      <div>
        <Header colorProfile={colorProfile} logoAlignment={logoAlignment} companyId={companyId} />
        <Content />
      </div>
      <Footer text={footerText} />
    </PaperPage>
  </Inset>
);
