import useSWR from 'swr';
import { TimeSaved } from '@/types';
import client from '@/services/client';

interface BaseArgs {
  caseId: string;
  chapterId: string;
  widgetId: string;
}

const timeSavedUrl = ({ caseId, chapterId, widgetId }: BaseArgs) => (
  `/timesaveddetails/case/${caseId}/chapters/${chapterId}/widget/${widgetId}`
);

interface UseTimeSavedDetailsArgs extends BaseArgs { swrOptions: any }

export function useTimeSavedDetails({ caseId, chapterId, widgetId, swrOptions }: UseTimeSavedDetailsArgs) {
  const requestUrl = timeSavedUrl({ caseId, chapterId, widgetId });
  const swr = useSWR(requestUrl, swrOptions);
  return swr;
}

interface UpdateTimeSavedDetailsAsyncArgs extends BaseArgs { timeSavedDetails: TimeSaved }

export const updateTimeSavedDetailsAsync = async ({ caseId, chapterId, widgetId, timeSavedDetails } : UpdateTimeSavedDetailsAsyncArgs) => {
  const url = timeSavedUrl({ caseId, chapterId, widgetId });
  const { data } = await client.put(url, timeSavedDetails);
  return data;
};
