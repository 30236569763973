import styled from 'styled-components';
import { useCaseStatus } from '@/services/caseStatusServices';
import { Loader } from '@instech/components';
import { NewBoxButton } from '@/components/shared/BoxButton/NewBoxButton';
import { Check, Remove } from '@instech/icons';
import { useModalContext } from '@/components/modal/ModalContext';
import { showCaseStatusModal } from './CaseStatusModal';

const CaseStatusArea = styled.div`
  justify-content: end;
`;

export const CaseStatus = () => {
  const { open, close } = useModalContext();
  const { data: caseStatus } = useCaseStatus();

  if (!caseStatus) return <Loader />;

  const text = caseStatus.isClosed ? 'Status: Closed' : 'Status: Open';

  return (
    <CaseStatusArea>
      <NewBoxButton
        startIcon={caseStatus.isClosed ? <Remove /> : <Check />}
        onClick={() => open(showCaseStatusModal({ close }))}
      >
        {text}
      </NewBoxButton>
    </CaseStatusArea>
  );
};
