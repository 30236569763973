import { Checkbox, OccurrenceSelect } from '@/components/shared/Form/FormFields';
import { AddSaveButtons } from '@/components/shared/FormButton/FormButtons';
import {
  TableCell as DefaultTableCell,
  TableHeader as DefaultTableHeader,
  Table,
  TableRow
} from '@/components/shared/Table';
import { SurveyLocation } from '@/types';
import {
  DateRangePicker,
  Divider,
  FormikWithPrompt as Formik,
  TextField
} from '@instech/components';
import {
  FieldArray, Form, useFormikContext
} from 'formik';
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import {
  LocationTableForm, locationModel, setInitialState
} from './core/utils';
import { locationSchema } from './core/locationSchema';
import useFilterExistingOccurrenceOptions from './core/useFilterExistingOccurrenceOptions';
import { LocationDeleteButton } from './core/LocationDeleteButton';

const TableHeader = styled(DefaultTableHeader)`
  padding: 16px 8px;
`;

const TableCell = styled(DefaultTableCell)`
  padding: 16px 8px;
`;

const StyledTextField = styled(TextField)`
  min-width: 90px;
`;

const HeaderRow = () => (
  <>
    <TableHeader id="locationheader-location">Survey location</TableHeader>
    <TableHeader id="locationheader-date">Date of survey</TableHeader>
    <TableHeader id="locationheader-occurrence">Occurrence(s)</TableHeader>
    <TableHeader id="locationheader-includereport" title="Included in report">Included</TableHeader>
    <TableHeader />
  </>
);

interface InnerProps {
  locations: SurveyLocation[];
  onDeleteOnServer: (surveyLocationId: string) => Promise<void>;
  setInitialItemId: (id: string) => void;
}
const LocationInnerForm: FunctionComponent<InnerProps> = ({ locations, onDeleteOnServer, setInitialItemId }) => {
  const { values, dirty, isSubmitting, handleSubmit } = useFormikContext<LocationTableForm>();

  const existingLocations = locations.map(x => x.id);

  useFilterExistingOccurrenceOptions();

  return (
    <FieldArray
      name="locations"
      render={({ push, remove }) => (
        <>
          <Table layout="1fr 340px minmax(240px, 1fr) auto 60px">
            <HeaderRow />
            {values.locations.map((row, i) => (
              <TableRow key={row.id || i} even={i % 2 === 0}>
                <TableCell>
                  <StyledTextField
                    name={`locations.${i}.location`}
                    placeholder="Location"
                    aria-labelledby="locationheader-location"
                    noLabel
                  />
                </TableCell>
                <TableCell>
                  <DateRangePicker
                    name={`locations.${i}.duration`}
                    aria-labelledby="locationheader-date"
                  />
                </TableCell>
                <TableCell>
                  <OccurrenceSelect name={`locations.${i}.occurrences`} />
                </TableCell>
                <TableCell checkbox>
                  <Checkbox
                    name={`locations.${i}.report`}
                    aria-labelledby="locationheader-includereport"
                  />
                </TableCell>
                <TableCell right button>
                  <LocationDeleteButton
                    row={row}
                    locationIds={existingLocations}
                    removeFromArray={() => remove(i)}
                    onDeleteOnServer={onDeleteOnServer}
                    onUpdateInitialId={setInitialItemId}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <Divider />
          <AddSaveButtons
            addLabel="Location"
            onAdd={() => push({ ...locationModel(uuidv4()) })}
            onSave={dirty ? handleSubmit : null}
            disableSave={!dirty}
            isSubmitting={isSubmitting}
            spaceBetween
          />
        </>
      )} />
  );
};

interface Props {
  locations: SurveyLocation[];
  onSubmit: (newLocations: LocationTableForm) => Promise<SurveyLocation[]>;
  onDeleteOnServer: (surveyLocationId: string) => Promise<void>;
}
export const LocationTable: FunctionComponent<Props> = ({ locations, onSubmit, onDeleteOnServer }) => {
  const [initialItemId, setInitialItemId] = useState(uuidv4());

  return (
    <Formik
      initialValues={{ locations: setInitialState(locations, initialItemId) }}
      validationSchema={locationSchema}
      onSubmit={async (values, { resetForm }) => {
        const result = await onSubmit(values);
        if (result) {
          resetForm({ values: { locations: setInitialState(result, initialItemId) } });
        }
      }}>
      {() => (
        <Form>
          <LocationInnerForm
            setInitialItemId={setInitialItemId}
            locations={locations}
            onDeleteOnServer={onDeleteOnServer}
          />
        </Form>
      )}
    </Formik>
  );
};
