import { Loader } from '@instech/components';
import { Pane, ReportPane } from '@/components/shared/Pane';
import {
  Table, TableHeader, TableRow, TruncatingTextTableCell
} from '@/components/shared/Table';
import { openVesselInformationTool } from '@/components/tools/VesselParticulars';
import { VesselInformationTab } from '@/components/tools/VesselParticulars/VesselInformationTool';
import { EquipmentParticular, useEquipmentsParticulars } from '@/services/equipmentsParticularsServices';
import { getToolWidget, useManifest } from '@/services/manifestServices';
import { Manifest } from '@/utils/manifestTypes';
import { ViewProps, NoAddedInformation } from './Components';

const EquipmentsParticularsTable = ({ data }: { data :EquipmentParticular[]}) => {
  if (!data || data.length === 0) return <NoAddedInformation />;
  return (
    <Table layout="auto auto auto">
      <TableHeader>Equipment</TableHeader>
      <TableHeader>Make / Manufacturer</TableHeader>
      <TableHeader>Type / Model</TableHeader>
      {data.map((particular, i) => (
        <TableRow key={particular.id} even={i % 2 === 0}>
          <TruncatingTextTableCell text={particular.equipment} />
          <TruncatingTextTableCell text={particular.make} />
          <TruncatingTextTableCell text={particular.type} />
        </TableRow>
      ))}
    </Table>
  );
};

export const EquipmentsParticularsView = ({ data, title }: ViewProps) => (
  <Pane title={title}>
    <EquipmentsParticularsTable data={data.items} />
  </Pane>
);

export const EquipmentsParticularsPreview = () => {
  const { data } = useEquipmentsParticulars();
  const { data: manifest } = useManifest() as { data: Manifest };

  if (!data) return <Loader />;
  const tab = VesselInformationTab.EquipmentsParticularsTab;

  const filteredData = data.filter(x => x.includeInReport);

  const widget = getToolWidget(manifest, 'EquipmentParticulars');

  const title = widget?.title ?? 'Equipment&apos;s Particulars';

  return (
    <ReportPane title={title} open={openVesselInformationTool({ currentTab: tab })} editable>
      <EquipmentsParticularsTable data={filteredData} />
    </ReportPane>
  );
};
