import PropTypes from 'prop-types';
import { FullwidthWidgetWrapper } from '@/components/pages/edit/widgets/FullwidthWidgetWrapper';
import { useExpensesService } from '@/services/expensesServices';
import { ExpenseList } from '../../components/ExpenseList';

export const Expenses = ({ widgetId, title }) => {
  const [data, update] = useExpensesService('Expenses', widgetId, { suspense: true });
  return (
    <FullwidthWidgetWrapper>
      <ExpenseList data={data} title={title} onSubmit={update} formId={widgetId} />
    </FullwidthWidgetWrapper>
  );
};
Expenses.propTypes = {
  widgetId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
