import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { childrenPropType } from '@/prop-types/custom-prop-types';
import { useAccount } from '@/components/authentication/AuthenticationProvider';

// Note, the disabled eslint is correct, signinCallback is not an updateable dependency in our effect
const CallbackComponent = ({ children }) => {
  const { signinCallback } = useAccount();
  const [url, setUrl] = useState(null);

  useEffect(() => {
    void signinCallback().then(state =>
      setUrl(state.route || '/'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (url) {
    return <Redirect to={url} />;
  }

  return (
    <>{children}</>
  );
};
CallbackComponent.propTypes = { children: childrenPropType };

export const CallbackPage = () => (
  <CallbackComponent>
    <div>Redirecting...</div>
  </CallbackComponent>
);
