import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ErrorBoundary } from '@instech/components';
import { ErrorPage } from '@/components/pages/error/ErrorPage';
import { Widget, widgetPropType } from '@/components/pages/edit/widgets/Widget';
import { Pane } from '@/components/shared/Pane';
import { ReportLoader } from '@/components/shared/ReportLoader';

const PlaceholderPage = () => (
  <Pane title="General Background Description">
    No content
  </Pane>
);

const breakpoint = '1400px';
/**
 * Flexible layout that supports both full-width widgets (most of them),
 * and column based widgets (ExpensesSummary and TimeSummary)
 */
const WidgetLayout = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoint}) {
    flex-direction: row;
    flex-wrap: wrap;
  };
`;

const Widgets = ({ widgets }) => (
  <WidgetLayout>
    {widgets.map(({ title, widgetId, widgetType }) => (
      <Widget key={widgetId || title} title={title} widgetId={widgetId} widgetType={widgetType} />
    ))}
  </WidgetLayout>
);
Widgets.propTypes = {
  widgets: PropTypes.arrayOf(widgetPropType)
};

export const WidgetsContainer = ({ widgets }) => (
  <ErrorBoundary component={ErrorPage}>
    {!widgets && <PlaceholderPage />}
    <ReportLoader>
      {widgets && <Widgets widgets={widgets} />}
    </ReportLoader>
  </ErrorBoundary>
);
WidgetsContainer.propTypes = {
  widgets: PropTypes.arrayOf(widgetPropType)
};
