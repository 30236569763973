import { useHasScrolled } from '@/hooks/useHasScrolled';
import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Grid, Header as EditHeader } from './EditGrid';
import { Header } from './Header';

const PageWrapper = styled.div<{scrolled?: boolean, noScroll?: boolean}>`
  background: ${props => props.theme.whiteBlue};
  min-height: 100vh;
  ${props => props.scrolled && !props.noScroll && css`
    margin-top: 52px;
  `}
`;

interface Props {
  title?: string;
  noScroll?: boolean;
  showHelpAndNotifications?:boolean;
  hideUserInfo?: boolean;
}

export const Page: FunctionComponent<Props> = ({
  children,
  title,
  noScroll,
  showHelpAndNotifications,
  hideUserInfo
}) => {
  const scrolledHeight = 52;
  const hasScrolled = useHasScrolled(scrolledHeight);
  const scrolled = noScroll ? true : hasScrolled;
  return (
    <PageWrapper scrolled={scrolled} noScroll={noScroll}>
      <Header
        title={title}
        scrolled={scrolled}
        hideUserInfo={hideUserInfo}
        showHelpAndNotifications={showHelpAndNotifications} />
      {children}
    </PageWrapper>
  );
};

interface EditPageProps { title?: string; }
export const EditPage: FunctionComponent<EditPageProps> = ({ children, title }) => (
  <Grid>
    <EditHeader>
      <Header scrolled title={title} />
    </EditHeader>
    {children}
  </Grid>
);
