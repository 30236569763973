import { TimeDetails } from '@/components/pages/edit/widgets/TimeDetails';
import { TimeSavedDetails } from '@/components/pages/edit/widgets/TimeSavedDetails';
import { Expenses } from '@/components/pages/edit/widgets/Expenses';
import { OccurrenceExpenses } from '@/components/pages/edit/widgets/OccurrenceExpenses';
import { GeneralExpenses } from '@/components/pages/edit/widgets/GeneralExpenses';
import { SurveyLocations } from '@/components/pages/edit/widgets/SurveyLocations';
import { AttendingRepresentatives } from '@/components/pages/edit/widgets/AttendingRepresentatives';
import { Comments } from '@/components/pages/edit/widgets/Comments';
import { DamageDescription } from '@/components/pages/edit/widgets/DamageDescription';
import { RepairDescription } from '@/components/pages/edit/widgets/RepairDescription';
import { IntroductionForm as Introduction } from '@/components/pages/edit/widgets/Introduction';
import { Description } from '@/components/pages/edit/widgets/Description';
import { Occurrences } from '@/components/pages/edit/widgets/Occurrences';
import { VesselEvents } from '@/components/pages/edit/widgets/VesselEvents';
import { TimeSummary } from '@/components/pages/edit/widgets/TimeSummary';
import { ExpensesSummary } from '@/components/pages/edit/widgets/ExpensesSummary';
import { WidgetType } from '@/types';

const widgetTypeToComponentMap = {
  AttendingRepresentatives,
  Comments,
  Description,
  Expenses,
  ExpensesSummary,
  GeneralExpenses,
  Introduction,
  Occurrences,
  TimeDetails,
  TimeSavedDetails,
  TimeSummary,
  SurveyLocations,
  VesselEvents,
  OccurrenceExpenses,
  DamageDescription,
  RepairDescription
};

export function getWidgetEditComponent(widgetType: WidgetType) {
  const Component = widgetTypeToComponentMap[widgetType];
  if (!Component) {
    throw new Error(`No component with widgetType ${widgetType} found`);
  }
  return Component;
}
