import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  phldrBlue1: '#1C3775', /* this is a placeholder name */
  maritime2: '#004A73',
  marineBlue: '#003C71',
  marineBlue75: '#33638D',
  marineBlue60: '#99b1c6',
  marineBlue50: '#7f9db8',
  marineBlue20: '#d1dce5', /* technically it's 17-18, but 20 for uniformity */
  marineBlue10: '#e5ebf1',
  marineBlue05: '#F2F5F8',
  marineGreen: '#2E827E',
  deepAzureBlue: '#053262',
  lightBlue: '#ECF1F5',
  whiteBlue: '#D9E3EC',
  mediumBlue: '#93D4F4',
  softBlue: '#4887B1',
  strongBlue: '#2A9FD8',
  blue: '#003A4D',
  white: '#FFF',
  flatWhite: '#F5F5F5',
  darkWhite: '#F8F8F8',
  black: '#000000',
  blackPearl: '#001A32',
  darkGray: '#212121',
  mediumGray: '#C9CDD0',
  lightGray: '#DEDEDE',
  ultraLightGray: '#F3F4F6',
  ultraLightGray2: '#F1F3F6',
  placeholderGray: '#808080',
  orange: '#F5866C',
  orange50: '#FAC2B5',
  orange10: 'rgba(245,134,108,0.1)',
  green: '#73C6A1',
  green75: '#96d4b9',
  green50: '#b9e2d0',
  green15: '#EAF7F1',
  lightGreen: '#64C698',
  lightGreen50: '#73C6A180',
  lightGreen25: '#73C6A140',
  lightRed: '#FE8E9C',
  red: '#AD1F23',
  yellow: '#F6C525',
  lightYellow: '#FFD26C',
  lighterYellow: '#FFE8B5',
  ghost: '#EBF1F5',
  warning: '#880D18',
  notification: {
    infoIconBackground: '#003C71',
    infoBox: '#003C7140',
    warnIconBackground: '#FFD26C',
    warnBox: '#FFD26C7F',
    dangerIconBackground: '#880D18',
    dangerBox: '#FAC2B5'
  },
  opacity: {
    placeholder: 0.6,
  },
  border: {
    gray: '#7F9DB8',
    darkGray: '#979797',
    blueGray: '#BFCEDB',
    brightBlue: '#4A90E2',
    white: '#D9E3EC',
  },
  background: {
    primary: '#D9E2EA'
  },
  breakpoints: {
    tablet: '1100px',
    tabletMax: '1099px'
  },
  zIndex: {
    auto: 'auto',
    base: 0,
    docked: 50, // Inner content scrolling with user
    sidebar: 100,
    header: 150,
    overlay: 1100, // Over all other content, but under modal
    modal: 1200,
    popover: 1400,
    toast: 1500,
    tooltip: 1600,
  },
  status: {
    green: '#73C6A1',
    blue: '#4EC3E0',
    red: '#F5866C'
  },
  timeline: {
    'Vessel Movement': {
      main: '#7F9DB8',
      dark: '#003C71'
    },
    'Vessel Status': {
      main: '#8173C6',
      dark: '#524878'
    },
    'Occurrence': {
      main: '#F5866C',
      dark: '#880D18'
    },
    'Event': {
      main: '#73C6A1',
      dark: '#0D7B58'
    },
    'None': {
      main: '#333',
      dark: '#222'
    },
  },
  header: {
    height: '100px',
    scrolledHeight: '50px'
  }
};
