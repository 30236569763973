import {
  ImmediateFeedback,
  Guid,
  ApplicationRole
} from '@/types';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import useSWR from 'swr';
import { putJsonAsync } from './client';

const getApiRoute = (caseId: Guid, role: string) => `case/${caseId}/immediatefeedback?role=${role}`;

const getApiPublishRoute = (caseId: Guid) => `case/${caseId}/immediatefeedback/publish`;

export const useImmediateFeedbackService = (caseId: Guid) => {
  const currentRole = useCurrentRole() as ApplicationRole;
  const route = getApiRoute(caseId, currentRole.name);
  const { data, error, mutate } = useSWR(route);
  if (error) {
    throw error;
  }
  const update = async (immediateFeedback: ImmediateFeedback) => {
    const response = await putJsonAsync(route, immediateFeedback);
    void mutate(response);
    return response;
  };

  const publish = async () => {
    const publishRoute = getApiPublishRoute(caseId);
    const response = await putJsonAsync(publishRoute, null);
    void mutate(response);
    return response;
  };

  return [data, update, publish];
};
