import { FunctionComponent } from 'react';
import styled from 'styled-components';

const Header = styled.div`
  font-size: 20px;
  margin-bottom: 6px;
`;

const HelpText = styled.div`
  font-style: italic;
  margin-bottom: 6px;
`;

interface Props {
  className?: string;
  header?: string;
  helpText?: string;
}
export const Overview: FunctionComponent<Props> = ({ className, header, helpText }) => (
  <div className={className}>
    <Header>{header}</Header>
    <HelpText>{helpText}</HelpText>
  </div>
);

export const Wrapper = styled.div`
  background-color: ${props => props.theme.flatWhite};
  padding: 16px;
  margin-bottom: 8px;
`;
