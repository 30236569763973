import { Guid } from '@/types';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useImageList } from '@/services/imageServices';
import { useModalContext } from '@/components/modal/ModalContext';
import { Loader } from '@instech/components';
import { ImageInsert } from './views/ImageInsert';
import { UploadImageContainer } from './core/UploadImageContainer';

interface SelectImagesModalProps {
  selectedImages: Guid[];
  onSelect: (ids: Guid[]) => void;
}
export const SelectImagesModal = ({ selectedImages, onSelect }: SelectImagesModalProps) => {
  const caseId = useCaseIdFromUrl();
  const { images } = useImageList(caseId);
  const { close } = useModalContext();

  const insertImagesHandler = async (ids: Guid[]) => {
    onSelect(ids);
    close();
  };

  if (!images) return <Loader />;

  return (
    <>
      <UploadImageContainer />
      <ImageInsert
        imageIds={images}
        selectedImages={selectedImages}
        insertImages={insertImagesHandler}
      />
    </>
  );
};
