import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { CheckboxField } from '@/components/shared/Form/FormFields';
import { ListContainer } from './Components';

const Checkbox = styled(CheckboxField)`
  & label {
    width: 100%;
    color: ${props => props.theme.marineBlue};
  }
  & input[type="checkbox"] {
    padding: 8px;
    margin-right: 16px;
    &::before {
      background: transparent;
      box-shadow: none;
      border: 2px solid ${props => props.theme.marineBlue50};
      border-radius: 2px;
    }
    &::after {
      bottom: 13px;
      left: 14px;
      border-width: 0px;
    }
    &:checked::after {
      width: 4px;
      height: 12px;
      border-width: 2px;
    }
  }
`;

// TODO: Improve component
const NoData = () => (
  <div>No data</div>
);

interface Props {
  id: string;
  isLoading: boolean;
  facets: any;
  selectedFacets: any;
  error?: any;
  toggleFacet: (facet: any) => any;
}
export const FilterList: FunctionComponent<Props> = ({ id, isLoading, facets, selectedFacets, error, toggleFacet }) => (
  <ListContainer>
    {!isLoading && facets
      ? facets.map((facet: any, i: number) => (
        <Checkbox key={`${id}-checkbox-${facet}`}>
          <label htmlFor={`${id}-checkbox-${facet}`}>
            <input
              type="checkbox"
              id={`${id}-checkbox-${facet}`}
              checked={selectedFacets.includes(facet)}
              onChange={() => toggleFacet(facet)}
            />
            {facet}
          </label>
        </Checkbox>
      ))
      : <NoData />}
  </ListContainer>
);
