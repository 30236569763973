import PropTypes from 'prop-types';
import 'styled-components/macro';
import { TextField, FormikWithPrompt as Formik } from '@instech/components';
import styled from 'styled-components';
import { Form } from 'formik';
import useSWR, { mutate } from 'swr';
import { updateContactAsync } from '@/services/contactsServices';
import { TableCell } from '@/components/shared/Table';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import { contactSchema } from './core/contactSchema';
import { sortByName } from './core/sortByName';

const FormTableCell = styled(TableCell)`
  justify-content: flex-start;
`;

export const ContactRowEdit = ({ data, toggleEditMode, caseId }) => {
  const swrRoute = `case/${caseId}/contacts`;

  const { data: contacts } = useSWR(swrRoute);

  const onSubmit = async values => {
    await updateContactAsync(caseId, values);
    const newContacts = contacts.filter(x => x.id !== values.id);
    newContacts.push(values);
    newContacts.sort(sortByName);
    void mutate(swrRoute, newContacts);
    toggleEditMode();
  };

  return (
    <Formik
      css="display: contents"
      initialValues={data}
      validationSchema={contactSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, dirty }) => (
        <Form css="display: contents">
          <FormTableCell>
            <TextField
              name="name"
              placeholder="Name"
              noLabel
            />
          </FormTableCell>
          <FormTableCell>
            <TextField
              name="function"
              placeholder="Function"
              noLabel
            />
          </FormTableCell>
          <FormTableCell>
            <TextField
              name="phone"
              placeholder="Phone"
              noLabel
            />
          </FormTableCell>
          <FormTableCell>
            <TextField
              name="email"
              placeholder="Email"
              noLabel
            />
            <SaveCloseButtons
              saveLabel="Save"
              closeLabel="Cancel"
              onSave={handleSubmit}
              disableSave={!dirty}
              isSubmitting={isSubmitting}
              onClose={toggleEditMode}
              alignRight
              reverse
            />
          </FormTableCell>
          <FormTableCell />
        </Form>
      )}
    </Formik>
  );
};
ContactRowEdit.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    function: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
  toggleEditMode: PropTypes.func,
  caseId: PropTypes.string
};
