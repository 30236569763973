import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { childrenPropType } from '../../../prop-types/custom-prop-types';

const activeClassName = 'active';
const StyledToolButton = styled.a.attrs({ activeClassName })`
  text-align: center;
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.white};
  padding: 10px 5px;
  margin: 4px;
  width: 105px;
  height: 84px; /* Make space for two text lines on tablet */
  box-sizing: border-box;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
  transition: border-color 0.7s ease;
  cursor: pointer;
  /* Mobile nav display on the Edit page */
  ${props => props.editTools && css`
    width: calc((100% - 60px) / 6);
  `}
  /* All desktop displays */
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    width: calc((100% - 30px) / 3);
    height: 72px;  
    flex-shrink: 0;
    padding: 10px 0px;
  };  
  &.${activeClassName} {
    border: 1px solid ${props => props.theme.marineBlue};
  }
`;

export const TextContainer = styled.div`
  color: ${props => props.theme.black};
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 13px;
  margin-top: 8px;
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    /* Smaller font sizes on desktop edit sidebar */
    ${props => props.editTools && css`
        font-size: 11px;
        margin-top: 2px; 
    `}
  }
`;

const Container = styled.div`
  line-height: 14px;
  padding: 0px 3px;
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    /* Smaller font sizes on desktop edit sidebar */
    ${props => props.editTools && css`
        line-height: 12px;
    `}
  }
`;

// This component has a className so that a restyling can be applied to the dashboard
// without touching the editTools version of the same buttons.
export const ToolButton = ({ children, icon: Icon, onClick, editTools, className }) => (
  <StyledToolButton onClick={onClick} editTools={editTools} className={className}>
    <Container editTools={editTools}>
      {Icon && <div>{Icon}</div>}
      <TextContainer editTools={editTools}>{children}</TextContainer>
    </Container>
  </StyledToolButton>
);

ToolButton.propTypes = {
  children: childrenPropType,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  editTools: PropTypes.bool,
  className: PropTypes.string
};
