import { FC } from 'react';
import styled from 'styled-components';
import { Add, FloppyDisk } from '@instech/icons';
import {
  BoxButton, ButtonGroup, LinkButton, SlimButton
} from '@instech/components';
import { SmallLoader } from '../Loader';
import { NewBoxButton } from '../BoxButton/NewBoxButton';

const ButtonWrapper = styled.div<{ marginTop?: string }>`
  margin-top: ${props => props.marginTop || '0px'};
`;

interface AddButtonProps {
  label?: string;
  inverted?: boolean;
  onClick: () => any;
}
/**
 * Renders a button with a (+) add symbol in front and the default label 'Add'.
 */
export const AddButton: FC<AddButtonProps> = ({ label = 'Add', inverted = false, onClick }) => (
  <BoxButton startIcon={<Add />} onClick={onClick} inverted={inverted}>
    {label}
  </BoxButton>
);

interface SaveButtonProps {
  label?: string;
  title?: string;
  onClick?: () => any;
  disabled?: boolean;
  isSubmitting?: boolean;
  width?: string;
  padding?: string;
  noIcon?: boolean;
  fontSize?: string;
}
/**
 * Renders a button with the default label 'Save', with or without a floppy disk icon.
 * If the flag 'isSubmitting' is set, the text and icon will be hidden and a loader icon
 * will be displayed instead; can be bound directly to Formik `isSubmitting` when
 * used inside of a form.
 */
export const SaveButton: FC<SaveButtonProps> = ({
  label = 'Save',
  title = 'Save',
  onClick,
  disabled,
  isSubmitting,
  width,
  padding,
  noIcon,
  fontSize
}) => {
  // Determine what baked width to use, to prevent Loader width-jumping
  const buttonWidth = width ?? (noIcon ? '80px' : '114px');
  const handleClick = (!isSubmitting && !disabled) ? onClick : undefined;
  return (
    <BoxButton
      title={title}
      startIcon={(!isSubmitting && !noIcon) ? <FloppyDisk /> : undefined}
      disabled={!isSubmitting && disabled}
      onClick={handleClick}
      width={buttonWidth}
      padding={padding}
      fontSize={fontSize}>
      {isSubmitting ? <SmallLoader /> : label}
    </BoxButton>
  );
};

interface CloseButtonProps {
  label?: string;
  onClick?: () => any;
  disabled?: boolean;
}
/**
 * Renders a button with the default label 'Close'.
 */
export const CloseButton: FC<CloseButtonProps> = ({ label = 'Close', onClick, disabled }) => (
  <LinkButton height="47px" onClick={onClick} disabled={disabled}>
    {label}
  </LinkButton>
);

interface ToggleInlineButtonProps {
  label: string;
  onClick?: () => any;
  marginTop?: string;
}
/**
 * Renders a LinkButton with a (+) Add icon, intended to be used for toggling inline
 * forms inputs in forms where elements appear as a list. Company's Particulars and
 * Vessel Information are two examples of such forms.
 */
export const ToggleInlineButton: FC<ToggleInlineButtonProps> = ({ label, onClick, marginTop }) => (
  <ButtonWrapper marginTop={marginTop}>
    <LinkButton startIcon={<Add />} onClick={onClick} height="38px">
      {label}
    </LinkButton>
  </ButtonWrapper>
);

interface ModalFormButtonsProps {
  onSave: () => void;
  onCancel: () => void;
  isDisabled?: boolean;
  isPending?: boolean;
}
/**
 * Newer set of FormButtons for use with a form rendered in a Modal. Explicitly
 * renders a set of two buttons; a gray "Cancel" button and a blue "Save" button.
 * Both buttons will align to the right, with a 24px spacing to the elements above.
 */
export const ModalFormButtons: FC<ModalFormButtonsProps> = ({ onSave, onCancel, isDisabled, isPending }) => (
  <ButtonGroup alignRight marginTop="24px">
    <SlimButton variant="secondary" width="150px" onClick={onCancel}>
      Cancel
    </SlimButton>
    <NewBoxButton lowercase width="150px" disabled={isDisabled} onClick={onSave}>
      {!isPending ? 'Save' : <SmallLoader />}
    </NewBoxButton>
  </ButtonGroup>
);
