import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ChevronToggle } from '@/components/shared/Chevron';
import { ChapterEntriesEdit } from './ChapterEntries';

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: bold;
    @media (min-width: ${props => props.theme.breakpoints.tablet}) {
       border-bottom: 1px solid ${props => props.theme.border.white};
       cursor: pointer;
    };
`;

const ChevronWrapper = styled.div`
  display: none;
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    display: block;
  }
`;

const ChapterBlock = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.tabletMax}) {
    &:nth-child(-n+3) {
      border-bottom: 1px solid ${props => props.theme.border.white};
    }
  }
`;

export const ChapterExpander = ({ title, entries }) => {
  const [isExpanded, setExpanded] = useState(true);
  return (
    <ChapterBlock>
      <Header data-testid="chapter-expander-header" onClick={() => setExpanded(value => !value)}>
        <span>{title}</span>
        <ChevronWrapper>
          <ChevronToggle open={isExpanded} />
        </ChevronWrapper>
      </Header>
      <ChapterEntriesEdit isExpanded={isExpanded} entries={entries} />
    </ChapterBlock>
  );
};

ChapterExpander.propTypes = {
  title: PropTypes.string.isRequired,
  entries: PropTypes.arrayOf(PropTypes.object).isRequired
};
