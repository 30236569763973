import * as Yup from 'yup';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { Form } from 'formik';
import { Introduction } from '@/types';
import { FullwidthWidgetWrapper } from '@/components/pages/edit/widgets/FullwidthWidgetWrapper';
import {
  Loader, ButtonGroup, Divider, FormField, FormRow, TextArea, FormikWithPrompt as Formik, Checkbox
} from '@instech/components';
import { FrontpageImageUploader } from '@/components/pages/report/shared/components/FrontpageImage';
import { SaveButton } from '@/components/shared/FormButton/FormButton';
import { stringMaxLength } from '@/utils/validationSchemas';
import { EditPane } from '../../core/EditPane';
import {
  updateIntroductionAsync, useIntroduction, useIntroductionInfoText
} from './introductionService';
import { InformationHeader } from './utility';

const validationSchema = Yup.object().shape({
  comment: stringMaxLength(500)
});

const AttendanceContent = styled.div`
  display: flex;
  color: ${({ theme }) => theme.marineBlue};
  margin-top: 10px;
`;

const BoldText = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
  color: ${({ theme }) => theme.marineBlue};
`;

interface IntroductionFormProps {
  introduction: Introduction;
  onSubmit: (widget: any) => Promise<any>;
}
const IntroductionFormInner = ({ introduction, onSubmit }: IntroductionFormProps) => (
  <Formik
    formId={introduction.id}
    initialValues={introduction}
    validationSchema={validationSchema}
    onSubmit={async (values, helpers) => {
      const result = await onSubmit(values);
      if (result) {
        helpers.resetForm({ values: result });
      }
    }}>
    {({ dirty, isSubmitting, handleSubmit }) => (
      <Form>
        <BoldText>The survey was performed...</BoldText>
        <FormRow>
          <AttendanceContent>
            <FormField>
              <Checkbox
                name="attendance"
                formId={introduction.id}
                rightLabel="with attendance"
                noTopLabel
              />
            </FormField>
            <FormField>
              <Checkbox
                name="remote"
                formId={introduction.id}
                rightLabel="by Remote Survey"
                noTopLabel
              />
            </FormField>
            <FormField>
              <Checkbox
                name="withoutAttendance"
                formId={introduction.id}
                rightLabel="without attendance"
                noTopLabel
              />
            </FormField>
          </AttendanceContent>
        </FormRow>
        <FormRow gutter="40px">
          <FormField span={12}>
            <TextArea
              name="comment"
              label="Additional introduction text"
              placeholder="Additional introduction text"
              maxLength={500}
            />
          </FormField>
        </FormRow>
        <Divider />
        <ButtonGroup alignRight>
          <SaveButton
            disabled={!dirty}
            isSubmitting={isSubmitting}
            onClick={handleSubmit}
          />
        </ButtonGroup>
      </Form>
    )}
  </Formik>
);

const ContentArea = styled.div`
  max-width: 750px;
  margin: 0 auto;
`;

export const IntroductionForm = () => {
  const { id: caseId } = useParams<{ id: string }>();
  const introduction = useIntroduction(caseId);
  const { data: infoText } = useIntroductionInfoText();

  if (!introduction) {
    return <Loader />;
  }

  const handleSubmit = async (widget: any) => {
    const request: Introduction = {
      id: introduction.id,
      comment: widget.comment,
      attendance: widget.attendance,
      remote: widget.remote,
      withoutAttendance: widget.withoutAttendance
    };

    const result = await updateIntroductionAsync(caseId, request);
    return (result) as any;
  };

  return (
    <FullwidthWidgetWrapper>
      <FrontpageImageUploader />
      <EditPane title="Introduction">
        <ContentArea>
          <BoldText>Introduction text</BoldText>
          <InformationHeader>
            {infoText}
          </InformationHeader>
          <IntroductionFormInner
            introduction={introduction}
            onSubmit={handleSubmit} />
        </ContentArea>
      </EditPane>
    </FullwidthWidgetWrapper>
  );
};
