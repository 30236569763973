/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Pane = styled.div`
  background-color: #fff;
  ${props => !props.noBorder && css`
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
    border: 2px solid ${props.theme.border.blueGray};
  `};
`;

const GridTable = styled.div`
  display: grid;  
  background-color: ${props => props.theme.flatWhite};
  grid-template-columns: ${props =>
    props.layout ? props.layout :
      props.columns ? `repeat(${props.columns}, auto)` :
        ''};
`;

export const Table = ({ layout, columns, noBorder, children }) => (
  <Pane noBorder={noBorder}>
    <GridTable layout={layout} columns={columns}>
      {children}
    </GridTable>
  </Pane>
);
Table.propTypes = {
  layout: PropTypes.string,
  columns: PropTypes.number,
  noBorder: PropTypes.bool,
  children: PropTypes.node.isRequired
};
