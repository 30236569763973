import { Widget } from '@/utils/manifestTypes';
import useSWR from 'swr';
import { DescriptionWidget, Subcategory } from '@/types';
import client, { postJsonAsync } from './client';
import { refreshCachedManifest } from './manifestServices';

export const useDescription = (caseId: string, chapterId: string, widgetId: string, swrOptions: any) => {
  const requestUrl = `/description/case/${caseId}/chapters/${chapterId}/widget/${widgetId}`;
  return useSWR(requestUrl, swrOptions);
};

// FIXME: BROKEN
// TODO: Should be refactored to a chapter service
export const createSubSectionsAsync = async (caseId: string, chapterId: string, widgets: Widget[]) => {
  const url = `case/${caseId}/chapters/${chapterId}`;
  const { data } = await client.post(url, widgets);
  return data;
};

export const useSubcategoriesService = (caseId: string, chapterId: string) => {
  const url = `/description/case/${caseId}/chapters/${chapterId}/subcategories`;
  const { data, mutate } = useSWR<Subcategory[]>(url);
  const update = async (subcategories: Subcategory[]) => {
    const result = await postJsonAsync<Subcategory[]>(url, subcategories);
    void mutate(result);
    await refreshCachedManifest(caseId);
    return result;
  };
  return {
    subcategories: data,
    update
  };
};

export const updateDescriptionAsync = async (caseId: string, chapterId: string, widgetId: string, description: DescriptionWidget) => {
  const url = `/description/case/${caseId}/chapters/${chapterId}/widget/${widgetId}`;
  const { data } = await client.put<DescriptionWidget>(url, description);
  return data;
};
