import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { NavLink, useParams } from 'react-router-dom';
import {
  editRoute, reportPreviewRoute, reportViewRoute
} from '@/utils/routeTemplates';
import { Guid } from '@/types';
import { getUrlFriendlyString } from '@/utils/text';

const EntryItem = styled.span`
  display: block;
  text-decoration: none;
  position: relative;
  padding: 12px;
  cursor: pointer;
  &::after {
    content: '';
    left: -2px;
    top: 18px;
    position: absolute;
    box-sizing: border-box;
    height: 9px;
    width: 9px;
    border: 1px solid transparent;
    background-color: transparent;
    border-radius: 50%;
    transition: border-color, background-color 0.7s ease-in;
  }
  &.active {
    color: ${props => props.theme.marineBlue};
    &::after {
      border-color: ${props => props.theme.marineBlue};
      background-color: ${props => props.theme.mediumBlue};
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    margin-left: 12px; 
    &:hover::after {
      border-color: ${props => props.theme.mediumBlue};
      background-color: ${props => props.theme.mediumBlue};
    }
    &::after {
      left: -9px;
      top: 16px;
      height: 11px;
      width: 11px;
    }
  }
`;

const ChapterList = styled.div<{ visible?: boolean }>`    
  padding: 12px;
  position: relative;
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    display: ${props => props.visible ? 'block' : 'none'};       
    background-color: ${props => props.theme.darkWhite};
    border-bottom: 1px solid ${props => props.theme.border.white};
    &::before {
      content: '';
      position: absolute;
      width: 1px;
      background-color: rgba(28, 55, 117, 0.5);
      top: 0; 
      bottom: 0;
      left: 23px;
      margin-left: -3px;
    }
  }
`;

interface ParamsInterface {
  id: Guid;
}

interface Entry {
  id?: string, title: string
}

interface EntriesInterfaceView {
  entries: Entry[];
  version: string;
}

interface ChapterEntriesInterface {
  entries: Entry[],
  onClick?: () => any;
}

interface ChapterEntriesEditInterface {
  entries: Entry[];
  isExpanded?: boolean;
}

interface GenericEntriesInterface {
  entries: Entry[];
  onClick?: () => any;
  isExpanded?: boolean;
  routeResolver: (entryId: string) => string;
}

export const NavEntriesList: FunctionComponent<GenericEntriesInterface> = ({ isExpanded, entries, onClick, routeResolver }) => (
  <ChapterList data-testid="chapter-entries" visible={isExpanded}>
    {entries.map(({ id, title }) =>
      (
        <EntryItem as={NavLink} key={id} to={routeResolver(id as string)} onClick={onClick}>{title}</EntryItem>
      ))}
  </ChapterList>
);

export const ChapterEntriesEdit: FunctionComponent<ChapterEntriesEditInterface> = ({ isExpanded, entries }) => {
  const { id: caseId }: ParamsInterface = useParams();
  const routeResolver = (entryId: string) => `${editRoute(caseId)}/chapter/${entryId}`;
  return (
    <NavEntriesList isExpanded={isExpanded} entries={entries} routeResolver={routeResolver} />
  );
};

export const ChapterEntriesReportPreview: FunctionComponent<ChapterEntriesInterface> = ({ entries, onClick }) => {
  const { id: caseId }: ParamsInterface = useParams();
  return (
    <ChapterList data-testid="report-entries" visible>
      {entries.map(({ title }) => {
        const urlTitle = getUrlFriendlyString(title);
        return (
          <EntryItem as={NavLink} key={urlTitle} to={`${reportPreviewRoute(caseId)}#${urlTitle}`} isActive={() => false} onClick={onClick}>
            {title}
          </EntryItem>
        );
      })}
    </ChapterList>
  );
};

export const ChapterEntriesReportView: FunctionComponent<EntriesInterfaceView> = ({ entries, version }) => {
  const { id: caseId }: ParamsInterface = useParams();
  return (
    <ChapterList data-testid="report-entries" visible>
      {entries.map(({ title }) => {
        const urlTitle = getUrlFriendlyString(title);
        return (
          <EntryItem as={NavLink} key={urlTitle} to={`${reportViewRoute(caseId, version)}#${urlTitle}`} isActive={() => false}>{title}</EntryItem>
        );
      })}
    </ChapterList>
  );
};
