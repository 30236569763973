import * as Yup from 'yup';
import {
  datePickerRequired, stringMaxLength, stringRequired, stringRequiredMaxLength
} from '@/utils/validationSchemas';
import { getCurrentTimezoneOrDefault } from '@/utils/date';
import { EventFormModel, eventTypes } from './types';

export const eventModel: EventFormModel = {
  fieldSchema: {},
  eventType: '',
  movementType: null,
  typeOfActivity: null,
  location: null,
  eventDate: null,
  eventTime: '',
  timezoneId: getCurrentTimezoneOrDefault(),
  timeStatus: '',
  vesselStatus: '',
  customActivityName: '',
  occurrences: [],
  comment: '',
  includeInReport: false
};

export const addEventModel: EventFormModel = {
  fieldSchema: {},
  eventType: '',
  movementType: null,
  typeOfActivity: null,
  location: null,
  eventDate: null,
  eventTime: '',
  timeStatus: '',
  timezoneId: getCurrentTimezoneOrDefault(),
  vesselStatus: '',
  customActivityName: '',
  occurrences: [],
  comment: '',
  includeInReport: false
};

// Conditional validation schema
export const eventSchema = Yup.object().shape({
  fieldSchema: Yup.object().shape({ selectOccurrences: Yup.boolean(), useCustomName: Yup.boolean() }),
  location: Yup.object().shape({ label: Yup.string().required(), value: Yup.string().required() }).typeError('Required'),
  eventDate: datePickerRequired(),
  eventTime: Yup.string().nullable(),
  timezoneId: Yup.string().nullable().required('Required'),
  timeStatus: stringRequired(),
  customActivityName: Yup.string().when('fieldSchema.useCustomName', {
    is: true,
    then: stringRequiredMaxLength(),
    otherwise: stringMaxLength()
  }),
  occurrences: Yup.array().when('fieldSchema.selectOccurrences', {
    is: true,
    then: Yup.array().required('Required'),
    otherwise: Yup.array().nullable()
  }),
  comment: stringMaxLength(256),
});

export const getFieldStates = (values: any, schema: any) => {
  // Return schema if one exists
  if (values.typeOfActivity) {
    if (values.eventType === eventTypes.vesselMovement) {
      return schema.VesselMovement[values.typeOfActivity.value];
    }
    if (values.eventType === eventTypes.event) {
      return schema.Event[values.typeOfActivity.value];
    }
    if (values.eventType === eventTypes.vesselStatus) {
      return schema.Status[values.typeOfActivity.value];
    }
  }

  // Catch lacking schema and return empty
  if (values.eventType && !values.typeOfActivity) {
    return schema.emptySchema;
  }
  return null;
};

export const clearInvalidFields = (values: any, states: any) => {
  const newValues = { ...values };
  if (!states.selectOccurrences) newValues.occurrences = null;
  if (!states.useCustomName) newValues.customActivityName = null;
  return newValues;
};
