import { FunctionComponent } from 'react';
import { Loader, Pane } from '@instech/components';
import { useVesselInformation } from '@/components/tools/VesselParticulars/information/useVesselInformation';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useTotalClaimHandlingDays } from '@/services/caseStatusServices';
import { useClaimsOccurrence } from '@/services/claimsOccurrenceServices';
import { useUserInfo } from '@/services/userInfoService';
import { useNewestReport } from '@/services/versionsService';
import { VesselInformation } from '@/services/vesselInformationServices';
import { Case, ClaimOccurrence } from '@/types';
import { Assignment, Boat } from '@instech/icons';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { CurrentClaimsHandler } from './CurrentClaimsHandler';
import { FrontImage } from './FrontImage';
import { LatestReport } from './LatestReport';
import { Field, VesselInfo } from './VesselInfo';

const Layout = styled.div`
  display: flex;
  width: 100%;
`;

const DetailArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin-left: 80px;
`;

const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin-top: 12px;
  margin-bottom: 16px;
`;

const getFields = (vesselInfo: VesselInformation[] | undefined, claimOccurrence: ClaimOccurrence | undefined, caseData: Case | undefined): Field[] => {
  const fields: Array<Field> = [];
  // Create the list of fields to display
  const imo = vesselInfo?.filter(item => item.title === 'IMO-Number')![0];
  fields.push({ id: imo?.id ?? uuidv4(), title: 'IMO number', value: imo?.value ?? '-', icon: Boat });
  // Add the fleet name
  const fleetName = claimOccurrence?.fleetName;
  fields.push({ id: uuidv4(), title: 'Fleet name', value: fleetName ?? '-', icon: Boat });
  // Add the build year
  const built = vesselInfo?.filter(item => item.title === 'Built')![0];
  fields.push({ id: built?.id ?? uuidv4(), title: 'Year Built', value: built?.value ?? '-', icon: Boat });
  // Add the class name
  const className = claimOccurrence?.className;
  fields.push({ id: uuidv4(), title: 'Class', value: className ?? '-', icon: Boat });
  // Add the policy currency
  const policyCurrency = caseData?.metadata.policyCurrency;
  fields.push({ id: uuidv4(), title: 'Policy currency', value: policyCurrency ?? '-', icon: Assignment });

  return fields;
};

interface ClaimDetailsProps {
  caseData: Case
}
export const ClaimDetails: FunctionComponent<ClaimDetailsProps> = ({ caseData }) => {
  const caseId = useCaseIdFromUrl();
  const claimsHandlerId = caseData?.users?.primaryClaimsHandlerId;
  const claimId = caseData?.metadata.claimId;
  const { data: claimOccurrence, isValidating } = useClaimsOccurrence(claimId);
  const { data: userInfo, error: userInfoError } = useUserInfo(claimsHandlerId);
  const { data: report } = useNewestReport(caseId);
  const { data: vesselInfo, error: vesselInfoError } = useVesselInformation();
  const { data: handlingDays } = useTotalClaimHandlingDays();

  if (userInfoError || vesselInfoError) {
    throw new Error(userInfoError || vesselInfoError);
  }

  // Report comes undefined while loading and empty string if it fetches no value,
  // so here it just tests if the data has loaded to avoid content-popping when it does
  if (claimsHandlerId && (!userInfo || !vesselInfo || (claimId && (!claimOccurrence && isValidating)) || report === undefined || !handlingDays)) {
    return <Loader />;
  }
  const fields = getFields(vesselInfo, claimOccurrence, caseData);
  const daysText = `Total handling days: ${handlingDays?.totalClaimHandlingDays}`;

  // Using nonbreaking space to emulate lack of title
  // Hopefully that will cover it, for lining up the layout?
  return (
    <Pane subTitle={daysText}>
      <Layout>
        <FrontImage caseId={caseId} />
        <DetailArea>
          <HeadingRow>
            {vesselInfo && <VesselInfo items={fields} />}
            <CurrentClaimsHandler userInfo={userInfo} claimId={claimId} />
          </HeadingRow>
          {report && <LatestReport {...report} />}
        </DetailArea>
      </Layout>
    </Pane>
  );
};
