import { FunctionComponent, useState } from 'react';
import { BoxButton } from '@instech/components';
import { downloadTermsOfUsePdf } from '@/services/termsOfUseServices';
import { SmallLoader } from '@/components/shared/Loader';

const invokeDownload = (url: string, name: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  if (link.parentNode) link.parentNode.removeChild(link);
};

// Upon click, fetch file and then return to user
export const DownloadButton: FunctionComponent = () => {
  const [isPending, setIsPending] = useState(false);

  const handleDownloadPdf = async () => {
    setIsPending(true);
    const result = await downloadTermsOfUsePdf();
    if (result.status === 200) {
      invokeDownload(result.data, 'survey-terms-of-use.pdf');
      setTimeout(() => setIsPending(false), 300);
    } else {
      // Else, how does this function handle itself if it fails?
      setTimeout(() => setIsPending(false), 300);
    }
  };

  return (
    <BoxButton width="125px" onClick={handleDownloadPdf}>
      {isPending ? <SmallLoader /> : 'Download as PDF'}
    </BoxButton>
  );
};
