import { useState } from 'react';
import { useParams } from 'react-router';
import useSWR from 'swr';
import client from '@/services/client';
import { RepairDescription } from '@/types';

export const repairDescriptionUrl = (caseId: string, chapterId: string, widgetId: string) =>
  `/repairdescription/case/${caseId}/chapters/${chapterId}/widget/${widgetId}`;

export const useRepairDescriptionViewService = (caseId: string, chapterId: string, widgetId: string) => {
  const apiRoute = repairDescriptionUrl(caseId, chapterId, widgetId);
  const { data, error } = useSWR<RepairDescription>(apiRoute);
  if (error) {
    throw error;
  }
  return [data];
};

export const updateRepairDescriptionAsync = async (
  caseId: string,
  chapterId: string,
  widgetId: string,
  item: RepairDescription
) => {
  const url = repairDescriptionUrl(caseId, chapterId, widgetId);
  const { data } = await client.put<RepairDescription>(url, item);
  return data;
};

export const useRepairDescription = (
  widgetId: string
) => {
  const [updateError, setUpdateError] = useState('');
  const { chapterId, id: caseId } = useParams<{ chapterId: string, id: string }>();
  const url = repairDescriptionUrl(caseId, chapterId, widgetId);
  const { data, mutate } = useSWR<RepairDescription>(url, { suspense: true });

  if (updateError) {
    throw new Error(updateError);
  }

  const update = async (newItem: RepairDescription) => {
    try {
      void mutate(newItem); // Update cached value optimistically to avoid "blinking" on 'Save' button
      const result = await updateRepairDescriptionAsync(caseId, chapterId, widgetId, newItem);
      void mutate(result);
      return result;
    } catch (errorMessage) {
      setUpdateError(errorMessage as string);
    }
    return undefined;
  };

  return [data, update] as [RepairDescription, (newItem: RepairDescription) => Promise<RepairDescription | undefined>];
};
