import { EditPage } from '@/components/layout/Page';
import { useState } from 'react';
import {
  Redirect,
  Route,
  Switch
} from 'react-router';
import { Content, Sidebar } from '@/components/layout/EditGrid';
import { NavigationHeader } from '@/components/layout/navigation/core/NavigationHeader';
import { NavSections, NavWrapperInner } from '@/components/layout/navigation/core/Components';
import { ReportNavContainer, ReportNavWrapperOuter } from '@/components/layout/navigation/core/ReportComponents';
import { NavEntriesList } from '@/components/layout/navigation/core/Chapter/ChapterEntries';
import { useFeatureFlag } from '@/providers/featureFlagProvider';
import { CompanyProfile } from './widgets/CompanyProfile';
import { Support } from './widgets/Support';
import { TermsOfUse } from './widgets/TermsOfUse';
import { Logout } from './widgets/Logout';
import { ProfileSettings } from './widgets/ProfileSettings';

const navEntries = [
  { id: 'profilesettings', title: 'Profile' },
  { id: 'support', title: 'Support' },
  { id: 'companyprofile', title: 'Company Profile' },
  { id: 'termsofuse', title: 'Terms of Use' },
  { id: 'logout', title: 'Log Out' },
];

const navEntriesRemoteMenu = [
  { id: 'profilesettings', title: 'Profile' },
  { id: 'companyprofile', title: 'Company Profile' },
  { id: 'termsofuse', title: 'Terms of Use' },
];

const ProfileSidebar = () => {
  const [showContents, setShowContents] = useState(true);
  const enableRemoteUserMenu = useFeatureFlag('remoteUserMenu');

  const onOpenMenu = () => setShowContents(!showContents);
  const onClickMenuItem = () => setShowContents(true);

  return (
    <ReportNavContainer>
      <NavigationHeader
        title="Back"
        to="/"
        menuOpen={!showContents}
        onClick={onOpenMenu}
      />
      <ReportNavWrapperOuter data-testid="navigation-section-wrapper" visible={!showContents}>
        <NavWrapperInner>
          <NavSections>
            {enableRemoteUserMenu ? (
              <NavEntriesList
                        isExpanded
                        routeResolver={id => `/profile/${id}`}
                        entries={navEntriesRemoteMenu}
                        onClick={onClickMenuItem}
                      />
            ) : (
              <NavEntriesList
              isExpanded
              routeResolver={id => `/profile/${id}`}
              entries={navEntries}
              onClick={onClickMenuItem}
            />
            )}
          </NavSections>
        </NavWrapperInner>
      </ReportNavWrapperOuter>
    </ReportNavContainer>
  );
};

const ProfileRoutes = () => {
  const enableRemoteUserMenu = useFeatureFlag('remoteUserMenu');

  return (
    <Switch>
      <Route path="/profile/profilesettings">
        <ProfileSettings />
      </Route>
      <Route path="/profile/companyprofile">
        <CompanyProfile />
      </Route>
      {!enableRemoteUserMenu && (
      <Route path="/profile/support">
        <Support />
      </Route>
      )}
      <Route path="/profile/termsofuse">
        <TermsOfUse preTitle="My profile" />
      </Route>
      {!enableRemoteUserMenu && (
      <Route path="/profile/logout">
        <Logout />
      </Route>
      )}
      <Redirect exact from="/profile" to="/profile/profilesettings" />
    </Switch>
  );
};

export const ProfilePage = () => (
  <EditPage>
    <Sidebar>
      <ProfileSidebar />
    </Sidebar>
    <Content visible>
      <ProfileRoutes />
    </Content>
  </EditPage>
);
