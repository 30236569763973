import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TableColumnHeader } from '../types';
import {
  TitleContainer, Subtitle, TableHeader
} from './Components';

const StyledHeader = styled(TableHeader) <{ center?: boolean; }>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.center ? 'center' : 'flex-start'};
  height: 100%;
  // Have to handle the component padding a bit differently between the two
  padding: 16px 8px;
  &:first-child {  padding-left: 16px; }
  &:last-child {  padding-right: 16px; }
`;

interface Props {
  header: TableColumnHeader;
}
export const NonFilterableHeader: FunctionComponent<Props> = ({ header }) => (
  <StyledHeader center={header.center}>
    <TitleContainer>
      {header.title}
      <Subtitle>{header.subtitle}</Subtitle>
    </TitleContainer>
  </StyledHeader>
);
