import { CompanyParticular } from '@/types';
import {
  FormField,
  FormRow
} from '@instech/components';
import { FC } from 'react';
import { NoContent } from '../../shared/NoContent';
import { ParticularField } from '../core/ParticularField';

interface Props {
  particulars: CompanyParticular[];
}
export const CompanyParticularsReadOnlyView: FC<Props> = ({ particulars }) => {
  if (particulars.length === 0) {
    return <NoContent text="No company particulars added" />;
  }

  return (
    <>
      {particulars.map(item => (
        <FormRow key={item.id} marginBottom="12px">
          <FormField>
            <ParticularField
              title={item.title}
              description={item.description}
              source={item.source}
            />
          </FormField>
        </FormRow>
      ))}
    </>
  );
};
