import { useState } from 'react';
import { Guid } from '@/types';
import { ButtonGroup, BoxButton } from '@instech/components';
import { ImagePreview } from '@/services/imageServices';
import { SelectedImages } from '../core/SelectedImages';
import { EditImageForm } from '../core/ImageForm/EditImageForm';
import { ContentGrid, Sidebar } from '../core/ContentGrid';
import { ImageGrid } from '../core/ImageGrid';
import { NoImagesUploaded } from '../core/NoImagesUploaded';

const addOrRemove = (entries: Guid[], itemId: Guid) => {
  const alreadySelected = entries.find(e => e === itemId);
  if (alreadySelected) {
    return entries.filter(e => e !== itemId);
  }
  return [...entries, itemId];
};

interface ImageInsertProps {
  imageIds: Guid[];
  selectedImages: Guid[];
  insertImages: (images: Guid[]) => void;
}
export const ImageInsert = ({ imageIds, selectedImages, insertImages }: ImageInsertProps) => {
  const [selectedIds, setSelectedIds] = useState<Guid[]>(selectedImages || []);
  const [editingImage, setEditingImage] = useState<ImagePreview | null>(null);

  const onSelectImage = (image: ImagePreview) => {
    setSelectedIds(prev => addOrRemove(prev, image.id));
  };

  const onRemoveImage = ({ id }: ImagePreview) => {
    setSelectedIds(prev => prev.filter(e => e !== id));
  };

  if (imageIds.length < 1) {
    return <NoImagesUploaded />;
  }

  return (
    <ContentGrid>
      <ImageGrid
        imageIds={imageIds}
        selectedIds={selectedIds}
        onSelectImage={onSelectImage}
        disableSelect={!!editingImage}
      />
      <Sidebar title={editingImage ? 'Edit image' : 'Selected images'}>
        {!editingImage && (
          <>
            <SelectedImages
              imageIds={selectedIds}
              onEdit={image => setEditingImage(image)}
              onRemove={image => onRemoveImage(image)}
            />
            <ButtonGroup alignRight marginTop="30px">
              <BoxButton onClick={() => insertImages(selectedIds)}>
                Include
              </BoxButton>
            </ButtonGroup>
          </>
        )}
        {editingImage && (
          <EditImageForm
            image={editingImage}
            onSave={() => setEditingImage(null)}
            onClose={() => setEditingImage(null)}
          />
        )}
      </Sidebar>
    </ContentGrid>
  );
};
