import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useImageList } from '@/services/imageServices';
import { Loader } from '@instech/components';
import { ImageLibraryReadOnly } from './views/ImageLibraryReadOnly';

export const ImagesReadonlyView = () => {
  const caseId = useCaseIdFromUrl();
  const { images } = useImageList(caseId);

  if (!images) return <Loader />;

  return <ImageLibraryReadOnly imageIds={images} />;
};
