import { FunctionComponent } from 'react';
import { ContentArea } from '../../core/Components';
import { PageTitle } from '../../core/PageTitle';
import { CompanyLogo } from './CompanyLogo';
import { PDFDesign } from './PDFDesign';

export const CompanyProfile: FunctionComponent = () => (
  <ContentArea>
    <PageTitle title="Company Profile" />
    <CompanyLogo />
    <PDFDesign />
  </ContentArea>
);
