import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ArrowBack, ArrowForward } from '@instech/icons';
import { BoxButton } from '@instech/components';
import { childrenPropType } from '@/prop-types/custom-prop-types';

// BoxButton wrapped in a React-Router Link
export const NavBox = ({ children, inverted, startIcon: StartIcon, endIcon: EndIcon, width, height, to, onClick, padding }) => (
  <Link to={to}>
    <BoxButton
      inverted={inverted}
      startIcon={StartIcon && <StartIcon />}
      endIcon={EndIcon && <EndIcon />}
      width={width}
      height={height}
      onClick={onClick}
      padding={padding}
    >
      {children}
    </BoxButton>
  </Link>
);

export const PreviousButton = ({ to, label = 'Previous' }) => (
  <NavBox inverted startIcon={ArrowBack} width="125px" height="50px" fontSize="18px" boldFont={false} to={to}>{label}</NavBox>
);

export const NextButton = ({ to, label = 'Next' }) => (
  <NavBox endIcon={ArrowForward} width="125px" height="50px" fontSize="18px" boldFont={false} to={to}>{label}</NavBox>
);

NextButton.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string
};

PreviousButton.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string
};

NavBox.propTypes = {
  children: childrenPropType,
  inverted: PropTypes.bool,
  startIcon: PropTypes.func,
  endIcon: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  padding: PropTypes.string
};
