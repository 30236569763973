import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TruncatingText } from '@instech/components';

const Section = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

const Heading = styled.div`
  font-weight: bold;
  margin-bottom: 3px;
`;

interface TimeSavedProps {
  days?: number;
  comment?: string;
}
export const TimeSaved: FunctionComponent<TimeSavedProps> = ({ days, comment }) => (
  <>
    <Section>
      <Heading>Days</Heading>
      <div>{days || '0'}</div>
    </Section>
    {comment && (
      <Section>
        <Heading>Comment</Heading>
        <TruncatingText text={comment} wrap />
      </Section>
    )}
  </>
);
