import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Edit, Trash } from '@instech/icons';
import { TableCell, TruncatingTextTableCell } from '@/components/shared/Table';
import { ContextMenu } from '@/components/shared/ContextMenu';
import { Contact } from '@/types';

const ContextTableCell = styled(TableCell)`
  justify-content: center;
  padding: 0px;
`;

interface ContactRowViewProps {
  contact: Contact;
  toggleEditMode: () => void;
  remove: (contactId: string) => void;
}
export const ContactRowView: FunctionComponent<ContactRowViewProps> = ({ contact, toggleEditMode, remove }) => (
  <>
    <TruncatingTextTableCell text={contact.name} />
    <TruncatingTextTableCell text={contact.function} />
    <TruncatingTextTableCell text={contact.phone} />
    <TruncatingTextTableCell text={contact.email} />
    <ContextTableCell>
      <ContextMenu
        id={contact.id}
        buttonColor="marineBlue"
        options={[
          {
            icon: <Edit />,
            label: 'Edit',
            onClick: toggleEditMode
          },
          {
            icon: <Trash />,
            label: 'Delete',
            onClick: () => remove(contact.id)
          },
        ]}
      />
    </ContextTableCell>
  </>
);
