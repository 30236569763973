import styled from 'styled-components';

export const Row = styled.div<{ even: boolean, bold?: boolean; }>`
  display: contents;
  // Pointers to target child cells, and child cells that will be
  // wrapped in a Link component set to display: contents
  > span, > a span {
    background-color: ${props => props.even ? props.theme.ultraLightGray2 : props.theme.white};
    color: ${props => props.theme.marineBlue};
    font-weight: ${props => props.bold && 'bold'};
    /* Handle the spacing to be even across */
    padding: 16px 8px;
    &:first-child { padding-left: 16px; }
    &:last-child { padding-right: 16px; }
  }
`;
