import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Pane } from '@/components/shared/Pane';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useSignature } from '@/services/signatureServices';
import { Loader } from '@instech/components';
import { hiddenOnPrint } from '@/utils/styles';
import { Guid, SignatureUserInfo } from '@/types';
import { SigneeUserInfo } from '@/utils/archivedReportTypes';
import { SignaturePreview } from './core/SignaturePreview';

const Wrapper = styled.div`
  padding: 0 30px 30px;
  margin-top: 16px;
`;

const PaneTitle = styled.span`
  ${hiddenOnPrint};
`;

export const getUserInfo = (signeeUserInfo?: SigneeUserInfo, signatureUserInfo?: SignatureUserInfo) => ({
  name: signeeUserInfo?.name ?? signatureUserInfo?.name,
  title: signeeUserInfo?.title?.trim() ? signeeUserInfo.title : 'Surveyor'
});

interface Props {
  signatureId?: Guid;
  companyName?: string;
  signeeUserInfo?: SigneeUserInfo;
}
export const SignatureReportView: FunctionComponent<Props> = ({ signatureId, companyName, signeeUserInfo }) => {
  const caseId = useCaseIdFromUrl();
  const { data } = useSignature(caseId, signatureId, 'medium');

  if (!signatureId) {
    return null;
  }
  if (!data) {
    return <Loader />;
  }

  const userInfo = getUserInfo(signeeUserInfo, data.signature?.userInfo);
  const signUrl = data.signature?.url ?? '';

  return (
    <Wrapper>
      <Pane title={<PaneTitle>Signature</PaneTitle>}>
        <p>Subject to the rights of the Underwriters according to the relevant insurance conditions and policy.</p>
        <SignaturePreview url={signUrl} text="Signature" />
        <p>Yours faithfully,</p>
        <p>
          {userInfo.name}
          <br />
          {userInfo.title}
          {companyName && <div>{companyName}</div>}
        </p>
      </Pane>
    </Wrapper>
  );
};
