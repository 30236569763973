import styled from 'styled-components';
import { Field, useField } from 'formik';
import {
  FieldLayout, FieldLabel, ErrorMessage, InputField
} from '../core/Components';
import { useFieldId } from '../core/useFieldId';

interface NumberInputProps {
  error?: string | boolean;
}
const NumberInput = styled(InputField) <NumberInputProps>`
  & input[type=number] {
    -moz-appearance: textfield;
  }
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

interface FieldProps {
  name: string;
  label?: string;
  placeholder: string;
  noErrors?: boolean;
  min: number;
  step?: number;
  className?: string;
}
export const NumberField = ({ name, label, noErrors, placeholder, min, step = 1, className }: FieldProps) => {
  const [, meta] = useField(name);
  const { fieldId } = useFieldId(name);

  return (
    <FieldLayout className={className}>
      {label && <FieldLabel as="label" htmlFor={fieldId}>{label}</FieldLabel>}
      <NumberInput error={meta.touched && meta.error}>
        <Field id={label && fieldId} name={name} placeholder={placeholder} type="number" min={min} step={step} />
      </NumberInput>
      {!noErrors && (
        <ErrorMessage>
          {meta.error && meta.touched ? meta.error : ''}
        </ErrorMessage>
      )}
    </FieldLayout>
  );
};
