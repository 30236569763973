import styled from 'styled-components';

const Container = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

export const ContactEmptyView = () => (
  <Container>No contacts added yet</Container>
);
