import useSWR from 'swr';
import {
  Case, HiddenFlags, ChapterTemplate, ReportTemplate, WidgetTemplate
} from '@/types';
import { ViewChapter } from '@/utils/manifestTypes';
import { ChapterType } from '@/components/pages/report/core/utils';

export const useTemplate = (caseId: string) => {
  const { data } = useSWR<Case>(`/case/${caseId}`);
  return useSWR<ReportTemplate>(() => typeof data === 'undefined' ? null : `/template/${data.templateId}`);
};

export const filterOutHiddenChapters = (chapters: ViewChapter[], viewMode: boolean, reportTemplate: ReportTemplate) => {
  const templateChapters = reportTemplate.parts.flatMap(x => x.chapters);
  const templateToolsWidgets = reportTemplate.tools.flatMap(x => x.widgets);
  return chapters.filter(x => {
    const chapter = templateChapters.find(c => c.title === x.title);
    const toolWidget = templateToolsWidgets.find(w => w.title === x.title);
    const hidden = chapter?.hidden || toolWidget?.hidden || 0;
    /* eslint no-bitwise: ["error", { "allow": ["&"] }] */
    return viewMode ? (HiddenFlags.ArchivedReport & hidden) === 0 : (HiddenFlags.ReportPreview & hidden) === 0;
  });
};

export const getTemplateChapters = (reportTemplate: ReportTemplate) => reportTemplate.parts.flatMap(x => x.chapters);

export const getTemplateWidgets = (reportTemplate: ReportTemplate) => {
  const templateChaptersWidgets = reportTemplate.parts.flatMap(x => x.chapters).flatMap(z => z.widgets);
  const templateToolsWidgets = reportTemplate.tools.flatMap(x => x.widgets);
  return templateChaptersWidgets.concat(templateToolsWidgets).filter(x => x !== null);
};

export const filterOutHiddenChaptersAndWidgets = (
  chapter: ChapterType, viewMode: boolean, reportTemplateChapters: ChapterTemplate[], reportTemplateWidgets: WidgetTemplate[]
) => {
  const widgets = (chapter.widgets ?? []).filter(x => x.notInUse === false);
  return widgets.filter(widget => {
    const templateWidget = reportTemplateWidgets.find(x => x.title === widget.title);
    if (typeof (templateWidget) === 'undefined') {
      const chapterHidden = reportTemplateChapters.find(x => x.title === chapter.title)?.hidden || 0;
      return viewMode ? (HiddenFlags.ArchivedReport & chapterHidden) === 0 : (HiddenFlags.ReportPreview & chapterHidden) === 0;
    }
    const { hidden } = templateWidget;
    return viewMode ? (HiddenFlags.ArchivedReport & hidden) === 0 : (HiddenFlags.ReportPreview & hidden) === 0;
  });
};
