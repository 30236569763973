import {
  FunctionComponent, useState, useRef, useEffect
} from 'react';
import styled from 'styled-components';
import { LabelValuePair } from '@/types';
import { addOrRemove } from '@/utils/arrayHelpers';
import { useOutsideAlerter } from '@/utils/mouseEventHelpers';
import { CheckboxControlled } from '@/components/shared/Form/FormFields';
import { TruncatingText } from '@instech/components';
import { DropdownButtons } from '../core/DropdownButtons';
import { DropdownField } from '../FilterDropdown/DropdownField';
import {
  DropdownContainer, DropdownElement, ListContainer
} from '../core/Components';

interface ContainerProps {
  width?: string;
}
const Wrapper = styled.div<ContainerProps>`
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: ${props => props.width};
`;

const setFieldMessage = (values: LabelValuePair[]) => {
  if (values.length === 1) return values[0].label;
  return `${values.length || 'None'} selected`;
};

interface Props {
  blocked: boolean;
  parameterName: string;
  selected: LabelValuePair[];
  entries: LabelValuePair[];
  onChange: (values: LabelValuePair[]) => void;
}
export const FilterSelectDropdownMulti: FunctionComponent<Props> = (
  { blocked, parameterName, selected, entries, onChange }
) => {
  const [open, setOpen] = useState(false);
  const [currentValues, setCurrentValues] = useState<LabelValuePair[]>(selected);

  const apply = () => {
    onChange(currentValues);
    setOpen(false);
  };

  const cancel = () => {
    setCurrentValues(selected);
    setOpen(false);
  };

  // If dropdown closes, trigger Cancel
  useEffect(() => {
    if (blocked || !open) cancel();
  }, [blocked, open]); // eslint-disable-line react-hooks/exhaustive-deps

  const clickRef = useRef(null);
  const onOutsideClick = () => setOpen(false);
  useOutsideAlerter(clickRef, open, onOutsideClick);

  const onToggleOpen = () => !blocked && setOpen(!open);

  return (
    <Wrapper ref={clickRef} width="200px">
      <DropdownField onClick={onToggleOpen} isOpen={open}>
        <TruncatingText text={setFieldMessage(selected)} />
      </DropdownField>
      <DropdownContainer isOpen={open}>
        <DropdownElement first>
          <ListContainer>
            {entries.map(item => (
              <CheckboxControlled
                key={`${parameterName}-checkbox-${item.value}`}
                id={`${parameterName}-checkbox-${item.value}`}
                label={item.label}
                labelMargin="20px"
                checked={currentValues.some(current => item.value === current.value)}
                onChange={() => setCurrentValues(
                  addOrRemove([...currentValues], item)
                )}
              />
            ))}
          </ListContainer>
        </DropdownElement>
        <DropdownButtons
          onCancel={cancel}
          onFilter={apply}
          disableFilter={!currentValues.length}
        />
      </DropdownContainer>
    </Wrapper>
  );
};
