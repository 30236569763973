import { YesOrNo } from '@/types';

export function yesNoToBool(value?: YesOrNo) {
  if (value === 'Yes') return true;
  if (value === 'No') return false;
  return undefined;
}

export function boolToYesNo(value?: boolean): YesOrNo | undefined {
  if (value === null) {
    return undefined;
  }
  return value ? 'Yes' : 'No';
}
