import { useMemo } from 'react';
import { SummaryOfTime } from '@/types';
import {
  Row, Field, FieldHeader, FieldContent, LargeField
} from './Components';
import { getTimeEstimateValues } from './utils';

export const TimeEstimate = ({ summary }: { summary: SummaryOfTime[]; }) => {
  const { notConcerningAvg, repairTime, status } = useMemo(() => getTimeEstimateValues(summary), [summary]);
  return (
    <Row>
      <Field>
        <FieldHeader>Time for repairs</FieldHeader>
        <FieldContent>{repairTime}</FieldContent>
      </Field>
      <Field>
        <FieldHeader>Status</FieldHeader>
        <FieldContent>{status}</FieldContent>
      </Field>
      <LargeField>
        <FieldHeader>Time for work not concerning average</FieldHeader>
        <FieldContent>{notConcerningAvg}</FieldContent>
      </LargeField>
    </Row>
  );
};
