import { Route } from 'react-router';
import { editRoute } from '@/utils/routeTemplates';
import { ChapterPage } from '../ChapterPage';
import { DefaultEditPage } from './DefaultEditPage';

export const EditRouter = () => {
  const basePath = editRoute(':id');
  return (
    <>
      <Route path={`${basePath}/chapter/:chapterId`} component={ChapterPage} />
      <Route exact path={`${basePath}`} component={DefaultEditPage} />
    </>
  );
};
