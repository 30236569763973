import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';

// Allows one or more children
export const childrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node
]);

// Must have multiple children, useful when using array methods on children prop
export const childrenStrictPropType = PropTypes.arrayOf(PropTypes.node);

// Draft.js editor state
export const editorStatePropType = PropTypes.instanceOf(EditorState);

// For RichTextForm images
export const imageStatePropType = PropTypes.arrayOf(PropTypes.string);

// Occurrence props
export const occurrencePropType = PropTypes.shape({
  id: PropTypes.string,
  number: PropTypes.number,
  name: PropTypes.string,
  dateOfLoss: PropTypes.string, // String or null
  claimReference: PropTypes.string,
  location: PropTypes.string,
  charter: PropTypes.string
});

// Vessel Movements timeline event props
export const timelineEventPropType = PropTypes.shape({
  id: PropTypes.string,
  eventType: PropTypes.string,
  timeStatus: PropTypes.string,
  typeOfActivity: PropTypes.string,
  eventDate: PropTypes.string, // String or null
  location: PropTypes.string,
  includeInReport: PropTypes.bool,
  occurrenceDetails: occurrencePropType
});

export const expensesPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  currency: PropTypes.string,
  sum: PropTypes.number,
  status: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      cost: PropTypes.number,
      status: PropTypes.string,
      includeInReport: PropTypes.bool,
      comment: PropTypes.string,
      includeCommentInReport: PropTypes.bool
    })
  )
});

export const availableInfoPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  file: PropTypes.shape({ // Should this be able to prop actual file data?
    name: PropTypes.string,
    ext: PropTypes.string
  }),
  source: PropTypes.shape({
    name: PropTypes.string,
    date: PropTypes.string
  }),
  date: PropTypes.string,
  category: PropTypes.string,
  status: PropTypes.string,
  personallyIdentifiableInformation: PropTypes.shape({
    includesEu: PropTypes.bool
  })
});

export const modalPropType = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  args: PropTypes.object,
  component: PropTypes.func,
  options: PropTypes.shape({
    title: PropTypes.string,
    size: PropTypes.string
  })
});

export const widgetComponentPropType = {
  widgetId: PropTypes.string.isRequired,
  title: PropTypes.string
};

export const optionPropType = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};
