import { FC } from 'react';
import styled from 'styled-components';
import { ToolPaneTab } from '../types';

export const PaneTitle = styled.span`
  color: ${props => props.theme.deepAzureBlue};
  font-size: 16px;
  padding-top: 11px;
  padding-bottom: 13px;
`;

export const PaneContent = styled.div`
  background: ${props => props.theme.lightBlue};
`;

interface TabbedPaneContentProps {
  tab: ToolPaneTab;
  isActive: boolean;
}
export const TabbedPaneContent: FC<TabbedPaneContentProps> = ({ tab, isActive }) => {
  if (!isActive) {
    return null;
  }
  return (
    <PaneContent key={tab.key ?? tab.title}>
      {tab.component}
    </PaneContent>
  );
};
