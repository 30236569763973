import styled from 'styled-components';
import { ChapterNavigation } from '@/components/shared/ChapterNavigation';

const HeaderWrapper = styled.div`
  padding: 33px 30px;
  color: #003C71;
  background-color: #ECF1F5;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PartHeader = styled.h3`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  margin-left: 2px;
`;

const TitleHeader = styled.h2`
  margin: 0;
  font-size: 42px;
  font-weight: 400;
`;

interface ChapterHeaderProps {
  part: string;
  title: string;
}
export const ChapterHeader = ({ part, title }: ChapterHeaderProps) => (
  <HeaderWrapper>
    <div>
      <PartHeader>{part}</PartHeader>
      <TitleHeader>{title}</TitleHeader>
    </div>
    <ChapterNavigation />
  </HeaderWrapper>
);
