import {
  ClaimsHandlerDashboardAssignment, Dictionary, Occurrence, SortableCase
} from '@/types';
import { ReportVersion } from '@/utils/archivedReportTypes';
import { getClaimNumberFromClaimReference, isNullishOrWhitespace } from '@/utils/text';

export const sortableHeaders = [
  {
    title: 'Tasks',
    propertyName: 'sortableTask',
    parameterName: 'SelectedInternalReferences'
  },
  {
    title: 'Report Status',
    propertyName: 'sortableReportStatus',
    parameterName: 'SelectedReportStatuses'
  },
  {
    title: 'Claim Number',
    propertyName: 'sortableClaimNumber',
    parameterName: 'SelectedClaimNumbers'
  },
  {
    title: 'Date of Loss',
    propertyName: 'sortableDateOfLoss',
    parameterName: 'SelectedLossDates',
    noDropdown: true
  },
  {
    title: 'Vessel',
    propertyName: 'name',
    parameterName: 'SelectedVessels'
  },
  {
    title: 'Fleet',
    propertyName: 'sortableFleet',
    parameterName: 'SelectedFleets'
  },
  {
    title: 'Survey Company',
    propertyName: 'sortableSurveyCompany',
    parameterName: 'SelectedSurveyCompany'
  },
  {
    title: 'Interest',
    propertyName: 'sortableInterest',
    parameterName: 'SelectedInterestTypes'
  },
  {
    title: 'Occurrence Name',
    propertyName: 'sortableOccurrenceName',
    parameterName: 'SelectedOccurrenceNames'
  },
  {
    title: 'Starred',
    propertyName: 'sortableFavourite',
    parameterName: 'SelectedStarredRows',
    center: true
  },
];

const sortableDateOfLoss = (occurrences: Occurrence[] | null): string | null => {
  const occurrence = occurrences?.find(item => item.dateOfLoss !== null);
  if (!occurrence) return null;
  return occurrence.dateOfLoss;
};

const sortableFleet = (occurrences: Occurrence[] | null): string | null | undefined => {
  const occurrence = occurrences?.find(item => item.fleetName !== null);
  if (!occurrence) return null;
  return occurrence.fleetName;
};

const sortableInterest = (occurrences: Occurrence[] | null): string | null | undefined => {
  const interests = occurrences?.flatMap(x => x.interests.flatMap(i => i.interest)) ?? [];
  const interest = interests.find(item => !isNullishOrWhitespace(item));
  if (!interest) return null;
  return interest;
};

const sortableOccurrenceName = (occurrences: Occurrence[] | null): string | null | undefined => {
  const occurrence = occurrences?.find(item => item.name !== null);
  if (!occurrence) return null;
  return occurrence.name;
};

const sortableClaimNumber = (occurrences: Occurrence[] | null): string | null | undefined => {
  const claimReferences = occurrences?.flatMap(x => x.interests.flatMap(i => i.claimReference)) ?? [];
  const claimReference = claimReferences.find(item => !isNullishOrWhitespace(item));
  if (!claimReference) return null;
  return getClaimNumberFromClaimReference(claimReference);
};

export const convertToSortableCases = (cases: ClaimsHandlerDashboardAssignment[], versions: Dictionary<ReportVersion>, favourites?: string[]): SortableCase[] =>
  cases.map(item => (
    {
      ...item,
      sortableDateOfLoss: sortableDateOfLoss(item.occurrences),
      sortableFleet: sortableFleet(item.occurrences),
      sortableInterest: sortableInterest(item.occurrences),
      sortableSurveyCompany: item.surveyCompanyName,
      sortableOccurrenceName: sortableOccurrenceName(item.occurrences),
      sortableReportStatus: versions[item.caseId]?.reportType,
      sortableClaimNumber: sortableClaimNumber(item.occurrences),
      sortableFavourite: favourites?.includes(item.caseId) ?? false,
      sortableTask: item.caseTasks !== null && (item.caseTasks.hasAvailableInformationTask || item.caseTasks.hasOccurrenceTask),
    }
  ));
