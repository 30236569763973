import { TruncatingText } from '@instech/components';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: ${props => props.theme.white};
`;

interface RowBackgroundProps {
  dark?: boolean;
  alert?: boolean;
  interestRow?: boolean;
}
export const RowBackground = styled.div<RowBackgroundProps>`
  position: relative;
  border: 3px solid transparent;
  ${props => props.dark && css`
    background-color: rgba(127,157,184,0.1);
  `};
  ${props => props.alert && css`
    background-color: ${props.theme.orange50};
    border: 3px solid ${props.theme.orange};
  `}
  ${props => props.interestRow && css`
    background-color: ${props.theme.background}
  `}
`;

export const Divider = styled.div`
  border-top: 1px solid #04232e;
  margin: 0px 16px;
  opacity: 0.2;
`;

export const Rows = styled.div`
  padding: 32px;
`;

interface RowProps {
  bottom?: boolean;
}
export const Row = styled.div<RowProps>`
  ${props => props.bottom && css`
    margin-top: 32px;
  `}
  display: grid;
  // Icon column, then 6 field columns, then edit button
  grid-template-columns: 20px repeat(6, 1fr) 40px;
`;

interface FieldRowProps {
  gridStart?: number;
  gridEnd?: number;
}
export const FieldRow = styled.div<FieldRowProps>`
  ${props => props.gridStart && css`
    grid-column-start: ${props.gridStart};
  `}
  ${props => props.gridEnd && css`
    grid-column-end: ${props.gridEnd};
  `}
  // This is needed to make the text truncation work in the children
  min-width: 0;
`;

export const FieldText = styled.div`
  color: ${props => props.theme.blue};
  font-size: 16px;
  line-height: 20px;
`;

export const FieldTitle = styled.div`
  color: ${props => props.theme.marineBlue};
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 4px;
`;

export const FieldIcon = styled.div`
  color: ${props => props.theme.marineBlue};
  svg {
    height: 16px;
    width: 16px;
  }
`;

interface FieldProps {
  label: string;
  value?: string;
  gridStart?: number;
  gridEnd?: number;
}
export const Field = ({ label, value = '-', gridStart, gridEnd }: FieldProps) => {
  const displayValue = (value === '' || value.trim() === '') ? '-' : value;
  return (
    <FieldRow gridStart={gridStart} gridEnd={gridEnd}>
      <FieldTitle>{label}</FieldTitle>
      <FieldText><TruncatingText text={displayValue} padding="0px 10px 0px 0px" wrap /></FieldText>
    </FieldRow>
  );
};
