import styled from 'styled-components';
import { CaseStatusHistoryItem } from '@/types';
import { NewBoxButton } from '@/components/shared/BoxButton/NewBoxButton';
import { ChangeLog } from './ChangeLog';

const Header = styled.div`
  font-size: 22px;
  font-weight: bold;  
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TextArea = styled.div`
  margin: 20px 0px;
  line-height: 20px;
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px;
`;

const StyledButton = styled(NewBoxButton)`
  margin: 5px;
  height: 20px;
  width: 100px;
`;

interface SurveyorProps {
  daysText: string;
  closed: boolean;
  close: () => void;
  changelog: CaseStatusHistoryItem[];
}
export const SurveyorFrame = ({ daysText, closed, close, changelog }: SurveyorProps) => (
  <div>
    <HeaderRow>
      <Header>{`Status: ${closed ? 'Closed' : 'Open'}`}</Header>
      <div>{daysText}</div>
    </HeaderRow>
    <TextArea>
      <div>{`The claim has status ${closed ? 'closed' : 'open'}.`}</div>
      <div>{`Contact the Claims Handler if you ${closed ? 'need the claim reopened.' : 'have any questions.'}`}</div>
    </TextArea>
    <ChangeLog changelog={changelog} />
    <ButtonArea>
      <StyledButton onClick={close}>Done</StyledButton>
    </ButtonArea>
  </div>
);
