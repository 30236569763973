import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { EquipmentParticular } from '@/services/equipmentsParticularsServices';
import { ContextMenu } from '@/components/shared/ContextMenu';
import { Edit, Trash } from '@instech/icons';
import { ReadOnlyField } from '../../../shared/ReadOnlyField';
import { SourceDetails } from '../../../shared/SourceDetails';

interface StyledProps {
  readonly?: boolean;
}
const StyledEquipment = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: ${props => props.readonly ? '1fr 1fr 1fr' : '1fr 1fr 1fr 40px'};
  grid-auto-rows: auto;
  column-gap: 10px;
`;

const Wrapper = styled.div`
  margin-top: 12px;
`;

const SourceDetailsWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
`;

interface Props extends EquipmentParticular {
  onDelete: (id: string) => void;
  readonly?: boolean;
  toggleEditMode: () => void;
}
export const EquipmentRowView: FunctionComponent<Props> = ({
  id,
  equipment,
  make,
  type,
  onDelete,
  readonly,
  includeInReport,
  toggleEditMode
}) => (
  <Wrapper>
    <StyledEquipment readonly={readonly}>
      <ReadOnlyField title="Equipment" value={equipment} />
      <ReadOnlyField title="Make / Manufacturer" value={make} />
      <ReadOnlyField title="Type / Model" value={type} />
      {!readonly &&
        (
          <ContextMenu
            id={id}
            buttonColor="marineBlue"
            options={[
              {
                icon: <Edit />,
                label: 'Edit',
                onClick: toggleEditMode
              },
              {
                icon: <Trash />,
                label: 'Delete',
                onClick: !readonly ? () => onDelete(id) : undefined
              },
            ]} />
        )}
      <SourceDetailsWrapper>
        <SourceDetails included={includeInReport} />
      </SourceDetailsWrapper>
    </StyledEquipment>
  </Wrapper>
);
