import styled from 'styled-components';
import { hiddenOnPrint } from '@/utils/styles';
import { Sidebar } from '../../../layout/EditGrid';

export const ContentGrid = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 220px;
  grid-auto-flow: row;
  @media print {
    display: block;
  }
`;

export const ReportSidebar = styled(Sidebar)`
  ${hiddenOnPrint}
`;

export const ReportArea = styled.div`
  width: 100%;
  max-width: 880px;
  margin: 0 auto;
  margin-top: 14px;
`;

export const ToolsArea = styled.div`
  &:not(:empty) {
    padding: 0 30px 30px;
  } 
`;
