import useSWR, { mutate, cache } from 'swr';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { CaseStatusHistoryItem } from '@/types';
import client from './client';
import { filesApiRoute } from './fileServices';

export interface ClaimHandlingDays {
  totalClaimHandlingDays: number;
}

const caseStatusUrl = (caseId: string) => `caseStatus/cases/${caseId}`;

export const useCaseStatus = (suspense?: boolean) => {
  const caseId = useCaseIdFromUrl();
  const url = `${caseStatusUrl(caseId)}/current`;
  return useSWR<CaseStatusHistoryItem>(url, { suspense });
};

export const useTotalClaimHandlingDays = () => {
  const caseId = useCaseIdFromUrl();
  const url = `${caseStatusUrl(caseId)}/claimhandlingdays`;
  return useSWR<ClaimHandlingDays>(url);
};

export const useCaseStatusChangeLog = () => {
  const caseId = useCaseIdFromUrl();
  const url = `${caseStatusUrl(caseId)}/history`;
  return useSWR<CaseStatusHistoryItem[]>(url);
};

export const closeCase = async (caseId: string) => {
  const url = `${caseStatusUrl(caseId)}/close`;
  const result = await client.put<CaseStatusHistoryItem>(url);
  const currentUrl = `${caseStatusUrl(caseId)}/current`;
  await mutate(currentUrl, result.data);

  // Closed cases should not show GDPR data, therefore we clear the file list cache when closing cases
  cache.delete(filesApiRoute(caseId));
};

export const reopenCase = async (caseId: string) => {
  const url = `${caseStatusUrl(caseId)}/reopen`;
  const result = await client.put<CaseStatusHistoryItem>(url);
  const currentUrl = `${caseStatusUrl(caseId)}/current`;

  await mutate(currentUrl, result.data);
};
