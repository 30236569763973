import { getFriendlyDate } from '@instech/components';
import { Occurrence, SurveyLocation } from '@/types';
import { sortByDurationDesc } from '../utils';

export const mapLocationToOccurrences = (occurrenceData: Occurrence[], locationsData: SurveyLocation[]) => {
  const newOccurrenceData = occurrenceData.map(occurrence => {
    const occurrenceLocations = locationsData
      .filter(location => location.occurrences.map(x => x.id).includes(occurrence.id));
    sortByDurationDesc(occurrenceLocations);

    const firstLocation = occurrenceLocations.length > 0 ? occurrenceLocations[0] : undefined;

    const surveyStartDate = firstLocation?.duration.startDate ? getFriendlyDate(firstLocation?.duration.startDate) : '';
    const surveyEndDate = firstLocation?.duration.endDate && firstLocation?.duration.startDate !== firstLocation?.duration.endDate
      ? ` - ${getFriendlyDate(firstLocation.duration.endDate)}`
      : '';

    const dateOfSurvey = firstLocation ? `${surveyStartDate}${surveyEndDate}` : undefined;

    return { ...occurrence, surveyLocation: firstLocation?.location, dateOfSurvey };
  });

  return newOccurrenceData;
};
export type MapLocationToOccurrencesReturn = ReturnType<typeof mapLocationToOccurrences>[number];
