import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { ToolButton, TextContainer } from '@/components/shared/ToolButton';
import { useModalContext } from '@/components/modal/ModalContext';
import { useManifest } from '@/services/manifestServices';
import { getReportTools } from '@/services/toolsServices';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import { Gear } from '@instech/icons';
import { NotifyIcon, NotifyTooltip } from '@/components/shared/Tooltip';
import { availableInformationTask, useCaseTasks } from '@/services/caseTasksService';
import { DashboardPane } from './DashboardPane';

const FlexWrappedRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media(max-width: ${props => props.theme.breakpoints.tablet}) {
    justify-content: space-around;
  };
`;

// Applying style changes here that can apply to the buttons on the
// Dashboard without also changing the Edit tools for now
const StyledToolButton = styled(ToolButton)`
  position: relative;
  width: calc(100% /3);
  height: auto;
  margin: 0px;
  box-shadow: none;

  & ${TextContainer} {
    max-width: 80px;
    font-size: 14px;
    color: ${props => props.theme.marineBlue};
    margin: 16px auto 0px;
  }

  
  ${props => !props.hasTask && css`
    :not(:nth-of-type(3n+0)) {
      border-right: 1px solid ${props.theme.whiteBlue};
    }
  `}
  ${props => props.hasTask && css`
    background-color: ${props.theme.orange50};
    border: 3px solid ${props.theme.orange};

    // The background color should cover the whole button, so we need to compensate for the margin
    margin-top: -12px;
    margin-bottom: -16px;

    // The extra padding is added to compensate for the negative margin
    padding-top: 22px;
    padding-bottom: 26px;
  `}
`;

const ToolsDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.whiteBlue};
  margin: 12px 16px;
`;

const getTaskForTool = (toolLabel, tasks) => {
  if (tasks?.hasAvailableInformationTask && toolLabel === 'Available information') {
    return availableInformationTask.name;
  }
  return null;
};

const NavButton = ({ label, onClick, tasks, icon: Icon, index }) => {
  const task = getTaskForTool(label, tasks);
  return (
    <>
      <StyledToolButton onClick={onClick} icon={<Icon />} hasTask={task}>
        {task && <NotifyIcon id={`${label}-notify`} message={task} />}
        {label}
      </StyledToolButton>
      {index === 2 && <ToolsDivider />}
      {task && <NotifyTooltip id={`${label}-notify`} />}
    </>
  );
};
NavButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tasks: PropTypes.object.isRequired,
  icon: PropTypes.element.isRequired,
  index: PropTypes.number.isRequired
};

export const NavButtons = () => {
  const { open } = useModalContext();
  const role = useCurrentRole();
  const { data: manifest } = useManifest(true);
  const tools = getReportTools(manifest?.reportTools, role.name);
  const { data: tasks } = useCaseTasks();

  if (tasks?.hasAvailableInformationTask) {
    const availableInfoTool = tools.find(tool => tool.label === 'Available information');
    availableInfoTool.task = availableInformationTask.name;
  }

  return (
    <DashboardPane title="Surveyor's Tools" padding="16px 0px" icon={<Gear />}>
      <FlexWrappedRow>
        {tools.map((each, i) => (
          <NavButton
            key={each.label}
            label={each.label}
            onClick={() => open(each.onclick)}
            icon={each.icon}
            tasks={tasks}
            index={i}
          />
        ))}
      </FlexWrappedRow>
    </DashboardPane>
  );
};
