import { FunctionComponent } from 'react';
import { Contact } from '@/types';
import { Loader } from '@instech/components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import styled from 'styled-components';
import {
  Table,
  TableHeader,
  TableCell,
  TruncatingTextTableCell
} from '@/components/shared/Table';
import { useContacts } from '@/services/contactsServices';
import { sortByName } from './core/sortByName';
import { ContactEmptyView } from './ContactEmptyView';

interface TableRowContainerProps {
  readonly even: boolean;
}
export const TableRowContainer = styled.span<TableRowContainerProps>`
  display: contents;
  ${TableCell} {
    background-color: ${props => props.even ? props.theme.white : props.theme.flatWhite}
  };
`;

interface ContactRowViewProps {
  readonly even: boolean;
  contact: Contact;
}
const ContactRowView: FunctionComponent<ContactRowViewProps> = ({ even, contact }) => (
  <TableRowContainer even={even}>
    <TruncatingTextTableCell text={contact.name} />
    <TruncatingTextTableCell text={contact.function} />
    <TruncatingTextTableCell text={contact.phone} />
    <TruncatingTextTableCell text={contact.email} />
  </TableRowContainer>
);

interface ContactsViewTableProps {
  contacts: Contact[];
}
const ContactsViewTable = ({ contacts = [] }: ContactsViewTableProps) => (
  <Table layout="1fr 1fr 1fr 1fr">
    <TableHeader>Name</TableHeader>
    <TableHeader>Function</TableHeader>
    <TableHeader>Phone</TableHeader>
    <TableHeader>Email</TableHeader>
    {contacts.map((entry, index) => <ContactRowView key={entry.id} even={index % 2 === 0} contact={entry} />)}
  </Table>
);

export const ContactsReadonlyView = () => {
  const caseId = useCaseIdFromUrl();

  const contacts = useContacts(caseId);

  if (!contacts) {
    return <Loader />;
  }
  if (contacts.length === 0) {
    return <ContactEmptyView />;
  }

  contacts.sort(sortByName);

  return <ContactsViewTable contacts={contacts} />;
};
