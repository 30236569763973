import { useState } from 'react';
import styled from 'styled-components';
import { CaseStatusHistoryItem } from '@/types';
import { ChevronToggle } from '@/components/shared/Chevron';
import { getFriendlyDate } from '@/utils/date';

const ChangeLogArea = styled.div`
  border: 1px solid ${props => props.theme.ultraLightGray};
`;

const ChangeLogHeader = styled.div`
    padding: 20px;
    font-size: 18px;
`;

const ToggleArea = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
`;

const HistoryGridArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;  
`;

const Row = styled.div< {odd?: boolean} >`
  display: contents;
  justify-content: space-between;
  & span {
    background-color: ${props => props.odd ? props.theme.ultraLightGray2 : props.theme.white};
    padding: 10px;
  }  
`;

const RowHeader = styled.div`
  display: contents;
  justify-content: space-between;
  & span {
    font-weight: bold;
    padding: 10px;
  }    
`;

const Toggle = styled.div`
  margin-right: 10px;
`;

interface LogProps {
  changelog: CaseStatusHistoryItem[];
}

const ChangeLogGrid = ({ changelog }: LogProps) => (
  <HistoryGridArea>
    <RowHeader>
      <span>Status</span>
      <span>Date</span>
      <span>By</span>
    </RowHeader>
    {changelog.map((item, index) => (
      <Row key={item.updatedDate} odd={index % 2 === 0}>
        <span>{item.isClosed ? 'Closed' : 'Open'}</span>
        <span>{getFriendlyDate(item.updatedDate)}</span>
        <span>{item.lastUpdatedBy ? item.lastUpdatedBy : '-'}</span>
      </Row>
    ))}
  </HistoryGridArea>
);

export const ChangeLog = ({ changelog }: LogProps) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <ChangeLogArea onClick={() => setExpanded(!isExpanded)}>
      <ToggleArea>
        <ChangeLogHeader>Change log</ChangeLogHeader>
        <Toggle>
          <ChevronToggle open={isExpanded} />
        </Toggle>
      </ToggleArea>
      {isExpanded && <ChangeLogGrid changelog={changelog} />}
    </ChangeLogArea>
  );
};
