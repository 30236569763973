import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useModalContext } from '@/components/modal/ModalContext';
import { BoxButton, Divider } from '@instech/components';
import { RichTextView } from '../../shared/Form/RichTextView';
import { Pane } from '../../shared/Pane';
import { FilesHiddenGdprMessage } from '../shared/FilesHiddenGdprMessage';

const defaultContent = {
  images: null,
  text: '*No feedback added yet*'
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ButtonWrapper = styled.div`
display: flex;
justify-content: flex-end;
  & > button + button {
    margin-left: 16px;
  }
`;

interface Props {
  data: {
    text: string | null;
    images: string[] | null;
  }
  showGdprMessage?: boolean;
}

export const ImmediateFeedbackView: FunctionComponent<Props> = ({ data, showGdprMessage }) => {
  const { close } = useModalContext();
  const noContent = (!data.images || data.images?.length === 0) && !data.text?.trim();

  return (
    <Container>
      {showGdprMessage && <FilesHiddenGdprMessage />}
      <Pane title="Initial information about the damage">
        <RichTextView data={noContent ? defaultContent : data} />
      </Pane>
      <Divider padding="0px" />
      <ButtonWrapper>
        <BoxButton onClick={close}>
          Close
        </BoxButton>
      </ButtonWrapper>
    </Container>
  );
};
