/* eslint-disable react/forbid-prop-types */
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TriStateCheckbox } from '@/components/shared/TriStateCheckbox';
import { Overview } from '../Overview';

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const CheckBox = styled.div`
  padding: 6px;
`;

interface Props {
  header: string;
  helpText?: string;
  value?: boolean;
  onChange: (newValue: any) => void;
}
export const CertificateWithCheckbox: FunctionComponent<Props> = ({ header, helpText, value, onChange }) => (
  <InnerWrapper>
    <Overview header={header} helpText={helpText} />
    <CheckBox>
      <TriStateCheckbox value={value} onClick={newValue => onChange(newValue)} />
    </CheckBox>
  </InnerWrapper>
);
