import { useModalContext } from '@/components/modal/ModalContext';
import {
  ButtonGroup, Loader, BoxButton, Dropdown, TextField, FormikWithPrompt as Formik
} from '@instech/components';
import { SmallLoader } from '@/components/shared/Loader';
import { Form } from 'formik';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useCase } from '@/services/dashboardServices';
import { useOccurrencesForCase } from '@/services/occurrenceServices';
import { useUserSignature } from '@/services/signatureServices';
import { useCurrentUserInfo } from '@/services/userInfoService';
import { publishCurrentReport, useNewestPreviewReport } from '@/services/versionsService';
import {
  LabelValuePair,
  Occurrence,
  claimInterests
} from '@/types';
import { ArchivedReport, PublishReportOptions } from '@/utils/archivedReportTypes';
import {
  Add, ComingSoon as RowingIcon,
  Edit, Send
} from '@instech/icons';
import { PreviewVersions } from '@/components/pages/report/shared/components/PublishReport/preview/PreviewVersions';
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { SignatureUploader } from '../Signature/SignatureUploader';
import { ArchivedSummary } from './ArchivedSummary';

const pdfGenerateTitle = 'PDF report is generating';
const pdfGenerateTextMessage = 'Please be patient while the PDF generates. It will be available for download soon.';

interface Props {
  versionStatus: string;
}

const preliminary: LabelValuePair = { label: 'Preliminary', value: 'Preliminary' };

const Title = styled.h2`
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 29px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
`;

const Text = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
  padding-bottom: 1.5rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
`;

const options: LabelValuePair[] = [
  preliminary,
  { label: 'Formal', value: 'Formal' },
  { label: 'Final', value: 'Final' }
];

const interestOptions: LabelValuePair[] = [
  { label: 'Hull and Machinery template', value: claimInterests.hullAndMachinery },
  { label: 'Loss of Hire template', value: claimInterests.lossOfHire }
];

const publishSchema = (isDraft: boolean) => Yup.object().shape({
  signatureId: isDraft ? Yup.string() : Yup.string().required('Required'),
  externalReference: Yup.string().nullable()
});

const PublishStatus = styled.div`
  font-weight: bold;
  color: green;
`;

const VersionListContent = styled.div`
  margin-top: 20px;
`;

const getInitialValues = (
  isDraft: boolean, userSignature: any, versionStatus: string, externalReference?: string, interest?: LabelValuePair, companyName?: string
) =>
  ({
    options: {
      versionType: preliminary,
      status: versionStatus,
      externalReference,
      interest,
      companyName
    },
    signatureId: isDraft ? undefined : userSignature.signature?.id
  });

const multipleInterest = (occurrences: Occurrence[]): boolean => {
  const interests = [...new Set(occurrences?.flatMap(x => x.interests.flatMap(i => i.claimInterest)) ?? [])];
  return interests.length > 1;
};

export const PublishVersionModal: FunctionComponent<Props> = ({ versionStatus }) => {
  const caseId = useCaseIdFromUrl();
  const { close } = useModalContext();
  const { data: userSignature } = useUserSignature();
  const { data: report, mutate } = useCase(caseId, true);
  const { data } = useOccurrencesForCase(caseId, { suspense: true });
  const { data: userInfo } = useCurrentUserInfo();
  const { data: preview, mutate: previewMutate } = useNewestPreviewReport(caseId);
  const companyName = report?.companyName ?? userInfo?.company;
  const occurrences = data as Occurrence[];
  const hasMultipleInterests = multipleInterest(occurrences);
  const singleInterest = occurrences?.length > 0 ?
    interestOptions.find(interest => interest.value === occurrences[0].interests[0].claimInterest) :
    interestOptions[0];
  const initialInterest = hasMultipleInterests ? interestOptions[0] : singleInterest;
  const [saved, setSaved] = useState(false);
  const [archivedReport, setArchivedReport] = useState<ArchivedReport | null>(null);
  const isDraft = versionStatus === 'Draft';
  const isPreview = versionStatus === 'Preview';

  let publishStatus = isDraft ? 'Draft submitted': 'Report published';
  let submitButtonText = isDraft ? 'Submit draft': 'Publish report';
  let versionText = isDraft ? 'Select a report type to submit': 'Select a report type to publish';
  let submitIcon = isDraft ? <Edit /> : <Send />;

  if (isPreview) {
    submitButtonText = 'Create preview';
    publishStatus = 'Preview created';
    versionText = 'Select a report type to preview';
    submitIcon = <Add />;
  }

  if (!userSignature || !report) {
    return <Loader />;
  }

  return (
    <>
      {(isPreview && archivedReport && !preview?.pdfGenerated) &&
        (
          <Content>
            <RowingIcon />
            <Title>{pdfGenerateTitle}</Title>
            <Text>{pdfGenerateTextMessage}</Text>
          </Content>
        )}
      <Formik
        initialValues={getInitialValues(isDraft, userSignature, versionStatus, report.externalReference, initialInterest, companyName)}
        validationSchema={publishSchema(isDraft || isPreview)}
        onSubmit={async values => {
          const optionsPayload = {
            ...values.options,
            versionType: values.options.versionType?.value,
            interest: values.options.interest?.value
          };
          const result = await publishCurrentReport(caseId, optionsPayload as PublishReportOptions, values.signatureId);
          setArchivedReport(result);
          report.externalReference = values.options.externalReference;
          if (preview) {
            preview.pdfGenerated = false;
            await previewMutate(preview);
          } else {
            setSaved(true);
          }
          await mutate(report);
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <Form>
            {(!archivedReport || (isPreview && preview && preview.pdfGenerated)) && (
            <Dropdown
              name="options.versionType"
              label={versionText}
              options={options}
            />
            )}
            {((!archivedReport || (isPreview && preview && preview.pdfGenerated)) && hasMultipleInterests) && (
              <Dropdown
                name="options.interest"
                label="Select report template"
                options={interestOptions}
              />
            )}
            {(!archivedReport || (isPreview && preview && preview.pdfGenerated)) && (
              <>
                <TextField
                  label="Our Reference"
                  name="options.externalReference"
                  placeholder="Our Reference"
                />
                <TextField
                  label="Company Name"
                  name="options.companyName"
                  placeholder="Company Name"
                />
              </>
            )}

            {(!isDraft && (!archivedReport || (isPreview && preview && preview.pdfGenerated))) && (
              <SignatureUploader name="signatureId" companyName={values.options.companyName} />)}
            {archivedReport && !isPreview && <PublishStatus>{publishStatus}</PublishStatus>}
            {archivedReport && !isPreview && (
              <ArchivedSummary
                versionId={archivedReport.versionId}
                reportType={archivedReport.reportType}
                status={archivedReport.status}
              />
            )}

            <ButtonGroup alignRight>
              <BoxButton width="180px" height="50px" inverted onClick={() => close()}>Close</BoxButton>
              {(!archivedReport || (isPreview && preview && preview.pdfGenerated)) && (
                <BoxButton
                  width="180px"
                  height="50px"
                  disabled={saved || isSubmitting}
                  startIcon={!isSubmitting ? submitIcon : undefined}
                  onClick={handleSubmit}
                >
                  {isSubmitting ? <SmallLoader /> : submitButtonText}
                </BoxButton>
              )}
            </ButtonGroup>
            {(isPreview && (preview && preview.pdfGenerated)) && (
            <VersionListContent>
              <PreviewVersions />
            </VersionListContent>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
