/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Trash } from '@instech/icons';
import { TextArea, TextField } from '@instech/components';
import { useField } from 'formik';
import {
  TableRow, ExpandedDetailRow, TableCell, CellButton, ArrowButton
} from '@/components/shared/Table';
import {
  MoneyField, Checkbox, StatusTypeDropdownField
} from '@/components/shared/Form/FormFields';
import { ChevronToggle } from '@/components/shared/Chevron';
import { CommentIncludeInReportBehavior } from '@/components/shared/CommentIncludeInReportBehavior/CommentIncludeInReportBehavior';

export const ExpenseTableItem = ({ arrayId, length, number, onMoveDown, onMoveUp, onRemove, disableDelete }) => {
  const [open, setOpen] = useState(false);
  const commentFieldName = `${arrayId}.comment`;
  const [, { error, touched }] = useField(commentFieldName);

  /**
   * Opens the comment field whenever the comment field has a validation error. Also
   * prevents the user from closing the comment field when there is a validation error.
   * */
  useEffect(() => {
    if (error && touched) {
      setOpen(true);
    }
  }, [error, touched]);
  const collapseCommentIsDisabled = !!(touched && error);

  const even = number % 2 === 0;
  const first = number === 0;
  const last = number + 1 === length;
  const disabled = length === 1;

  const arrowUpHandler = evt => {
    evt.stopPropagation();
    onMoveUp();
  };

  const arrowDownHandler = evt => {
    evt.stopPropagation();
    onMoveDown();
  };

  return (
    <CommentIncludeInReportBehavior
      commentFieldName={`${arrayId}.comment`}
      includeInReportFieldName={`${arrayId}.includeCommentInReport`}
    >
      <TableRow even={even}>
        <TableCell center ticker>
          <ArrowButton
            first={first}
            last={last}
            disabled={disabled}
            onClickUp={arrowUpHandler}
            onClickDown={arrowDownHandler}
          />
        </TableCell>
        <TableCell>
          <TextField
            name={`${arrayId}.name`}
            placeholder="Name"
            noLabel
          />
        </TableCell>
        <TableCell>
          <MoneyField
            name={`${arrayId}.cost`}
            placeholder="0"
            step={0.01}
            min={0}
          />
        </TableCell>
        <TableCell>
          <StatusTypeDropdownField name={`${arrayId}.status`} noErrors noLabel />
        </TableCell>
        <TableCell checkbox>
          <Checkbox
            name={`${arrayId}.includeInReport`}
            noErrors
          />
        </TableCell>
        <TableCell button>
          <CellButton
            type="button"
            onClick={() => setOpen(!open)}
            icon={<ChevronToggle big thin open={open} />}
            disabled={collapseCommentIsDisabled}
          />
        </TableCell>
        <TableCell right button>
          <CellButton
            lineLeft
            smallIcon
            icon={<Trash />}
            onClick={onRemove}
            disabled={disableDelete}
          />
        </TableCell>
      </TableRow>
      <ExpandedDetailRow isOpen={open}>
        <TableCell span={7}>
          <TextArea
            name={`${arrayId}.comment`}
            placeholder="Comment"
            maxLength={500}
            noLabel
          />
          <Checkbox
            name={`${arrayId}.includeCommentInReport`}
            rightLabel="Include comment in report?"
            noErrors
          />
        </TableCell>
      </ExpandedDetailRow>
    </CommentIncludeInReportBehavior>
  );
};
// TODO: Define Damage & repair prop type?
ExpenseTableItem.propTypes = {
  arrayId: PropTypes.string,
  length: PropTypes.number,
  number: PropTypes.number,
  onMoveDown: PropTypes.func,
  onMoveUp: PropTypes.func,
  onRemove: PropTypes.func,
  disableDelete: PropTypes.bool,
};
