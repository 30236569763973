import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { getFriendlyDate } from '@/utils/date';
import { CalendarSolid } from '@instech/icons';

const Container = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.marineBlue};
  
  & svg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-top: 2px;
  }
`;

const Text = styled.div`
  margin-left: 8px;
`;

interface Props {
  date: string;
}
export const LastModifiedDate: FunctionComponent<Props> = ({ date }) => (
  <Container>
    <CalendarSolid />
    <Text>{getFriendlyDate(date)}</Text>
  </Container>
);
