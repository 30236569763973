import styled from 'styled-components';
import PropTypes from 'prop-types';

const DropdownWrapper = styled.div`
  position: relative; 
  & select,
  & input {
    cursor: pointer;
      width: 118px;
      height: 24px;
      background-color: ${props => props.theme.white};
      padding: 0px 16px;
      border: 1px solid;
      border-color: ${props => props.theme.border.gray};
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
      appearance: none;
    }
    &::after {
      content: '';
      position: absolute;
      height: 0.55em;
      width: 0.55em;
      margin-top: 0.3em;
      border-style: solid;
      border-color: ${props => props.theme.black};
      border-width: 0.1em 0.1em 0 0;
      box-sizing: border-box;
      transform: rotate(135deg);
      right: 15px;
      pointer-events: none;
    }
`;

const Select = styled.select`
  font-family: CalibriWeb, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
`;

export const Dropdown = ({ options = [], onChange, value }) => (
  <DropdownWrapper>
    <Select value={value} onChange={event => onChange(event.target.value)}>
      {options.map(option => <option key={option.label} value={option.value}>{option.label}</option>)}
    </Select>
  </DropdownWrapper>
);

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string
};
