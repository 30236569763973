import { Guid } from '@/types';
import { Table, TableHeader } from '@/components/shared/Table';
import { Loader } from '@instech/components';
import { useModalContext } from '@/components/modal/ModalContext';
import { useVersionedReports } from '@/services/versionsService';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { ReportVersion } from './ReportVersion';

const HeaderRow = () => (
  <>
    <TableHeader>Version</TableHeader>
    <TableHeader>Status</TableHeader>
    <TableHeader>Interest</TableHeader>
    <TableHeader>Last modified</TableHeader>
    <TableHeader>Report</TableHeader>
    <TableHeader />
  </>
);

const VersionsModal = () => {
  const caseId = useCaseIdFromUrl();
  const { data: reports } = useVersionedReports(caseId);
  const { close } = useModalContext();
  if (!reports) return <Loader />;
  return (
    <Table layout="0.5fr 1fr 1fr 1fr 1fr 1fr">
      <HeaderRow />
      {reports.map(({ versionId, archivedId, status, lastModified, pdfGenerated, interest, pdfGeneratedWithFallbackParameters }, i) => (
        <ReportVersion
          key={versionId}
          caseId={caseId}
          index={i}
          archivedId={archivedId}
          versionId={versionId}
          status={status}
          lastModified={lastModified}
          onReportView={close}
          pdfGenerated={pdfGenerated}
          interest={interest}
          pdfGeneratedWithFallbackParameters={pdfGeneratedWithFallbackParameters}
        />
      ))}
    </Table>
  );
};

export const showVersionsModal = (caseId: Guid) => ({
  component: VersionsModal,
  options: {
    title: 'Versions',
    footer: true
  },
  args: {
    caseId,
  }
});
