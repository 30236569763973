import { Dictionary } from '@/types';
import { AttendingRepresentativesReportPreview } from '@/components/pages/report/shared/components/AttendingRepresentativesReportView';
import { OccurrenceReportPreview } from '@/components/pages/report/shared/components/OccurrenceReportView';
import { VesselMovementsReportPreview } from '@/components/pages/report/shared/components/VesselMovementsReportView';
import { DescriptionReportPreview } from '@/components/pages/report/shared/components/Description/DescriptionReportView';
import { DamageDescriptionReportPreview } from '@/components/pages/report/shared/components/Description/DamageDescriptionReportView';
import { RepairDescriptionReportPreview } from '@/components/pages/report/shared/components/Description/RepairDescriptionReportView';
import { ExpensesSummaryReportPreview } from '@/components/pages/report/shared/components/ExpensesSummaryReportView';
import { SurveyLocationsReportPreview } from '@/components/pages/report/shared/components/SurveyLocationsReportView';
import { TimeSummaryReportPreview } from '@/components/pages/report/shared/components/TimeSummaryReportView';
import { VesselParticularsPreview } from '@/components/pages/report/shared/components/VesselInformation/VesselParticularsView';
import { CompanyParticularsPreview } from '@/components/pages/report/shared/components/CompanyParticulars/CompanyParticularsView';
import { AvailableInformationReportPreview } from '@/components/pages/report/shared/components/AvailableInformation/AvailableInformationReportView';
import { CertificatesPreview } from '@/components/pages/report/shared/components/VesselInformation/CertificatesView';
import { EquipmentsParticularsPreview } from '@/components/pages/report/shared/components/VesselInformation/EquipmentsView';
import { IntroductionReportPreview } from '@/components/pages/edit/widgets/Introduction';
import { TimeSavedDetailsReportPreview } from '@/components/pages/report/shared/components/TimeSavedDetails';
import { CommentsPreview } from '@/components/pages/report/shared/components/Comments/CommentsView';
import { TimeDetailsReportPreview } from '@/components/pages/report/shared/components/TimeDetails';
import { ExpensesReportPreview } from '../../shared/components/Expenses';

export const widgetTypeToComponentMap: Dictionary<any> = {
  Introduction: IntroductionReportPreview,
  Occurrences: OccurrenceReportPreview,
  SurveyLocations: SurveyLocationsReportPreview,
  AttendingRepresentatives: AttendingRepresentativesReportPreview,
  VesselEvents: VesselMovementsReportPreview,
  Description: DescriptionReportPreview,
  DamageDescription: DamageDescriptionReportPreview,
  RepairDescription: RepairDescriptionReportPreview,
  ExpensesSummary: ExpensesSummaryReportPreview,
  TimeSummary: TimeSummaryReportPreview,
  CompanyParticulars: CompanyParticularsPreview,
  EquipmentParticulars: EquipmentsParticularsPreview,
  AvailableInformation: AvailableInformationReportPreview,
  VesselInformation: VesselParticularsPreview,
  Certificates: CertificatesPreview,
  TimeSavedDetails: TimeSavedDetailsReportPreview,
  Comments: CommentsPreview,
  TimeDetails: TimeDetailsReportPreview,
  Expenses: ExpensesReportPreview
};
