import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { BrokerContact } from '@/types';
import useSWR, { mutate } from 'swr';
import client from './client';

const brokerUrl = (caseId: string) => `case/${caseId}/broker`;

export const useBrokerInCase = () => {
  const caseId = useCaseIdFromUrl();
  const swrRoute = brokerUrl(caseId);

  return useSWR<BrokerContact | ''>(swrRoute);
};

export interface BrokerUpdate {
  name: string;
  email: string;
  phone: string;
}

export const updateBrokerAsync = async (caseId: string, model: BrokerUpdate) => {
  const url = brokerUrl(caseId);
  const result = await client.post<BrokerContact>(url, model);

  void mutate(url, result.data);

  return result.data;
};
