import { FunctionComponent } from 'react';
import { FileParagraph, Upload } from '@instech/icons';
import { TruncatingTextMiddle } from '@instech/components';
import {
  FieldAction, FieldActionLinkText, FieldActionFileText
} from '../core/Components';

export const FileDownload: FunctionComponent<{ fileName: string, url: string }> = ({ fileName, url }) => (
  <FieldAction as="a" href={url}>
    <FieldActionFileText title={fileName}>
      <TruncatingTextMiddle text={fileName} maxLen={18} />
    </FieldActionFileText>
    <FileParagraph />
  </FieldAction>
);

interface FileUploadProps {
  onClick: () => any;
}
const FileUpload: FunctionComponent<FileUploadProps> = ({ onClick }) => (
  <FieldAction as="a" onClick={onClick}>
    <FieldActionLinkText>Upload</FieldActionLinkText>
    <Upload />
  </FieldAction>
);

interface Props {
  fileName: string;
  url: string;
  handleUpload?: () => void;
}
const FileDownloadOrUpload: FunctionComponent<Props> = ({ fileName, url, handleUpload }) => {
  if (url) {
    return <FileDownload fileName={fileName} url={url} />;
  }
  return <FileUpload onClick={handleUpload ?? (() => {})} />;
};

export { FileDownloadOrUpload };
