import client from '@/services/client';
import { RemoteSurvey } from '@/types';

const remoteSurveyUrl = (caseId: string) => `remotesurvey/${caseId}`;

export const createRemoteSurveyAsync = async (caseId: string) => {
  const url = `${remoteSurveyUrl(caseId)}`;
  const result = await client.post<RemoteSurvey>(url);
  return result.data;
};
