import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Row } from './Row';
import { StarButton } from './StarButton';

const LinkWrapper = styled(Link)`
  display: contents;
`;

interface Props {
  even: boolean;
  to: string;
  starred?: boolean;
  starHandler?: () => any;
}
// Component for a table row that provides a dashboardRoute to each item on it
// so they can be clicked and navigated to.
export const LinkedTableRow: FunctionComponent<Props> = ({ even, to, starred, starHandler, children }) => (
  <Row even={even}>
    <LinkWrapper to={to}>
      {children}
    </LinkWrapper>
    {starHandler && (
      <StarButton starred={starred} onClick={starHandler} />
    )}
  </Row>
);

export { NoDataRow } from './NoData';
