import { InformationNotification } from '@instech/components';
import { FC } from 'react';
import styled from 'styled-components';

const headingText = 'Automatic email and portal notifications';

const descriptionText = `
  Users you give access to will automatically receive notifications.
  The Instructions above will be included in the email to the surveyor.
`;

const Container = styled.div`
  margin: 8px 0px 32px;
`;

export const AutoEmailNotification: FC = () => (
  <Container>
    <InformationNotification
      size="medium"
      headingText={headingText}
      descriptionText={descriptionText}
    />
  </Container>
);
