import { CertificateListItem } from '@/services/certificatesServices';
import { getFriendlyDate } from '@/utils/date';
import { TruncatingText } from '@instech/components';
import styled from 'styled-components';

const TruncatingCertificate = styled.div`
  display: flex;
  white-space: nowrap;
`;

interface ClassNotificationProps {
  recommendations: CertificateListItem[]
}
export const FormatClassNotation = ({ recommendations }: ClassNotificationProps) => {
  if (!recommendations.length) {
    return <div>-</div>;
  }
  return (
    <div>
      {recommendations.map(entry => (
        <TruncatingCertificate key={entry.id}>
          <TruncatingText text={entry.name} />
          <span>{`, due ${getFriendlyDate(entry.date)}`}</span>
        </TruncatingCertificate>
      ))}
    </div>
  );
};

interface CertificatesProps {
  valid: boolean;
  invalid: CertificateListItem[];
}
export const FormatCertificates = ({ valid, invalid }: CertificatesProps) => {
  if (!valid && !invalid.length) {
    return <div>-</div>;
  }
  if (valid) {
    return <div>All certificates valid at time of occurrence</div>;
  }
  return (
    <div>
      {invalid.map(entry => (
        <TruncatingCertificate key={entry.id}>
          <TruncatingText text={entry.name} />
          <span>{`, invalidated ${getFriendlyDate(entry.date)}`}</span>
        </TruncatingCertificate>
      ))}
    </div>
  );
};

interface DetailsProps {
  dates: {
    startDate?: string;
    endDate?: string;
  };
}
export const FormatDetails = ({ dates }: DetailsProps) => {
  if (!dates.startDate && !dates.endDate) {
    return <div>-</div>;
  }
  const startDate = getFriendlyDate(dates.startDate);
  const endDate = getFriendlyDate(dates.endDate);
  return <div>{`Issued ${startDate}, valid till ${endDate}`}</div>;
};

interface TriStateProps {
  state?: boolean;
}
export const FormatTriState = ({ state }: TriStateProps) => {
  if (state === null) {
    return <div>-</div>;
  }
  return (
    <div>{(state === null && '-') || (state ? 'Yes' : 'No')}</div>
  );
};
