import { useContext, FunctionComponent } from 'react';
import Select from 'react-select';
import styled, { ThemeContext } from 'styled-components';
import { useField } from 'formik';
import { Loader } from '@instech/components';
import { useTimezoneOptions } from '@/services/timezoneServices';
import {
  FieldLayout, FieldLabel, ErrorMessage
} from '../../core/Components';
import { TimezoneLabel } from './core/TimezoneLabel';

interface FormFieldProps {
  name: string;
  label?: string;
  emptyLabel?: boolean;
  noErrors?: boolean;
  dateTime?: string;
}

interface SelectValueProps {
  label: string;
  value: string;
}

const Label = styled(FieldLabel)`
  font-size: 14px;
  margin-bottom: 6px;
`;

export const TimezonePicker: FunctionComponent<FormFieldProps> = (
  { name, label, emptyLabel, noErrors, dateTime }
) => {
  const theme = useContext(ThemeContext);
  const [field, meta, helpers] = useField(name);
  const { data: timezoneOptions, isValidating } = useTimezoneOptions(dateTime);
  const isError = meta.error && meta.touched;

  if (!timezoneOptions || isValidating) {
    return <Loader />;
  }

  const changeHandler = (selected?: SelectValueProps) => {
    const newVal = selected ? selected.value : '';
    helpers.setValue(newVal);
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      'borderColor': isError ? theme.red : theme.border.gray,
      'height': '40px',
      '&:hover': {
        borderColor: isError ? theme.red : theme.border.gray
      }
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: isError ? theme.red : provided.color,
      opacity: isError ? theme.opacity.placeholder : 1
    }),
  };

  return (
    <FieldLayout>
      {(label || emptyLabel) && <Label as="label" htmlFor={name}>{label}</Label>}
      <Select
        name={name}
        inputId={name}
        value={timezoneOptions.find(option => option.value === field.value)}
        options={timezoneOptions}
        formatOptionLabel={TimezoneLabel}
        onChange={changeHandler as any}
        placeholder="Find timezone ..."
        styles={customStyles}
        isClearable
        backspaceRemovesValue
      />
      {noErrors ? '' : (
        <ErrorMessage>
          {meta.error && meta.touched ? meta.error : ''}
        </ErrorMessage>
      )}
    </FieldLayout>
  );
};
