import {
  useState, useEffect, useRef
} from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { AddWhite } from '@instech/icons';
import { TimelineView } from '@/components/shared/TimelineView';
import { NewEventFormInline, addEventModel } from '@/components/shared/TimelineView/EventForm/NewEventForm';
import { TimelineHeader } from '@/components/shared/TimelineView/components/TimelineHeader';
import { extractLocations } from '@/components/shared/TimelineView/utils/timelineUtils';
import { widgetComponentPropType } from '@/prop-types/custom-prop-types';
import { FullwidthWidgetWrapper } from '@/components/pages/edit/widgets/FullwidthWidgetWrapper';
import { useTimelineService } from '@/services/timelineServices';
import { ScrollUpdater } from '@/components/pages/report/shared/core/ScrollUpdater';
import { InformationRow } from '@/components/shared/InformationBox';
import { DetailedInformationBox } from '@/components/shared/DetailedInformationBox';
import { BoxButton } from '@instech/components';
import { useModalContext } from '../../../../modal/ModalContext';
import { TimelineProvider } from '../../core/TimelineContext';
import { EditPane } from '../../core/EditPane';
import { useEditContext } from '../../core/EditContext';

const addEventPropsToModel = event => {
  // Add field values to the form if clicking on "Submit & new" or "New event"
  addEventModel.eventDate = event.eventDate;
  addEventModel.location = event.location;
  addEventModel.timezoneId = event.timezoneId;
};

const AddEventButton = styled(BoxButton)`
  margin-left: 20px;
`;

export const VesselEvents = ({ title }) => {
  const { id: caseId, chapterId } = useParams();
  const { chapters } = useEditContext();

  const currentChapter = chapters.find(f => f.id === chapterId);
  const { description, instructionReference } = currentChapter;

  const { items, removeItem, addItem } = useTimelineService(caseId);
  const [displayInUtcTime, setDisplayInUtcTime] = useState(false);

  const { close } = useModalContext();
  const [showForm, setShowForm] = useState(false);
  const [addingNew, setAddingNew] = useState(false);
  const scrollToRef = useRef(null);

  useEffect(() => {
    if (!scrollToRef.current) {
      return undefined;
    }
    const timeout = setTimeout(() => {
      const element = document.getElementById(scrollToRef.current);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      scrollToRef.current = null;
    }, 300);
    return () => clearTimeout(timeout);
  }, [items]);

  const locations = extractLocations(items);

  const handleShowNewEvent = () => setShowForm(prev => !prev);

  const onAddNewHandler = event => {
    addEventPropsToModel(event);
    setAddingNew(true);
    setShowForm(true);
    window.scrollTo(0, 0);
  };

  const onAddEventHandler = async (event, addNew) => {
    const newEvent = await addItem(event);
    if (!newEvent) return false;
    close();
    if (addNew) {
      onAddNewHandler(event);
    } else {
      scrollToRef.current = newEvent.id;
    }
    return true;
  };

  return (
    <FullwidthWidgetWrapper>
      <ScrollUpdater>
        <TimelineProvider value={{ locations }}>
          <InformationRow>
            <DetailedInformationBox
              instructionReference={instructionReference}
              fallbackDescription={description}
              actionButton={(
                <AddEventButton startIcon={<AddWhite />} onClick={handleShowNewEvent}>
                  Vessel movements &amp; events
                </AddEventButton>
              )}
            />
          </InformationRow>
          {showForm && (
            <NewEventFormInline
              handleClose={handleShowNewEvent}
              handleSubmit={onAddEventHandler}
              addingNew={addingNew}
            />
          )}
          <EditPane title={title} padding="0px 0px 20px 0px">
            <TimelineHeader displayInUtcTime={displayInUtcTime} setDisplayInUtcTime={setDisplayInUtcTime} />
            <TimelineView
              data={items}
              onRemove={removeItem}
              canEdit
              onAddNew={onAddNewHandler}
              displayInUtcTime={displayInUtcTime}
            />
          </EditPane>
        </TimelineProvider>
      </ScrollUpdater>
    </FullwidthWidgetWrapper>
  );
};
VesselEvents.propTypes = widgetComponentPropType;
