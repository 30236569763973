import * as Yup from 'yup';
import { stringMaxLength, stringRequiredMaxLength } from '@/utils/validationSchemas';

export const contactSchema = Yup.object().shape({
  name: stringRequiredMaxLength(),
  function: stringRequiredMaxLength(),
  phone: stringMaxLength(),
  email: stringMaxLength(90)
    .email('Invalid email')
    .required('Required'),
});
