import {
  TimeDetails, TimeDetailsForm, TimeStatus
} from '@/types';
import { normalizeDuration } from '@/utils/date';

export const mapToTimeDetailsForm = (timeDetails: TimeDetails): TimeDetailsForm => ({
  ...timeDetails,
  durationTimeStatus: {
    label: timeDetails.durationTimeStatus,
    value: timeDetails.durationTimeStatus,
  },
  separateDaysDryDockTimeStatus: {
    label: timeDetails.separateDaysDryDockTimeStatus,
    value: timeDetails.separateDaysDryDockTimeStatus,
  },
  separateDaysAfloatTimeStatus: {
    label: timeDetails.separateDaysAfloatTimeStatus,
    value: timeDetails.separateDaysAfloatTimeStatus,
  },
  simultaneousRepairs: timeDetails.simultaneousRepairs ? {
    ...timeDetails.simultaneousRepairs,
    status: {
      label: timeDetails.simultaneousRepairs.status || '',
      value: timeDetails.simultaneousRepairs.status || '',
    }
  }: null,
  /**
   * Ensures that the date strings from the input are of the same format
   * as the date strings in the output. This is neccessary in order to determine the
   * pristine/dirty state of the form data
   */
  duration: timeDetails.duration ? normalizeDuration(timeDetails.duration) : timeDetails.duration,
});

export const mapToTimeDetails = (timeDetails: TimeDetailsForm): TimeDetails => ({
  ...timeDetails,
  durationTimeStatus: timeDetails.durationTimeStatus.value as TimeStatus,
  separateDaysAfloatTimeStatus: timeDetails.separateDaysAfloatTimeStatus.value as TimeStatus,
  separateDaysDryDockTimeStatus: timeDetails.separateDaysDryDockTimeStatus.value as TimeStatus,
  simultaneousRepairs: timeDetails.simultaneousRepairs ? {
    ...timeDetails.simultaneousRepairs,
    status: timeDetails.simultaneousRepairs.status.value as TimeStatus
  } : null
});
