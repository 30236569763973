import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Form, FieldArray } from 'formik';
import { expensesPropType } from '@/prop-types/custom-prop-types';
import { Pane } from '@/components/shared/Pane';
import {
  Divider, Loader, FormikWithPrompt as Formik
} from '@instech/components';
import { useStatusTypes } from '@/services/configurationServices';
import { FormIdProvider } from '@/components/shared/Form/core/useFieldId';
import { SaveButton, AddButton } from '@/components/shared/FormButton/FormButton';
import { ExpenseSummary } from './ExpenseSummary';
import { ExpenseTable } from './ExpenseTable/ExpenseTable';
import { ExpenseTableStatic } from './ExpenseTable/ExpenseTableStatic';
import {
  createExpensesShema, mapToExpenses, mapToExpenseFormTypes
} from './ExpenseListUtils';
import { defaultExpenseItemModel } from './core/common';
import { EditPane } from '../../core/EditPane';

const RightAlignedButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const createDefaultItem = () => ({ ...defaultExpenseItemModel, id: uuidv4() });

const StaticList = ({ dirty, expenses, setFieldValue, handleSubmit, currency }) => (
  <FieldArray
    name="expenses"
    render={arrayHelpers => (
      <>
        <ExpenseTableStatic
          expenses={expenses}
          setFieldValue={setFieldValue}
          arrayHelpers={arrayHelpers}
          currency={currency}
        />
        <Divider />
        <RightAlignedButton>
          <SaveButton
            onClick={handleSubmit}
            disabled={!dirty}
            spaceBetween
          />
        </RightAlignedButton>
      </>
    )}
  />
);

StaticList.propTypes = {
  dirty: PropTypes.bool,
  expenses: PropTypes.arrayOf(PropTypes.object),
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  currency: PropTypes.string
};

const LeftAlignedButton = styled.div`
  margin-top: 15px;
`;

const DynamicList = ({ dirty, expenses, addLabel, setFieldValue, handleSubmit, currency }) => (
  <FieldArray
    name="expenses"
    render={arrayHelpers => {
      const addNewItem = () => arrayHelpers.push(createDefaultItem());
      return (
        <>
          <ExpenseTable
            expenses={expenses}
            setFieldValue={setFieldValue}
            arrayHelpers={arrayHelpers}
            currency={currency}
          />
          <LeftAlignedButton>
            <AddButton
              label={addLabel}
              onClick={addNewItem}
              spaceBetween />
          </LeftAlignedButton>
          <Divider />
          <RightAlignedButton>
            <SaveButton
              onClick={handleSubmit}
              disabled={!dirty}
              spaceBetween
            />
          </RightAlignedButton>
        </>
      );
    }}
  />
);

const setInitialState = expenses => expenses.length ? mapToExpenseFormTypes(expenses) : [createDefaultItem()];

DynamicList.propTypes = {
  dirty: PropTypes.bool,
  expenses: PropTypes.arrayOf(PropTypes.object),
  addLabel: PropTypes.string,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  currency: PropTypes.string,
};

// @TODO there is some serious lag when trying to input text in text fields while holding a button down.
export const ExpenseList = ({ formId, title, data, onSubmit, isStatic, addLabel }) => {
  const { data: statusTypes } = useStatusTypes();
  const [initialExpenses, setInitialExpenses] = useState(setInitialState(data.items));
  if (!statusTypes) return <Loader />;

  // Only 'Work not Concerning Average' has a report preview component at this time.
  // Do not show link to the report preview for other widgets.
  const isWorkNotConcerningAverage = title === 'Cost for work not concerning average';
  const PaneComponent = isWorkNotConcerningAverage ? EditPane : Pane;

  const expensesShema = createExpensesShema(statusTypes);
  return (
    <PaneComponent title={title} margin="0px 0px 30px">
      <Formik
        formId={formId}
        initialValues={{ expenses: initialExpenses }}
        validationSchema={expensesShema}
        enableReinitialize
        onSubmit={async (newValues, { resetForm }) => {
          const newExpenses = mapToExpenses(newValues.expenses);

          try {
            await onSubmit(newExpenses);
            const updatedValues = setInitialState(newExpenses);
            setInitialExpenses(updatedValues);
            resetForm({ values: { expenses: updatedValues } });
          } catch {
            // eslint-disable-next-line no-console
            console.error('Could not submit data.', newExpenses);
          }
        }}
      >
        {({ values, setFieldValue, dirty, handleSubmit }) => (
          <FormIdProvider formId={formId}>
            <ExpenseSummary currency={data.currency} expenses={values.expenses} />
            <Form>
              {isStatic
                ? (
                  <StaticList
                    dirty={dirty}
                    expenses={values.expenses}
                    setFieldValue={setFieldValue}
                    handleSubmit={handleSubmit}
                    currency={data.currency}
                  />
                )
                : (
                  <DynamicList
                    dirty={dirty}
                    expenses={values.expenses}
                    setFieldValue={setFieldValue}
                    handleSubmit={handleSubmit}
                    addLabel={addLabel}
                    currency={data.currency}
                  />
                )}
            </Form>
          </FormIdProvider>
        )}
      </Formik>
    </PaneComponent>
  );
};

ExpenseList.propTypes = {
  /** Only required if there are multiple ExpenseTables per page.
 * Used to set unique htmlFor ids on labels and controls. */
  formId: PropTypes.string,
  title: PropTypes.string,
  data: expensesPropType,
  addLabel: PropTypes.string,
  isStatic: PropTypes.bool,
  onSubmit: PropTypes.func
};
