import styled from 'styled-components';

export const TextLink = styled.span`
  color: ${props => props.theme.deepAzureBlue};
  font-weight: bold;
  :hover, :active {
    text-decoration: underline;
    cursor: pointer;
  }
`;
