import styled from 'styled-components';
import {
  Table, TableCell, TableHeader, TableRow
} from '@/components/shared/Table';
import { SimultaneousRepairs as SimultaneousRepairsType } from '@/types';
import { useStaticResource } from '@/services/staticResourcesServices';
import { Loader } from '@instech/components';
import { Text, Header } from './Components';

const Wrapper = styled.div`
  margin-top: 20px;
`;

const Comment = styled(Text)`
  padding-top: 20px;
`;

interface Props extends SimultaneousRepairsType {
  showWarning?: boolean;
}
export const SimultaneousRepairs = ({ days, comment, status, includeCommentInReport, showWarning }: Props) => {
  const { data: infoText } = useStaticResource('lossOfHireTimeForWorkNotConcerningAverage');

  if (!infoText) return <Loader />;

  return (
    <Wrapper>
      <Header>Time for Simultaneous Repairs</Header>
      <Text>{infoText}</Text>
      {showWarning && <Text>This part will only be shown in reports published as LOH:</Text>}
      <Table layout="2fr 1fr 2fr">
        <TableHeader />
        <TableHeader>Days</TableHeader>
        <TableHeader>Status</TableHeader>
        <TableRow even>
          <TableCell>Time for Simultaneous Repairs</TableCell>
          <TableCell>{days}</TableCell>
          <TableCell>{status}</TableCell>
        </TableRow>
      </Table>
      {(includeCommentInReport && comment) && <Comment>{comment}</Comment>}
    </Wrapper>
  );
};
