import useSWR from 'swr';
import { putJsonAsync } from './client';

const apiRoute = '/profile';

export interface UserModel {
  displayName: string;
  title: string | null;
  email: string;
  phoneNumber: string | null;
}

export interface UpdateUserRequest {
  displayName: string;
  phoneNumber: string | null;
  title: string | null;
}

export const useProfileService = () => {
  const { data, error, mutate } = useSWR<UserModel>(apiRoute);

  if (error) {
    throw error;
  }

  const updateProfile = async (profile: UpdateUserRequest) => {
    const response = await putJsonAsync<UserModel>(apiRoute, profile);
    await mutate(response);
  };

  return { data, updateProfile };
};
