import { Part as PartType, Widget } from '@/utils/archivedReportTypes';
import { Tool, ChapterBase } from '@/utils/manifestTypes';

const mergePartsAndTools = (parts: PartType[], tools: Tool[]) => [
  ...parts.flatMap(p => p.chapters.map(c => ({ id: c.id, title: c.title, widgets: c.widgets }))),
  ...tools.flatMap(t => t.widgets).map(tw => ({ id: tw.widgetId as string, title: tw.title, widgets: [tw] }))
];

export interface ChapterType extends ChapterBase {
  widgets: Widget[] | null;
}

const toTitleDictionary = (chapters: ChapterType[]) => Object.fromEntries(chapters.map(c => [c.title, c]));

export const getTitleChapterDictionary = (parts: PartType[], tools: Tool[]) => toTitleDictionary(mergePartsAndTools(parts, tools));
