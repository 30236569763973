import { FunctionComponent } from 'react';
import { UserSingle } from '@instech/icons';
import styled from 'styled-components';
import { Pane } from '@instech/components';

const Text = styled.p`
  font-size: 14px;
  margin-bottom: 27px;
`;

const Title = styled.h3`
  font-size: 18px;
  color: #003A4D;
`;

const Email = styled.a`
  text-decoration: underline;
  font-size: 14px;
  margin-top: 5px;
`;

export const ContactPersons: FunctionComponent = () => (
  <Pane title="How to contact support" margin="32px 0px 0px" icon={<UserSingle />}>
    <Text>Please refer to the information below to ensure that you get help from the correct department or person to better assist you.</Text>
    <Title>For questions regarding a specific claim</Title>
    <Text>Please contact the Claims Handler or Surveyor associated with that specific claim.</Text>
    <Title>For questions regarding how to use the portal</Title>
    <Text>
      Please send an email to Norwegian Hull Club´s technical department at
      <Email href="mailto:steinar.sivertsen@norclub.com"> steinar.sivertsen@norclub.com</Email>
    </Text>
    <Title>To report a bug, suggest a new feature or suggest an improvement</Title>
    <Text>
      Please send an email to our Survey support team at
      <Email href="mailto:issd@instech.atlassian.net"> issd@instech.atlassian.net</Email>
    </Text>
    <Text>
      When contacting us about a problem you are having, please consider providing the following information
      (when relevant) so we can better assist you:
    </Text>
    <ul>
      <li>A sensible step-by-step description of the issue</li>
      <li>The browser and device you are using</li>
      <li>How frequently the issue occurs</li>
      <li>Any screenshots to clarify your issue or question</li>
      <li>The claim number, policy year and vessel name if the issue is specifically related to one particular assignment</li>
    </ul>
  </Pane>
);
