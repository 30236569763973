import { Guid } from '@/types';
import client from './client';

export interface VesselInformationAdd {
  title: string;
  value: string;
  includeInReport: boolean;
  editable: boolean;
}

export interface VesselInformationUpdate extends VesselInformationAdd {
  id: Guid;
}

export interface VesselInformation {
  id: string;
  title: string;
  value: string;
  includeInReport: boolean;
  source: {
    name: string;
    date: string;
  };
  editable: boolean;
}

export const vesselInformationApiUrl = (caseId: string) => `/case/${caseId}/vesselinformation`;
export const getVesselInformationAsync = async (caseId: string) => {
  const result = await client.get<VesselInformation[]>(vesselInformationApiUrl(caseId));
  return result.data;
};

export const addVesselInformationAsync = async (caseId: string, information: VesselInformationAdd) => {
  const result = await client.post<VesselInformation>(vesselInformationApiUrl(caseId), information);
  return result.data;
};

export const deleteVesselInformationAsync = async (caseId: string, itemId: string) => {
  await client.delete(`${vesselInformationApiUrl(caseId)}/${itemId}`);
};

export const updateVesselInformationAsync = async (caseId: Guid, information: VesselInformationUpdate) => {
  const url = `${vesselInformationApiUrl(caseId)}/${information.id}`;
  await client.put<VesselInformation>(url, information);
};

export const restoreDeletedVesselInformationAsync = async (caseId: string, itemId: string) => {
  const result = await client.post<VesselInformation[]>(`${vesselInformationApiUrl(caseId)}/${itemId}`);
  return result.data;
};
