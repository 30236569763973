import styled from 'styled-components';
import { NavBox } from '@/components/shared/BoxButton/BoxButtons';
import { editRoute, reportPreviewRoute } from '@/utils/routeTemplates';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useCaseStatus } from '@/services/caseStatusServices';

const PrimaryRow = styled.div`
  border: 2px solid ${props => props.theme.border.blueGray};
  padding: 16px 32px;
  margin: 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
`;

const SecondaryRow = styled.div`
  display: flex;
  > * {
    margin-left: 16px;
    > button {
      width: 140px;
    }
  }
`;

const Title = styled.div`
  font-size: 16px;
  flex-grow: 1;
`;

export const DraftNavigationContainer = () => {
  const caseId = useCaseIdFromUrl();
  const { data: caseStatus } = useCaseStatus();

  const previewUrl = reportPreviewRoute(caseId);
  const editUrl = editRoute(caseId);

  const canEditReport = caseStatus && caseStatus.isClosed === false;

  return (
    <PrimaryRow>
      <Title>Report Template</Title>
      <SecondaryRow>
        <NavBox inverted padding="10px" to={previewUrl}>Preview Report</NavBox>
        {canEditReport && <NavBox padding="10px" to={editUrl}>Edit Report</NavBox>}
      </SecondaryRow>
    </PrimaryRow>
  );
};
