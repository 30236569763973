import useSWR from 'swr';
import { Comment } from '@/types';
import client from '@/services/client';

interface BaseArgs {
  caseId: string;
  chapterId: string;
  widgetId: string;
}

const commentsUrl = ({ caseId, chapterId, widgetId }: BaseArgs) => (
  `/comments/case/${caseId}/chapters/${chapterId}/widget/${widgetId}`
);

interface UseCommentsArgs extends BaseArgs { swrOptions: any }

export const useComments = ({ caseId, chapterId, widgetId, swrOptions }: UseCommentsArgs) => {
  const requestUrl = commentsUrl({ caseId, chapterId, widgetId });
  const swr = useSWR(requestUrl, swrOptions);
  return swr;
};

interface UpdateCommentsAsyncArgs extends BaseArgs { comment: Comment }

export const updateCommentsAsync = async ({ caseId, chapterId, widgetId, comment } : UpdateCommentsAsyncArgs) => {
  const requestUrl = commentsUrl({ caseId, chapterId, widgetId });
  const { data } = await client.put(requestUrl, comment);
  return data;
};
