import PropTypes from 'prop-types';
import styled from 'styled-components';
import { childrenPropType } from '../../../prop-types/custom-prop-types';

const StyledButton = styled.button`
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.marineBlue};
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
  cursor: pointer;
  color: ${props => props.inverted ? props.theme.marineBlue : props.theme.white};
  background-color: ${props => props.inverted ? props.theme.white : props.theme.marineBlue};
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, width 0.3s ease;

  & svg {
    height:25px;
  }
  :disabled {
    color: ${props => props.inverted ? props.theme.black : props.theme.darkGray};
    background-color: ${props => props.inverted ? props.theme.ultraLightGray : props.theme.mediumGray};
    border-color: ${props => props.inverted ? props.theme.marineBlue50 : 'transparent'};
    cursor: default;
  }
  @media (hover: hover) { 
    &:hover {
      background-color: ${props => props.inverted ? props.theme.white : props.theme.marineBlue75};
      border-color: ${props => props.inverted ? props.theme.white : 'transparent'};
    };
  }
`;

const TextContainer = styled.span`
  text-align: left;
  font-size: ${props => props.fontSize ? props.fontSize : '13px'};
  font-weight: ${props => props.boldFont && 'bold'};
`;

const Container = styled.div`
  padding: 0px 9px ;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const IconContainer = styled.div`
  padding-right: ${props => props.start && '10px'};
  padding-left: ${props => props.end && '10px'};
  display: flex;
`;

export const ActionButton = (
  { children, inverted, startIcon: StartIcon, endIcon: EndIcon, width, height, fontSize, boldFont = true, onClick, disabled, type='button', className }
) => (
  <StyledButton inverted={inverted} width={width} height={height} onClick={onClick} type={type} disabled={disabled} className={className}>
    <Container inverted={inverted}>
      {StartIcon && <IconContainer start="true">{StartIcon}</IconContainer>}
      <TextContainer fontSize={fontSize} boldFont={boldFont}>{children}</TextContainer>
      {EndIcon && <IconContainer end="true">{EndIcon}</IconContainer>}
    </Container>
  </StyledButton>
);

ActionButton.propTypes = {
  children: childrenPropType,
  inverted: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  boldFont: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  className: PropTypes.string
};
