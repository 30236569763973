import { FunctionComponent, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import deepEqual from 'deep-equal';
import { Form, FieldArray } from 'formik';
import { Trash } from '@instech/icons';
import {
  Table, TableHeader, TableRow, TableCell, CellButton
} from '@/components/shared/Table';
import { AttendingRepresentative } from '@/services/representativesServices';
import { SurveyLocation } from '@/types';
import { stringMaxLength, stringRequiredMaxLength } from '@/utils/validationSchemas';
import { AddSaveButtons } from '@/components/shared/FormButton/FormButtons';
import { ArrayTextField, ArrayCheckbox } from '@/components/shared/Table/ArrayFields';
import { Divider, FormikWithPrompt as Formik } from '@instech/components';
import { FormIdProvider } from '@/components/shared/Form/core/useFieldId';

function representativeModel(surveyLocationId: string, itemId: string): AttendingRepresentative {
  return ({
    id: itemId,
    name: '',
    function: '',
    company: '',
    representing: '',
    includeInReport: false,
    surveyLocationId
  });
}

const representativesModelSchema = Yup.object().shape({
  name: stringRequiredMaxLength(),
  function: stringRequiredMaxLength(),
  company: stringRequiredMaxLength(),
  representing: stringMaxLength(),
  includeInReport: Yup.boolean()
});

const representativesSchema = Yup.object().shape({
  representatives: Yup.array().of(representativesModelSchema)
});

// Create initial state of existing entries or one new empty entry
const setInitialState = (representatives: AttendingRepresentative[], locationId: string, itemId: string): AttendingRepresentative[] => (
  representatives.length ? representatives : [representativeModel(locationId, itemId)]
);

const TableHeaders = () => (
  <>
    <TableHeader>Name</TableHeader>
    <TableHeader>Function</TableHeader>
    <TableHeader>Company</TableHeader>
    <TableHeader>Representing</TableHeader>
    <TableHeader>Include in report</TableHeader>
    <TableHeader />
  </>
);

const deepEqualsDefaultModel = (representative: AttendingRepresentative) => {
  const { id, surveyLocationId, ...defaultModel } = representativeModel('', '');
  const { id: _id2, surveyLocationId: _id3, ...locationWithoutId } = representative;
  return deepEqual(defaultModel, locationWithoutId);
};

interface TableEntryProps {
  index: number;
  onRemove: () => void;
  location: SurveyLocation;
  disableDelete: boolean;
}
const TableEntry = ({ index, onRemove, location, disableDelete }: TableEntryProps) => (
  <TableRow even={index % 2 === 0}>
    <TableCell>
      <ArrayTextField name={`representatives.${index}.name`} placeholder='e.g. "Name Nameson"' />
    </TableCell>
    <TableCell>
      <ArrayTextField name={`representatives.${index}.function`} placeholder='e.g. "Vessel manager"' />
    </TableCell>
    <TableCell>
      <ArrayTextField name={`representatives.${index}.company`} placeholder='e.g. "Norwegian Hull Club"' />
    </TableCell>
    <TableCell>
      <ArrayTextField name={`representatives.${index}.representing`} placeholder='e.g. "Client"' />
    </TableCell>
    <TableCell checkbox>
      <ArrayCheckbox name={`representatives.${index}.includeInReport`}
        disabled={!location.report}
        title={location.report ? '' : 'Location not included in report'} />
    </TableCell>
    <TableCell right button>
      <CellButton
        lineLeft
        smallIcon
        icon={<Trash />}
        onClick={!disableDelete ? onRemove : undefined}
        disabled={disableDelete} />
    </TableCell>
  </TableRow>
);

interface Props {
  representatives: AttendingRepresentative[];
  onSave: (newRepresentatives: AttendingRepresentative[]) => Promise<AttendingRepresentative[]>;
  surveyLocation: SurveyLocation;
}
export const RepresentativesTable: FunctionComponent<Props> = ({ representatives, onSave, surveyLocation }) => {
  const [initialItemId, setInitialItemId] = useState(uuidv4());
  return (
    <Formik
      formId={surveyLocation.id}
      initialValues={{
        representatives: setInitialState(representatives, surveyLocation.id, initialItemId)
      }}
      validationSchema={representativesSchema}
      onSubmit={async (values, actions) => {
        const result = await onSave(values.representatives);

        actions.resetForm({
          values: {
            representatives: setInitialState(result, surveyLocation.id, initialItemId)
          }
        });
      }}
    >
      {({ values, handleSubmit, dirty, isSubmitting }) => (
        <Form>
          <FormIdProvider formId={surveyLocation.id}>
            <FieldArray
              name="representatives"
              render={({ push, remove }) => (
                <>
                  <Table columns={6}>
                    <TableHeaders />
                    {values.representatives.map((row, i) => (
                      <TableEntry
                        key={row.id}
                        index={i}
                        onRemove={() => {
                          remove(i);
                          if (values.representatives.length === 1) {
                            setInitialItemId(uuidv4());
                          }
                        }}
                        location={surveyLocation}
                        disableDelete={values.representatives.length === 1 && deepEqualsDefaultModel(row)} />
                    ))}
                  </Table>
                  <Divider />
                  <AddSaveButtons
                    isSubmitting={isSubmitting}
                    addLabel="Representative"
                    onAdd={() => push({ ...representativeModel(surveyLocation.id, uuidv4()) })}
                    onSave={dirty ? handleSubmit : null}
                    disableSave={!dirty}
                    spaceBetween />
                </>
              )} />
          </FormIdProvider>
        </Form>
      )}
    </Formik>
  );
};
