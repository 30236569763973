import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ComingSoon as RowingIcon } from '@instech/icons';
import {
  Divider, ButtonGroup, BoxButton
} from '@instech/components';
import { useModalContext } from '../../modal/ModalContext';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const Title = styled.h2`
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 29px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
`;

const Text = styled.p`
  max-width: 180px;
  margin: 0px;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
`;

const defaultDescription = 'We are working on a standard instruction text to replace this';

interface Props {
  description?: string;
}
export const ComingSoon: FunctionComponent<Props> = ({ description = defaultDescription }) => {
  const modal = useModalContext();

  return (
    <div>
      <Content>
        <RowingIcon />
        <Title>Coming soon</Title>
        <Text>{description}</Text>
      </Content>
      <Divider />
      <ButtonGroup alignRight>
        <BoxButton onClick={modal.close}>Done</BoxButton>
      </ButtonGroup>
    </div>
  );
};
