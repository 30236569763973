import { useState } from 'react';
import styled from 'styled-components';
import { roles } from '@/components/AppRouting/roles';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import {
  ButtonGroup, BoxButton, Divider
} from '@instech/components';
import { useModalContext } from '@/components/modal/ModalContext';
import { useCaseStatus } from '@/services/caseStatusServices';
import { Tab } from './shared/TabItem';
import { Certificates } from './certificates/Certificates';
import { EquipmentsParticulars } from './equipment/EquipmentsParticulars';
import { VesselInformation } from './information/VesselInformation';

const tabs = [
  { id: 1, title: 'Vessel information', page: VesselInformation },
  { id: 2, title: 'Equipment\'s particulars', page: EquipmentsParticulars },
  { id: 3, title: 'Certificates', page: Certificates }
];

const TabHeader = styled.div`
    display: flex;
    text-align: center;
    height: 50px;
`;

export enum VesselInformationTab {
  VesselsParticularsTab = 0,
  EquipmentsParticularsTab = 1,
  CertificatesTab = 2
}

interface TabProp {
  currentTab: VesselInformationTab;
}

export const VesselInformationTool = ({ currentTab = VesselInformationTab.VesselsParticularsTab }: TabProp) => {
  const [{ id: currentId, page: CurrentPage }, setCurrentTab] = useState(tabs[currentTab]);
  const { close } = useModalContext();
  const { data: status } = useCaseStatus(true);
  const role = useCurrentRole();
  const readonlyUser = role?.name !== roles.surveyor || status?.isClosed;
  return (
    <>
      <TabHeader>
        {tabs.map(tab => (
          <Tab
            key={tab.id}
            title={tab.title}
            active={tab.id === currentId}
            onClick={() => setCurrentTab(tab)}
          />
        ))}
      </TabHeader>
      <>
        <CurrentPage readonly={readonlyUser} />
        {readonlyUser && (
          <>
            <Divider padding="0px" />
            <ButtonGroup alignRight>
              <BoxButton onClick={close}>Done</BoxButton>
            </ButtonGroup>
          </>
        )}
      </>
    </>
  );
};

export const openVesselInformationTool = ({ currentTab = VesselInformationTab.VesselsParticularsTab }: TabProp) => ({
  component: VesselInformationTool,
  options: {
    title: 'Vessel information',
    footer: true
  },
  args: { currentTab }
});
