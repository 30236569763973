import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useModalContext } from '@/components/modal/ModalContext';
import { BoxButton } from '@instech/components';
import { AddWhite } from '@instech/icons';
import { SubcategoriesDialog } from '@/components/shared/SubcategoriesDialog/SubcategoriesDialog';

const StyledBoxButton = styled(BoxButton)`
  margin-left: 20px;
`;

const getModalOptions = ({ caseId, chapterId }) => ({
  component: SubcategoriesDialog,
  options: {
    title: 'Subcategories',
    size: 'small'
  },
  args: {
    caseId,
    chapterId
  }
});

export const SubcategoriesButton = ({ caseId, chapterId }) => {
  const { open } = useModalContext();

  return (
    <StyledBoxButton
      startIcon={<AddWhite />}
      onClick={() => open(getModalOptions({ caseId, chapterId }))}
    >
      Subcategories
    </StyledBoxButton>
  );
};
SubcategoriesButton.propTypes = {
  caseId: PropTypes.string.isRequired,
  chapterId: PropTypes.string.isRequired
};
