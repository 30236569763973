import styled from 'styled-components';
import { NewBoxButton } from '@/components/shared/BoxButton/NewBoxButton';
import { Locked, OpenLock } from '@instech/icons';

const openedText = 'The Surveyor will be able to edit the report.';

const closedText = `The Surveyor will no longer have access to edit the report.
                    Information covered by GDPR rules is hidden and will be deleted after 10 years.
                    Only a Claims Handler will be able to reopen the case.`;

const Content = styled.div`
  text-align: center;
  align-content: center;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${props => props.theme.marineBlue};
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 15px;
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

interface ConfirmationProps {
  closed: boolean;
  close: () => void;
}

export const ConfirmationFrame = ({ closed, close }: ConfirmationProps) => {
  const icon = closed ? <Locked width="60px" fill="currentColor" /> : <OpenLock width="60px" />;
  const header = `Claim status changed to ${closed ? 'Closed' : 'Open'}`;
  const text = closed ? closedText : openedText;

  return (
    <Content>
      <Icon>{icon}</Icon>
      <Header>{header}</Header>
      <div>{text}</div>
      <ButtonArea>
        <NewBoxButton width="90px" onClick={close}>Done</NewBoxButton>
      </ButtonArea>
    </Content>
  );
};
