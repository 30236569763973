import {
  FormField, Pane, TextField
} from '@instech/components';

export const BrokerFields = () => (
  <Pane title="Broker" padding="20px" margin="16px 0px 0px">
    <FormField>
      <TextField name="broker.companyName" placeholder="Company name" label="Company name" />
    </FormField>
    <FormField>
      <TextField name="broker.name" placeholder="Name" label="Contact name" />
    </FormField>
    <FormField>
      <TextField name="broker.email" placeholder="Email" label="Email" />
    </FormField>
    <FormField>
      <TextField name="broker.phone" placeholder="Number" label="Phone number" />
    </FormField>
  </Pane>
);
