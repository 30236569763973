import { FunctionComponent } from 'react';
import { ApplicationRole } from '@/types';
import {
  Redirect,
  Route,
  Switch
} from 'react-router';
import { useCurrentUserInfo } from '@/services/userInfoService';
import { PageLoader } from '@instech/components';
import { useRoles } from '@/services/rolesServices';
import { useUserSessionSlide } from '@/utils/sessionSlide/useUserSessionSlide';
import { roles } from './roles';
import { SurveyorRoutes } from './SurveyorRoutes';
import { ClaimsHandlerRoutes } from './ClaimsHandlerRoutes';
import { AdminRoutes } from './AdminRoutes';
import { useCurrentRoleContext } from '../authentication/CurrentRoleContext';
import { ProfilePage } from '../pages/profile/ProfilePage';
import { AcceptTermsPage } from '../pages/acceptTerms/AcceptTermsPage';
import { NoAccessPage } from './NoAccess';
import { FeedbackAndSupportPage } from '../pages/FeedbackAndSupportPage/FeedbackAndSupportPage';
import { UpdatesPage } from '../pages/UpdatesPage/UpdatesPage';
import { useAccount } from '../authentication/AuthenticationProvider';

const RoleRouting: FunctionComponent<ApplicationRole> = ({ name }) => {
  switch (name) {
    case roles.surveyor:
      return <SurveyorRoutes />;

    case roles.claimHandler:
      return <ClaimsHandlerRoutes />;

    case roles.admin:
      return <AdminRoutes />;

    default:
      return <NoAccessPage />;
  }
};

const InnerRouting: FunctionComponent<ApplicationRole> = props => (
  <Switch>
    <Route path="/profile">
      <ProfilePage />
    </Route>
    <Route exact path="/feedback-and-support">
      <FeedbackAndSupportPage />
    </Route>
    <Route exact path="/announcements">
      <UpdatesPage />
    </Route>
    <Route path="*">
      <RoleRouting {...props} />
    </Route>
  </Switch>
);

const TermsOfUseRouting = () => (
  <Switch>
    <Route path="*">
      <AcceptTermsPage />
    </Route>
  </Switch>
);

export const AppRouting = () => {
  const { currentRole } = useCurrentRoleContext();
  const { data: userInfo, isValidating: isValidatingUserInfo } = useCurrentUserInfo();
  const { data: userRoles } = useRoles();
  const { user } = useAccount() as unknown as { user: { internal: string } };
  useUserSessionSlide();

  const isLoading = (!userInfo && isValidatingUserInfo) || !userRoles;
  if (isLoading) {
    return <PageLoader />;
  }
  if (!userInfo && !isValidatingUserInfo) {
    return <Redirect to="/unauthorized" />;
  }

  if (!user.internal && !userInfo?.hasAcceptedTerms) {
    return <TermsOfUseRouting />;
  }
  return <InnerRouting {...(currentRole as any)} />;
};
