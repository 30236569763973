import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { CaseStatus } from '../CaseStatus';

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  color: ${props => props.theme.marineBlue50};
  margin-left: -.12em; // Offset spacing around the symbols
`;

const BackLink = styled(NavLink)`
  &:hover { text-decoration: underline; }
`;

const Divider = styled.span`
  margin-left: .25em;
  margin-right: .25em;
`;

const VesselName = styled.div`
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  color: ${props => props.theme.marineBlue};
  text-transform: uppercase;
`;

const DashboardHeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface DashboardHeaderProps {
  vesselName: string;
  behindClaimWall?: boolean;
}
// This doesn't feel super elegant, for the markers around the text, but it's
// easier to sort the acutal link styling this way compared to :before and :after markers
export const DashboardHeader: FunctionComponent<DashboardHeaderProps> = ({ vesselName, behindClaimWall }) => (
  <DashboardHeaderArea>
    <div>
      <Title>
        <Divider>{'<'}</Divider>
        <BackLink to="/">Claims dashboard</BackLink>
        <Divider>/</Divider>
      </Title>
      <VesselName>{vesselName}</VesselName>
    </div>
    { !behindClaimWall && (
      <div>
        <CaseStatus />
      </div>
    )}
  </DashboardHeaderArea>
);
