import { UserInfo } from '@/types';
import { FC } from 'react';
import styled from 'styled-components';
import { SimpleTable, TableHeader } from '@instech/components';
import { NoUsersRow, UserTableRows } from './TableRows';

const StyledHeading = styled(TableHeader)`
  font-size: 14px;
  font-weight: bold;
  padding: 12px 8px 16px;
  box-sizing: border-box;
  border-bottom: 2px solid ${({ theme }) => theme.marineBlue};
`;

interface Props {
  users: UserInfo[];
  primaryId?: string;
}
export const UserInfoTable: FC<Props> = ({ users, primaryId }) => (
  <SimpleTable layout="repeat(2, 1fr) 64px">
    <StyledHeading>Full Name</StyledHeading>
    <StyledHeading>Company</StyledHeading>
    <StyledHeading>Primary</StyledHeading>
    {!users?.length
      ? <NoUsersRow />
      : <UserTableRows users={users} primaryId={primaryId} />}
  </SimpleTable>
);
