import styled, { css } from 'styled-components';

export const ListContainer = styled.div`
  background-color: ${props => props.theme.marineBlue10};
  border-radius: 2px;
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: 5px;
  width: 100%;
  padding: 8px 4px;
`;

interface DropdownProps {
  right?: boolean;
  last?: boolean;
  isOpen?: boolean;
}
export const DropdownContainer = styled.div<DropdownProps>`
  display: ${props => props.isOpen ? 'initial' : 'none'};
  position: absolute;
  background-color: white;
  width: 284px;
  padding: 12px;
  box-sizing: border-box;
  z-index: 30;
  box-shadow: 2px 8px 24px rgba(0,82,137,0.2);
  // Ensure the dropdown can properly align to the right of its parent.
  // The 'last' prop was added for the header of the last table column.
  right: ${props => props.right && (props.last ? '8px' : '0')};
`;

// Each element in Dropdown is wrapped in this div. The first
// element of them is attributed 'first' and the rest auto-add space
// between them and the preceding.
export const DropdownElement = styled.div<{ first?: boolean }>`
  color: ${props => props.theme.marineBlue};
  display: flex;
  align-items: center;
  cursor: pointer;

  ${props => !props.first && css`
    margin-top: 12px;
  `};
`;

export const GridTable = styled.div<{ columns: string; }>`
  display: grid;
  background-color: ${props => props.theme.flatWhite};
  grid-template-columns: ${props => props.columns};
`;

interface TableCellProps {
  span?: string | number;
  disabled?: boolean;
  right?: boolean;
  center?: boolean;
  slim?: boolean;
  height?: string;
  hover?: boolean;
  textWrap?: boolean;
  backgroundColor?: string;
  minWidth?: string;
}
export const TableCell = styled.span<TableCellProps>`
  ${props => !props.height && css`
    min-height: 44px; // The design shows both 40 and 48px cells, for now we use one size for all
  `};   // except! when we need to set specific heights...
  display: flex;
  ${props => props.minWidth && css`
    min-width: ${props.minWidth};
  `}
  flex-direction: ${props => props.center ? 'row' : 'column'};
  justify-content: center;
  color: ${props => props.theme.marineBlue};
  grid-column: ${props => props.span ? `span ${props.span}` : ''};
  padding: ${props => props.slim ? 0 : '10px'};
  box-sizing: border-box;
  white-space: ${props => !props.textWrap && 'nowrap'};
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.hover && css`
    &:hover {
      background-color: ${props.theme.whiteBlue} !important;
      cursor: pointer;
    }
  `};
`;

export const FilterRow = styled.div`
  padding: 20px 16px 8px;
  > * ~ * { // Auto-add spacing between dropdowns in the row
    margin-left: 16px;
  }
`;
