import { FunctionComponent } from 'react';
import { ContentArea } from '../../core/Components';
import { PageTitle } from '../../core/PageTitle';
import { ContactPersons } from './ContactPersons';

export const Support: FunctionComponent = () => (
  <ContentArea>
    <PageTitle title="SUPPORT" />
    <ContactPersons />
  </ContentArea>
);
