import { useState } from 'react';
import { useParams } from 'react-router';
import useSWR from 'swr';
import client from '@/services/client';
import { DamageDescription } from '@/types';

export const damageDescriptionUrl = (caseId: string, chapterId: string, widgetId: string) =>
  `/damagedescription/case/${caseId}/chapters/${chapterId}/widget/${widgetId}`;

export const useDamageDescriptionViewService = (caseId: string, chapterId: string, widgetId: string) => {
  const apiRoute = damageDescriptionUrl(caseId, chapterId, widgetId);
  const { data, error } = useSWR<DamageDescription>(apiRoute);
  if (error) {
    throw error;
  }
  return [data];
};

export const updateDamageDescriptionAsync = async (
  caseId: string,
  chapterId: string,
  widgetId: string,
  item: DamageDescription
) => {
  const url = damageDescriptionUrl(caseId, chapterId, widgetId);
  const { data } = await client.put<DamageDescription>(url, item);
  return data;
};

export const useDamageDescription = (
  widgetId: string
) => {
  const [updateError, setUpdateError] = useState('');
  const { chapterId, id: caseId } = useParams<{ chapterId: string, id: string }>();
  const url = damageDescriptionUrl(caseId, chapterId, widgetId);
  const { data, mutate } = useSWR<DamageDescription>(url, { suspense: true });

  if (updateError) {
    throw new Error(updateError);
  }

  const update = async (newItem: DamageDescription) => {
    try {
      void mutate(newItem); // Update cached value optimistically to avoid "blinking" on 'Save' button
      const result = await updateDamageDescriptionAsync(caseId, chapterId, widgetId, newItem);
      void mutate(result);
      return result;
    } catch (errorMessage) {
      setUpdateError(errorMessage as string);
    }
    return undefined;
  };

  return [data, update] as [DamageDescription, (newItem: DamageDescription) => Promise<DamageDescription | undefined>];
};
