import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { childrenPropType } from '../../../../../../prop-types/custom-prop-types';

const Pane = styled.div`
  border: 2px solid ${props => props.theme.border.blueGray};
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
  ${props => props.top && css`
    border-bottom: 0px;
  `}
  ${props => props.bottom && css`
    border-top: 0px;
  `}
`;

const GridTable = styled.div`
  display: grid;  
  background-color: ${props => props.theme.flatWhite};
  grid-template-columns: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.layout ? props.layout :
      props.columns ? `repeat(${props.columns}, auto)` :
        ''};
`;

export const Table = ({ columns, children, top, bottom, layout }) => (
  <Pane top={top} bottom={bottom}>
    <GridTable columns={columns} layout={layout}>
      {children}
    </GridTable>
  </Pane>
);

Table.propTypes = {
  columns: PropTypes.number,
  children: childrenPropType.isRequired,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  layout: PropTypes.string
};

export const HeaderText = styled.div`
  padding: 14px 20px 16px;
  color: ${props => props.theme.blue};
  font-size: 16px;
  font-weight: bold;
`;

export const SingleColumnTableHeader = styled.span`
  background-color: ${props => props.theme.flatWhite};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TableCell = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 20px 0px;
`;
