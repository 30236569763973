import PropTypes from 'prop-types';
import styled from 'styled-components';
import { childrenPropType } from '../../../../prop-types/custom-prop-types';
import { MenuItem } from './MenuItem';

const StyledMenu = styled.div`
  position: absolute;
  left: 16px;
  display: flex;
  flex-direction: column;
  width: max-content;
  min-width: 100px;
  max-width: 200px;
  border: 1px solid ${props => props.theme.marineBlue50};
  background: ${props => props.theme.white};
  box-sizing: border-box;
  z-index: 30; /* Needs a z-index defined to render over neighbours */
`;

export const Menu = ({ id, setShowing, options }) => {
  const handleClick = onClick => {
    if (!onClick) return;
    onClick();
    setShowing(false);
  };

  return (
    <StyledMenu>
      {options.map(option => (
        <MenuItem
          key={`${id}-${option.label}`}
          icon={option.icon}
          label={option.label}
          disabled={!option.onClick}
          onClick={() => handleClick(option.onClick)}
        />
      ))}
    </StyledMenu>
  );
};
Menu.propTypes = {
  id: PropTypes.string.isRequired,
  setShowing: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    icon: childrenPropType,
    label: PropTypes.string,
    onClick: PropTypes.func
  }))
};
