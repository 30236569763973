import { Page } from '@/components/layout/Page';
import { ClaimsHandlerAssignments } from './ClaimsHandlerAssignments';
import { SurveyorAssignments } from './SurveyorAssignments';

export const ClaimsHandlerAssignmentsPage = () => (
  <Page>
    <ClaimsHandlerAssignments />
  </Page>
);

export const SurveyorAssignmentsPage = () => (
  <Page>
    <SurveyorAssignments />
  </Page>
);
