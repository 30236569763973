import { useModalContext } from '@/components/modal/ModalContext';
import { IconButton } from '@instech/components';
import { Pen } from '@instech/icons';
import { ComponentProps, FC } from 'react';
import styled from 'styled-components';
import { EditOccurrence, showEditOccurrenceModal } from './EditOccurrenceModal';

const StyledPen = styled(Pen)<{ disabled: boolean }>`
  width: 16px;
  height: 16px;
  margin-left: 12px;
  color: ${props => props.disabled ? props.theme.mediumGray : props.theme.blue};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

const EditButton = styled(IconButton)`
  grid-column: 9;
`;

const EditOccurrenceButton: FC<ComponentProps<typeof EditOccurrence>> = props => {
  const modal = showEditOccurrenceModal(props);
  const { open } = useModalContext();

  return (
    <EditButton icon={<StyledPen />} onClick={() => open(modal)} />
  );
};

export default EditOccurrenceButton;
