import useSWR from 'swr';
import client from '@/services/client';

export const useTermsOfuse = () => {
  const requestUrl = '/termsofuse';
  return useSWR<string>(requestUrl);
};

export const downloadTermsOfUsePdf = async () => {
  const requestUrl = '/termsofuse/pdf';
  return client.get<string>(requestUrl, { responseType: 'blob' })
    .then(response => ({
      status: response.status,
      data: window.URL.createObjectURL(new Blob([response.data]))
    }));
};
