import { ComponentProps, FunctionComponent } from 'react';
import { useImagePreviewById, Crop } from '@/services/imageServices';
import { ImageView as InsifyImageView } from '@instech/components';

interface Props extends Omit<ComponentProps<typeof InsifyImageView>, 'image' | 'error'> {
  imageId: string;
  crop: Crop;
}

export const ImageView: FunctionComponent<Props> = (
  { imageId, crop, ...rest }
) => {
  const { data: image, error, isValidating } = useImagePreviewById(imageId, crop);

  return <InsifyImageView image={!isValidating ? image : undefined} error={error} {...rest} />;
};
