import './custom.css';
import { LightboxHandler, PromptOnUnsavedChanges } from '@instech/components';
import { RootRouting } from './components/authentication/RootRouting';
import { AppRouting } from './components/AppRouting/AppRouting';
import { useImagePreviewById } from './services/imageServices';
import { CurrentRoleProvider } from './components/authentication/CurrentRoleContext';
import { FeatureFlagProvider } from './providers/featureFlagProvider';
import { ModalHandler } from './components/modal/Modal';
import { AuthenticationProvider } from './components/authentication/AuthenticationProvider';
import { useWebsiteDown } from './providers/websiteDownProvider';
import { DownForMaintenancePage } from './components/pages/error/DownForMaintenancePage';

export const AppContent = () => (
  <AuthenticationProvider>
    <PromptOnUnsavedChanges>
      <CurrentRoleProvider>
        <FeatureFlagProvider>
          <LightboxHandler imageLoader={useImagePreviewById}>
            <ModalHandler>
              <RootRouting>
                <AppRouting />
              </RootRouting>
            </ModalHandler>
          </LightboxHandler>
        </FeatureFlagProvider>
      </CurrentRoleProvider>
    </PromptOnUnsavedChanges>
  </AuthenticationProvider>
);

export const App = () => {
  const downForMaintenance = useWebsiteDown();

  if (downForMaintenance === undefined) {
    return null;
  }

  if (downForMaintenance) {
    if (window.location.pathname !== '/') {
      window.location.href = '/';
    }
    return <DownForMaintenancePage />;
  }

  return <AppContent />;
};
