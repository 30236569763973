import { useStaticResource } from '@/services/staticResourcesServices';
import styled from 'styled-components';

const Pane = styled.div`
  padding: 12px;
  margin-bottom: 20px;
  background-color: #ECF1F5;
`;

export const ReportPreview = () => {
  const { data: infoText } = useStaticResource('surveyLocationChapterText');
  return (
    <Pane>{infoText}</Pane>
  );
};
