import useSWR from 'swr';
import { Guid } from '@/types';
import client from './client';

export type PdfColorProfile = 'Standard';
export type PdfLogoAlignment = 'Left' | 'Right';

export interface CompanyPdfDesign {
  id?: Guid;
  companyId: Guid;
  colorProfile: PdfColorProfile;
  logoAlignment: PdfLogoAlignment;
  footerText: string;
  logoId?: Guid;
}

export const getDefaultPdfDesign = (companyId: Guid): CompanyPdfDesign => ({
  id: undefined,
  companyId,
  colorProfile: 'Standard',
  logoAlignment: 'Right',
  footerText: '',
  logoId: undefined
});

export const companyPdfDesignApiRoute = 'companypdfdesign';

export const useCompanyPdfDesign = (companyId?: Guid) => {
  const swr = useSWR<CompanyPdfDesign>(companyId ? `${companyPdfDesignApiRoute}/companies/${companyId}` : null);

  if (!swr.isValidating && !swr.data) {
    return { ...swr, data: getDefaultPdfDesign(companyId ?? '') };
  }
  return swr;
};

export const updatePdfDesign = (data: CompanyPdfDesign) =>
  client.put<CompanyPdfDesign>(`${companyPdfDesignApiRoute}/${data.id}`, data);
