import styled from 'styled-components';

const breakpoint = '1400px';

const spaceBetweenColumns = 20;
const columnWidths = `calc(50% - ${spaceBetweenColumns/2}px)`;
export const ColumnWidgetWrapper = styled.div`
  flex: 1;
  margin-bottom: 40px;
  > div {
    margin-bottom: 20px;
  };
  @media (min-width: ${breakpoint}) {
    margin-bottom: 0px;
    width: ${columnWidths};
    :not(:first-child) {
      margin-left: 20px;
    };
  };
`;
