import styled from 'styled-components';

interface Props {
  height?: string;
  padding?: string;
}
export const TimelineAnchor = styled.div<Props>`
  page-break-inside: avoid;
  display: grid;
  grid-template-areas: 'left center right';
  grid-template-columns: 1fr 96px 1fr;
  width: 100%;
  height: ${props => props.height ? props.height : 'auto'};
  padding: ${props => props.padding ? props.padding : '0px'} 20px;
  box-sizing: border-box;
`;
