import { Image, Loader } from '@instech/components';
import { TextLink } from '@/components/shared/TextLink';
import { useImagePreviewById } from '@/services/imageServices';
import { Guid } from '@/types';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

export const imageFetchConfig = {
  errorRetryInterval: 800,
  retryCount: 20
};

const StyledImage = styled(Image)`
  border: none;
  height: 400px;
  > img {
    object-fit: contain;
  }
`;

const Wrapper = styled.div`
  font-style: italic;
`;

const ImageLoadError: FunctionComponent<{onDismiss?: () => void}> = ({ onDismiss }) => (
  <Wrapper>
    Something went wrong. Try again later
    {onDismiss ? (
      <span>
        {' or '}
        <TextLink onClick={onDismiss}>upload a new image.</TextLink>
      </span>
    ) : '.'}
  </Wrapper>
);

const ImageNotUploadedYetMessage = () => (
  <Wrapper>
    No image has been uploaded yet.
  </Wrapper>
);

interface Props {
  imageId: Guid;
  readonly: boolean;
  onErrorDismiss?: () => void;
}
export const ImagePreview: FunctionComponent<Props> = ({ imageId, readonly, onErrorDismiss }) => {
  const { data, isValidating } = useImagePreviewById(
    imageId,
    'large',
    imageFetchConfig
  );

  const uploadError = !data && !isValidating;
  const cropError = data && !data.url;

  if (uploadError || cropError) {
    if (readonly) return <ImageNotUploadedYetMessage />;
    return <ImageLoadError onDismiss={onErrorDismiss} />;
  }

  if (!data) {
    return <Loader size="medium" />;
  }
  return (
    <StyledImage url={data.url} alt={data.title} />
  );
};
