import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { optionPropType } from '@/prop-types/custom-prop-types';
import {
  FieldLayout, ErrorMessage, FieldLabel, InputField
} from '../core/Components';

/* In order to style the arrow on the dropdown, we need to wrap the
Select in a new element, add the arrow to the wrapper, and
then give the Select extra padding to make space. */
const DropdownWrapper = styled(InputField)`
  position: relative; 

  & select {
    padding-right: 40px;
  }

  &::after {
    content: '';
    position: absolute;
    height: 0.813em;
    width: 0.813em;
    margin-top: 0.625em;
    border-style: solid;
    border-color: ${props => props.theme.marineBlue};
    border-width: 0.1em 0.1em 0 0;
    box-sizing: border-box;
    transform: rotate(135deg);
    right: 15px;
    pointer-events: none;

    ${props => props.error && css`
      border-color: #AD1F23;
    `};
  }
`;

const DefaultSelect = (children, selectedOption, onChange, disabled) => (
  <select
    type="select"
    value={selectedOption}
    onChange={onChange}
    disabled={disabled}
  >
    {children}
  </select>
);

const Options = ({ options, placeholder }) => (
  <>
    {placeholder && (
    <option hidden defaultValue key={placeholder}>{placeholder}</option>
    )}
    {options.map((option, i) => (
      <option key={option.id} defaultValue={!placeholder && i === 0} value={option.id}>{option.value}</option>
    ))}
  </>
);
Options.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape(optionPropType)).isRequired,
  placeholder: PropTypes.string
};

export const Dropdown = ({
  name,
  className,
  label,
  noErrors,
  error,
  touched,
  options = [],
  selectedOption,
  onChange,
  placeholder,
  disabled,
  renderSelect = DefaultSelect,
}) => (
  <FieldLayout className={className}>
    {label && <FieldLabel as="label" htmlFor={name}>{label}</FieldLabel>}
    <DropdownWrapper error={touched && error}>
      {renderSelect(<Options options={options} name={name} placeholder={placeholder} />, selectedOption, onChange, disabled)}
    </DropdownWrapper>
    {!noErrors && (
    <ErrorMessage>
      {error && touched ? error : ''}
    </ErrorMessage>
    )}
  </FieldLayout>
);

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape(optionPropType)).isRequired,
  selectedOption: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  noErrors: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  renderSelect: PropTypes.func,
  disabled: PropTypes.bool
};
