import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useParams } from 'react-router';
import useSWR from 'swr';
import { putJsonAsync } from '@/services/client';
import {
  Guid, Expense, ExpenseGroup
} from '@/types';

type ExpensesType =
 | 'Expenses'
 | 'GeneralExpenses'
 | 'OccurrenceExpenses';

const expensesUrl = (type: ExpensesType, caseId: Guid, chapterId: Guid, widgetId: Guid) => `/${type}/case/${caseId}/chapters/${chapterId}/widget/${widgetId}`;

export const useExpensesService = (type: ExpensesType, widgetId: Guid, swrOptions: any) => {
  const caseId = useCaseIdFromUrl();
  const { chapterId } = useParams<any>();
  const requestUrl = expensesUrl(type, caseId, chapterId, widgetId);
  const { data, mutate } = useSWR(requestUrl, swrOptions);
  const update = async (values: Expense[]) => {
    const result = await putJsonAsync(requestUrl, values);
    void mutate(result);
    return values;
  };

  return [data, update];
};

export const useExpenses = (type: ExpensesType, caseId: Guid, chapterId: Guid, widgetId: Guid, swrOptions: any) => {
  const requestUrl = expensesUrl(type, caseId, chapterId, widgetId);
  return useSWR<ExpenseGroup>(requestUrl, swrOptions);
};
