/* eslint-disable react/forbid-prop-types */
import { FunctionComponent } from 'react';
import { CertificateListItem } from '@/services/certificatesServices';
import { CertificateWithCheckbox } from './CertificateWithCheckbox';
import { CertificateEntryList } from './CertificateEntryList';
import { Wrapper } from '../Overview';

interface Props {
  header: string;
  fieldName: string;
  onChange: (newValue: any) => void;
  entries: CertificateListItem[];
  value: boolean;
}
export const ClassRecommendations: FunctionComponent<Props> = ({ header, fieldName, onChange, entries, value }) => {
  const showRecommendations = value && value !== null;
  return (
    <Wrapper>
      <CertificateWithCheckbox
        header={header}
        value={value}
        onChange={onChange}
      />
      {showRecommendations && (
        <CertificateEntryList
          fieldName={fieldName}
          entries={entries}
          nameLabel="Class recommendation"
          dateLabel="Due date"
          placeholder='Class recommendation e.g. "CC67"'
        />
      )}
    </Wrapper>
  );
};
