import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { StatusDot } from '@/components/pages/Dashboard/reportVersions/StatusDot';
import { ReportVersion } from '@/utils/archivedReportTypes';
import { TableCell as Cell } from '@/components/shared/FilterableTable';
import { Notification } from '@/services/caseNotificationsServices';
import { NotificationBadge } from './ClaimsHandlerAssignments/core/badge';

interface TableCellProps { favourite?: boolean }
const StyledTableCell = styled(Cell)<TableCellProps>`
  font-weight: ${props => props.favourite ? 'bold' : 'normal'};
`;

export const TableCell: FunctionComponent<TableCellProps> = ({ children, favourite }) => (
  <StyledTableCell textWrap favourite={favourite}>
    {children}
  </StyledTableCell>
);

interface ReportStatusContentProps {
  reportVersion?: ReportVersion;
}
const ReportStatusContent = ({ reportVersion }: ReportStatusContentProps) => {
  if (!reportVersion) {
    return <div>-</div>;
  }

  return (
    <div>
      <StatusDot status={reportVersion.status} />
      <span>{reportVersion.reportType}</span>
    </div>
  );
};

const ReportStatusField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface ReportStatusProps {
  caseId: string;
  reportVersion?: ReportVersion;
  notifications?: Notification[];
}
export const ReportStatus: FunctionComponent<ReportStatusProps> = ({ caseId, reportVersion, notifications }) => (
  <ReportStatusField>
    <ReportStatusContent reportVersion={reportVersion} />
    {notifications && <NotificationBadge notifications={notifications} caseId={caseId} />}
  </ReportStatusField>
);
