import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isNullishOrWhitespace } from '@/utils/text';
import { TruncatingText } from '@instech/components';

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const Title = styled.span`
  display: inline-block;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const DetailRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const Detail = ({ title, text = '-' }) => (
  <DetailWrapper>
    <Title>{title}</Title>
    <TruncatingText text={isNullishOrWhitespace(text) ? '-' : text} padding="0px 10px 0px 0px" />
  </DetailWrapper>
);
Detail.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};
