import { BoxButton } from '@instech/components';
import { AddWhite } from '@instech/icons';
import { FC, useState } from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  background-color: #ECF1F5;
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  padding-left: 20px;
  padding-right: 12px; /* Close button has its own padding */
  border-bottom: 1px solid #7F9DB8;
`;

const FormTitle = styled.span`
  color: ${props => props.theme.marineBlue};
  padding-top: 11px;
  padding-bottom: 13px;
`;

const FormBody = styled.div`
  padding: 20px;
`;

interface Props {
  title: string;
  openLabel: string;
  startOpen?: boolean;
  children(props: { onClose: () => any }): React.ReactNode;
}
export const ToggleInlineForm: FC<Props> = ({ title, openLabel, startOpen = false, children }) => {
  const [isOpen, setOpen] = useState(startOpen);
  const open = () => setOpen(true);
  const close = () => setOpen(false);

  if (!isOpen) {
    return (
      <BoxButton onClick={open} startIcon={<AddWhite />}>
        {openLabel}
      </BoxButton>
    );
  }

  return (
    <FormContainer>
      <FormHeader>
        <FormTitle>{title}</FormTitle>
      </FormHeader>
      <FormBody>
        {(children({ onClose: close }))}
      </FormBody>
    </FormContainer>
  );
};
