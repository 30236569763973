import styled from 'styled-components';

export interface ViewProps {
  data: any;
  title: string;
}

const ItalicText = styled.div`
  font-style: italic;
`;

export const NotSpecified = () => (
  <ItalicText>Not specified</ItalicText>
);

export const NoAddedInformation = () => (
  <ItalicText>No added information</ItalicText>
);

export const InfoBox = styled.div`
  width: 150px;
  display: block;
`;

export const ContentBox = styled.div`
  display: block;
  overflow: hidden;
  max-width: 80%;
`;

export interface HeaderProps {
  large?: boolean;
  upper?: boolean;
}

export const HeaderText = styled.div<HeaderProps>`
  font-size: ${props => props.large ? '16px' : '13px'};
  font-weight: bold;
  padding-bottom: 3px;
  display: block;  
  text-transform: ${props => props.upper ? 'uppercase' : ''};
`;

export const Particular = styled.div`
  padding: 5px 0;
`;

export const Wrapper = styled.div`
  display: flex;
`;
