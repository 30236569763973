import useSWR, { mutate } from 'swr';
import client from './client';

const favoriteUrl = (caseId: string) => `caseFavourites/${caseId}`;

const favouritesUrl = 'caseFavourites';

export interface CaseFavorites {
  id: string;
  caseId: string[];
}

export const useCaseFavourites = () => useSWR<CaseFavorites>(favouritesUrl);

export const addFavourite = async (caseId: string) => {
  const result = await client.post<boolean>(favoriteUrl(caseId));
  await mutate(favouritesUrl);
  return result.data;
};

export const removeFavourite = async (caseId: string) => {
  await client.delete(favoriteUrl(caseId));
  await mutate(favouritesUrl);
};
