import { useDamageDescription } from '@/services/damageDescriptionServices';
import { useOccurrence } from '@/services/occurrenceServices';
import { Detail, DetailRow } from '@/components/pages/edit/components/OccurrenceDetails';
import { getFriendlyDate } from '@/utils/date';
import {
  concatString,
  getOccurrencePrefixTitle
} from '@/utils/text';
import { ItemDescriptionTable } from '../../components/ItemDescriptionTable';
import { FullwidthWidgetWrapper } from '../FullwidthWidgetWrapper';
import { EditPane } from '../../core/EditPane';

interface Props {
  widgetId: string;
  title: string;
}
const DamageDescription = ({ title, widgetId }: Props) => {
  const [item, update] = useDamageDescription(widgetId);
  const { data: occurrence } = useOccurrence(item?.occurrenceId, { suspense: true });
  const occurrenceTitle = getOccurrencePrefixTitle(title, true);

  const submitDamage = async (newDamage: any) => {
    const newItem = { ...item, damages: newDamage };
    await update(newItem);
  };

  const deleteDamage = async (itemArrayIndex: number) => {
    const newItem = { ...item, damages: item?.damages?.filter((_e, index) => index !== itemArrayIndex) ?? [] };
    await update(newItem);
  };

  return (
    <FullwidthWidgetWrapper>
      <EditPane title={occurrenceTitle}>
        <DetailRow>
          <Detail title="Date of loss" text={getFriendlyDate(occurrence?.dateOfLoss)} />
          <Detail title="Claim reference" text={concatString(occurrence?.interests.map(x => x.claimReference))} />
          <Detail title="Location" text={occurrence?.location} />
        </DetailRow>
        <ItemDescriptionTable
          formId={`widget:${widgetId}-damage`}
          data={item?.damages || []}
          addLabel="Damage Description"
          submit={submitDamage}
          onDelete={deleteDamage}
        />
      </EditPane>
    </FullwidthWidgetWrapper>
  );
};

export { DamageDescription };
