import { useTypedModal } from '@/components/modal/ModalContext';
import { IconButton } from '@instech/components';
import { Pen } from '@instech/icons';
import styled from 'styled-components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { AssignedSurveyorsInCase, useAssignedSurveyors } from '@/services/CaseAssignmentsService';
import { openSurveyorEditSurveyorsModal } from '../../SurveyorsModal/SurveyorEditSurveyorsModal';

const NameStyled = styled.span`
  font-weight: bold;
`;

const DetailsContainerStyled = styled.div`
  padding: 20px;
  min-height: 114px;
  color: ${props => props.theme.marineBlue};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.softBlue};
  }

  // Adjust the margin of the first element to offset the height of the edit button,
  // ensuring the sticky button appears on top of the first element.
  &:first-of-type {
    margin-top: -48px;
  }
`;

const PrimaryDetailsStyled = styled.div`
  font-size: 16px;
  display: flex;
  gap: 16px;
`;

const PrimarySurveyorStyled = styled.span`
  font-style: italic;
`;

const ContactInfoStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SecondaryDetailsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SurveyorDetailsStyled = styled.div`
  position: relative;
  overflow-y: auto;
  max-height: 228px;
  display: flex;
  flex-direction: column;
`;

const EditButtonStyled = styled.span`
  position: sticky;
  width: fit-content;
  margin-left: auto;
  padding: 10px;
  top: 0;
  background-color: ${props => props.theme.lightBlue};
`;

type SurveyorDetailsCardProps = {
  name: string;
  company: string;
  email: string;
  phone: string;
  isPrimary?: boolean;
}
const SurveyorDetailsCard = ({ name, company, email, phone, isPrimary }: SurveyorDetailsCardProps) => (
  <DetailsContainerStyled>
    <PrimaryDetailsStyled>
      <NameStyled>
        {name}
      </NameStyled>
      {isPrimary &&
        <PrimarySurveyorStyled>Primary</PrimarySurveyorStyled>}
    </PrimaryDetailsStyled>
    <SecondaryDetailsStyled>
      <div>{company}</div>
      <ContactInfoStyled>
        <span>
          {email}
        </span>
        <span>
          {phone}
        </span>
      </ContactInfoStyled>
    </SecondaryDetailsStyled>
  </DetailsContainerStyled>
);

type EditButtonProps = {
  assignedSurveyorsInCase: AssignedSurveyorsInCase;
}
const EditButton = ({ assignedSurveyorsInCase }: EditButtonProps) => {
  const { open } = useTypedModal(openSurveyorEditSurveyorsModal({ assignedSurveyorsInCase }));
  return (
    <EditButtonStyled>
      <IconButton smallIcon width="28px" height="28px" icon={<Pen />} onClick={open} />
    </EditButtonStyled>
  );
};

export const SurveyorDetails = () => {
  const caseId = useCaseIdFromUrl();
  const assignedSurveyors = useAssignedSurveyors(caseId);

  return (
    <SurveyorDetailsStyled>
      <EditButton assignedSurveyorsInCase={assignedSurveyors} />
      {assignedSurveyors.surveyors.map(surveyor => (
        <SurveyorDetailsCard
          key={surveyor.id}
          name={surveyor.fullName}
          company={surveyor.company}
          email={surveyor.email}
          phone={surveyor?.phoneNumber ?? ''}
          isPrimary={surveyor.id === assignedSurveyors.primarySurveyorId}
        />
      ))}
    </SurveyorDetailsStyled>
  );
};
