import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Guid } from '@/types';
import { FileParagraph, MoveLayerDown } from '@instech/icons';
import { reportViewRoute } from '@/utils/routeTemplates';
import { NewBoxButton } from '@/components/shared/BoxButton/NewBoxButton';
import { useModalContext } from '@/components/modal/ModalContext';
import { openDownloadPdf } from '@/components/shared/FileDownload/FileDownloadModal';
import { useCaseNotifications } from '@/services/caseNotificationsServices';

interface ButtonProps { notification?: boolean; }
const StyledButton = styled(NewBoxButton) <ButtonProps>`
  width: 100%;
  background-color: ${props => (
    props.notification ? props.theme.green : props.theme.ultraLightGray2
  )};
  color: ${props => props.theme.marineBlue};
  border: 0px;
  box-shadow: none;

  @media (hover: hover) { 
    &:hover&:not(:disabled) {
      background-color: ${props => (
    props.notification ? props.theme.green75 : props.theme.lightGray
  )};
    }; 
  }
`;

interface DownloadButtonProps {
  caseId: Guid;
  archivedId: Guid;
  pdfGenerated: boolean;
  notification?: boolean;
}
export const DownloadButton: FunctionComponent<DownloadButtonProps> = ({ caseId, archivedId, pdfGenerated, notification }) => {
  const { open } = useModalContext();
  const { dismissArchiveVersion } = useCaseNotifications(caseId);
  const callback = notification ? dismissArchiveVersion : undefined;
  return (
    <StyledButton
      startIcon={<MoveLayerDown />}
      onClick={() => open(openDownloadPdf(caseId, archivedId, pdfGenerated, callback))}
      notification={notification}
    >
      Download
    </StyledButton>
  );
};

interface ViewReportButtonProps {
  caseId: Guid;
  archivedId: Guid;
  notification?: boolean;
}
// This is basically a new NavBox instead of reusing the NavBox component, so that there's no need to
// make some tangly Styled mixing to redesign those components to work with this style. And so that
// both these two buttons can adopt the same styles more easily.
export const ViewReportButton: FunctionComponent<ViewReportButtonProps> = ({ caseId, archivedId, notification }) => {
  const reportRoute = reportViewRoute(caseId, archivedId);
  const { dismissArchiveVersion } = useCaseNotifications(caseId);
  const onClick = notification ? dismissArchiveVersion : undefined;
  return (
    <Link to={reportRoute} onClick={onClick}>
      <StyledButton startIcon={<FileParagraph />} notification={notification}>
        View
      </StyledButton>
    </Link>
  );
};
