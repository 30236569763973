import { Interest, claimInterests } from '@/types';
import { formatNumber } from '@/utils/number';
import { ComponentProps } from 'react';
import styled from 'styled-components';
import { RefreshClaim } from './ClaimsField';
import { Field } from './Components';

const InterestList = styled.div`
  background-color: ${({ theme }) => theme.marineBlue05};
  padding: 0px 84px;
  padding-bottom: 0;
  box-shadow: inset 0 0 2px ${({ theme }) => theme.marineBlue50};
`;

const InterestRow = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.marineBlue20};
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto 2fr;
  padding: 16px 0;
  align-items: center;

  :last-child {
    border: 0;
  }
`;

const getFormattedDeductible = (interest: Interest) => {
  if (interest.claimInterest === claimInterests.lossOfHire) return interest.deductible;
  const value = formatNumber(Number(interest.deductible));
  return value === '0' ? '-' : value;
};

interface InterestRowsListProps {
  interests: Interest[];
  occurrenceId: string;
  updateInterest: ComponentProps<typeof RefreshClaim>['updateInterest'];
  userCanEdit: boolean;
}
const InterestRowsList = ({ interests, occurrenceId, updateInterest, userCanEdit }: InterestRowsListProps) => {
  if (interests.length === 0) {
    return null;
  }

  return (
    <InterestList>
      {interests.map(interest => (
        <InterestRow key={interest.claimReference}>
          <Field label="Interest" value={interest.interest ?? ''} />
          <Field label="Deductible" value={getFormattedDeductible(interest) ?? ''} />
          <Field label="Our share" value={interest.ourShare ? `${interest.ourShare} %` : ''} />
          <RefreshClaim
            occurrenceClaimId={interest.claimId}
            occurrenceId={occurrenceId}
            claimReference={interest.claimReference}
            updateInterest={updateInterest}
            userCanEdit={userCanEdit} />
        </InterestRow>
      ))}
    </InterestList>
  );
};

export default InterestRowsList;
