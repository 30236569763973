import useSWR, { keyInterface } from 'swr';

type fetcherFn<Data> = ((...args: any) => Data | Promise<Data>) | null;

type mutateCallback<Data = any> = (currentValue: Data) => Promise<Data> | Data;

type responseInterface<Data, Error> = {
  data: Data;
  error?: Error;
  revalidate: () => Promise<boolean>;
  mutate: (data?: Data | Promise<Data> | mutateCallback<Data>, shouldRevalidate?: boolean) => Promise<Data | undefined>;
  isValidating: boolean;
};

/**
 * Typescript friendly version of useSWR with suspense.
 *
 * The return type is never undefined so we don't have to manually cast to 'not undefined' when using { suspense: true }
 */
function useSWRWithSuspense<Data = any, Error = any>(
  key: keyInterface,
  fn?: fetcherFn<Data> | null
): responseInterface<Data, Error> {
  return useSWR(key, fn, { suspense: true }) as responseInterface<Data, Error>;
}

export default useSWRWithSuspense;
