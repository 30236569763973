import { FunctionComponent } from 'react';
import { useTimeSavedDetails } from '@/components/pages/edit/widgets/TimeSavedDetails/timeSavedDetailsService';
import { Pane, PreviewReportPane } from '@/components/shared/Pane';
import { TimeSaved } from './core/TimeSaved';

interface ViewProps {
  widgetTitle: string;
  data?: {
    days: number;
    comment: string;
  }
}

interface PreviewProps {
  widgetTitle: string;
  caseId: string;
  chapterId: string;
  widgetId: string;
}

// TODO: Should these not render at all if there are no days and no comments?

export const TimeSavedDetailsReportView: FunctionComponent<ViewProps> = ({ widgetTitle, data }) => (
  <Pane title={widgetTitle || ''}>
    <TimeSaved days={data?.days} comment={data?.comment} />
  </Pane>
);

export const TimeSavedDetailsReportPreview: FunctionComponent<PreviewProps> = ({ widgetTitle, caseId, chapterId, widgetId }) => {
  const { data } = useTimeSavedDetails({ caseId, chapterId, widgetId, swrOptions: { suspense: true } });
  const comment = data.includeInReport ? data.comment : null;
  return (
    <PreviewReportPane title={widgetTitle || ''} caseId={caseId} chapterId={chapterId}>
      <TimeSaved days={data.days} comment={comment} />
    </PreviewReportPane>
  );
};
