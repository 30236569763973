import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Remove } from '@instech/icons';
import { IconButton } from '@instech/components';
import { childrenPropType } from '../../prop-types/custom-prop-types';

const onClickHandler = fn => evt => evt.target === evt.currentTarget && fn();

const ModalScroll = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${props => props.zIndex || props.theme.zIndex.modal};
  overflow-y: scroll;
`;

const Overlay = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 40px;
  background-color: rgba(0,0,0,0.65);
  box-sizing: border-box;
`;

const Container = styled.div`
  pointer-events: none;
  background: none;
  position: relative;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
`;

const LayoutArea = styled.div`
  pointer-events: auto;
  width: 100%;
  max-width: 1110px;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;

  ${props => props.size === 'medium' && css`
    max-width: 716px;
  `};

  ${props => props.size === 'small' && css`
    max-width: 512px;
  `};
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: ${props => props.options.hideTitle ? 'row-reverse' : 'row'};
  justify-content: space-between;
  height: 40px;
  ${props => !props.options.useNewHeader && css`
    background-color: ${!props.options.hideTitle && props.theme.flatWhite};
    padding-left: 20px;
    padding-right: 8px;
  `};
  ${props => props.options.useNewHeader && css`
    padding: 12px 12px 12px 20px;
    color: ${props.theme.marineBlue};
    & svg {
      color: ${props.theme.marineBlue};
      stroke-width: 2px;
    }
  `};
`;

const ModalTitle = styled.span`
  padding-top: 11px;
  padding-bottom: 13px;
  font-weight: bold;
  ${props => props.options.useNewHeader && css`
    font-size: 18px;
  `};
`;

const ModalBody = styled.div`
  padding: ${({ options }) => {
    if (options.padding) return options.padding;
    if (options.useNewHeader) return '12px 32px';
    return '32px';
  }};
  > * ~ * { /* Impose spacing between direct descendants */
    margin-top: 32px;
  }
`;

export const ModalLayout = ({ children, options = {}, closeModal, zIndex = 100 }) => (
  <ModalScroll zIndex={zIndex}>
    <Overlay onClick={onClickHandler(closeModal)}>
      <Container role="dialog">
        <LayoutArea size={options.size}>
          {options.title && (
            <ModalHeader options={options}>
              {!options.hideTitle && (
                <ModalTitle options={options}>{options.title}</ModalTitle>
              )}
              {!options.hideCloseButton && (
                <IconButton
                  icon={<Remove width={24} height={24} />}
                  onClick={closeModal}
                />
              )}
            </ModalHeader>
          )}
          <ModalBody options={options}>
            {children}
          </ModalBody>
        </LayoutArea>
      </Container>
    </Overlay>
  </ModalScroll>
);

ModalLayout.propTypes = {
  children: childrenPropType.isRequired,
  options: PropTypes.shape({
    title: PropTypes.string,
    size: PropTypes.oneOf(['large', 'medium', 'small']),
    padding: PropTypes.string,
    hideTitle: PropTypes.bool,
    useNewHeader: PropTypes.bool,
    hideCloseButton: PropTypes.bool
  }),
  closeModal: PropTypes.func.isRequired,
  zIndex: PropTypes.number
};
