import { useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Expense, SummaryOfCost } from '@/types';
import { calculateSummary } from '@/components/pages/report/shared/components/ExpensesSummaryReportView/utils';
import { calculateExpensesCostSummary } from '@/components/pages/edit/components/ExpenseList/ExpenseListUtils';
import {
  NotifyIcon, AverageValuesTooltip as AggregateValuesTooltip, HeaderWrapper, TextWrapper
} from '@/components/shared/Tooltip';
import styled from 'styled-components';
import { theme } from '@/utils/theme';
import { Close } from '@instech/icons';
import { IconButton } from '@instech/components';
import {
  Row, Field, FieldHeader, FieldContent, LargeField
} from './Components';

export const NotificationArea = styled.div`
  position: relative;
`;

export const AggregateValuesNotifyPatch = styled.div`
  position: absolute;
  height: 110px;
  width: 40%;
  left: 18%;
  top: -30%;
  background-color: ${props => props.theme.marineBlue60};
  opacity: 0.1;
`;

const AggregateValuesNotifyContainer = styled.div`
  width: 320px;
`;

export const CostEstimate = ({ summary, currency }: { summary: SummaryOfCost[], currency: string; }) => {
  const [showAggregateValuesNotifyPatch, setAggregateValuesNotifyPatch] = useState(false);
  const aggregateValuesTooltip = useRef<any>(null);
  const { sum: damageCost, notConcerningAverage } = calculateSummary(summary);

  const aggregateValuesTooltipId = 'aggregate-values-notify';
  const excludedCosts = ['Cost for work not concerning average'];
  // Transform the list of expenses to fit the calculateExpensesCostSummary function.
  // The status must be set and the "sum" prop must be renamed to "cost".
  // We must also filter out 'Cost for work not concerning average'.
  const expenses = summary
    .filter(expence => expence.status)
    .filter(expence => !excludedCosts.includes(expence.title))
    .map(({ sum, status }) => ({ cost: sum, status })) as Expense[];
  const { estimate, quoted, final } = calculateExpensesCostSummary(expenses);

  const onHideTooltip = () => {
    if (aggregateValuesTooltip.current) {
      aggregateValuesTooltip.current.tooltipRef = null;
      ReactTooltip.hide();
    }
  };

  return (
    <NotificationArea>
      <Row>
        <Field>
          <FieldHeader>Damage cost</FieldHeader>
          <FieldContent>{`${currency} ${damageCost}`}</FieldContent>
        </Field>
        <Row gridTemplateColumns="repeat(3, 1fr)">
          { showAggregateValuesNotifyPatch && <AggregateValuesNotifyPatch data-testid="aggregate-values-notify-patch" /> }
          <NotifyIcon
            id={aggregateValuesTooltipId}
            message=""
            color={theme.blue}
            backgroundColor="transparent"
            borderWidth="2px"
            symbol="?"
            dataEvent="click"
            position={{ top: '-5px', right: '45%' }}
            iconSize={{ width: '16px', height: '16px' }} />
          <Field position="relative">
            <FieldHeader>Estimate</FieldHeader>
            <FieldContent>{`${estimate}%`}</FieldContent>
          </Field>
          <Field position="relative">
            <FieldHeader>Quoted / Verified</FieldHeader>
            <FieldContent>{`${quoted}%`}</FieldContent>
          </Field>
          <Field position="relative">
            <FieldHeader>Final</FieldHeader>
            <FieldContent>{`${final}%`}</FieldContent>
          </Field>
        </Row>
        <AggregateValuesTooltip
          id={aggregateValuesTooltipId}
          refCallBack={el => {
            aggregateValuesTooltip.current = el;
          }}
          afterShow={() => setAggregateValuesNotifyPatch(true)}
          afterHide={() => setAggregateValuesNotifyPatch(false)}>
          <AggregateValuesNotifyContainer>
            <Row gridTemplateColumns="4fr 1fr">
              <HeaderWrapper>Aggregated Values</HeaderWrapper>
              <IconButton
                icon={<Close height="14px" color={theme.marineBlue} />}
                onClick={onHideTooltip}
              />
            </Row>
            <TextWrapper>
              The percentages of estimate to final are aggregate
              statuses per chapter and may show a higher
              estimate or quoted/verified percentages.
            </TextWrapper>
          </AggregateValuesNotifyContainer>
        </AggregateValuesTooltip>
        <LargeField>
          <FieldHeader>Cost for work not concerning average</FieldHeader>
          <FieldContent>{`${currency} ${notConcerningAverage?.sum}`}</FieldContent>
        </LargeField>
      </Row>
    </NotificationArea>
  );
};
