/* eslint-disable dot-notation */
import { roles } from '@/components/AppRouting/roles';
import { ModalType } from '@/components/modal/types';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useCaseStatus } from '@/services/caseStatusServices';
import { useCase } from '@/services/dashboardServices';
import { useAllowedUserInfoList, useCurrentUserInfo } from '@/services/userInfoService';
import {
  Case, CaseStatusHistoryItem, UserInfo
} from '@/types';
import { Loader } from '@instech/components';
import styled from 'styled-components';
import { EditSurveyorsModal } from './EditSurveyorsModal';
import { ReadonlySurveyorsModal } from './ReadonlySurveyorsModal';

// Want to just Loader the entirety of the modal interior
const ModalLoader = styled(Loader)`
  height: 400px;
  max-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const checkUserCanEdit = (report?: Case, currentUser?: UserInfo, status?: CaseStatusHistoryItem) => {
  const userIsPrimary = report?.users?.primaryClaimsHandlerId === currentUser?.id;
  const caseIsOpen = status && !status.isClosed;
  return (userIsPrimary && caseIsOpen);
};

const SurveyorsModal = () => {
  const caseId = useCaseIdFromUrl();
  const { data: report } = useCase(caseId, true);
  const { data: currentUser } = useCurrentUserInfo();
  const { data: status } = useCaseStatus(true);
  // Should there be a service for fetching UserInfo from users in report directly?
  const allowedSurveyors = useAllowedUserInfoList(roles.surveyor, report?.claimId, caseId);
  const userCanEdit = checkUserCanEdit(report, currentUser, status);

  if (!currentUser || !report?.users || !status || !allowedSurveyors) {
    return <ModalLoader />;
  }

  if (userCanEdit) {
    return (
      <EditSurveyorsModal
        caseId={caseId}
        report={report}
        allowedSurveyors={allowedSurveyors}
      />
    );
  }

  return (
    <ReadonlySurveyorsModal
      report={report}
      allowedSurveyors={allowedSurveyors}
    />
  );
};

export const openSurveyorsModal = (): ModalType => ({
  component: SurveyorsModal,
  options: {
    title: 'Surveyors with access to edit report',
    padding: '0px 20px 20px',
    useNewHeader: true
  },
});
