import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SaveButton, CloseButton } from '@/components/shared/FormButton/FormButton';
import { ButtonGroup } from '@instech/components';
import { ButtonLoader } from '@/components/shared/Loader';

export const TypeSection = styled.div`
  background-color: ${props => props.theme.flatWhite};
  padding: 20px 20px 10px;
  margin-bottom: 10px;
`;

export const MainSection = styled.div`
  padding: 20px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const FormButtons = ({ saveLabel, closeLabel, disableSave, isSubmitting, onSave, onClose, addLabel, onAdd, addTitle }) => {
  if (isSubmitting) {
    return <LoaderWrapper><ButtonLoader /></LoaderWrapper>;
  }
  return (
    <ButtonGroup>
      <SaveButton label={saveLabel} disabled={disableSave} onClick={onSave} />
      <SaveButton label={addLabel} disabled={disableSave} onClick={onAdd} title={addTitle} width="160px" padding="10px" />
      <CloseButton label={closeLabel} onClick={onClose} />
    </ButtonGroup>
  );
};
FormButtons.propTypes = {
  saveLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  disableSave: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  addLabel: PropTypes.string,
  onAdd: PropTypes.func,
  addTitle: PropTypes.string
};
