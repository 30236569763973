import { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { ReportVersion } from '@/utils/archivedReportTypes';
import { NotifyIcon, NotifyTooltip } from '@/components/shared/Tooltip';
import { useCaseNotifications } from '@/services/caseNotificationsServices';
import { LastModifiedDate } from './LastModifiedDate';
import { StatusInfo } from './StatusInfo';
import { ReportInfoEntry } from './ReportInfoEntry';
import { SeeAllVersions } from './SeeAllVersions';
import { DownloadButton, ViewReportButton } from './ReportButtons';

interface NotificationBoxProps { notification?: boolean; }
export const NotificationBox = styled.div<NotificationBoxProps>`
  position: relative;
  height: 100%;
  border: 3px solid transparent;
  // Ignore parent padding
  margin: 0px -32px -32px 0px;
  padding: 20px 38px 0px 20px;
  ${props => props.notification && css`
    background-color: ${props.theme.green15};
    border: 3px solid ${props.theme.green};
  `}
`;

const Layout = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto) 150px 150px;
  grid-gap: 16px;
  margin-top: auto; /* ensure this is at the bottom of its container */
  align-items: center;
  margin-bottom: 12px;
`;

export const LatestReport = ({ caseId, reportType, versionId, archivedId, status, lastModified, pdfGenerated }: ReportVersion) => {
  const themeContext = useContext(ThemeContext);
  const { data: notifications } = useCaseNotifications(caseId);
  const hasNotification = notifications?.hasArchiveVersionNotification;
  return (
    <NotificationBox notification={hasNotification}>
      {hasNotification && <NotifyIcon id="latest-report-notify" message="New report available" backgroundColor={themeContext.green} />}
      <Layout>
        <ReportInfoEntry type={reportType} version={versionId} />
        <StatusInfo status={status} />
        <LastModifiedDate date={lastModified} />
        <SeeAllVersions id={caseId} />
        <DownloadButton caseId={caseId} archivedId={archivedId} pdfGenerated={pdfGenerated} notification={hasNotification} />
        <ViewReportButton caseId={caseId} archivedId={archivedId} notification={hasNotification} />
      </Layout>
      <NotifyTooltip id="latest-report-notify" />
    </NotificationBox>
  );
};
