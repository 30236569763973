import { TimelineItem } from '@/types';

export const segmentTypes = {
  yearStart: 'yearStart',
  year: 'year',
  pathAfter: 'pathAfter',
  pathBefore: 'pathBefore',
  pathLong: 'pathLong',
  location: 'location',
  vessel: 'vessel',
  tail: 'tail'
} as const;

export type TimelineSegmentTypes = keyof typeof segmentTypes;

/**
 * Discriminatory union of all possible segment types and the
 * properties available to each one, as they are set up in the constructor.
 */
export type TimelineSegment = {
  type: 'yearStart';
  date: string;
} | {
  type: 'year';
  date: string;
  right: boolean;
} | {
  type: 'pathBefore' | 'pathAfter' | 'pathLong';
  right: boolean;
} | {
  type: 'location';
  right: boolean;
  elsewhere: boolean;
  location: string;
} | {
  type: 'vessel';
  right: boolean;
  elsewhere: boolean;
  event: TimelineItem;
} | {
  type: 'tail';
};
