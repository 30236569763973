import * as Yup from 'yup';
import {
  datePickerRequired, stringRequiredMaxLength, dateRangeOptionalStart,
} from '@/utils/validationSchemas';

export const certificateArraySchema = Yup.array().of(
  Yup.object().shape({
    name: stringRequiredMaxLength(),
    date: datePickerRequired()
  })
);

const docSmcSchema = dateRangeOptionalStart();

export const ismSchema = Yup.object().shape({
  issuedBy: stringRequiredMaxLength(),
  doc: docSmcSchema,
  smc: docSmcSchema
});

export const certificateSchema = Yup.object().shape({
  certificatesValid: Yup.bool().nullable(true),
  hasClassRecommendations: Yup.bool().nullable(true),
  casualtyIsmReported: Yup.bool().nullable(true),
  priorRelatedIsmReports: Yup.bool().nullable(true),
  invalidCertificates: Yup.array().when('certificatesValid', {
    is: val => (val === false),
    then: certificateArraySchema,
    otherwise: Yup.array().nullable(true)
  }),
  classRecommendations: Yup.array().when('hasClassRecommendations', {
    is: true,
    then: certificateArraySchema,
    otherwise: Yup.array().nullable(true)
  }),
  ismCertificates: ismSchema
});
