import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { hideBoxShadowOnPrint, hiddenOnPrint } from '@/utils/styles';
import { TruncatingText } from '@instech/components';

const Container = styled.div<{margin?: string}>`
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
  ${hideBoxShadowOnPrint}
  margin: ${props => props.margin};
`;

const Title = styled.div`
  background-color: ${props => props.theme.flatWhite};
  color: ${props => props.theme.darkGray};
  font-size: 16px;
  font-weight: bold;
  display: flex;
`;

const TitleText = styled.div`
  padding: 12px 20px;
  width: 100%;
  // This is needed to make the text truncation work in the children
  overflow: hidden;
`;

const SubTitle = styled.div`
  background-color: ${props => props.theme.lightGray};
  color: ${props => props.theme.blackPearl};
  font-size: 13px;
  padding: 12px 20px;
`;

const ButtonWrapper = styled.div`
  padding-right: 20px;
  align-self: center;
  ${hiddenOnPrint};
`;

const Content = styled.div<{padding?: string}>`
  padding: ${props => props.padding ? props.padding : '20px'};
`;

interface PaneProps {
  title?: string | JSX.Element;
  subTitle?: string;
  button?: JSX.Element;
  margin?: string;
  padding?: string;
  className?: string;
}
export const Pane: FunctionComponent<PaneProps> = ({ title, subTitle, button: Button, children, margin, padding, className }) => (
  <Container margin={margin} className={className}>
    <Title>
      <TitleText>
        {typeof title === 'string' ? <TruncatingText text={title} /> : title}
      </TitleText>
      {Button && <ButtonWrapper>{Button}</ButtonWrapper>}
    </Title>
    {subTitle && <SubTitle><TruncatingText text={subTitle} /></SubTitle>}
    <Content padding={padding}>{children}</Content>
  </Container>
);
