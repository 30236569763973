import {
  ClaimOccurrence, ClaimOccurrenceOverview, Guid
} from '@/types';
import useSWR from 'swr';

export const useClaimsOccurrence = (claimId?: Guid | null) => {
  const swrRoute = claimId ? `/claimsOccurrence/${claimId}` : null;
  return useSWR<ClaimOccurrence>(swrRoute);
};

export const useClaimsOccurrenceOverview = (claimId?: Guid | null) => {
  const swrRoute = claimId ? `/claimsOccurrence/${claimId}/overview` : null;
  return useSWR<ClaimOccurrenceOverview[]>(swrRoute);
};
