import { useParams } from 'react-router';
import { useLatestArchivedReportSummary } from '@/services/archivedReportsSummaryServices';
import { Info } from '@instech/icons';
import { useCase } from '@/services/dashboardServices';
import { Loader } from '@instech/components';
import { DashboardPane } from '../core/DashboardPane';
import { Wrapper } from './Components';
import { CostEstimate } from './CostEstimate';
import { TimeEstimate } from './TimeEstimate';
import { MissingEstimate } from './MissingEstimate';

const SurveyorsEstimateContent = () => {
  const { id: caseId } = useParams<{ id: string; }>();
  const { data, isValidating } = useLatestArchivedReportSummary(caseId);
  const { data: reportCase } = useCase(caseId);
  const currency = reportCase?.metadata.expenseCurrency || '';

  if (!data && isValidating) {
    return <Loader />;
  }

  if (!data && !isValidating) {
    return <MissingEstimate />;
  }

  return (
    <Wrapper>
      <CostEstimate summary={data.expenseSummary} currency={currency} />
      <TimeEstimate summary={data.timeSummary} />
    </Wrapper>
  );
};

export const SurveyorsEstimate = () => (
  <DashboardPane title="Surveyors estimate" icon={<Info />} chapterLink="summary" padding="0">
    <SurveyorsEstimateContent />
  </DashboardPane>
);
