import { charterOptions } from '@/components/pages/edit/widgets/Occurrences/core/options';
import { CharterSelect } from '@/components/pages/edit/widgets/Occurrences/core/CharterSelect';
import {
  Pane, FormField, TextField, DatePicker
} from '@instech/components';
import { FieldLabel } from '@/components/shared/Form/core/Components';

export const OccurrenceFields = () => (
  <Pane title="Occurrence" padding="20px" margin="16px 0px 0px">
    <FormField>
      <TextField name="occurrence.name" placeholder="Name of new occurrence" label="Occurrence Name" />
    </FormField>
    <FormField>
      <DatePicker name="occurrence.dateOfLoss" label="Date of loss" />
    </FormField>
    <FormField>
      <TextField name="occurrence.interests[0].claimReference" placeholder="TBA by claims handler" label="Claims Reference" />
    </FormField>
    <FormField>
      <TextField name="occurrence.location" placeholder="Place and country" label="Location" />
    </FormField>
    <FormField>
      <FieldLabel as="label" htmlFor="occurrence.charter">Charter</FieldLabel>
      <CharterSelect name="occurrence.charter" options={charterOptions} />
    </FormField>
  </Pane>
);
