import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { CheckboxControlled } from '@/components/shared/Form/FormFields';
import { TimelineLegend } from './TimelineLegend';

const Wrapper = styled.div`
  max-width: 750px;
  margin: 0 auto;
`;

const LegendWrapper = styled.div`
  flex-grow: 1;
`;

const CheckboxWrapper = styled.div`
  padding-left: 12px;
`;

interface Props {
  displayInUtcTime: boolean;
  setDisplayInUtcTime: (newValue: boolean) => void;
}
export const TimelineHeader: FunctionComponent<Props> = ({ displayInUtcTime, setDisplayInUtcTime }) => (
  <Wrapper>
    <LegendWrapper>
      <TimelineLegend />
    </LegendWrapper>
    <CheckboxWrapper>
      <CheckboxControlled checked={displayInUtcTime} onChange={() => setDisplayInUtcTime(!displayInUtcTime)} label="Show time and date in UTC" />
    </CheckboxWrapper>
  </Wrapper>
);
