import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form } from 'formik';
import { stringRequired, stringRequiredMaxLength } from '@/utils/validationSchemas';
import {
  FormField, FormRow, TextField, FormikWithPrompt as Formik
} from '@instech/components';
import { ToggleInlineButton } from '@/components/shared/FormButton/FormButton';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';

const particularModel = {
  title: '',
  description: ''
};

const particularSchema = Yup.object().shape({
  title: stringRequiredMaxLength(),
  description: stringRequired()
});

const Container = styled.div`
  background-color: ${props => props.theme.background.primary};
  padding: 15px;
  margin-top: ${props => !props.clearMargin && '20px'};
`;

export const NewParticularForm = ({ submit, showWhenEmpty, hideRestoreDialog }) => {
  const [isOpen, setOpen] = useState(showWhenEmpty);
  const open = () => {
    hideRestoreDialog();
    setOpen(true);
  };
  const close = () => setOpen(false);

  if (!isOpen) {
    return (
      <ToggleInlineButton
        label="Company's particulars"
        onClick={open}
        marginTop={!showWhenEmpty ? '16px' : ''}
      />
    );
  }

  return (
    <Container clearMargin={showWhenEmpty}>
      <Formik
        initialValues={particularModel}
        validationSchema={particularSchema}
        onSubmit={async values => {
          await submit(values);
          close();
        }}
      >
        {({ dirty, isSubmitting, handleSubmit, resetForm }) => (
          <Form>
            <FormRow gutter="10px">
              <FormField span={6}>
                <TextField name="title" label="Function" placeholder='"Ship Owner"' />
              </FormField>
              <FormField span={6}>
                <TextField name="description" label="Company" placeholder='"High Sea Shipping LTD"' />
              </FormField>
            </FormRow>
            <SaveCloseButtons
              saveLabel="Save"
              closeLabel={showWhenEmpty ? 'Reset' : 'Cancel'}
              onSave={handleSubmit}
              disableSave={!dirty}
              disableClose={!dirty && showWhenEmpty}
              isSubmitting={isSubmitting}
              onClose={() => showWhenEmpty ? resetForm() : close()}
              alignRight
              reverse
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
};
NewParticularForm.propTypes = {
  submit: PropTypes.func,
  showWhenEmpty: PropTypes.bool,
  hideRestoreDialog: PropTypes.func
};
