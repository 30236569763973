import { NotifyTooltip } from '@/components/shared/Tooltip';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useCaseStatus } from '@/services/caseStatusServices';
import { useCase } from '@/services/dashboardServices';
import { useOccurrences } from '@/services/occurrenceServices';
import { useCurrentUserInfo } from '@/services/userInfoService';
import { Interest, Occurrence } from '@/types';
import { Info } from '@instech/icons';
import { useCallback } from 'react';
import { DashboardPane } from '../core/DashboardPane';
import { Container } from './Components';
import { OccurrenceRow } from './OccurrenceRow';
import { AddNewOccurrenceButton } from './UpdateOccurrenceModals';

const OccurrenceList = () => {
  const caseId = useCaseIdFromUrl();
  const { data: caseStatus } = useCaseStatus();
  const { data: occurrences, mutate } = useOccurrences({ suspense: true });
  const { data: reportCase } = useCase(caseId, true);
  const { data: currentUser } = useCurrentUserInfo();

  // Test if current user is primary claims handler, as only they can edit
  const userCanEdit = reportCase?.users?.primaryClaimsHandlerId === currentUser?.id && !caseStatus?.isClosed;

  const updateInterest = async (occurrenceId: string, newInterest: Interest) => {
    if (!occurrences) return;

    const newOccurrences = occurrences.map(occurrence => {
      if (occurrence.id !== occurrenceId) {
        return occurrence;
      }

      const newInterests = occurrence.interests.map(interest =>
        interest.claimId !== newInterest.claimId ? interest : newInterest
      );

      return { ...occurrence, interests: newInterests };
    });

    await mutate(newOccurrences, false);
  };

  const onOccurrencesUpdate = useCallback(async (update: (occurrences: Occurrence[]) => Occurrence[]) => {
    if (occurrences === undefined) {
      return;
    }
    await mutate(update(occurrences), false);
  }, [mutate, occurrences]);

  return (
    <Container>
      {reportCase && occurrences?.map((occurrence, index) => (
        <OccurrenceRow
          key={occurrence.id}
          occurrence={occurrence}
          caseMetadata={reportCase.metadata}
          index={index}
          onOccurrencesUpdate={onOccurrencesUpdate}
          updateInterest={updateInterest}
          userCanEdit={userCanEdit}
        />
      ))}
      {userCanEdit && (
        <AddNewOccurrenceButton onOccurrencesUpdate={onOccurrencesUpdate} occurrenceClaimId={reportCase?.claimId ?? null} userCanEdit={userCanEdit} />
      )}
      <NotifyTooltip id="occurrence-list-notify" />
    </Container>
  );
};

export const Occurrences = () => (
  <DashboardPane
    title="Occurrences"
    icon={<Info />}
    chapterLink="occurrences"
    padding="0"
  >
    <OccurrenceList />
  </DashboardPane>
);
