import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BoxButton } from '@instech/components';
import { Add } from '@instech/icons';
import { useTypedModal } from '@/components/modal/ModalContext';
import { ImageCard } from '@/components/shared/Image/ImageCard';
import { Guid } from '@/types';
import { SelectImagesModal } from '@/components/tools/Images/SelectImageModal';

const headerHeight = '49px';

const HeaderRow = styled.div`
  height: ${headerHeight};
  display: flex;
  > button {
    flex-shrink: 0;
  }
`;

const Header = styled.div`
  flex-grow: 1;
  background-color: ${props => props.theme.flatWhite};
  color: ${props => props.theme.darkGray};
  font-size: 15px;
  font-weight: bold;
  line-height: ${headerHeight};
  padding-left: 20px;
`;

const ImageListOuter = styled.div`
  height: calc(100% - ${headerHeight});
  overflow: auto;
`;

const ImageList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 100%);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin: 16px;

  @media (max-width: ${props => props.theme.breakpoints.tabletMax}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

interface Props {
  onImageChange?: (newImages: string[]) => void;
  imageState: string[];
  imageFormId?: string;
}
export const Images: FunctionComponent<Props> = ({ onImageChange, imageState, imageFormId }) => {
  const { open } = useTypedModal({
    component: SelectImagesModal,
    options: {
      title: 'Image Library - Select'
    },
    args: {
      selectedImages: imageState,
      onSelect: (ids: string[]) => {
        if (onImageChange) {
          onImageChange(ids);
        }
      }
    }
  });

  const numberOfImages = imageState ? imageState.length : 0;

  return (
    <>
      <HeaderRow>
        <Header>
          <span>Images</span>
          <span>{` (${numberOfImages})`}</span>
        </Header>
        <BoxButton startIcon={<Add />} onClick={open}>Manage images</BoxButton>
      </HeaderRow>
      <ImageListOuter>
        <ImageList>
          {numberOfImages > 0 && imageState.map((id: Guid) => (
            <ImageCard key={id} id={id} />
          ))}
        </ImageList>
      </ImageListOuter>
    </>
  );
};
