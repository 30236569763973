import { useModalContext } from '@/components/modal/ModalContext';
import { openConfirmDeleteDialog } from '@/components/shared/ConfirmDeleteDialog';
import { IconButton } from '@instech/components';
import { Trash } from '@instech/icons';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { DeleteOccurrenceModalContent } from '@/components/pages/edit/widgets/Occurrences/core/DeleteOccurrenceModalContent';
import styled from 'styled-components';
import { OccurrenceFormSchema, OccurrenceSchema } from './occurrenceFormSchema';

// hack-fix for SVG inside button sometimes catching the
// tooltip hover pointer-events, hiding the tooltip
const StyledIconButton = styled(IconButton)`
  & svg { pointer-events: none; }
`;

interface Props {
  row: OccurrenceSchema;
  occurrences: string[];
  removeFromArray: () => void;
  onDeleteOnServer: (occurrenceId: string) => Promise<void>;
}
export const OccurrenceDeleteButton: FC<Props> = ({
  row,
  occurrences,
  removeFromArray, // Unsure if FieldArray can be accessed from context?
  onDeleteOnServer
}) => {
  const { open } = useModalContext();
  const { values, dirty, resetForm } = useFormikContext<OccurrenceFormSchema>();

  const isLast = values.occurrences.length === 1;

  const confirmDeleteCallback = async () => {
    // If we wound up here on the last item, silently fail
    if (isLast) return;

    // Create a copy of  occurrences without the item about to be deleted
    const copyOccurrences = [...values.occurrences].filter(item => item.id !== row.id);

    // If the occurrence exists on the backend, send call to delete
    if (occurrences.includes(row.id)) {
      await onDeleteOnServer(row.id);
    }

    // Also send call for Formik to safely remove from FieldArray
    removeFromArray();

    if (!dirty) {
      // If deleting was all the user did, reset form to disable Save button
      resetForm({ values: { occurrences: copyOccurrences } });
    }
  };

  const tipText = 'Cannot delete, at least one occurrence is required';

  return (
    <StyledIconButton
      icon={<Trash />}
      disabled={isLast}
      data-tip={isLast ? tipText : undefined}
      data-for="edit-occurrences-tooltip"
      height="48px"
      width="48px"
      onClick={() => openConfirmDeleteDialog({
        name: row.name,
        callback: confirmDeleteCallback,
        open,
        content: <DeleteOccurrenceModalContent name={row.name} />
      })}
    />
  );
};
