import * as Yup from 'yup';
import {
  Expense, ExpenseFormType, TimeStatus
} from '@/types';
import {
  currencyRequired, dropdownOptionRequired, optionalComment, stringRequiredMaxLength
} from '@/utils/validationSchemas';
import { formatNumber } from '@/utils/number';

export interface ExpenseCostSummary {
  isValid: boolean;
  totalCost: string;
  estimate: string;
  quoted: string;
  final: string;
}

const emptyExpenseCostSummary: ExpenseCostSummary = {
  isValid: true,
  totalCost: '0',
  estimate: '0',
  final: '0',
  quoted: '0'
};

interface CostEntry {
  status: string;
  cost: number;
}

const countDecimals = (value: number): number => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

const sumStatus = (entries: CostEntry[], totalCost: number, status: TimeStatus): string => {
  if (totalCost <= 0) return '0';
  const costForStatus = entries
    .filter(entry => entry.status === status)
    .reduce((sum, { cost }) => sum + cost, 0);

  return ((costForStatus / totalCost) * 100).toFixed(0);
};

const maxDecimals = 2;
const isValidNumber = (value: number) => !Number.isNaN(value) && countDecimals(value) <= maxDecimals;

const toNumber = (value: number) => Number(value.toString().replace(' ', ''));

export const calculateExpensesCostSummary = (expenses?: Expense[]): ExpenseCostSummary => {
  if (!expenses || expenses.length === 0) return emptyExpenseCostSummary;

  const validCosts = expenses
    .map(({ status, cost }) => ({ status, cost: toNumber(cost) }))
    .filter(entry => isValidNumber(entry.cost));

  const totalCost = validCosts.reduce((sum, { cost }) => sum + cost, 0);

  return {
    isValid: validCosts.length === expenses.length,
    totalCost: formatNumber(totalCost),
    estimate: sumStatus(validCosts, totalCost, 'Estimate'),
    quoted: sumStatus(validCosts, totalCost, 'Quoted / Verified'),
    final: sumStatus(validCosts, totalCost, 'Final'),
  };
};

export const createExpensesShema = (statusTypes: { id: string, name: string }[]) => {
  const expenseModelSchema = Yup.object().shape({
    name: stringRequiredMaxLength(),
    cost: currencyRequired(),
    status: dropdownOptionRequired(),
    includeInReport: Yup.boolean(),
    comment: optionalComment('includeCommentInReport'),
    includeCommentInReport: Yup.boolean()
  });

  const expensesShema = Yup.object().shape({
    expenses: Yup.array().of(expenseModelSchema).defined()
  });
  return expensesShema;
};

export const mapToExpenseFormTypes = (expenses: Expense[]): ExpenseFormType[] => expenses.map(x => ({
  ...x,
  cost: String(x.cost),
  status: {
    label: x.status,
    value: x.status,
  }
}));

export const mapToExpenses = (expenses: ExpenseFormType[]): Expense[] => expenses.map(x => ({
  ...x,
  cost: Number(x.cost),
  status: x.status?.value || '',
}));
