import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Pane } from '@/components/shared/Pane';
import { childrenPropType } from '@/prop-types/custom-prop-types';
import { NewEventForm } from './NewEventForm';

const Wrapper = styled.div`
  margin-bottom: 30px;
`;

const FormContainer = ({ children }) => (
  <Pane title="New vessel movement &amp; events">
    {children}
  </Pane>
);

FormContainer.propTypes = {
  children: childrenPropType
};

export const NewEventFormInline = ({ handleClose, handleSubmit, addingNew }) => (
  <Wrapper>
    <FormContainer>
      <NewEventForm handleClose={handleClose} onSubmit={handleSubmit} addingNew={addingNew} />
    </FormContainer>
  </Wrapper>
);

NewEventFormInline.propTypes = {
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  addingNew: PropTypes.bool,
};
