import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useOccurrences } from '@/services/occurrenceServices';
import { Pane, PreviewReportPane } from '@/components/shared/Pane';
import { Loader } from '@instech/components';
import { useStaticResource } from '@/services/staticResourcesServices';
import { Occurrences } from './Components';

const InfoText = styled.div`
  margin-bottom: 20px;
`;

export const OccurrenceReportPreview = ({ caseId, chapterId, title }) => {
  const { data } = useOccurrences();
  const { data: infoText } = useStaticResource('occurrenceChapterText');

  if (!data) return <Loader />;

  return (
    <PreviewReportPane title={title} caseId={caseId} chapterId={chapterId}>
      <InfoText>{infoText}</InfoText>
      <Occurrences data={data} />
    </PreviewReportPane>
  );
};
OccurrenceReportPreview.propTypes = {
  caseId: PropTypes.string.isRequired,
  chapterId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export const OccurrenceReportView = ({ data, title }) => {
  // Fallback text for backward compatibility
  const { data: fallbackInfoText } = useStaticResource('occurrenceChapterText');

  return (
    <Pane title={title}>
      <InfoText>{data.infoText || fallbackInfoText}</InfoText>
      <Occurrences data={data.items} />
    </Pane>
  );
};
OccurrenceReportView.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
    infoText: PropTypes.string
  }),
  title: PropTypes.string.isRequired
};
