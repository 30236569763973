import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import {
  dashboardRoute,
  editRoute,
  reportPreviewRoute
} from '@/utils/routeTemplates';
import { NextButton, PreviousButton } from './BoxButton/BoxButtons';
import { useEditContext } from '../pages/edit/core/EditContext';

const ButtonRow = styled.div`
  display: flex;
  > :first-child{
    padding-right: 15px;
  }
`;

const Description = styled.div`
  width: 125px;
  padding-top: 10px;
  font-size: 12px;
  color: ${props => props.theme.black};
`;

export const ChapterNavigation = () => {
  const { id: caseId, chapterId } = useParams();
  const editViewBaseUrl = editRoute(caseId);
  const { chapters } = useEditContext();

  const navigationList = [
    { title: 'Back to vessel dashboard' },
    ...chapters,
    { title: 'Report View' }
  ];

  // eslint-disable-next-line eqeqeq
  const currentChapterIndex = navigationList.findIndex(f => f.id == chapterId);

  const { title: previousTitle, id: previousId } = navigationList[currentChapterIndex - 1];
  const { title: nextTitle, id: nextId } = navigationList[currentChapterIndex + 1];
  const previousRoute = previousId ? `${editViewBaseUrl}/chapter/${previousId}` : dashboardRoute(caseId);
  const nextRoute = nextId ? `${editViewBaseUrl}/chapter/${nextId}` : reportPreviewRoute(caseId);

  return (
    <ButtonRow>
      <div>
        <PreviousButton to={previousRoute} />
        <Description>{previousTitle}</Description>
      </div>
      <div>
        <NextButton to={nextRoute} />
        <Description>{nextTitle}</Description>
      </div>
    </ButtonRow>
  );
};
