import { FunctionComponent } from 'react';
import { PdfColorProfile, PdfLogoAlignment } from '@/services/companyPdfDesignServices';
import styled from 'styled-components';
import { Guid } from '@/types';
import { SmallLogo } from './SmallLogo';

interface Props {
  colorProfile: PdfColorProfile;
  logoAlignment: PdfLogoAlignment;
  companyId: Guid;
}

const Wrapper = styled.div<{logoAlignment: PdfLogoAlignment}>`
  display: flex;
  flex-direction: ${props => props.logoAlignment === 'Right' ? 'row' : 'row-reverse'};
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
`;

const Bar = styled.div<{colorProfile: PdfColorProfile}>`
 // For now there are only 1 available color profile,
 // therefore no need to check 'colorProfile'
  background-color: ${props => props.theme.marineBlue};
  height: 16px;
  flex-grow: 1;
`;

export const Header: FunctionComponent<Props> = ({ logoAlignment, colorProfile, companyId }) => (
  <Wrapper logoAlignment={logoAlignment}>
    <Bar colorProfile={colorProfile} />
    <SmallLogo companyId={companyId} logoAlignment={logoAlignment} />
  </Wrapper>
);
