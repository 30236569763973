import { Fragment, FunctionComponent } from 'react';
import styled from 'styled-components';
import { FilterableTableHeader, SortedTableHeader } from '@instech/components';
import {
  Filters, TableColumnHeader, ColumnMetadata
} from '../types';
import { NonFilterableHeader } from './NonFilterableHeader';

const TableRow = styled.div`
  display: contents;
`;

const NotPaddedFilterableTableHeader = styled(FilterableTableHeader)`
  padding-left: 0;
`;

interface FilterableTableHeadersProps {
  endpoint: string;
  sortedHeader?: SortedTableHeader;
  headers: TableColumnHeader[];
  filters?: Filters;
  columnMetadata?: ColumnMetadata[];
  setSortedHeader: (header: SortedTableHeader) => void;
  changeFilter: (parameterName: any, selectedFacets: any[]) => any;
  allowFilters?: boolean;
  wide?: boolean;
}
export const FilterableTable: FunctionComponent<FilterableTableHeadersProps> = (
  {
    endpoint,
    sortedHeader,
    setSortedHeader,
    headers,
    filters,
    columnMetadata,
    changeFilter,
    allowFilters,
    wide = false
  }
) => {
  const renderFilterableHeader = (header: TableColumnHeader, i: number) => (
    <NotPaddedFilterableTableHeader
      header={header}
      sortedHeader={sortedHeader}
      columnMetadata={columnMetadata}
      filters={filters}
      endpoint={endpoint}
      right={false}
      last={i === headers.length - 1}
      setSortedHeader={setSortedHeader}
    />
  );

  const renderNonFilterableHeader = (header: TableColumnHeader, i: number) => (
    <NonFilterableHeader header={header} />
  );

  return (
    <TableRow>
      {headers && headers.map((header, i) => (
        <Fragment key={header.propertyName || header.title || header.key}>
          {(allowFilters && header.propertyName)
            ? (renderFilterableHeader(header, i))
            : (renderNonFilterableHeader(header, i))}
        </Fragment>
      ))}
    </TableRow>
  );
};
