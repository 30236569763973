import { TruncatingText } from '@instech/components';
import styled from 'styled-components';

const FieldRow = styled.div`
  font-size: 16px;
  flex-basis: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  flex-wrap: wrap;
  @media (min-width: 800px) {
    flex-wrap: none;
    display: block;
  }
  // This is needed to make the text truncation work in the children
  overflow: hidden;
`;

const Label = styled.div`
  font-size: 13px;
  font-weight: bold;
  @media (min-width: 800px) {
    margin-bottom: 8px;
  }
`;

interface FieldProps {
  label: string;
  value?: string | null;
}
const Field = ({ label, value = '-' }: FieldProps) => {
  const displayValue = (value === '' || value === null || value.trim() === '') ? '-' : value;
  return (
    <FieldRow>
      <Label>{ label }</Label>
      <TruncatingText text={displayValue} padding="0px 10px 0px 0px" wrap />
    </FieldRow>
  );
};

export default Field;
