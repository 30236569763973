import { css } from 'styled-components';

export const hiddenOnPrint = css`
  @media print {
    display: none;
  }
`;

export const showBackgroundOnPrint = css`
  @media print {
    -webkit-print-color-adjust: exact;
  }
`;

export const hideBoxShadowOnPrint = css`
  @media print {
    box-shadow: none;
  }
`;

export const addHeaderToTopOnPrint = css`
  @media print {
    position: static;
  }
`;
