import { FunctionComponent } from 'react';
import styled from 'styled-components';

const TextLink = styled.span`
  color: ${props => props.theme.strongBlue};
  font-size: 0.8rem;

  :hover, :active {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledImage = styled.img`
  display: block;
  max-height: 150px;
`;

interface Props {
  url: string;
  text?: string;
  onReplaceSignature?: () => void;
}
export const SignaturePreview: FunctionComponent<Props> = ({ url, text, onReplaceSignature }) => (
  <>
    <StyledImage src={url} alt={text || ''} />
    { onReplaceSignature && <TextLink onClick={onReplaceSignature}>Replace signature</TextLink>}
  </>
);
