import { Loader, TruncatingText } from '@instech/components';
import { Pane, ReportPane } from '@/components/shared/Pane';
import { useVesselInformation } from '@/components/tools/VesselParticulars/information/useVesselInformation';
import { VesselInformation } from '@/services/vesselInformationServices';
import { openVesselInformationTool } from '@/components/tools/VesselParticulars';
import { VesselInformationTab } from '@/components/tools/VesselParticulars/VesselInformationTool';
import { getToolWidget, useManifest } from '@/services/manifestServices';
import { Manifest } from '@/utils/manifestTypes';
import {
  Wrapper, InfoBox, ContentBox, Particular, ViewProps, HeaderText, NoAddedInformation
} from './Components';

const VesselParticulars = ({ data, title }: { data: VesselInformation[], title: string }) => {
  const noData = !data || data.length === 0;
  if (noData) return <NoAddedInformation />;
  return (
    <Wrapper>
      <InfoBox>
        <HeaderText large>{title}</HeaderText>
      </InfoBox>
      <ContentBox>
        {data.map(particular => (
          <Particular key={particular.id}>
            <HeaderText upper><TruncatingText text={particular.title} /></HeaderText>
            <TruncatingText text={particular.value} />
          </Particular>
        ))}
      </ContentBox>
    </Wrapper>
  );
};

export const VesselParticularsView = ({ data, title }: ViewProps) => (
  <Pane title={title}>
    <VesselParticulars data={data.items} title={title} />
  </Pane>
);

export const VesselParticularsPreview = () => {
  const { data } = useVesselInformation();
  const tab = VesselInformationTab.VesselsParticularsTab;
  const { data: manifest } = useManifest() as { data: Manifest };

  if (!data) return <Loader />;

  const filteredData = data.filter(x => x.includeInReport);

  const widget = getToolWidget(manifest, 'VesselInformation');

  const title = widget?.title ?? 'Vessel Information';

  return (
    <ReportPane title={title} open={openVesselInformationTool({ currentTab: tab })} editable>
      <VesselParticulars data={filteredData} title={title} />
    </ReportPane>
  );
};
