import { Suspense } from 'react';
import {
  Loader, PageLoader, WarningNotification
} from '@instech/components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useCase } from '@/services/dashboardServices';
import styled from 'styled-components';
import {
  BrokerPane,
  ClaimDetails,
  ClaimsHandlerVesselMovements,
  DashboardBasePage,
  FeedbackContainer,
  NavButtons,
  Occurrences,
} from './ClaimsHandlerDashboard/';
import { SurveyorsEstimate } from './ClaimsHandlerDashboard/SurveyorsEstimate';

const ContentArea = styled.div`
  display: flex;
`;

const WarningContentArea = styled.div`
  margin-top: 32px;
`;

const PrimaryColumn = styled.div`
  flex: 2;
  margin-right: 50px;
  // Handle spacing between dashboard panes
  & > div + div {
    margin-top: 32px;
  }
`;

const SecondaryColumn = styled.div`
  flex: 1;
  // Handle spacing between dashboard panes
  & > div + div {
    margin-top: 32px;
  }
`;

export const ClaimsHandlerDashboardPage = () => {
  const caseId = useCaseIdFromUrl();
  const { data: reportCase } = useCase(caseId);

  if (!reportCase) {
    return <PageLoader />;
  }

  if (reportCase.userCannotAccessBehindWall) {
    return (
      <DashboardBasePage
        behindClaimWall={reportCase.userCannotAccessBehindWall}
        vesselName={reportCase.name}
      >
        <WarningContentArea>
          <WarningNotification
            headingText="Claim Behind Chinese Wall"
            descriptionText={`You cannot access this case as it is behind a Chinese Wall.
              ${reportCase.metadata.claimReference &&
              ` Claim reference: ${reportCase.metadata.claimReference}`
              }`}
            size="large"
          />
        </WarningContentArea>
      </DashboardBasePage>
    );
  }

  return (
    <DashboardBasePage vesselName={reportCase.name}>
      <ContentArea>
        <PrimaryColumn>
          <ClaimDetails caseData={reportCase} />
          <Occurrences />
          <SurveyorsEstimate />
          <ClaimsHandlerVesselMovements caseId={caseId} />
        </PrimaryColumn>
        <SecondaryColumn>
          <FeedbackContainer />
          <Suspense fallback={<Loader />}>
            <NavButtons />
          </Suspense>
          <BrokerPane />
        </SecondaryColumn>
      </ContentArea>
    </DashboardBasePage>
  );
};
