import { useField, useFormikContext } from 'formik';
import { FunctionComponent, useEffect } from 'react';
import { RadioButtons } from '../RadioButtons';

const defaultPiiLabel = 'This document contains Personal Identifiable Information';

interface PiiFieldsProps {
  piiLabel?: string;
}
export const PiiFields: FunctionComponent<PiiFieldsProps> = ({ piiLabel }) => {
  const [, { value: pii }] = useField('pii');
  const { setFieldTouched } = useFormikContext();
  useEffect(() => {
    setFieldTouched('piiIncludesEu', false, false);
  }, [pii, setFieldTouched]);
  return (
    <>
      <RadioButtons
        name="pii"
        options={['Yes', 'No']}
        label={piiLabel ?? defaultPiiLabel}
        horizontal
      />
      { pii === 'Yes' && (
      <RadioButtons
        name="piiIncludesEu"
        options={['Yes', 'No']}
        label="Is the information about EU citizens (GDPR)?"
        horizontal
      />
      )}
    </>
  );
};
