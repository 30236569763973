import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useSummaryOfTime } from '@/services/summaryoftimeService';
import { Pane, PreviewReportPane } from '@/components/shared/Pane';
import {
  Table, TableHeader, TableRow, TableCell
} from '@/components/shared/Table';
import { SummaryOfTime, SummaryOfTimeGroup } from '@/types';
import { getOccurrencePrefixTitle, numberToDays } from '@/utils/text';
import { useStaticResource } from '@/services/staticResourcesServices';
import { Loader, TruncatingText } from '@instech/components';
import { useManifest } from '@/services/manifestServices';
import { useParams } from 'react-router';
import { useVersionedReport } from '@/services/versionsService';
import {
  getSimultaneousRepairsData, getSimultaneousRepairsMetadata, SimultaneousRepairsContainer, SimultaneousRepairsView
} from './SimultaneousRepairs';

const TextAbove = styled.div`
  padding-bottom: 20px;
`;

interface TableItemProps extends SummaryOfTime {
  even: boolean;
}

const TableItem: FunctionComponent<TableItemProps> = ({ even, title, dryDock, afloat, isOccurrence }) => (
  <TableRow even={even}>
    <TableCell>
      <TruncatingText text={getOccurrencePrefixTitle(title, isOccurrence)} />
    </TableCell>
    <TableCell>{numberToDays(dryDock)}</TableCell>
    <TableCell>{numberToDays(afloat)}</TableCell>
    <TableCell>{numberToDays(dryDock + afloat)}</TableCell>
  </TableRow>
);

interface TimeSummaryProps {
  data: SummaryOfTime[];
  infoText: string;
}

const TimeSummary: FunctionComponent<TimeSummaryProps> = ({ data, infoText }) => (
  <>
    <TextAbove>
      {infoText}
    </TextAbove>
    <Table layout="60% repeat(3, auto)">
      <TableHeader />
      <TableHeader>In dry dock</TableHeader>
      <TableHeader>Afloat</TableHeader>
      <TableHeader>Total</TableHeader>
      {data.map((item: any, i: number) => (
        <TableItem key={item.id} even={i % 2 === 0} {...item} />
      ))}
    </Table>
  </>
);

interface PreviewProps {
  caseId: string;
  chapterId: string;
}

export const TimeSummaryReportPreview: FunctionComponent<PreviewProps> = ({ caseId, chapterId }) => {
  const { data } = useSummaryOfTime({ suspense: true }) as { data: SummaryOfTimeGroup[] };
  const entries = data.flatMap(each => each.timeSummaries);
  const { data: infoText } = useStaticResource('summaryOfTimeChapterText');
  const { data: manifest } = useManifest();

  if (!infoText || !manifest) {
    return <Loader />;
  }

  const simultaneousRepairsMetadata = getSimultaneousRepairsMetadata(manifest);

  return (
    <PreviewReportPane title="Summary of time" caseId={caseId} chapterId={chapterId}>
      <TimeSummary data={entries} infoText={infoText} />
      {simultaneousRepairsMetadata && (
        <SimultaneousRepairsContainer caseId={caseId} {...simultaneousRepairsMetadata} />
      )}
    </PreviewReportPane>
  );
};

const fallbackInfoText = 'If carried out separately the work involved would have required the following time:';

interface ViewProps {
  data: {
    items: SummaryOfTime[];
    infoText?: string;
  }
}

export const TimeSummaryReportView: FunctionComponent<ViewProps> = ({ data }) => {
  const { id: caseId, version } = useParams() as { id: string; version: string; };
  const { data: report } = useVersionedReport(caseId, version);

  if (!report) return <Loader />;

  const simultaneousRepairs = getSimultaneousRepairsData(report);

  return (
    <Pane title="Summary of time">
      <TimeSummary data={data.items} infoText={data.infoText || fallbackInfoText} />
      {simultaneousRepairs && (
        <SimultaneousRepairsView days={simultaneousRepairs.days} />
      )}
    </Pane>
  );
};
