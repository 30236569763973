import styled from 'styled-components';
import { CheckboxField } from './Checkbox';

const InputWrapper = styled.div`
  padding: 10px;
`;

const Label = styled.span<{ margin?: string; }>`
  margin-left: ${props => props.margin};
`;

interface Props {
  id?: string;
  error?: boolean | string;
  disabled?: boolean;
  checked: boolean;
  onChange: (newValue: boolean) => void;
  label?: string;
  labelMargin?: string;
}

export const CheckboxControlled = ({ error, disabled, checked, onChange, id, label, labelMargin }: Props) => (
  <CheckboxField error={error} disabled={disabled}>
    <label htmlFor={id}>
      <InputWrapper>
        <input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={e => onChange(e.target.checked)}
          disabled={disabled}
        />
      </InputWrapper>
      <Label margin={labelMargin}>{label}</Label>
    </label>
  </CheckboxField>
);
