import styled from 'styled-components';
import { TimelineEventType } from '@/utils/timelineTypes';
import { showBackgroundOnPrint } from '@/utils/styles';

const StyledItem = styled.div`
  display: flex;
  padding: 6px;
  align-items: center;
`;

interface ColorBoxProps {
  color: TimelineEventType;
}
const ColorBox = styled.div<ColorBoxProps>`
  background-color: ${props => props.theme.timeline[props.color].main};
  ${showBackgroundOnPrint}
  height: 20px;
  width: 20px;
  border: 1px solid ${props => props.theme.timeline[props.color].dark};
  margin: 10px;
  border-radius: 3px;
`;

interface LegendProps {
  name: string;
  color: TimelineEventType;
}
const LegendItem = ({ name, color }: LegendProps) => (
  <StyledItem>
    <ColorBox color={color} />
    <div>{name}</div>
  </StyledItem>
);

const StyledLegend = styled.div`
  display: flex;
  padding: 6px;
  background-color: ${props => props.theme.white};
  justify-content: space-between;
`;

export const TimelineLegend = () => (
  <StyledLegend>
    <LegendItem name="Occurrence" color="Occurrence" />
    <LegendItem name="Event" color="Event" />
    <LegendItem name="Vessel movement" color="Vessel Movement" />
    <LegendItem name="Vessel status" color="Vessel Status" />
  </StyledLegend>
);
