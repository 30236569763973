import { FunctionComponent } from 'react';
import { Header } from '@/components/layout/Header';
import { useCompanies } from '@/services/companiesService';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Loader } from '@instech/components';
import { ImageUploader } from './ImageUploader';
import { PdfDesign } from './PdfDesign/PdfDesign';

const Page = styled.div`
  background: ${props => props.theme.whiteBlue};
  min-height: 100vh;
`;

const ContentArea = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 16px;
`;

export const CompanyEditPage: FunctionComponent = () => {
  const { companyId }: { companyId: string } = useParams();
  const { data, error } = useCompanies();
  if (error) {
    throw new Error(error);
  }
  if (!data) return <Loader />;
  const company = data.find(({ id }) => id === companyId);

  if (!company) return <div>Company not found</div>;

  return (
    <Page>
      <Header title={`Company profile for ${company.name}`} />
      <ContentArea>
        <ImageUploader companyId={company.id} companyName={company.name} />
        <PdfDesign companyId={companyId} />
      </ContentArea>
    </Page>
  );
};
