import client from '@/services/client';
import { SurveyLocation } from '@/types';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import useSWR from 'swr';

export const surveyLocationApiUrl = (caseId: string) => `/case/${caseId}/surveylocations`;

export const updateLocationsAsync = async (caseId: string, locations: SurveyLocation[]) => {
  const { data } = await client.put<SurveyLocation[]>(surveyLocationApiUrl(caseId), locations);
  return data;
};

export const deleteLocationAsync = async (caseId: string, surveyLocationId: string) => {
  await client.delete<void>(`${surveyLocationApiUrl(caseId)}/${surveyLocationId}`);
};

export const useSurveyLocations = () => {
  const caseId = useCaseIdFromUrl();
  const swr = useSWR<SurveyLocation[]>(surveyLocationApiUrl(caseId));
  return swr;
};
