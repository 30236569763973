import styled, { css } from 'styled-components';

type RadioFieldType = {
  error?: string | boolean;
}
export const RadioField = styled.div<RadioFieldType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  line-height: 21px;

  ${props => props.error && css`
    color: #AD1F23;
  `};

  & input[type="radio"] {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: ${props => props.theme.white};
    border: 1px solid ${props => props.theme.border.gray};
    border-radius: 50%;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
    box-sizing: border-box;
    appearance: none;

    ${props => props.error && css`
        border-color: #AD1F23;
    `};
    &:checked {
      border-color: ${props => props.theme.marineBlue};
    }

    &:checked::after {
      content: '';
      display: block;
      padding: 5px;
      background: ${props => props.theme.marineBlue};
      border-radius: 50%;
    }
  }
`;
