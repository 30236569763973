import { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { Form, FormikHelpers, } from 'formik';
import * as Yup from 'yup';
import { FullwidthWidgetWrapper } from '@/components/pages/edit/widgets/FullwidthWidgetWrapper';
import { Checkbox } from '@/components/shared/Form/FormFields';
import {
  Divider, ButtonGroup, FormField, FormRow, TextArea, FormikWithPrompt as Formik
} from '@instech/components';
import { SaveButton } from '@/components/shared/FormButton/FormButton';
import { Comment } from '@/types';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { optionalComment } from '@/utils/validationSchemas';
import { CommentIncludeInReportBehavior } from '@/components/shared/CommentIncludeInReportBehavior/CommentIncludeInReportBehavior';
import { useComments, updateCommentsAsync } from './commentsService';
import { EditPane } from '../../core/EditPane';

const formModelSchema = Yup.object().shape({
  comment: optionalComment('includeInReport'),
  includeInReport: Yup.bool().nullable()
});

interface CommentsProps { title: string; widgetId: string; }

export const Comments: FunctionComponent<CommentsProps> = ({ title, widgetId }) => {
  const caseId = useCaseIdFromUrl();
  const { chapterId } = useParams<{ chapterId: string }>();
  const { data, mutate } = useComments({ caseId, chapterId, widgetId, swrOptions: { suspense: true } });

  const onSubmitHandler = async (values: Comment, helpers: FormikHelpers<Comment>) => {
    try {
      const newValues = await updateCommentsAsync({ caseId, chapterId, widgetId, comment: values });
      void mutate(newValues);
      helpers.resetForm({ values: newValues });
    } catch {
      // eslint-disable-next-line no-console
      console.error('Could not submit data.', values);
    }
  };

  return (
    <FullwidthWidgetWrapper>
      <EditPane title={title}>
        <Formik
          formId={widgetId}
          initialValues={data}
          validationSchema={formModelSchema}
          onSubmit={onSubmitHandler}
        >
          {({ dirty, handleSubmit }) => (
            <Form>
              <CommentIncludeInReportBehavior<Comment> commentFieldName="comment" includeInReportFieldName="includeInReport">
                <FormRow gutter="40px">
                  <FormField span={6}>
                    <TextArea
                      name="comment"
                      label="Comment - Optional"
                      placeholder="Comment"
                      maxLength={500}
                    />
                  </FormField>
                  <FormField span={3}>
                    <Checkbox
                      name="includeInReport"
                      label="Include comment in report?"
                    />
                  </FormField>
                </FormRow>
                <Divider />
                <ButtonGroup alignRight>
                  <SaveButton
                    disabled={!dirty}
                    onClick={handleSubmit}
                  />
                </ButtonGroup>
              </CommentIncludeInReportBehavior>
            </Form>
          )}
        </Formik>
      </EditPane>
    </FullwidthWidgetWrapper>
  );
};
Comments.propTypes = {
  title: PropTypes.string.isRequired,
  widgetId: PropTypes.string.isRequired,
};
