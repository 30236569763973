import {
  FormField, Pane, TextField
} from '@instech/components';
import { FC } from 'react';

export const CoreFields: FC = () => (
  <>
    <Pane title="Report" padding="20px" margin="16px 0px 0px">
      <FormField>
        <TextField name="name" placeholder="Name" label="Name" />
      </FormField>
    </Pane>
    <Pane title="Metadata" padding="20px" margin="16px 0px 0px">
      <FormField>
        <TextField name="metadata.interest" readOnly placeholder="Interest" label="Interest" />
      </FormField>
      <FormField>
        <TextField name="metadata.conditions.value" readOnly placeholder="Conditions" label="Conditions" />
      </FormField>
      <FormField>
        <TextField name="metadata.policyCurrency" readOnly placeholder="Policy Currency" label="Policy Currency" />
      </FormField>
      <FormField>
        <TextField name="metadata.expenseCurrency" readOnly placeholder="Expense Currency" label="Expense Currency" />
      </FormField>
      <FormField>
        <TextField name="metadata.deductible" readOnly placeholder="Deductible" label="Deductible" />
      </FormField>
      <FormField>
        <TextField name="metadata.claimReference" readOnly placeholder="Claim Reference" label="Claim Reference" />
      </FormField>
    </Pane>
  </>
);
