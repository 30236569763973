import { FunctionComponent } from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import 'styled-components/macro';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { Form } from 'formik';
import {
  dropdownOptionRequired, fileRequired, yesNoOption
} from '@/utils/validationSchemas';
import {
  FileModel, FileStatusOption, FileStatusOptionType, uploadFileForPlaceholderAsync, useFileOptions
} from '@/services/fileServices';
import {
  Dropdown, Dropzone, FormField, FormRow, LabelValuePair, FormikWithPrompt as Formik
} from '@instech/components';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import { useAllowedFileExtensions } from '@/services/allowedFileExtensionsServices';
import { YesOrNo } from '@/types';
import { yesNoToBool } from '@/utils/yesNoType';
import { PiiFields } from '@/components/shared/Form/fields/PiiFields';

const Container = styled.div`
  background-color: ${props => props.theme.background.primary};
  padding: 16px;
  grid-column-start: 1;
  grid-column-end: 4;
`;

const validationSchema = (validFileExtensions: string[]) => Yup.object().shape({
  file: fileRequired(validFileExtensions, false),
  status: dropdownOptionRequired(),
  pii: yesNoOption().required('Required'),
  piiIncludesEu: yesNoOption().when(
    'pii', {
      is: 'Yes',
      then: yesNoOption().required('Required')
    }
  )
});

interface FormModel {
  file?: File;
  status?: LabelValuePair;
  pii?: YesOrNo;
  piiIncludesEu?: YesOrNo;
}

function initialFormValues(initialStatus: LabelValuePair): FormModel {
  return ({
    file: undefined,
    status: initialStatus,
    pii: undefined,
    piiIncludesEu: undefined
  });
}

interface Props {
  handleClose: () => any;
  fileItem: FileModel;
}
export const UploadPlaceholderFileForm: FunctionComponent<Props> = ({ handleClose, fileItem }) => {
  const { data: fileStatusOptions } = useFileOptions() as { data: FileStatusOption[] };

  const dropdownOptions = fileStatusOptions
    .filter(x => x.requireFile)
    .map(x => ({ value: x.status, label: x.name }));

  // Hide enclosed status for filetypes that are not supported
  const reducedOptions = dropdownOptions.filter(x => x.value !== 'Enclosed');

  const caseId = useCaseIdFromUrl();
  const allowedFileExtensions = useAllowedFileExtensions();

  const fileTypes = allowedFileExtensions.map(e => e.replace('.', ''));

  const fileId = fileItem.id;

  return (
    <Container>
      <Formik
        initialValues={{ ...initialFormValues(dropdownOptions[0]) }}
        validationSchema={validationSchema(allowedFileExtensions)}
        onSubmit={async (values, { setSubmitting }) => {
          const uploadRequest = {
            file: values.file as File,
            status: values.status?.value as FileStatusOptionType,
            pii: values.pii === 'Yes',
            piiIncludesEu: yesNoToBool(values.piiIncludesEu)
          };
          await uploadFileForPlaceholderAsync(caseId, fileId, uploadRequest);
          handleClose();
          setSubmitting(false);
        }}
      >
        {({ dirty, isSubmitting, handleSubmit, values }) => (
          <Form>
            <FormRow marginBottom="20px">
              <FormField>
                <Dropzone name="file" filetypes={fileTypes} label="Click or drop file to upload" />
              </FormField>
            </FormRow>
            {values.file && (
            <>
              <FormRow gutter="20px">
                <FormField width="220px">
                  <Dropdown
                    name="status"
                    label="Status"
                    options={!values.file?.name.includes('.pdf') ? reducedOptions : dropdownOptions} />
                </FormField>
              </FormRow>
              <PiiFields />
            </>
            )}
            <SaveCloseButtons
              saveLabel="Save"
              closeLabel="Cancel"
              onSave={handleSubmit}
              disableSave={!dirty}
              onClose={handleClose}
              isSubmitting={isSubmitting}
              alignRight
              reverse />
          </Form>
        )}
      </Formik>
    </Container>
  );
};
