import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FloppyDisk } from '@instech/icons';
import { Form } from 'formik';
import { EditPane } from '@/components/pages/edit/core/EditPane';
import {
  BoxButton, Divider, FormikWithPrompt as Formik
} from '@instech/components';
import { imageStatePropType } from '../../../../prop-types/custom-prop-types';
import { RichText } from './RichText';
import { Pane } from '../../Pane';
import { initializeRichText } from './utility';

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px;
  margin-top: -10px;
  & button {
    align-self: flex-end;
  }  
`;

export const RichTextForm = ({ data, onSubmit, headerText, margin, formId, withReportLink }) => {
  const initialValues = useMemo(() => initializeRichText(data), [data]);
  const submit = newData => {
    const widget = { ...data, ...newData }; // @HN: ugly hack!
    onSubmit(widget);
  };

  const PaneComponent = withReportLink ? EditPane : Pane;

  return (
    <PaneComponent title={headerText} padding="0px" margin={margin}>
      <Formik
        formId={formId}
        initialValues={initialValues}
        onSubmit={async (newValues, { resetForm }) => {
          await submit(newValues);
          resetForm({ values: { ...newValues } });
        }}
      >
        {({ values, setFieldValue, handleSubmit, dirty }) => (
          <Form>
            <RichText
              imageFormId={`${formId}-images`}
              useImages={!data.hideImages}
              onRichTextChange={value => setFieldValue('text', value)}
              richText={data.text}
              onImageChange={value => setFieldValue('images', value)}
              imageState={values.images}
              padding="20px"
              spaceBetween="20px"
            />
            <Footer>
              <Divider />
              <BoxButton
                startIcon={<FloppyDisk />}
                onClick={handleSubmit}
                disabled={!dirty}
              >
                Save
              </BoxButton>
            </Footer>
          </Form>
        )}
      </Formik>
    </PaneComponent>
  );
};

RichTextForm.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string,
    images: imageStatePropType,
    hideImages: PropTypes.bool
  }),
  onSubmit: PropTypes.func,
  headerText: PropTypes.string,
  margin: PropTypes.string,
  formId: PropTypes.string,
  withReportLink: PropTypes.bool
};
