import { FC, useEffect } from 'react';
import { Guid } from '@/types';
import { usePrevious } from '@/hooks/usePrevious';
import { useField, useFormikContext } from 'formik';
import { RichText } from '@/components/shared/Form/RichTextForm/RichText';
import { Pane } from '@instech/components';
import { useTemplateDefinitions } from '../../admin/adminServices';

const fieldName = 'instructions';

interface Props {
  currentTemplateId?: Guid;
}
export const InstructionsField: FC<Props> = ({ currentTemplateId }) => {
  const [, { value: currentInstructions }] = useField(fieldName);
  const { setFieldValue } = useFormikContext();
  const templateDefinitions = useTemplateDefinitions();

  const prevTemplateId = usePrevious(currentTemplateId);

  const prevDefaultInstructions = prevTemplateId
    ? templateDefinitions?.find(x => x.id === prevTemplateId)?.defaultInstructions
    : undefined;

  const nextDefaultInstructions = currentTemplateId
    ? templateDefinitions?.find(x => x.id === currentTemplateId)?.defaultInstructions
    : undefined;

  /**
   * Will update the instructions to the default instructions every time the user change the template
   * dropdown. However, if the user has done any changes to the Instruction textbox, nothing will happen
   * so their changes are not overwritten.
   */
  useEffect(() => {
    const hasEditedInstructionsField =
      currentInstructions &&
      templateDefinitions?.filter(x => x.defaultInstructions === currentInstructions).length === 0;
    if (currentTemplateId !== prevTemplateId && nextDefaultInstructions && !hasEditedInstructionsField) {
      void setFieldValue(fieldName, nextDefaultInstructions);
    }
  }, [currentInstructions, currentTemplateId, nextDefaultInstructions, prevDefaultInstructions, prevTemplateId, setFieldValue, templateDefinitions]);

  return (
    <Pane title="Instructions" padding="20px" margin="16px 0px 0px">
      {!currentTemplateId && ( // If no case template has been selected yet, display a message instead.
        <div><i>Select a template to begin editing case instructions.</i></div>
      )}
      {currentTemplateId && (
        <RichText
          onRichTextChange={value => setFieldValue(fieldName, value)}
          richText={currentInstructions}
        />
      )}
    </Pane>
  );
};
