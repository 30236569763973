import styled from 'styled-components';
import PropTypes from 'prop-types';
import { clampNumber } from '@/utils/number';

const StyledField = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;
const StyledTitle = styled.div`
  top: 0;
  color: ${props => props.theme.marineBlue};
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 16px;
  white-space: nowrap;
`;
const StyledValue = styled.div`
  width: 100%;
  font-size: 20px;
`;

// Clamp the days showing to a min and max value
export const NumberOfDays = ({ value }) => (
  <StyledField>
    <StyledTitle>Total days</StyledTitle>
    <StyledValue>{clampNumber(value) || 0}</StyledValue>
  </StyledField>
);

NumberOfDays.propTypes = {
  value: PropTypes.number
};
