import { WidgetReference, DamageDescription } from '@/types';
import { Pane, PreviewReportPane } from '@/components/shared/Pane';
import { Loader } from '@instech/components';
import { useDamageDescriptionViewService } from '@/services/damageDescriptionServices';
import { getOccurrencePrefixTitle } from '@/utils/text';
import { Section } from './Components';

export const DamageDescriptionReportPreview = ({ title, caseId, chapterId, widgetId, widgetTitle }: WidgetReference) => {
  const [data] = useDamageDescriptionViewService(caseId, chapterId, widgetId as string);

  if (!data) return <Loader />;

  return (
    <PreviewReportPane title={title} subTitle={getOccurrencePrefixTitle(widgetTitle, true)} caseId={caseId} chapterId={chapterId}>
      <Section items={data.damages} />
    </PreviewReportPane>
  );
};

interface ViewProps {
  title: string;
  widgetTitle: string;
  data: DamageDescription;
}
export const DamageDescriptionReportView = ({ title, widgetTitle, data }: ViewProps) => (
  <Pane title={title} subTitle={getOccurrencePrefixTitle(widgetTitle, true)}>
    <Section items={data.damages} />
  </Pane>
);
