import { FormikProps } from 'formik';
import { useEffect } from 'react';

/**
 * If the relevant value changes, reset Touched state
 * on all values listed in the model. Allows resetting a form to its
 * default interaction state after a a major state change.
 */
export const useResetTouched = (
  model: Object,
  selectedValue: string | undefined,
  setTouched: FormikProps<any>['setTouched']
) => useEffect(() => {
  const touchedDict = {} as any;
  Object.keys(model).forEach(key => {
    touchedDict[key] = false;
  });
  void setTouched(touchedDict);
}, [model, selectedValue, setTouched]);
