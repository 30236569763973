import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TitleArea = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const Header = styled.div`
  padding: 33px 30px;
  color: ${props => props.theme.marineBlue};
  background-color: ${props => props.theme.lightBlue};
  h3 {
    font-weight: bold;
    color: ${props => props.theme.marineBlue50};
    font-size: 18px;
    margin: 0;
  }
  h2 {
    margin: 0;
    font-size: 42px;
    font-weight: 400;
  }
`;

const BackLink = styled(NavLink)`
  &:hover { text-decoration: underline; }
`;

const Divider = styled.span`
  margin-left: .25em;
  margin-right: .25em;
`;

// This doesn't feel super elegant, for the markers around the text, but it's
// easier to sort the acutal link styling this way compared to :before and :after markers
export const DashboardHeader = ({ section, title, to }) => (
  <Header>
    <TitleArea>
      <h3>
        <Divider>{'<'}</Divider>
        <BackLink to={to}>{section}</BackLink>
        <Divider>/</Divider>
      </h3>
      <h2>{title}</h2>
    </TitleArea>
  </Header>
);

DashboardHeader.propTypes = {
  section: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string.isRequired,
};
