import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { childrenPropType } from '../../../../prop-types/custom-prop-types';

const TimelineContext = createContext();

export const TimelineProvider = ({ value, children }) => (
  <TimelineContext.Provider value={value}>
    {children}
  </TimelineContext.Provider>
);

TimelineProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.object,
  children: childrenPropType
};

export const useTimelineContext = () => useContext(TimelineContext);
