import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SurveyLocation } from '@/types';
import { useSurveyLocations } from '@/services/locationServices';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { FullwidthWidgetWrapper } from '@/components/pages/edit/widgets/FullwidthWidgetWrapper';
import {
  updateRepresentativesAsync,
  useAttendingRepresentatives,
  AttendingRepresentative
} from '@/services/representativesServices';
import { Loader } from '@instech/components';
import { InformationBox } from '@/components/shared/InformationBox';
import { useStaticResource } from '@/services/staticResourcesServices';
import { RepresentativesTable } from './RepresentativesTable';
import { extractTitles } from './utility/helpers';
import { EditPane } from '../../core/EditPane';

const InfoArea = styled.div`
  padding: 12px;
  margin-bottom: 20px;
  background-color: #ECF1F5;
`;

const Wrapper = styled(FullwidthWidgetWrapper)`
  margin-bottom: 20px;
`;

export const AttendingRepresentativesGroup = (surveyLocation: SurveyLocation) => {
  const caseId = useCaseIdFromUrl();
  const surveyLocationId = surveyLocation.id;
  const { data, mutate } = useAttendingRepresentatives(caseId, surveyLocationId);

  const { data: infoText } = useStaticResource('attendingRepresentativesText');

  if (!data || !infoText) return <Loader />;

  const { title, subTitle } = extractTitles(surveyLocation);

  const onSaveHandler = async (newRepresentatives: AttendingRepresentative[]) => {
    await mutate(newRepresentatives, false);
    const { items } = await updateRepresentativesAsync(newRepresentatives, caseId, surveyLocationId);
    await mutate(items, false);
    return items;
  };

  return (
    <Wrapper>
      <EditPane title={title} subTitle={subTitle}>
        <InfoArea>{infoText}</InfoArea>
        <RepresentativesTable
          representatives={data}
          surveyLocation={surveyLocation}
          onSave={onSaveHandler}
        />
      </EditPane>
    </Wrapper>
  );
};

export const AttendingRepresentatives: FunctionComponent = () => {
  const { data } = useSurveyLocations();

  if (!data) {
    return <Loader />;
  }

  if (data.length === 0) {
    return (
      <InformationBox text="Please add a Survey Location before editing this chapter" />
    );
  }

  return (
    <>
      {data.map(surveyLocation => (
        <AttendingRepresentativesGroup
          key={surveyLocation.id}
          {...surveyLocation}
        />
      ))}
    </>
  );
};
